import { ArrowUpOnSquareIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import AddItemsForm from "../components/sliderForms/addForms/addItemsForm";
import EditItemsForm from "../components/sliderForms/editForms/editItemsForm";
import { HiOutlineDownload, HiOutlinePrinter } from 'react-icons/hi';
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import Quotation from "../components/reports/quotation";
import EditQuoteIEForm from "../components/sliderForms/editForms/editQuoteIEForm";
import ModalQuoteShareWithCustomer from "../components/popUp/quoteShareWithCustomer";
const Quote = () => {

    React.useEffect(() => {
        checkSession();
      }, []);
    
      const checkSession = async () => {
          const data = { 'username': localStorage.getItem('user_id') };
          const token = localStorage.getItem('token');
          const config = { Authorization: `Bearer ${token}` }
          const response = await axios.post(`http://test-ilios-master.linkpc.net/api/checkSession.php`, data, {
              headers: config
          });
          if (response.data.message == 'Invalid') {
            localStorage.removeItem('user_id');
            localStorage.removeItem('token');
            document.location.reload();
          }
      }

    const [tabs, selectTab] = React.useState([
        { name: 'Quote Details', href: '#', current: true },
        { name: 'Items', href: '#', current: false },
        { name: 'Inclusion/Exclusion', href: '#', current: false },]);

    //Slider for Adding Items
    const [addItemsSlider, setAddItemsSlider] = React.useState(false);
    const toggleAddItemsSlider = () => setAddItemsSlider(!addItemsSlider);

    //Slider for Editing Items
    const [editItemsSlider, setEditItemsSlider] = React.useState(false);
    const toggleEditItemsSliderHelper = () => setEditItemsSlider(!editItemsSlider);
    const [editItemsData, setEditItemsData] = React.useState(false);
    const toggleEditItemsSlider = (row) => {
        setEditItemsData(row);
        toggleEditItemsSliderHelper();
    };

    //Slider for Editing IE
    const [editIESlider, setEditIESlider] = React.useState(false);
    const toggleEditIESliderHelper = () => setEditIESlider(!editIESlider);
    const [editIEData, setEditIEData] = React.useState(false);
    const toggleEditIESlider = () => {
        setEditIEData(quote);
        toggleEditIESliderHelper();
    };

    const setTab = (event, name) => {
        event.preventDefault();
        console.log(name);
        var i = 0;
        while (i < tabs.length) {
            var temp = [...tabs]
            if (tabs[i].name == name) {
                temp[i].current = true;
                selectTab(temp);
            } else {
                temp[i].current = false;
                selectTab(temp);
            }
            i++;
            console.log(i);
        }
    }
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //Fetch Quote details
    const [quote, setQuote] = useState(null);
    const { quote_id } = useParams();
    React.useEffect(() => {
        fetchQuote();
    }, []);

    const fetchQuote = async () => {

        const data = { 'action': 'fetch_quote', 'username': localStorage.getItem('user_id'), 'quote_id': quote_id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data, {
            headers: config
        });
        setQuote(response.data);
    }

    //Fetch Items details
    const [items, setItems] = useState(null);
    React.useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = async () => {

        const data = { 'action': 'fetch_quote_items', 'username': localStorage.getItem('user_id'), 'quote_id': quote_id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data, {
            headers: config
        });
        setItems(response.data);
    }

    //Fetch PDF Default details
    const [defaults, setDefaults] = useState(null);
    React.useEffect(() => {
        fetchDefaults();
    }, []);

    const fetchDefaults = async () => {

        const data = { 'action': 'fetch_quote_defaults', 'username': localStorage.getItem('user_id'), 'quote_id': quote_id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data, {
            headers: config
        });
        setDefaults(response.data);
        fetchLogo(response.data.logo);
    }

    const [logoBlob,setLogoBlob] = useState(null);
    const fetchLogo = async (file) => {
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}`, filename: file }
        axios(`http://test-ilios-master.linkpc.net/api/files/fetchLogo.php`, {
            method: 'GET',
            responseType: 'blob', //Force to receive data in a Blob Format
            headers: config
        })
            .then(response => {
                //Create a Blob 
                const file = new Blob(
                    [response.data],
                    { type: 'image/png' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                setLogoBlob(fileURL);
                console.log(fileURL);
            })
            .catch(error => {
                console.log(error);
            });
    }

    //Generated PDF
    const [generated, setGenerated] = useState(0);
    const [pdfData,setPdfData] = useState(null);
    const [pdf,setPdf] = useState(null);
    const toggleGenerateQuoteHelper = (data) => {
        setPdfData(data);
    }
    const toggleGenerateQuote = () =>{
        
        const temp = {
            'quote_no':quote.id,
            'valid_till_date':quote.valid_till_date,
            'travel_start_date':quote.travel_start_date,
            'customer_name':quote.customer_name,
            'customer_email':quote.customer_email,
            'customer_phone':quote.customer_mobile,
            'title':quote.title,
            'currency':quote.currency,
            'inclusions':quote.inclusions,
            'exclusions':quote.exclusions,
            'notes':quote.notes,
            'contact_footer_1':defaults.quote_contact_footer_1,
            'contact_footer_2':defaults.quote_contact_footer_2,
            'detail_tab_color':defaults.detail_tab_color,
            'table_tab_color':defaults.table_tab_color,
            'footer_tab_color':defaults.footer_tab_color,
            'items':items,
            'logo':logoBlob
        };
        
        console.log(temp);
        toggleGenerateQuoteHelper(temp);
        setGenerated(1);
    }

    const deleteItem = async (row) => {
        const data = { 'action': 'remove_quote_item', 'username': localStorage.getItem('user_id'), 'quote_id': quote_id, 'id': row.id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/removeDocuments.php`, data, {
            headers: config
        });
        if (response.data.message == "Success") {
            document.location.reload();
        } else {
            alert(response.data.message);
        }
    }
    
        //Pop up Share
        const [shareWithCustomer, setShareWithCustomer] = React.useState(false);
        const toggleShareWithCustomer = () => setShareWithCustomer(!shareWithCustomer);

    return (
        <div >
            <AddItemsForm open={addItemsSlider} close={toggleAddItemsSlider} quote_id={quote_id}></AddItemsForm>
            <EditItemsForm open={editItemsSlider} close={toggleEditItemsSlider} inputs={editItemsData} quote_id={quote_id}></EditItemsForm>
            <EditQuoteIEForm open={editIESlider} close={toggleEditIESlider} inputs={editIEData} quote_id={quote_id}></EditQuoteIEForm>
            <ModalQuoteShareWithCustomer open={shareWithCustomer} close={toggleShareWithCustomer} quote_id={quote_id}></ModalQuoteShareWithCustomer>
            <div className="hidden sm:block">
                <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
                    {tabs.map((tab, tabIdx) => (
                        <button
                            key={tab.name}
                            onClick={(e) => setTab(e, tab.name)}
                            className={classNames(
                                tab.current ? 'text-gray-100 bg-indigo-900' : 'text-gray-900 hover:text-gray-700',
                                tabIdx === 0 ? 'rounded-l-lg' : '',
                                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                                'group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-center text-xs font-medium hover:bg-gray-50 hover:text-gray-900 focus:z-10'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                        >
                            <span>{tab.name}</span>
                        </button>
                    ))}
                </nav>
            </div>
            {tabs[0].current == true ?
                <div className=" border-none">
                    <div className="mt-2 flex flex-row">
                        {quote!=null && quote.shared_email == null ?<button
                            type="button"
                            className="ml-2 mr-2 rounded-md bg-blue-600 px-2 py-2 sm:py-5 text-xs sm:text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                            onClick={toggleShareWithCustomer}
                        >Share With Customer
                        </button>:
                        <span> Shared with : {quote!=null?quote.shared_email:'Error'}</span>}
                        {generated == "0" ? <button
                            type="button"
                            className="rounded-md bg-blue-600 px-2 py-2 sm:py-5 text-xs sm:text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                            onClick={toggleGenerateQuote}
                        >Generate PDF Quote
                        </button>:null}
                        {generated == "1" ?  <BlobProvider document={<Quotation quote_data={pdfData} />}>
                                    {({ url, blob }) => (
                                        <a href={url} target="_blank" style={{ borderRadius: '3px', border: '1px solid gray', display: 'flex', alignItems: 'center', gap: '2px', padding: '3px', fontSize: '16px', color: '#4f4f4f', fontWeight: 600, cursor: 'pointer', userSelect: 'none' }}>
                                            <HiOutlinePrinter size={20} />
                                            <span>Open Quote</span>
                                        </a>
                                    )}
                                </BlobProvider>:null}
                        
                    </div>
                    <dl className="grid  grid-cols-2 sm:grid-cols-4">
                        <div className="px-4 py-6 bg-slate-100 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Customer</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{quote != null ? quote.customer_name : null}</dd>
                        </div>
                        <div className="px-4 py-6 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Email</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{quote != null ? quote.customer_email : null}</dd>
                        </div>
                        <div className="px-4 py-6 bg-slate-100 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Phone</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">+{quote != null ? quote.customer_mobile_country_code : null}-{quote != null ? quote.customer_mobile : null}</dd>
                        </div>
                        <div className="px-4 py-6 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Title</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{quote != null ? quote.title : null}</dd>
                        </div>
                    </dl>
                    <dl className="grid grid-cols-2 sm:grid-cols-4">
                        <div className="px-4 py-6 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Tour Date</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{quote != null ? quote.travel_start_date : null}</dd>
                        </div>
                        <div className="px-4 py-6 bg-slate-100 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Valid Till</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{quote != null ? quote.valid_till_date : null}</dd>
                        </div>
                        <div className="px-4 py-6 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Currency</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{quote != null ? quote.currency : null}</dd>
                        </div>
                        <div className="px-4 py-6 bg-slate-100 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Pax Count</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{quote != null ? quote.adults_count + quote.childs_count + quote.toddlers_count : null}</dd>
                        </div>
                        <div className="px-4 py-6 bg-slate-100 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Adults</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{quote != null ? quote.adults_count : null}</dd>
                        </div>
                        <div className="px-4 py-6 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Children</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{quote != null ? quote.childs_count : null}</dd>
                        </div>
                        <div className="px-4 py-6 bg-slate-100 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Minors</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{quote != null ? quote.toddlers_count : null}</dd>
                        </div>
                    </dl>
                </div> : null}
            {tabs[1].current == true ?
                <div className="mt-4 flow-root">
                    <div className="" style={{ justifyContent: "space-between" }}>
                        <div className="flex justify-end">
                            <button
                                type="button"
                                className="flex rounded-full bg-green-500 px-2 py-2 sm:py-5 text-xs sm:text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                onClick={toggleAddItemsSlider}
                            >Add New Item
                            </button>
                        </div>
                    </div>
                    <div className=" overflow-y-auto ">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                Description
                                            </th>
                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                Quantity
                                            </th>
                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                Amount
                                            </th>
                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {Array.isArray(items) ? items.map((row) => (
                                            <tr key={row.id}>
                                                <td className="whitespace-nowrap text-sm text-gray-900">
                                                    {row.description}
                                                </td>
                                                <td className="whitespace-nowrap text-sm text-gray-900">{row.quantity}</td>
                                                <td className="whitespace-nowrap text-sm text-gray-900">{row.amount}</td>
                                                <td>
                                                    <button onClick={() => toggleEditItemsSlider(row)} style={{ marginRight: "5px" }} className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600" >Edit</button>
                                                    <button onClick={() => deleteItem(row)} style={{ marginRight: "5px" }} className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600" >Remove</button>
                                                </td>
                                            </tr>
                                        )) : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div> : null}
            {tabs[2].current == true ?
                <div className="mt-4 flow-root">
                    <div className="" style={{ justifyContent: "space-between" }}>
                        <div className="flex justify-end">
                            <button
                                type="button"
                                className="flex rounded-md bg-green-500 px-2 py-2 sm:py-5 text-xs sm:text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                onClick={toggleEditIESlider}
                            > Update
                            </button>
                        </div>
                    </div>
                    <div className=" overflow-x-auto ">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6">
                                                Type
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Comments
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        <tr>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">Inclusions</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900"><textarea cols={20} rows={3}>{ quote != null ? quote.inclusions:null}</textarea></td>
                                        </tr>
                                        <tr>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">Exclusions</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900"><textarea cols={20} rows={3}>{ quote != null ? quote.exclusions:null}</textarea></td>
                                        </tr>
                                        <tr>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">Notes</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900"><textarea cols={20} rows={3}>{ quote != null ? quote.notes:null}</textarea></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div> : null}
        </div>
    );
};

export default Quote;
