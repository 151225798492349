import React, { useState } from 'react';
import {getDocument} from 'pdfjs-dist/legacy/build/pdf.mjs';
import {GlobalWorkerOptions} from 'pdfjs-dist';
import * as pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.mjs';
import JSZip from 'jszip';

GlobalWorkerOptions.workerSrc = pdfjsWorker;
const PdfToImageConverter = () => {
    const [images, setImages] = useState([]);
    const [zipUrl, setZipUrl] = useState('');
    const [pdfFileName, setpdfFileName] = useState('');
    // drag state
    const [dragActive, setDragActive] = React.useState(false);
    // ref
    const inputRef = React.useRef(null);

    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const file = e.dataTransfer.files[0];
            if (file) {
                setpdfFileName(file.name);
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onloadend = async () => {
                    const pdfData = new Uint8Array(reader.result);
                    const pdf = await getDocument({ data: pdfData }).promise;
                    const totalPages = pdf.numPages;
                    const imgList = [];

                    for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
                        const imgData = await convertPageToImage(pdf, pageNum);
                        imgList.push(imgData);
                    }

                    setImages(imgList);
                };
            }
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    const handlePdfUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setpdfFileName(file.name);
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onloadend = async () => {
                const pdfData = new Uint8Array(reader.result);
                const pdf = await getDocument({ data: pdfData }).promise;
                const totalPages = pdf.numPages;
                const imgList = [];

                for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
                    const imgData = await convertPageToImage(pdf, pageNum);
                    imgList.push(imgData);
                }

                setImages(imgList);
            };
        }
    };

    const convertPageToImage = async (pdf, pageNum) => {
        const page = await pdf.getPage(pageNum);
        const viewport = page.getViewport({ scale: 1.5 });
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        await page.render({ canvasContext: ctx, viewport }).promise;

        return canvas.toDataURL('image/jpeg');
    };

    const handleConvertToZip = async () => {

        const zip = new JSZip();
        images.forEach((imgData, index) => {
            zip.file(`page_${index + 1}.jpg`, imgData.split('base64,')[1], { base64: true });
        });

        const content = await zip.generateAsync({ type: 'blob' });
        const zipUrl = URL.createObjectURL(content);
        setZipUrl(zipUrl);
    };

    return (
        <div>
            <div className=" mt-4 flex justify-center">
            <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                <input ref={inputRef} type="file" id="input-file-upload" accept=".pdf" multiple={false} onChange={handlePdfUpload} />
                <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                    {pdfFileName == ''?<div>
                        <p>Drag and drop your file here or</p>
                        <button className="upload-button" onClick={onButtonClick}>Upload a file</button>
                    </div>:
                    <div>
                        <p>{pdfFileName}</p>
                    </div>}
                </label>
                {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
            </form>
            </div>
            <div className="mt-4 flex justify-between">
                <button className="rounded-full bg-blue-600 px-2 py-2 sm:py-5 text-xs sm:text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600" onClick={handleConvertToZip} disabled={images.length === 0}>
                    Convert PDF
                </button>
                {zipUrl && <a className="rounded-full bg-blue-600 px-2 py-2 sm:py-5 text-xs sm:text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600" href={zipUrl} download="images.zip">Download Zip</a>}
            </div>
        </div>
    );
};

export default PdfToImageConverter;
