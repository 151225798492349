import React from "react";
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import axios from "axios";
import Stack from '@mui/material/Stack';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Dashboard = () => {
  
  React.useEffect(() => {
    checkSession();
  }, []);

  const checkSession = async () => {
      const data = { 'username': localStorage.getItem('user_id') };
      const token = localStorage.getItem('token');
      const config = { Authorization: `Bearer ${token}` }
      const response = await axios.post(`http://test-ilios-master.linkpc.net/api/checkSession.php`, data, {
          headers: config
      });
      if (response.data.message == 'Invalid') {
        localStorage.removeItem('user_id');
        localStorage.removeItem('token');
        document.location.reload();
      }
  }

  const stats = [
    { name: 'Total Tours ', value: '00' },
    { name: 'Total Customers', value: '00' },
    { name: 'Pending Payments', value: '00' },
    { name: 'Payments Received', value: '00' },
  ]
  const [loadingDone, setLoadingDone] = React.useState(false);

  const [dashboardStats,setDashboardStats] = React.useState(null);

  React.useEffect(() => {
    fetchDashboardStats();
  }, []);

  const fetchDashboardStats = async() => {
      const data = { 'action': 'fetch_dashboard_stats', 'username': localStorage.getItem('user_id') };
      const token = localStorage.getItem('token');
      const config = { Authorization: `Bearer ${token}` }
      const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data, {
          headers: config
      });
      setDashboardStats(response.data);
      setLoadingDone(true);
  }
  
  
  return (
    <div>
      {loadingDone == true ? <div>
        {dashboardStats != null && dashboardStats.total_tours > 0 ?<div className="bg-white">
          <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
            <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
              {stats.map((stat, statIdx) => (
                <div
                  key={stat.name}
                  className={classNames(
                    statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : '',
                    'flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8'
                  )}
                >
                  <dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
                  <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                    {dashboardStats == null ? stat.value : statIdx == 0 ? dashboardStats.total_tours :statIdx == 1? dashboardStats.total_customers : statIdx == 2 ? dashboardStats.pending_payments :dashboardStats.payments_received}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
            <div >
              <h2>Total Tours History</h2>
              <BarChart
                xAxis={[{ scaleType: 'band', data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] }]}
                series={[{ data: dashboardStats.monthwise_tours }]}
                height={500}
              />
            </div>
        </div> : 
        <div>
          <h2> Welcome ,Now  Please Start Adding Customers and Creating Tours !!! </h2>
          <img className="mt-5 object-center object-cover h-3/5"
               src={`https://test-ilios-master.linkpc.net/assets/logo/home.png`}/>
        </div>}
      </div> : null}
    </div>
  )
};

export default Dashboard;