import React, { Fragment } from 'react'
import { Image, Text, View, Page, Document, StyleSheet, Link } from '@react-pdf/renderer';

const SightseeingVoucher = ({ sightseeing_data, logoBlob, qrBlob }) => {

     
    const styles = StyleSheet.create({
        page: { fontSize: 11, paddingTop: 20, paddingLeft: 30, paddingRight: 30, paddingBottom: 50, lineHeight: 1.5, flexDirection: 'column' },

        spaceBetween: { flex: 2, flexDirection: 'row', justifyContent: 'space-between', color: "#3E3E3E" },

        logo: { width: 120, height: 60 },

        desc_png: { width: 90, height: 60, marginTop: 2, marginBottom: 2},

        titleContainer: { flexDirection: 'row', marginTop: 10 },

        reportTitle: { fontSize: 12, textAlign: 'center' },

        addressTitle: { fontSize: 10 },

        sightseeing: { fontWeight: 'bold', fontSize: 38 },

        sightseeingMisc: { fontSize: 14, fontWeight: 'bold' },

        data: { fontSize:12 },

        address: { fontWeight: 400, fontSize: 10 },

        footer: { position: 'absolute', width: '200%', bottom: 10, fontSize: 8, fontStyle: 'bold', paddingTop: 4, paddingLeft: 7, backgroundColor: '#DEDEDE', borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },

        sightseeingDetail1: { fontSize: 11, fontWeight: 'bold', paddingTop: 4, paddingLeft: 7, flex: 1, height: 25, backgroundColor: '#FFA07A', borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },

        sightseeingDetail2: { fontSize: 11, fontWeight: 'bold', paddingTop: 4, paddingLeft: 7, flex: 1, height: 25, backgroundColor: '#98FB98', borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },

        sightseeingDetail3: { fontSize: 11, fontWeight: 'bold', paddingTop: 4, paddingLeft: 7, flex: 1, height: 25, backgroundColor: '#FFD700', borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },

        theader: { fontSize: 11, fontStyle: 'bold', paddingTop: 4, paddingLeft: 7, flex: 0.5, height: 25, backgroundColor: '#F5DEB3', borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },

        theader2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },

        tbodyday: { fontSize: 12, fontWeight: 'bold', paddingTop: 4, paddingLeft: 7, paddingRight: 7, flex: 0.5, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1, borderLeftWidth: 1 },

        tbody: { fontSize: 9, paddingTop: 4, paddingLeft: 7, paddingRight: 7, flex: 0.5, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1, borderLeftWidth: 1 },

        tbodycolored: { fontSize: 9, paddingTop: 4, paddingLeft: 7, paddingRight: 7, flex: 0.5, backgroundColor: '#DEDEDE', borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1, borderLeftWidth: 1 },

        total: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 3, borderColor: 'whitesmoke', borderBottomWidth: 1, borderLeftWidth: 1 },

        tbody2: { flex: 2, borderRightWidth: 1, },

        tbodybold: { fontSize: 9, fontWeight: 'bold', paddingTop: 4, paddingLeft: 7, flex: 0.5, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1, borderLeftWidth: 1 }

    });

    const SightseeingTitle = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    <Image style={styles.logo} src={logoBlob} />
                </View>
                <View>
                    <Text style={styles.sightseeing}>ACTIVITY VOUCHER</Text>
                </View>
            </View>
        </View>
    );

    const SightseeingToFrom = () => (
        <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
            <View style={styles.sightseeingDetail1}>
                <Text >Booking No:{sightseeing_data.booking_number}</Text>
            </View>
            <View style={styles.sightseeingDetail2}>
                <Text >Date:{new Date(sightseeing_data.activity_datetime).toDateString()}</Text>
            </View>
            <View style={styles.sightseeingDetail3}>
                <Text >Time:{new Date(sightseeing_data.activity_datetime).toTimeString().slice(0,5)}</Text>
            </View>
        </View>
    );

    const SightseeingTitle2 = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View style={{ maxWidth: 200 }}>
                    <Text style={styles.sightseeingMisc}>Booking For:</Text>
                    <Text style={styles.address}>
                        Lead Passenger: {sightseeing_data.lead_passenger[0].label},
                    </Text>
                    <Text style={styles.address}>
                        Contact: +{sightseeing_data.passenger_contact}
                    </Text>
                </View>
                <View style={{ maxWidth: 200 }}>
                    <Text style={styles.sightseeingMisc} >Booking From:</Text>
                    <Text style={styles.reportTitle}>{sightseeing_data.company_name}</Text>
                    <Text style={styles.addressTitle}>{sightseeing_data.address_line_1}</Text>
                    <Text style={styles.addressTitle}>{sightseeing_data.address_line_2}</Text>
                </View>
            </View>
        </View>
    );

    const TableHead = () => (
        <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
            <View style={[styles.theader, styles.theader2]}>
                <Text>Activity Description</Text>
            </View>
            <View style={[styles.theader]}>
                <Text>Tickets</Text>
            </View>
            <View style={[styles.theader]}>
                <Text>Quantity</Text>
            </View>
        </View>
    );

    const TableBody = () => (
            <Fragment key={1}>
                    <View style={{ width: '100%', flexDirection: 'row' }}>
                            <View style={[styles.tbody, styles.tbody2]}>
                                <Text style={styles.data}>{sightseeing_data.activity}</Text>
                            </View>
                            <View style={[styles.tbody]}>
                                {sightseeing_data.tickets.map((t,index)=>(
                                    <view>
                                        <Text style={styles.data}>{index+1} : {t}</Text>
                                        {qrBlob ?<Image style={styles.desc_png} src={qrBlob[sightseeing_data.starting_index + index].url} />:null}
                                        <br></br>
                                    </view>
                                ))}
                            </View>
                            <View style={[styles.tbody]}>
                                <Text style={styles.data}>{sightseeing_data.quantity}</Text>
                            </View>
                    </View>
            </Fragment>
    );

    const TermsTableHead = () => (
        <View style={{ width: '100%', flexDirection: 'row', marginTop: 25 }} wrap={false}>
            <View style={[styles.theader, styles.theader2]} wrap={false}>
                <Text wrap={false}>Terms & Conditions :</Text>
            </View>
        </View>
    );


    const TermsTableBody = () => (
        <Fragment key={1}>
            <View style={{ width: '100%', flexDirection: 'row' }}>
                <View style={styles.bodyIE}>
                    <Text >{sightseeing_data.terms}</Text>
                </View>
            </View>
        </Fragment>
    );

    const SightseeingFooter = () => (
        <View style={styles.footer} fixed>
            <Text >Contact Us:{sightseeing_data.default_contact_footer_1}</Text>
            <Text >{sightseeing_data.default_contact_footer_2}</Text>
        </View>
    );

    return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                <SightseeingTitle />
                <SightseeingToFrom />
                <SightseeingTitle2 />
                <TableHead />
                <TableBody wrap />
                <TermsTableHead />
                <TermsTableBody wrap />
                <SightseeingFooter fixed />
            </Page>
        </Document>

    )
}

export default SightseeingVoucher