import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './pages/LoginPage';
import AppLayout from "./layout/AppLayout";
import Dashboard from "./pages/dashboard";
import ToursList from './pages/toursList';
import CustomersList from './pages/customersList';
import Tour from './pages/tour';
import Wallet from './pages/wallet';
import PdfConverterTool from './pages/pdfConverterTool';
import ToursListCustomerSpecific from './pages/tourListCustomerSpecific';
import QuotesList from './pages/quotationList';
import Quote from './pages/quote';
import Settings from './pages/settings';
import Profile from './pages/profile';
function App() {
    const loginToken = localStorage.getItem('user_id');

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    {loginToken && <Route path="/" element={<AppLayout />}>
                        {loginToken && <Route index element={<Dashboard />} />}
                        {loginToken && <Route path="/tours" element={<ToursList />} />}
                        {loginToken && <Route path="/toursCustomerSpecific/:customer_id" element={<ToursListCustomerSpecific />} />}
                        {loginToken && <Route path="/tours/:tour_id" element={<Tour />} />}
                        {loginToken && <Route path="/customers" element={<CustomersList />} />}
                        {loginToken && <Route path="/quotes" element={<QuotesList />} />}
                        {loginToken && <Route path="/quotes/:quote_id" element={<Quote />} />}
                        {loginToken && <Route path="/wallet" element={<Wallet />} />}
                        {loginToken && <Route path="/pdfConverter" element={<PdfConverterTool />} />}
                        {loginToken && <Route path="/defaults" element={<Settings />} />}
                        {loginToken && <Route path="/profile" element={<Profile />} />}
                    </Route>}
                    {!loginToken && <Route path="*" element={<Login />} />}
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
