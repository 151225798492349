import { Dialog, Menu, Transition } from "@headlessui/react";
import { Fragment, useState , useEffect} from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import {
  Bars3Icon,
  BellIcon,
  ChevronDownIcon,
  Cog6ToothIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Outlet } from "react-router-dom";

import { NAV_SIDEBAR_LINKS } from "../libs/navigation";

const logout = async () => {
  const data = { 'username': localStorage.getItem('user_id') }
  const response = await axios.post(`http://test-ilios-master.linkpc.net/api/logout.php`, data);
  
  localStorage.removeItem('user_id');
  localStorage.removeItem('token');

  document.location.reload();
  
}

const profile = async () => {
  document.location.href = "/profile";
}
const userNavigation = [
  { name: "Your profile", href: profile },
  { name: "Sign out", href: logout },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AppLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  useEffect(() => {
    fetchAvatar();
  }, []);
  const [avatarBlob, setAvatarBlob] = useState(null);
    const fetchAvatar = async () => {
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}`, filename: localStorage.getItem('avatar') }
        axios(`http://test-ilios-master.linkpc.net/api/files/fetchAvatar.php`, {
            method: 'GET',
            responseType: 'blob', //Force to receive data in a Blob Format
            headers: config
        })
            .then(response => {
                //Create a Blob 
                const file = new Blob(
                    [response.data],
                    { type: 'image/png' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                setAvatarBlob(fileURL);
            })
            .catch(error => {
                console.log(error);
            });
    }

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 "
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex flex-1 w-full max-w-xs mr-16">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 flex justify-center w-16 pt-5 left-full">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="w-6 h-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-col px-6 pb-4 overflow-y-auto bg-white grow gap-y-5">
                  <div className="flex items-center h-16 shrink-0">
                    <img
                      className="mt-2 w-auto h-20 w-40"
                      src={`https://test-ilios-master.linkpc.net/assets/logo/logo.png`}

                    />
                  </div>
                  <nav className="flex flex-col flex-1" style={{ padding: 0 }}>
                    <ul role="list" className="flex flex-col flex-1 " style={{ padding: 0 }}>
                      <li>
                        <ul role="list" >
                          {NAV_SIDEBAR_LINKS.map((item) => (
                            <li key={item.name}>
                              <NavLink
                                to={item.path}
                                className={({ isActive }) => {
                                  return (
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold " +
                                    (isActive
                                      ? " bg-gray-50 text-blue-600"
                                      : " text-gray-700 hover:text-blue-600 hover:bg-gray-50")
                                  );
                                }}
                              >
                                <item.icon
                                  className={classNames(
                                    item.isActive
                                      ? "text-blue-600"
                                      : "text-gray-400 group-hover:text-blue-600",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                {item.label}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li className="mt-auto">
                        <NavLink to="defaults" className="flex p-2 -mx-2 text-sm font-semibold leading-6 text-gray-700 rounded-md group gap-x-3 hover:bg-gray-50 hover:text-blue-600">
                        <Cog6ToothIcon
                            className="w-6 h-6 text-gray-400 shrink-0 group-hover:text-blue-600"
                            aria-hidden="true"
                          />
                          Defaults
                        </NavLink>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div >
        <div className="sticky top-0 z-10 flex items-center h-16 px-4 bg-white border-b border-gray-200 shadow-sm shrink-0 gap-x-4 sm:gap-x-6 sm:px-6 lg:px-8">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="w-6 h-6" aria-hidden="true" />
          </button>

          {/* Separator */}
          <div className="w-px h-6 bg-gray-200 lg:hidden" aria-hidden="true" />
          <span>
            <h3>My Trips Manager - Basic/Premium</h3>
          </span>
          <div className="flex flex-1 gap-x-4 lg:gap-x-6" style={{ 'justifyContent': 'flex-end' }}>
            <div className="flex items-center gap-x-4 lg:gap-x-6">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">View notifications</span>
                <BellIcon className="w-6 h-6" aria-hidden="true" />
              </button>

              {/* Separator */}
              <div
                className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                aria-hidden="true"
              />

              {/* Profile dropdown */}
              <Menu as="div" className="relative">
                <Menu.Button className="-m-1.5 flex items-center p-1.5">
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="w-8 h-8 rounded-full bg-gray-50"
                    src={avatarBlob}
                    alt=""
                  />
                  <span className="hidden lg:flex lg:items-center">
                    <span
                      className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                      aria-hidden="true"
                    >
                      {localStorage.getItem('user_id')}
                    </span>
                    <ChevronDownIcon
                      className="w-5 h-5 ml-2 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <Menu.Item key="profile">
                          <NavLink
                            to="profile"
                            className="block px-3 w-32 py-1 text-sm leading-6 text-gray-900"
                            
                          >
                            My Profile
                          </NavLink>
                      </Menu.Item>
                  <Menu.Item key="profile">
                          <button
                            onClick={logout}
                            className=
                              "block px-3 w-32 py-1 text-sm leading-6 text-gray-900"
                            
                          >
                            Logout
                          </button>
                      </Menu.Item>
                    
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main className="py-5">
          <div className="px-4 sm:px-6 lg:px-8">{<Outlet />}</div>
        </main>
      </div>
    </div>
  );
};

export default AppLayout;
