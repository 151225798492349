import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HeartIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { PaperClipIcon, PlusIcon } from '@heroicons/react/20/solid'
import axios from 'axios';

const AddPaymentLinkForm = ({ open, close, tour_id }) => {

    //To Add the Payment Record (API Call)
    const postRecord = async (event) => {
        event.preventDefault();
        const formdata = new FormData(document.getElementById('addForm'));
        
            const update_data = {
                title: formdata.get("title"),
                description: formdata.get("description"),
                amount: formdata.get("amount"),
                currency: formdata.get("currency")
            };
            const data = { ...update_data, 'action': 'add_payment_link', 'username': localStorage.getItem('user_id'), 'tours_id': tour_id };
            const token = localStorage.getItem('token');
            const config = { Authorization: `Bearer ${token}` };
            const response = await axios.post(`http://test-ilios-master.linkpc.net/api/stripe/addDocuments.php`, data, {
                headers: config
            });
            if (response.data.message == "Success") {
                alert("Please Save this Link :" + response.data.link);
            } else {
                alert(response.data.message);
            }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={close}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto relative w-96">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-500"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                                            <button
                                                type="button"
                                                className=""
                                                onClick={close}
                                            >
                                                <span className="sr-only">Close panel</span>
                                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="h-full overflow-y-auto bg-white p-8">
                                        <h1 className="font-bold text-violet-500">Enter Payment Link Details</h1>
                                        <div className="mt-2">
                                            <form id='addForm' onSubmit={postRecord}>
                                                <h3 className="font-medium text-indigo-900">Title</h3>
                                                <input
                                                    id="title"
                                                    name="title"
                                                    type="text"
                                                    required
                                                    placeholder="Enter Title ..."
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Description</h3>
                                                <input
                                                    id="description"
                                                    name="description"
                                                    type="text"
                                                    required
                                                    placeholder="Enter Passenger Name etc..."
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Amount</h3>
                                                <input
                                                    id="amount"
                                                    name="amount"
                                                    type="text"
                                                    inputmode="decimal"
                                                    pattern="[0-9]*[.][0-9]"
                                                    required
                                                    placeholder="Amount *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Currency</h3>
                                                <select
                                                    id="currency"
                                                    name="currency"
                                                    className="block w-full mt-2 py-2 pl-3 pr-10 mt-2 text-gray-900 border-0 rounded-md ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-50 sm:text-sm sm:leading-6"

                                                >
                                                    <option>USD</option>
                                                    <option>AED</option>
                                                    <option>INR</option>
                                                </select>
                                                <div className="mt-2 flex">
                                                    <button
                                                        type="submit"
                                                        className="flex-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default AddPaymentLinkForm;