import LoginForm from "../components/LoginForm";
import RegisterForm from "../components/RegisterForm";
import React from "react";
const LoginPage = () => {
  const [tabs, selectTab] = React.useState([
    { name: 'Login', href: '#', current: true },
    { name: 'Register', href: '#', current: false },])

  const setTab = (event, name) => {
    event.preventDefault();
    console.log(name);
    var i = 0;
    while (i < tabs.length) {
      var temp = [...tabs]
      if (tabs[i].name == name) {
        temp[i].current = true;
        selectTab(temp);
      } else {
        temp[i].current = false;
        selectTab(temp);
      }
      i++;
      console.log(i);
    }
  }
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  return (
    <div className="bg-white">
        <div className="grid h-dvh sm:grid-cols-5 gap-2">
          <div className="bg-white bg-no-repeat bg-cover col-span-3">
               <div className="flex items-center flex-col">
               <img className="mt-5 ml-5 w-3/5 h-50"
                src={`https://test-ilios-master.linkpc.net/assets/logo/logo.png`}></img>
                <h1 className="text-2xl"> Manage Your Trips With Ease...</h1>
               </div>
          </div>
          <div className="col-span-2 bg-white">
            <div>
              <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
                {tabs.map((tab, tabIdx) => (
                  <button
                    key={tab.name}
                    onClick={(e) => setTab(e, tab.name)}
                    className={classNames(
                      tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                      tabIdx === 0 ? 'rounded-l-lg' : '',
                      tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                      'group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
                    )}
                    aria-current={tab.current ? 'page' : undefined}
                  >
                    <span>{tab.name}</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        tab.current ? 'bg-indigo-500' : 'bg-white',
                        'absolute inset-x-0 bottom-0 h-0.5'
                      )}
                    />
                  </button>
                ))}
              </nav>
              {tabs[0].current == true ?
                <div className="min-w-[450px] px-8 bg-white">
                  <div className="mb-1">
                    <h1 className="text-3xl font-medium">Welcome Back!</h1>
                    <p>Please enter your credentials to sign in!</p>
                  </div>
                  <LoginForm />
                </div> : null}
              {tabs[1].current == true ?
                <div className="min-w-[450px] px-8 bg-white">
                  <div className="mb-25">
                    <h1 className="text-3xl font-medium">Register Yourself!</h1>
                    <p>Please enter your credentials to Register!</p>
                  </div>
                  <RegisterForm />
                </div> : null}
            </div>
          </div>
        </div>
    </div>
  );
};

export default LoginPage;
