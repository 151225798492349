import React, { Fragment } from 'react'
import { Image, Text, View, Page, Document, StyleSheet, Link } from '@react-pdf/renderer';
import { ToWords } from 'to-words';

const Receipt = ({receipt_data, logoBlob}) => {

    const toWords = new ToWords({
        converterOptions: {
            currency: false,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false
        }
    });

    const styles = StyleSheet.create({
        page: { fontSize: 11, paddingTop: 20, paddingLeft: 30, paddingRight: 30, paddingBottom: 50,lineHeight: 1.5, flexDirection: 'column' },

        spaceBetween: { flex: 2, flexDirection: 'row', justifyContent: 'space-between', color: "#3E3E3E" },
        
        logo: { width: 90, height: 45},

        titleContainer: { flexDirection: 'row', marginTop: 10 },

        reportTitle: { fontSize: 12, textAlign: 'center' },

        addressTitle: { fontSize: 10 },

        invoice: { fontWeight: 'bold', fontSize: 36 },

        invoiceMisc: { fontSize: 14, fontWeight: 'bold' },

        address: { fontWeight: 400, fontSize: 10 },

        footer: { position:'absolute', width:'200%', bottom:10, fontSize: 8, fontStyle: 'bold', paddingTop: 4, paddingLeft: 7, backgroundColor: receipt_data.footer_tab_color, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1},

        invoiceDetail: { fontSize: 11, fontStyle: 'bold', paddingTop: 4, paddingLeft: 7, flex: 1, height: 25, backgroundColor: receipt_data.detail_tab_color, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },

        theader: { fontSize: 11, fontStyle: 'bold', paddingTop: 4, paddingLeft: 7, flex: 0.5, height: 25, backgroundColor: receipt_data.table_tab_color, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },

        theader2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },

        tbody: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 0.5, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1, borderLeftWidth:1 },

        total: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 3, borderColor: 'whitesmoke', borderBottomWidth: 1, borderLeftWidth:1 },

        tbody2: { flex: 2, borderRightWidth: 1, },
        
        tbodybold: { fontSize: 9, fontWeight: 'bold', paddingTop: 4, paddingLeft: 7, flex: 0.5, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1, borderLeftWidth:1 }

    });

    const InvoiceTitle = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    <Image style={styles.logo} src={logoBlob} />
                </View>
                <View>
                    <Text style={styles.invoice}>PAYMENT RECEIPT</Text>
                </View>
            </View>
        </View>
    );

    const InvoiceToFrom = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View style={{ maxWidth: 200 }}>
                    <Text style={styles.invoiceMisc}>To:</Text>
                    <Text style={styles.address}>
                        {receipt_data.customer_name}
                    </Text>
                    <Text style={styles.address}>
                        {receipt_data.customer_email} , {receipt_data.customer_phone}
                    </Text>
                    {receipt_data.customer_taxid != ""?<Text style={styles.address}>
                        {receipt_data.customer_tax_id}
                    </Text>:null}
                </View>
                <View style={{ maxWidth: 200 }}>
                    <Text style={styles.invoiceMisc} >From:</Text>
                    <Text style={styles.reportTitle}>{receipt_data.company_name}</Text>
                    <Text style={styles.addressTitle}>{receipt_data.address_line_1}</Text>
                    <Text style={styles.addressTitle}>{receipt_data.address_line_2}</Text>
                    <Text style={styles.addressTitle}>{receipt_data.company_tax_id}</Text>
                </View>
            </View>
        </View>
    );

    const PaymentDetail = () => (
        <View style={styles.titleContainer}>
                <View>
                <Text> </Text>
                <Text> </Text>
                <Text style={styles.invoiceMisc}>This is to Confirm that payment of amount {receipt_data.currency}{receipt_data.amount} {"(" + toWords.convert(receipt_data.amount) + ")"} on {receipt_data.date} received through {receipt_data.method}.</Text>
                <Text> </Text>
                <Text> </Text>
                </View>
        </View>
    );

    const InvoiceNote = () => (
        <View style={styles.titleContainer}>
                <View>
                    <Text style={styles.invoiceMisc}>Note:This is a computer generated receipt and does not require signature.</Text>
                </View>
        </View>
    );

    const InvoiceFooter = () => (
            <View style={styles.footer} fixed>
                <Text >Contact Us:{receipt_data.default_contact_footer_1}</Text>
                <Text >{receipt_data.default_contact_footer_2}</Text>
            </View>
    );

    return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                <InvoiceTitle />
                <InvoiceToFrom />
                <PaymentDetail wrap/>
                <InvoiceNote wrap/>
                <InvoiceFooter fixed/>
            </Page>
        </Document>

    )
}

export default Receipt