import { Fragment, useState, useRef } from 'react';
import { PaperClipIcon, PlusIcon } from '@heroicons/react/20/solid';
import React from "react";
import axios from "axios";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Settings = () => {
    
    React.useEffect(() => {
        checkSession();
      }, []);
    
      const checkSession = async () => {
          const data = { 'username': localStorage.getItem('user_id') };
          const token = localStorage.getItem('token');
          const config = { Authorization: `Bearer ${token}` }
          const response = await axios.post(`http://test-ilios-master.linkpc.net/api/checkSession.php`, data, {
              headers: config
          });
          if (response.data.message == 'Invalid') {
            localStorage.removeItem('user_id');
            localStorage.removeItem('token');
            document.location.reload();
          }
      }

    //Tabs  
    const [tabs, selectTab] = React.useState([
        { name: 'General Defaults', href: '#', current: true },
        { name: 'Quote Report Defaults', href: '#', current: false },
        { name: 'Tour Reports Defaults', href: '#', current: false },]);

    //Set Tab
    const setTab = (event, name) => {
        event.preventDefault();
        console.log(name);
        var i = 0;
        while (i < tabs.length) {
            var temp = [...tabs]
            if (tabs[i].name == name) {
                temp[i].current = true;
                selectTab(temp);
            } else {
                temp[i].current = false;
                selectTab(temp);
            }
            i++;
            console.log(i);
        }
    }


    //Fetch General details
    const [defaults, setDefaults] = useState(null);
    React.useEffect(() => {
        fetchDefaults();
    }, []);

    const fetchDefaults = async () => {

        const data = { 'action': 'fetch_defaults_settings', 'username': localStorage.getItem('user_id') };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data, {
            headers: config
        });
        setDefaults(response.data);
        fetchLogo(response.data.logo);
    }
    
    //Form changed
    const [edited,setEdited] = useState(false);
    const handleFormChange = (event) => {
        event.preventDefault();
        setEdited(true);
        setDefaults({
            ...defaults,
            [event.target.name]: event.target.value,
        });
    }

    const postGeneralForm = async (event) => {
        event.preventDefault();
        const data = { ...defaults, 'action': 'update_general_defaults', 'username': localStorage.getItem('user_id'),'logo':logoUploaded==true?logoFileServer:defaults.logo };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/updateDocuments.php`, data, {
            headers: config
        });
        if (response.data.message == "Success") {
            document.location.reload();
        } else {
            alert(response.data.message);
        }
    }

    const postQuoteForm = async (event) => {
        event.preventDefault();
        if(edited == false)
            return;
        const data = { ...defaults, 'action': 'update_quote_defaults', 'username': localStorage.getItem('user_id') };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/updateDocuments.php`, data, {
            headers: config
        });
        if (response.data.message == "Success") {
            document.location.reload();
        } else {
            alert(response.data.message);
        }
    }

    const postInvoiceForm = async (event) => {
        event.preventDefault();
        if(edited == false)
            return;
        const data = { ...defaults, 'action': 'update_invoice_defaults', 'username': localStorage.getItem('user_id') };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/updateDocuments.php`, data, {
            headers: config
        });
        if (response.data.message == "Success") {
            document.location.reload();
        } else {
            alert(response.data.message);
        }
    }

    //To Store Uploaded Files
    const [logoFile, SetLogoFile] = useState("");
    const SetLogoFileHelper = (e) => {
        e.preventDefault();
        SetLogoFile(e.target.files[0]);
    }
    //To store name of server files
    const [logoFileServer, SetLogoFileServer] = useState("default");
    //Use to set the button for upload of files or display status as uploaded.
    const [logoUploaded, setLogoUploaded] = useState(false);
    //File Input using custom button style
    const hiddenFileInputLogo = useRef(null);
    const handleClickLogo = () => {
        hiddenFileInputLogo.current.click();
        setLogoUploaded(false);
    };
    //Handle Upload Call
    const uploadLogo = async () => {
        var fileSize = logoFile.size / 1024;
        console.log(logoFile);
        if (fileSize > 1024) {
            alert('File Size Exceeds 1MB Limit.');
        }
        else {
            const formData = new FormData();
            formData.append('image', logoFile);
            const token = localStorage.getItem('token');
            const config = { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data', 'username': localStorage.getItem('user_id') };
            const response = await axios.post('http://test-ilios-master.linkpc.net/api/files/uploadLogo.php', formData, {
                headers: config
            });
            if (response.data.message == "Success") {
                setLogoUploaded(true);
                SetLogoFileServer(response.data.name);
            }
            else {
                alert('Error uploading file');
            }
        }
    }
    
    const [logoBlob,setLogoBlob] = useState(null);
    const fetchLogo = async (file) => {
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}`, filename: file }
        axios(`http://test-ilios-master.linkpc.net/api/files/fetchLogo.php`, {
            method: 'GET',
            responseType: 'blob', //Force to receive data in a Blob Format
            headers: config
        })
            .then(response => {
                //Create a Blob 
                const file = new Blob(
                    [response.data],
                    { type: 'image/png' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                setLogoBlob(fileURL);
                console.log(fileURL);
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <div className="bg-white">
                <div className="hidden sm:block">
                <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
                    {tabs.map((tab, tabIdx) => (
                        <button
                            key={tab.name}
                            onClick={(e) => setTab(e, tab.name)}
                            className={classNames(
                                tab.current ? 'text-gray-100 bg-indigo-900' : 'text-gray-900 hover:text-gray-700',
                                tabIdx === 0 ? 'rounded-l-lg' : '',
                                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                                'group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-center text-xs font-medium hover:bg-gray-50 hover:text-gray-900 focus:z-10'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                        >
                            <span>{tab.name}</span>
                        </button>
                    ))}
                </nav>
            </div>
            {((tabs[0].current == true) && (defaults != null)) ?
                <div>
                    
                        <div className="space-y-12">
                            <div className="border-b border-gray-900/10 pb-12">

                                <div className="mt-10 mr-10 ml-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                    <div className="col-span-full">
                                        <label htmlFor="mobile" className="block text-sm font-medium leading-6 text-gray-900">
                                            Logo
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex justify-evenly">
                                                <img width={90} height={40} src={logoBlob}></img>
                                                <button className="text-base" onClick={handleClickLogo}>
                                                    <div className='flex'><PaperClipIcon className="h-5 w-5 pl-2 flex-shrink-0 text-gray-400" aria-hidden="true" /> Select File to Attach</div>
                                                </button>
                                                <text className='p-1 text-xs text-indigo-500' style={{ 'max-width': '10ch', 'text-overflow': 'ellipsis', 'overflow': 'hidden', 'white-space': 'nowrap' }}>{logoFile.name}</text>
                                                <input type='file' accept='image/png, image/jpeg, image/jpg' id='logo_file' name='logo_file' onChange={(e) => { SetLogoFileHelper(e) }} ref={hiddenFileInputLogo} style={{ display: 'none' }}>
                                                </input>
                                                {logoUploaded == false ?
                                                <button
                                                    type="button"
                                                    className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    onClick={uploadLogo}
                                                >
                                                    Upload
                                                </button>
                                                :
                                                <text className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                    Uploaded
                                                </text>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-span-full">
                                        <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
                                            Address Line 1
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="address_line_1"
                                                id="address_line_1"
                                                placeholder='Info to display at Company Details in  PDF'
                                                required
                                                maxLength={50}
                                                value={defaults.address_line_1}
                                                onChange={handleFormChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-full">
                                        <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
                                            Address Line 2
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="address_line_2"
                                                id="address_line_2"
                                                placeholder='Info to display at Company details in PDF'
                                                required
                                                maxLength={50}
                                                value={defaults.address_line_2}
                                                onChange={handleFormChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-full">
                                        <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
                                            Contact Line 1
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="footer_contact_line_1"
                                                id="footer_contact_line_1"
                                                placeholder='Contact Info to display at bottom of PDF'
                                                required
                                                maxLength={50}
                                                value={defaults.footer_contact_line_1}
                                                onChange={handleFormChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-full">
                                        <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
                                            Contact Line 2
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="footer_contact_line_2"
                                                id="footer_contact_line_2"
                                                placeholder='Contact Info to display at bottom of PDF'
                                                required
                                                maxLength={50}
                                                value={defaults.footer_contact_line_2}
                                                onChange={handleFormChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                            onClick={postGeneralForm}
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button>
                    </div>
                </div> : null}
            {((tabs[1].current == true) && (defaults != null))?
                <div>
                    <form id="quoteForm">
                        <div className="space-y-12">
                            <div className="border-b border-gray-900/10 pb-12">

                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                    <div className="sm:col-span-3">
                                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                            Default Inclusion
                                        </label>
                                        <div className="mt-2">
                                            <textarea
                                                id="default_inclusion"
                                                name="default_inclusion"
                                                rows={10}
                                                onChange={handleFormChange}
                                                required
                                                placeholder="Inclusion"
                                                className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                            >
                                                {defaults.default_inclusion}
                                            </textarea>
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="government_id" className="block text-sm font-medium leading-6 text-gray-900">
                                            Default Exclusion
                                        </label>
                                        <div className="mt-2">
                                            <textarea
                                                id="default_exclusion"
                                                name="default_exclusion"
                                                rows={10}
                                                onChange={handleFormChange}
                                                required
                                                placeholder="Exclusion"
                                                className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                            >
                                                {defaults.default_exclusion}
                                            </textarea>

                                        </div>
                                    </div>

                                    <div className="col-span-full">
                                        <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
                                            Quotation Notes
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="quote_notes"
                                                id="quote_notes"
                                                placeholder='Notes for any special Comment.'
                                                required
                                                value={defaults.quote_notes}
                                                onChange={handleFormChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-2">
                                        <label htmlFor="detail-color" className="block text-sm font-medium leading-6 text-gray-900">
                                            Detail Tab Color
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="color"
                                                name="quote_detail_tab_color"
                                                id="quote_detail_tab_color"
                                                required
                                                value={defaults.quote_detail_tab_color}
                                                onChange={handleFormChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-2">
                                        <label htmlFor="table-color" className="block text-sm font-medium leading-6 text-gray-900">
                                            Table Head Color
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="color"
                                                name="quote_table_tab_color"
                                                id="quote_table_tab_color"
                                                required
                                                value={defaults.quote_table_tab_color}
                                                onChange={handleFormChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-2">
                                        <label htmlFor="footer-color" className="block text-sm font-medium leading-6 text-gray-900">
                                            Contact Tab Color
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="color"
                                                name="quote_footer_tab_color"
                                                id="quote_footer_tab_color"
                                                required
                                                value={defaults.quote_footer_tab_color}
                                                onChange={handleFormChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                            onClick={postQuoteForm}
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button>
                    </div>
                </div> : null}
            {((tabs[2].current == true) && (defaults != null)) ?
                <div>
                    <form id="tourForm">
                        <div className="space-y-12">
                            <div className="border-b border-gray-900/10 pb-12">

                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                        <label htmlFor="company_name" className="block text-sm font-medium leading-6 text-gray-900">
                                            Paymnet Bank Name
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="payment_bank_name"
                                                id="payment_bank_name"
                                                placeholder="Bank Name "
                                                required
                                                value={defaults.payment_bank_name}
                                                onChange={handleFormChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="mobile" className="block text-sm font-medium leading-6 text-gray-900">
                                            Payment Bank Branch
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="payment_bank_branch"
                                                id="payment_bank_branch"
                                                required
                                                value={defaults.payment_bank_branch}
                                                placeholder='Bank Branch Location'
                                                onChange={handleFormChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                            Bank Code Title
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="payment_bank_code_title"
                                                id="payment_bank_code_title"
                                                required
                                                value={defaults.payment_bank_code_title}
                                                placeholder='IFSC or MICR or IBAN or SWIFT etc.'
                                                onChange={handleFormChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="government_id" className="block text-sm font-medium leading-6 text-gray-900">
                                            Payment Bank Code
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="payment_bank_code"
                                                name="payment_bank_code"
                                                type="text"
                                                required
                                                value={defaults.payment_bank_code}
                                                onChange={handleFormChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-full">
                                        <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
                                            Account Number
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="payment_account_number"
                                                id="payment_account_number"
                                                required
                                                value={defaults.payment_account_number}
                                                onChange={handleFormChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-2">
                                        <label htmlFor="detail-color" className="block text-sm font-medium leading-6 text-gray-900">
                                            Detail Tab Color
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="color"
                                                name="invoice_detail_tab_color"
                                                id="invoice_detail_tab_color"
                                                required
                                                value={defaults.invoice_detail_tab_color}
                                                onChange={handleFormChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-2">
                                        <label htmlFor="table-color" className="block text-sm font-medium leading-6 text-gray-900">
                                            Table Head Color
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="color"
                                                name="invoice_table_tab_color"
                                                id="invoice_table_tab_color"
                                                required
                                                value={defaults.invoice_table_tab_color}
                                                onChange={handleFormChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-2">
                                        <label htmlFor="footer-color" className="block text-sm font-medium leading-6 text-gray-900">
                                            Contact Tab Color
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="color"
                                                name="invoice_footer_tab_color"
                                                id="invoice_footer_tab_color"
                                                required
                                                value={defaults.invoice_footer_tab_color}
                                                onChange={handleFormChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-full">
                                        <label htmlFor="government_id" className="block text-sm font-medium leading-6 text-gray-900">
                                            Default Terms & Conditions For Itinernary 
                                        </label>
                                        <div className="mt-2">
                                            <textarea
                                                id="default_itinernary_terms"
                                                name="default_itinernary_terms"
                                                rows={10}
                                                onChange={handleFormChange}
                                                required
                                                placeholder="Enter Default TnC's to be used for Itinernary Report. "
                                                className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                            >
                                                {defaults.default_itinernary_terms}
                                            </textarea>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                            onClick={postInvoiceForm}
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button>
                    </div>
                </div> : null}
        </div>
    )
}

export default Settings;