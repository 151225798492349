import { Fragment, useState, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HeartIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { PaperClipIcon, PlusIcon } from '@heroicons/react/20/solid'
import axios from 'axios';
import Select from "react-tailwindcss-select";

const AddWalletBalanceForm = ({ open, close }) => {

  //To Store Uploaded Files
  const [transactionFile, SetTransactionFile] = useState("");
  
  const SetTransactionFileHelper = (e) => {
    e.preventDefault();
    SetTransactionFile(e.target.files[0]);
  }
  
  //To store name of server files
  const [transactionFileServer, SetTransactionFileServer] = useState("default.pdf");

  //To Add the Passenger Record (API Call)
  const postRecord = async (event) => {
    event.preventDefault();
    const formdata = new FormData(document.getElementById('addForm'));
    const update_data = {
      amount: formdata.get("amount"),
      transaction_date: formdata.get("transaction_date"),
      transaction_number: formdata.get("transaction_number"),
      source_bank: formdata.get("source_bank"),
      receipt_file: transactionFileServer
    };
    const data = { ...update_data, 'action': 'add_wallet_transaction', 'username': localStorage.getItem('user_id') };
    const token = localStorage.getItem('token');
    const config = { Authorization: `Bearer ${token}` };
    const response = await axios.post(`http://test-ilios-master.linkpc.net/api/addDocuments.php`, data, {
      headers: config
    });
    if (response.data.message == "Success") {
      document.location.reload();
    } else {
      alert(response.data.message);
    }
  }

  //Use to set the button for upload of files or display status as uploaded.
  const [transactionUploaded, setTransactionUploaded] = useState(false);

  //File Input using custom button style
  const hiddenFileInputTransaction = useRef(null);
  const handleClickTransaction = event => {
    hiddenFileInputTransaction.current.click();
    setTransactionUploaded(false);
  };

  //Handle Upload Call
  const uploadTransaction = async () => {
    var fileSize = transactionFile.size / 1024;
    console.log(transactionFile);
    if (fileSize > 1024) {
      alert('File Size Exceeds 1MB Limit.');
    }
    else {
      const formData = new FormData();
        formData.append('pdf', transactionFile);
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data'};
        const response = await axios.post('http://test-ilios-master.linkpc.net/api/files/uploadWalletTransaction.php', formData, {
          headers: config
        });
        if(response.data.message == "Success")
        {
          setTransactionUploaded(true);
          SetTransactionFileServer(response.data.name);
        }
        else
        {
          alert('Error uploading file'); 
        }
      
    }

  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-96">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className=""
                        onClick={close}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full overflow-y-auto bg-white p-8">
                    <h1 className="font-bold text-violet-500">Enter New Top-Up Details</h1>
                    <div className="mt-2">
                      <form id='addForm'>
                        <h3 className="font-medium mt-2 text-indigo-900">Amount</h3>
                        <input
                          id="amount"
                          name="amount"
                          type="number"
                          required
                          placeholder="Amount *"
                          className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Date of Transaction</h3>
                        <input
                          id="transaction_date"
                          name="transaction_date"
                          type="date"
                          required
                          className="mt-2 w-full"
                        >
                        </input>
                        <h3 className="font-medium mt-2 text-indigo-900">Transaction Number</h3>
                        <input
                          id="transaction_number"
                          name="transaction_number"
                          type="text"
                          required
                          placeholder="Transaction Number *"
                          className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Source Bank</h3>
                        <input
                          id="source_bank"
                          name="source_bank"
                          type="text"
                          required
                          placeholder="Source Bank Name*"
                          className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Upload Receipt</h3>
                        <ul role="list" className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                          <li className="flex items-center justify-between py-3">
                            <div className="flex items-center">
                              <img
                                src="https://png.pngtree.com/png-vector/20190215/ourmid/pngtree-vector-receipt-icon-png-image_530217.jpg"
                                alt=""
                                className="h-8 w-8 rounded-full"
                              />
                              <p className="ml-4 text-sm font-medium text-gray-900">Receipt</p>
                              <button className="text-xs" onClick={handleClickTransaction}>
                                <div className='flex'><PaperClipIcon className="h-5 w-5 pl-2 flex-shrink-0 text-gray-400" aria-hidden="true" /> Attach</div>
                              </button>
                              <text className='p-1 text-xs text-indigo-500' style={{ 'max-width': '10ch', 'text-overflow': 'ellipsis', 'overflow': 'hidden', 'white-space': 'nowrap' }}>{transactionFile.name}</text>
                              <input type='file' accept='image/*,application/pdf' id='transaction_file' name='transaction_file' onChange={(e) => { SetTransactionFileHelper(e) }} ref={hiddenFileInputTransaction} style={{ display: 'none' }}>
                              </input>
                            </div>
                            {transactionUploaded == false ?
                              <button
                                type="button"
                                className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={uploadTransaction}
                              >
                                Upload
                              </button>
                              :
                              <text className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                Uploaded
                              </text>}
                          </li>
                        </ul>
                        </form>
                        <div className="mt-2 flex">
                          <button
                            onClick={postRecord}
                            className="flex-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Save
                          </button>
                        </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default AddWalletBalanceForm;