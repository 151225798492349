import axios from "axios";
import React from "react";
import AddCustomerForm from "../components/sliderForms/addForms/addCustomerForm";
import EditCustomerForm from "../components/sliderForms/editForms/editCustomerForm";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/system';
import {
  TablePagination,
  tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

const CustomersList = () => {

  React.useEffect(() => {
    checkSession();
  }, []);

  const checkSession = async () => {
      const data = { 'username': localStorage.getItem('user_id') };
      const token = localStorage.getItem('token');
      const config = { Authorization: `Bearer ${token}` }
      const response = await axios.post(`http://test-ilios-master.linkpc.net/api/checkSession.php`, data, {
          headers: config
      });
      if (response.data.message == 'Invalid') {
        localStorage.removeItem('user_id');
        localStorage.removeItem('token');
        document.location.reload();
      }
  }

  /** Define the Dynamic Objects To be Used. */
  const [documents, setDocument] = React.useState(false);
  const [documentFiltered, setDocumentFiltered] = React.useState(false);
  const [documentList, setDocumentList] = React.useState(false);

  const [editCustomerData, setEditCustomerData] = React.useState(false);

  //Slider for Adding Customer
  const [addCustomerSlider, setAddCustomerSlider] = React.useState(false);
  const toggleAddCustomerSlider = () => setAddCustomerSlider(!addCustomerSlider);

  //Slider for Editing Customer
  const [editCustomerSlider, setEditCustomerSlider] = React.useState(false);
  const toggleEditCustomerSliderHelper = () => setEditCustomerSlider(!editCustomerSlider);
  const toggleEditCustomerSlider = (row) => {
    setEditCustomerData(row);
    toggleEditCustomerSliderHelper();
  };
  
  const navigate = useNavigate();

  React.useEffect(() => {
    fetchDocument();
  }, []);

  const fetchDocument = async () => {
    
    const data = { 'action':'fetch_customers', 'username': localStorage.getItem('user_id') };
    const token = localStorage.getItem('token');
    const config = { Authorization: `Bearer ${token}`}
    const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data,{
      headers:config
    });
    setDocument(response.data);
    setDocumentFiltered(response.data);
    setDocumentList(response.data);
  }


  const [searchQuery, SetSearchQuery] = React.useState("");

  const filterByQuery = (query) => {
    // Create copy of item list
    if (Array.isArray(documents) && query !== "") {
      var updatedList = [...documentFiltered];
      // Trigger render with updated values
      setDocumentList(updatedList.filter((item) => {
        return item.name ? item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 : null;
      }));
    } else {
      setDocumentList(documentFiltered);
    }
  }

  React.useEffect(() => {
    filterByQuery(searchQuery);
  }, [documentFiltered])

  const filterBySearch = (event) => {
    // Access input value
    event.preventDefault();
    const query = event.target.value;
    SetSearchQuery(query);
    filterByQuery(query);
  };
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  
  //Handle Pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
};

  const blue = {
    50: '#F0F7FF',
    200: '#A5D8FF',
    400: '#3399FF',
    900: '#003A75',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  
  const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      padding: 4px 0;
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      font-family: 'IBM Plex Sans', sans-serif;
      padding: 2px 0 2px 4px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 6px; 
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      transition: all 100ms ease;
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
        border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
      }
  
      &:focus {
        outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
        border-color: ${blue[400]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      display: flex;
      gap: 6px;
      border: transparent;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      display: flex;
      align-items: center;
      padding: 0;
      border: transparent;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      transition: all 120ms ease;
  
      > svg {
        font-size: 22px;
      }
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
        border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
      }
  
      &:focus {
        outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
        border-color: ${blue[400]};
      }
  
      &:disabled {
        opacity: 0.3;
        &:hover {
          border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
          background-color: transparent;
        }
      }
    }
    `,
  );

  return (
    <div className="mt-4 ">
      <AddCustomerForm open={addCustomerSlider} close={toggleAddCustomerSlider}></AddCustomerForm>
      <EditCustomerForm open={editCustomerSlider} close={toggleEditCustomerSlider} inputs={editCustomerData}></EditCustomerForm>
      <div className="flex justify-between">
        <h2 className="text-xl pl-0 p-4 font-semibold leading-6 text-gray-900">Customers List</h2>
        <div style={{ 'width': '70%', 'direction': 'ltr' }} class="input-group rounded">
          <input type="search" class="h-full w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" onChange={filterBySearch} />
        </div><button
          type="button"
          className="flex rounded-full bg-blue-500 px-2 py-2 sm:py-5 text-xs sm:text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          onClick={toggleAddCustomerSlider}
        >New Customer
        </button>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                      Email
                    </th>
                    <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                      Phone
                    </th>
                    <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                      Type
                    </th>
                    <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {Array.isArray(documentList) ?  (rowsPerPage > 0
                            ? documentList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : documentList
                        ).map((row) => (
                    <tr key={row.id}>
                      <td className="whitespace-nowrap text-sm text-gray-900">{row.name}</td>
                      <td className="whitespace-nowrap text-sm text-gray-900">{row.email}</td>
                      <td className="whitespace-nowrap text-sm text-gray-900">+{row.mobile_country_code}-{row.mobile}</td>
                      <td className="whitespace-nowrap text-sm text-gray-900">{row.type}</td>
                      <td className="flex justify-evenly"> 
                        <button onClick={() => toggleEditCustomerSlider(row)} className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600" >Edit</button> 
                        <button onClick={() => navigate(`/toursCustomerSpecific/${row.id}`)} className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600" >Open Tours</button>
                      </td>
                    </tr>
                  )) : null}
                </tbody>
                <tfoot>
                        <tr>
                            <CustomTablePagination
                                rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
                                colSpan={5}
                                count={documentList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                slotProps={{
                                    select: {
                                        'aria-label': 'rows per page',
                                    },
                                    actions: {
                                        showFirstButton: true,
                                        showLastButton: true,
                                        slots: {
                                          firstPageIcon: FirstPageRoundedIcon,
                                          lastPageIcon: LastPageRoundedIcon,
                                          nextPageIcon: ChevronRightRoundedIcon,
                                          backPageIcon: ChevronLeftRoundedIcon,
                                        },
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </tr>
                    </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomersList;
