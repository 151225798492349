import { Fragment, useState } from 'react'
import { Dialog, Popover, Tab, Transition } from '@headlessui/react'
import { Bars3Icon, MagnifyingGlassIcon, ShoppingCartIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, WalletIcon } from '@heroicons/react/20/solid'
import AddWalletBalanceForm from '../components/sliderForms/addForms/addWalletBalanceForm'
import React from "react";
import axios from "axios";

const wallet = {
  currency_symbol: '$',
  balance: '250'
}
const orders = [
  {
    number: 'WU88191111',
    date: 'January 22, 2021',
    invoiceHref: '#',
    total: '$104.00',
    type: 'Credit',
    status: 'In-Progress'
  },
  {
    number: 'WU88191111',
    date: 'January 22, 2021',
    invoiceHref: '#',
    total: '$104.00',
    type: 'Debit',
    status: 'Completed'
  },
  // More orders...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export default function Example() {

  React.useEffect(() => {
    checkSession();
  }, []);

  const checkSession = async () => {
      const data = { 'username': localStorage.getItem('user_id') };
      const token = localStorage.getItem('token');
      const config = { Authorization: `Bearer ${token}` }
      const response = await axios.post(`http://test-ilios-master.linkpc.net/api/checkSession.php`, data, {
          headers: config
      });
      if (response.data.message == 'Invalid') {
        localStorage.removeItem('user_id');
        localStorage.removeItem('token');
        document.location.reload();
      }
  }

  //Tabs  
  const [tabs, selectTab] = React.useState([
    { name: 'Wallet', href: '#', current: true },
    { name: 'Transactions', href: '#', current: false },]);

  //Slider for Transfer Hotel
  const [addBalanceSlider, setAddBalanceSlider] = useState(false);
  const toggleAddBalanceSlider = () => setAddBalanceSlider(!addBalanceSlider);

  //Set Tab
  const setTab = (event, name) => {
    event.preventDefault();
    console.log(name);
    var i = 0;
    while (i < tabs.length) {
      var temp = [...tabs]
      if (tabs[i].name == name) {
        temp[i].current = true;
        selectTab(temp);
      } else {
        temp[i].current = false;
        selectTab(temp);
      }
      i++;
      console.log(i);
    }
  }
  
  //Fetch Wallet details
  const [wallet, setWallet] = useState(null);
  React.useEffect(() => {
      fetchWallet();
    }, []);
  
  const fetchWallet = async () => {
  
      const data = { 'action':'fetch_wallet', 'username': localStorage.getItem('user_id')};
      const token = localStorage.getItem('token');
      const config = { Authorization: `Bearer ${token}`}
      const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data,{
        headers:config
      });
      console.log(response.data);
      setWallet(response.data)
    }
     
  //Fetch Transactions details
    const [transactions, setTransactions] = useState(null);
    React.useEffect(() => {
        fetchTransactions();
      }, []);
    
    const fetchTransactions = async () => {
    
        const data = { 'action':'fetch_wallet_transaction', 'username': localStorage.getItem('user_id')};
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}`}
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data,{
          headers:config
        });
        setTransactions(response.data);
    }

    const openReceipt = async(file) => {
      const token = localStorage.getItem('token');
      const config = { Authorization: `Bearer ${token}`,filename:file}
      axios(`http://test-ilios-master.linkpc.net/api/files/fetchWalletReceipt.php`, {
          method: 'GET',
          responseType: 'blob', //Force to receive data in a Blob Format
          headers: config
      })
      .then(response => {
      //Create a Blob from the PDF Stream
          const file = new Blob(
            [response.data], 
            {type: 'application/pdf'});
      //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
          window.open(fileURL);
      })
      .catch(error => {
          console.log(error);
      });
  }

  return (
    <div className="bg-white">
      <div className="hidden sm:block">
        <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <button
              key={tab.name}
              onClick={(e) => setTab(e, tab.name)}
              className={classNames(
                tab.current ? 'text-gray-100 bg-indigo-900' : 'text-gray-900 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                'group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-center text-xs font-medium hover:bg-gray-50 hover:text-gray-900 focus:z-10'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              <span>{tab.name}</span>
            </button>
          ))}
        </nav>
      </div>
      {tabs[0].current == true ?
        <div>
          <AddWalletBalanceForm open={addBalanceSlider} close={toggleAddBalanceSlider}></AddWalletBalanceForm>
          <div className="flex justify-between grid grid-cols-7 ">
            <h2 className="text-2xl p-4 mt-4 col-span-6 font-bold text-left tracking-tight text-green-500 sm:text-2xl">{wallet == null?' Error Please Login Again. ':wallet.currency + " " + wallet.wallet_balance } </h2>
            <button
              type="button"
              className="rounded-md bg-blue-600 px-2 py-2 sm:py-5 text-xs sm:text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={toggleAddBalanceSlider}
            >Add Balance
            </button>
          </div>
        </div> : null}
      {tabs[1].current == true ?
        <div>
          <div className="max-w-xl">
            <h1 className="text-2xl mt-4 font-bold text-left tracking-tight text-gray-900 sm:text-3xl">Transaction History</h1>
          </div>
          <div className="mt-4">
            {Array.isArray(transactions) && transactions.map((order) => (
              <div key={order.id}>
                <div className={order.type == "Credit" ? "rounded-lg bg-green-50 px-4 py-6 sm:flex sm:items-center sm:justify-between sm:space-x-6 sm:px-6 lg:space-x-8" :
                  "rounded-lg bg-red-50 px-4 py-6 sm:flex sm:items-center sm:justify-between sm:space-x-6 sm:px-6 lg:space-x-8"}>
                  <dl className="flex-auto divide-gray-200 text-sm text-gray-600 sm:grid sm:grid-cols-6 sm:gap-x-3 sm:space-y-0 sm:divide-y-0 lg:w-1/2 lg:gap-x-8">
                    <div className="flex justify-between sm:block">
                      <dt className="font-medium text-gray-900">Date placed</dt>
                      <dd className="sm:mt-1">{order.transaction_date}</dd>
                    </div>
                    <div className="flex justify-between pt-6 sm:block sm:pt-0">
                      <dt className="font-medium text-gray-900">Order number</dt>
                      <dd className="sm:mt-1">{order.transaction_number}</dd>
                    </div>
                    <div className="flex justify-between pt-6 font-medium text-gray-900 sm:block sm:pt-0">
                      <dt>Total amount</dt>
                      <dd className="sm:mt-1">{order.amount}</dd>
                    </div>
                    <div className="flex justify-between pt-6 font-medium text-gray-900 sm:block sm:pt-0">
                      <dt>Transaction Type</dt>
                      <dd className="sm:mt-1">{order.type}</dd>
                    </div>
                    <div className="flex justify-between pt-6 font-medium text-gray-900 sm:block sm:pt-0">
                      <dt>Status</dt>
                      <dd className="sm:mt-1">{order.status}</dd>
                    </div>
                    <div className="flex justify-between pt-6 font-medium text-gray-900 sm:block sm:pt-0">
                      <dt>Receipt</dt>
                      <dd className="sm:mt-1">
                      <button onClick={()=> openReceipt(order.receipt_file)} className="rounded-md border border-gray-300 bg-white  text-sm font-medium text-gray-700 shadow-sm hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto"> View </button>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            ))}
          </div>
        </div> : null}

    </div>
  )
}