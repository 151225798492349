import React, { useState } from 'react';
import jsPDF from 'jspdf';

const ImageToPdfConverter = () => {
    const [pdf, setPdf] = useState(null);
    const [imageFiles, setImageFiles] = useState([]);

    // drag state
    const [dragActive, setDragActive] = React.useState(false);
    // ref
    const inputRef = React.useRef(null);

    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const files = e.dataTransfer.files;
            if (files.length > 0) {
                const updatedFiles = [...imageFiles];
                for (let i = 0; i < files.length; i++) {
                    updatedFiles.push(files[i]);
                }
                setImageFiles(updatedFiles);
            }
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    const handleImageUpload = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const updatedFiles = [...imageFiles];
            for (let i = 0; i < files.length; i++) {
                updatedFiles.push(files[i]);
            }
            setImageFiles(updatedFiles);
        }
    };

    const convertToPdf = () => {
        if (imageFiles.length === 0) {
            alert('Please upload one or more images to convert to PDF.');
            return;
          }
      
          const pdfDoc = new jsPDF();
    const pageWidth = pdfDoc.internal.pageSize.getWidth();
    const pageHeight = pdfDoc.internal.pageSize.getHeight();
    const a4Width = 210; // A4 width in mm
    const a4Height = 297; // A4 height in mm

    let yOffset = 10;
    imageFiles.forEach((file, index) => {
      console.log(index);  
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          let imgWidth = a4Width - 20; // Adjust margin
          let imgHeight = (img.height * imgWidth) / img.width;

          // Check if image height exceeds A4 height, then adjust dimensions
          if (imgHeight > a4Height - 20) {
            imgHeight = a4Height - 20; // Adjust margin
            imgWidth = (img.width * imgHeight) / img.height;
          }

          // Add new page for each image
          if (index !== 0) {
            pdfDoc.addPage();
            yOffset = 10; // Reset yOffset for new page
          }

          pdfDoc.addImage(img, 'JPEG', 10, yOffset, imgWidth, imgHeight);
          yOffset += imgHeight + 10;

          // Check if next image will fit on the current page, if not, add a page break
          if (yOffset + imgHeight > pageHeight) {
            pdfDoc.addPage();
            yOffset = 10; // Reset yOffset for new page
          }

          // Set PDF blob URL when all images are added
          if (index === imageFiles.length - 1) {
            setPdf(pdfDoc.output('bloburl'));
          }
        };
      };
    });
    };

    const handleDownload = () => {
        const a = document.createElement('a');
        a.href = pdf;
        a.download = 'converted_pdf.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <div>
            <div className=" mt-4 flex justify-center">
            <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                <input ref={inputRef} type="file" id="input-file-upload" accept="image/*" multiple={true} onChange={handleImageUpload} />
                <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                    {imageFiles.length == 0?<div>
                        <p>Drag and drop your file here or</p>
                        <button className="upload-button" onClick={onButtonClick}>Upload a file</button>
                    </div>:
                    <div>
                        {imageFiles.map((file)=>(
                            <p>{file.name}</p>
                        ))}
                    </div>}
                </label>
                {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
            </form>
            </div>
            <div className="mt-4 flex justify-between">
                <button className="rounded-full bg-blue-600 px-2 py-2 sm:py-5 text-xs sm:text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600" onClick={convertToPdf}>Convert Images to PDF</button>
                {pdf?<button className="rounded-full bg-blue-600 px-2 py-2 sm:py-5 text-xs sm:text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600" onClick={handleDownload} >
                    Download PDF
                </button>:null}
            </div>
        </div>
    );
};

export default ImageToPdfConverter;
