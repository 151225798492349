import { ArrowUpOnSquareIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import AddPassengerForm from "../components/sliderForms/addForms/addPassengerForm";
import EditPassengerForm from "../components/sliderForms/editForms/editPassengerForm";
import AddFlightForm from "../components/sliderForms/addForms/addFlightForm";
import EditFlightForm from "../components/sliderForms/editForms/editFlightForm";
import AddHotelForm from "../components/sliderForms/addForms/addHotelForm";
import EditHotelForm from "../components/sliderForms/editForms/editHotelForm";
import AddSightseeingForm from "../components/sliderForms/addForms/addSighseeingForm";
import EditSightseeingForm from "../components/sliderForms/editForms/editSightseeingForm";
import AddTransferForm from "../components/sliderForms/addForms/addTransferForm";
import EditTransferForm from "../components/sliderForms/editForms/editTransferForm";
import { HiOutlineDownload, HiOutlinePrinter } from 'react-icons/hi';
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import Invoice from "../components/reports/invoice";
import Itinernary from "../components/reports/itinernary";
import Receipt from "../components/reports/receipt";
import AddPaymentForm from "../components/sliderForms/addForms/addPaymentForm";
import AddTicketForm from "../components/sliderForms/addForms/addTicketForm";
import ModalTourShareWithCustomer from "../components/popUp/tourShareWithCustomer";
import HotelVoucher from "../components/reports/hotelVoucher";
import AddHotelVoucherForm from "../components/sliderForms/addForms/addHotelVoucher";
import AddSightseeingVoucherForm from "../components/sliderForms/addForms/addSightseeingVoucher";
import SightseeingVoucher from "../components/reports/sightseeingVoucher";
import AddPaymentLinkForm from "../components/sliderForms/addForms/addPaymentLinkSlider";
const Tour = () => {

    React.useEffect(() => {
        checkSession();
      }, []);
    
      const checkSession = async () => {
          const data = { 'username': localStorage.getItem('user_id') };
          const token = localStorage.getItem('token');
          const config = { Authorization: `Bearer ${token}` }
          const response = await axios.post(`http://test-ilios-master.linkpc.net/api/checkSession.php`, data, {
              headers: config
          });
          if (response.data.message == 'Invalid') {
            localStorage.removeItem('user_id');
            localStorage.removeItem('token');
            document.location.reload();
          }
      }

    const [tabs, selectTab] = React.useState([
        { name: 'Tour Details', href: '#', current: true },
        { name: 'Passengers', href: '#', current: false },
        { name: 'Flight Details', href: '#', current: false },
        { name: 'Hotel Details', href: '#', current: false },
        { name: 'Sightseeing Details', href: '#', current: false },
        { name: 'Land Transfers', href: '#', current: false },
        { name: 'Reports', href: '#', current: false },]);
    const [reportTabs, selectReportTab] = React.useState([
        { name: 'Itinernary', href: '#', current: true },
        { name: 'Invoice', href: '#', current: false },
        { name: 'Payments', href: '#', current: false },
        { name: 'Tickets', href: '#', current: false },
        { name: 'Margin Sheet', href: '#', current: false }])
    const [documentList, setDocumentList] = React.useState(false);

    //Slider for Adding Passenger
    const [addPassengerSlider, setAddPassengerSlider] = React.useState(false);
    const toggleAddPassengerSlider = () => setAddPassengerSlider(!addPassengerSlider);

    //Slider for Editing Passenger
    const [editPassengerSlider, setEditPassengerSlider] = React.useState(false);
    const toggleEditPassengerSliderHelper = () => setEditPassengerSlider(!editPassengerSlider);
    const [editPassengerData, setEditPassengerData] = React.useState(false);
    const toggleEditPassengerSlider = (row) => {
        setEditPassengerData(row);
        toggleEditPassengerSliderHelper();
    };

    //Slider for Adding Flights
    const [addFlightSlider, setAddFlightSlider] = React.useState(false);
    const toggleAddFlightSlider = () => setAddFlightSlider(!addFlightSlider);

    //Slider for Editing Flights
    const [editFlightSlider, setEditFlightSlider] = React.useState(false);
    const toggleEditFlightSliderHelper = () => setEditFlightSlider(!editFlightSlider);
    const [editFlightData, setEditFlightData] = React.useState(false);
    const toggleEditFlightSlider = (row) => {
        setEditFlightData(row);
        toggleEditFlightSliderHelper();
    };

    //Slider for Adding Hotel
    const [addHotelSlider, setAddHotelSlider] = React.useState(false);
    const toggleAddHotelSlider = () => setAddHotelSlider(!addHotelSlider);

    //Slider for Editing Hotel
    const [editHotelSlider, setEditHotelSlider] = React.useState(false);
    const toggleEditHotelSliderHelper = () => setEditHotelSlider(!editHotelSlider);
    const [editHotelData, setEditHotelData] = React.useState(false);
    const toggleEditHotelSlider = (row) => {
        setEditHotelData(row);
        toggleEditHotelSliderHelper();
    };

    //Slider for Hotel Voucher
    const [addHotelVoucherSlider, setAddHotelVoucherSlider] = React.useState(false);
    const toggleAddHotelVoucherSliderHelper = () => setAddHotelVoucherSlider(!addHotelVoucherSlider);
    const [addHotelVoucherData, setAddHotelVoucherData] = React.useState(false);
    const toggleAddHotelVoucherSlider = (row) => {
        const temp = {
            id:row.id,
            passengers:row.passengers,
            passenger_contact:"",
            hotel_name:row.hotel,
            hotel_address:"",
            hotel_city:"",
            hotel_country:"",
            room_description:row.room_type + row.meal_type,
            booking_number:row.booking_number,
            checkin_datetime:row.checkin_datetime.slice(0,10),
            checkout_datetime:row.checkout_datetime.slice(0,10),
            quantity:row.quantity,
            terms:""
        }
        setAddHotelVoucherData(temp);
        toggleAddHotelVoucherSliderHelper();
    };

    //Slider for Sightseeing Hotel
    const [addSightseeingSlider, setAddSightseeingSlider] = React.useState(false);
    const toggleAddSightseeingSlider = () => setAddSightseeingSlider(!addSightseeingSlider);

    //Slider for Editing Sightseeing
    const [editSightseeingSlider, setEditSightseeingSlider] = React.useState(false);
    const toggleEditSightseeingSliderHelper = () => setEditSightseeingSlider(!editSightseeingSlider);
    const [editSightseeingData, setEditSightseeingData] = React.useState(false);
    const toggleEditSightseeingSlider = (row) => {
        setEditSightseeingData(row);
        toggleEditSightseeingSliderHelper();
    };
    
    //Slider for Sightseeing Voucher
    const [addSightseeingVoucherSlider, setAddSightseeingVoucherSlider] = React.useState(false);
    const toggleAddSightseeingVoucherSliderHelper = () => setAddSightseeingVoucherSlider(!addSightseeingVoucherSlider);
    const [addSightseeingVoucherData, setAddSightseeingVoucherData] = React.useState(false);
    const toggleAddSightseeingVoucherSlider = (row) => {
        const temp = {
            id:row.id,
            passengers:row.passengers,
            passenger_contact:"",
            activity:row.activity,
            booking_number:row.booking_number,
            activity_datetime:row.activity_datetime,
            quantity:row.quantity,
            terms:""
        }
        setAddSightseeingVoucherData(temp);
        toggleAddSightseeingVoucherSliderHelper();
    };

    //Slider for Transfer Hotel
    const [addTransferSlider, setAddTransferSlider] = React.useState(false);
    const toggleAddTransferSlider = () => setAddTransferSlider(!addTransferSlider);

    //Slider for Edit Transfer
    const [editTransferSlider, setEditTransferSlider] = React.useState(false);
    const toggleEditTransferSliderHelper = () => setEditTransferSlider(!editTransferSlider);
    const [editTransferData, setEditTransferData] = React.useState(false);
    const toggleEditTransferSlider = (row) => {
        setEditTransferData(row);
        toggleEditTransferSliderHelper();
    };

    //Slider for Adding Payment
    const [addPaymentSlider, setAddPaymentSlider] = React.useState(false);
    const toggleAddPaymentSlider = () => setAddPaymentSlider(!addPaymentSlider);

    //Slider for Adding Payment Link
    const [addPaymentLinkSlider, setAddPaymentLinkSlider] = React.useState(false);
    const toggleAddPaymentLinkSlider = () => setAddPaymentLinkSlider(!addPaymentLinkSlider);

    //Slider for Adding Ticket
    const [addTicketSlider, setAddTicketSlider] = React.useState(false);
    const toggleAddTicketSlider = () => setAddTicketSlider(!addPaymentSlider);

    //Pop up Share
    const [shareWithCustomer, setShareWithCustomer] = React.useState(false);
    const toggleShareWithCustomer = () => setShareWithCustomer(!shareWithCustomer);

    const setTab = (event, name) => {
        event.preventDefault();
        console.log(name);
        var i = 0;
        while (i < tabs.length) {
            var temp = [...tabs]
            if (tabs[i].name == name) {
                temp[i].current = true;
                selectTab(temp);
            } else {
                temp[i].current = false;
                selectTab(temp);
            }
            i++;
            console.log(i);
        }
    }
    const setReportTab = (event, name) => {
        event.preventDefault();
        console.log(name);
        var i = 0;
        while (i < reportTabs.length) {
            var temp = [...reportTabs]
            if (reportTabs[i].name == name) {
                temp[i].current = true;
                selectReportTab(temp);
            } else {
                temp[i].current = false;
                selectReportTab(temp);
            }
            i++;
            console.log(i);
        }
    }
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const openPassport = async (file) => {
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}`, filename: file }
        axios(`http://test-ilios-master.linkpc.net/api/files/fetchPassport.php`, {
            method: 'GET',
            responseType: 'blob', //Force to receive data in a Blob Format
            headers: config
        })
            .then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const openNationalID = async (file) => {
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}`, filename: file }
        axios(`http://test-ilios-master.linkpc.net/api/files/fetchNationalID.php`, {
            method: 'GET',
            responseType: 'blob', //Force to receive data in a Blob Format
            headers: config
        })
            .then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const openTicket = async (file) => {
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}`, filename: file }
        axios(`http://test-ilios-master.linkpc.net/api/files/fetchTicket.php`, {
            method: 'GET',
            responseType: 'blob', //Force to receive data in a Blob Format
            headers: config
        })
            .then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const [logoBlob, setLogoBlob] = useState(null);
    const fetchLogo = async (file) => {
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}`, filename: file }
        axios(`http://test-ilios-master.linkpc.net/api/files/fetchLogo.php`, {
            method: 'GET',
            responseType: 'blob', //Force to receive data in a Blob Format
            headers: config
        })
            .then(response => {
                //Create a Blob 
                const file = new Blob(
                    [response.data],
                    { type: 'image/png' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                setLogoBlob(fileURL);
                console.log(fileURL);
            })
            .catch(error => {
                console.log(error);
            });
    }


    //Fetch tour details
    const [tour, setTour] = useState(null);
    const [invoiceGenerated, SetInvoiceGenerated] = useState(0);
    const { tour_id } = useParams();
    React.useEffect(() => {
        fetchTour();
    }, []);

    const fetchTour = async () => {

        const data = { 'action': 'fetch_tour', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data, {
            headers: config
        });
        setTour(response.data);
        if (response.data.invoice_total > 0) {
            SetInvoiceGenerated(1);
        }
    }

    //Fetch Passengers details
    const [passengers, setPassengers] = useState(null);
    React.useEffect(() => {
        fetchPassengers();
    }, []);

    const fetchPassengers = async () => {

        const data = { 'action': 'fetch_passengers', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data, {
            headers: config
        });
        setPassengers(response.data);
    }

    //Fetch Flights details
    const [flights, setFlights] = useState(null);
    React.useEffect(() => {
        fetchFlights();
    }, []);

    const fetchFlights = async () => {

        const data = { 'action': 'fetch_flight', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data, {
            headers: config
        });
        setFlights(response.data);
    }

    //Fetch Hotel details
    const [hotels, setHotels] = useState(null);
    React.useEffect(() => {
        fetchHotels();
    }, []);
    
    const fetchHotels = async () => {

        const data = { 'action': 'fetch_hotel', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data, {
            headers: config
        });
        setHotels(response.data);
    }

    //Fetch Sightseeing details
    const [sightseeings, setSightseeings] = useState(null);
    React.useEffect(() => {
        fetchSightseeings();
    }, []);
    //save qr_code blob for voucher
    const [qrBlob,setQRBlob] = useState(null);

    const fetchSightseeings = async () => {

        const data = { 'action': 'fetch_sightseeing', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data, {
            headers: config
        });
        setSightseeings(response.data);
        fetchQRBlob(response.data);
        console.log(qrBlob);
    }
    
    const fetchQRBlob = (data) => {
        
        const token = localStorage.getItem('token');
        const tempBlobs = []
        var i = 0;
        var count = 0;
        while(data && i < data.length)
        {
            var j = 0;
            while(data[i].sightseeing_voucher_data && j<data[i].sightseeing_voucher_data.qr_codes.length)
                {   
                    const tmp = data[i].sightseeing_voucher_data.qr_codes[j]; 
                    const config = { Authorization: `Bearer ${token}`, filename: tmp };
                axios(`http://test-ilios-master.linkpc.net/api/files/fetchQRCode.php`, {
                    method: 'GET',
                    responseType: 'blob', //Force to receive data in a Blob Format
                    headers: config
                })
                    .then(response => {
                        //Create a Blob 
                        const file = new Blob(
                            [response.data],
                            { type: 'image/png' });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        tempBlobs.push({url:fileURL});
                        console.log(tmp,fileURL);
                    })
                    .catch(error => {
                        console.log(error);
                        tempBlobs.push({url:''})
                    });
                    j++;
                }
            i++;
        }
        setQRBlob(tempBlobs);
    }

    //Fetch Transfer details
    const [transfers, setTransfers] = useState(null);
    React.useEffect(() => {
        fetchTransfers();
    }, []);

    const fetchTransfers = async () => {

        const data = { 'action': 'fetch_land_transfer', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data, {
            headers: config
        });
        setTransfers(response.data);
    }

    //Fetch Payment details
    const [payments, setPayments] = useState(null);
    React.useEffect(() => {
        fetchPayments();
    }, []);

    const fetchPayments = async () => {

        const data = { 'action': 'fetch_tour_payments', 'username': localStorage.getItem('user_id'), 'tours_id': tour_id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data, {
            headers: config
        });
        setPayments(response.data);
    }

    //Fetch Ticket details
    const [tickets, setTickets] = useState(null);
    React.useEffect(() => {
        fetchTickets();
    }, []);

    const fetchTickets = async () => {

        const data = { 'action': 'fetch_tour_tickets', 'username': localStorage.getItem('user_id'), 'tours_id': tour_id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data, {
            headers: config
        });
        setTickets(response.data);
    }

    const [paymentReceiptdata, setPaymentReceiptData] = useState(null);
    const handlePrintPaymentReceipt = (data) => {
        const temp = {
            customer_name: tour.customer_name,
            customer_phone: tour.mobile_country_code + "-" + tour.mobile,
            customer_email: tour.email,
            company_name: invoiceData.company_name,
            address_line_1: invoiceData.address_line_1,
            address_line_2: invoiceData.address_line_2,
            company_tax_id: invoiceData.company_tax_id,
            default_contact_footer_1: invoiceData.default_contact_footer_1,
            default_contact_footer_2: invoiceData.default_contact_footer_2,
            footer_tab_color: invoiceData.footer_tab_color,
            detail_tab_color: invoiceData.detail_tab_color,
            table_tab_color: invoiceData.table_tab_color,
            date: data.date,
            amount: data.amount,
            transaction_number: data.transaction_number,
            method: data.method,
            currency: invoiceData.currency
        };
        setPaymentReceiptData(temp);
    }

    const handleInvoiceFormChange = (event) => {
        setTour({
            ...tour,
            [event.target.name]: event.target.value,
        });
    }

    const postInvoiceForm = async (event) => {

        event.preventDefault();
        const formdata = new FormData(document.getElementById('invoiceForm'));
        const update_data = {
            invoice_number: formdata.get("invoice_number"),
            invoice_date: formdata.get("invoice_date"),
            authorised_by: formdata.get("authorised_by"),
            invoice_description: formdata.get("invoice_description"),
            invoice_type: formdata.get("invoice_type"),
            is_service_charge: formdata.get("is_service_charge"),
            service_charge: formdata.get("service_charge"),
            is_tax: formdata.get("is_tax"),
            tax_type: formdata.get("tax_type"),
            tax_description: formdata.get("tax_description"),
            tax_percentage: formdata.get("tax_percentage"),
            payment_link: formdata.get("payment_link"),
            invoice_comment: formdata.get("invoice_comment"),
        };
        const data = { ...update_data, 'action': 'update_tour_invoice', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/addDocuments.php`, data, {
            headers: config
        });
        if (response.data.message == "Success") {
            postGenerateInvoice();
        } else {
            alert(response.data.message);
        }
    }

    //Genertae Invoice
    const postGenerateInvoice = async () => {
        const data = { 'action': 'generate_tour_invoice', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/addDocuments.php`, data, {
            headers: config
        });
        if (response.data.message == "Success") {
            document.location.reload();
        } else {
            alert(response.data.message);
        }
    }

    //invoice data
    const [invoiceData, SetInvoiceData] = useState(null);
    React.useEffect(() => {
        fetchInvoiceData();
    }, []);

    const fetchInvoiceData = async () => {
        const data = { 'action': 'fetch_invoice_data', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data, {
            headers: config
        });
        SetInvoiceData(response.data);
        fetchLogo(response.data.logo);
    }

    //Mark Status as Paid
    const postPaidStatus = async () => {
        const data = { 'action': 'update_tour_status_paid', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/updateDocuments.php`, data, {
            headers: config
        });
        if (response.data.message == "Success") {
            document.location.reload();
        } else {
            alert(response.data.message);
        }
    }

    //itinernary data
    const [itinernaryData, setItinernaryData] = useState(null);

    //Create Itinernary Items
    const [itinernaryItems, setItinernaryItems] = useState(null);
    
    const createItemsItinernary = () => {
        var items = [];
        //Flights
        var idx = 0;
        while (idx < flights.length) {
            var temp = {
                datetime: flights[idx].departure_datetime,
                date: new Date(flights[idx].departure_datetime).toDateString(),
                time: new Date(flights[idx].departure_datetime).toTimeString().slice(0,5),
                description: flights[idx].airline + ",PNR: " + flights[idx].pnr + "From: "+ flights[idx].origin_airport['label'] + " --> To: " + flights[idx].destination_airport['label'],
                note: "",
                type: "flight"
            };
            items.push(temp);
            idx++;
        }

        //Hotels
        idx = 0;
        while (idx < hotels.length) {
            var temp = {
                datetime: hotels[idx].checkin_datetime,
                date: new Date(hotels[idx].checkin_datetime).toDateString(),
                time: new Date(hotels[idx].checkin_datetime).toTimeString().slice(0,5),
                description: "Check-In: " + hotels[idx].hotel + ",Room: " + hotels[idx].room_type + ",Meal: " +hotels[idx].meal_type,
                note: "",
                type: "check-in"
            };
            items.push(temp);
            temp = {
                datetime: hotels[idx].checkout_datetime,
                date: new Date(hotels[idx].checkout_datetime).toDateString(),
                time: new Date(hotels[idx].checkout_datetime).toTimeString().slice(0,5),
                description: "Check-Out: " + hotels[idx].hotel + ",Room: " + hotels[idx].room_type,
                note: "",
                type: "check-out"
            };
            items.push(temp);
            idx++;
        }

        //Sightseeing
        idx = 0;
        while (idx < sightseeings.length) {
            var temp = {
                datetime: sightseeings[idx].activity_datetime,
                date: new Date(sightseeings[idx].activity_datetime).toDateString(),
                time: new Date(sightseeings[idx].activity_datetime).toTimeString().slice(0,5),
                description: sightseeings[idx].activity + ", Transfer: " + sightseeings[idx].transfer,
                note: "",
                type: "sightseeing"
            };
            items.push(temp);
            idx++;
        }

        //Transfers
        idx = 0;
        while (idx < transfers.length) {
            var temp = {
                datetime: transfers[idx].pickup_datetime,
                date: new Date(transfers[idx].pickup_datetime).toDateString(),
                time: new Date(transfers[idx].pickup_datetime).toTimeString().slice(0,5),
                description: "Transfer: " + transfers[idx].pickup_location + "-->" + transfers[idx].drop_location + ", Vehicle: " + transfers[idx].vehicle + ", Drop Time: " + transfers[idx].drop_datetime.split(' ')[1].slice(0,5),
                note: "",
                type: "transfer"
            };
            items.push(temp);
            idx++;
        }
        //sort
        items = items.sort((x, y) => x.datetime > y.datetime ? 1 : -1);
        var temp = [];
        var idx = 0;
        while (idx < items.length) {
            var curr = {
                date: items[idx].date,
                time: items[idx].time,
                datetime: items[idx].datetime,
                description: items[idx].description,
                note: items[idx].note,
                type:items[idx].type,
                colored: idx%2 == 0?false:true
            }
            temp.push(curr);
            idx++;
        }
        var group_items = Object.groupBy(temp, ({ date }) => date);
        var end_temp = Object.keys(group_items).map((key,index) => ({
            description: "End of Day " + (index + 1)
        }))
        setItinernaryItems(temp);
        console.log(temp);
        setItinernaryEndDays(end_temp);
    }

    const handleItinernaryItemFormChange = (event, dt) => {
        var temp = [];
        var idx = 0;
        while (idx < itinernaryItems.length) {
            if (itinernaryItems[idx].datetime === dt) {
                var curr = {
                    date: itinernaryItems[idx].date,
                    time: itinernaryItems[idx].time,
                    datetime: itinernaryItems[idx].datetime,
                    description: event.target.name == "item_description" ? event.target.value : itinernaryItems[idx].description,
                    note: event.target.name == "note" ? event.target.value : itinernaryItems[idx].note,
                    colored:itinernaryItems[idx].colored,
                    type:itinernaryItems[idx].type
                }
                temp.push(curr);
            }
            else {
                temp.push(itinernaryItems[idx]);
            }
            idx++;
        }
        setItinernaryItems(temp);
        console.log(temp);
    }

    const handleItinernaryEndDaysFormChange = (event, index) => {
        var temp = [];
        var idx = 0;
        while (idx < itinernaryEndDays.length) {
            if (idx === index) {
                temp.push({description: event.target.value});
            }
            else {
                temp.push(itinernaryEndDays[idx]);
            }
            idx++;
        }
        setItinernaryEndDays(temp);
    }

    const createItinernaryData = () => {
        if (itinernaryItems == null)
            return;
        //group
        var group_items = Object.groupBy(itinernaryItems, ({ date }) => date);

        setItinernaryData({
            customer_name: tour.customer_name,
            customer_phone: tour.mobile_country_code + "-" + tour.mobile,
            customer_email: tour.email,
            default_contact_footer_1: invoiceData.default_contact_footer_1,
            default_contact_footer_2: invoiceData.default_contact_footer_2,
            footer_tab_color: invoiceData.footer_tab_color,
            detail_tab_color: invoiceData.detail_tab_color,
            table_tab_color: invoiceData.table_tab_color,
            title: itinernaryTitle,
            terms: itinernaryTerms,
            items: Object.keys(group_items).map((key,index) => ({
                date: key,
                steps: [...group_items[key],{
                    date: "",
                    time: "",
                    datetime: "",
                    description: itinernaryEndDays[index].description,
                    note: "",
                    type: null,
                    colored: false
                }]
            }))
        });
    }

    const [itinernaryTerms, setItinernaryTerms] = useState(null);
    const [itinernaryTitle, setItinernaryTitle] = useState(null);
    const [itinernaryEndDays, setItinernaryEndDays] = useState(null);
    const handleItinernaryTermsFormChange = (event) => {
        setItinernaryTerms(event.target.value);
    }
    const handleItinernaryTitleFormChange = (event) => {
        setItinernaryTitle(event.target.value);
    }

    React.useEffect(() => {
        fetchItinernary();
    }, []);

    const fetchItinernaryHelper = (data) => {
        setItinernaryTerms(data.terms);
        setItinernaryTitle(data.title);
        setItinernaryItems(data.items);
        setItinernaryEndDays(data.end_days);
    }

    const fetchItinernary = async () => {

        const data = { 'action': 'fetch_itinernary', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data, {
            headers: config
        });
        fetchItinernaryHelper(response.data);
    }

    const postItinernaryForm = async (event) => {

        event.preventDefault();
        const data = { 'action': 'update_itinernary_terms', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id, 'terms': itinernaryTerms, 'title': itinernaryTitle, 'items': itinernaryItems, 'end_of_days': itinernaryEndDays };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/updateDocuments.php`, data, {
            headers: config
        });
        if (response.data.message == "Success") {
            createItinernaryData();
        } else {
            alert(response.data.message);
        }
    }

    //RE-generate and post
    const postItinernaryFormRe = async (event) => {

        event.preventDefault();

        const data = { 'action': 'update_itinernary_terms', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id, 'terms': itinernaryTerms, 'title': itinernaryTitle, 'items': null, 'end_of_days': null };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/updateDocuments.php`, data, {
            headers: config
        });
        if (response.data.message == "Success") {
            document.location.reload();
        } else {
            alert(response.data.message);
        }
    }

    //Create Margin Sheet
    //itinernary data
    const [marginData, seMarginData] = useState(null);

    //Create Margin Items
    const createItemsMargin = () => {

        var items = [];
        var total_buy = 0.0;
        var total_quote = 0.0;
        var total_margin = 0.0;
        //Flights
        var idx = 0;
        while (idx < flights.length) {
            var temp = {
                Description: flights[idx].airline + "," + flights[idx].origin_airport['label'] + "->" + flights[idx].destination_airport['label'],
                Buy_Price: parseFloat(flights[idx].buy_price) * flights[idx].quantity,
                Quote_Price: parseFloat(flights[idx].quote_price) * flights[idx].quantity,
                Margin: (parseFloat(flights[idx].quote_price) - parseFloat(flights[idx].buy_price)) * flights[idx].quantity
            };
            total_buy = total_buy + ((parseFloat(flights[idx].buy_price)) * flights[idx].quantity)
            total_quote = total_quote + ((parseFloat(flights[idx].quote_price)) * flights[idx].quantity)
            total_margin = total_margin + ((parseFloat(flights[idx].quote_price) - parseFloat(flights[idx].buy_price)) * flights[idx].quantity);
            items.push(temp);
            idx++;
        }

        //Hotels
        idx = 0;
        while (idx < hotels.length) {
            var temp = {
                Description: hotels[idx].hotel + "," + hotels[idx].room_type,
                Buy_Price: parseFloat(hotels[idx].buy_price) * hotels[idx].quantity,
                Quote_Price: parseFloat(hotels[idx].quote_price) * hotels[idx].quantity,
                Margin: (parseFloat(hotels[idx].quote_price) - parseFloat(hotels[idx].buy_price)) * hotels[idx].quantity
            };
            total_buy = total_buy + ((parseFloat(hotels[idx].buy_price)) * hotels[idx].quantity)
            total_quote = total_quote + ((parseFloat(hotels[idx].quote_price)) * hotels[idx].quantity)
            total_margin = total_margin + ((parseFloat(hotels[idx].quote_price) - parseFloat(hotels[idx].buy_price)) * hotels[idx].quantity);
            items.push(temp);
            idx++;
        }

        //Sightseeing
        idx = 0;
        while (idx < sightseeings.length) {
            var temp = {
                Description: sightseeings[idx].activity,
                Buy_Price: parseFloat(sightseeings[idx].buy_price * sightseeings[idx].quantity),
                Quote_Price: parseFloat(sightseeings[idx].quote_price * sightseeings[idx].quantity),
                Margin: (parseFloat(sightseeings[idx].quote_price) - parseFloat(sightseeings[idx].buy_price)) * sightseeings[idx].quantity
            };
            total_buy = total_buy + ((parseFloat(sightseeings[idx].buy_price)) * sightseeings[idx].quantity)
            total_quote = total_quote + ((parseFloat(sightseeings[idx].quote_price)) * sightseeings[idx].quantity)
            total_margin = total_margin + ((parseFloat(sightseeings[idx].quote_price) - parseFloat(sightseeings[idx].buy_price)) * sightseeings[idx].quantity);
            items.push(temp);
            idx++;
        }

        //Transfers
        idx = 0;
        while (idx < transfers.length) {
            var temp = {
                Description: "Transfer: " + transfers[idx].pickup_location + "->" + transfers[idx].drop_location + ", Vehicle: " + transfers[idx].vehicle,
                Buy_Price: parseFloat(transfers[idx].buy_price) * transfers[idx].quantity,
                Quote_Price: parseFloat(transfers[idx].quote_price) * transfers[idx].quantity,
                Margin: (parseFloat(transfers[idx].quote_price) - parseFloat(transfers[idx].buy_price))
            };
            total_buy = total_buy + ((parseFloat(transfers[idx].buy_price)) * transfers[idx].quantity)
            total_quote = total_quote + ((parseFloat(transfers[idx].quote_price)) * transfers[idx].quantity)
            total_margin = total_margin + (parseFloat(transfers[idx].quote_price) - parseFloat(transfers[idx].buy_price));
            items.push(temp);
            idx++;
        }
        var temp = {
            Description: "Total",
            Buy_Price: total_buy,
            Quote_Price: total_quote,
            Margin: total_margin
        }
        items.push(temp);
        seMarginData({
            margin: total_margin,
            items: items
        });
    }

    const exportToExcel = async () => {
        const worksheet = XLSX.utils.json_to_sheet(marginData.items);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

        const data = { 'action': 'update_tour_margin', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id, 'margin': marginData.margin.toString() };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/updateDocuments.php`, data, {
            headers: config
        });
        if (response.data.message == "Success") {
            saveAs(blob, `margin_sheet.xlsx`);
        } else {
            alert(response.data.message);
        }
    };

    const deletePassenger = async (row) => {
        const data = { 'action': 'remove_passenger', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id, 'id': row.id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/removeDocuments.php`, data, {
            headers: config
        });
        if (response.data.message == "Success") {
            document.location.reload();
        } else {
            alert(response.data.message);
        }
    }

    const deleteFlight = async (row) => {
        const data = { 'action': 'remove_flight', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id, 'id': row.id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/removeDocuments.php`, data, {
            headers: config
        });
        if (response.data.message == "Success") {
            document.location.reload();
        } else {
            alert(response.data.message);
        }
    }

    const deleteHotel = async (row) => {
        const data = { 'action': 'remove_hotel', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id, 'id': row.id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/removeDocuments.php`, data, {
            headers: config
        });
        if (response.data.message == "Success") {
            document.location.reload();
        } else {
            alert(response.data.message);
        }
    }

    const deleteSightseeing = async (row) => {
        const data = { 'action': 'remove_sightseeing', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id, 'id': row.id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/removeDocuments.php`, data, {
            headers: config
        });
        if (response.data.message == "Success") {
            document.location.reload();
        } else {
            alert(response.data.message);
        }
    }

    const deleteTransfer = async (row) => {
        const data = { 'action': 'remove_transfer', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id, 'id': row.id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/removeDocuments.php`, data, {
            headers: config
        });
        if (response.data.message == "Success") {
            document.location.reload();
        } else {
            alert(response.data.message);
        }
    }

    const deleteTicket = async (row) => {
        const data = { 'action': 'remove_ticket', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id, 'id': row.id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/removeDocuments.php`, data, {
            headers: config
        });
        if (response.data.message == "Success") {
            document.location.reload();
        } else {
            alert(response.data.message);
        }
    }

    return (
        <div >
            <AddPassengerForm open={addPassengerSlider} close={toggleAddPassengerSlider} tour_id={tour_id}></AddPassengerForm>
            <EditPassengerForm open={editPassengerSlider} close={toggleEditPassengerSlider} inputs={editPassengerData} tour_id={tour_id}></EditPassengerForm>
            <AddFlightForm open={addFlightSlider} close={toggleAddFlightSlider} tour_id={tour_id}></AddFlightForm>
            <EditFlightForm open={editFlightSlider} close={toggleEditFlightSlider} inputs={editFlightData} tour_id={tour_id}></EditFlightForm>
            <AddHotelForm open={addHotelSlider} close={toggleAddHotelSlider} tour_id={tour_id}></AddHotelForm>
            <EditHotelForm open={editHotelSlider} close={toggleEditHotelSlider} inputs={editHotelData} tour_id={tour_id}></EditHotelForm>
            <AddHotelVoucherForm open={addHotelVoucherSlider} close={toggleAddHotelVoucherSliderHelper} inputs={addHotelVoucherData} tour_id={tour_id}></AddHotelVoucherForm>
            <AddSightseeingForm open={addSightseeingSlider} close={toggleAddSightseeingSlider} tour_id={tour_id}></AddSightseeingForm>
            <EditSightseeingForm open={editSightseeingSlider} close={toggleEditSightseeingSlider} inputs={editSightseeingData} tour_id={tour_id}></EditSightseeingForm>
            <AddSightseeingVoucherForm open={addSightseeingVoucherSlider} close={toggleAddSightseeingVoucherSlider} inputs={addSightseeingVoucherData} tour_id={tour_id} ></AddSightseeingVoucherForm>
            <AddTransferForm open={addTransferSlider} close={toggleAddTransferSlider} tour_id={tour_id}></AddTransferForm>
            <EditTransferForm open={editTransferSlider} close={toggleEditTransferSlider} inputs={editTransferData} tour_id={tour_id}></EditTransferForm>
            <AddPaymentForm open={addPaymentSlider} close={toggleAddPaymentSlider} tour_id={tour_id}></AddPaymentForm>
            <AddPaymentLinkForm open={addPaymentLinkSlider} close={toggleAddPaymentLinkSlider} tour_id={tour_id}></AddPaymentLinkForm>
            <AddTicketForm open={addTicketSlider} close={toggleAddTicketSlider} tour_id={tour_id}></AddTicketForm>
            <ModalTourShareWithCustomer open={shareWithCustomer} close={toggleShareWithCustomer} tour_id={tour_id}></ModalTourShareWithCustomer>
            <div className="block overflow-y-auto">
                <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
                    {tabs.map((tab, tabIdx) => (
                        <button
                            key={tab.name}
                            onClick={(e) => setTab(e, tab.name)}
                            className={classNames(
                                tab.current ? 'text-gray-100 bg-indigo-900' : 'text-gray-900 hover:text-gray-700 bg-indigo-100',
                                tabIdx === 0 ? 'rounded-l-lg' : '',
                                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                                'group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-center text-xs font-medium hover:bg-gray-50 hover:text-gray-900 focus:z-10'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                        >
                            <span>{tab.name}</span>
                        </button>
                    ))}
                </nav>
            </div>
            {tabs[0].current == true ?
                <div className="mt-5 border-none bg-white">
                    {tour !=null && tour.shared_email == null ? <div className="mt-2 flex flex-row-reverse ">
                        <button
                            type="button"
                            className="rounded-md bg-blue-600 px-2 py-2 sm:py-5 text-xs sm:text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                            onClick={toggleShareWithCustomer}
                        >Share With Customer
                        </button>
                    </div> : 
                    <div className="mt-2 flex flex-row-reverse ">
                        Shared with : {tour!=null?tour.shared_email:'Error'}
                    </div>}
                    <dl className="mt-2 grid grid-cols-2 sm:grid-cols-4">
                        <div className="bg-slate-100 px-4 py-6 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Customer</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{tour != null ? tour.customer_name : null}</dd>
                        </div>
                        <div className="px-4 py-6 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Email</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{tour != null ? tour.email : null}</dd>
                        </div>
                        <div className="bg-slate-100 px-4 py-6 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Phone</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">+{tour != null ? tour.mobile_country_code : null}-{tour != null ? tour.mobile : null}</dd>
                        </div>
                        <div className="px-4 py-6 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Type</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{tour != null ? tour.type : null}</dd>
                        </div>
                    </dl>
                    <dl className="grid grid-cols-2 sm:grid-cols-4">
                        <div className="px-4 py-6 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Tour Date</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{tour != null ? tour.start_date : null}</dd>
                        </div>
                        <div className="bg-slate-100 px-4 py-6 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Origin</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{tour != null ? tour.origin : null}</dd>
                        </div>
                        <div className="px-4 py-6 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Destination</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{tour != null ? tour.destination : null}</dd>
                        </div>
                        <div className="bg-slate-100 px-4 py-6 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Payment</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{tour != null ? tour.status : null}</dd>
                        </div>
                        <div className="bg-slate-100 px-4 py-6 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Pax Count</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{tour != null ? tour.adults_count + tour.childs_count + tour.toddlers_count : null}</dd>
                        </div>
                        <div className="px-4 py-6 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Adults</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{tour != null ? tour.adults_count : null}</dd>
                        </div>
                        <div className="bg-slate-100 px-4 py-6 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Children</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{tour != null ? tour.childs_count : null}</dd>
                        </div>
                        <div className="px-4 py-6 sm:gap-4 sm:px-0">
                            <dt className="text-base font-bold leading-6 text-gray-900">Minors</dt>
                            <dd className="text-sm leading-6 text-gray-700 sm:mt-0">{tour != null ? tour.toddlers_count : null}</dd>
                        </div>
                    </dl>
                </div> : null}
            {tabs[1].current == true ?
                <div className="mt-4 flow-root">
                    <div className="" style={{ justifyContent: "space-between" }}>
                        <div className="flex justify-end">
                            <button
                                type="button"
                                className="flex rounded-full bg-green-500 px-2 py-2 sm:py-5 text-xs sm:text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                onClick={toggleAddPassengerSlider}
                            >Add Passenger
                            </button>
                        </div>
                    </div>
                    <div className=" overflow-y-auto ">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                Passenger Name
                                            </th>
                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                Gender
                                            </th>
                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                DOB
                                            </th>
                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                Nationality
                                            </th>
                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                Passport
                                            </th>
                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                National ID
                                            </th>
                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {Array.isArray(passengers) ? passengers.map((row) => (
                                            <tr key={row.id}>
                                                <td className="whitespace-nowrap text-sm text-gray-900">
                                                    {row.first_name + " " + row.middle_name + " " + row.last_name}
                                                </td>
                                                <td className="whitespace-nowrap text-sm text-gray-900">{row.gender}</td>
                                                <td className="whitespace-nowrap text-sm text-gray-900">{row.dob}</td>
                                                <td className="whitespace-nowrap text-sm text-gray-900">{row.nationality}</td>
                                                <td className="whitespace-nowrap text-sm text-gray-900">
                                                    {row.passport}
                                                    <button onClick={() => openPassport(row.passport_file)}>
                                                        <ArrowUpOnSquareIcon className="h-5 w-8 pl-2 flex-shrink-0 text-gray-400" />
                                                    </button>
                                                </td>
                                                <td className="whitespace-nowrap text-sm text-gray-900">
                                                    {row.national_id}
                                                    <button onClick={() => openNationalID(row.national_id_file)}>
                                                        <ArrowUpOnSquareIcon className="h-5 w-8 pl-2 flex-shrink-0 text-gray-400" />
                                                    </button>
                                                </td>
                                                <td colSpan={2} className="flex flex-row justify-between">
                                                    <button onClick={() => toggleEditPassengerSlider(row)} style={{ marginRight: "1px" }} className="rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600" >Edit</button>
                                                    <button onClick={() => deletePassenger(row)} style={{ marginRight: "1px" }} className="rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600" >Remove</button>
                                                </td>
                                            </tr>
                                        )) : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div> : null}
            {tabs[2].current == true ?
                <div className="mt-4 flow-root">
                    <div className="" style={{ justifyContent: "space-between" }}>
                        <div className="flex justify-end">
                            <button
                                type="button"
                                className="flex rounded-full bg-green-500 px-2 py-2 sm:py-5 text-xs sm:text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                onClick={toggleAddFlightSlider}
                            >Add Flight Reservation
                            </button>
                        </div>
                    </div>
                    <div className=" overflow-x-auto ">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6">
                                                Passengers
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Airline
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                PNR
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Origin Airport
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Departure (UTC)
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Destination Airport
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Arrival (UTC)
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Status
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Quantity
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Buy Price
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Quote/Sell Price
                                            </th>
                                            <th scope="col" colSpan={2} className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {Array.isArray(flights) ? flights.map((row) => (
                                            <tr key={row.id}>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.passengers.map((p) => (<span>{p.label}</span>))}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.airline}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.pnr}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.origin_airport['label']}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.departure_datetime}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.destination_airport['label']}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.arrival_datetime}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.status}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.quantity}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.buy_price}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.quote_price}</td>
                                                <td colSpan={2} className="flex flex-row justify-between">
                                                    <button onClick={() => toggleEditFlightSlider(row)} style={{ marginRight: "1px" }} className="rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600" >Edit</button>
                                                    <button onClick={() => deleteFlight(row)} style={{ marginRight: "1px" }} className="rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600" >Remove</button>
                                                </td>
                                            </tr>
                                        )) : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div> : null}
            {tabs[3].current == true ?
                <div className="mt-4 flow-root">
                    <div className="" style={{ justifyContent: "space-between" }}>
                        <div className="flex justify-end">
                            <button
                                type="button"
                                className="flex rounded-full bg-green-500 px-2 py-2 sm:py-5 text-xs sm:text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                onClick={toggleAddHotelSlider}
                            >Add Hotel Reservation
                            </button>
                        </div>
                    </div>
                    <div className=" overflow-x-auto ">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6">
                                                Passengers
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Hotel
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Room
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Meal Type
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Check-In Date
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Check-Out Date
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Booking Number
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Status
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Quantity
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Buy Price
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Quote/Sell Price
                                            </th>
                                            <th scope="col" colSpan={2} className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {Array.isArray(hotels) ? hotels.map((row) => (
                                            <tr key={row.id}>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.passengers.map((p) => (<span>{p.label}</span>))}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.hotel}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.room_type}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.meal_type}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.checkin_datetime}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.checkout_datetime}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.booking_number}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.status}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.quantity}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.buy_price}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.quote_price}</td>
                                                <td colSpan={2} className="flex flex-row justify-between">
                                                    <button onClick={() => toggleEditHotelSlider(row)} style={{ marginRight: "1px" }} className="rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600" >Edit</button>
                                                    <button onClick={() => deleteHotel(row)} style={{ marginRight: "1px" }} className="rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600" >Remove</button>
                                                    {row.status == "Confirmed"? 
                                                        row.hotel_voucher_id == null ?<button onClick={() => toggleAddHotelVoucherSlider(row)} style={{ marginRight: "1px" }} className="rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600" >Generate Voucher</button>
                                                        :
                                                        <BlobProvider document={<HotelVoucher hotel_data={row.hotel_voucher_data} logoBlob={logoBlob} />}>
                                                            {({ url, blob }) => (
                                                                <a href={url} target="_blank" style={{ borderRadius: '3px', border: '1px solid gray', display: 'flex', alignItems: 'center', gap: '2px', padding: '3px', fontSize: '16px', color: '#4f4f4f', fontWeight: 600, cursor: 'pointer', userSelect: 'none' }}>
                                                                    <HiOutlinePrinter size={16} />
                                                                    <span>Open Voucher</span>
                                                                </a>
                                                            )}
                                                        </BlobProvider>
                                                        :
                                                        null
                                                    }
                                                </td>
                                            </tr>
                                        )) : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div> : null}
            {tabs[4].current == true ?
                <div className="mt-4 flow-root">
                    <div className="" style={{ justifyContent: "space-between" }}>
                        <div className="flex justify-end">
                            <button
                                type="button"
                                className="flex rounded-full bg-green-500 px-2 py-2 sm:py-5 text-xs sm:text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                onClick={toggleAddSightseeingSlider}
                            >Add Sightseeing Reservation
                            </button>
                        </div>
                    </div>
                    <div className=" overflow-x-auto ">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6">
                                                Passengers
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Activity
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Date/Time
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Transfer
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Status
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Quantity
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Buy Price
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Quote/Sell Price
                                            </th>
                                            <th scope="col" colSpan={2} className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {Array.isArray(sightseeings) ? sightseeings.map((row) => (
                                            <tr key={row.id}>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.passengers.map((p) => (<span>{p.label}</span>))}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.activity}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.activity_datetime}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.transfer}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.status}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.quantity}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.buy_price}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.quote_price}</td>
                                                <td colSpan={2} className="flex flex-row justify-between">
                                                    <button onClick={() => toggleEditSightseeingSlider(row)} style={{ marginRight: "1px" }} className="rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600" >Edit</button>
                                                    <button onClick={() => deleteSightseeing(row)} style={{ marginRight: "1px" }} className="rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600" >Remove</button>
                                                    {row.status == "Confirmed"? 
                                                        row.sightseeing_voucher_id == null ?<button onClick={() => toggleAddSightseeingVoucherSlider(row)} style={{ marginRight: "1px" }} className="rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600" >Generate Voucher</button>
                                                        :
                                                        <BlobProvider document={<SightseeingVoucher sightseeing_data={row.sightseeing_voucher_data} logoBlob={logoBlob} qrBlob={qrBlob}/>}>
                                                        {({ url, blob }) => (
                                                            <a href={url} target="_blank" style={{ borderRadius: '3px', border: '1px solid gray', display: 'flex', alignItems: 'center', gap: '2px', padding: '3px', fontSize: '16px', color: '#4f4f4f', fontWeight: 600, cursor: 'pointer', userSelect: 'none' }}>
                                                                <HiOutlinePrinter size={16} />
                                                                <span>Open Voucher</span>
                                                            </a>
                                                        )}
                                                        </BlobProvider>
                                                        :
                                                        null
                                                    }
                                                </td>
                                            </tr>
                                        )) : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div> : null}
            {tabs[5].current == true ?
                <div className="mt-4 flow-root">
                    <div className="" style={{ justifyContent: "space-between" }}>
                        <div className="flex justify-end">
                            <button
                                type="button"
                                className="flex rounded-full bg-green-500 px-2 py-2 sm:py-5 text-xs sm:text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                onClick={toggleAddTransferSlider}
                            >Add Land Transfers
                            </button>
                        </div>
                    </div>
                    <div className=" overflow-x-auto ">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6">
                                                Passengers
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Pick-Up Location
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Drop Location
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Pick-Up Date/Time
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Drop Date/Time
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Vehicle
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Status
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Quantity
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Buy Price
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Quote/Sell Price
                                            </th>
                                            <th scope="col" colSpan={2} className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {Array.isArray(transfers) ? transfers.map((row) => (
                                            <tr key={row.id}>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.passengers.map((p) => (<span>{p.label}</span>))}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.pickup_location}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.drop_location}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.pickup_datetime}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.drop_datetime}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.vehicle}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.status}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.quantity}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.buy_price}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{row.quote_price}</td>
                                                <td colSpan={2} className="flex flex-row justify-between">
                                                    <button onClick={() => toggleEditTransferSlider(row)} style={{ marginRight: "1px" }} className="rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600" >Edit</button>
                                                    <button onClick={() => deleteTransfer(row)} style={{ marginRight: "1px" }} className="rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600" >Remove</button>
                                                </td>
                                            </tr>
                                        )) : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div> : null}
            {tabs[6].current == true ?
                <div className="mt-4 flex flex-row bg-white">
                    <div>
                        <nav className="flex flex-col space-y-2 divide-gray-200 rounded-lg shadow" aria-label="Tabs">
                            {reportTabs.map((tab, tabIdx) => (
                                <button
                                    key={tab.name}
                                    onClick={(e) => setReportTab(e, tab.name)}
                                    className={classNames(
                                        tab.current ? 'text-gray-100 bg-indigo-500' : 'text-gray-900 hover:text-gray-700 bg-gray-100',
                                        tabIdx === 0 ? 'rounded-l-lg' : '',
                                        tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                                        'group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-center text-xs font-medium hover:text-gray-900  focus:z-10'
                                    )}
                                    aria-current={tab.current ? 'page' : undefined}
                                >
                                    <span>{tab.name}</span>
                                </button>
                            ))}
                        </nav>
                    </div>
                    <div className="w-full">
                        {reportTabs[0].current == true ?
                            <div className="mr-2 ml-2">
                                {itinernaryData != null ? <div className="mt-6 flex items-center justify-end gap-x-6">
                                    <BlobProvider document={<Itinernary itinernary_data={itinernaryData} logoBlob={logoBlob} />}>
                                        {({ url, blob }) => (
                                            <a href={url} target="_blank" style={{ borderRadius: '3px', border: '1px solid gray', display: 'flex', alignItems: 'center', gap: '2px', padding: '3px', fontSize: '16px', color: '#4f4f4f', fontWeight: 600, cursor: 'pointer', userSelect: 'none' }}>
                                                <HiOutlinePrinter size={16} />
                                                <span>Open Itinernary</span>
                                            </a>
                                        )}
                                    </BlobProvider>
                                    <br></br>
                                </div>
                                    : null}
                                {itinernaryItems != null ?
                                    <div className=" overflow-y-auto ">
                                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                            <h2> List of Items for Itinernary</h2>
                                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <thead className="bg-gray-50">
                                                        <tr>
                                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                                DateTime
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                                Description
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                                Note
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                        {Array.isArray(itinernaryItems) ? itinernaryItems.map((row) => (
                                                            <tr >
                                                                <td className="whitespace-nowrap text-sm text-gray-900">
                                                                    {row.datetime}
                                                                </td>
                                                                <td className="whitespace-nowrap text-sm text-gray-900">
                                                                    <textarea
                                                                        id="item_description"
                                                                        name="item_description"
                                                                        rows={3}
                                                                        onChange={(event) => handleItinernaryItemFormChange(event, row.datetime)}
                                                                        required
                                                                        placeholder="Exclusion"
                                                                        className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                                    >
                                                                        {row.description}
                                                                    </textarea>
                                                                </td>
                                                                <td className="whitespace-nowrap text-sm text-gray-900">
                                                                <textarea
                                                                        id="note"
                                                                        name="note"
                                                                        rows={3}
                                                                        onChange={(event) => handleItinernaryItemFormChange(event, row.datetime)}
                                                                        required
                                                                        placeholder="Exclusion"
                                                                        className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                                    >
                                                                        {row.note}
                                                                    </textarea>
                                                                </td>
                                                            </tr>
                                                        )) : null}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    : null}

                                {itinernaryEndDays != null ?
                                    <div className=" overflow-y-auto ">
                                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                            <h2> List of End of Days Note</h2>
                                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <thead className="bg-gray-50">
                                                        <tr>
                                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                                Day
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                                Note
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                        {Array.isArray(itinernaryEndDays) ? itinernaryEndDays.map((row, index) => (
                                                            <tr >
                                                                <td className="whitespace-nowrap text-sm text-gray-900">
                                                                    {index}
                                                                </td>
                                                                <td className="whitespace-nowrap text-sm text-gray-900">
                                                                    <textarea
                                                                        id="end_of_day_description"
                                                                        name="end_of_day_description"
                                                                        rows={2}
                                                                        onChange={(event) => handleItinernaryEndDaysFormChange(event, index)}
                                                                        required
                                                                        placeholder="Exclusion"
                                                                        className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                                    >
                                                                        {row.description}
                                                                    </textarea>
                                                                </td>
                                                            </tr>
                                                        )) : null}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    : null}
                                <div className=" overflow-y-auto ">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">

                                            {itinernaryItems != null ?
                                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                                    <div className="sm:col-span-full">
                                                        <label htmlFor="government_id" className="block text-sm font-medium leading-6 text-gray-900">
                                                            Title For Itinernary
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                type="text"
                                                                id="itinernary_title"
                                                                name="itinernary_title"
                                                                placeholder="Invoice Number *"
                                                                required
                                                                value={itinernaryTitle}
                                                                onChange={handleItinernaryTitleFormChange}
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            >
                                                            </input>

                                                        </div>
                                                    </div>

                                                    <div className="sm:col-span-full">
                                                        <label htmlFor="government_id" className="block text-sm font-medium leading-6 text-gray-900">
                                                            Terms & Conditions
                                                        </label>
                                                        <div className="mt-2">
                                                            <textarea
                                                                id="itinernary_terms"
                                                                name="itinernary_terms"
                                                                rows={10}
                                                                onChange={handleItinernaryTermsFormChange}
                                                                required
                                                                placeholder="Terms & Conditions"
                                                                className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                            >
                                                                {itinernaryTerms}
                                                            </textarea>

                                                        </div>
                                                    </div>
                                                </div> : null}
                                        </div>
                                    </div>
                                </div>
                                {itinernaryItems == null ?

                                    <button
                                        onClick={createItemsItinernary}
                                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Generate Itinernary Items
                                    </button>
                                    :
                                    <div>
                                        <button
                                            onClick={postItinernaryFormRe}
                                            className="mr-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Clear All Itinernary Items
                                        </button>
                                        <button
                                            onClick={postItinernaryForm}
                                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Generate Itinernary
                                        </button>
                                    </div>}
                            </div> : null}
                        {reportTabs[1].current == true ?
                            <div className="mr-2 ml-2">
                                <div>
                                    {invoiceGenerated == 1 ? <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <BlobProvider document={<Invoice invoice_data={invoiceData} logoBlob={logoBlob} />}>
                                            {({ url, blob }) => (
                                                <a href={url} target="_blank" style={{ borderRadius: '3px', border: '1px solid gray', display: 'flex', alignItems: 'center', gap: '2px', padding: '3px', fontSize: '16px', color: '#4f4f4f', fontWeight: 600, cursor: 'pointer', userSelect: 'none' }}>
                                                    <HiOutlinePrinter size={16} />
                                                    <span>Open Invoice</span>
                                                </a>
                                            )}
                                        </BlobProvider>
                                    </div> : null}
                                    <br></br>
                                    <h3>OR ...</h3>
                                </div>
                                <form id="invoiceForm">
                                    <div className="space-y-12">
                                        <div className="border-b border-gray-900/10 pb-12">
                                            <h2 className="text-base font-semibold leading-7 text-gray-900">Invoice Information</h2>

                                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                                <div className="sm:col-span-3">
                                                    <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Invoice Number
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="text"
                                                            name="invoice_number"
                                                            id="invoice_number"
                                                            placeholder="Invoice Number *"
                                                            required
                                                            value={tour.invoice_number}
                                                            onChange={handleInvoiceFormChange}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-3">
                                                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Invoice Date
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="date"
                                                            name="invoice_date"
                                                            id="invoice_date"
                                                            required
                                                            value={tour.invoice_date}
                                                            onChange={handleInvoiceFormChange}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-2">
                                                    <label htmlFor="invoice_type" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Invoice Type
                                                    </label>
                                                    <div className="mt-2">
                                                        <select
                                                            id="invoice_type"
                                                            name="invoice_type"
                                                            value={tour.invoice_type}
                                                            onChange={handleInvoiceFormChange}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                                        >
                                                            <option>Simple</option>
                                                            <option>Detailed</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-2">
                                                    <label htmlFor="is_service_charge" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Add Service Charge?
                                                    </label>
                                                    <div className="mt-2">
                                                        <select
                                                            id="is_service_charge"
                                                            name="is_service_charge"
                                                            value={tour.is_service_charge}
                                                            onChange={handleInvoiceFormChange}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                                        >
                                                            <option>Yes</option>
                                                            <option>No</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-2">
                                                    <label htmlFor="service_charge" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Service Charge
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            id="service_charge"
                                                            name="service_charge"
                                                            type="number"
                                                            required
                                                            value={tour.service_charge}
                                                            onChange={handleInvoiceFormChange}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-2">
                                                    <label htmlFor="is_tax" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Add Tax?
                                                    </label>
                                                    <div className="mt-2">
                                                        <select
                                                            id="is_tax"
                                                            name="is_tax"
                                                            value={tour.is_tax}
                                                            onChange={handleInvoiceFormChange}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                                        >
                                                            <option>Yes</option>
                                                            <option>No</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-2">
                                                    <label htmlFor="tax_type" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Tax For?
                                                    </label>
                                                    <div className="mt-2">
                                                        <select
                                                            id="tax_type"
                                                            name="tax_type"
                                                            value={tour.tax_type}
                                                            onChange={handleInvoiceFormChange}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                                        >
                                                            <option>Gross Amount</option>
                                                            <option>Only Service Charge</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-2">
                                                    <label htmlFor="tax_description" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Tax Description
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            id="tax_description"
                                                            name="tax_description"
                                                            type="text"
                                                            required
                                                            value={tour.tax_description}
                                                            onChange={handleInvoiceFormChange}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-3">
                                                    <label htmlFor="tax_percentage" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Tax Percentage
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            id="tax_percentage"
                                                            name="tax_percentage"
                                                            type="number"
                                                            required
                                                            value={tour.tax_percentage}
                                                            onChange={handleInvoiceFormChange}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-3">
                                                    <label htmlFor="authorised_by" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Authorised By:
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            id="authorised_by"
                                                            name="authorised_by"
                                                            type="text"
                                                            required
                                                            value={tour.authorised_by}
                                                            onChange={handleInvoiceFormChange}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-span-full">
                                                    <label htmlFor="invoice_description" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Invoice Description (This will be the title in Invoice Bill Item)
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="text"
                                                            name="invoice_description"
                                                            id="invoice_description"
                                                            required
                                                            value={tour.invoice_description}
                                                            onChange={handleInvoiceFormChange}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-span-full">
                                                    <label htmlFor="payment_link" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Payment Link
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="text"
                                                            name="payment_link"
                                                            id="payment_link"
                                                            required
                                                            value={tour.payment_link}
                                                            onChange={handleInvoiceFormChange}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-span-full">
                                                    <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Comment
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="text"
                                                            name="invoice_comment"
                                                            id="invoice_comment"
                                                            value={tour.invoice_comment}
                                                            onChange={handleInvoiceFormChange}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="mt-6 flex items-center justify-end gap-x-6">
                                    {invoiceGenerated == 1 ?
                                        <div className="flex justify-between">
                                            {tour.status == "Unpaid" ? <button
                                                onClick={postPaidStatus}
                                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                Mark as Paid
                                            </button> : null}
                                            <button
                                                onClick={postInvoiceForm}
                                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                Re-Generate Invoice
                                            </button>
                                        </div> : null}
                                    {invoiceGenerated == 0 ? <button
                                        onClick={postInvoiceForm}
                                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Generate Invoice
                                    </button> : null}
                                </div>

                            </div> : null}

                        {reportTabs[2].current == true ?
                            <div className="mt-4 flow-root">
                                <div className="" style={{ justifyContent: "space-between" }}>
                                    <div className="flex justify-end">
                                        <button
                                            type="button"
                                            className="flex rounded-full bg-green-500 px-2 py-2 sm:py-5 text-xs sm:text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                            onClick={toggleAddPaymentLinkSlider}
                                        >Create Payment Link
                                        </button>
                                        <button
                                            type="button"
                                            className="flex rounded-full bg-green-500 px-2 py-2 sm:py-5 text-xs sm:text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                            onClick={toggleAddPaymentSlider}
                                        >Add Payment
                                        </button>
                                    </div>
                                </div>
                                <div className=" overflow-y-auto ">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                            Date
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                            Amount
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                            Transaction Number
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                            Method
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                    {Array.isArray(payments) ? payments.map((row) => (
                                                        <tr key={row.id}>
                                                            <td className="whitespace-nowrap text-sm text-gray-900">
                                                                {row.date}
                                                            </td>
                                                            <td className="whitespace-nowrap text-sm text-gray-900">{row.amount}</td>
                                                            <td className="whitespace-nowrap text-sm text-gray-900">{row.transaction_number}</td>
                                                            <td className="whitespace-nowrap text-sm text-gray-900">{row.method}</td>
                                                            <td>
                                                                {row.enable == true ? <button onClick={() => handlePrintPaymentReceipt(row)} style={{ marginRight: "1px" }} className="rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600" >Generate Receipt</button>
                                                                 :
                                                                 paymentReceiptdata != null ?
                                                                    <BlobProvider document={<Receipt receipt_data={paymentReceiptdata} logoBlob={logoBlob} />}>
                                                                        {({ url, blob }) => (
                                                                            <a href={url} target="_blank" style={{ borderRadius: '3px', border: '1px solid gray', display: 'flex', alignItems: 'center', gap: '2px', padding: '3px', fontSize: '16px', color: '#4f4f4f', fontWeight: 600, cursor: 'pointer', userSelect: 'none' }}>
                                                                                <HiOutlinePrinter size={16} />
                                                                                <span>Open Receipt</span>
                                                                            </a>
                                                                        )}
                                                                    </BlobProvider> : null}
                                                            </td>
                                                        </tr>
                                                    )) : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div> : null}
                        {reportTabs[3].current == true ?
                            <div className="mt-4 flow-root">
                                <div className="" style={{ justifyContent: "space-between" }}>
                                    <div className="flex justify-end">
                                        <button
                                            type="button"
                                            className="flex rounded-full bg-green-500 px-2 py-2 sm:py-5 text-xs sm:text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                            onClick={toggleAddTicketSlider}
                                        >Add Ticket
                                        </button>
                                    </div>
                                </div>
                                <div className=" overflow-y-auto ">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                            Description
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                    {Array.isArray(tickets) ? tickets.map((row) => (
                                                        <tr key={row.id}>
                                                            <td className="whitespace-nowrap text-sm text-gray-900">
                                                                {row.description}
                                                            </td>
                                                            <td>
                                                                <button onClick={() => openTicket(row.ticket)} style={{ marginRight: "1px" }} className="rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600" >Open Ticket</button>
                                                                <button onClick={() => deleteTicket(row)} style={{ marginRight: "1px" }} className="rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600" >Delete</button>
                                                            </td>
                                                        </tr>
                                                    )) : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div> : null}
                        {reportTabs[4].current == true ?
                            <div>
                                {marginData != null ? <div className="mt-6 flex items-center justify-end gap-x-6">
                                    <button onClick={exportToExcel}
                                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Save As Excel
                                    </button>
                                    <br></br>
                                </div>
                                    : null}
                                {marginData != null ?
                                    <div className=" overflow-y-auto ">
                                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <thead className="bg-gray-50">
                                                        <tr>
                                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                                Description
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                                Buy Price
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                                Sell/Quote Price
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5  text-sm font-semibold text-gray-900">
                                                                Margin
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                        {Array.isArray(marginData.items) ? marginData.items.map((row) => (
                                                            <tr className={row.Margin > 0 ? "bg-green-100" : "bg-red-100"}>
                                                                <td className="whitespace-nowrap text-sm text-gray-900">
                                                                    {row.Description}
                                                                </td>
                                                                <td className="whitespace-nowrap text-sm text-gray-900">{row.Buy_Price}</td>
                                                                <td className="whitespace-nowrap text-sm text-gray-900">{row.Quote_Price}</td>
                                                                <td className="whitespace-nowrap text-sm text-gray-900">{row.Margin}</td>
                                                            </tr>
                                                        )) : null}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                                {marginData == null ? <button
                                    onClick={createItemsMargin}
                                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Generate Margin Sheet
                                </button>
                                    : null}
                            </div> : null}
                    </div>
                </div> : null}

        </div>
    );
};

export default Tour;
