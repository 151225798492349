import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HeartIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { PaperClipIcon, PlusIcon } from '@heroicons/react/20/solid'
import axios from 'axios';
import Select from "react-tailwindcss-select";

const AddTransferForm = ({ open, close, tour_id }) => {

    //To Add the Flight Record (API Call)
    const postRecord = async (event) => {
        event.preventDefault();
        const formdata = new FormData(document.getElementById('addForm'));
        if (selectedPassenger == null) {
            alert("Please Select Passengers.")
        }
        else if (formdata.get("pickup_location").length == 0) {
            alert("Please Enter Activity Date.")
        }
        else if (formdata.get("drop_location").length == 0) {
            alert("Please Enter Activity Date.")
        }
        else if(formdata.get("quantity") < 1){
            alert("Please Enter At least 1 Quantity.")
        }
        else {
            const update_data = {
                passengers: selectedPassenger,
                pickup_location: formdata.get("pickup_location"),
                drop_location: formdata.get("drop_location"),
                pickup_datetime: formdata.get("pickup_datetime"),
                drop_datetime: formdata.get("drop_datetime"),
                vehicle: formdata.get("vehicle"),
                status: formdata.get("status"),
                buy_price: formdata.get("buy_price"),
                quote_price: formdata.get("quote_price"),
                quantity: formdata.get("quantity")
            };
            const data = { ...update_data, 'action': 'add_land_transfer', 'username': localStorage.getItem('user_id'), 'tours_id': tour_id };
            const token = localStorage.getItem('token');
            const config = { Authorization: `Bearer ${token}` };
            const response = await axios.post(`http://test-ilios-master.linkpc.net/api/addDocuments.php`, data, {
                headers: config
            });
            if (response.data.message == "Success") {
                document.location.reload();
            } else {
                alert(response.data.message);
            }
        }
    }

    //To store the passenger list options and selected passengers
    const [passengerList, setPassengerList] = useState(null);
    const [selectedPassenger, setSelectedPassenger] = useState(null);
    //To fetch passengers list for option
    useEffect(() => {
        fetchPassengers();
    }, [open]);

    const fetchPassengers = async () => {
        const data = { 'action': 'fetch_passengers_option', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data, {
            headers: config
        });
        setPassengerList(response.data);
    }

    const handleChangeSelectPassenger = (value) => {
        console.log(value);
        setSelectedPassenger(value);
    }
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={close}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto relative w-96">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-500"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                                            <button
                                                type="button"
                                                className=""
                                                onClick={close}
                                            >
                                                <span className="sr-only">Close panel</span>
                                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="h-full overflow-y-auto bg-white p-8">
                                        <h1 className="font-bold text-violet-500">Enter New Transfer Details</h1>
                                        <div className="mt-2">
                                            <form id='addForm' onSubmit={postRecord}>
                                                <h3 className="font-medium text-indigo-900">Select Passengers</h3>
                                                <Select
                                                    id="passengers"
                                                    name="passengers"
                                                    value={selectedPassenger}
                                                    options={passengerList}
                                                    isSearchable={true}
                                                    isMultiple={true}
                                                    isClearable={true}
                                                    primaryColor='black'
                                                    searchInputPlaceholder='Search'
                                                    onChange={handleChangeSelectPassenger}
                                                    classNames={{
                                                        searchIcon: `absolute w-5 h-5 mt-2.5 pb-0.5 ml-12 text-gray-500`,
                                                        tagItemIconContainer: `flex items-center px-2  cursor-pointer rounded-r-sm hover:bg-red-200 hover:text-red-600`,
                                                        tagItemIcon: `w-3 h-3 bg-white-500 `

                                                    }}
                                                />
                                                <h3 className="font-medium text-indigo-900">Pick-up Location</h3>
                                                <input
                                                    id="pickup_location"
                                                    name="pickup_location"
                                                    type="text"
                                                    required
                                                    placeholder="Pick-up Location *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Drop Location</h3>
                                                <input
                                                    id="drop_location"
                                                    name="drop_location"
                                                    type="text"
                                                    required
                                                    placeholder="Drop Location"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Pick-up Date/Time</h3>
                                                <input
                                                    id="pickup_datetime"
                                                    name="pickup_datetime"
                                                    type="datetime-local"
                                                    required
                                                    placeholder="Pick-up Date/Time *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Drop Date/Time</h3>
                                                <input
                                                    id="drop_datetime"
                                                    name="drop_datetime"
                                                    type="datetime-local"
                                                    required
                                                    placeholder="Drop Date/Time *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Vehicle</h3>
                                                <input
                                                    id="vehicle"
                                                    name="vehicle"
                                                    type="text"
                                                    required
                                                    placeholder="Vehicle *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Status</h3>
                                                <select
                                                    id="status"
                                                    name="status"
                                                    className="block w-full mt-2 py-2 pl-3 pr-10 mt-2 text-gray-900 border-0 rounded-md ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-50 sm:text-sm sm:leading-6"

                                                >
                                                    <option>Not Booked</option>
                                                    <option>Booked, To be Confirmed</option>
                                                    <option>Confirmed</option>
                                                </select>
                                                <h3 className="font-medium mt-2 text-indigo-900">Quantity</h3>
                                                <input
                                                    id="quantity"
                                                    name="quantity"
                                                    type="number"
                                                    required
                                                    placeholder="Quantity *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Buy Price (Paid By You)</h3>
                                                <input
                                                    id="buy_price"
                                                    name="buy_price"
                                                    type="number"
                                                    required
                                                    placeholder="Buy Price *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Quote Price (Paid By Customer To You)</h3>
                                                <input
                                                    id="quote_price"
                                                    name="quote_price"
                                                    type="number"
                                                    required
                                                    placeholder="Quote Price *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <div className="mt-2 flex">
                                                    <button
                                                        type="submit"
                                                        className="flex-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default AddTransferForm;