import { Fragment, useState, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HeartIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { PaperClipIcon, PlusIcon } from '@heroicons/react/20/solid'
import axios from "axios";
import Select from "react-tailwindcss-select";

const AddCustomerForm = ({ open, close }) => {
  //To store the mobile code
  const [mobileCode, setMobileCode] = useState(null);

  const handleChangeSelectMobileCode= value => {
    setMobileCode(value);
  };

  //To Add the Customer Record (API Call)
  const postRecord = async (event) => {
    event.preventDefault();
    const formdata = new FormData(document.getElementById('addForm'));
    const update_data = {
      name: formdata.get("name"),
      email: formdata.get("email"),
      mobile: formdata.get("mobile"),
      mobile_country_code:mobileCode.value,
      tax_id: formdata.get("tax_id") == ""? "NULL":formdata.get("tax_id"),
      type: formdata.get("type")
    };
    const data = { ...update_data,'action':'add_customers', 'username': localStorage.getItem('user_id') };
    const token = localStorage.getItem('token');
    const config = { Authorization: `Bearer ${token}`}
    const response = await axios.post(`http://test-ilios-master.linkpc.net/api/addDocuments.php`, data,{
      headers:config
    });
    if(response.data.message == "Success"){
      document.location.reload();
    }else{
      alert(response.data.message);
    }
  }
  
  const countriesWithMobile = [
    { "value": "93", "label": "Afghanistan (93)", "emojicode": "AF" },
    { "value": "355", "label": "Albania (355)", "emojicode": "AL" },
    { "value": "213", "label": "Algeria (213)", "emojicode": "DZ" },
    { "value": "376", "label": "Andorra (376)", "emojicode": "AD" },
    { "value": "244", "label": "Angola (244)", "emojicode": "AO" },
    { "value": "1268", "label": "Antigua and Barbuda (1268)", "emojicode": "AG" },
    { "value": "54", "label": "Argentina (54)", "emojicode": "AR" },
    { "value": "374", "label": "Armenia (374)", "emojicode": "AM" },
    { "value": "61", "label": "Australia (61)", "emojicode": "AU" },
    { "value": "43", "label": "Austria (43)", "emojicode": "AT" },
    { "value": "994", "label": "Azerbaijan (994)", "emojicode": "AZ" },
    { "value": "1242", "label": "Bahamas (1242)", "emojicode": "BS" },
    { "value": "973", "label": "Bahrain (973)", "emojicode": "BH" },
    { "value": "880", "label": "Bangladesh (880)", "emojicode": "BD" },
    { "value": "1246", "label": "Barbados (1246)", "emojicode": "BB" },
    { "value": "375", "label": "Belarus (375)", "emojicode": "BY" },
    { "value": "32", "label": "Belgium (32)", "emojicode": "BE" },
    { "value": "501", "label": "Belize (501)", "emojicode": "BZ" },
    { "value": "229", "label": "Benin (229)", "emojicode": "BJ" },
    { "value": "975", "label": "Bhutan (975)", "emojicode": "BT" },
    { "value": "591", "label": "Bolivia (591)", "emojicode": "BO" },
    { "value": "387", "label": "Bosnia and Herzegovina (387)", "emojicode": "BA" },
    { "value": "267", "label": "Botswana (267)", "emojicode": "BW" },
    { "value": "55", "label": "Brazil (55)", "emojicode": "BR" },
    { "value": "673", "label": "Brunei (673)", "emojicode": "BN" },
    { "value": "359", "label": "Bulgaria (359)", "emojicode": "BG" },
    { "value": "226", "label": "Burkina Faso (226)", "emojicode": "BF" },
    { "value": "257", "label": "Burundi (257)", "emojicode": "BI" },
    { "value": "855", "label": "Cambodia (855)", "emojicode": "KH" },
    { "value": "237", "label": "Cameroon (237)", "emojicode": "CM" },
    { "value": "1", "label": "Canada (1)", "emojicode": "CA" },
    { "value": "238", "label": "Cabo Verde (238)", "emojicode": "CV" },
    { "value": "236", "label": "Central African Republic (236)", "emojicode": "CF" },
    { "value": "235", "label": "Chad (235)", "emojicode": "TD" },
    { "value": "56", "label": "Chile (56)", "emojicode": "CL" },
    { "value": "86", "label": "China (86)", "emojicode": "CN" },
    { "value": "57", "label": "Colombia (57)", "emojicode": "CO" },
    { "value": "269", "label": "Comoros (269)", "emojicode": "KM" },
    { "value": "243", "label": "Congo, Democratic Republic of the (243)", "emojicode": "CD" },
    { "value": "242", "label": "Congo, Republic of the (242)", "emojicode": "CG" },
    { "value": "506", "label": "Costa Rica (506)", "emojicode": "CR" },
    { "value": "385", "label": "Croatia (385)", "emojicode": "HR" },
    { "value": "53", "label": "Cuba (53)", "emojicode": "CU" },
    { "value": "357", "label": "Cyprus (357)", "emojicode": "CY" },
    { "value": "420", "label": "Czech Republic (420)", "emojicode": "CZ" },
    { "value": "45", "label": "Denmark (45)", "emojicode": "DK" },
    { "value": "253", "label": "Djibouti (253)", "emojicode": "DJ" },
    { "value": "1767", "label": "Dominica (1767)", "emojicode": "DM" },
    { "value": "1809", "label": "Dominican Republic (1809)", "emojicode": "DO" },
    { "value": "593", "label": "Ecuador (593)", "emojicode": "EC" },
    { "value": "20", "label": "Egypt (20)", "emojicode": "EG" },
    { "value": "503", "label": "El Salvador (503)", "emojicode": "SV" },
    { "value": "240", "label": "Equatorial Guinea (240)", "emojicode": "GQ" },
    { "value": "291", "label": "Eritrea (291)", "emojicode": "ER" },
    { "value": "372", "label": "Estonia (372)", "emojicode": "EE" },
    { "value": "251", "label": "Ethiopia (251)", "emojicode": "ET" },
    { "value": "500", "label": "Falkland Islands (500)", "emojicode": "FK" },
    { "value": "298", "label": "Faroe Islands (298)", "emojicode": "FO" },
    { "value": "679", "label": "Fiji (679)", "emojicode": "FJ" },
    { "value": "358", "label": "Finland (358)", "emojicode": "FI" },
    { "value": "33", "label": "France (33)", "emojicode": "FR" },
    { "value": "594", "label": "French Guiana (594)", "emojicode": "GF" },
    { "value": "689", "label": "French Polynesia (689)", "emojicode": "PF" },
    { "value": "241", "label": "Gabon (241)", "emojicode": "GA" },
    { "value": "220", "label": "Gambia (220)", "emojicode": "GM" },
    { "value": "995", "label": "Georgia (995)", "emojicode": "GE" },
    { "value": "49", "label": "Germany (49)", "emojicode": "DE" },
    { "value": "233", "label": "Ghana (233)", "emojicode": "GH" },
    { "value": "350", "label": "Gibraltar (350)", "emojicode": "GI" },
    { "value": "30", "label": "Greece (30)", "emojicode": "GR" },
    { "value": "299", "label": "Greenland (299)", "emojicode": "GL" },
    { "value": "1473", "label": "Grenada (1473)", "emojicode": "GD" },
    { "value": "590", "label": "Guadeloupe (590)", "emojicode": "GP" },
    { "value": "1671", "label": "Guam (1671)", "emojicode": "GU" },
    { "value": "502", "label": "Guatemala (502)", "emojicode": "GT" },
    { "value": "224", "label": "Guinea (224)", "emojicode": "GN" },
    { "value": "245", "label": "GuineaBissau (245)", "emojicode": "GW" },
    { "value": "592", "label": "Guyana (592)", "emojicode": "GY" },
    { "value": "509", "label": "Haiti (509)", "emojicode": "HT" },
    { "value": "504", "label": "Honduras (504)", "emojicode": "HN" },
    { "value": "852", "label": "Hong Kong (852)", "emojicode": "HK" },
    { "value": "36", "label": "Hungary (36)", "emojicode": "HU" },
    { "value": "354", "label": "Iceland (354)", "emojicode": "IS" },
    { "value": "91", "label": "India (91)", "emojicode": "IN" },
    { "value": "62", "label": "Indonesia (62)", "emojicode": "ID" },
    { "value": "98", "label": "Iran (98)", "emojicode": "IR" },
    { "value": "964", "label": "Iraq (964)", "emojicode": "IQ" },
    { "value": "353", "label": "Ireland (353)", "emojicode": "IE" },
    { "value": "972", "label": "Israel (972)", "emojicode": "IL" },
    { "value": "39", "label": "Italy (39)", "emojicode": "IT" },
    { "value": "1876", "label": "Jamaica (1876)", "emojicode": "JM" },
    { "value": "81", "label": "Japan (81)", "emojicode": "JP" },
    { "value": "962", "label": "Jordan (962)", "emojicode": "JO" },
    { "value": "7", "label": "Kazakhstan (7)", "emojicode": "KZ" },
    { "value": "254", "label": "Kenya (254)", "emojicode": "KE" },
    { "value": "686", "label": "Kiribati (686)", "emojicode": "KI" },
    { "value": "850", "label": "Korea, North (850)", "emojicode": "KP" },
    { "value": "82", "label": "Korea, South (82)", "emojicode": "KR" },
    { "value": "383", "label": "Kosovo (383)", "emojicode": "XK" },
    { "value": "965", "label": "Kuwait (965)", "emojicode": "KW" },
    { "value": "996", "label": "Kyrgyzstan (996)", "emojicode": "KG" },
    { "value": "856", "label": "Laos (856)", "emojicode": "LA" },
    { "value": "371", "label": "Latvia (371)", "emojicode": "LV" },
    { "value": "961", "label": "Lebanon (961)", "emojicode": "LB" },
    { "value": "266", "label": "Lesotho (266)", "emojicode": "LS" },
    { "value": "231", "label": "Liberia (231)", "emojicode": "LR" },
    { "value": "218", "label": "Libya (218)", "emojicode": "LY" },
    { "value": "423", "label": "Liechtenstein (423)", "emojicode": "LI" },
    { "value": "370", "label": "Lithuania (370)", "emojicode": "LT" },
    { "value": "352", "label": "Luxembourg (352)", "emojicode": "LU" },
    { "value": "261", "label": "Madagascar (261)", "emojicode": "MG" },
    { "value": "265", "label": "Malawi (265)", "emojicode": "MW" },
    { "value": "60", "label": "Malaysia (60)", "emojicode": "MY" },
    { "value": "960", "label": "Maldives (960)", "emojicode": "MV" },
    { "value": "223", "label": "Mali (223)", "emojicode": "ML" },
    { "value": "356", "label": "Malta (356)", "emojicode": "MT" },
    { "value": "692", "label": "Marshall Islands (692)", "emojicode": "MH" },
    { "value": "222", "label": "Mauritania (222)", "emojicode": "MR" },
    { "value": "230", "label": "Mauritius (230)", "emojicode": "MU" },
    { "value": "52", "label": "Mexico (52)", "emojicode": "MX" },
    { "value": "691", "label": "Micronesia (691)", "emojicode": "FM" },
    { "value": "373", "label": "Moldova (373)", "emojicode": "MD" },
    { "value": "377", "label": "Monaco (377)", "emojicode": "MC" },
    { "value": "976", "label": "Mongolia (976)", "emojicode": "MN" },
    { "value": "382", "label": "Montenegro (382)", "emojicode": "ME" },
    { "value": "1664", "label": "Montserrat (1664)", "emojicode": "MS" },
    { "value": "212", "label": "Morocco (212)", "emojicode": "MA" },
    { "value": "258", "label": "Mozambique (258)", "emojicode": "MZ" },
    { "value": "95", "label": "Myanmar (Burma) (95)", "emojicode": "MM" },
    { "value": "264", "label": "Namibia (264)", "emojicode": "NA" },
    { "value": "674", "label": "Nauru (674)", "emojicode": "NR" },
    { "value": "977", "label": "Nepal (977)", "emojicode": "NP" },
    { "value": "31", "label": "Netherlands (31)", "emojicode": "NL" },
    { "value": "599", "label": "Netherlands Antilles (599)", "emojicode": "AN" },
    { "value": "687", "label": "New Caledonia (687)", "emojicode": "NC" },
    { "value": "64", "label": "New Zealand (64)", "emojicode": "NZ" },
    { "value": "505", "label": "Nicaragua (505)", "emojicode": "NI" },
    { "value": "227", "label": "Niger (227)", "emojicode": "NE" },
    { "value": "234", "label": "Nigeria (234)", "emojicode": "NG" },
    { "value": "683", "label": "Niue (683)", "emojicode": "NU" },
    { "value": "672", "label": "Norfolk Island (672)", "emojicode": "NF" },
    { "value": "1670", "label": "Northern Mariana Islands (1670)", "emojicode": "MP" },
    { "value": "47", "label": "Norway (47)", "emojicode": "NO" },
    { "value": "968", "label": "Oman (968)", "emojicode": "OM" },
    { "value": "92", "label": "Pakistan (92)", "emojicode": "PK" },
    { "value": "680", "label": "Palau (680)", "emojicode": "PW" },
    { "value": "970", "label": "Palestine (970)", "emojicode": "PS" },
    { "value": "507", "label": "Panama (507)", "emojicode": "PA" },
    { "value": "675", "label": "Papua New Guinea (675)", "emojicode": "PG" },
    { "value": "595", "label": "Paraguay (595)", "emojicode": "PY" },
    { "value": "51", "label": "Peru (51)", "emojicode": "PE" },
    { "value": "63", "label": "Philippines (63)", "emojicode": "PH" },
    { "value": "48", "label": "Poland (48)", "emojicode": "PL" },
    { "value": "351", "label": "Portugal (351)", "emojicode": "PT" },
    { "value": "1939", "label": "Puerto Rico (1939)", "emojicode": "PR" },
    { "value": "974", "label": "Qatar (974)", "emojicode": "QA" },
    { "value": "262", "label": "Reunion (262)", "emojicode": "RE" },
    { "value": "40", "label": "Romania (40)", "emojicode": "RO" },
    { "value": "7", "label": "Russia (7)", "emojicode": "RU" },
    { "value": "250", "label": "Rwanda (250)", "emojicode": "RW" },
    { "value": "590", "label": "Saint Barthelemy (590)", "emojicode": "BL" },
    { "value": "290", "label": "Saint Helena (290)", "emojicode": "SH" },
    { "value": "1869", "label": "Saint Kitts and Nevis (1869)", "emojicode": "KN" },
    { "value": "1758", "label": "Saint Lucia (1758)", "emojicode": "LC" },
    { "value": "590", "label": "Saint Martin (590)", "emojicode": "MF" },
    { "value": "508", "label": "Saint Pierre and Miquelon (508)", "emojicode": "PM" },
    { "value": "1784", "label": "Saint Vincent and the Grenadines (1784)", "emojicode": "VC" },
    { "value": "685", "label": "Samoa (685)", "emojicode": "WS" },
    { "value": "378", "label": "San Marino (378)", "emojicode": "SM" },
    { "value": "239", "label": "Sao Tome and Principe (239)", "emojicode": "ST" },
    { "value": "966", "label": "Saudi Arabia (966)", "emojicode": "SA" },
    { "value": "221", "label": "Senegal (221)", "emojicode": "SN" },
    { "value": "381", "label": "Serbia (381)", "emojicode": "RS" },
    { "value": "248", "label": "Seychelles (248)", "emojicode": "SC" },
    { "value": "232", "label": "Sierra Leone (232)", "emojicode": "SL" },
    { "value": "65", "label": "Singapore (65)", "emojicode": "SG" },
    { "value": "421", "label": "Slovakia (421)", "emojicode": "SK" },
    { "value": "386", "label": "Slovenia (386)", "emojicode": "SI" },
    { "value": "677", "label": "Solomon Islands (677)", "emojicode": "SB" },
    { "value": "252", "label": "Somalia (252)", "emojicode": "SO" },
    { "value": "27", "label": "South Africa (27)", "emojicode": "ZA" },
    { "value": "211", "label": "South Sudan (211)", "emojicode": "SS" },
    { "value": "34", "label": "Spain (34)", "emojicode": "ES" },
    { "value": "94", "label": "Sri Lanka (94)", "emojicode": "LK" },
    { "value": "249", "label": "Sudan (249)", "emojicode": "SD" },
    { "value": "597", "label": "Suriname (597)", "emojicode": "SR" },
    { "value": "268", "label": "Eswatini (268)", "emojicode": "SZ" },
    { "value": "46", "label": "Sweden (46)", "emojicode": "SE" },
    { "value": "41", "label": "Switzerland (41)", "emojicode": "CH" },
    { "value": "963", "label": "Syria (963)", "emojicode": "SY" },
    { "value": "886", "label": "Taiwan (886)", "emojicode": "TW" },
    { "value": "992", "label": "Tajikistan (992)", "emojicode": "TJ" },
    { "value": "255", "label": "Tanzania (255)", "emojicode": "TZ" },
    { "value": "66", "label": "Thailand (66)", "emojicode": "TH" },
    { "value": "228", "label": "Togo (228)", "emojicode": "TG" },
    { "value": "676", "label": "Tonga (676)", "emojicode": "TO" },
    { "value": "1868", "label": "Trinidad and Tobago (1868)", "emojicode": "TT" },
    { "value": "216", "label": "Tunisia (216)", "emojicode": "TN" },
    { "value": "90", "label": "Turkey (90)", "emojicode": "TR" },
    { "value": "993", "label": "Turkmenistan (993)", "emojicode": "TM" },
    { "value": "1649", "label": "Turks and Caicos Islands (1649)", "emojicode": "TC" },
    { "value": "688", "label": "Tuvalu (688)", "emojicode": "TV" },
    { "value": "256", "label": "Uganda (256)", "emojicode": "UG" },
    { "value": "380", "label": "Ukraine (380)", "emojicode": "UA" },
    { "value": "971", "label": "United Arab Emirates (971)", "emojicode": "AE" },
    { "value": "44", "label": "United Kingdom (44)", "emojicode": "GB" },
    { "value": "1", "label": "United States (1)", "emojicode": "US" },
    { "value": "598", "label": "Uruguay (598)", "emojicode": "UY" },
    { "value": "998", "label": "Uzbekistan (998)", "emojicode": "UZ" },
    { "value": "678", "label": "Vanuatu (678)", "emojicode": "VU" },
    { "value": "379", "label": "Vatican City (379)", "emojicode": "VA" },
    { "value": "58", "label": "Venezuela (58)", "emojicode": "VE" },
    { "value": "84", "label": "Vietnam (84)", "emojicode": "VN" },
    { "value": "681", "label": "Wallis and Futuna (681)", "emojicode": "WF" },
    { "value": "967", "label": "Yemen (967)", "emojicode": "YE" },
    { "value": "260", "label": "Zambia (260)", "emojicode": "ZM" },
    { "value": "263", "label": "Zimbabwe (263)", "emojicode": "ZW" }
];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-96">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className=""
                        onClick={close}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full overflow-y-auto bg-white p-8">
                    <h1 className="font-bold text-violet-500">Enter New Customer Details</h1>
                    <div className="mt-2">
                      <form id='addForm' onSubmit={postRecord}>
                        <h3 className="font-medium text-indigo-900">Customer Name</h3>
                        <input
                          id="name"
                          name="name"
                          type="text"
                          required
                          placeholder="Name"
                          className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                        >
                        </input>
                        <h3 className="font-medium mt-2 text-indigo-900">Email Address</h3>
                        <input
                          id="email"
                          name="email"
                          type="email"
                          required
                          placeholder="Email"
                          className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Mobile Country Code</h3>
                        <Select
                          id="mobile_country_code"
                          name="mobile_country_code"
                          value={mobileCode}
                          options={countriesWithMobile}
                          isSearchable={true}
                          primaryColor='black'
                          searchInputPlaceholder='Search'
                          onChange={handleChangeSelectMobileCode}
                          formatOptionLabel={data => (
                            <li
                              className={`block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${!data.isSelected
                                  ? `text-black`
                                  : `bg-blue-100 text-black`
                                }`}
                            >
                              <img src={`https://test-ilios-master.linkpc.net/assets/images/${data.emojicode.toLowerCase()}.png`} alt='flag' />
                              {data.label}
                            </li>
                          )}
                          classNames={{
                            searchIcon: `absolute w-5 h-5 mt-2.5 pb-0.5 ml-12 text-gray-500`,

                          }}
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Phone Number</h3>
                        <input
                          id="mobile"
                          name="mobile"
                          type="phone"
                          required
                          placeholder="+00"
                          className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Tax Registration Number</h3>
                        <input
                          id="tax_id"
                          name="tax_id"
                          type="text"
                          placeholder="GST/PAN etc.(optional)"
                          className="block w-full mt-2 py-2 pl-3 pr-10 mt-2 text-gray-900 border-0 rounded-md ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-50 sm:text-sm sm:leading-6"
                          defaultValue="Male"
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Type</h3>
                        <select
                          id="type"
                          name="type"
                          required
                          className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                        >
                          <option>Individual</option>
                          <option>Organisation</option>
                          <option>Travel Agency</option>
                        </select>
                        <div className="mt-2 flex">
                          <button
                            type="submit"
                            className="flex-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default AddCustomerForm;