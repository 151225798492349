import axios from "axios";
import React from "react";
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';

const LoginForm = () => {
    const [errMsg, setErrMsg] = React.useState(false);
    const [formData, setFormData] = React.useState({
        username: '',
        password: ''
    });

    const onChangeInput = (e) => {
        var value = e.target.value;
        if (e.target.name == 'password') {
            value = Base64.stringify(sha256(e.target.value));
        }
        setFormData({
            ...formData,
            [e.target.name]: value
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!Object.values(formData).every(val => val.trim() !== '')) {
            setErrMsg('Please Fill in all Required Fields!');
            return;
        }

        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/login.php`, formData);
        if (response.data == 'empty') {
            console.log('empty');
        }
        else if (response.data.message == "Login successful") {
            localStorage.setItem('user_id', response.data.username);
            localStorage.setItem('token',response.data.token);
            localStorage.setItem('avatar',response.data.avatar);
            document.location.reload();
        }
        else {
            setErrMsg("ID or Password is Incorrect.")
        }
    };
    return (
        <div className="flex flex-col justify-center flex-1 min-h-full">
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" action="#" method="POST">
                    <div>
                        <div className="flex items-center justify-between">
                            <label
                                htmlFor="UserName"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Enter Username
                            </label>
                        </div>
                        <div className="mt-2">
                            <input
                                id="username"
                                name="username"
                                type="text"
                                onChange={onChangeInput}
                                required
                                className="block w-full py-3 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label
                                htmlFor="password"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Password
                            </label>
                        </div>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                onChange={onChangeInput}
                                required
                                className="block w-full py-3 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        {errMsg && <div className="err-msg">{errMsg}</div>}
                        <button
                            type="submit"
                            onClick={handleSubmit}
                            className="flex justify-center w-full px-3 py-3 text-sm font-semibold leading-6 text-white bg-blue-600 rounded-md shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                        >
                            Sign in
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginForm;
