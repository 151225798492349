import React from "react";
import PdfToImageConverter from "../components/pdfConverter/pdfToImageConverter";
import ImageToPdfConverter from "../components/pdfConverter/imageToPdfConverter";
import axios from "axios";

const PdfConverterTool = () => {

    React.useEffect(() => {
        checkSession();
      }, []);
    
      const checkSession = async () => {
          const data = { 'username': localStorage.getItem('user_id') };
          const token = localStorage.getItem('token');
          const config = { Authorization: `Bearer ${token}` }
          const response = await axios.post(`http://test-ilios-master.linkpc.net/api/checkSession.php`, data, {
              headers: config
          });
          if (response.data.message == 'Invalid') {
            localStorage.removeItem('user_id');
            localStorage.removeItem('token');
            document.location.reload();
          }
      }

    const [tabs, selectTab] = React.useState([
        { name: 'PDF To Image', href: '#', current: true },
        { name: 'Image To PDF', href: '#', current: false },]);

    const setTab = (event, name) => {
        event.preventDefault();
        console.log(name);
        var i = 0;
        while (i < tabs.length) {
            var temp = [...tabs]
            if (tabs[i].name == name) {
                temp[i].current = true;
                selectTab(temp);
            } else {
                temp[i].current = false;
                selectTab(temp);
            }
            i++;
            console.log(i);
        }
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div >
            <div className="hidden sm:block">
                <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
                    {tabs.map((tab, tabIdx) => (
                        <button
                            key={tab.name}
                            onClick={(e) => setTab(e, tab.name)}
                            className={classNames(
                                tab.current ? 'text-gray-100 bg-indigo-900' : 'text-gray-900 hover:text-gray-700',
                                tabIdx === 0 ? 'rounded-l-lg' : '',
                                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                                'group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-center text-xs font-medium hover:bg-gray-50 hover:text-gray-900 focus:z-10'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                        >
                            <span>{tab.name}</span>
                        </button>
                    ))}
                </nav>
            </div>
            {tabs[0].current == true ?
                <PdfToImageConverter></PdfToImageConverter>: null}
            {tabs[1].current == true ?
                <ImageToPdfConverter></ImageToPdfConverter>: null}
        </div>
    );
};

export default PdfConverterTool;
