import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HeartIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { PaperClipIcon, PlusIcon } from '@heroicons/react/20/solid'
import axios from 'axios';
import Select from "react-tailwindcss-select";

const AddHotelVoucherForm = ({ open, close, inputs, tour_id }) => {

    const [record, setRecord] = useState({});

    //To Update the Values of the form with inputs received from parent
    useEffect(() => {
        setRecord(inputs);
        setPassengerList(inputs.passengers);
    }, [inputs])

    //Handle when Values are edited
    const handleFormChange = (event) => {
        setRecord({
            ...record,
            [event.target.name]: event.target.value,
        });
    };

    //To Update the Hotel Record (API Call)
    const postRecord = async (event) => {
        event.preventDefault();
        const formdata = new FormData(document.getElementById('editForm'));
        if (formdata.get("hotel_name").length == 0) {
            alert("Please Enter Hotel Name.")
        }
        else if (formdata.get("hotel_address").length == 0) {
            alert("Please Enter Hotel Address.")
        }
        else if (formdata.get("hotel_city").length == 0) {
            alert("Please Enter Hotel City.")
        }
        else if (formdata.get("hotel_country").length == 0) {
            alert("Please Enter Hotel Country.")
        }
        else if (formdata.get("checkin_datetime").length == 0) {
            alert("Please Enter Checkin Detail.")
        }
        else if (formdata.get("checkout_datetime").length == 0) {
            alert("Please Enter Checkout Detail.")
        }
        else if(formdata.get("booking_number").length == 0){
            alert("Please Enter Booking Number.")
        }
        else if(formdata.get("quantity") < 1){
            alert("Please Enter At least 1 Quantity.")
        }
        else{
            const update_data = {
                hotel_details_id:record.id,
                lead_passenger: selectedPassenger == null ? record.passengers:selectedPassenger,
                passenger_contact: formdata.get("passenger_contact"),
                hotel_name: formdata.get("hotel_name"),
                hotel_address: formdata.get("hotel_address"),
                hotel_city: formdata.get("hotel_city"),
                hotel_country: formdata.get("hotel_country"),
                booking_number: formdata.get("booking_number"),
                room_description: formdata.get("room_description"),
                checkin_datetime: formdata.get("checkin_datetime"),
                checkout_datetime: formdata.get("checkout_datetime"),
                quantity: formdata.get("quantity"),
                terms: formdata.get("terms")
            };
            const data = { ...update_data, 'action': 'add_hotel_voucher', 'username': localStorage.getItem('user_id'), 'tours_id': tour_id };
                const token = localStorage.getItem('token');
                const config = { Authorization: `Bearer ${token}` };
                const response = await axios.post(`http://test-ilios-master.linkpc.net/api/updateDocuments.php`, data, {
                    headers: config
                });
                if (response.data.message == "Success") {
                    document.location.reload();
                } else {
                    alert(response.data.message);
                }
                console.log(data);
        }
    }
    
        //To store the passenger list options and selected passengers
        const [passengerList, setPassengerList] = useState(null);
        const [selectedPassenger, setSelectedPassenger] = useState(null);
        
    
        const handleChangeSelectPassenger = (value) => {
            console.log(value);
            setSelectedPassenger(value);
        }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={close}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto relative w-96">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-500"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                                            <button
                                                type="button"
                                                className=""
                                                onClick={close}
                                            >
                                                <span className="sr-only">Close panel</span>
                                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="h-full overflow-y-auto bg-white p-8">
                                        <h1 className="font-bold text-violet-500">Enter Hotel Voucher Details</h1>
                                        <div className="mt-2">
                                            <form id='editForm' onSubmit={postRecord}>
                                                <h3 className="font-medium text-indigo-900">Select Lead Passenger</h3>
                                                <Select
                                                    id="passengers"
                                                    name="passengers"
                                                    value={selectedPassenger}
                                                    options={passengerList}
                                                    isSearchable={true}
                                                    isMultiple={false}
                                                    isClearable={true}
                                                    primaryColor='black'
                                                    searchInputPlaceholder='Search'
                                                    onChange={handleChangeSelectPassenger}
                                                    classNames={{
                                                        searchIcon: `absolute w-5 h-5 mt-2.5 pb-0.5 ml-12 text-gray-500`,
                                                        tagItemIconContainer : `flex items-center px-2  cursor-pointer rounded-r-sm hover:bg-red-200 hover:text-red-600`,
                                                        tagItemIcon : `w-3 h-3 bg-white-500 `

                                                    }}
                                                />
                                                <h3 className="font-medium text-indigo-900">Passenger Contact Detail(Include Country Code without +)</h3>
                                                <input
                                                    id="passenger_contact"
                                                    name="passenger_contact"
                                                    type="number"
                                                    required
                                                    value={record.passenger_contact}
                                                    onChange={handleFormChange}
                                                    placeholder="Passenger Contact"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium text-indigo-900">Hotel Name</h3>
                                                <input
                                                    id="hotel_name"
                                                    name="hotel_name"
                                                    type="text"
                                                    required
                                                    value={record.hotel_name}
                                                    onChange={handleFormChange}
                                                    placeholder="Hotel Name *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium text-indigo-900">Hotel Address</h3>
                                                <input
                                                    id="hotel_address"
                                                    name="hotel_address"
                                                    type="text"
                                                    required
                                                    value={record.hotel_address}
                                                    onChange={handleFormChange}
                                                    placeholder="Hotel Address *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium text-indigo-900">Hotel City</h3>
                                                <input
                                                    id="hotel_city"
                                                    name="hotel_city"
                                                    type="text"
                                                    required
                                                    value={record.hotel_city}
                                                    onChange={handleFormChange}
                                                    placeholder="Hotel City*"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium text-indigo-900">Hotel Country</h3>
                                                <input
                                                    id="hotel_country"
                                                    name="hotel_country"
                                                    type="text"
                                                    required
                                                    value={record.hotel_country}
                                                    onChange={handleFormChange}
                                                    placeholder="Hotel Country *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Room Description</h3>
                                                <input
                                                    id="room_description"
                                                    name="room_description"
                                                    type="text"
                                                    required
                                                    value={record.room_description}
                                                    onChange={handleFormChange}
                                                    placeholder="Room Type *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Booking Number</h3>
                                                <input
                                                    id="booking_number"
                                                    name="booking_number"
                                                    type="text"
                                                    value={record.booking_number}
                                                    onChange={handleFormChange}
                                                    placeholder="Booking Number"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Check-In Date/Time</h3>
                                                <input
                                                    id="checkin_datetime"
                                                    name="checkin_datetime"
                                                    type="date"
                                                    required
                                                    value={record.checkin_datetime}
                                                    onChange={handleFormChange}
                                                    placeholder="Check-In Date/Time *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Check-Out Date/Time</h3>
                                                <input
                                                    id="checkout_datetime"
                                                    name="checkout_datetime"
                                                    type="date"
                                                    required
                                                    value={record.checkout_datetime}
                                                    onChange={handleFormChange}
                                                    placeholder="Check-Out Date/Time *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Quantity</h3>
                                                <input
                                                    id="quantity"
                                                    name="quantity"
                                                    type="number"
                                                    required
                                                    value={record.quantity}
                                                    onChange={handleFormChange}
                                                    placeholder="Quantity *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Terms & Conditions</h3>
                                                <textarea
                                                    id="terms"
                                                    name="terms"
                                                    rows={5}
                                                    required
                                                    onChange={handleFormChange}
                                                    placeholder="Terms & Condition *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                >
                                                    {record.terms}
                                                </textarea>
                                                <div className="mt-2 flex">
                                                    <button
                                                        type="submit"
                                                        className="flex-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default AddHotelVoucherForm;