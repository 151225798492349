import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HeartIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Select from "react-tailwindcss-select";
import axios from 'axios';

const AddTourForm = ({ open, close }) => {
  //To store the customers list
  const [customersList, setCustomersList] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleChangeSelectCustomer = value => {
    setSelectedCustomer(value);
  };

  useEffect(() => {
    fetchCustomerList();
  }, []);

  const fetchCustomerList = async () => {
    const data = { 'action': 'fetch_customers_label', 'username': localStorage.getItem('user_id') };
    const token = localStorage.getItem('token');
    const config = { Authorization: `Bearer ${token}` }
    const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data, {
      headers: config
    });
    setCustomersList(response.data);
  }
  //To Add the Tour Record (API Call)
  const postRecord = async (event) => {

    event.preventDefault();
    const formdata = new FormData(document.getElementById('addForm'));
    const update_data = {
      customer: selectedCustomer.value.toString(),
      title: formdata.get("title"),
      adults_count: formdata.get("adults_count"),
      childs_count: formdata.get("childs_count"),
      toddlers_count: formdata.get("toddlers_count"),
      travel_start_date: formdata.get("travel_start_date"),
      origin: selectedOrigin.value,
      destination: selectedDestination.value,
      currency: selectedCurrency.value,
      status: selectedStatus.value
    };
    const data = { ...update_data, 'action': 'add_tour', 'username': localStorage.getItem('user_id') };
    const token = localStorage.getItem('token');
    const config = { Authorization: `Bearer ${token}` }
    const response = await axios.post(`http://test-ilios-master.linkpc.net/api/addDocuments.php`, data, {
      headers: config
    });
    if (response.data.message == "Success") {
      document.location.reload();
    } else {
      alert(response.data.message);
    }
  }

  const countriesWithFlags = [
    { "value": "Afghanistan", "label": "Afghanistan", "emojicode": "AF" },
    { "value": "Albania", "label": "Albania", "emojicode": "AL" },
    { "value": "Algeria", "label": "Algeria", "emojicode": "DZ" },
    { "value": "Andorra", "label": "Andorra", "emojicode": "AD" },
    { "value": "Angola", "label": "Angola", "emojicode": "AO" },
    { "value": "Antigua and Barbuda", "label": "Antigua and Barbuda", "emojicode": "AG" },
    { "value": "Argentina", "label": "Argentina", "emojicode": "AR" },
    { "value": "Armenia", "label": "Armenia", "emojicode": "AM" },
    { "value": "Australia", "label": "Australia", "emojicode": "AU" },
    { "value": "Austria", "label": "Austria", "emojicode": "AT" },
    { "value": "Azerbaijan", "label": "Azerbaijan", "emojicode": "AZ" },
    { "value": "Bahamas", "label": "Bahamas", "emojicode": "BS" },
    { "value": "Bahrain", "label": "Bahrain", "emojicode": "BH" },
    { "value": "Bangladesh", "label": "Bangladesh", "emojicode": "BD" },
    { "value": "Barbados", "label": "Barbados", "emojicode": "BB" },
    { "value": "Belarus", "label": "Belarus", "emojicode": "BY" },
    { "value": "Belgium", "label": "Belgium", "emojicode": "BE" },
    { "value": "Belize", "label": "Belize", "emojicode": "BZ" },
    { "value": "Benin", "label": "Benin", "emojicode": "BJ" },
    { "value": "Bhutan", "label": "Bhutan", "emojicode": "BT" },
    { "value": "Bolivia", "label": "Bolivia", "emojicode": "BO" },
    { "value": "Bosnia and Herzegovina", "label": "Bosnia and Herzegovina", "emojicode": "BA" },
    { "value": "Botswana", "label": "Botswana", "emojicode": "BW" },
    { "value": "Brazil", "label": "Brazil", "emojicode": "BR" },
    { "value": "Brunei", "label": "Brunei", "emojicode": "BN" },
    { "value": "Bulgaria", "label": "Bulgaria", "emojicode": "BG" },
    { "value": "Burkina Faso", "label": "Burkina Faso", "emojicode": "BF" },
    { "value": "Burundi", "label": "Burundi", "emojicode": "BI" },
    { "value": "Cabo Verde", "label": "Cabo Verde", "emojicode": "CV" },
    { "value": "Cambodia", "label": "Cambodia", "emojicode": "KH" },
    { "value": "Cameroon", "label": "Cameroon", "emojicode": "CM" },
    { "value": "Canada", "label": "Canada", "emojicode": "CA" },
    { "value": "Central African Republic", "label": "Central African Republic", "emojicode": "CF" },
    { "value": "Chad", "label": "Chad", "emojicode": "TD" },
    { "value": "Chile", "label": "Chile", "emojicode": "CL" },
    { "value": "China", "label": "China", "emojicode": "CN" },
    { "value": "Colombia", "label": "Colombia", "emojicode": "CO" },
    { "value": "Comoros", "label": "Comoros", "emojicode": "KM" },
    { "value": "Congo, Democratic Republic of the", "label": "Congo, Democratic Republic of the", "emojicode": "CD" },
    { "value": "Congo, Republic of the", "label": "Congo, Republic of the", "emojicode": "CG" },
    { "value": "Costa Rica", "label": "Costa Rica", "emojicode": "CR" },
    { "value": "Croatia", "label": "Croatia", "emojicode": "HR" },
    { "value": "Cuba", "label": "Cuba", "emojicode": "CU" },
    { "value": "Cyprus", "label": "Cyprus", "emojicode": "CY" },
    { "value": "Czech Republic", "label": "Czech Republic", "emojicode": "CZ" },
    { "value": "Denmark", "label": "Denmark", "emojicode": "DK" },
    { "value": "Djibouti", "label": "Djibouti", "emojicode": "DJ" },
    { "value": "Dominica", "label": "Dominica", "emojicode": "DM" },
    { "value": "Dominican Republic", "label": "Dominican Republic", "emojicode": "DO" },
    { "value": "Ecuador", "label": "Ecuador", "emojicode": "EC" },
    { "value": "Egypt", "label": "Egypt", "emojicode": "EG" },
    { "value": "El Salvador", "label": "El Salvador", "emojicode": "SV" },
    { "value": "Equatorial Guinea", "label": "Equatorial Guinea", "emojicode": "GQ" },
    { "value": "Eritrea", "label": "Eritrea", "emojicode": "ER" },
    { "value": "Estonia", "label": "Estonia", "emojicode": "EE" },
    { "value": "Eswatini", "label": "Eswatini", "emojicode": "SZ" },
    { "value": "Ethiopia", "label": "Ethiopia", "emojicode": "ET" },
    { "value": "Fiji", "label": "Fiji", "emojicode": "FJ" },
    { "value": "Finland", "label": "Finland", "emojicode": "FI" },
    { "value": "France", "label": "France", "emojicode": "FR" },
    { "value": "Gabon", "label": "Gabon", "emojicode": "GA" },
    { "value": "Gambia", "label": "Gambia", "emojicode": "GM" },
    { "value": "Georgia", "label": "Georgia", "emojicode": "GE" },
    { "value": "Germany", "label": "Germany", "emojicode": "DE" },
    { "value": "Ghana", "label": "Ghana", "emojicode": "GH" },
    { "value": "Greece", "label": "Greece", "emojicode": "GR" },
    { "value": "Grenada", "label": "Grenada", "emojicode": "GD" },
    { "value": "Guatemala", "label": "Guatemala", "emojicode": "GT" },
    { "value": "Guinea", "label": "Guinea", "emojicode": "GN" },
    { "value": "Guinea-Bissau", "label": "Guinea-Bissau", "emojicode": "GW" },
    { "value": "Guyana", "label": "Guyana", "emojicode": "GY" },
    { "value": "Haiti", "label": "Haiti", "emojicode": "HT" },
    { "value": "Honduras", "label": "Honduras", "emojicode": "HN" },
    { "value": "Hungary", "label": "Hungary", "emojicode": "HU" },
    { "value": "Iceland", "label": "Iceland", "emojicode": "IS" },
    { "value": "India", "label": "India", "emojicode": "IN" },
    { "value": "Indonesia", "label": "Indonesia", "emojicode": "ID" },
    { "value": "Iran", "label": "Iran", "emojicode": "IR" },
    { "value": "Iraq", "label": "Iraq", "emojicode": "IQ" },
    { "value": "Ireland", "label": "Ireland", "emojicode": "IE" },
    { "value": "Israel", "label": "Israel", "emojicode": "IL" },
    { "value": "Italy", "label": "Italy", "emojicode": "IT" },
    { "value": "Jamaica", "label": "Jamaica", "emojicode": "JM" },
    { "value": "Japan", "label": "Japan", "emojicode": "JP" },
    { "value": "Jordan", "label": "Jordan", "emojicode": "JO" },
    { "value": "Kazakhstan", "label": "Kazakhstan", "emojicode": "KZ" },
    { "value": "Kenya", "label": "Kenya", "emojicode": "KE" },
    { "value": "Kiribati", "label": "Kiribati", "emojicode": "KI" },
    { "value": "Korea, North", "label": "Korea, North", "emojicode": "KP" },
    { "value": "Korea, South", "label": "Korea, South", "emojicode": "KR" },
    { "value": "Kosovo", "label": "Kosovo", "emojicode": "XK" },
    { "value": "Kuwait", "label": "Kuwait", "emojicode": "KW" },
    { "value": "Kyrgyzstan", "label": "Kyrgyzstan", "emojicode": "KG" },
    { "value": "Laos", "label": "Laos", "emojicode": "LA" },
    { "value": "Latvia", "label": "Latvia", "emojicode": "LV" },
    { "value": "Lebanon", "label": "Lebanon", "emojicode": "LB" },
    { "value": "Lesotho", "label": "Lesotho", "emojicode": "LS" },
    { "value": "Liberia", "label": "Liberia", "emojicode": "LR" },
    { "value": "Libya", "label": "Libya", "emojicode": "LY" },
    { "value": "Liechtenstein", "label": "Liechtenstein", "emojicode": "LI" },
    { "value": "Lithuania", "label": "Lithuania", "emojicode": "LT" },
    { "value": "Luxembourg", "label": "Luxembourg", "emojicode": "LU" },
    { "value": "Madagascar", "label": "Madagascar", "emojicode": "MG" },
    { "value": "Malawi", "label": "Malawi", "emojicode": "MW" },
    { "value": "Malaysia", "label": "Malaysia", "emojicode": "MY" },
    { "value": "Maldives", "label": "Maldives", "emojicode": "MV" },
    { "value": "Mali", "label": "Mali", "emojicode": "ML" },
    { "value": "Malta", "label": "Malta", "emojicode": "MT" },
    { "value": "Marshall Islands", "label": "Marshall Islands", "emojicode": "MH" },
    { "value": "Mauritania", "label": "Mauritania", "emojicode": "MR" },
    { "value": "Mauritius", "label": "Mauritius", "emojicode": "MU" },
    { "value": "Mexico", "label": "Mexico", "emojicode": "MX" },
    { "value": "Micronesia", "label": "Micronesia", "emojicode": "FM" },
    { "value": "Moldova", "label": "Moldova", "emojicode": "MD" },
    { "value": "Monaco", "label": "Monaco", "emojicode": "MC" },
    { "value": "Mongolia", "label": "Mongolia", "emojicode": "MN" },
    { "value": "Montenegro", "label": "Montenegro", "emojicode": "ME" },
    { "value": "Morocco", "label": "Morocco", "emojicode": "MA" },
    { "value": "Mozambique", "label": "Mozambique", "emojicode": "MZ" },
    { "value": "Myanmar", "label": "Myanmar", "emojicode": "MM" },
    { "value": "Namibia", "label": "Namibia", "emojicode": "NA" },
    { "value": "Nauru", "label": "Nauru", "emojicode": "NR" },
    { "value": "Nepal", "label": "Nepal", "emojicode": "NP" },
    { "value": "Netherlands", "label": "Netherlands", "emojicode": "NL" },
    { "value": "New Zealand", "label": "New Zealand", "emojicode": "NZ" },
    { "value": "Nicaragua", "label": "Nicaragua", "emojicode": "NI" },
    { "value": "Niger", "label": "Niger", "emojicode": "NE" },
    { "value": "Nigeria", "label": "Nigeria", "emojicode": "NG" },
    { "value": "North Macedonia", "label": "North Macedonia", "emojicode": "MK" },
    { "value": "Norway", "label": "Norway", "emojicode": "NO" },
    { "value": "Oman", "label": "Oman", "emojicode": "OM" },
    { "value": "Pakistan", "label": "Pakistan", "emojicode": "PK" },
    { "value": "Palau", "label": "Palau", "emojicode": "PW" },
    { "value": "Palestine", "label": "Palestine", "emojicode": "PS" },
    { "value": "Panama", "label": "Panama", "emojicode": "PA" },
    { "value": "Papua New Guinea", "label": "Papua New Guinea", "emojicode": "PG" },
    { "value": "Paraguay", "label": "Paraguay", "emojicode": "PY" },
    { "value": "Peru", "label": "Peru", "emojicode": "PE" },
    { "value": "Philippines", "label": "Philippines", "emojicode": "PH" },
    { "value": "Poland", "label": "Poland", "emojicode": "PL" },
    { "value": "Portugal", "label": "Portugal", "emojicode": "PT" },
    { "value": "Qatar", "label": "Qatar", "emojicode": "QA" },
    { "value": "Romania", "label": "Romania", "emojicode": "RO" },
    { "value": "Russia", "label": "Russia", "emojicode": "RU" },
    { "value": "Rwanda", "label": "Rwanda", "emojicode": "RW" },
    { "value": "Saint Kitts and Nevis", "label": "Saint Kitts and Nevis", "emojicode": "KN" },
    { "value": "Saint Lucia", "label": "Saint Lucia", "emojicode": "LC" },
    { "value": "Saint Vincent and the Grenadines", "label": "Saint Vincent and the Grenadines", "emojicode": "VC" },
    { "value": "Samoa", "label": "Samoa", "emojicode": "WS" },
    { "value": "San Marino", "label": "San Marino", "emojicode": "SM" },
    { "value": "Sao Tome and Principe", "label": "Sao Tome and Principe", "emojicode": "ST" },
    { "value": "Saudi Arabia", "label": "Saudi Arabia", "emojicode": "SA" },
    { "value": "Senegal", "label": "Senegal", "emojicode": "SN" },
    { "value": "Serbia", "label": "Serbia", "emojicode": "RS" },
    { "value": "Seychelles", "label": "Seychelles", "emojicode": "SC" },
    { "value": "Sierra Leone", "label": "Sierra Leone", "emojicode": "SL" },
    { "value": "Singapore", "label": "Singapore", "emojicode": "SG" },
    { "value": "Slovakia", "label": "Slovakia", "emojicode": "SK" },
    { "value": "Slovenia", "label": "Slovenia", "emojicode": "SI" },
    { "value": "Solomon Islands", "label": "Solomon Islands", "emojicode": "SB" },
    { "value": "Somalia", "label": "Somalia", "emojicode": "SO" },
    { "value": "South Africa", "label": "South Africa", "emojicode": "ZA" },
    { "value": "South Sudan", "label": "South Sudan", "emojicode": "SS" },
    { "value": "Spain", "label": "Spain", "emojicode": "ES" },
    { "value": "Sri Lanka", "label": "Sri Lanka", "emojicode": "LK" },
    { "value": "Sudan", "label": "Sudan", "emojicode": "SD" },
    { "value": "Suriname", "label": "Suriname", "emojicode": "SR" },
    { "value": "Sweden", "label": "Sweden", "emojicode": "SE" },
    { "value": "Switzerland", "label": "Switzerland", "emojicode": "CH" },
    { "value": "Syria", "label": "Syria", "emojicode": "SY" },
    { "value": "Taiwan", "label": "Taiwan", "emojicode": "TW" },
    { "value": "Tajikistan", "label": "Tajikistan", "emojicode": "TJ" },
    { "value": "Tanzania", "label": "Tanzania", "emojicode": "TZ" },
    { "value": "Thailand", "label": "Thailand", "emojicode": "TH" },
    { "value": "Timor-Leste", "label": "Timor-Leste", "emojicode": "TL" },
    { "value": "Togo", "label": "Togo", "emojicode": "TG" },
    { "value": "Tonga", "label": "Tonga", "emojicode": "TO" },
    { "value": "Trinidad and Tobago", "label": "Trinidad and Tobago", "emojicode": "TT" },
    { "value": "Tunisia", "label": "Tunisia", "emojicode": "TN" },
    { "value": "Turkey", "label": "Turkey", "emojicode": "TR" },
    { "value": "Turkmenistan", "label": "Turkmenistan", "emojicode": "TM" },
    { "value": "Tuvalu", "label": "Tuvalu", "emojicode": "TV" },
    { "value": "Uganda", "label": "Uganda", "emojicode": "UG" },
    { "value": "Ukraine", "label": "Ukraine", "emojicode": "UA" },
    { "value": "United Arab Emirates", "label": "United Arab Emirates", "emojicode": "AE" },
    { "value": "United Kingdom", "label": "United Kingdom", "emojicode": "GB" },
    { "value": "United States", "label": "United States", "emojicode": "US" },
    { "value": "Uruguay", "label": "Uruguay", "emojicode": "UY" },
    { "value": "Uzbekistan", "label": "Uzbekistan", "emojicode": "UZ" },
    { "value": "Vanuatu", "label": "Vanuatu", "emojicode": "VU" },
    { "value": "Vatican City", "label": "Vatican City", "emojicode": "VA" },
    { "value": "Venezuela", "label": "Venezuela", "emojicode": "VE" },
    { "value": "Vietnam", "label": "Vietnam", "emojicode": "VN" },
    { "value": "Yemen", "label": "Yemen", "emojicode": "YE" },
    { "value": "Zambia", "label": "Zambia", "emojicode": "ZM" },
    { "value": "Zimbabwe", "label": "Zimbabwe", "emojicode": "ZW" }
  ];

  const currencies = [
    {"value":"INR","label":"Indian Rupee"},
    {"value":"USD","label":"US Dollar"},
    {"value":"AED","label":"Arab Emirates Dhiram"}
  ]; 

  const statuses = [
    {"value":"Paid","label":"Paid"},
    {"value":"Partial","label":"Partial"},
    {"value":"Unpaid","label":"Unpaid"}
  ];
  //to store origin country
  const [selectedOrigin, setSelectedOrigin] = useState(null);

  const handleChangeSelectOrigin = value => {
    setSelectedOrigin(value);
  };
  //to store destination country
  const [selectedDestination, setSelectedDestination] = useState(null);

  const handleChangeSelectDestination = value => {
    setSelectedDestination(value);
  };
  //to store currency
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const handleChangeSelectCurrency = value => {
    setSelectedCurrency(value);
  };
  //to store status
  const [selectedStatus, setSelectedStatus] = useState(null);

  const handleChangeSelectStatus = value => {
    setSelectedStatus(value);
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-96">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className=""
                        onClick={close}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full overflow-y-auto bg-white p-8">
                    <h1 className="font-bold text-violet-500">Enter New Tour Details</h1>
                    <div className="mt-2">
                      <form id='addForm' onSubmit={postRecord}>
                        <h3 className="font-medium text-indigo-900">Customer</h3>
                        <Select
                          id="customer"
                          name="customer"
                          value={selectedCustomer}
                          options={customersList}
                          isSearchable={true}
                          primaryColor='black'
                          searchInputPlaceholder='Search'
                          onChange={handleChangeSelectCustomer}
                          formatOptionLabel={data => (
                            <li
                              className={`block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${!data.isSelected
                                  ? `text-black`
                                  : `bg-blue-100 text-black`
                                }`}
                            >
                              {data.label}
                            </li>
                          )}
                          classNames={{
                            searchIcon: `absolute w-5 h-5 mt-2.5 pb-0.5 ml-12 text-gray-500`,

                          }}
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Title (Short Description Max:50 Characters)</h3>
                        <input
                          id="title"
                          name="title"
                          type="text"
                          maxLength={50}
                          required
                          placeholder="Adults Count"
                          className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Adults Travelling</h3>
                        <input
                          id="adults_count"
                          name="adults_count"
                          type="number"
                          required
                          placeholder="Adults Count"
                          className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Children Travelling (4+ yrs old)</h3>
                        <input
                          id="childs_count"
                          name="childs_count"
                          type="number"
                          required
                          placeholder="Childs Count"
                          className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Children Travelling (0-3 yrs old)</h3>
                        <input
                          id="toddlers_count"
                          name="toddlers_count"
                          type="number"
                          required
                          placeholder="Toddlers Count"
                          className="block w-full mt-2 py-2 pl-3 pr-10 mt-2 text-gray-900 border-0 rounded-md ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-50 sm:text-sm sm:leading-6"
                          defaultValue="Male"
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Date of Travel(Start)</h3>
                        <input
                          id="travel_start_date"
                          name="travel_start_date"
                          type="date"
                          required
                          className="mt-2 w-full"
                        >
                        </input>
                        <h3 className="font-medium mt-2 text-indigo-900">Origin</h3>
                        <Select
                          id="origin"
                          name="origin"
                          value={selectedOrigin}
                          options={countriesWithFlags}
                          isSearchable={true}
                          primaryColor='black'
                          searchInputPlaceholder='Search'
                          onChange={handleChangeSelectOrigin}
                          formatOptionLabel={data => (
                            <li
                              className={`block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${!data.isSelected
                                  ? `text-black`
                                  : `bg-blue-100 text-black`
                                }`}
                            >
                              <img src={`https://test-ilios-master.linkpc.net/assets/images/${data.emojicode.toLowerCase()}.png`} alt='flag' />
                              {data.label}
                            </li>
                          )}
                          classNames={{
                            searchIcon: `absolute w-5 h-5 mt-2.5 pb-0.5 ml-12 text-gray-500`,

                          }}
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Destination</h3>
                        <Select
                          id="destination"
                          name="destination"
                          value={selectedDestination}
                          options={countriesWithFlags}
                          isSearchable={true}
                          primaryColor='black'
                          searchInputPlaceholder='Search'
                          onChange={handleChangeSelectDestination}
                          formatOptionLabel={data => (
                            <li
                              className={`block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${!data.isSelected
                                  ? `text-black`
                                  : `bg-blue-100 text-black`
                                }`}
                            >
                              <img src={`https://test-ilios-master.linkpc.net/assets/images/${data.emojicode.toLowerCase()}.png`} alt='flag' />
                              {data.label}
                            </li>
                          )}
                          classNames={{
                            searchIcon: `absolute w-5 h-5 mt-2.5 pb-0.5 ml-12 text-gray-500`,

                          }}
                        />
                        <h3 className="font-medium text-indigo-900">Currency</h3>
                        <Select
                          id="currency"
                          name="currency"
                          value={selectedCurrency}
                          options={currencies}
                          isSearchable={true}
                          primaryColor='black'
                          searchInputPlaceholder='Search'
                          onChange={handleChangeSelectCurrency}
                          formatOptionLabel={data => (
                            <li
                              className={`block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${!data.isSelected
                                  ? `text-black`
                                  : `bg-blue-100 text-black`
                                }`}
                            >
                              {data.label}
                            </li>
                          )}
                          classNames={{
                            searchIcon: `absolute w-5 h-5 mt-2.5 pb-0.5 ml-12 text-gray-500`,

                          }}
                        />
                        <h3 className="font-medium text-indigo-900">Status</h3>
                        <Select
                          id="status"
                          name="status"
                          value={selectedStatus}
                          options={statuses}
                          isSearchable={true}
                          primaryColor='black'
                          searchInputPlaceholder='Search'
                          onChange={handleChangeSelectStatus}
                          formatOptionLabel={data => (
                            <li
                              className={`block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${!data.isSelected
                                  ? `text-black`
                                  : `bg-blue-100 text-black`
                                }`}
                            >
                              {data.label}
                            </li>
                          )}
                          classNames={{
                            searchIcon: `absolute w-5 h-5 mt-2.5 pb-0.5 ml-12 text-gray-500`,

                          }}
                        />
                        <div className="mt-2 flex">
                          <button
                            type="submit"
                            className="flex-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default AddTourForm;