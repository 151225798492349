import React, { Fragment } from 'react'
import { Image, Text, View, Page, Document, StyleSheet, Link } from '@react-pdf/renderer';

const Itinernary = ({ itinernary_data, logoBlob, terms }) => {


    const styles = StyleSheet.create({
        page: { fontSize: 11, paddingTop: 20, paddingLeft: 30, paddingRight: 30, paddingBottom: 50, lineHeight: 1.5, flexDirection: 'column' },

        spaceBetween: { flex: 2, flexDirection: 'row', justifyContent: 'space-between', color: "#3E3E3E" },

        logo: { width: 120, height: 60 },

        desc_png: { width: 45, height: 30, marginLeft: 5 },

        titleContainer: { flexDirection: 'row', marginTop: 10 },

        reportTitle: { fontSize: 12, textAlign: 'center' },

        addressTitle: { fontSize: 10 },

        itinernary: { fontWeight: 'bold', fontSize: 42 },

        itinernaryMisc: { fontSize: 14, fontWeight: 'bold' },

        data: { fontSize:12 },

        address: { fontWeight: 400, fontSize: 10 },

        footer: { position: 'absolute', width: '200%', bottom: 10, fontSize: 8, fontStyle: 'bold', paddingTop: 4, paddingLeft: 7, backgroundColor: itinernary_data.footer_tab_color, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },

        itinernaryDetail: { fontSize: 11, fontWeight: 'bold', paddingTop: 4, paddingLeft: 7, flex: 1, height: 25, backgroundColor: itinernary_data.detail_tab_color, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },

        theader: { fontSize: 11, fontStyle: 'bold', paddingTop: 4, paddingLeft: 7, flex: 0.5, height: 25, backgroundColor: itinernary_data.table_tab_color, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },

        theader2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },

        tbodyday: { fontSize: 12, fontWeight: 'bold', paddingTop: 4, paddingLeft: 7, paddingRight: 7, flex: 0.5, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1, borderLeftWidth: 1 },

        tbody: { fontSize: 9, paddingTop: 4, paddingLeft: 7, paddingRight: 7, flex: 0.5, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1, borderLeftWidth: 1 },

        tbodycolored: { fontSize: 9, paddingTop: 4, paddingLeft: 7, paddingRight: 7, flex: 0.5, backgroundColor: '#DEDEDE', borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1, borderLeftWidth: 1 },

        total: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 3, borderColor: 'whitesmoke', borderBottomWidth: 1, borderLeftWidth: 1 },

        tbody2: { flex: 2, borderRightWidth: 1, },

        tbodybold: { fontSize: 9, fontWeight: 'bold', paddingTop: 4, paddingLeft: 7, flex: 0.5, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1, borderLeftWidth: 1 }

    });

    const ItinernaryTitle = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    <Image style={styles.logo} src={logoBlob} />
                </View>
                <View>
                    <Text style={styles.itinernary}>ITINERNARY</Text>
                </View>
            </View>
        </View>
    );

    const ItinernaryToFrom = () => (
        <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
            <View style={styles.itinernaryDetail}>
                <Text >Customer:{itinernary_data.customer_name}</Text>
            </View>
            <View style={styles.itinernaryDetail}>
                <Text >Phone:{itinernary_data.customer_phone}</Text>
            </View>
            <View style={styles.itinernaryDetail}>
                <Text >Email:{itinernary_data.customer_email}</Text>
            </View>
        </View>
    );

    const ItinernaryTitle2 = () => (
        <View style={styles.titleContainer}>
            <View>
                <Text style={styles.itinernaryMisc}>{itinernary_data.title}</Text>
            </View>
        </View>
    );

    const TableHead = () => (
        <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
            <View style={styles.theader}>
                <Text>Day/Time</Text>
            </View>
            <View style={[styles.theader, styles.theader2]}>
                <Text>Description</Text>
            </View>
            <View style={[styles.theader]}>
                <Text>Note</Text>
            </View>
        </View>
    );

    const TableBody = () => (
        itinernary_data.items.map((day) => (
            <Fragment key={day.date}>
                <View style={{ width: '100%', flexDirection: 'row' }}>
                    <View >
                        <Text style={{ fontFamily: 'Times-Bold', fontSize: 13, marginTop: 5 }}>{day.date} </Text>
                    </View>
                </View>
                {day.steps.map((receipt) => (
                    <View style={{ width: '100%', flexDirection: 'row' }}>
                        {receipt.colored == false ? <View style={styles.tbody}>
                            <View style={{ width: '100%', flexDirection: 'row'}}>
                                <Text style={styles.data}>{receipt.time}</Text>
                                {receipt.type!=null ?<Image style={styles.desc_png} src={`https://test-ilios-master.linkpc.net/assets/icons/${receipt.type.toLowerCase()}.png`} />:null}
                            </View>
                        </View> :
                            <View style={styles.tbodycolored}>
                                <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
                                    <Text style={styles.data}>{receipt.time}</Text>
                                    {receipt.type!=null ?<Image style={styles.desc_png} src={`https://test-ilios-master.linkpc.net/assets/icons/${receipt.type.toLowerCase()}.png`} />:null}
                                </View>
                            </View>}

                        {receipt.colored == false ? <View style={[styles.tbody, styles.tbody2]}>
                            <Text style={styles.data}>{receipt.description}</Text>
                        </View> :
                            <View style={[styles.tbodycolored, styles.tbody2]}>
                                <Text style={styles.data}>{receipt.description}</Text>
                            </View>}
                        {receipt.colored == false ? <View style={[styles.tbody]}>
                            <Text style={styles.data}>{receipt.note}</Text>
                        </View> :
                            <View style={[styles.tbodycolored]}>
                                <Text style={styles.data}>{receipt.note}</Text>
                            </View>}
                    </View>
                ))}
            </Fragment>

        ))
    );

    const TermsTableHead = () => (
        <View style={{ width: '100%', flexDirection: 'row', marginTop: 25 }} wrap={false}>
            <View style={[styles.theader, styles.theader2]} wrap={false}>
                <Text wrap={false}>Terms & Conditions :</Text>
            </View>
        </View>
    );


    const TermsTableBody = () => (
        <Fragment key={1}>
            <View style={{ width: '100%', flexDirection: 'row' }}>
                <View style={styles.bodyIE}>
                    <Text >{itinernary_data.terms}</Text>
                </View>
            </View>
        </Fragment>
    );

    const ItinernaryFooter = () => (
        <View style={styles.footer} fixed>
            <Text >Contact Us:{itinernary_data.default_contact_footer_1}</Text>
            <Text >{itinernary_data.default_contact_footer_2}</Text>
        </View>
    );

    return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                <ItinernaryTitle />
                <ItinernaryToFrom />
                <ItinernaryTitle2 />
                <TableHead />
                <TableBody wrap />
                <TermsTableHead />
                <TermsTableBody wrap />
                <ItinernaryFooter fixed />
            </Page>
        </Document>

    )
}

export default Itinernary