import { Fragment, useState, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HeartIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { PaperClipIcon, PlusIcon } from '@heroicons/react/20/solid'
import axios from 'axios';
import Select from "react-tailwindcss-select";

const AddTicketForm = ({ open, close, tour_id }) => {

    //To Store Uploaded Files
    const [ticketFile, SetTicketFile] = useState("");

    const SetTicketFileHelper = (e) => {
        e.preventDefault();
        SetTicketFile(e.target.files[0]);
    }

    //To store name of server files
    const [ticketFileServer, SetTicketFileServer] = useState("default");

    //Use to set the button for upload of files or display status as uploaded.
    const [ticketUploaded, setTicketUploaded] = useState(false);

    //File Input using custom button style
    const hiddenFileInputTicket = useRef(null);
    const handleClickTicket = event => {
        hiddenFileInputTicket.current.click();
        setTicketUploaded(false);
    };

    //Handle Upload Call
    const uploadTicket = async () => {
        var fileSize = ticketFile.size / 1024;
        console.log(ticketFile);
        if (fileSize > 1024) {
            alert('File Size Exceeds 1MB Limit.');
        }
        else {
            const formData = new FormData();
            formData.append('pdf', ticketFile);
            const token = localStorage.getItem('token');
            const config = { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data', 'unique_id': tour_id };
            const response = await axios.post('http://test-ilios-master.linkpc.net/api/files/uploadTicket.php', formData, {
                headers: config
            });
            if (response.data.message == "Success") {
                setTicketUploaded(true);
                SetTicketFileServer(response.data.name);
            }
            else {
                alert('Error uploading file');
            }
        }
    }

    //To Add the Payment Record (API Call)
    const postRecord = async (event) => {
        event.preventDefault();
        const formdata = new FormData(document.getElementById('addForm'));

        const update_data = {
            description: formdata.get("description"),
            ticket: ticketFileServer
        };
        const data = { ...update_data, 'action': 'add_tour_ticket', 'username': localStorage.getItem('user_id'), 'tours_id': tour_id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` };
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/addDocuments.php`, data, {
            headers: config
        });
        if (response.data.message == "Success") {
            document.location.reload();
        } else {
            alert(response.data.message);
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={close}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto relative w-96">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-500"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                                            <button
                                                type="button"
                                                className=""
                                                onClick={close}
                                            >
                                                <span className="sr-only">Close panel</span>
                                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="h-full overflow-y-auto bg-white p-8">
                                        <h1 className="font-bold text-violet-500">Enter New Ticket Details</h1>
                                        <div className="mt-2">
                                            <form id='addForm' >
                                                <h3 className="font-medium text-indigo-900">Description</h3>
                                                <input
                                                    id="description"
                                                    name="description"
                                                    type="text"
                                                    placeholder='Brief About the type of ticket etc.'
                                                    required
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                            </form>
                                                <h3 className="font-medium mt-2 text-indigo-900">Upload Ticket</h3>
                                                <ul role="list" className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                                                    <li className="flex items-center justify-between py-3">
                                                        <div className="flex items-center">
                                                            <img
                                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbQiOeebAFaQFZv5UxhNfSzMsQiTaPShoWdPdHEsxTbulhu8CPTfzxHp0AQ6JvjHj_Iwg&usqp=CAU"
                                                                alt=""
                                                                className="h-8 w-8 rounded-full"
                                                            />

                                                            <button className="text-xs" onClick={handleClickTicket}>
                                                                <div className='flex'><PaperClipIcon className="h-5 w-5 pl-2 flex-shrink-0 text-gray-400" aria-hidden="true" /> Attach</div>
                                                            </button>
                                                            <text className='p-1 text-xs text-indigo-500' style={{ 'max-width': '10ch', 'text-overflow': 'ellipsis', 'overflow': 'hidden', 'white-space': 'nowrap' }}>{ticketFile.name}</text>
                                                            <input type='file' accept='pdf/*' id='ticket_file' name='ticket_file' onChange={(e) => { SetTicketFileHelper(e) }} ref={hiddenFileInputTicket} style={{ display: 'none' }}>
                                                            </input>
                                                            {ticketUploaded == false ?
                                                                <button
                                                                    type="button"
                                                                    className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                    onClick={() => uploadTicket()}
                                                                >
                                                                    Upload
                                                                </button>
                                                                :
                                                                <text className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                                    Uploaded
                                                                </text>}
                                                        </div>
                                                    </li>
                                                </ul>
                                            <div className="mt-2 flex">
                                                <button
                                                    onClick={(event) => postRecord(event)}
                                                    className="flex-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default AddTicketForm;