import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HeartIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { PaperClipIcon, PlusIcon } from '@heroicons/react/20/solid'
import axios from 'axios';
import Select from "react-tailwindcss-select";
const EditFlightForm = ({ open, close, inputs, tour_id }) => {

    const [record, setRecord] = useState({});

    //To Update the Values of the form with inputs received from parent
    useEffect(() => {
        setRecord(inputs);
        setSelectedPassenger(inputs.passengers);
        setSelectedOrigin(inputs.origin_airport);
        setSelectedDestination(inputs.destination_airport);
    }, [inputs])

    //Handle when Values are edited
    const handleFormChange = (event) => {
        setRecord({
            ...record,
            [event.target.name]: event.target.value,
        });
    };

    //To Update the Flight Record (API Call)
    const postRecord = async (event) => {
        event.preventDefault();
        const formdata = new FormData(document.getElementById('editForm'));
        if (formdata.get("departure_datetime").length == 0) {
            alert("Please Enter Departure Date.")
        }
        else if (formdata.get("arrival_datetime").length == 0) {
            alert("Please Enter Arrival Date.")
        }
        else if(formdata.get("status").length == 0){
            alert("Please Select Status.")
        }
        else if(formdata.get("quantity") < 1){
            alert("Please Enter At least 1 Quantity.")
        }
        else{
            const update_data = {
                id:record.id,
                passengers: selectedPassenger == null ? record.passengers:selectedPassenger,
                airline: formdata.get("airline"),
                pnr: formdata.get("pnr"),
                departure_datetime: formdata.get("departure_datetime"),
                arrival_datetime: formdata.get("arrival_datetime"),
                origin_airport: selectedOrigin == null ? record.origin_airport:selectedOrigin,
                destination_airport: selectedDestination == null ? record.destination_airport:selectedDestination,
                status: formdata.get("status"),
                buy_price: formdata.get("buy_price").length == 0?"0":formdata.get("buy_price"),
                quote_price: formdata.get("quote_price").length ==0?"0":formdata.get("quote_price"),
                quantity: formdata.get("quantity")
            };
            const data = { ...update_data, 'action': 'update_flight', 'username': localStorage.getItem('user_id'), 'tours_id': tour_id };
            const token = localStorage.getItem('token');
            const config = { Authorization: `Bearer ${token}` };
            const response = await axios.post(`http://test-ilios-master.linkpc.net/api/updateDocuments.php`, data, {
                headers: config
            });
            if (response.data.message == "Success") {
                document.location.reload();
            } else {
                alert(response.data.message);
            }
            console.log(data);
        }
    }
    
    //To store the passenger list options and selected passengers
    const [passengerList, setPassengerList] = useState(null);
    const [selectedPassenger, setSelectedPassenger] = useState(null);
    //To fetch passengers list for option
    useEffect(() => {
        fetchPassengers();
    }, [open]);

    const fetchPassengers = async () => {
        const data = { 'action': 'fetch_passengers_option', 'username': localStorage.getItem('user_id'), 'tour_id': tour_id };
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}` }
        const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data, {
            headers: config
        });
        setPassengerList(response.data);
    }

    const handleChangeSelectPassenger = (value) => {
        console.log(value);
        setSelectedPassenger(value);
    }

    //airport

    const airports = [
        {
            "value": "AAA",
            "icao_code": "NTGA",
            "label": "Anaa Airport , Anaa"
        },
        {
            "value": "AAB",
            "icao_code": "YARY",
            "label": "Arrabury Airport , Arrabury"
        },
        {
            "value": "AAC",
            "icao_code": "HEAR",
            "label": "El Arish International Airport , El Arish"
        },
        {
            "value": "AAD",
            "icao_code": "HCAD",
            "label": "Adado Airport , Adado"
        },
        {
            "value": "AAE",
            "icao_code": "DABB",
            "label": "Rabah Bitat Airport , Annaba"
        },
        {
            "value": "AAF",
            "icao_code": "KAAF",
            "label": "Apalachicola Regional Airport , Apalachicola"
        },
        {
            "value": "AAG",
            "icao_code": "SSYA",
            "label": "Arapoti Airport , Arapoti"
        },
        {
            "value": "AAH",
            "icao_code": "EDKA",
            "label": "Merzbr\u00fcck Airport , Aachen"
        },
        {
            "value": "AAI",
            "icao_code": "SWRA",
            "label": "Arraias Airport , Arraias"
        },
        {
            "value": "AAJ",
            "icao_code": "SMCA",
            "label": "Cayana Airstrip , Awaradam"
        },
        {
            "value": "AAK",
            "icao_code": "NGUK",
            "label": "Aranuka Airport , Aranuka"
        },
        {
            "value": "AAL",
            "icao_code": "EKYT",
            "label": "Aalborg Airport , Aalborg"
        },
        {
            "value": "AAM",
            "icao_code": "FAMD",
            "label": "Mala Mala Airport , Mala Mala"
        },
        {
            "value": "AAN",
            "icao_code": "OMAL",
            "label": "Al Ain International Airport , Al Ain"
        },
        {
            "value": "AAO",
            "icao_code": "SVAN",
            "label": "Anaco Airport , Anaco"
        },
        {
            "value": "AAP",
            "icao_code": "WALS",
            "label": "Aji Pangeran Tumenggung Pranoto International Airport , Samarinda"
        },
        {
            "value": "AAQ",
            "icao_code": "URKA",
            "label": "Anapa Airport , Anapa"
        },
        {
            "value": "AAR",
            "icao_code": "EKAH",
            "label": "Aarhus Airport , Aarhus"
        },
        {
            "value": "AAS",
            "icao_code": null,
            "label": "Apalapsili Airport , Apalapsili"
        },
        {
            "value": "AAT",
            "icao_code": "ZWAT",
            "label": "Altay Airport , Altay"
        },
        {
            "value": "AAU",
            "icao_code": "NSAU",
            "label": "Asau Airport , Asau"
        },
        {
            "value": "AAV",
            "icao_code": "RPMA",
            "label": "Allah Valley Airport , Surallah"
        },
        {
            "value": "AAX",
            "icao_code": "SBAX",
            "label": "Arax\u00e1 Airport , Arax\u00e1"
        },
        {
            "value": "AAY",
            "icao_code": "OYGD",
            "label": "Al Ghaydah Airport , Al Ghaydah"
        },
        {
            "value": "AAZ",
            "icao_code": "MGQZ",
            "label": "Quetzaltenango Airport , Quetzaltenango"
        },
        {
            "value": "ABA",
            "icao_code": "UNAA",
            "label": "Abakan International Airport , Abakan"
        },
        {
            "value": "ABB",
            "icao_code": "DNAS",
            "label": "Asaba International Airport , Asaba"
        },
        {
            "value": "ABC",
            "icao_code": "LEAB",
            "label": "Albacete Airport , Albacete"
        },
        {
            "value": "ABD",
            "icao_code": "OIAA",
            "label": "Abadan International Airport , Abadan"
        },
        {
            "value": "ABE",
            "icao_code": "KABE",
            "label": "Lehigh Valley International Airport , Allentown"
        },
        {
            "value": "ABF",
            "icao_code": "NGAB",
            "label": "Abaiang Atoll Airport , Abaiang"
        },
        {
            "value": "ABG",
            "icao_code": "YABI",
            "label": "Abingdon Airport , Abingdon Downs"
        },
        {
            "value": "ABH",
            "icao_code": "YAPH",
            "label": "Alpha Airport , Alpha"
        },
        {
            "value": "ABI",
            "icao_code": "KABI",
            "label": "Abilene Regional Airport , Abilene"
        },
        {
            "value": "ABJ",
            "icao_code": "DIAP",
            "label": "Port Bouet Airport , Abidjan"
        },
        {
            "value": "ABK",
            "icao_code": "HAKD",
            "label": "Kabri Dar Airport , Kebri Dahar"
        },
        {
            "value": "ABL",
            "icao_code": "PAFM",
            "label": "Ambler Airport , Ambler"
        },
        {
            "value": "ABM",
            "icao_code": "YNPE",
            "label": "Northern Peninsula Airport , Bamaga"
        },
        {
            "value": "ABN",
            "icao_code": "SMBN",
            "label": "Albina Airstrip , Albina"
        },
        {
            "value": "ABO",
            "icao_code": "DIAO",
            "label": "Aboisso Airport , Aboisso"
        },
        {
            "value": "ABP",
            "icao_code": null,
            "label": "Atkamba Airport , Atkamba"
        },
        {
            "value": "ABQ",
            "icao_code": "KABQ",
            "label": "Albuquerque International Sunport , Albuquerque"
        },
        {
            "value": "ABR",
            "icao_code": "KABR",
            "label": "Aberdeen Regional Airport , Aberdeen"
        },
        {
            "value": "ABS",
            "icao_code": "HEBL",
            "label": "Abu Simbel Airport , Abu Simbel"
        },
        {
            "value": "ABT",
            "icao_code": "OEBA",
            "label": "Al-Baha Domestic Airport , Al Bahah"
        },
        {
            "value": "ABU",
            "icao_code": "WATA",
            "label": "Haliwen Airport , Atambua"
        },
        {
            "value": "ABV",
            "icao_code": "DNAA",
            "label": "Nnamdi Azikiwe International Airport , Abuja"
        },
        {
            "value": "ABW",
            "icao_code": null,
            "label": "Abau Airport , Abau"
        },
        {
            "value": "ABX",
            "icao_code": "YMAY",
            "label": "Albury Airport , Albury"
        },
        {
            "value": "ABY",
            "icao_code": "KABY",
            "label": "Southwest Georgia Regional Airport , Albany"
        },
        {
            "value": "ABZ",
            "icao_code": "EGPD",
            "label": "Aberdeen Airport , Aberdeen"
        },
        {
            "value": "ACA",
            "icao_code": "MMAA",
            "label": "General Juan N. \u00c1lvarez International Airport , Acapulco"
        },
        {
            "value": "ACB",
            "icao_code": "KACB",
            "label": "Antrim County Airport , Bellaire"
        },
        {
            "value": "ACC",
            "icao_code": "DGAA",
            "label": "Kotoka International Airport , Accra"
        },
        {
            "value": "ACD",
            "icao_code": "SKAD",
            "label": "Alcides Fern\u00e1ndez Airport , Acand\u00ed"
        },
        {
            "value": "ACE",
            "icao_code": "GCRR",
            "label": "Lanzarote Airport , Lanzarote"
        },
        {
            "value": "ACH",
            "icao_code": "LSZR",
            "label": "St. Gallen\u2013Altenrhein Airport , Altenrhein"
        },
        {
            "value": "ACI",
            "icao_code": "EGJA",
            "label": "Alderney Airport , Alderney"
        },
        {
            "value": "ACJ",
            "icao_code": "VCCA",
            "label": "Anuradhapura Airport , Anuradhapura"
        },
        {
            "value": "ACK",
            "icao_code": "KACK",
            "label": "Nantucket Memorial Airport , Nantucket"
        },
        {
            "value": "ACL",
            "icao_code": null,
            "label": "Aguaclara Airport , Aguaclara"
        },
        {
            "value": "ACM",
            "icao_code": null,
            "label": "Arica Airport , Arica"
        },
        {
            "value": "ACN",
            "icao_code": "MMCC",
            "label": "Ciudad Acu\u00f1a International Airport , Ciudad Acu\u00f1a"
        },
        {
            "value": "ACP",
            "icao_code": "OITM",
            "label": "Sahand Airport , Maragheh"
        },
        {
            "value": "ACR",
            "icao_code": "SKAC",
            "label": "Araracuara Airport , Araracuara"
        },
        {
            "value": "ACS",
            "icao_code": "UNKS",
            "label": "Achinsk Airport , Achinsk"
        },
        {
            "value": "ACT",
            "icao_code": "KACT",
            "label": "Waco Regional Airport , Waco"
        },
        {
            "value": "ACU",
            "icao_code": null,
            "label": "Achutupo Airport , Achutupo"
        },
        {
            "value": "ACV",
            "icao_code": "KACV",
            "label": "Arcata-Eureka Airport , Eureka / Arcata"
        },
        {
            "value": "ACX",
            "icao_code": "ZUYI",
            "label": "Xingyi Wanfenglin Airport , Xingyi"
        },
        {
            "value": "ACY",
            "icao_code": "KACY",
            "label": "Atlantic City International Airport , Atlantic City"
        },
        {
            "value": "ACZ",
            "icao_code": "OIZB",
            "label": "Zabol Airport , Zabol"
        },
        {
            "value": "ADA",
            "icao_code": "LTAF",
            "label": "Adana \u015eakirpa\u015fa Airport , Adana"
        },
        {
            "value": "ADB",
            "icao_code": "LTBJ",
            "label": "Adnan Menderes Airport , \u0130zmir"
        },
        {
            "value": "ADC",
            "icao_code": "AYAN",
            "label": "Andakombe Airport , Andakombe"
        },
        {
            "value": "ADD",
            "icao_code": "HAAB",
            "label": "Bole International Airport , Addis Ababa"
        },
        {
            "value": "ADE",
            "icao_code": "OYAA",
            "label": "Aden International Airport , Aden"
        },
        {
            "value": "ADF",
            "icao_code": "LTCP",
            "label": "Ad\u0131yaman Airport , Ad\u0131yaman"
        },
        {
            "value": "ADG",
            "icao_code": "KADG",
            "label": "Lenawee County Airport , Adrian"
        },
        {
            "value": "ADH",
            "icao_code": "UEEA",
            "label": "Aldan Airport , Aldan"
        },
        {
            "value": "ADI",
            "icao_code": "FYAR",
            "label": "Arandis Airport , Arandis"
        },
        {
            "value": "ADJ",
            "icao_code": "OJAM",
            "label": "Amman Civil Airport , Amman"
        },
        {
            "value": "ADK",
            "icao_code": "PADK",
            "label": "Adak Airport , Adak Island"
        },
        {
            "value": "ADL",
            "icao_code": "YPAD",
            "label": "Adelaide Airport , Adelaide"
        },
        {
            "value": "ADM",
            "icao_code": "KADM",
            "label": "Ardmore Municipal Airport , Ardmore"
        },
        {
            "value": "ADN",
            "icao_code": "SKAN",
            "label": "Andes Airport , Andes"
        },
        {
            "value": "ADO",
            "icao_code": "YAMK",
            "label": "Andamooka Airport , Andamooka"
        },
        {
            "value": "ADP",
            "icao_code": "VCCG",
            "label": "Ampara Airport , Ampara"
        },
        {
            "value": "ADQ",
            "icao_code": "PADQ",
            "label": "Kodiak Airport , Kodiak"
        },
        {
            "value": "ADR",
            "icao_code": "KPHH",
            "label": "Robert F. Swinnie Airport , Andrews"
        },
        {
            "value": "ADS",
            "icao_code": "KADS",
            "label": "Addison Airport , Dallas"
        },
        {
            "value": "ADT",
            "icao_code": "KADH",
            "label": "Ada Municipal Airport , Ada"
        },
        {
            "value": "ADU",
            "icao_code": "OITL",
            "label": "Ardabil Airport , Ardabil"
        },
        {
            "value": "ADV",
            "icao_code": null,
            "label": "Ed Daein Airport , Ed Daein"
        },
        {
            "value": "ADW",
            "icao_code": "KADW",
            "label": "Andrews Field , Camp Springs"
        },
        {
            "value": "ADX",
            "icao_code": "EGQL",
            "label": "Leuchars Station , St Andrews"
        },
        {
            "value": "ADY",
            "icao_code": "FAAL",
            "label": "Alldays Airport , Alldays"
        },
        {
            "value": "ADZ",
            "icao_code": "SKSP",
            "label": "Gustavo Rojas Pinilla International Airport , San Andres Island"
        },
        {
            "value": "AEA",
            "icao_code": "NGTB",
            "label": "Abemama Atoll Airport , Abemama Atoll"
        },
        {
            "value": "AEB",
            "icao_code": "ZGBS",
            "label": "Baise Bama Airport , Baise"
        },
        {
            "value": "AEE",
            "icao_code": null,
            "label": "Adareil Airport , Adareil"
        },
        {
            "value": "AEG",
            "icao_code": "WIME",
            "label": "Aek Godang Airport , Padang Sidempuan"
        },
        {
            "value": "AEH",
            "icao_code": "FTTC",
            "label": "Ab\u00e9ch\u00e9 Airport , Ab\u00e9ch\u00e9"
        },
        {
            "value": "AEK",
            "icao_code": "AYAX",
            "label": "Aseki Airport , Aseki"
        },
        {
            "value": "AEL",
            "icao_code": "KAEL",
            "label": "Albert Lea Municipal Airport , Albert Lea"
        },
        {
            "value": "AEM",
            "icao_code": "UHTG",
            "label": "Amgu Airport , Amgu"
        },
        {
            "value": "AEO",
            "icao_code": "GQNA",
            "label": "Aioun el Atrouss Airport , Aioun el Atrouss"
        },
        {
            "value": "AEP",
            "icao_code": "SABE",
            "label": "Jorge Newbery Airpark , Buenos Aires"
        },
        {
            "value": "AEQ",
            "icao_code": null,
            "label": "Ar Horqin Airport , Ar Horqin Banner"
        },
        {
            "value": "AER",
            "icao_code": "URSS",
            "label": "Sochi International Airport , Sochi"
        },
        {
            "value": "AES",
            "icao_code": "ENAL",
            "label": "\u00c5lesund Airport, Vigra , \u00c5lesund"
        },
        {
            "value": "AET",
            "icao_code": "PFAL",
            "label": "Allakaket Airport , Allakaket"
        },
        {
            "value": "AEU",
            "icao_code": "OIBA",
            "label": "Abu Musa Airport , Abu Musa Island"
        },
        {
            "value": "AEX",
            "icao_code": "KAEX",
            "label": "Alexandria International Airport , Alexandria"
        },
        {
            "value": "AEY",
            "icao_code": "BIAR",
            "label": "Akureyri Airport , Akureyri"
        },
        {
            "value": "AFA",
            "icao_code": "SAMR",
            "label": "San Rafael Airport , San Rafael"
        },
        {
            "value": "AFD",
            "icao_code": "FAPA",
            "label": "Port Alfred Airport , Port Alfred"
        },
        {
            "value": "AFF",
            "icao_code": "KAFF",
            "label": "United States Air Force Academy Airfield , Colorado Springs"
        },
        {
            "value": "AFI",
            "icao_code": "SKAM",
            "label": "Amalfi Airport , Amalfi"
        },
        {
            "value": "AFK",
            "icao_code": null,
            "label": "Kondavattavan Tank Seaplane Base , Ampara"
        },
        {
            "value": "AFL",
            "icao_code": "SBAT",
            "label": "Alta Floresta Airport , Alta Floresta"
        },
        {
            "value": "AFN",
            "icao_code": "KAFN",
            "label": "Jaffrey Airport\u2013Silver Ranch , Jaffrey"
        },
        {
            "value": "AFO",
            "icao_code": "KAFO",
            "label": "Afton Municipal Airport , Afton"
        },
        {
            "value": "AFR",
            "icao_code": "AYAF",
            "label": "Afore Airstrip , Afore"
        },
        {
            "value": "AFS",
            "icao_code": "UTSN",
            "label": "Zarafshan Airport , Zarafshan"
        },
        {
            "value": "AFT",
            "icao_code": "AGAF",
            "label": "Afutara Airport , Afutara"
        },
        {
            "value": "AFW",
            "icao_code": "KAFW",
            "label": "Fort Worth Alliance Airport , Fort Worth"
        },
        {
            "value": "AFY",
            "icao_code": "LTAH",
            "label": "Afyon Airport , Afyon"
        },
        {
            "value": "AFZ",
            "icao_code": "OIMS",
            "label": "Sabzevar Airport , Sabzevar"
        },
        {
            "value": "AGA",
            "icao_code": "GMAD",
            "label": "Agadir\u2013Al Massira Airport , Agadir"
        },
        {
            "value": "AGB",
            "icao_code": "EDMA",
            "label": "Augsburg Airport , Augsburg"
        },
        {
            "value": "AGC",
            "icao_code": "KAGC",
            "label": "Allegheny County Airport , Pittsburgh"
        },
        {
            "value": "AGD",
            "icao_code": "WASG",
            "label": "Anggi Airport , Anggi"
        },
        {
            "value": "AGE",
            "icao_code": "EDWG",
            "label": "Wangerooge Airfield , Wangerooge"
        },
        {
            "value": "AGF",
            "icao_code": "LFBA",
            "label": "Agen La Garenne Airport , Agen"
        },
        {
            "value": "AGG",
            "icao_code": null,
            "label": "Angoram Airport , Angoram"
        },
        {
            "value": "AGH",
            "icao_code": "ESTA",
            "label": "\u00c4ngelholm\u2013Helsingborg Airport , \u00c4ngelholm"
        },
        {
            "value": "AGI",
            "icao_code": "SMWA",
            "label": "Wageningen Airstrip , Wageningen"
        },
        {
            "value": "AGJ",
            "icao_code": "RORA",
            "label": "Aguni Airport , Aguni"
        },
        {
            "value": "AGK",
            "icao_code": null,
            "label": "Kagua Airport , Kagua"
        },
        {
            "value": "AGL",
            "icao_code": "AYWG",
            "label": "Wanigela Airport , Wanigela"
        },
        {
            "value": "AGN",
            "icao_code": "PAGN",
            "label": "Angoon Seaplane Base , Angoon"
        },
        {
            "value": "AGO",
            "icao_code": "KAGO",
            "label": "Magnolia Municipal Airport , Magnolia"
        },
        {
            "value": "AGP",
            "icao_code": "LEMG",
            "label": "M\u00e1laga Airport , M\u00e1laga"
        },
        {
            "value": "AGQ",
            "icao_code": "LGAG",
            "label": "Agrinion Airport , Agrinion"
        },
        {
            "value": "AGR",
            "icao_code": "VIAG",
            "label": "Agra Airport , Agra"
        },
        {
            "value": "AGS",
            "icao_code": "KAGS",
            "label": "Augusta Regional Airport , Augusta"
        },
        {
            "value": "AGT",
            "icao_code": "SGES",
            "label": "Guaran\u00ed International Airport , Ciudad del Este"
        },
        {
            "value": "AGU",
            "icao_code": "MMAS",
            "label": "Lic. Jes\u00fas Ter\u00e1n Peredo International Airport , Aguascalientes"
        },
        {
            "value": "AGV",
            "icao_code": "SVAC",
            "label": "Oswaldo Guevara Mujica Airport , Acarigua"
        },
        {
            "value": "AGW",
            "icao_code": null,
            "label": "Agnew Airport , Agnew"
        },
        {
            "value": "AGX",
            "icao_code": "VOAT",
            "label": "Agatti Aerodrome , Agatti Island"
        },
        {
            "value": "AGZ",
            "icao_code": "FAAG",
            "label": "Aggeneys Airport , Aggeneys"
        },
        {
            "value": "AHB",
            "icao_code": "OEAB",
            "label": "Abha Regional Airport , Abha"
        },
        {
            "value": "AHC",
            "icao_code": "KAHC",
            "label": "Amedee Army Airfield , Herlong"
        },
        {
            "value": "AHD",
            "icao_code": null,
            "label": "Ardmore Downtown Executive Airport , Ardmore"
        },
        {
            "value": "AHE",
            "icao_code": "NTHE",
            "label": "Ahe Airport , Ahe"
        },
        {
            "value": "AHF",
            "icao_code": null,
            "label": "Arapahoe Municipal Airport , Arapahoe"
        },
        {
            "value": "AHH",
            "icao_code": "KAHH",
            "label": "Amery Municipal Airport , Amery"
        },
        {
            "value": "AHI",
            "icao_code": "WAPA",
            "label": "Amahai Airport , Amahai"
        },
        {
            "value": "AHJ",
            "icao_code": "ZUHY",
            "label": "Hongyuan Airport , Hongyuan"
        },
        {
            "value": "AHL",
            "icao_code": "SYAH",
            "label": "Aishalton Airport , Aishalton"
        },
        {
            "value": "AHM",
            "icao_code": null,
            "label": "Ashland Municipal Airport , Ashland"
        },
        {
            "value": "AHN",
            "icao_code": "KAHN",
            "label": "Athens Ben Epps Airport , Athens"
        },
        {
            "value": "AHO",
            "icao_code": "LIEA",
            "label": "Alghero-Fertilia Airport , Alghero"
        },
        {
            "value": "AHS",
            "icao_code": "MHAH",
            "label": "Ahuas Airport , Ahuas"
        },
        {
            "value": "AHU",
            "icao_code": "GMTA",
            "label": "Cherif Al Idrissi Airport , Al Hoceima"
        },
        {
            "value": "AHW",
            "icao_code": null,
            "label": "Saih Rawl Airport , Saih Rawl"
        },
        {
            "value": "AHY",
            "icao_code": null,
            "label": "Ambatolahy Airport , Ambatolahy"
        },
        {
            "value": "AHZ",
            "icao_code": "LFHU",
            "label": "Alpe d'Huez Airport , Alpe d'Huez"
        },
        {
            "value": "AIA",
            "icao_code": "KAIA",
            "label": "Alliance Municipal Airport , Alliance"
        },
        {
            "value": "AIC",
            "icao_code": null,
            "label": "Ailinglaplap Airok Airport , Airok"
        },
        {
            "value": "AID",
            "icao_code": "KAID",
            "label": "Anderson Municipal Airport , Anderson"
        },
        {
            "value": "AIE",
            "icao_code": "AYAO",
            "label": "Aiome Airport , Aiome"
        },
        {
            "value": "AIF",
            "icao_code": "SNAX",
            "label": "Assis Airport , Assis"
        },
        {
            "value": "AIG",
            "icao_code": "FEFY",
            "label": "Yalinga Airport , Yalinga"
        },
        {
            "value": "AIH",
            "icao_code": "AYAK",
            "label": "Aiambak Airport , Aiambak"
        },
        {
            "value": "AII",
            "icao_code": "HDAS",
            "label": "Ali-Sabieh Airport , Ali Sabieh"
        },
        {
            "value": "AIK",
            "icao_code": "KAIK",
            "label": "Aiken Municipal Airport , Aiken"
        },
        {
            "value": "AIL",
            "icao_code": null,
            "label": "Ailigand\u00ed Airport , Ailigand\u00ed"
        },
        {
            "value": "AIM",
            "icao_code": null,
            "label": "Ailuk Airport , Ailuk Atoll"
        },
        {
            "value": "AIN",
            "icao_code": "PAWI",
            "label": "Wainwright Airport , Fort Wainwright"
        },
        {
            "value": "AIO",
            "icao_code": "KAIO",
            "label": "Atlantic Municipal Airport , Atlantic"
        },
        {
            "value": "AIP",
            "icao_code": "VIAX",
            "label": "Adampur Airport , Jalandhar"
        },
        {
            "value": "AIR",
            "icao_code": "SSOU",
            "label": "Aripuan\u00e3 Airport , Aripuan\u00e3"
        },
        {
            "value": "AIS",
            "icao_code": "NGTR",
            "label": "Arorae Island Airport , Arorae Island"
        },
        {
            "value": "AIT",
            "icao_code": "NCAI",
            "label": "Aitutaki Airport , Aitutaki"
        },
        {
            "value": "AIU",
            "icao_code": "NCAT",
            "label": "Enua Airport , Atiu Island"
        },
        {
            "value": "AIV",
            "icao_code": "KAIV",
            "label": "George Downer Airport , Aliceville"
        },
        {
            "value": "AIW",
            "icao_code": null,
            "label": "Ai-Ais Airport , Ai-Ais"
        },
        {
            "value": "AIZ",
            "icao_code": "KAIZ",
            "label": "Lee C. Fine Memorial Airport , Lake Ozark"
        },
        {
            "value": "AJA",
            "icao_code": "LFKJ",
            "label": "Ajaccio Napoleon Bonaparte Airport , Ajaccio"
        },
        {
            "value": "AJF",
            "icao_code": "OESK",
            "label": "Al-Jawf Domestic Airport , Sakakah"
        },
        {
            "value": "AJI",
            "icao_code": "LTCO",
            "label": "A\u011fr\u0131 Airport , A\u011fr\u0131"
        },
        {
            "value": "AJJ",
            "icao_code": "GQNJ",
            "label": "Akjoujt Airport , Akjoujt"
        },
        {
            "value": "AJK",
            "icao_code": "OIHR",
            "label": "Arak Airport , Arak"
        },
        {
            "value": "AJL",
            "icao_code": "VELP",
            "label": "Lengpui Airport , Aizawl"
        },
        {
            "value": "AJN",
            "icao_code": "FMCV",
            "label": "Ouani Airport , Anjouan"
        },
        {
            "value": "AJR",
            "icao_code": "ESNX",
            "label": "Arvidsjaur Airport , Arvidsjaur"
        },
        {
            "value": "AJS",
            "icao_code": "MX86",
            "label": "Punta Abreojos Airstrip , Punta Abreojos"
        },
        {
            "value": "AJU",
            "icao_code": "SBAR",
            "label": "Santa Maria Airport , Aracaju"
        },
        {
            "value": "AJY",
            "icao_code": "DRZA",
            "label": "Mano Dayak International Airport , Agadez"
        },
        {
            "value": "AKA",
            "icao_code": "ZLAK",
            "label": "Ankang Wulipu Airport , Ankang"
        },
        {
            "value": "AKB",
            "icao_code": "PAAK",
            "label": "Atka Airport , Atka"
        },
        {
            "value": "AKC",
            "icao_code": "KAKR",
            "label": "Akron Fulton International Airport , Akron"
        },
        {
            "value": "AKD",
            "icao_code": "VAAK",
            "label": "Akola Airport , Akola"
        },
        {
            "value": "AKE",
            "icao_code": "FOGA",
            "label": "Akieni Airport , Aki\u00e9ni"
        },
        {
            "value": "AKF",
            "icao_code": "HLKF",
            "label": "Kufra Airport , Kufra"
        },
        {
            "value": "AKG",
            "icao_code": "AYGU",
            "label": "Anguganak Airport , Anguganak"
        },
        {
            "value": "AKH",
            "icao_code": "OEPS",
            "label": "Prince Sultan Air Base , Al-Kharj"
        },
        {
            "value": "AKI",
            "icao_code": "PFAK",
            "label": "Akiak Airport , Akiak"
        },
        {
            "value": "AKJ",
            "icao_code": "RJEC",
            "label": "Asahikawa Airport , Asahikawa"
        },
        {
            "value": "AKK",
            "icao_code": "PAKH",
            "label": "Akhiok Airport , Akhiok"
        },
        {
            "value": "AKL",
            "icao_code": "NZAA",
            "label": "Auckland Airport , Auckland"
        },
        {
            "value": "AKM",
            "icao_code": null,
            "label": "Zakouma Airport , Zakouma National Park"
        },
        {
            "value": "AKN",
            "icao_code": "PAKN",
            "label": "King Salmon Airport , King Salmon"
        },
        {
            "value": "AKO",
            "icao_code": "KAKO",
            "label": "Colorado Plains Regional Airport , Akron"
        },
        {
            "value": "AKP",
            "icao_code": "PAKP",
            "label": "Anaktuvuk Pass Airport , Anaktuvuk Pass"
        },
        {
            "value": "AKQ",
            "icao_code": "WIAG",
            "label": "Gunung Batin Airport , Astraksetra"
        },
        {
            "value": "AKR",
            "icao_code": "DNAK",
            "label": "Akure Airport , Akure"
        },
        {
            "value": "AKS",
            "icao_code": "AGGA",
            "label": "Auki Gwaunaru'u Airport , Auki"
        },
        {
            "value": "AKT",
            "icao_code": "LCRA",
            "label": "RAF Akrotiri , Akrotiri"
        },
        {
            "value": "AKU",
            "icao_code": "ZWAK",
            "label": "Aksu Airport , Aksu"
        },
        {
            "value": "AKV",
            "icao_code": "CYKO",
            "label": "Akulivik Airport , Akulivik"
        },
        {
            "value": "AKW",
            "icao_code": "OIAG",
            "label": "Aghajari Airport , Aghajari"
        },
        {
            "value": "AKX",
            "icao_code": "UATT",
            "label": "Aktobe Airport , Aktobe"
        },
        {
            "value": "AKY",
            "icao_code": "VYSW",
            "label": "Sittwe Airport , Sittwe"
        },
        {
            "value": "ALA",
            "icao_code": "UAAA",
            "label": "Almaty International Airport , Almaty"
        },
        {
            "value": "ALB",
            "icao_code": "KALB",
            "label": "Albany International Airport , Albany"
        },
        {
            "value": "ALC",
            "icao_code": "LEAL",
            "label": "Alicante\u2013Elche Airport , Alicante"
        },
        {
            "value": "ALD",
            "icao_code": "SPAR",
            "label": "Alerta Airport , Alerta"
        },
        {
            "value": "ALE",
            "icao_code": null,
            "label": "Alpine\u2013Casparis Municipal Airport , Alpine"
        },
        {
            "value": "ALF",
            "icao_code": "ENAT",
            "label": "Alta Airport , Alta"
        },
        {
            "value": "ALG",
            "icao_code": "DAAG",
            "label": "Houari Boumediene Airport , Algiers"
        },
        {
            "value": "ALH",
            "icao_code": "YABA",
            "label": "Albany Airport , Albany"
        },
        {
            "value": "ALI",
            "icao_code": "KALI",
            "label": "Alice International Airport , Alice"
        },
        {
            "value": "ALJ",
            "icao_code": "FAAB",
            "label": "Alexander Bay Airport , Alexander Bay"
        },
        {
            "value": "ALK",
            "icao_code": null,
            "label": "Asella Airport , Asella"
        },
        {
            "value": "ALL",
            "icao_code": "LIMG",
            "label": "Albenga Airport , Albenga"
        },
        {
            "value": "ALM",
            "icao_code": "KALM",
            "label": "Alamogordo\u2013White Sands Regional Airport , Alamogordo"
        },
        {
            "value": "ALN",
            "icao_code": "KALN",
            "label": "St. Louis Regional Airport , Alton"
        },
        {
            "value": "ALO",
            "icao_code": "KALO",
            "label": "Waterloo Regional Airport , Waterloo"
        },
        {
            "value": "ALP",
            "icao_code": "OSAP",
            "label": "Aleppo International Airport , Aleppo"
        },
        {
            "value": "ALQ",
            "icao_code": "SSLT",
            "label": "Alegrete Airport , Alegrete"
        },
        {
            "value": "ALR",
            "icao_code": "NZLX",
            "label": "Alexandra Aerodrome , Alexandra"
        },
        {
            "value": "ALS",
            "icao_code": "KALS",
            "label": "San Luis Valley Regional Airport , Alamosa"
        },
        {
            "value": "ALT",
            "icao_code": null,
            "label": "Alenquer Airport , Alenquer"
        },
        {
            "value": "ALU",
            "icao_code": "HCMA",
            "label": "Alula Airport , Alula"
        },
        {
            "value": "ALW",
            "icao_code": "KALW",
            "label": "Walla Walla Regional Airport , Walla Walla"
        },
        {
            "value": "ALX",
            "icao_code": "KALX",
            "label": "Thomas C. Russell Field , Alexander City"
        },
        {
            "value": "ALY",
            "icao_code": "HEAX",
            "label": "El Nouzha Airport , Alexandria"
        },
        {
            "value": "ALZ",
            "icao_code": null,
            "label": "Alitak Seaplane Base , Lazy Bay"
        },
        {
            "value": "AMA",
            "icao_code": "KAMA",
            "label": "Rick Husband Amarillo International Airport , Amarillo"
        },
        {
            "value": "AMB",
            "icao_code": "FMNE",
            "label": "Ambilobe Airport , Ambilobe"
        },
        {
            "value": "AMC",
            "icao_code": "FTTN",
            "label": "Am Timan Airport , Am Timan"
        },
        {
            "value": "AMD",
            "icao_code": "VAAH",
            "label": "Sardar Vallabhbhai Patel International Airport , Ahmedabad"
        },
        {
            "value": "AME",
            "icao_code": null,
            "label": "Alto Molocue Airport , Alto Molocue"
        },
        {
            "value": "AMF",
            "icao_code": "AYAA",
            "label": "Ama Airport , Ama"
        },
        {
            "value": "AMG",
            "icao_code": null,
            "label": "Amboin Airport , Amboin"
        },
        {
            "value": "AMH",
            "icao_code": "HAAM",
            "label": "Arba Minch Airport , Arba Minch"
        },
        {
            "value": "AMI",
            "icao_code": "WADA",
            "label": "Selaparang Airport , Mataram, Indonesia"
        },
        {
            "value": "AMJ",
            "icao_code": "SNAR",
            "label": "Almenara Airport , Almenara"
        },
        {
            "value": "AMK",
            "icao_code": null,
            "label": "Animas Air Park , Durango"
        },
        {
            "value": "AML",
            "icao_code": null,
            "label": "Puerto Armuelles Airport , Puerto Armuelles"
        },
        {
            "value": "AMM",
            "icao_code": "OJAI",
            "label": "Queen Alia International Airport , Amman"
        },
        {
            "value": "AMN",
            "icao_code": "KAMN",
            "label": "Gratiot Community Airport , Alma"
        },
        {
            "value": "AMO",
            "icao_code": "FTTU",
            "label": "Mao Airport , Mao"
        },
        {
            "value": "AMP",
            "icao_code": "FMSY",
            "label": "Ampanihy Airport , Ampanihy"
        },
        {
            "value": "AMQ",
            "icao_code": "WAPP",
            "label": "Pattimura Airport , Ambon"
        },
        {
            "value": "AMS",
            "icao_code": "EHAM",
            "label": "Amsterdam Airport Schiphol , Amsterdam"
        },
        {
            "value": "AMT",
            "icao_code": "YAMT",
            "label": "Amata Airport , Amata"
        },
        {
            "value": "AMU",
            "icao_code": "AYAM",
            "label": "Amanab Airport , Amanab"
        },
        {
            "value": "AMV",
            "icao_code": "ULDD",
            "label": "Amderma Airport , Amderma"
        },
        {
            "value": "AMW",
            "icao_code": "KAMW",
            "label": "Ames Municipal Airport , Ames"
        },
        {
            "value": "AMX",
            "icao_code": null,
            "label": "Ammaroo Airport , Ammaroo"
        },
        {
            "value": "AMY",
            "icao_code": null,
            "label": "Ambatomainty Airport , Ambatomainty"
        },
        {
            "value": "AMZ",
            "icao_code": "NZAR",
            "label": "Ardmore Airport , Ardmore"
        },
        {
            "value": "ANA",
            "icao_code": "DNAB",
            "label": "Angama Mara Airport , Angama Mara"
        },
        {
            "value": "ANB",
            "icao_code": "KANB",
            "label": "Anniston Regional Airport , Anniston"
        },
        {
            "value": "ANC",
            "icao_code": "PANC",
            "label": "Ted Stevens Anchorage International Airport , Anchorage"
        },
        {
            "value": "AND",
            "icao_code": "KAND",
            "label": "Anderson Regional Airport , Anderson"
        },
        {
            "value": "ANE",
            "icao_code": "LFJR",
            "label": "Angers \u2013 Loire Airport , Angers"
        },
        {
            "value": "ANF",
            "icao_code": "SCFA",
            "label": "Cerro Moreno International Airport , Antofagasta"
        },
        {
            "value": "ANG",
            "icao_code": "LFBU",
            "label": "Angoul\u00eame \u2013 Cognac International Airport , Angoul\u00eame"
        },
        {
            "value": "ANI",
            "icao_code": "PANI",
            "label": "Aniak Airport , Aniak"
        },
        {
            "value": "ANJ",
            "icao_code": "FCBZ",
            "label": "Zanaga Airport , Zanaga"
        },
        {
            "value": "ANK",
            "icao_code": "LTAD",
            "label": "Etimesgut Air Base , Ankara"
        },
        {
            "value": "ANL",
            "icao_code": null,
            "label": "Andulo Airport , Andulo"
        },
        {
            "value": "ANM",
            "icao_code": "FMNH",
            "label": "Antsirabato Airport , Antalaha"
        },
        {
            "value": "ANN",
            "icao_code": "PANT",
            "label": "Annette Island Airport , Annette Island"
        },
        {
            "value": "ANO",
            "icao_code": "FQAG",
            "label": "Angoche Airport , Angoche"
        },
        {
            "value": "ANP",
            "icao_code": "KANP",
            "label": "Lee Airport , Annapolis"
        },
        {
            "value": "ANQ",
            "icao_code": "KANQ",
            "label": "Tri-State Steuben County Airport , Angola"
        },
        {
            "value": "ANR",
            "icao_code": "EBAW",
            "label": "Antwerp International Airport , Antwerp"
        },
        {
            "value": "ANS",
            "icao_code": "SPHY",
            "label": "Andahuaylas Airport , Andahuaylas"
        },
        {
            "value": "ANU",
            "icao_code": "TAPA",
            "label": "V. C. Bird International Airport , Antigua"
        },
        {
            "value": "ANV",
            "icao_code": "PANV",
            "label": "Anvik Airport , Anvik"
        },
        {
            "value": "ANW",
            "icao_code": "KANW",
            "label": "Ainsworth Regional Airport , Ainsworth"
        },
        {
            "value": "ANX",
            "icao_code": "ENAN",
            "label": "And\u00f8ya Airport, Andenes , Andenes"
        },
        {
            "value": "ANY",
            "icao_code": "KANY",
            "label": "Anthony Municipal Airport , Anthony"
        },
        {
            "value": "ANZ",
            "icao_code": null,
            "label": "Angus Downs Airport , Angus Downs"
        },
        {
            "value": "AOA",
            "icao_code": null,
            "label": "Aroa Airport , Aroa"
        },
        {
            "value": "AOB",
            "icao_code": null,
            "label": "Annanberg Airport , Annanberg"
        },
        {
            "value": "AOC",
            "icao_code": "EDAC",
            "label": "Leipzig\u2013Altenburg Airport , Altenburg"
        },
        {
            "value": "AOD",
            "icao_code": null,
            "label": "Abou-De\u00efa Airport , Abou-De\u00efa"
        },
        {
            "value": "AOE",
            "icao_code": "LTBY",
            "label": "Anadolu Airport , Eski\u015fehir"
        },
        {
            "value": "AOG",
            "icao_code": "ZYAS",
            "label": "Anshan Teng'ao Airport , Anshan"
        },
        {
            "value": "AOH",
            "icao_code": "KAOH",
            "label": "Lima Allen County Airport , Lima"
        },
        {
            "value": "AOI",
            "icao_code": "LIPY",
            "label": "Ancona Falconara Airport , Ancona"
        },
        {
            "value": "AOJ",
            "icao_code": "RJSA",
            "label": "Aomori Airport , Aomori"
        },
        {
            "value": "AOK",
            "icao_code": "LGKP",
            "label": "Karpathos Island National Airport , Karpathos"
        },
        {
            "value": "AOL",
            "icao_code": "SARL",
            "label": "Paso de los Libres Airport , Paso de los Libres"
        },
        {
            "value": "AOM",
            "icao_code": null,
            "label": "Adam Airport , Adam"
        },
        {
            "value": "AON",
            "icao_code": null,
            "label": "Arona Airport , Arona"
        },
        {
            "value": "AOO",
            "icao_code": "KAOO",
            "label": "Altoona\u2013Blair County Airport , Altoona"
        },
        {
            "value": "AOP",
            "icao_code": null,
            "label": "Alf\u00e9rez FAP Alfredo Vladimir Sara Bauer Airport , Andoas"
        },
        {
            "value": "AOR",
            "icao_code": "WMKA",
            "label": "Sultan Abdul Halim Airport , Alor Setar"
        },
        {
            "value": "AOS",
            "icao_code": null,
            "label": "Amook Bay Seaplane Base , Amook Bay"
        },
        {
            "value": "AOT",
            "icao_code": "LIMW",
            "label": "Aosta Valley Airport , Aosta"
        },
        {
            "value": "AOU",
            "icao_code": "VLAP",
            "label": "Attapeu International Airport , Attapeu"
        },
        {
            "value": "APA",
            "icao_code": "KAPA",
            "label": "Centennial Airport , Denver"
        },
        {
            "value": "APB",
            "icao_code": "SLAP",
            "label": "Apolo Airport , Apolo"
        },
        {
            "value": "APC",
            "icao_code": "KAPC",
            "label": "Napa County Airport , Napa"
        },
        {
            "value": "APF",
            "icao_code": "KAPF",
            "label": "Naples Municipal Airport , Naples"
        },
        {
            "value": "APG",
            "icao_code": "KAPG",
            "label": "Phillips Army Airfield , Aberdeen Proving Ground"
        },
        {
            "value": "APH",
            "icao_code": "KAPH",
            "label": "A.P. Hill Army Airfield , Fort A.P. Hill"
        },
        {
            "value": "API",
            "icao_code": "SKAP",
            "label": "Captain Luis F. G\u00f3mez Ni\u00f1o Air Base , Apiay"
        },
        {
            "value": "APK",
            "icao_code": "NTGD",
            "label": "Apataki Airport , Apataki"
        },
        {
            "value": "APL",
            "icao_code": "FQNP",
            "label": "Nampula Airport , Nampula"
        },
        {
            "value": "APN",
            "icao_code": "KAPN",
            "label": "Alpena County Regional Airport , Alpena"
        },
        {
            "value": "APO",
            "icao_code": "SKLC",
            "label": "Antonio Rold\u00e1n Betancourt Airport , Apartad\u00f3"
        },
        {
            "value": "APP",
            "icao_code": null,
            "label": "Asapa Airport , Asapa"
        },
        {
            "value": "APQ",
            "icao_code": "SNAL",
            "label": "Arapiraca Airport , Arapiraca"
        },
        {
            "value": "APR",
            "icao_code": "AYPE",
            "label": "April River Airport , April River"
        },
        {
            "value": "APS",
            "icao_code": "SWNS",
            "label": "An\u00e1polis Airport , An\u00e1polis"
        },
        {
            "value": "APT",
            "icao_code": "KAPT",
            "label": "Marion County Airport , Jasper"
        },
        {
            "value": "APU",
            "icao_code": "SSAP",
            "label": "Apucarana Airport , Apucarana"
        },
        {
            "value": "APV",
            "icao_code": "KAPV",
            "label": "Apple Valley Airport , Apple Valley"
        },
        {
            "value": "APW",
            "icao_code": "NSFA",
            "label": "Faleolo International Airport , Apia"
        },
        {
            "value": "APX",
            "icao_code": "SSOG",
            "label": "Arapongas Airport , Arapongas"
        },
        {
            "value": "APY",
            "icao_code": "SNAI",
            "label": "Alto Parna\u00edba Airport , Alto Parna\u00edba"
        },
        {
            "value": "APZ",
            "icao_code": "SAHZ",
            "label": "Zapala Airport , Zapala"
        },
        {
            "value": "AQA",
            "icao_code": "SBAQ",
            "label": "Araraquara Airport , Araraquara"
        },
        {
            "value": "AQB",
            "icao_code": "MGQC",
            "label": "Quich\u00e9 Airport , Quich\u00e9"
        },
        {
            "value": "AQG",
            "icao_code": "ZSAQ",
            "label": "Anqing Tianzhushan Airport , Anqing"
        },
        {
            "value": "AQI",
            "icao_code": "OEPA",
            "label": "Al Qaisumah/Hafr Al Batin Airport , Qaisumah"
        },
        {
            "value": "AQJ",
            "icao_code": "OJAQ",
            "label": "King Hussein International Airport , Aqaba"
        },
        {
            "value": "AQM",
            "icao_code": null,
            "label": "Ariquemes Airport , Ariquemes"
        },
        {
            "value": "AQP",
            "icao_code": "SPQU",
            "label": "Rodr\u00edguez Ball\u00f3n International Airport , Arequipa"
        },
        {
            "value": "AQS",
            "icao_code": null,
            "label": "Saqani Airport , Saqani"
        },
        {
            "value": "AQY",
            "icao_code": null,
            "label": "Girdwood Airport , Girdwood"
        },
        {
            "value": "ARA",
            "icao_code": "KARA",
            "label": "Acadiana Regional Airport , New Iberia"
        },
        {
            "value": "ARB",
            "icao_code": "KARB",
            "label": "Ann Arbor Municipal Airport , Ann Arbor"
        },
        {
            "value": "ARC",
            "icao_code": "PARC",
            "label": "Arctic Village Airport , Arctic Village"
        },
        {
            "value": "ARD",
            "icao_code": "WATM",
            "label": "Alor Island Airport , Alor Island"
        },
        {
            "value": "ARE",
            "icao_code": "TJAB",
            "label": "Antonio (Nery) Juarbe Pol Airport , Arecibo"
        },
        {
            "value": "ARF",
            "icao_code": null,
            "label": "Acaricuara Airport , Acaricuara"
        },
        {
            "value": "ARG",
            "icao_code": "KARG",
            "label": "Walnut Ridge Regional Airport , Walnut Ridge"
        },
        {
            "value": "ARH",
            "icao_code": "ULAA",
            "label": "Talagi Airport , Arkhangelsk"
        },
        {
            "value": "ARI",
            "icao_code": "SCAR",
            "label": "Chacalluta International Airport , Arica"
        },
        {
            "value": "ARJ",
            "icao_code": "WAJA",
            "label": "Arso Airport , Arso"
        },
        {
            "value": "ARK",
            "icao_code": "HTAR",
            "label": "Arusha Airport , Arusha"
        },
        {
            "value": "ARL",
            "icao_code": "DFER",
            "label": "Arly Airport , Arly"
        },
        {
            "value": "ARM",
            "icao_code": "YARM",
            "label": "Armidale Airport , Armidale"
        },
        {
            "value": "ARN",
            "icao_code": "ESSA",
            "label": "Stockholm Arlanda Airport , Stockholm"
        },
        {
            "value": "ARO",
            "icao_code": null,
            "label": "Arboletes Airport , Arboletes"
        },
        {
            "value": "ARP",
            "icao_code": null,
            "label": "Aragip Airport , Aragip"
        },
        {
            "value": "ARQ",
            "icao_code": "SKAT",
            "label": "El Troncal Airport , Arauquita"
        },
        {
            "value": "ARR",
            "icao_code": "SAVR",
            "label": "Alto R\u00edo Senguer Airport , Alto R\u00edo Senguer"
        },
        {
            "value": "ARS",
            "icao_code": "SWEC",
            "label": "Aragar\u00e7as Airport , Aragar\u00e7as"
        },
        {
            "value": "ART",
            "icao_code": "KART",
            "label": "Watertown International Airport , Watertown"
        },
        {
            "value": "ARU",
            "icao_code": "SBAU",
            "label": "Ara\u00e7atuba Airport , Ara\u00e7atuba"
        },
        {
            "value": "ARV",
            "icao_code": "KARV",
            "label": "Lakeland Airport , Minocqua"
        },
        {
            "value": "ARW",
            "icao_code": "LRAR",
            "label": "Arad International Airport , Arad"
        },
        {
            "value": "ARX",
            "icao_code": "SBAC",
            "label": "Aracati Airport , Aracati"
        },
        {
            "value": "ARY",
            "icao_code": "YARA",
            "label": "Ararat Airport , Ararat"
        },
        {
            "value": "ARZ",
            "icao_code": "FNZE",
            "label": "N'zeto Airport , N'zeto"
        },
        {
            "value": "ASA",
            "icao_code": "HHSB",
            "label": "Assab International Airport , Assab"
        },
        {
            "value": "ASB",
            "icao_code": "UTAA",
            "label": "Ashgabat International Airport , Ashgabat"
        },
        {
            "value": "ASC",
            "icao_code": "SLAS",
            "label": "Ascenci\u00f3n de Guarayos Airport , Ascenci\u00f3n de Guarayos"
        },
        {
            "value": "ASD",
            "icao_code": "MYAF",
            "label": "Andros Town International Airport , Andros Town"
        },
        {
            "value": "ASE",
            "icao_code": "KASE",
            "label": "Aspen\u2013Pitkin County Airport , Aspen"
        },
        {
            "value": "ASF",
            "icao_code": "URWA",
            "label": "Narimanovo Airport , Astrakhan"
        },
        {
            "value": "ASG",
            "icao_code": "NZAS",
            "label": "Ashburton Aerodrome , Ashburton"
        },
        {
            "value": "ASH",
            "icao_code": "KASH",
            "label": "Nashua Airport , Nashua"
        },
        {
            "value": "ASI",
            "icao_code": "FHAW",
            "label": "RAF Ascension Island , Ascension Island"
        },
        {
            "value": "ASJ",
            "icao_code": "RJKA",
            "label": "Amami Airport , Amami"
        },
        {
            "value": "ASK",
            "icao_code": "DIYO",
            "label": "Yamoussoukro Airport , Yamoussoukro"
        },
        {
            "value": "ASL",
            "icao_code": "KASL",
            "label": "Harrison County Airport , Marshall"
        },
        {
            "value": "ASM",
            "icao_code": "HHAS",
            "label": "Asmara International Airport , Asmara"
        },
        {
            "value": "ASN",
            "icao_code": "KASN",
            "label": "Talladega Municipal Airport , Talladega"
        },
        {
            "value": "ASO",
            "icao_code": "HASO",
            "label": "Asosa Airport , Asosa"
        },
        {
            "value": "ASP",
            "icao_code": "YBAS",
            "label": "Alice Springs Airport , Alice Springs"
        },
        {
            "value": "ASQ",
            "icao_code": null,
            "label": "Austin Airport , Austin"
        },
        {
            "value": "ASR",
            "icao_code": "LTAU",
            "label": "Erkilet International Airport , Kayseri"
        },
        {
            "value": "AST",
            "icao_code": "KAST",
            "label": "Astoria Regional Airport , Astoria"
        },
        {
            "value": "ASU",
            "icao_code": "SGAS",
            "label": "Silvio Pettirossi International Airport , Asunci\u00f3n"
        },
        {
            "value": "ASV",
            "icao_code": "HKAM",
            "label": "Amboseli Airport , Amboseli"
        },
        {
            "value": "ASW",
            "icao_code": "HESN",
            "label": "Aswan International Airport , Aswan"
        },
        {
            "value": "ASX",
            "icao_code": "KASX",
            "label": "John F. Kennedy Memorial Airport , Ashland"
        },
        {
            "value": "ASY",
            "icao_code": "KASY",
            "label": "Ashley Municipal Airport , Ashley"
        },
        {
            "value": "ASZ",
            "icao_code": null,
            "label": "Asirim Airport , Asirim"
        },
        {
            "value": "ATA",
            "icao_code": "SPHZ",
            "label": "Comandante FAP Germ\u00e1n Arias Graziani Airport , Huaraz"
        },
        {
            "value": "ATB",
            "icao_code": "HSAT",
            "label": "Atbara Airport , Atbara"
        },
        {
            "value": "ATC",
            "icao_code": "MYCA",
            "label": "Arthur's Town Airport , Arthur's Town"
        },
        {
            "value": "ATD",
            "icao_code": "AGAT",
            "label": "Uru Harbour Airport , Atoifi"
        },
        {
            "value": "ATE",
            "icao_code": null,
            "label": "Antlers Municipal Airport , Antlers"
        },
        {
            "value": "ATF",
            "icao_code": "SEAM",
            "label": "Chachoan Airport , Ambato"
        },
        {
            "value": "ATG",
            "icao_code": null,
            "label": "PAF Base Minhas , Attock"
        },
        {
            "value": "ATH",
            "icao_code": "LGAV",
            "label": "Athens International Airport , Athens"
        },
        {
            "value": "ATI",
            "icao_code": "SUAG",
            "label": "Artigas International Airport , Artigas"
        },
        {
            "value": "ATJ",
            "icao_code": "FMME",
            "label": "Antsirabe Airport , Antsirabe"
        },
        {
            "value": "ATK",
            "icao_code": "PATQ",
            "label": "Atqasuk Edward Burnell Sr. Memorial Airport , Atqasuk"
        },
        {
            "value": "ATL",
            "icao_code": "KATL",
            "label": "Hartsfield\u2013Jackson Atlanta International Airport , Atlanta"
        },
        {
            "value": "ATM",
            "icao_code": "SBHT",
            "label": "Altamira Airport , Altamira"
        },
        {
            "value": "ATN",
            "icao_code": "AYNX",
            "label": "Namatanai Airport , Namatanai"
        },
        {
            "value": "ATO",
            "icao_code": "KUNI",
            "label": "Ohio University Airport , Athens"
        },
        {
            "value": "ATP",
            "icao_code": "AYAI",
            "label": "Aitape Airstrip , Aitape"
        },
        {
            "value": "ATQ",
            "icao_code": "VIAR",
            "label": "Sri Guru Ram Dass Jee International Airport , Amritsar"
        },
        {
            "value": "ATR",
            "icao_code": "GQPA",
            "label": "Atar International Airport , Atar"
        },
        {
            "value": "ATS",
            "icao_code": "KATS",
            "label": "Artesia Municipal Airport , Artesia"
        },
        {
            "value": "ATT",
            "icao_code": null,
            "label": "Atmautluak Airport , Atmautluak"
        },
        {
            "value": "ATU",
            "icao_code": "PAAT",
            "label": "Casco Cove Coast Guard Station , Attu Island"
        },
        {
            "value": "ATV",
            "icao_code": "FTTI",
            "label": "Ati Airport , Ati"
        },
        {
            "value": "ATW",
            "icao_code": "KATW",
            "label": "Appleton International Airport , Appleton"
        },
        {
            "value": "ATX",
            "icao_code": null,
            "label": "Atbasar Airport , Atbasar"
        },
        {
            "value": "ATY",
            "icao_code": "KATY",
            "label": "Watertown Regional Airport , Watertown"
        },
        {
            "value": "ATZ",
            "icao_code": "HEAT",
            "label": "Assiut Airport , Assiut"
        },
        {
            "value": "AUA",
            "icao_code": "TNCA",
            "label": "Queen Beatrix International Airport , Aruba"
        },
        {
            "value": "AUC",
            "icao_code": "SKUC",
            "label": "Santiago P\u00e9rez Quiroz Airport , Arauca"
        },
        {
            "value": "AUD",
            "icao_code": "YAGD",
            "label": "Augustus Downs Airport , Augustus Downs Station"
        },
        {
            "value": "AUE",
            "icao_code": null,
            "label": "Abu Rudeis Airport , Abou Redis"
        },
        {
            "value": "AUF",
            "icao_code": "LFLA",
            "label": "Auxerre \u2013 Branches Aerodrome , Auxerre"
        },
        {
            "value": "AUG",
            "icao_code": "KAUG",
            "label": "Augusta State Airport , Augusta"
        },
        {
            "value": "AUH",
            "icao_code": "OMAA",
            "label": "Abu Dhabi International Airport , Abu Dhabi"
        },
        {
            "value": "AUI",
            "icao_code": "AYND",
            "label": "Aua Island Airport , Aua Island"
        },
        {
            "value": "AUJ",
            "icao_code": "AYAT",
            "label": "Ambunti Airport , Ambunti"
        },
        {
            "value": "AUK",
            "icao_code": null,
            "label": "Alakanuk Airport , Alakanuk"
        },
        {
            "value": "AUL",
            "icao_code": null,
            "label": "Aur Airport , Aur Atoll"
        },
        {
            "value": "AUM",
            "icao_code": "KAUM",
            "label": "Austin Municipal Airport , Austin"
        },
        {
            "value": "AUN",
            "icao_code": "KAUN",
            "label": "Auburn Municipal Airport , Auburn"
        },
        {
            "value": "AUO",
            "icao_code": "KAUO",
            "label": "Auburn University Regional Airport , Auburn"
        },
        {
            "value": "AUP",
            "icao_code": "AYAG",
            "label": "Agaun Airport , Agaun"
        },
        {
            "value": "AUQ",
            "icao_code": "NTMN",
            "label": "Atuona Airport , Atuona"
        },
        {
            "value": "AUR",
            "icao_code": "LFLW",
            "label": "Aurillac \u2013 Tronqui\u00e8res Airport , Aurillac"
        },
        {
            "value": "AUS",
            "icao_code": "KAUS",
            "label": "Austin\u2013Bergstrom International Airport , Austin"
        },
        {
            "value": "AUT",
            "icao_code": "WPAT",
            "label": "Atauro Airport , Atauro Island"
        },
        {
            "value": "AUU",
            "icao_code": "YAUR",
            "label": "Aurukun Airport , Aurukun"
        },
        {
            "value": "AUV",
            "icao_code": "AYUM",
            "label": "Aumo Airport , Aumo"
        },
        {
            "value": "AUW",
            "icao_code": "KAUW",
            "label": "Wausau Downtown Airport , Wausau"
        },
        {
            "value": "AUX",
            "icao_code": "SWGN",
            "label": "Aragua\u00edna Airport , Aragua\u00edna"
        },
        {
            "value": "AUY",
            "icao_code": "NVVA",
            "label": "Anatom Airport , Anatom Island"
        },
        {
            "value": "AUZ",
            "icao_code": "KARR",
            "label": "Aurora Municipal Airport , Aurora"
        },
        {
            "value": "AVA",
            "icao_code": "ZUAS",
            "label": "Anshun Huangguoshu Airport , Anshun"
        },
        {
            "value": "AVB",
            "icao_code": "LIPA",
            "label": "Aviano Air Base , Aviano"
        },
        {
            "value": "AVG",
            "icao_code": "YAUV",
            "label": "Auvergne Airport , Auvergne Station"
        },
        {
            "value": "AVI",
            "icao_code": "MUCA",
            "label": "M\u00e1ximo G\u00f3mez Airport , Ciego de \u00c1vila"
        },
        {
            "value": "AVK",
            "icao_code": "ZMAH",
            "label": "Arvaikheer Airport , Arvaikheer"
        },
        {
            "value": "AVL",
            "icao_code": "KAVL",
            "label": "Asheville Regional Airport , Asheville"
        },
        {
            "value": "AVN",
            "icao_code": "LFMV",
            "label": "Avignon \u2013 Provence Airport , Avignon"
        },
        {
            "value": "AVO",
            "icao_code": "KAVO",
            "label": "Avon Park Executive Airport , Avon Park"
        },
        {
            "value": "AVP",
            "icao_code": "KAVP",
            "label": "Wilkes-Barre/Scranton International Airport , Wilkes-Barre"
        },
        {
            "value": "AVU",
            "icao_code": "AGGJ",
            "label": "Avu Avu Airport , Avu Avu"
        },
        {
            "value": "AVV",
            "icao_code": "YMAV",
            "label": "Avalon Airport , Avalon"
        },
        {
            "value": "AVW",
            "icao_code": "KAVQ",
            "label": "Marana Regional Airport , Tucson"
        },
        {
            "value": "AVX",
            "icao_code": "KAVX",
            "label": "Catalina Airport , Avalon"
        },
        {
            "value": "AWA",
            "icao_code": "HALA",
            "label": "Awasa Airport , Awasa"
        },
        {
            "value": "AWB",
            "icao_code": "AYAW",
            "label": "Awaba Airport , Awaba"
        },
        {
            "value": "AWD",
            "icao_code": "NVVB",
            "label": "Aniwa Airport , Aniwa Island"
        },
        {
            "value": "AWE",
            "icao_code": null,
            "label": "Alowe Airport , Alowe"
        },
        {
            "value": "AWK",
            "icao_code": "PWAK",
            "label": "Wake Island Airfield , Wake Island"
        },
        {
            "value": "AWM",
            "icao_code": "KAWM",
            "label": "West Memphis Municipal Airport , West Memphis"
        },
        {
            "value": "AWN",
            "icao_code": null,
            "label": "Alton Downs Airport , Alton Downs"
        },
        {
            "value": "AWP",
            "icao_code": null,
            "label": "Austral Downs Airport , Austral Downs"
        },
        {
            "value": "AWR",
            "icao_code": null,
            "label": "Awar Airport , Awar"
        },
        {
            "value": "AWZ",
            "icao_code": "OIAW",
            "label": "Ahvaz International Airport , Ahvaz"
        },
        {
            "value": "AXA",
            "icao_code": "TQPF",
            "label": "Clayton J. Lloyd International Airport , The Valley"
        },
        {
            "value": "AXB",
            "icao_code": null,
            "label": "Maxson Airfield (FAA: 89N) , Alexandria Bay"
        },
        {
            "value": "AXC",
            "icao_code": "YAMC",
            "label": "Aramac Airport , Aramac"
        },
        {
            "value": "AXD",
            "icao_code": "LGAL",
            "label": "Alexandroupoli Airport , Alexandroupoli"
        },
        {
            "value": "AXE",
            "icao_code": null,
            "label": "Xanxere Airport , Xanxer\u00ea"
        },
        {
            "value": "AXF",
            "icao_code": null,
            "label": "Alxa Left Banner Bayanhot Airport , Alxa Left Banner"
        },
        {
            "value": "AXG",
            "icao_code": "KAXA",
            "label": "Algona Municipal Airport , Algona"
        },
        {
            "value": "AXJ",
            "icao_code": "RJDA",
            "label": "Amakusa Airfield , Amakusa"
        },
        {
            "value": "AXK",
            "icao_code": "OYAT",
            "label": "Ataq Airport , Ataq"
        },
        {
            "value": "AXL",
            "icao_code": null,
            "label": "Alexandria Airport , Alexandria Station"
        },
        {
            "value": "AXM",
            "icao_code": "SKAR",
            "label": "El Ed\u00e9n International Airport , Armenia"
        },
        {
            "value": "AXN",
            "icao_code": "KAXN",
            "label": "Alexandria Municipal Airport , Alexandria"
        },
        {
            "value": "AXP",
            "icao_code": "MYAP",
            "label": "Spring Point Airport , Spring Point"
        },
        {
            "value": "AXR",
            "icao_code": "NTGU",
            "label": "Arutua Airport , Arutua"
        },
        {
            "value": "AXS",
            "icao_code": "KAXS",
            "label": "Altus/Quartz Mountain Regional Airport , Altus"
        },
        {
            "value": "AXT",
            "icao_code": "RJSK",
            "label": "Akita Airport , Akita"
        },
        {
            "value": "AXU",
            "icao_code": "HAAX",
            "label": "Axum Airport , Axum"
        },
        {
            "value": "AXV",
            "icao_code": "KAXV",
            "label": "Neil Armstrong Airport , Wapakoneta"
        },
        {
            "value": "AXX",
            "icao_code": "KAXX",
            "label": "Angel Fire Airport , Angel Fire"
        },
        {
            "value": "AYA",
            "icao_code": null,
            "label": "Ayapel Airport , Ayapel"
        },
        {
            "value": "AYC",
            "icao_code": null,
            "label": "Ayacucho Airport , Ayacucho"
        },
        {
            "value": "AYD",
            "icao_code": null,
            "label": "Alroy Downs Airport , Alroy Downs"
        },
        {
            "value": "AYG",
            "icao_code": "SKYA",
            "label": "Yaguara Airport , Yaguara"
        },
        {
            "value": "AYI",
            "icao_code": null,
            "label": "Yari Airport , Yari"
        },
        {
            "value": "AYK",
            "icao_code": "UAUR",
            "label": "Arkalyk Airport , Arkalyk"
        },
        {
            "value": "AYL",
            "icao_code": null,
            "label": "Anthony Lagoon Airport , Anthony Lagoon"
        },
        {
            "value": "AYM",
            "icao_code": null,
            "label": "Yas Island Seaplane Base , Yas Island"
        },
        {
            "value": "AYN",
            "icao_code": "ZHAY",
            "label": "Anyang Airport , Anyang"
        },
        {
            "value": "AYO",
            "icao_code": "SGAY",
            "label": "Juan de Ayolas Airport , Ayolas"
        },
        {
            "value": "AYP",
            "icao_code": "SPHO",
            "label": "Coronel FAP Alfredo Mend\u00edvil Duarte Airport , Ayacucho"
        },
        {
            "value": "AYQ",
            "icao_code": "YAYE",
            "label": "Ayers Rock Airport , Yulara"
        },
        {
            "value": "AYR",
            "icao_code": "YAYR",
            "label": "Ayr Airport , Ayr"
        },
        {
            "value": "AYS",
            "icao_code": "KAYS",
            "label": "Waycross\u2013Ware County Airport , Waycross"
        },
        {
            "value": "AYT",
            "icao_code": "LTAI",
            "label": "Antalya Airport , Antalya"
        },
        {
            "value": "AYU",
            "icao_code": "AYAY",
            "label": "Aiyura Airport , Aiyura"
        },
        {
            "value": "AYW",
            "icao_code": "WASA",
            "label": "Ayawasi Airport , Ayawasi"
        },
        {
            "value": "AYX",
            "icao_code": "SPAY",
            "label": "Tnte. Gral. Gerardo P\u00e9rez Pinedo Airport , Atalaya Province"
        },
        {
            "value": "AYY",
            "icao_code": null,
            "label": "Arugam Bay Seaplane Base , Pottuvil"
        },
        {
            "value": "AZA",
            "icao_code": "KIWA",
            "label": "Phoenix\u2013Mesa Gateway Airport , Phoenix"
        },
        {
            "value": "AZB",
            "icao_code": null,
            "label": "Amazon Bay Airport , Amazon Bay"
        },
        {
            "value": "AZD",
            "icao_code": "OIYY",
            "label": "Shahid Sadooghi Airport , Yazd"
        },
        {
            "value": "AZG",
            "icao_code": null,
            "label": "Pablo L. Sidar Airport , Apatzing\u00e1n"
        },
        {
            "value": "AZI",
            "icao_code": "OMAD",
            "label": "Al Bateen Executive Airport , Abu Dhabi"
        },
        {
            "value": "AZL",
            "icao_code": "SWTU",
            "label": "Fazenda Tucunar\u00e9 Airport , Sapezal"
        },
        {
            "value": "AZN",
            "icao_code": "UTFA",
            "label": "Andizhan Airport , Andizhan"
        },
        {
            "value": "AZO",
            "icao_code": "KAZO",
            "label": "Kalamazoo/Battle Creek International Airport , Kalamazoo"
        },
        {
            "value": "AZP",
            "icao_code": "MMJC",
            "label": "Jorge Jim\u00e9nez Cant\u00fa National Airport , Ciudad L\u00f3pez Mateos"
        },
        {
            "value": "AZR",
            "icao_code": "DAUA",
            "label": "Touat-Cheikh Sidi Mohamed Belkebir Airport , Adrar"
        },
        {
            "value": "AZS",
            "icao_code": "MDCY",
            "label": "Saman\u00e1 El Catey International Airport , El Catey"
        },
        {
            "value": "AZT",
            "icao_code": null,
            "label": "Zapatoca Airport , Zapatoca"
        },
        {
            "value": "AZZ",
            "icao_code": "FNAM",
            "label": "Ambriz Airport , Ambriz"
        },
        {
            "value": "BAA",
            "icao_code": "AYBL",
            "label": "Bialla Airport , Bialla"
        },
        {
            "value": "BAB",
            "icao_code": "KBAB",
            "label": "Beale Air Force Base , Marysville"
        },
        {
            "value": "BAC",
            "icao_code": null,
            "label": "Barranca de Upia Airport , Barranca de Up\u00eda"
        },
        {
            "value": "BAD",
            "icao_code": "KBAD",
            "label": "Barksdale Air Force Base , Bossier City"
        },
        {
            "value": "BAE",
            "icao_code": "LFMR",
            "label": "Barcelonnette \u2013 Saint-Pons Airport , Barcelonnette"
        },
        {
            "value": "BAF",
            "icao_code": "KBAF",
            "label": "Westfield-Barnes Regional Airport , Westfield"
        },
        {
            "value": "BAG",
            "icao_code": "RPUB",
            "label": "Loakan Airport , Baguio"
        },
        {
            "value": "BAH",
            "icao_code": "OBBI",
            "label": "Bahrain International Airport , Manama"
        },
        {
            "value": "BAI",
            "icao_code": "MRBA",
            "label": "Buenos Aires Airport , Buenos Aires"
        },
        {
            "value": "BAJ",
            "icao_code": null,
            "label": "Bali Airport , Bali"
        },
        {
            "value": "BAK",
            "icao_code": null,
            "label": "metropolitan area , Baku"
        },
        {
            "value": "BAL",
            "icao_code": "LTCJ",
            "label": "Batman Airport , Batman"
        },
        {
            "value": "BAM",
            "icao_code": "KBAM",
            "label": "Battle Mountain Airport , Battle Mountain"
        },
        {
            "value": "BAN",
            "icao_code": "FZVR",
            "label": "Basongo Airport , Basongo"
        },
        {
            "value": "BAP",
            "icao_code": null,
            "label": "Baibara Airport , Baibara"
        },
        {
            "value": "BAQ",
            "icao_code": "SKBQ",
            "label": "Ernesto Cortissoz International Airport , Barranquilla"
        },
        {
            "value": "BAR",
            "icao_code": null,
            "label": "Qionghai Bo'ao Airport , Qionghai"
        },
        {
            "value": "BAS",
            "icao_code": "AGGE",
            "label": "Balalae Airport , Balalae Island"
        },
        {
            "value": "BAT",
            "icao_code": "SBBT",
            "label": "Chafei Amsei Airport , Barretos"
        },
        {
            "value": "BAV",
            "icao_code": "ZBOW",
            "label": "Baotou Airport , Baotou"
        },
        {
            "value": "BAW",
            "icao_code": null,
            "label": "Biawonque Airport , Biawonque"
        },
        {
            "value": "BAX",
            "icao_code": "UNBB",
            "label": "Barnaul Airport , Barnaul"
        },
        {
            "value": "BAY",
            "icao_code": "LRBM",
            "label": "Baia Mare Airport , Baia Mare"
        },
        {
            "value": "BAZ",
            "icao_code": "SWBC",
            "label": "Barcelos Airport , Barcelos"
        },
        {
            "value": "BBA",
            "icao_code": "SCBA",
            "label": "Balmaceda Airport , Balmaceda"
        },
        {
            "value": "BBB",
            "icao_code": "KBBB",
            "label": "Benson Municipal Airport , Benson"
        },
        {
            "value": "BBC",
            "icao_code": "KBYY",
            "label": "Bay City Municipal Airport , Bay City"
        },
        {
            "value": "BBD",
            "icao_code": "KBBD",
            "label": "Curtis Field , Brady"
        },
        {
            "value": "BBG",
            "icao_code": "NGTU",
            "label": "Butaritari Atoll Airport , Butaritari Atoll"
        },
        {
            "value": "BBH",
            "icao_code": "EDBH",
            "label": "Stralsund\u2013Barth Airport , Stralsund"
        },
        {
            "value": "BBI",
            "icao_code": "VEBS",
            "label": "Biju Patnaik International Airport , Bhubaneswar"
        },
        {
            "value": "BBJ",
            "icao_code": "EDRB",
            "label": "Bitburg Airport , Bitburg"
        },
        {
            "value": "BBK",
            "icao_code": "FBKE",
            "label": "Kasane Airport , Kasane"
        },
        {
            "value": "BBL",
            "icao_code": "YLLE",
            "label": "Ballera Airport , Ballera gas plant"
        },
        {
            "value": "BBM",
            "icao_code": "VDBG",
            "label": "Battambang Airport , Battambang"
        },
        {
            "value": "BBN",
            "icao_code": "WBGZ",
            "label": "Bario Airport , Bario"
        },
        {
            "value": "BBO",
            "icao_code": "HCMI",
            "label": "Berbera Airport , Berbera"
        },
        {
            "value": "BBP",
            "icao_code": "EGHJ",
            "label": "Bembridge Airport , Bembridge"
        },
        {
            "value": "BBQ",
            "icao_code": "TAPH",
            "label": "Barbuda Codrington Airport , Barbuda"
        },
        {
            "value": "BBR",
            "icao_code": "TFFB",
            "label": "Baillif Airport , Basse-Terre"
        },
        {
            "value": "BBS",
            "icao_code": "EGLK",
            "label": "Blackbushe Airport , Yateley"
        },
        {
            "value": "BBT",
            "icao_code": "FEFT",
            "label": "Berb\u00e9rati Airport , Berb\u00e9rati"
        },
        {
            "value": "BBU",
            "icao_code": "LRBS",
            "label": "Aurel Vlaicu International Airport , Bucharest"
        },
        {
            "value": "BBV",
            "icao_code": "DIGN",
            "label": "Nero-Mer Airport , Grand-B\u00e9r\u00e9by"
        },
        {
            "value": "BBW",
            "icao_code": "KBBW",
            "label": "Broken Bow Municipal Airport , Broken Bow"
        },
        {
            "value": "BBX",
            "icao_code": "KLOM",
            "label": "Wings Field , Philadelphia"
        },
        {
            "value": "BBY",
            "icao_code": "FEFM",
            "label": "Bambari Airport , Bambari"
        },
        {
            "value": "BBZ",
            "icao_code": "FLZB",
            "label": "Zambezi Airport , Zambezi"
        },
        {
            "value": "BCA",
            "icao_code": "MUBA",
            "label": "Gustavo Rizo Airport , Baracoa"
        },
        {
            "value": "BCB",
            "icao_code": "KBCB",
            "label": "Virginia Tech Montgomery Executive Airport , Blacksburg"
        },
        {
            "value": "BCC",
            "icao_code": null,
            "label": "Bear Creek 3 Airport , Bear Creek"
        },
        {
            "value": "BCD",
            "icao_code": "RPVB",
            "label": "Bacolod\u2013Silay International Airport , Bacolod"
        },
        {
            "value": "BCE",
            "icao_code": "KBCE",
            "label": "Bryce Canyon Airport , Bryce Canyon"
        },
        {
            "value": "BCF",
            "icao_code": "FEGU",
            "label": "Bouca Airport , Bouca"
        },
        {
            "value": "BCG",
            "icao_code": null,
            "label": "Bemichi Airport , Kumaka"
        },
        {
            "value": "BCH",
            "icao_code": "WPEC",
            "label": "Baucau Airport , Baucau"
        },
        {
            "value": "BCI",
            "icao_code": "YBAR",
            "label": "Barcaldine Airport , Barcaldine"
        },
        {
            "value": "BCK",
            "icao_code": "YBWR",
            "label": "Bolwarra Airport , Bolwarra"
        },
        {
            "value": "BCL",
            "icao_code": "MRBC",
            "label": "Barra del Colorado Airport , Barra del Colorado"
        },
        {
            "value": "BCM",
            "icao_code": "LRBC",
            "label": "Bac\u0103u International Airport , Bac\u0103u"
        },
        {
            "value": "BCN",
            "icao_code": "LEBL",
            "label": "Barcelona\u2013El Prat Airport , Barcelona"
        },
        {
            "value": "BCO",
            "icao_code": "HABC",
            "label": "Baco Airport , Jinka"
        },
        {
            "value": "BCP",
            "icao_code": "AYBC",
            "label": "Bambu Airport , Bambu"
        },
        {
            "value": "BCQ",
            "icao_code": null,
            "label": "Brak Airport , Brak"
        },
        {
            "value": "BCR",
            "icao_code": "SWNK",
            "label": "Novo Campo Airport , Boca do Acre"
        },
        {
            "value": "BCS",
            "icao_code": null,
            "label": "Southern Seaplane Airport , Belle Chasse"
        },
        {
            "value": "BCT",
            "icao_code": "KBCT",
            "label": "Boca Raton Airport , Boca Raton"
        },
        {
            "value": "BCU",
            "icao_code": null,
            "label": "Bauchi State Airport , Bauchi"
        },
        {
            "value": "BCV",
            "icao_code": null,
            "label": "Hector Silva Airstrip , Belmopan"
        },
        {
            "value": "BCW",
            "icao_code": null,
            "label": "Benguerra Island Airport , Benguerra Island"
        },
        {
            "value": "BCX",
            "icao_code": "UWUB",
            "label": "Beloretsk Airport , Beloretsk"
        },
        {
            "value": "BCY",
            "icao_code": "HABU",
            "label": "Bulchi Airport , Bulchi"
        },
        {
            "value": "BCZ",
            "icao_code": null,
            "label": "Bickerton Island Airport , Bickerton Island"
        },
        {
            "value": "BDA",
            "icao_code": "TXKF",
            "label": "L.F. Wade International Airport , Hamilton"
        },
        {
            "value": "BDB",
            "icao_code": "YBUD",
            "label": "Bundaberg Airport , Bundaberg"
        },
        {
            "value": "BDC",
            "icao_code": "SNBC",
            "label": "Barra do Corda Airport , Barra do Corda"
        },
        {
            "value": "BDD",
            "icao_code": "YBAU",
            "label": "Badu Island Airport , Badu Island"
        },
        {
            "value": "BDE",
            "icao_code": "KBDE",
            "label": "Baudette International Airport , Baudette"
        },
        {
            "value": "BDF",
            "icao_code": null,
            "label": "Rinkenberger RLA Airport , Bradford"
        },
        {
            "value": "BDG",
            "icao_code": "KBDG",
            "label": "Blanding Municipal Airport , Blanding"
        },
        {
            "value": "BDH",
            "icao_code": "OIBL",
            "label": "Bandar Lengeh Airport , Bandar Lengeh"
        },
        {
            "value": "BDI",
            "icao_code": "FSSB",
            "label": "Bird Island Airport , Bird Island"
        },
        {
            "value": "BDJ",
            "icao_code": "WAOO",
            "label": "Syamsudin Noor Airport , Banjarmasin"
        },
        {
            "value": "BDK",
            "icao_code": "DIBU",
            "label": "Soko Airport , Bondoukou"
        },
        {
            "value": "BDL",
            "icao_code": "KBDL",
            "label": "Bradley International Airport , Windsor Locks"
        },
        {
            "value": "BDM",
            "icao_code": "LTBG",
            "label": "Band\u0131rma Airport , Band\u0131rma"
        },
        {
            "value": "BDN",
            "icao_code": "OPTH",
            "label": "Talhar Airport , Badin"
        },
        {
            "value": "BDO",
            "icao_code": "WICC",
            "label": "Husein Sastranegara International Airport , Bandung"
        },
        {
            "value": "BDP",
            "icao_code": "VNCG",
            "label": "Bhadrapur Airport , Bhadrapur"
        },
        {
            "value": "BDQ",
            "icao_code": "VABO",
            "label": "Vadodara Airport , Vadodara"
        },
        {
            "value": "BDR",
            "icao_code": "KBDR",
            "label": "Igor I. Sikorsky Memorial Airport , Bridgeport"
        },
        {
            "value": "BDS",
            "icao_code": "LIBR",
            "label": "Brindisi \u2013 Salento Airport , Brindisi"
        },
        {
            "value": "BDT",
            "icao_code": "FZFD",
            "label": "Gbadolite Airport , Gbadolite"
        },
        {
            "value": "BDU",
            "icao_code": "ENDU",
            "label": "Bardufoss Airport , Bardufoss"
        },
        {
            "value": "BDV",
            "icao_code": "FZRB",
            "label": "Moba Airport , Moba"
        },
        {
            "value": "BDW",
            "icao_code": "YBDF",
            "label": "Bedford Downs Airport , Bedford Downs"
        },
        {
            "value": "BDX",
            "icao_code": null,
            "label": "Broadus Airport , Broadus"
        },
        {
            "value": "BDY",
            "icao_code": null,
            "label": "Bandon State Airport , Bandon"
        },
        {
            "value": "BDZ",
            "icao_code": "AYBG",
            "label": "Baindoung Airport , Baindoung"
        },
        {
            "value": "BEA",
            "icao_code": null,
            "label": "Bereina Airport , Bereina"
        },
        {
            "value": "BEB",
            "icao_code": "EGPL",
            "label": "Benbecula Airport , Benbecula"
        },
        {
            "value": "BEC",
            "icao_code": "KBEC",
            "label": "Beech Factory Airport , Wichita"
        },
        {
            "value": "BED",
            "icao_code": "KBED",
            "label": "Laurence G. Hanscom Field , Bedford"
        },
        {
            "value": "BEE",
            "icao_code": "YBGB",
            "label": "Beagle Bay Airport , Beagle Bay"
        },
        {
            "value": "BEF",
            "icao_code": "MNBL",
            "label": "Bluefields Airport , Bluefields"
        },
        {
            "value": "BEG",
            "icao_code": "LYBE",
            "label": "Belgrade Nikola Tesla Airport , Belgrade"
        },
        {
            "value": "BEH",
            "icao_code": "KBEH",
            "label": "Southwest Michigan Regional Airport , Benton Harbor"
        },
        {
            "value": "BEI",
            "icao_code": "HABE",
            "label": "Beica Airport , Beica"
        },
        {
            "value": "BEJ",
            "icao_code": "WALK",
            "label": "Kalimarau Airport , Tanjung Redeb"
        },
        {
            "value": "BEK",
            "icao_code": "VIBY",
            "label": "Bareilly Airport , Bareilly"
        },
        {
            "value": "BEL",
            "icao_code": "SBBE",
            "label": "Val de Cans International Airport , Bel\u00e9m"
        },
        {
            "value": "BEM",
            "icao_code": "GMMD",
            "label": "Beni Mellal Airport , Beni Mellal"
        },
        {
            "value": "BEN",
            "icao_code": "HLLB",
            "label": "Benina International Airport , Benghazi"
        },
        {
            "value": "BEO",
            "icao_code": "YLMQ",
            "label": "Lake Macquarie Airport , Newcastle"
        },
        {
            "value": "BEP",
            "icao_code": "VOBI",
            "label": "Bellary Airport , Bellary"
        },
        {
            "value": "BEQ",
            "icao_code": "EGXH",
            "label": "RAF Honington , Bury St Edmunds"
        },
        {
            "value": "BER",
            "icao_code": "EDDB",
            "label": "Berlin Brandenburg Airport , Berlin"
        },
        {
            "value": "BES",
            "icao_code": "LFRB",
            "label": "Brest Bretagne Airport , Brest"
        },
        {
            "value": "BET",
            "icao_code": "PABE",
            "label": "Bethel Airport , Bethel"
        },
        {
            "value": "BEU",
            "icao_code": "YBIE",
            "label": "Bedourie Airport , Bedourie"
        },
        {
            "value": "BEV",
            "icao_code": "LLBS",
            "label": "Beersheba Airport , Beersheba"
        },
        {
            "value": "BEW",
            "icao_code": "FQBR",
            "label": "Beira Airport , Beira"
        },
        {
            "value": "BEX",
            "icao_code": "EGUB",
            "label": "RAF Benson , Benson"
        },
        {
            "value": "BEY",
            "icao_code": "OLBA",
            "label": "Beirut\u2013Rafic Hariri International Airport , Beirut"
        },
        {
            "value": "BEZ",
            "icao_code": "NGBR",
            "label": "Beru Island Airport , Beru Island"
        },
        {
            "value": "BFA",
            "icao_code": "SGBN",
            "label": "Bah\u00eda Negra Airport , Bah\u00eda Negra"
        },
        {
            "value": "BFC",
            "icao_code": null,
            "label": "Bloomfield Airport , Bloomfield"
        },
        {
            "value": "BFD",
            "icao_code": "KBFD",
            "label": "Bradford Regional Airport , Bradford"
        },
        {
            "value": "BFE",
            "icao_code": "EDLI",
            "label": "Bielefeld Airport , Bielefeld"
        },
        {
            "value": "BFF",
            "icao_code": "KBFF",
            "label": "Western Nebraska Regional Airport , Scottsbluff"
        },
        {
            "value": "BFG",
            "icao_code": null,
            "label": "Bullfrog Basin Airport , Glen Canyon"
        },
        {
            "value": "BFH",
            "icao_code": "SBBI",
            "label": "Bacacheri Airport , Curitiba"
        },
        {
            "value": "BFI",
            "icao_code": "KBFI",
            "label": "Boeing Field/King County International Airport , Seattle"
        },
        {
            "value": "BFJ",
            "icao_code": "ZUBJ",
            "label": "Bijie Feixiong Airport , Bijie"
        },
        {
            "value": "BFK",
            "icao_code": "KBKF",
            "label": "Buckley Space Force Base , Aurora"
        },
        {
            "value": "BFL",
            "icao_code": "KBFL",
            "label": "Meadows Field Airport , Bakersfield"
        },
        {
            "value": "BFM",
            "icao_code": "KBFM",
            "label": "Mobile Downtown Airport , Mobile"
        },
        {
            "value": "BFN",
            "icao_code": "FABL",
            "label": "Bloemfontein Airport , Bloemfontein"
        },
        {
            "value": "BFO",
            "icao_code": "FVCZ",
            "label": "Buffalo Range Airport , Chiredzi"
        },
        {
            "value": "BFP",
            "icao_code": "KBVI",
            "label": "Beaver County Airport , Beaver Falls"
        },
        {
            "value": "BFQ",
            "icao_code": null,
            "label": "Bah\u00eda Pi\u00f1a Airport , Puerto Pi\u00f1a"
        },
        {
            "value": "BFR",
            "icao_code": "KBFR",
            "label": "Virgil I. Grissom Municipal Airport , Bedford"
        },
        {
            "value": "BFS",
            "icao_code": "EGAA",
            "label": "Belfast International Airport , Belfast"
        },
        {
            "value": "BFT",
            "icao_code": "KARW",
            "label": "Beaufort County Airport , Beaufort County"
        },
        {
            "value": "BFU",
            "icao_code": "ZSBB",
            "label": "Bengbu Airport , Bengbu"
        },
        {
            "value": "BFV",
            "icao_code": "VTUO",
            "label": "Buriram Airport , Buriram"
        },
        {
            "value": "BFW",
            "icao_code": "DAOS",
            "label": "Sidi Bel Abb\u00e8s Airport , Sidi Bel Abb\u00e8s"
        },
        {
            "value": "BFX",
            "icao_code": "FKKU",
            "label": "Bafoussam Airport , Bafoussam"
        },
        {
            "value": "BGA",
            "icao_code": "SKBG",
            "label": "Palonegro International Airport , Bucaramanga"
        },
        {
            "value": "BGB",
            "icao_code": "FOGB",
            "label": "Boou\u00e9 Airport , Boou\u00e9"
        },
        {
            "value": "BGC",
            "icao_code": "LPBG",
            "label": "Bragan\u00e7a Airport , Bragan\u00e7a"
        },
        {
            "value": "BGD",
            "icao_code": "KBGD",
            "label": "Hutchinson County Airport , Borger"
        },
        {
            "value": "BGE",
            "icao_code": "KBGE",
            "label": "Decatur County Industrial Air Park , Bainbridge"
        },
        {
            "value": "BGF",
            "icao_code": "FEFF",
            "label": "Bangui M'Poko International Airport , Bangui"
        },
        {
            "value": "BGG",
            "icao_code": "LTCU",
            "label": "Bing\u00f6l Airport , Bing\u00f6l"
        },
        {
            "value": "BGH",
            "icao_code": "GQNE",
            "label": "Abbaye Airport , Bogu\u00e9"
        },
        {
            "value": "BGI",
            "icao_code": "TBPB",
            "label": "Grantley Adams International Airport , Bridgetown"
        },
        {
            "value": "BGJ",
            "icao_code": "BIBF",
            "label": "Borgarfj\u00f6r\u00f0ur Eystri Airport , Borgarfj\u00f6r\u00f0ur Eystri"
        },
        {
            "value": "BGK",
            "icao_code": null,
            "label": "Big Creek Airport , Big Creek"
        },
        {
            "value": "BGL",
            "icao_code": "VNBL",
            "label": "Baglung Airport , Baglung"
        },
        {
            "value": "BGM",
            "icao_code": "KBGM",
            "label": "Greater Binghamton Airport , Binghamton"
        },
        {
            "value": "BGN",
            "icao_code": "UESG",
            "label": "Belaya Gora Airport , Belaya Gora"
        },
        {
            "value": "BGO",
            "icao_code": "ENBR",
            "label": "Bergen Airport, Flesland , Bergen"
        },
        {
            "value": "BGP",
            "icao_code": null,
            "label": "Bongo Airport , Bongo"
        },
        {
            "value": "BGQ",
            "icao_code": "PAGQ",
            "label": "Big Lake Airport , Big Lake"
        },
        {
            "value": "BGR",
            "icao_code": "KBGR",
            "label": "Bangor International Airport , Bangor"
        },
        {
            "value": "BGT",
            "icao_code": null,
            "label": "Bagdad Airport , Bagdad"
        },
        {
            "value": "BGU",
            "icao_code": "FEFG",
            "label": "Bangassou Airport , Bangassou"
        },
        {
            "value": "BGV",
            "icao_code": "SSBG",
            "label": "Bento Gon\u00e7alves Airport , Bento Gon\u00e7alves"
        },
        {
            "value": "BGW",
            "icao_code": "ORBI",
            "label": "Baghdad International Airport , Baghdad"
        },
        {
            "value": "BGX",
            "icao_code": "SBBG",
            "label": "Comandante Gustavo Kraemer Airport , Bag\u00e9"
        },
        {
            "value": "BGY",
            "icao_code": "LIME",
            "label": "Il Caravaggio International Airport , Milan"
        },
        {
            "value": "BGZ",
            "icao_code": "LPBR",
            "label": "Braga Airport , Braga"
        },
        {
            "value": "BHA",
            "icao_code": "SESV",
            "label": "Los Perales Airport , Bah\u00eda de Car\u00e1quez"
        },
        {
            "value": "BHB",
            "icao_code": "KBHB",
            "label": "Hancock County-Bar Harbor Airport , Bar Harbor"
        },
        {
            "value": "BHD",
            "icao_code": "EGAC",
            "label": "George Best Belfast City Airport , Belfast"
        },
        {
            "value": "BHE",
            "icao_code": "NZWB",
            "label": "Woodbourne Airport , Blenheim"
        },
        {
            "value": "BHF",
            "icao_code": "SKCP",
            "label": "Bah\u00eda Cupica Airport , Bah\u00eda Cupica"
        },
        {
            "value": "BHG",
            "icao_code": null,
            "label": "Brus Laguna Airport , Brus Laguna"
        },
        {
            "value": "BHH",
            "icao_code": "OEBH",
            "label": "Bisha Domestic Airport , Bisha"
        },
        {
            "value": "BHI",
            "icao_code": "SAZB",
            "label": "Comandante Espora Airport , Bah\u00eda Blanca"
        },
        {
            "value": "BHJ",
            "icao_code": "VABJ",
            "label": "Bhuj Airport , Bhuj"
        },
        {
            "value": "BHK",
            "icao_code": "UTSB",
            "label": "Bukhara International Airport , Bukhara"
        },
        {
            "value": "BHL",
            "icao_code": null,
            "label": "Bah\u00eda de los \u00c1ngeles Airport , Bah\u00eda de los \u00c1ngeles"
        },
        {
            "value": "BHM",
            "icao_code": "KBHM",
            "label": "Birmingham\u2013Shuttlesworth International Airport , Birmingham"
        },
        {
            "value": "BHN",
            "icao_code": "OYBN",
            "label": "Beihan Airport , Beihan"
        },
        {
            "value": "BHO",
            "icao_code": "VABP",
            "label": "Raja Bhoj Airport , Bhopal"
        },
        {
            "value": "BHP",
            "icao_code": "VNBJ",
            "label": "Bhojpur Airport , Bhojpur"
        },
        {
            "value": "BHQ",
            "icao_code": "YBHI",
            "label": "Broken Hill Airport , Broken Hill"
        },
        {
            "value": "BHR",
            "icao_code": "VNBP",
            "label": "Bharatpur Airport , Bharatpur"
        },
        {
            "value": "BHS",
            "icao_code": "YBTH",
            "label": "Bathurst Airport , Bathurst"
        },
        {
            "value": "BHT",
            "icao_code": null,
            "label": "Brighton Downs Airport , Brighton Downs"
        },
        {
            "value": "BHU",
            "icao_code": "VABV",
            "label": "Bhavnagar Airport , Bhavnagar"
        },
        {
            "value": "BHV",
            "icao_code": "OPBW",
            "label": "Bahawalpur Airport , Bahawalpur"
        },
        {
            "value": "BHW",
            "icao_code": null,
            "label": "Bhagatanwala Airport , Sargodha"
        },
        {
            "value": "BHX",
            "icao_code": "EGBB",
            "label": "Birmingham Airport , Birmingham"
        },
        {
            "value": "BHY",
            "icao_code": "ZGBH",
            "label": "Beihai Fucheng Airport , Beihai"
        },
        {
            "value": "BHZ",
            "icao_code": null,
            "label": "metropolitan area , Belo Horizonte"
        },
        {
            "value": "BIA",
            "icao_code": "LFKB",
            "label": "Bastia \u2013 Poretta Airport , Bastia"
        },
        {
            "value": "BIB",
            "icao_code": "HCMB",
            "label": "Baidoa Airport , Baidoa"
        },
        {
            "value": "BID",
            "icao_code": "KBID",
            "label": "Block Island State Airport , Block Island"
        },
        {
            "value": "BIE",
            "icao_code": "KBIE",
            "label": "Beatrice Municipal Airport , Beatrice"
        },
        {
            "value": "BIF",
            "icao_code": "KBIF",
            "label": "Biggs Army Airfield , El Paso"
        },
        {
            "value": "BIG",
            "icao_code": "PABI",
            "label": "Allen Army Airfield , Delta Junction"
        },
        {
            "value": "BIH",
            "icao_code": "KBIH",
            "label": "Eastern Sierra Regional Airport , Bishop"
        },
        {
            "value": "BII",
            "icao_code": null,
            "label": "Bikini Atoll Airport , Bikini Atoll"
        },
        {
            "value": "BIJ",
            "icao_code": null,
            "label": "Biliau Airport , Biliau"
        },
        {
            "value": "BIK",
            "icao_code": "WABB",
            "label": "Frans Kaisiepo Airport , Biak"
        },
        {
            "value": "BIL",
            "icao_code": "KBIL",
            "label": "Billings Logan International Airport , Billings"
        },
        {
            "value": "BIM",
            "icao_code": "MYBS",
            "label": "South Bimini Airport , Bimini"
        },
        {
            "value": "BIN",
            "icao_code": "OABN",
            "label": "Bamyan Airport , Bamyan"
        },
        {
            "value": "BIO",
            "icao_code": "LEBB",
            "label": "Bilbao Airport , Bilbao"
        },
        {
            "value": "BIP",
            "icao_code": null,
            "label": "Bulimba Airport , Bulimba"
        },
        {
            "value": "BIQ",
            "icao_code": "LFBZ",
            "label": "Biarritz Pays Basque Airport , Biarritz"
        },
        {
            "value": "BIR",
            "icao_code": "VNVT",
            "label": "Biratnagar Airport , Biratnagar"
        },
        {
            "value": "BIS",
            "icao_code": "KBIS",
            "label": "Bismarck Municipal Airport , Bismarck"
        },
        {
            "value": "BIT",
            "icao_code": "VNBT",
            "label": "Baitadi Airport , Baitadi"
        },
        {
            "value": "BIU",
            "icao_code": "BIBD",
            "label": "B\u00edldudalur Airport , B\u00edldudalur"
        },
        {
            "value": "BIV",
            "icao_code": "FEFR",
            "label": "Bria Airport , Bria"
        },
        {
            "value": "BIW",
            "icao_code": null,
            "label": "Billiluna Airport , Billiluna"
        },
        {
            "value": "BIX",
            "icao_code": "KBIX",
            "label": "Keesler Air Force Base , Biloxi"
        },
        {
            "value": "BIY",
            "icao_code": "FABE",
            "label": "Bhisho Airport , Bhisho"
        },
        {
            "value": "BIZ",
            "icao_code": null,
            "label": "Bimin Airport , Bimin"
        },
        {
            "value": "BJA",
            "icao_code": "DAAE",
            "label": "Soummam \u2013 Abane Ramdane Airport , B\u00e9ja\u00efa"
        },
        {
            "value": "BJB",
            "icao_code": "OIMN",
            "label": "Bojnord Airport , Bojnord"
        },
        {
            "value": "BJC",
            "icao_code": "KBJC",
            "label": "Rocky Mountain Metropolitan Airport , Denver"
        },
        {
            "value": "BJD",
            "icao_code": "BIBK",
            "label": "Bakkafj\u00f6r\u00f0ur Airport , Bakkafj\u00f6r\u00f0ur"
        },
        {
            "value": "BJE",
            "icao_code": null,
            "label": "Baleela Airport , Baleela"
        },
        {
            "value": "BJF",
            "icao_code": "ENBS",
            "label": "B\u00e5tsfjord Airport , B\u00e5tsfjord"
        },
        {
            "value": "BJG",
            "icao_code": null,
            "label": "Kotamobagu Mopait Airport , Kotamobagu"
        },
        {
            "value": "BJH",
            "icao_code": "VNBG",
            "label": "Bajhang Airport , Bajhang"
        },
        {
            "value": "BJI",
            "icao_code": "KBJI",
            "label": "Bemidji Regional Airport , Bemidji"
        },
        {
            "value": "BJJ",
            "icao_code": "KBJJ",
            "label": "Wayne County Airport , Wooster"
        },
        {
            "value": "BJK",
            "icao_code": "WAPK",
            "label": "Benjina Airport , Benjina"
        },
        {
            "value": "BJL",
            "icao_code": "GBYD",
            "label": "Banjul International Airport , Banjul"
        },
        {
            "value": "BJM",
            "icao_code": "HBBA",
            "label": "Bujumbura International Airport , Bujumbura"
        },
        {
            "value": "BJN",
            "icao_code": null,
            "label": "Bajone Airport , Bajone"
        },
        {
            "value": "BJO",
            "icao_code": "SLBJ",
            "label": "Bermejo Airport , Bermejo"
        },
        {
            "value": "BJP",
            "icao_code": "SBBP",
            "label": "Arthur Siqueira\u2013Bragan\u00e7a Paulista State Airport , Bragan\u00e7a Paulista"
        },
        {
            "value": "BJQ",
            "icao_code": null,
            "label": "Bahja Airport , Bahja"
        },
        {
            "value": "BJR",
            "icao_code": "HABD",
            "label": "Bahir Dar Airport , Bahir Dar"
        },
        {
            "value": "BJS",
            "icao_code": null,
            "label": "metropolitan area , Beijing"
        },
        {
            "value": "BJT",
            "icao_code": null,
            "label": "Bentota River Airport , Bentota"
        },
        {
            "value": "BJU",
            "icao_code": "VNBR",
            "label": "Bajura Airport , Bajura"
        },
        {
            "value": "BJV",
            "icao_code": "LTFE",
            "label": "Milas\u2013Bodrum Airport , Milas"
        },
        {
            "value": "BJW",
            "icao_code": "WRKB",
            "label": "Bajawa Soa Airport , Bajawa"
        },
        {
            "value": "BJX",
            "icao_code": "MMLO",
            "label": "Del Baj\u00edo International Airport , Le\u00f3n"
        },
        {
            "value": "BJY",
            "icao_code": "LYBT",
            "label": "Batajnica Air Base , Batajnica"
        },
        {
            "value": "BJZ",
            "icao_code": "LEBZ",
            "label": "Badajoz Airport , Badajoz"
        },
        {
            "value": "BKB",
            "icao_code": "VIBK",
            "label": "Nal Airport , Bikaner"
        },
        {
            "value": "BKC",
            "icao_code": "PABL",
            "label": "Buckland Airport , Buckland"
        },
        {
            "value": "BKD",
            "icao_code": "KBKD",
            "label": "Stephens County Airport , Breckenridge"
        },
        {
            "value": "BKE",
            "icao_code": "KBKE",
            "label": "Baker City Municipal Airport , Baker City"
        },
        {
            "value": "BKF",
            "icao_code": null,
            "label": "Lake Brooks Seaplane Base , Brooks Camp"
        },
        {
            "value": "BKG",
            "icao_code": "KBBG",
            "label": "Branson Airport , Branson"
        },
        {
            "value": "BKH",
            "icao_code": "PHBK",
            "label": "Barking Sands PMRF , Kekaha"
        },
        {
            "value": "BKI",
            "icao_code": "WBKK",
            "label": "Kota Kinabalu International Airport , Kota Kinabalu"
        },
        {
            "value": "BKJ",
            "icao_code": "GUOK",
            "label": "Bok\u00e9 Baralande Airport , Bok\u00e9"
        },
        {
            "value": "BKK",
            "icao_code": "VTBS",
            "label": "Suvarnabhumi Airport , Bangkok"
        },
        {
            "value": "BKL",
            "icao_code": "KBKL",
            "label": "Cleveland Burke Lakefront Airport , Cleveland"
        },
        {
            "value": "BKM",
            "icao_code": "WBGQ",
            "label": "Bakelalan Airport , Bakelalan"
        },
        {
            "value": "BKN",
            "icao_code": null,
            "label": "Balkanabat Airport , Balkanabat"
        },
        {
            "value": "BKO",
            "icao_code": "GABS",
            "label": "Bamako\u2013S\u00e9nou International Airport , Bamako"
        },
        {
            "value": "BKP",
            "icao_code": "YBAW",
            "label": "Barkly Downs Airport , Barkly Downs"
        },
        {
            "value": "BKQ",
            "icao_code": "YBCK",
            "label": "Blackall Airport , Blackall"
        },
        {
            "value": "BKR",
            "icao_code": "FTTK",
            "label": "Bokoro Airport , Bokoro"
        },
        {
            "value": "BKS",
            "icao_code": "WIPL",
            "label": "Fatmawati Soekarno Airport , Bengkulu"
        },
        {
            "value": "BKT",
            "icao_code": "KBKT",
            "label": "Blackstone Army Airfield , Blackstone"
        },
        {
            "value": "BKU",
            "icao_code": "FMSV",
            "label": "Betioky Airport , Betioky"
        },
        {
            "value": "BKW",
            "icao_code": "KBKW",
            "label": "Raleigh County Memorial Airport , Beckley"
        },
        {
            "value": "BKX",
            "icao_code": "KBKX",
            "label": "Brookings Regional Airport , Brookings"
        },
        {
            "value": "BKY",
            "icao_code": "FZMA",
            "label": "Kavumu Airport , Bukavu"
        },
        {
            "value": "BKZ",
            "icao_code": "HTBU",
            "label": "Bukoba Airport , Bukoba"
        },
        {
            "value": "BLA",
            "icao_code": "SVBC",
            "label": "General Jos\u00e9 Antonio Anzo\u00e1tegui International Airport , Barcelona"
        },
        {
            "value": "BLB",
            "icao_code": "MPHO",
            "label": "Panam\u00e1 Pac\u00edfico International Airport , Panama Pacifico"
        },
        {
            "value": "BLC",
            "icao_code": "FKKG",
            "label": "Bali Airport , Bali"
        },
        {
            "value": "BLD",
            "icao_code": null,
            "label": "Boulder City Municipal Airport , Boulder City"
        },
        {
            "value": "BLE",
            "icao_code": "ESSD",
            "label": "Dala Airport , Borl\u00e4nge"
        },
        {
            "value": "BLF",
            "icao_code": "KBLF",
            "label": "Mercer County Airport , Bluefield"
        },
        {
            "value": "BLG",
            "icao_code": "WBGC",
            "label": "Belaga Airport , Belaga"
        },
        {
            "value": "BLH",
            "icao_code": "KBLH",
            "label": "Blythe Airport , Blythe"
        },
        {
            "value": "BLI",
            "icao_code": "KBLI",
            "label": "Bellingham International Airport , Bellingham"
        },
        {
            "value": "BLJ",
            "icao_code": "DABT",
            "label": "Most\u00e9pha Ben Boulaid Airport , Batna"
        },
        {
            "value": "BLK",
            "icao_code": "EGNH",
            "label": "Blackpool Airport , Blackpool"
        },
        {
            "value": "BLL",
            "icao_code": "EKBI",
            "label": "Billund Airport , Billund"
        },
        {
            "value": "BLM",
            "icao_code": "KBLM",
            "label": "Monmouth Executive Airport , Belmar"
        },
        {
            "value": "BLN",
            "icao_code": "YBLA",
            "label": "Benalla Airport , Benalla"
        },
        {
            "value": "BLO",
            "icao_code": "BIBL",
            "label": "Bl\u00f6ndu\u00f3s Airport , Bl\u00f6ndu\u00f3s"
        },
        {
            "value": "BLP",
            "icao_code": "SPBL",
            "label": "Huallaga Airport , Bellavista"
        },
        {
            "value": "BLQ",
            "icao_code": "LIPE",
            "label": "Bologna Guglielmo Marconi Airport , Bologna"
        },
        {
            "value": "BLR",
            "icao_code": "VOBL",
            "label": "Kempegowda International Airport , Bangalore"
        },
        {
            "value": "BLS",
            "icao_code": "YBLL",
            "label": "Bollon Airport , Bollon"
        },
        {
            "value": "BLT",
            "icao_code": "YBTR",
            "label": "Blackwater Airport , Blackwater"
        },
        {
            "value": "BLU",
            "icao_code": "KBLU",
            "label": "Blue Canyon\u2013Nyack Airport , Emigrant Gap"
        },
        {
            "value": "BLV",
            "icao_code": "KBLV",
            "label": "MidAmerica St. Louis Airport , Belleville"
        },
        {
            "value": "BLX",
            "icao_code": "LIDB",
            "label": "Belluno Airport , Belluno"
        },
        {
            "value": "BLY",
            "icao_code": "EIBT",
            "label": "Belmullet Aerodrome , Belmullet"
        },
        {
            "value": "BLZ",
            "icao_code": "FWCL",
            "label": "Chileka International Airport , Blantyre"
        },
        {
            "value": "BMA",
            "icao_code": "ESSB",
            "label": "Stockholm Bromma Airport , Stockholm"
        },
        {
            "value": "BMB",
            "icao_code": "FZFU",
            "label": "Bumba Airport , Bumba"
        },
        {
            "value": "BMC",
            "icao_code": "KBMC",
            "label": "Brigham City Airport , Brigham City"
        },
        {
            "value": "BMD",
            "icao_code": "FMML",
            "label": "Belo sur Tsiribihina Airport , Belo sur Tsiribihina"
        },
        {
            "value": "BME",
            "icao_code": "YBRM",
            "label": "Broome International Airport , Broome"
        },
        {
            "value": "BMF",
            "icao_code": "FEGM",
            "label": "Bakouma Airport , Bakouma"
        },
        {
            "value": "BMG",
            "icao_code": "KBMG",
            "label": "Monroe County Airport , Bloomington"
        },
        {
            "value": "BMH",
            "icao_code": "AYBO",
            "label": "Bomai Airport , Bomai"
        },
        {
            "value": "BMI",
            "icao_code": "KBMI",
            "label": "Central Illinois Regional Airport , Bloomington"
        },
        {
            "value": "BMJ",
            "icao_code": "SYBR",
            "label": "Baramita Airport , Baramita"
        },
        {
            "value": "BMK",
            "icao_code": "EDWR",
            "label": "Borkum Airfield , Borkum"
        },
        {
            "value": "BML",
            "icao_code": "KBML",
            "label": "Berlin Regional Airport , Berlin"
        },
        {
            "value": "BMM",
            "icao_code": "FOOB",
            "label": "Bitam Airport , Bitam"
        },
        {
            "value": "BMN",
            "icao_code": "ORBB",
            "label": "Bamarni Airport , Bamarni"
        },
        {
            "value": "BMO",
            "icao_code": "VYBM",
            "label": "Bhamo Airport , Bhamo"
        },
        {
            "value": "BMP",
            "icao_code": "YBPI",
            "label": "Brampton Island Airport , Brampton Island"
        },
        {
            "value": "BMQ",
            "icao_code": null,
            "label": "Bamburi Airport , Bamburi"
        },
        {
            "value": "BMR",
            "icao_code": "EDWZ",
            "label": "Baltrum Airport , Baltrum"
        },
        {
            "value": "BMS",
            "icao_code": "SNBU",
            "label": "Socrates Mariani Bittencourt Airport , Brumado"
        },
        {
            "value": "BMT",
            "icao_code": "KBMT",
            "label": "Beaumont Municipal Airport , Beaumont"
        },
        {
            "value": "BMU",
            "icao_code": "WADB",
            "label": "Sultan Muhammad Salahudin Airport , Bima"
        },
        {
            "value": "BMV",
            "icao_code": "VVBM",
            "label": "Buon Ma Thuot Airport , Buon Ma Thuot"
        },
        {
            "value": "BMW",
            "icao_code": "DATM",
            "label": "Bordj Mokhtar Airport , Bordj Badji Mokhtar"
        },
        {
            "value": "BMX",
            "icao_code": "PABM",
            "label": "Big Mountain Air Force Station , Big Mountain"
        },
        {
            "value": "BMY",
            "icao_code": "NWWC",
            "label": "\u00cele Art \u2013 Waala Airport , Waala"
        },
        {
            "value": "BMZ",
            "icao_code": "AYBF",
            "label": "Bamu Airport , Bamu"
        },
        {
            "value": "BNA",
            "icao_code": "KBNA",
            "label": "Nashville International Airport , Nashville"
        },
        {
            "value": "BNB",
            "icao_code": "FZGN",
            "label": "Boende Airport , Boende"
        },
        {
            "value": "BNC",
            "icao_code": "FZNP",
            "label": "Beni Airport , Beni"
        },
        {
            "value": "BND",
            "icao_code": "OIKB",
            "label": "Bandar Abbas International Airport , Bandar Abbas"
        },
        {
            "value": "BNE",
            "icao_code": "YBBN",
            "label": "Brisbane Airport , Brisbane"
        },
        {
            "value": "BNF",
            "icao_code": null,
            "label": "Warm Springs Bay Seaplane Base , Baranof Warm Springs"
        },
        {
            "value": "BNG",
            "icao_code": "KBNG",
            "label": "Banning Municipal Airport , Banning"
        },
        {
            "value": "BNI",
            "icao_code": "DNBE",
            "label": "Benin Airport , Benin City"
        },
        {
            "value": "BNK",
            "icao_code": "YBNA",
            "label": "Ballina Byron Gateway Airport , Ballina"
        },
        {
            "value": "BNL",
            "icao_code": "KBNL",
            "label": "Barnwell Regional Airport , Barnwell"
        },
        {
            "value": "BNM",
            "icao_code": "AYBD",
            "label": "Bodinumu Airport , Bodinumu"
        },
        {
            "value": "BNN",
            "icao_code": "ENBN",
            "label": "Br\u00f8nn\u00f8ysund Airport, Br\u00f8nn\u00f8y , Br\u00f8nn\u00f8ysund"
        },
        {
            "value": "BNO",
            "icao_code": "KBNO",
            "label": "Burns Municipal Airport , Burns"
        },
        {
            "value": "BNP",
            "icao_code": "OPBN",
            "label": "Bannu Airport , Bannu"
        },
        {
            "value": "BNQ",
            "icao_code": null,
            "label": "Baganga Airport , Baganga"
        },
        {
            "value": "BNR",
            "icao_code": "DFOB",
            "label": "Banfora Airport , Banfora"
        },
        {
            "value": "BNS",
            "icao_code": "SVBI",
            "label": "Barinas Airport , Barinas"
        },
        {
            "value": "BNT",
            "icao_code": "AYDI",
            "label": "Bundi Airport , Bundi"
        },
        {
            "value": "BNU",
            "icao_code": "SSBL",
            "label": "Blumenau Airport , Blumenau"
        },
        {
            "value": "BNV",
            "icao_code": null,
            "label": "Boana Airport , Boana"
        },
        {
            "value": "BNW",
            "icao_code": "KBNW",
            "label": "Boone Municipal Airport , Boone"
        },
        {
            "value": "BNX",
            "icao_code": "LQBK",
            "label": "Banja Luka International Airport , Banja Luka"
        },
        {
            "value": "BNY",
            "icao_code": "AGGB",
            "label": "Bellona/Anua Airport , Bellona Island"
        },
        {
            "value": "BNZ",
            "icao_code": null,
            "label": "Banz Airport , Banz"
        },
        {
            "value": "BOA",
            "icao_code": "FZAJ",
            "label": "Boma Airport , Boma"
        },
        {
            "value": "BOB",
            "icao_code": "NTTB",
            "label": "Bora Bora Airport , Bora Bora"
        },
        {
            "value": "BOC",
            "icao_code": "MPBO",
            "label": "Bocas del Toro \"Isla Col\u00f3n\" International Airport , Bocas Town"
        },
        {
            "value": "BOD",
            "icao_code": "LFBD",
            "label": "Bordeaux\u2013M\u00e9rignac Airport , Bordeaux"
        },
        {
            "value": "BOE",
            "icao_code": "FCOB",
            "label": "Boundji Airport , Boundji"
        },
        {
            "value": "BOG",
            "icao_code": "SKBO",
            "label": "El Dorado International Airport , Bogot\u00e1"
        },
        {
            "value": "BOH",
            "icao_code": "EGHH",
            "label": "Bournemouth Airport , Bournemouth"
        },
        {
            "value": "BOI",
            "icao_code": "KBOI",
            "label": "Boise Air Terminal , Boise"
        },
        {
            "value": "BOJ",
            "icao_code": "LBBG",
            "label": "Burgas Airport , Burgas"
        },
        {
            "value": "BOK",
            "icao_code": "KBOK",
            "label": "Brookings Airport , Brookings"
        },
        {
            "value": "BOL",
            "icao_code": "EGQB",
            "label": "Ballykelly Airfield , Ballykelly"
        },
        {
            "value": "BOM",
            "icao_code": "VABB",
            "label": "Chhatrapati Shivaji International Airport , Mumbai"
        },
        {
            "value": "BON",
            "icao_code": "TNCB",
            "label": "Flamingo International Airport , Kralendijk"
        },
        {
            "value": "BOO",
            "icao_code": "ENBO",
            "label": "Bod\u00f8 Airport , Bod\u00f8"
        },
        {
            "value": "BOP",
            "icao_code": "FEFO",
            "label": "Bouar Airport , Bouar"
        },
        {
            "value": "BOQ",
            "icao_code": null,
            "label": "Boku Airport , Boku"
        },
        {
            "value": "BOS",
            "icao_code": "KBOS",
            "label": "Logan International Airport , Boston"
        },
        {
            "value": "BOT",
            "icao_code": "AYET",
            "label": "Bosset Airport , Bosset"
        },
        {
            "value": "BOU",
            "icao_code": "LFLD",
            "label": "Bourges Airport , Bourges"
        },
        {
            "value": "BOV",
            "icao_code": null,
            "label": "Boang Island Airport , Boang Island"
        },
        {
            "value": "BOW",
            "icao_code": "KBOW",
            "label": "Bartow Municipal Airport , Bartow"
        },
        {
            "value": "BOX",
            "icao_code": "YBRL",
            "label": "Borroloola Airport , Borroloola"
        },
        {
            "value": "BOY",
            "icao_code": "DFOO",
            "label": "Bobo Dioulasso Airport , Bobo-Dioulasso"
        },
        {
            "value": "BOZ",
            "icao_code": "FEGZ",
            "label": "Bozoum Airport , Bozoum"
        },
        {
            "value": "BPB",
            "icao_code": null,
            "label": "Boridi Airport , Boridi"
        },
        {
            "value": "BPC",
            "icao_code": "FKKV",
            "label": "Bamenda Airport , Bamenda"
        },
        {
            "value": "BPD",
            "icao_code": "AYBP",
            "label": "Bapi Airport , Bapi"
        },
        {
            "value": "BPE",
            "icao_code": "ZBDH",
            "label": "Qinhuangdao Beidaihe Airport , Qinhuangdao"
        },
        {
            "value": "BPF",
            "icao_code": "AGBT",
            "label": "Batuna Airport , Batuna"
        },
        {
            "value": "BPG",
            "icao_code": "SBBW",
            "label": "Barra do Gar\u00e7as Airport , Barra do Gar\u00e7as"
        },
        {
            "value": "BPH",
            "icao_code": "RPMF",
            "label": "Bislig Airport , Bislig"
        },
        {
            "value": "BPI",
            "icao_code": "KBPI",
            "label": "Miley Memorial Field , Big Piney"
        },
        {
            "value": "BPK",
            "icao_code": "AYBQ",
            "label": "Biangabip Airport , Biangabip"
        },
        {
            "value": "BPL",
            "icao_code": "ZWBL",
            "label": "Bole Alashankou Airport , Bole"
        },
        {
            "value": "BPM",
            "icao_code": "VOHY",
            "label": "Begumpet Airport , Hyderabad"
        },
        {
            "value": "BPN",
            "icao_code": "WALL",
            "label": "Sultan Aji Muhammad Sulaiman Airport , Balikpapan"
        },
        {
            "value": "BPS",
            "icao_code": "SBPS",
            "label": "Porto Seguro Airport , Porto Seguro"
        },
        {
            "value": "BPT",
            "icao_code": "KBPT",
            "label": "Jack Brooks Regional Airport , Beaumont"
        },
        {
            "value": "BPX",
            "icao_code": "ZUBD",
            "label": "Qamdo Bamda Airport , Qamdo"
        },
        {
            "value": "BPY",
            "icao_code": "FMNQ",
            "label": "Besalampy Airport , Besalampy"
        },
        {
            "value": "BQA",
            "icao_code": "RPUR",
            "label": "Dr. Juan C. Angara Airport , Baler"
        },
        {
            "value": "BQB",
            "icao_code": "YBLN",
            "label": "Busselton Margaret River Airport , Busselton"
        },
        {
            "value": "BQE",
            "icao_code": "GGBU",
            "label": "Bubaque Airport , Bubaque"
        },
        {
            "value": "BQG",
            "icao_code": "UHNB",
            "label": "Bogorodskoye Airport , Bogorodskoye"
        },
        {
            "value": "BQH",
            "icao_code": "EGKB",
            "label": "London Biggin Hill Airport , London"
        },
        {
            "value": "BQI",
            "icao_code": null,
            "label": "Bagani Airport , Bagani"
        },
        {
            "value": "BQJ",
            "icao_code": null,
            "label": "Batagay Airport , Batagay"
        },
        {
            "value": "BQK",
            "icao_code": "KBQK",
            "label": "Brunswick Golden Isles Airport , Brunswick"
        },
        {
            "value": "BQL",
            "icao_code": "YBOU",
            "label": "Boulia Airport , Boulia"
        },
        {
            "value": "BQN",
            "icao_code": "TJBQ",
            "label": "Rafael Hern\u00e1ndez Airport , Aguadilla"
        },
        {
            "value": "BQO",
            "icao_code": "DIBN",
            "label": "Tehini Airport , Bouna"
        },
        {
            "value": "BQQ",
            "icao_code": "SNBX",
            "label": "Barra Airport , Barra"
        },
        {
            "value": "BQS",
            "icao_code": "UHBB",
            "label": "Ignatyevo Airport , Blagoveshchensk"
        },
        {
            "value": "BQT",
            "icao_code": "UMBB",
            "label": "Brest Airport , Brest"
        },
        {
            "value": "BQU",
            "icao_code": "TVSB",
            "label": "J. F. Mitchell Airport , Bequia"
        },
        {
            "value": "BQV",
            "icao_code": null,
            "label": "Bartlett Cove Seaplane Base , Bartlett Cove"
        },
        {
            "value": "BQW",
            "icao_code": "YBGO",
            "label": "Balgo Hill Airport , Balgo Hill"
        },
        {
            "value": "BRA",
            "icao_code": "SNBR",
            "label": "Barreiras Airport , Barreiras"
        },
        {
            "value": "BRB",
            "icao_code": "SBRR",
            "label": "Barreirinhas Airport , Barreirinhas"
        },
        {
            "value": "BRC",
            "icao_code": "SAZS",
            "label": "San Carlos de Bariloche Airport , San Carlos de Bariloche"
        },
        {
            "value": "BRD",
            "icao_code": "KBRD",
            "label": "Brainerd Lakes Regional Airport , Brainerd"
        },
        {
            "value": "BRE",
            "icao_code": "EDDW",
            "label": "Bremen Airport , Bremen"
        },
        {
            "value": "BRH",
            "icao_code": null,
            "label": "Brahman Airport , Brahman"
        },
        {
            "value": "BRI",
            "icao_code": "LIBD",
            "label": "Bari Karol Wojty\u0142a Airport , Bari"
        },
        {
            "value": "BRJ",
            "icao_code": null,
            "label": "Bright Airport , Bright"
        },
        {
            "value": "BRK",
            "icao_code": "YBKE",
            "label": "Bourke Airport , Bourke"
        },
        {
            "value": "BRL",
            "icao_code": "KBRL",
            "label": "Southeast Iowa Regional Airport , Burlington"
        },
        {
            "value": "BRM",
            "icao_code": "SVBM",
            "label": "Jacinto Lara International Airport , Barquisimeto"
        },
        {
            "value": "BRN",
            "icao_code": "LSZB",
            "label": "Bern Airport , Bern"
        },
        {
            "value": "BRO",
            "icao_code": "KBRO",
            "label": "Brownsville/South Padre Island International Airport , Brownsville"
        },
        {
            "value": "BRP",
            "icao_code": "AYBR",
            "label": "Biaru Airport , Biaru"
        },
        {
            "value": "BRQ",
            "icao_code": "LKTB",
            "label": "Brno\u2013Tu\u0159any Airport , Brno"
        },
        {
            "value": "BRR",
            "icao_code": "EGPR",
            "label": "Barra Airport , Barra"
        },
        {
            "value": "BRS",
            "icao_code": "EGGD",
            "label": "Bristol Airport , Bristol"
        },
        {
            "value": "BRT",
            "icao_code": "YBTI",
            "label": "Bathurst Island Airport , Bathurst Island"
        },
        {
            "value": "BRU",
            "icao_code": "EBBR",
            "label": "Brussels Airport , Brussels"
        },
        {
            "value": "BRV",
            "icao_code": "EDWB",
            "label": "Bremerhaven Airport , Bremerhaven"
        },
        {
            "value": "BRW",
            "icao_code": "PABR",
            "label": "Wiley Post\u2013Will Rogers Memorial Airport , Barrow"
        },
        {
            "value": "BRX",
            "icao_code": "MDBH",
            "label": "Mar\u00eda Montez International Airport , Barahona"
        },
        {
            "value": "BRY",
            "icao_code": "KBRY",
            "label": "Samuels Field , Bardstown"
        },
        {
            "value": "BSA",
            "icao_code": "HCMF",
            "label": "Bender Qassim International Airport , Bosaso"
        },
        {
            "value": "BSB",
            "icao_code": "SBBR",
            "label": "Bras\u00edlia International Airport , Bras\u00edlia"
        },
        {
            "value": "BSC",
            "icao_code": "SKBS",
            "label": "Jos\u00e9 Celestino Mutis Airport , Bah\u00eda Solano"
        },
        {
            "value": "BSD",
            "icao_code": "ZPBS",
            "label": "Baoshan Yunduan Airport , Baoshan"
        },
        {
            "value": "BSE",
            "icao_code": "WBGN",
            "label": "Sematan Airport , Sematan"
        },
        {
            "value": "BSF",
            "icao_code": "PHSF",
            "label": "Bradshaw Army Airfield , Camp Pohakuloa"
        },
        {
            "value": "BSG",
            "icao_code": "FGBT",
            "label": "Bata Airport , Bata"
        },
        {
            "value": "BSI",
            "icao_code": "RPLE",
            "label": "Balesin Airport , Balesin Island"
        },
        {
            "value": "BSJ",
            "icao_code": "YBNS",
            "label": "Bairnsdale Airport , Bairnsdale"
        },
        {
            "value": "BSK",
            "icao_code": "DAUB",
            "label": "Biskra Airport , Biskra"
        },
        {
            "value": "BSL",
            "icao_code": "LFSB",
            "label": "EuroAirport Basel-Mulhouse-Freiburg , Basel"
        },
        {
            "value": "BSM",
            "icao_code": "OINJ",
            "label": "Bishe Kola Air Base , Amol"
        },
        {
            "value": "BSN",
            "icao_code": "FEFS",
            "label": "Bossangoa Airport , Bossangoa"
        },
        {
            "value": "BSO",
            "icao_code": "RPUO",
            "label": "Basco Airport , Basco"
        },
        {
            "value": "BSP",
            "icao_code": "AYBH",
            "label": "Bensbach Airport , Bensbach,"
        },
        {
            "value": "BSQ",
            "icao_code": null,
            "label": "Bisbee Municipal Airport , Bisbee"
        },
        {
            "value": "BSR",
            "icao_code": "ORMM",
            "label": "Basra International Airport , Basra"
        },
        {
            "value": "BSS",
            "icao_code": "SNBS",
            "label": "Balsas Airport , Balsas"
        },
        {
            "value": "BST",
            "icao_code": "OABT",
            "label": "Bost Airport , Lashkar Gah"
        },
        {
            "value": "BSU",
            "icao_code": "FZEN",
            "label": "Basankusu Airport , Basankusu"
        },
        {
            "value": "BSV",
            "icao_code": null,
            "label": "Besakoa Airport , Besakoa"
        },
        {
            "value": "BSW",
            "icao_code": null,
            "label": "Boswell Bay Airport , Boswell Bay"
        },
        {
            "value": "BSX",
            "icao_code": "VYPN",
            "label": "Pathein Airport , Pathein"
        },
        {
            "value": "BSY",
            "icao_code": "HCMD",
            "label": "Bardera Airport , Bardera"
        },
        {
            "value": "BTA",
            "icao_code": "FKKO",
            "label": "Bertoua Airport , Bertoua"
        },
        {
            "value": "BTB",
            "icao_code": "FCOT",
            "label": "B\u00e9tou Airport , B\u00e9tou"
        },
        {
            "value": "BTC",
            "icao_code": "VCCB",
            "label": "Batticaloa International Airport , Batticaloa"
        },
        {
            "value": "BTD",
            "icao_code": null,
            "label": "Brunette Downs Airport , Brunette Downs"
        },
        {
            "value": "BTE",
            "icao_code": "GFBN",
            "label": "Sherbro International Airport , Bonthe"
        },
        {
            "value": "BTF",
            "icao_code": "KBTF",
            "label": "Skypark Airport , Bountiful"
        },
        {
            "value": "BTG",
            "icao_code": "FEGF",
            "label": "Batangafo Airport , Batangafo"
        },
        {
            "value": "BTH",
            "icao_code": "WIDD",
            "label": "Hang Nadim Airport , Batam"
        },
        {
            "value": "BTI",
            "icao_code": "PABA",
            "label": "Barter Island LRRS Airport , Barter Island"
        },
        {
            "value": "BTJ",
            "icao_code": "WITT",
            "label": "Sultan Iskandar Muda International Airport , Banda Aceh"
        },
        {
            "value": "BTK",
            "icao_code": "UIBB",
            "label": "Bratsk Airport , Bratsk"
        },
        {
            "value": "BTL",
            "icao_code": "KBTL",
            "label": "W. K. Kellogg Airport , Battle Creek"
        },
        {
            "value": "BTM",
            "icao_code": "KBTM",
            "label": "Bert Mooney Airport , Butte"
        },
        {
            "value": "BTN",
            "icao_code": "KBBP",
            "label": "Marlboro County Jetport , Bennettsville"
        },
        {
            "value": "BTO",
            "icao_code": null,
            "label": "Botopasi Airstrip , Botopasi"
        },
        {
            "value": "BTP",
            "icao_code": "KBTP",
            "label": "Butler County Airport , Butler"
        },
        {
            "value": "BTQ",
            "icao_code": "HRYI",
            "label": "Butare Airport , Butare"
        },
        {
            "value": "BTR",
            "icao_code": "KBTR",
            "label": "Baton Rouge Metropolitan Airport , Baton Rouge"
        },
        {
            "value": "BTS",
            "icao_code": "LZIB",
            "label": "M. R. \u0160tef\u00e1nik Airport , Bratislava"
        },
        {
            "value": "BTT",
            "icao_code": "PABT",
            "label": "Bettles Airport , Bettles"
        },
        {
            "value": "BTU",
            "icao_code": "WBGB",
            "label": "Bintulu Airport , Bintulu"
        },
        {
            "value": "BTV",
            "icao_code": "KBTV",
            "label": "Burlington International Airport , Burlington"
        },
        {
            "value": "BTW",
            "icao_code": "WAOC",
            "label": "Batu Licin Airport , Batu Licin"
        },
        {
            "value": "BTX",
            "icao_code": "YBEO",
            "label": "Betoota Airport , Betoota"
        },
        {
            "value": "BTY",
            "icao_code": "KBTY",
            "label": "Beatty Airport , Beatty"
        },
        {
            "value": "BUA",
            "icao_code": "AYBK",
            "label": "Buka Island Airport , Buka Island"
        },
        {
            "value": "BUB",
            "icao_code": "KBUB",
            "label": "Cram Field , Burwell"
        },
        {
            "value": "BUC",
            "icao_code": "YBKT",
            "label": "Burketown Airport , Burketown"
        },
        {
            "value": "BUD",
            "icao_code": "LHBP",
            "label": "Budapest Ferenc Liszt International Airport , Budapest"
        },
        {
            "value": "BUE",
            "icao_code": null,
            "label": "metropolitan area , Buenos Aires"
        },
        {
            "value": "BUF",
            "icao_code": "KBUF",
            "label": "Buffalo Niagara International Airport , Buffalo"
        },
        {
            "value": "BUG",
            "icao_code": "FNBG",
            "label": "Benguela Airport , Benguela"
        },
        {
            "value": "BUH",
            "icao_code": null,
            "label": "Bucharest Baneasa , Bucharest"
        },
        {
            "value": "BUI",
            "icao_code": "WAJB",
            "label": "Bokondini Airport , Bokondini"
        },
        {
            "value": "BUJ",
            "icao_code": "DAAD",
            "label": "Bou Saada Airport , Bou Sa\u00e2da"
        },
        {
            "value": "BUK",
            "icao_code": null,
            "label": "Albuq Airport , Albuq"
        },
        {
            "value": "BUL",
            "icao_code": "AYBU",
            "label": "Bulolo Airport , Bulolo"
        },
        {
            "value": "BUM",
            "icao_code": "KBUM",
            "label": "Butler Memorial Airport , Butler"
        },
        {
            "value": "BUN",
            "icao_code": "SKBU",
            "label": "Gerardo Tobar L\u00f3pez Airport , Buenaventura"
        },
        {
            "value": "BUO",
            "icao_code": "HCMV",
            "label": "Burao Airport , Burao"
        },
        {
            "value": "BUP",
            "icao_code": "VIBT",
            "label": "Bathinda Airport , Bathinda"
        },
        {
            "value": "BUQ",
            "icao_code": "FVBU",
            "label": "Joshua Mqabuko Nkomo International Airport , Bulawayo"
        },
        {
            "value": "BUR",
            "icao_code": "KBUR",
            "label": "Bob Hope Airport , Burbank"
        },
        {
            "value": "BUS",
            "icao_code": "UGSB",
            "label": "Batumi International Airport , Batumi"
        },
        {
            "value": "BUT",
            "icao_code": "VQBT",
            "label": "Bathpalathang Airport , Jakar"
        },
        {
            "value": "BUU",
            "icao_code": "WIPI",
            "label": "Muara Bungo Airport , Bungo Regency"
        },
        {
            "value": "BUV",
            "icao_code": "SUBU",
            "label": "Placeres Airport , Bella Uni\u00f3n"
        },
        {
            "value": "BUW",
            "icao_code": "WAWB",
            "label": "Betoambari Airport , Baubau"
        },
        {
            "value": "BUX",
            "icao_code": "FZKA",
            "label": "Bunia Airport , Bunia"
        },
        {
            "value": "BUY",
            "icao_code": "YBUN",
            "label": "Bunbury Airport , Bunbury"
        },
        {
            "value": "BUZ",
            "icao_code": "OIBB",
            "label": "Bushehr Airport , Bushehr"
        },
        {
            "value": "BVA",
            "icao_code": "LFOB",
            "label": "Beauvais\u2013Till\u00e9 Airport , Beauvais"
        },
        {
            "value": "BVB",
            "icao_code": "SBBV",
            "label": "Boa Vista International Airport , Boa Vista"
        },
        {
            "value": "BVC",
            "icao_code": "GVBA",
            "label": "Aristides Pereira International Airport , Boa Vista"
        },
        {
            "value": "BVE",
            "icao_code": "LFSL",
            "label": "Brive\u2013Souillac Airport , Brive-la-Gaillarde"
        },
        {
            "value": "BVF",
            "icao_code": "NFNU",
            "label": "Dama Airport , Bua"
        },
        {
            "value": "BVG",
            "icao_code": "ENBV",
            "label": "Berlev\u00e5g Airport , Berlev\u00e5g"
        },
        {
            "value": "BVH",
            "icao_code": "SBVH",
            "label": "Vilhena Airport , Vilhena"
        },
        {
            "value": "BVI",
            "icao_code": "YBDV",
            "label": "Birdsville Airport , Birdsville"
        },
        {
            "value": "BVJ",
            "icao_code": "USDB",
            "label": "Bovanenkovo Airport , Bovanenkovo gas field"
        },
        {
            "value": "BVK",
            "icao_code": "SLHJ",
            "label": "Huacaraje Airport , Huacaraje"
        },
        {
            "value": "BVL",
            "icao_code": "SLBU",
            "label": "Baures Airport , Baures"
        },
        {
            "value": "BVM",
            "icao_code": "SD6P",
            "label": "Belmonte Airport , Belmonte"
        },
        {
            "value": "BVO",
            "icao_code": "KBVO",
            "label": "Bartlesville Municipal Airport , Bartlesville"
        },
        {
            "value": "BVP",
            "icao_code": null,
            "label": "Bolovip Airport , Bolovip"
        },
        {
            "value": "BVR",
            "icao_code": "GVBR",
            "label": "Esperadinha Airport , Brava"
        },
        {
            "value": "BVS",
            "icao_code": "SNVS",
            "label": "Breves Airport , Breves"
        },
        {
            "value": "BVU",
            "icao_code": "PABG",
            "label": "Beluga Airport , Beluga"
        },
        {
            "value": "BVV",
            "icao_code": "UHSB",
            "label": "Burevestnik Airport , Burevestnik"
        },
        {
            "value": "BVW",
            "icao_code": null,
            "label": "Batavia Downs Airport , Batavia Downs"
        },
        {
            "value": "BVX",
            "icao_code": "KBVX",
            "label": "Batesville Regional Airport , Batesville"
        },
        {
            "value": "BVY",
            "icao_code": "KBVY",
            "label": "Beverly Municipal Airport , Beverly"
        },
        {
            "value": "BVZ",
            "icao_code": "YBYS",
            "label": "Beverley Springs Airport , Beverley Springs"
        },
        {
            "value": "BWA",
            "icao_code": "VNBW",
            "label": "Gautam Buddha Airport , Siddharthanagar"
        },
        {
            "value": "BWB",
            "icao_code": "YBWX",
            "label": "Barrow Island Airport , Barrow Island"
        },
        {
            "value": "BWC",
            "icao_code": "KBWC",
            "label": "Brawley Municipal Airport , Brawley"
        },
        {
            "value": "BWD",
            "icao_code": "KBWD",
            "label": "Brownwood Regional Airport , Brownwood"
        },
        {
            "value": "BWE",
            "icao_code": "EDVE",
            "label": "Braunschweig\u2013Wolfsburg Airport , Braunschweig"
        },
        {
            "value": "BWF",
            "icao_code": "EGNL",
            "label": "Barrow/Walney Island Airport , Barrow-in-Furness"
        },
        {
            "value": "BWG",
            "icao_code": "KBWG",
            "label": "Bowling Green-Warren County Regional Airport , Bowling Green"
        },
        {
            "value": "BWH",
            "icao_code": "WMKB",
            "label": "RMAF Butterworth , Butterworth"
        },
        {
            "value": "BWI",
            "icao_code": "KBWI",
            "label": "Baltimore/Washington International Thurgood Marshall Airport , Baltimore\u2013Washington metropolitan area"
        },
        {
            "value": "BWJ",
            "icao_code": null,
            "label": "Bawan Airport , Bawan"
        },
        {
            "value": "BWK",
            "icao_code": "LDSB",
            "label": "Bol Airport , Bol"
        },
        {
            "value": "BWL",
            "icao_code": "KBKN",
            "label": "Blackwell\u2013Tonkawa Municipal Airport , Blackwell"
        },
        {
            "value": "BWM",
            "icao_code": "KBPP",
            "label": "Bowman Municipal Airport , Bowman"
        },
        {
            "value": "BWN",
            "icao_code": "WBSB",
            "label": "Brunei International Airport , Bandar Seri Begawan"
        },
        {
            "value": "BWO",
            "icao_code": "UWSB",
            "label": "Balakovo Airport , Balakovo"
        },
        {
            "value": "BWP",
            "icao_code": "AYBI",
            "label": "Bewani Airport , Bewani"
        },
        {
            "value": "BWQ",
            "icao_code": "YBRW",
            "label": "Brewarrina Airport , Brewarrina"
        },
        {
            "value": "BWT",
            "icao_code": "YWYY",
            "label": "Burnie Airport , Burnie"
        },
        {
            "value": "BWU",
            "icao_code": "YSBK",
            "label": "Bankstown Airport , Bankstown"
        },
        {
            "value": "BWW",
            "icao_code": "MUBR",
            "label": "Las Brujas Airport , Cayo Santa Mar\u00eda"
        },
        {
            "value": "BWX",
            "icao_code": "WADY",
            "label": "Banyuwangi Airport , Banyuwangi"
        },
        {
            "value": "BWY",
            "icao_code": "EGVJ",
            "label": "RAF Bentwaters , Woodbridge"
        },
        {
            "value": "BXA",
            "icao_code": "KBXA",
            "label": "George R. Carr Memorial Air Field , Bogalusa"
        },
        {
            "value": "BXB",
            "icao_code": "WASO",
            "label": "Babo Airport , Babo"
        },
        {
            "value": "BXD",
            "icao_code": "WAKE",
            "label": "Bade Airport , Bade"
        },
        {
            "value": "BXE",
            "icao_code": "GOTB",
            "label": "Bakel Airport , Bakel"
        },
        {
            "value": "BXF",
            "icao_code": "YBEB",
            "label": "Bellburn Airstrip , Bellburn Camps"
        },
        {
            "value": "BXG",
            "icao_code": "YBDG",
            "label": "Bendigo Airport , Bendigo"
        },
        {
            "value": "BXH",
            "icao_code": "UAAH",
            "label": "Balkhash Airport , Balkhash"
        },
        {
            "value": "BXI",
            "icao_code": "DIBI",
            "label": "Boundiali Airport , Boundiali"
        },
        {
            "value": "BXJ",
            "icao_code": "UAAR",
            "label": "Boraldai Airport , Almaty"
        },
        {
            "value": "BXK",
            "icao_code": "KBXK",
            "label": "Buckeye Municipal Airport , Buckeye"
        },
        {
            "value": "BXL",
            "icao_code": null,
            "label": "Blue Lagoon Seaplane Base , Nanuya Lailai"
        },
        {
            "value": "BXM",
            "icao_code": null,
            "label": "Batom Airport , Batom"
        },
        {
            "value": "BXN",
            "icao_code": "LTBV",
            "label": "Bodrum-Imsik Airport , Bodrum"
        },
        {
            "value": "BXO",
            "icao_code": "LSZC",
            "label": "Buochs Airport , Buochs"
        },
        {
            "value": "BXP",
            "icao_code": "EPBP",
            "label": "Bia\u0142a Podlaska Airport , Bia\u0142a Podlaska"
        },
        {
            "value": "BXR",
            "icao_code": "OIKM",
            "label": "Bam Airport , Bam"
        },
        {
            "value": "BXS",
            "icao_code": null,
            "label": "Borrego Valley Airport , Borrego Springs"
        },
        {
            "value": "BXT",
            "icao_code": "WRLC",
            "label": "PT Badak Bontang Airport , Bontang"
        },
        {
            "value": "BXU",
            "icao_code": "RPME",
            "label": "Bancasi Airport , Butuan"
        },
        {
            "value": "BXV",
            "icao_code": "BIBV",
            "label": "Brei\u00f0dalsv\u00edk Airport , Brei\u00f0dalsv\u00edk"
        },
        {
            "value": "BXW",
            "icao_code": "WARW",
            "label": "Bawean Airport , Bawean"
        },
        {
            "value": "BXX",
            "icao_code": null,
            "label": "Borama Airport , Borama"
        },
        {
            "value": "BXY",
            "icao_code": "UAOL",
            "label": "Krayniy Airport , Baikonur"
        },
        {
            "value": "BXZ",
            "icao_code": "AYNS",
            "label": "Bunsil Airport , Bunsil"
        },
        {
            "value": "BYA",
            "icao_code": null,
            "label": "Boundary Airport , Boundary"
        },
        {
            "value": "BYB",
            "icao_code": null,
            "label": "Dibba Airport , Dibba Al-Baya"
        },
        {
            "value": "BYC",
            "icao_code": "SLYA",
            "label": "Yacuiba Airport , Yacu\u00edba"
        },
        {
            "value": "BYD",
            "icao_code": "OYBI",
            "label": "Al Bayda Airport , Al Bayda"
        },
        {
            "value": "BYF",
            "icao_code": "LFAQ",
            "label": "Albert \u2013 Picardie Airport , Albert"
        },
        {
            "value": "BYG",
            "icao_code": "KBYG",
            "label": "Johnson County Airport , Buffalo"
        },
        {
            "value": "BYH",
            "icao_code": "KBYH",
            "label": "Arkansas International Airport , Blytheville"
        },
        {
            "value": "BYI",
            "icao_code": "KBYI",
            "label": "Burley Municipal Airport , Burley"
        },
        {
            "value": "BYJ",
            "icao_code": "LPBJ",
            "label": "Beja Airport , Beja"
        },
        {
            "value": "BYK",
            "icao_code": "DIBK",
            "label": "Bouak\u00e9 Airport , Bouak\u00e9"
        },
        {
            "value": "BYL",
            "icao_code": null,
            "label": "Bella Yella Airport , Bella Yella"
        },
        {
            "value": "BYM",
            "icao_code": "MUBY",
            "label": "Carlos Manuel de C\u00e9spedes Airport , Bayamo"
        },
        {
            "value": "BYN",
            "icao_code": "ZMBH",
            "label": "Bayankhongor Airport , Bayankhongor"
        },
        {
            "value": "BYO",
            "icao_code": "SBDB",
            "label": "Bonito Airport , Bonito"
        },
        {
            "value": "BYP",
            "icao_code": "YBRY",
            "label": "Barimunya Airport , Barimunya"
        },
        {
            "value": "BYQ",
            "icao_code": "WALV",
            "label": "Bunyu Airport , Bunyu"
        },
        {
            "value": "BYR",
            "icao_code": "EKLS",
            "label": "L\u00e6s\u00f8 Airport , L\u00e6s\u00f8"
        },
        {
            "value": "BYS",
            "icao_code": "KBYS",
            "label": "Bicycle Lake Army Airfield , Barstow"
        },
        {
            "value": "BYT",
            "icao_code": "EIBN",
            "label": "Bantry Aerodrome , Bantry"
        },
        {
            "value": "BYU",
            "icao_code": "EDQD",
            "label": "Bindlacher Berg Airport , Bayreuth"
        },
        {
            "value": "BYV",
            "icao_code": null,
            "label": "Beira Lake Seaplane Base , Colombo"
        },
        {
            "value": "BYW",
            "icao_code": null,
            "label": "Blakely Island Airport , Blakely Island"
        },
        {
            "value": "BYX",
            "icao_code": null,
            "label": "Baniyala Airport , Baniyala"
        },
        {
            "value": "BZA",
            "icao_code": "MNBZ",
            "label": "San Pedro Airport , Bonanza"
        },
        {
            "value": "BZB",
            "icao_code": null,
            "label": "Bazaruto Island Airport , Bazaruto Island"
        },
        {
            "value": "BZC",
            "icao_code": "SBBZ",
            "label": "Umberto Modiano Airport , Arma\u00e7\u00e3o dos B\u00fazios"
        },
        {
            "value": "BZD",
            "icao_code": "YBRN",
            "label": "Balranald Airport , Balranald"
        },
        {
            "value": "BZE",
            "icao_code": "MZBZ",
            "label": "Philip S. W. Goldson International Airport , Belize City"
        },
        {
            "value": "BZF",
            "icao_code": null,
            "label": "Benton Field , Redding"
        },
        {
            "value": "BZG",
            "icao_code": "EPBY",
            "label": "Bydgoszcz Ignacy Jan Paderewski Airport , Bydgoszcz"
        },
        {
            "value": "BZH",
            "icao_code": null,
            "label": "Bumi Hills Airstrip , Bumi Hills"
        },
        {
            "value": "BZI",
            "icao_code": "LTBF",
            "label": "Bal\u0131kesir Airport , Bal\u0131kesir"
        },
        {
            "value": "BZK",
            "icao_code": "UUBP",
            "label": "Bryansk International Airport , Bryansk"
        },
        {
            "value": "BZL",
            "icao_code": "VGBR",
            "label": "Barisal Airport , Barisal"
        },
        {
            "value": "BZM",
            "icao_code": null,
            "label": "Bemolanga Airport , Bemolanga"
        },
        {
            "value": "BZN",
            "icao_code": "KBZN",
            "label": "Bozeman Yellowstone International Airport , Bozeman"
        },
        {
            "value": "BZO",
            "icao_code": "LIPB",
            "label": "Bolzano Airport , Bolzano"
        },
        {
            "value": "BZP",
            "icao_code": null,
            "label": "Bizant Airport , Bizant"
        },
        {
            "value": "BZR",
            "icao_code": "LFMU",
            "label": "B\u00e9ziers Cap d'Agde Airport , B\u00e9ziers"
        },
        {
            "value": "BZT",
            "icao_code": null,
            "label": "Eagle Air Park , Brazoria"
        },
        {
            "value": "BZU",
            "icao_code": "FZKJ",
            "label": "Buta Zega Airport , Buta"
        },
        {
            "value": "BZV",
            "icao_code": "FCBB",
            "label": "Maya-Maya Airport , Brazzaville"
        },
        {
            "value": "BZX",
            "icao_code": null,
            "label": "Bazhong Enyang Airport , Bazhong"
        },
        {
            "value": "BZY",
            "icao_code": "LUBL",
            "label": "B\u0103l\u021bi International Airport , B\u0103l\u021bi"
        },
        {
            "value": "BZZ",
            "icao_code": "EGVN",
            "label": "RAF Brize Norton , Carterton"
        },
        {
            "value": "CAA",
            "icao_code": "MHGE",
            "label": "El Aguacate Airport , Catacamas"
        },
        {
            "value": "CAB",
            "icao_code": "FNCA",
            "label": "Cabinda Airport , Cabinda"
        },
        {
            "value": "CAC",
            "icao_code": "SBCA",
            "label": "Cascavel Airport , Cascavel"
        },
        {
            "value": "CAD",
            "icao_code": "KCAD",
            "label": "Wexford County Airport , Cadillac"
        },
        {
            "value": "CAE",
            "icao_code": "KCAE",
            "label": "Columbia Metropolitan Airport , Columbia"
        },
        {
            "value": "CAF",
            "icao_code": "SWCA",
            "label": "Carauari Airport , Carauari"
        },
        {
            "value": "CAG",
            "icao_code": "LIEE",
            "label": "Cagliari Elmas Airport , Cagliari"
        },
        {
            "value": "CAH",
            "icao_code": "VVCM",
            "label": "C\u00e0 Mau Airport , C\u00e0 Mau"
        },
        {
            "value": "CAI",
            "icao_code": "HECA",
            "label": "Cairo International Airport , Cairo"
        },
        {
            "value": "CAJ",
            "icao_code": "SVCN",
            "label": "Canaima Airport , Canaima"
        },
        {
            "value": "CAK",
            "icao_code": "KCAK",
            "label": "Akron-Canton Airport , Akron"
        },
        {
            "value": "CAL",
            "icao_code": "EGEC",
            "label": "Campbeltown Airport , Campbeltown"
        },
        {
            "value": "CAM",
            "icao_code": "SLCA",
            "label": "Camiri Airport , Camiri"
        },
        {
            "value": "CAN",
            "icao_code": "ZGGG",
            "label": "Guangzhou Baiyun International Airport , Guangzhou"
        },
        {
            "value": "CAO",
            "icao_code": "KCAO",
            "label": "Clayton Municipal Airpark , Clayton"
        },
        {
            "value": "CAP",
            "icao_code": "MTCH",
            "label": "Hugo Ch\u00e1vez International Airport , Cap-Ha\u00eftien"
        },
        {
            "value": "CAQ",
            "icao_code": "SKCU",
            "label": "Juan H. White Airport , Caucasia"
        },
        {
            "value": "CAR",
            "icao_code": "KCAR",
            "label": "Caribou Municipal Airport , Caribou"
        },
        {
            "value": "CAS",
            "icao_code": "GMMC",
            "label": "Anfa Airport , Casablanca"
        },
        {
            "value": "CAT",
            "icao_code": "LPCS",
            "label": "Cascais Municipal Aerodrome , Cascais"
        },
        {
            "value": "CAU",
            "icao_code": "SNRU",
            "label": "Caruaru Airport , Caruaru"
        },
        {
            "value": "CAV",
            "icao_code": "FNCZ",
            "label": "Cazombo Airport , Cazombo"
        },
        {
            "value": "CAW",
            "icao_code": "SBCP",
            "label": "Bartolomeu Lysandro Airport , Campos dos Goytacazes"
        },
        {
            "value": "CAX",
            "icao_code": "EGNC",
            "label": "Carlisle Lake District Airport , Carlisle"
        },
        {
            "value": "CAY",
            "icao_code": "SOCA",
            "label": "Cayenne \u2013 F\u00e9lix Ebou\u00e9 Airport , Cayenne"
        },
        {
            "value": "CAZ",
            "icao_code": "YCBA",
            "label": "Cobar Airport , Cobar"
        },
        {
            "value": "CBB",
            "icao_code": "SLCB",
            "label": "Jorge Wilstermann International Airport , Cochabamba"
        },
        {
            "value": "CBC",
            "icao_code": null,
            "label": "Cherrabun Airport , Cherrabun"
        },
        {
            "value": "CBD",
            "icao_code": "VOCX",
            "label": "Car Nicobar Air Force Base , Car Nicobar"
        },
        {
            "value": "CBE",
            "icao_code": "KCBE",
            "label": "Greater Cumberland Regional Airport , Cumberland"
        },
        {
            "value": "CBF",
            "icao_code": "KCBF",
            "label": "Council Bluffs Municipal Airport , Council Bluffs"
        },
        {
            "value": "CBG",
            "icao_code": "EGSC",
            "label": "Cambridge Airport , Cambridge"
        },
        {
            "value": "CBH",
            "icao_code": "DAOR",
            "label": "Boudghene Ben Ali Lotfi Airport , B\u00e9char"
        },
        {
            "value": "CBI",
            "icao_code": null,
            "label": "Cape Barren Island Airport , Cape Barren Island"
        },
        {
            "value": "CBJ",
            "icao_code": "MDCR",
            "label": "Cabo Rojo Airport , Pedernales"
        },
        {
            "value": "CBK",
            "icao_code": "KCBK",
            "label": "Colby Municipal Airport , Colby"
        },
        {
            "value": "CBL",
            "icao_code": "SVCB",
            "label": "Tom\u00e1s de Heres Airport , Ciudad Bol\u00edvar"
        },
        {
            "value": "CBM",
            "icao_code": "KCBM",
            "label": "Columbus Air Force Base , Columbus"
        },
        {
            "value": "CBN",
            "icao_code": "WICD",
            "label": "Penggung Airport , Cirebon"
        },
        {
            "value": "CBO",
            "icao_code": "RPMC",
            "label": "Awang Airport , Cotabato City"
        },
        {
            "value": "CBP",
            "icao_code": "LPCO",
            "label": "Coimbra Airport , Coimbra"
        },
        {
            "value": "CBQ",
            "icao_code": "DNCA",
            "label": "Margaret Ekpo International Airport , Calabar"
        },
        {
            "value": "CBR",
            "icao_code": "YSCB",
            "label": "Canberra Airport , Canberra"
        },
        {
            "value": "CBS",
            "icao_code": "SVON",
            "label": "Oro Negro Airport , Cabimas"
        },
        {
            "value": "CBT",
            "icao_code": "FNCT",
            "label": "Catumbela Airport , Catumbela"
        },
        {
            "value": "CBU",
            "icao_code": "EDCD",
            "label": "Cottbus-Drewitz Airport , Cottbus"
        },
        {
            "value": "CBV",
            "icao_code": "MGCB",
            "label": "Cob\u00e1n Airport , Cob\u00e1n"
        },
        {
            "value": "CBW",
            "icao_code": "SSKM",
            "label": "Campo Mour\u00e3o Airport , Campo Mour\u00e3o"
        },
        {
            "value": "CBX",
            "icao_code": "YCDO",
            "label": "Condobolin Airport , Condobolin"
        },
        {
            "value": "CBY",
            "icao_code": null,
            "label": "Canobie Airport , Canobie Station"
        },
        {
            "value": "CCA",
            "icao_code": "SLCH",
            "label": "Chimore Airport , Chimor\u00e9"
        },
        {
            "value": "CCB",
            "icao_code": "KCCB",
            "label": "Cable Airport , Upland"
        },
        {
            "value": "CCC",
            "icao_code": "MUCC",
            "label": "Jardines del Rey Airport , Cayo Coco"
        },
        {
            "value": "CCF",
            "icao_code": "LFMK",
            "label": "Carcassonne Airport , Carcassonne"
        },
        {
            "value": "CCG",
            "icao_code": null,
            "label": "Crane County Airport , Crane"
        },
        {
            "value": "CCH",
            "icao_code": "SCCC",
            "label": "Chile Chico Airfield , Chile Chico"
        },
        {
            "value": "CCI",
            "icao_code": "SSCK",
            "label": "Conc\u00f3rdia Airport , Conc\u00f3rdia"
        },
        {
            "value": "CCJ",
            "icao_code": "VOCL",
            "label": "Calicut International Airport , Kozhikode"
        },
        {
            "value": "CCK",
            "icao_code": "YPCC",
            "label": "Cocos (Keeling) Islands Airport , Cocos (Keeling) Islands"
        },
        {
            "value": "CCL",
            "icao_code": "YCCA",
            "label": "Chinchilla Airport , Chinchilla"
        },
        {
            "value": "CCM",
            "icao_code": "SBCM",
            "label": "Diom\u00edcio Freitas Airport , Crici\u00fama"
        },
        {
            "value": "CCN",
            "icao_code": "OACC",
            "label": "Chaghcharan Airport , Chaghcharan"
        },
        {
            "value": "CCO",
            "icao_code": "SKCI",
            "label": "Carimagua Airport , Carimagua"
        },
        {
            "value": "CCP",
            "icao_code": "SCIE",
            "label": "Carriel Sur International Airport , Concepci\u00f3n"
        },
        {
            "value": "CCR",
            "icao_code": "KCCR",
            "label": "Buchanan Field Airport , Concord"
        },
        {
            "value": "CCS",
            "icao_code": "SVMI",
            "label": "Sim\u00f3n Bol\u00edvar International Airport , Caracas"
        },
        {
            "value": "CCT",
            "icao_code": null,
            "label": "Colonia Catriel Airport , Catriel"
        },
        {
            "value": "CCU",
            "icao_code": "VECC",
            "label": "Netaji Subhas Chandra Bose International Airport , Kolkata"
        },
        {
            "value": "CCV",
            "icao_code": "NVSF",
            "label": "Craig Cove Airport , Craig Cove"
        },
        {
            "value": "CCW",
            "icao_code": "YCWL",
            "label": "Cowell Airport , Cowell"
        },
        {
            "value": "CCX",
            "icao_code": "SWKC",
            "label": "C\u00e1ceres Airport , C\u00e1ceres"
        },
        {
            "value": "CCY",
            "icao_code": "KCCY",
            "label": "Northeast Iowa Regional Airport , Charles City"
        },
        {
            "value": "CCZ",
            "icao_code": "MYBC",
            "label": "Chub Cay International Airport , Chub Cay"
        },
        {
            "value": "CDA",
            "icao_code": "YCOO",
            "label": "Cooinda Airport , Cooinda"
        },
        {
            "value": "CDB",
            "icao_code": "PACD",
            "label": "Cold Bay Airport , Cold Bay"
        },
        {
            "value": "CDC",
            "icao_code": "KCDC",
            "label": "Cedar City Regional Airport , Cedar City"
        },
        {
            "value": "CDD",
            "icao_code": null,
            "label": "Cauquira Airport , Cauquira"
        },
        {
            "value": "CDE",
            "icao_code": "ZBCD",
            "label": "Chengde Puning Airport , Chengde"
        },
        {
            "value": "CDG",
            "icao_code": "LFPG",
            "label": "Charles de Gaulle Airport , Paris"
        },
        {
            "value": "CDH",
            "icao_code": "KCDH",
            "label": "Harrell Field , Camden"
        },
        {
            "value": "CDI",
            "icao_code": "SNKI",
            "label": "Cachoeiro de Itapemirim Airport , Cachoeiro de Itapemirim"
        },
        {
            "value": "CDJ",
            "icao_code": "SBAA",
            "label": "Concei\u00e7\u00e3o do Araguaia Airport , Concei\u00e7\u00e3o do Araguaia"
        },
        {
            "value": "CDK",
            "icao_code": "KCDK",
            "label": "George T. Lewis Airport , Cedar Key"
        },
        {
            "value": "CDL",
            "icao_code": null,
            "label": "Candle 2 Airport , Candle"
        },
        {
            "value": "CDN",
            "icao_code": "KCDN",
            "label": "Woodward Field , Camden"
        },
        {
            "value": "CDO",
            "icao_code": "FACD",
            "label": "Cradock Airport , Cradock"
        },
        {
            "value": "CDP",
            "icao_code": "VOCP",
            "label": "Kadapa Airport , Kadapa"
        },
        {
            "value": "CDQ",
            "icao_code": "YCRY",
            "label": "Croydon Airport , Croydon"
        },
        {
            "value": "CDR",
            "icao_code": "KCDR",
            "label": "Chadron Municipal Airport , Chadron"
        },
        {
            "value": "CDS",
            "icao_code": "KCDS",
            "label": "Childress Municipal Airport , Childress"
        },
        {
            "value": "CDT",
            "icao_code": "LECS",
            "label": "Castell\u00f3n\u2013Costa Azahar Airport , Castell\u00f3n de la Plana"
        },
        {
            "value": "CDU",
            "icao_code": "YSCN",
            "label": "Camden Airport , Camden"
        },
        {
            "value": "CDV",
            "icao_code": "PACV",
            "label": "Merle K. (Mudhole) Smith Airport , Cordova"
        },
        {
            "value": "CDW",
            "icao_code": "KCDW",
            "label": "Essex County Airport , Caldwell"
        },
        {
            "value": "CDY",
            "icao_code": "RPMU",
            "label": "Cagayan de Sulu Airport , Mapun"
        },
        {
            "value": "CEA",
            "icao_code": "KCEA",
            "label": "Cessna Aircraft Field , Wichita"
        },
        {
            "value": "CEB",
            "icao_code": "RPVM",
            "label": "Mactan\u2013Cebu International Airport , Lapu-Lapu"
        },
        {
            "value": "CEC",
            "icao_code": "KCEC",
            "label": "Del Norte County Airport , Crescent City"
        },
        {
            "value": "CED",
            "icao_code": "YCDU",
            "label": "Ceduna Airport , Ceduna"
        },
        {
            "value": "CEE",
            "icao_code": "ULWC",
            "label": "Cherepovets Airport , Cherepovets"
        },
        {
            "value": "CEF",
            "icao_code": "KCEF",
            "label": "Westover Metropolitan Airport , Springfield"
        },
        {
            "value": "CEG",
            "icao_code": "EGNR",
            "label": "Hawarden Airport , Chester"
        },
        {
            "value": "CEH",
            "icao_code": "FWCD",
            "label": "Chelinda Airport , Chelinda"
        },
        {
            "value": "CEI",
            "icao_code": "VTCT",
            "label": "Chiang Rai International Airport , Chiang Rai"
        },
        {
            "value": "CEK",
            "icao_code": "USCC",
            "label": "Chelyabinsk Airport , Chelyabinsk"
        },
        {
            "value": "CEL",
            "icao_code": "SSCN",
            "label": "Canela Airport , Canela"
        },
        {
            "value": "CEM",
            "icao_code": "PARL",
            "label": "Central Airport , Central"
        },
        {
            "value": "CEN",
            "icao_code": "MMCN",
            "label": "Ciudad Obreg\u00f3n International Airport , Ciudad Obreg\u00f3n"
        },
        {
            "value": "CEO",
            "icao_code": "FNWK",
            "label": "Waco Kungo Airport , Waku-Kungo"
        },
        {
            "value": "CEP",
            "icao_code": "SLCP",
            "label": "Concepci\u00f3n Airport , Concepci\u00f3n"
        },
        {
            "value": "CEQ",
            "icao_code": "LFMD",
            "label": "Cannes \u2013 Mandelieu Airport , Cannes"
        },
        {
            "value": "CER",
            "icao_code": "LFRC",
            "label": "Cherbourg \u2013 Maupertus Airport , Cherbourg-Octeville"
        },
        {
            "value": "CES",
            "icao_code": "YCNK",
            "label": "Cessnock Airport , Cessnock"
        },
        {
            "value": "CET",
            "icao_code": "LFOU",
            "label": "Cholet Le Pontreau Airport , Cholet"
        },
        {
            "value": "CEU",
            "icao_code": "KCEU",
            "label": "Oconee County Regional Airport , Clemson"
        },
        {
            "value": "CEV",
            "icao_code": "KCEV",
            "label": "Mettel Field , Connersville"
        },
        {
            "value": "CEW",
            "icao_code": "KCEW",
            "label": "Bob Sikes Airport , Crestview"
        },
        {
            "value": "CEX",
            "icao_code": null,
            "label": "Chena Hot Springs Airport , Chena Hot Springs"
        },
        {
            "value": "CEY",
            "icao_code": "KCEY",
            "label": "Murray-Calloway County Airport , Murray"
        },
        {
            "value": "CEZ",
            "icao_code": "KCEZ",
            "label": "Cortez Municipal Airport , Cortez"
        },
        {
            "value": "CFB",
            "icao_code": "SBCB",
            "label": "Cabo Frio International Airport , Cabo Frio"
        },
        {
            "value": "CFC",
            "icao_code": "SBCD",
            "label": "Ca\u00e7ador Airport , Ca\u00e7ador"
        },
        {
            "value": "CFD",
            "icao_code": "KCFD",
            "label": "Coulter Field , Bryan"
        },
        {
            "value": "CFE",
            "icao_code": "LFLC",
            "label": "Clermont-Ferrand Auvergne Airport , Clermont-Ferrand"
        },
        {
            "value": "CFF",
            "icao_code": "FNCF",
            "label": "Cafunfo Airport , Cafunfo"
        },
        {
            "value": "CFG",
            "icao_code": "MUCF",
            "label": "Jaime Gonz\u00e1lez Airport , Cienfuegos"
        },
        {
            "value": "CFH",
            "icao_code": null,
            "label": "Clifton Hills Airport , Clifton Hills"
        },
        {
            "value": "CFI",
            "icao_code": "YCFD",
            "label": "Camfield Airport , Camfield Station"
        },
        {
            "value": "CFK",
            "icao_code": "DAOI",
            "label": "Chlef International Airport , Chlef"
        },
        {
            "value": "CFM",
            "icao_code": null,
            "label": "Conklin (Leismer) Airport , Wood Buffalo"
        },
        {
            "value": "CFN",
            "icao_code": "EIDL",
            "label": "Donegal Airport , Donegal"
        },
        {
            "value": "CFO",
            "icao_code": null,
            "label": "Confresa Airport , Confresa"
        },
        {
            "value": "CFP",
            "icao_code": null,
            "label": "Carpentaria Downs Airport , Carpentaria Downs"
        },
        {
            "value": "CFQ",
            "icao_code": null,
            "label": "Creston Aerodrome , Creston"
        },
        {
            "value": "CFR",
            "icao_code": "LFRK",
            "label": "Caen \u2013 Carpiquet Airport , Caen"
        },
        {
            "value": "CFS",
            "icao_code": "YCFS",
            "label": "Coffs Harbour Airport , Coffs Harbour"
        },
        {
            "value": "CFT",
            "icao_code": "KCFT",
            "label": "Greenlee County Airport , Clifton"
        },
        {
            "value": "CFU",
            "icao_code": "LGKR",
            "label": "Corfu International Airport , Corfu"
        },
        {
            "value": "CFV",
            "icao_code": "KCFV",
            "label": "Coffeyville Municipal Airport , Coffeyville"
        },
        {
            "value": "CGA",
            "icao_code": null,
            "label": "Craig Seaplane Base , Craig"
        },
        {
            "value": "CGB",
            "icao_code": "SBCY",
            "label": "Marechal Rondon International Airport , Cuiab\u00e1"
        },
        {
            "value": "CGC",
            "icao_code": "AYCG",
            "label": "Cape Gloucester Airport , Cape Gloucester"
        },
        {
            "value": "CGD",
            "icao_code": "ZGCD",
            "label": "Changde Taohuayuan Airport , Changde"
        },
        {
            "value": "CGE",
            "icao_code": "KCGE",
            "label": "Cambridge\u2013Dorchester Airport , Cambridge"
        },
        {
            "value": "CGF",
            "icao_code": "KCGF",
            "label": "Cuyahoga County Airport , Cleveland"
        },
        {
            "value": "CGG",
            "icao_code": null,
            "label": "Casiguran Airport , Casiguran"
        },
        {
            "value": "CGH",
            "icao_code": "SBSP",
            "label": "S\u00e3o Paulo/Congonhas\u2013Deputado Freitas Nobre Airport , S\u00e3o Paulo"
        },
        {
            "value": "CGI",
            "icao_code": "KCGI",
            "label": "Cape Girardeau Regional Airport , Cape Girardeau"
        },
        {
            "value": "CGJ",
            "icao_code": null,
            "label": "Kasompe Airport , Chingola"
        },
        {
            "value": "CGK",
            "icao_code": "WIII",
            "label": "Soekarno\u2013Hatta International Airport , Jakarta"
        },
        {
            "value": "CGM",
            "icao_code": "RPMH",
            "label": "Camiguin Airport , Mambajao"
        },
        {
            "value": "CGN",
            "icao_code": "EDDK",
            "label": "Cologne Bonn Airport , Cologne"
        },
        {
            "value": "CGO",
            "icao_code": "ZHCC",
            "label": "Zhengzhou Xinzheng International Airport , Zhengzhou"
        },
        {
            "value": "CGP",
            "icao_code": "VGEG",
            "label": "Shah Amanat International Airport , Chittagong"
        },
        {
            "value": "CGQ",
            "icao_code": "ZYCC",
            "label": "Changchun Longjia International Airport , Changchun"
        },
        {
            "value": "CGR",
            "icao_code": "SBCG",
            "label": "Campo Grande International Airport , Campo Grande"
        },
        {
            "value": "CGS",
            "icao_code": "KCGS",
            "label": "College Park Airport , College Park"
        },
        {
            "value": "CGT",
            "icao_code": null,
            "label": "Chinguetti Airport , Chinguetti"
        },
        {
            "value": "CGV",
            "icao_code": "YCAG",
            "label": "Caiguna Airport , Caiguna"
        },
        {
            "value": "CGY",
            "icao_code": "RPMY",
            "label": "Laguindingan Airport , Cagayan de Oro"
        },
        {
            "value": "CGZ",
            "icao_code": "KCGZ",
            "label": "Casa Grande Municipal Airport , Casa Grande"
        },
        {
            "value": "CHA",
            "icao_code": "KCHA",
            "label": "Chattanooga Metropolitan Airport , Chattanooga"
        },
        {
            "value": "CHB",
            "icao_code": "OPCL",
            "label": "Chilas Airport , Chilas"
        },
        {
            "value": "CHC",
            "icao_code": "NZCH",
            "label": "Christchurch International Airport , Christchurch"
        },
        {
            "value": "CHF",
            "icao_code": "RKPE",
            "label": "Jinhae Airport , Jinhae"
        },
        {
            "value": "CHG",
            "icao_code": "ZYCY",
            "label": "Chaoyang Airport , Chaoyang"
        },
        {
            "value": "CHH",
            "icao_code": "SPPY",
            "label": "Chachapoyas Airport , Chachapoyas"
        },
        {
            "value": "CHI",
            "icao_code": null,
            "label": "metropolitan area , Chicago"
        },
        {
            "value": "CHJ",
            "icao_code": "FVCH",
            "label": "Chipinge Airport , Chipinge"
        },
        {
            "value": "CHK",
            "icao_code": "KCHK",
            "label": "Chickasha Municipal Airport , Chickasha"
        },
        {
            "value": "CHL",
            "icao_code": "KLLJ",
            "label": "Challis Airport , Challis"
        },
        {
            "value": "CHM",
            "icao_code": "SPEO",
            "label": "Tnte. FAP Jaime Montreuil Morales Airport , Chimbote"
        },
        {
            "value": "CHN",
            "icao_code": "RKJU",
            "label": "Jeonju Airport , Jeonju"
        },
        {
            "value": "CHO",
            "icao_code": "KCHO",
            "label": "Charlottesville\u2013Albemarle Airport , Charlottesville"
        },
        {
            "value": "CHP",
            "icao_code": null,
            "label": "Circle Hot Springs Airport , Circle Hot Springs"
        },
        {
            "value": "CHQ",
            "icao_code": "LGSA",
            "label": "Chania International Airport , Chania"
        },
        {
            "value": "CHR",
            "icao_code": "LFLX",
            "label": "Ch\u00e2teauroux-Centre \"Marcel Dassault\" Airport , Ch\u00e2teauroux"
        },
        {
            "value": "CHS",
            "icao_code": "KCHS",
            "label": "Charleston International Airport , Charleston"
        },
        {
            "value": "CHT",
            "icao_code": "NZCI",
            "label": "Chatham Islands / Tuuta Airport , Chatham Islands"
        },
        {
            "value": "CHU",
            "icao_code": "PACH",
            "label": "Chuathbaluk Airport , Chuathbaluk"
        },
        {
            "value": "CHV",
            "icao_code": "LPCH",
            "label": "Chaves Airport , Chaves"
        },
        {
            "value": "CHX",
            "icao_code": "MPCH",
            "label": "Changuinola \"Capit\u00e1n Manuel Ni\u00f1o\" International Airport , Changuinola"
        },
        {
            "value": "CHY",
            "icao_code": "AGGC",
            "label": "Choiseul Bay Airport , Choiseul Bay"
        },
        {
            "value": "CHZ",
            "icao_code": null,
            "label": "Chiloquin State Airport , Chiloquin"
        },
        {
            "value": "CIA",
            "icao_code": "LIRA",
            "label": "Ciampino\u2013G. B. Pastine International Airport , Rome"
        },
        {
            "value": "CIC",
            "icao_code": "KCIC",
            "label": "Chico Municipal Airport , Chico"
        },
        {
            "value": "CID",
            "icao_code": "KCID",
            "label": "The Eastern Iowa Airport , Cedar Rapids"
        },
        {
            "value": "CIE",
            "icao_code": "YCOI",
            "label": "Collie Airport , Collie"
        },
        {
            "value": "CIF",
            "icao_code": "ZBCF",
            "label": "Chifeng Yulong Airport , Chifeng"
        },
        {
            "value": "CIG",
            "icao_code": "KCAG",
            "label": "Craig\u2013Moffat Airport , Craig"
        },
        {
            "value": "CIH",
            "icao_code": "ZBCZ",
            "label": "Changzhi Wangcun Airport , Changzhi"
        },
        {
            "value": "CII",
            "icao_code": "LTBD",
            "label": "Ayd\u0131n Airport , Ayd\u0131n"
        },
        {
            "value": "CIJ",
            "icao_code": "SLCO",
            "label": "Captain An\u00edbal Arab Airport , Cobija"
        },
        {
            "value": "CIK",
            "icao_code": "PACI",
            "label": "Chalkyitsik Airport , Chalkyitsik"
        },
        {
            "value": "CIL",
            "icao_code": null,
            "label": "Council Airport , Council"
        },
        {
            "value": "CIM",
            "icao_code": "SKCM",
            "label": "Cimitarra Airport , Cimitarra"
        },
        {
            "value": "CIN",
            "icao_code": "KCIN",
            "label": "Arthur N. Neu Airport , Carroll"
        },
        {
            "value": "CIO",
            "icao_code": "SGCO",
            "label": "Teniente Coronel Carmelo Peralta Airport , Concepci\u00f3n"
        },
        {
            "value": "CIP",
            "icao_code": "FLCP",
            "label": "Chipata Airport , Chipata"
        },
        {
            "value": "CIQ",
            "icao_code": null,
            "label": "Chiquimula Airport , Chiquimula"
        },
        {
            "value": "CIR",
            "icao_code": "KCIR",
            "label": "Cairo Regional Airport , Cairo"
        },
        {
            "value": "CIS",
            "icao_code": "PCIS",
            "label": "Canton Island Airport , Canton Island"
        },
        {
            "value": "CIT",
            "icao_code": "UAII",
            "label": "Shymkent International Airport , Shymkent"
        },
        {
            "value": "CIU",
            "icao_code": "KCIU",
            "label": "Chippewa County International Airport , Sault Ste Marie"
        },
        {
            "value": "CIW",
            "icao_code": "TVSC",
            "label": "Canouan Airport , Canouan"
        },
        {
            "value": "CIX",
            "icao_code": "SPHI",
            "label": "FAP Captain Jos\u00e9 Abelardo Qui\u00f1ones Gonz\u00e1lez International Airport , Chiclayo"
        },
        {
            "value": "CIY",
            "icao_code": "LICB",
            "label": "Comiso Airport , Comiso"
        },
        {
            "value": "CIZ",
            "icao_code": "SWKO",
            "label": "Coari Airport , Coari"
        },
        {
            "value": "CJA",
            "icao_code": "SPJR",
            "label": "Mayor General FAP Armando Revoredo Iglesias Airport , Cajamarca"
        },
        {
            "value": "CJB",
            "icao_code": "VOCB",
            "label": "Coimbatore International Airport , Coimbatore"
        },
        {
            "value": "CJC",
            "icao_code": "SCCF",
            "label": "El Loa Airport , Calama"
        },
        {
            "value": "CJD",
            "icao_code": null,
            "label": "Candilejas Airport , Candilejas"
        },
        {
            "value": "CJF",
            "icao_code": "YCWA",
            "label": "Coondewanna Airport , Coondewanna"
        },
        {
            "value": "CJH",
            "icao_code": null,
            "label": "Chilko Lake (Tsylos Park Lodge) Aerodrome , Chilko Lake"
        },
        {
            "value": "CJJ",
            "icao_code": "RKTU",
            "label": "Cheongju International Airport , Cheongju"
        },
        {
            "value": "CJL",
            "icao_code": "OPCH",
            "label": "Chitral Airport , Chitral"
        },
        {
            "value": "CJM",
            "icao_code": "VTSE",
            "label": "Chumphon Airport , Chumphon"
        },
        {
            "value": "CJN",
            "icao_code": "WI1A",
            "label": "Cijulang Nusawiru Airport , Cijulang"
        },
        {
            "value": "CJS",
            "icao_code": "MMCS",
            "label": "Abraham Gonz\u00e1lez International Airport , Ciudad Ju\u00e1rez"
        },
        {
            "value": "CJT",
            "icao_code": "MMCO",
            "label": "Copalar Airport , Comit\u00e1n"
        },
        {
            "value": "CJU",
            "icao_code": "RKPC",
            "label": "Jeju International Airport , Jeju"
        },
        {
            "value": "CKA",
            "icao_code": "KCKA",
            "label": "Kegelman Air Force Auxiliary Field , Cherokee"
        },
        {
            "value": "CKB",
            "icao_code": "KCKB",
            "label": "North Central West Virginia Airport , Clarksburg"
        },
        {
            "value": "CKC",
            "icao_code": "UKKE",
            "label": "Cherkasy International Airport , Cherkasy"
        },
        {
            "value": "CKD",
            "icao_code": "PACJ",
            "label": "Crooked Creek Airport , Crooked Creek"
        },
        {
            "value": "CKE",
            "icao_code": null,
            "label": "Lampson Field , Lakeport"
        },
        {
            "value": "CKG",
            "icao_code": "ZUCK",
            "label": "Chongqing Jiangbei International Airport , Chongqing"
        },
        {
            "value": "CKH",
            "icao_code": "UESO",
            "label": "Chokurdakh Airport , Chokurdakh"
        },
        {
            "value": "CKI",
            "icao_code": "YCKI",
            "label": "Croker Island Airport , Croker Island"
        },
        {
            "value": "CKK",
            "icao_code": "KCVK",
            "label": "Sharp County Regional Airport , Ash Flat"
        },
        {
            "value": "CKL",
            "icao_code": "UUMU",
            "label": "Chkalovsky Airport , Shchyolkovo"
        },
        {
            "value": "CKM",
            "icao_code": "KCKM",
            "label": "Fletcher Field , Clarksdale"
        },
        {
            "value": "CKN",
            "icao_code": "KCKN",
            "label": "Crookston Municipal Airport , Crookston"
        },
        {
            "value": "CKO",
            "icao_code": "SSCP",
            "label": "Corn\u00e9lio Proc\u00f3pio Airport , Corn\u00e9lio Proc\u00f3pio"
        },
        {
            "value": "CKR",
            "icao_code": null,
            "label": "Crane Island Airstrip , Crane Island"
        },
        {
            "value": "CKS",
            "icao_code": "SBCJ",
            "label": "Caraj\u00e1s Airport , Parauapebas"
        },
        {
            "value": "CKT",
            "icao_code": "OIMC",
            "label": "Sarakhs Airport , Sarakhs"
        },
        {
            "value": "CKU",
            "icao_code": null,
            "label": "Cordova Municipal Airport , Cordova"
        },
        {
            "value": "CKV",
            "icao_code": "KCKV",
            "label": "Clarksville\u2013Montgomery County Regional Airport , Clarksville"
        },
        {
            "value": "CKW",
            "icao_code": "YCHK",
            "label": "Graeme Rowley Aerodrome , Christmas Creek mine"
        },
        {
            "value": "CKX",
            "icao_code": null,
            "label": "Chicken Airport , Chicken"
        },
        {
            "value": "CKY",
            "icao_code": "GUCY",
            "label": "Conakry International Airport , Conakry"
        },
        {
            "value": "CKZ",
            "icao_code": "LTBH",
            "label": "\u00c7anakkale Airport , \u00c7anakkale"
        },
        {
            "value": "CLA",
            "icao_code": "VGCM",
            "label": "Comilla Airport , Comilla"
        },
        {
            "value": "CLD",
            "icao_code": "KCRQ",
            "label": "McClellan\u2013Palomar Airport , Carlsbad"
        },
        {
            "value": "CLE",
            "icao_code": "KCLE",
            "label": "Cleveland Hopkins International Airport , Cleveland"
        },
        {
            "value": "CLG",
            "icao_code": null,
            "label": "New Coalinga Municipal Airport , Coalinga"
        },
        {
            "value": "CLH",
            "icao_code": "YCAH",
            "label": "Coolah Airport , Coolah"
        },
        {
            "value": "CLI",
            "icao_code": "KCLI",
            "label": "Clintonville Municipal Airport , Clintonville"
        },
        {
            "value": "CLJ",
            "icao_code": "LRCL",
            "label": "Cluj-Napoca International Airport , Cluj-Napoca"
        },
        {
            "value": "CLK",
            "icao_code": "KCLK",
            "label": "Clinton Regional Airport , Clinton"
        },
        {
            "value": "CLL",
            "icao_code": "KCLL",
            "label": "Easterwood Airport , College Station"
        },
        {
            "value": "CLM",
            "icao_code": "KCLM",
            "label": "William R. Fairchild International Airport , Port Angeles"
        },
        {
            "value": "CLN",
            "icao_code": "SBCI",
            "label": "Carolina Airport , Carolina"
        },
        {
            "value": "CLO",
            "icao_code": "SKCL",
            "label": "Alfonso Bonilla Arag\u00f3n International Airport , Cali"
        },
        {
            "value": "CLP",
            "icao_code": "PFCL",
            "label": "Clarks Point Airport , Clark's Point"
        },
        {
            "value": "CLQ",
            "icao_code": "MMIA",
            "label": "Licenciado Miguel de la Madrid Airport , Colima City"
        },
        {
            "value": "CLR",
            "icao_code": "KCLR",
            "label": "Cliff Hatfield Memorial Airport , Calipatria"
        },
        {
            "value": "CLS",
            "icao_code": "KCLS",
            "label": "Chehalis-Centralia Airport , Chehalis"
        },
        {
            "value": "CLT",
            "icao_code": "KCLT",
            "label": "Charlotte Douglas International Airport , Charlotte"
        },
        {
            "value": "CLU",
            "icao_code": "KBAK",
            "label": "Columbus Municipal Airport , Columbus"
        },
        {
            "value": "CLV",
            "icao_code": "SWKN",
            "label": "Caldas Novas Airport , Caldas Novas"
        },
        {
            "value": "CLW",
            "icao_code": "KCLW",
            "label": "Clearwater Air Park , Clearwater"
        },
        {
            "value": "CLX",
            "icao_code": "SATC",
            "label": "Clorinda Airport , Clorinda"
        },
        {
            "value": "CLY",
            "icao_code": "LFKC",
            "label": "Calvi \u2013 Sainte-Catherine Airport , Calvi"
        },
        {
            "value": "CLZ",
            "icao_code": "SVCL",
            "label": "Calabozo Airport , Calabozo"
        },
        {
            "value": "CMA",
            "icao_code": "YCMU",
            "label": "Cunnamulla Airport , Cunnamulla"
        },
        {
            "value": "CMB",
            "icao_code": "VCBI",
            "label": "Bandaranaike International Airport , Colombo"
        },
        {
            "value": "CMC",
            "icao_code": "SNWC",
            "label": "Camocim Airport , Camocim"
        },
        {
            "value": "CMD",
            "icao_code": "YCTM",
            "label": "Cootamundra Airport , Cootamundra"
        },
        {
            "value": "CME",
            "icao_code": "MMCE",
            "label": "Ciudad del Carmen International Airport , Ciudad del Carmen"
        },
        {
            "value": "CMF",
            "icao_code": "LFLB",
            "label": "Chamb\u00e9ry-Savoie Airport , Chamb\u00e9ry"
        },
        {
            "value": "CMG",
            "icao_code": "SBCR",
            "label": "Corumb\u00e1 International Airport , Corumb\u00e1"
        },
        {
            "value": "CMH",
            "icao_code": "KCMH",
            "label": "John Glenn Columbus International Airport , Columbus"
        },
        {
            "value": "CMI",
            "icao_code": "KCMI",
            "label": "University of Illinois Willard Airport , Champaign"
        },
        {
            "value": "CMJ",
            "icao_code": "RCCM",
            "label": "Qimei Airport , Qimei"
        },
        {
            "value": "CMK",
            "icao_code": "FWCM",
            "label": "Club Makokola Airport , Club Makokola"
        },
        {
            "value": "CML",
            "icao_code": "YCMW",
            "label": "Camooweal Airport , Camooweal"
        },
        {
            "value": "CMM",
            "icao_code": "MGCR",
            "label": "Carmelita Airport , Carmelita"
        },
        {
            "value": "CMN",
            "icao_code": "GMMN",
            "label": "Mohammed V International Airport , Casablanca"
        },
        {
            "value": "CMO",
            "icao_code": "HCMO",
            "label": "Obbia Airport , Obbia"
        },
        {
            "value": "CMP",
            "icao_code": "SNKE",
            "label": "Santana do Araguaia Airport , Santana do Araguaia"
        },
        {
            "value": "CMQ",
            "icao_code": "YCMT",
            "label": "Clermont Airport , Clermont"
        },
        {
            "value": "CMR",
            "icao_code": "LFGA",
            "label": "Colmar \u2013 Houssen Airport , Colmar"
        },
        {
            "value": "CMS",
            "icao_code": "HCMS",
            "label": "Iskushuban Airport , Iskushuban"
        },
        {
            "value": "CMT",
            "icao_code": null,
            "label": "Cameta Airport , Camet\u00e1"
        },
        {
            "value": "CMU",
            "icao_code": "AYCH",
            "label": "Chimbu Airport , Kundiawa"
        },
        {
            "value": "CMV",
            "icao_code": "NZCX",
            "label": "Coromandel Aerodrome , Coromandel"
        },
        {
            "value": "CMW",
            "icao_code": "MUCM",
            "label": "Ignacio Agramonte International Airport , Camag\u00fcey"
        },
        {
            "value": "CMX",
            "icao_code": "KCMX",
            "label": "Houghton County Memorial Airport , Hancock"
        },
        {
            "value": "CMY",
            "icao_code": "KCMY",
            "label": "Sparta/Fort McCoy Airport , Sparta"
        },
        {
            "value": "CMZ",
            "icao_code": null,
            "label": "Caia Airport , Caia"
        },
        {
            "value": "CNA",
            "icao_code": "MMCA",
            "label": "Cananea National Airport , Cananea"
        },
        {
            "value": "CNB",
            "icao_code": "YCNM",
            "label": "Coonamble Airport , Coonamble"
        },
        {
            "value": "CNC",
            "icao_code": "YCCT",
            "label": "Coconut Island Airport , Coconut (Poruma) Island"
        },
        {
            "value": "CND",
            "icao_code": "LRCK",
            "label": "Mihail Kog\u0103lniceanu International Airport , Constan\u021ba"
        },
        {
            "value": "CNE",
            "icao_code": null,
            "label": "Fremont County Airport , Ca\u00f1on City"
        },
        {
            "value": "CNF",
            "icao_code": "SBCF",
            "label": "Tancredo Neves International Airport , Belo Horizonte"
        },
        {
            "value": "CNG",
            "icao_code": "LFBG",
            "label": "Cognac \u2013 Ch\u00e2teaubernard Air Base , Cognac"
        },
        {
            "value": "CNH",
            "icao_code": "KCNH",
            "label": "Claremont Municipal Airport , Claremont"
        },
        {
            "value": "CNI",
            "icao_code": "ZYCH",
            "label": "Changhai Airport , Changhai"
        },
        {
            "value": "CNJ",
            "icao_code": "YCCY",
            "label": "Cloncurry Airport , Cloncurry"
        },
        {
            "value": "CNK",
            "icao_code": "KCNK",
            "label": "Blosser Municipal Airport , Concordia"
        },
        {
            "value": "CNL",
            "icao_code": "EKSN",
            "label": "Sindal Airport , Sindal"
        },
        {
            "value": "CNM",
            "icao_code": "KCNM",
            "label": "Cavern City Air Terminal , Carlsbad"
        },
        {
            "value": "CNN",
            "icao_code": "VOKN",
            "label": "Kannur International Airport , Kannur"
        },
        {
            "value": "CNO",
            "icao_code": "KCNO",
            "label": "Chino Airport , Chino"
        },
        {
            "value": "CNP",
            "icao_code": "BGCO",
            "label": "Nerlerit Inaat Airport , Ittoqqortoormiit"
        },
        {
            "value": "CNQ",
            "icao_code": "SARC",
            "label": "Doctor Fernando Piragine Niveyro International Airport , Corrientes"
        },
        {
            "value": "CNR",
            "icao_code": "SCRA",
            "label": "Cha\u00f1aral Airport , Cha\u00f1aral"
        },
        {
            "value": "CNS",
            "icao_code": "YBCS",
            "label": "Cairns Airport , Cairns"
        },
        {
            "value": "CNT",
            "icao_code": null,
            "label": "Charata Airport , Charata"
        },
        {
            "value": "CNU",
            "icao_code": "KCNU",
            "label": "Chanute Martin Johnson Airport , Chanute"
        },
        {
            "value": "CNV",
            "icao_code": "SNED",
            "label": "Canavieiras Airport , Canavieiras"
        },
        {
            "value": "CNW",
            "icao_code": "KCNW",
            "label": "TSTC Waco Airport , Waco"
        },
        {
            "value": "CNX",
            "icao_code": "VTCC",
            "label": "Chiang Mai International Airport , Chiang Mai"
        },
        {
            "value": "CNY",
            "icao_code": "KCNY",
            "label": "Canyonlands Field , Moab"
        },
        {
            "value": "CNZ",
            "icao_code": null,
            "label": "Cangamba Airport , Cangamba"
        },
        {
            "value": "COA",
            "icao_code": null,
            "label": "Columbia Airport , Columbia"
        },
        {
            "value": "COB",
            "icao_code": null,
            "label": "Coolibah Airport , Coolibah Station"
        },
        {
            "value": "COC",
            "icao_code": "SAAC",
            "label": "Comodoro Pierrestegui Airport , Concordia"
        },
        {
            "value": "COD",
            "icao_code": "KCOD",
            "label": "Yellowstone Regional Airport , Cody"
        },
        {
            "value": "COE",
            "icao_code": "KCOE",
            "label": "Coeur d'Alene Airport , Coeur d'Alene"
        },
        {
            "value": "COF",
            "icao_code": "KCOF",
            "label": "Patrick Space Force Base , Cocoa Beach"
        },
        {
            "value": "COG",
            "icao_code": "SKCD",
            "label": "Mandinga Airport , Condoto"
        },
        {
            "value": "COH",
            "icao_code": "VECO",
            "label": "Cooch Behar Airport , Cooch Behar"
        },
        {
            "value": "COI",
            "icao_code": "KCOI",
            "label": "Merritt Island Airport , Merritt Island"
        },
        {
            "value": "COJ",
            "icao_code": "YCBB",
            "label": "Coonabarabran Airport , Coonabarabran"
        },
        {
            "value": "COK",
            "icao_code": "VOCI",
            "label": "Cochin International Airport , Kochi"
        },
        {
            "value": "COL",
            "icao_code": null,
            "label": "Coll Airport , Coll"
        },
        {
            "value": "COM",
            "icao_code": "KCOM",
            "label": "Coleman Municipal Airport , Coleman"
        },
        {
            "value": "CON",
            "icao_code": "KCON",
            "label": "Concord Municipal Airport , Concord"
        },
        {
            "value": "COO",
            "icao_code": "DBBB",
            "label": "Cadjehoun Airport , Cotonou"
        },
        {
            "value": "COP",
            "icao_code": null,
            "label": "Cooperstown-Westville Airport , Cooperstown"
        },
        {
            "value": "COQ",
            "icao_code": "ZMCD",
            "label": "Choibalsan Airport , Choibalsan"
        },
        {
            "value": "COR",
            "icao_code": "SACO",
            "label": "Ingeniero Aeron\u00e1utico Ambrosio L.V. Taravella International Airport , C\u00f3rdoba"
        },
        {
            "value": "COS",
            "icao_code": "KCOS",
            "label": "Colorado Springs Airport , Colorado Springs"
        },
        {
            "value": "COT",
            "icao_code": "KCOT",
            "label": "Cotulla\u2013La Salle County Airport , Cotulla"
        },
        {
            "value": "COU",
            "icao_code": "KCOU",
            "label": "Columbia Regional Airport , Columbia"
        },
        {
            "value": "COW",
            "icao_code": "SCQB",
            "label": "Coquimbo Airport , Coquimbo"
        },
        {
            "value": "COY",
            "icao_code": "YCWY",
            "label": "Coolawanyah Station Airport , Coolawanyah Station"
        },
        {
            "value": "COZ",
            "icao_code": "MDCZ",
            "label": "Constanza Airport , Constanza"
        },
        {
            "value": "CPA",
            "icao_code": "GLCP",
            "label": "Cape Palmas Airport , Harper"
        },
        {
            "value": "CPB",
            "icao_code": "SKCA",
            "label": "Capurgan\u00e1 Airport , Capurgan\u00e1"
        },
        {
            "value": "CPC",
            "icao_code": "SAZY",
            "label": "Aviador Carlos Campos Airport , San Mart\u00edn de los Andes"
        },
        {
            "value": "CPD",
            "icao_code": "YCBP",
            "label": "Coober Pedy Airport , Coober Pedy"
        },
        {
            "value": "CPE",
            "icao_code": "MMCP",
            "label": "Ing. Alberto Acu\u00f1a Ongay International Airport , Campeche"
        },
        {
            "value": "CPF",
            "icao_code": "WARC",
            "label": "Ngloram Airport , Cepu"
        },
        {
            "value": "CPG",
            "icao_code": null,
            "label": "Carmen de Patagones Airport , Carmen de Patagones"
        },
        {
            "value": "CPH",
            "icao_code": "EKCH",
            "label": "Copenhagen Airport, Kastrup , Copenhagen"
        },
        {
            "value": "CPI",
            "icao_code": null,
            "label": "Cape Orford Airport , Cape Orford"
        },
        {
            "value": "CPL",
            "icao_code": "SKHA",
            "label": "General Navas Pardo Airport , Chaparral"
        },
        {
            "value": "CPM",
            "icao_code": "KCPM",
            "label": "Compton/Woodley Airport , Compton"
        },
        {
            "value": "CPN",
            "icao_code": null,
            "label": "Cape Rodney Airport , Cape Rodney"
        },
        {
            "value": "CPO",
            "icao_code": "SCAT",
            "label": "Desierto de Atacama Airport , Copiap\u00f3"
        },
        {
            "value": "CPP",
            "icao_code": "SCKP",
            "label": "Coposa Airport , Pica"
        },
        {
            "value": "CPR",
            "icao_code": "KCPR",
            "label": "Casper\u2013Natrona County International Airport , Casper"
        },
        {
            "value": "CPS",
            "icao_code": "KCPS",
            "label": "St. Louis Downtown Airport , Cahokia"
        },
        {
            "value": "CPT",
            "icao_code": "FACT",
            "label": "Cape Town International Airport , Cape Town"
        },
        {
            "value": "CPU",
            "icao_code": null,
            "label": "Cururupu Airport , Cururupu"
        },
        {
            "value": "CPV",
            "icao_code": "SBKG",
            "label": "Campina Grande Airport , Campina Grande"
        },
        {
            "value": "CPX",
            "icao_code": "TJCP",
            "label": "Benjam\u00edn Rivera Noriega Airport , Culebra"
        },
        {
            "value": "CQA",
            "icao_code": null,
            "label": "Canarana Airport , Canarana"
        },
        {
            "value": "CQD",
            "icao_code": "OIFS",
            "label": "Shahrekord Airport , Shahrekord"
        },
        {
            "value": "CQF",
            "icao_code": "LFAC",
            "label": "Calais\u2013Dunkerque Airport , Calais"
        },
        {
            "value": "CQM",
            "icao_code": "LERL",
            "label": "Ciudad Real Central Airport , Ciudad Real"
        },
        {
            "value": "CQP",
            "icao_code": null,
            "label": "Cape Flattery Airport , Cape Flattery"
        },
        {
            "value": "CQS",
            "icao_code": "SWCQ",
            "label": "Costa Marques Airport , Costa Marques"
        },
        {
            "value": "CQT",
            "icao_code": null,
            "label": "Caquetania Airport , Caquetania"
        },
        {
            "value": "CQW",
            "icao_code": null,
            "label": "Wulong Xiann\u00fcshan Airport , Wulong"
        },
        {
            "value": "CRA",
            "icao_code": "LRCV",
            "label": "Craiova International Airport , Craiova"
        },
        {
            "value": "CRB",
            "icao_code": "YCBR",
            "label": "Collarenebri Airport , Collarenebri"
        },
        {
            "value": "CRC",
            "icao_code": "SKGO",
            "label": "Santa Ana Airport , Cartago"
        },
        {
            "value": "CRD",
            "icao_code": "SAVC",
            "label": "General Enrique Mosconi International Airport , Comodoro Rivadavia"
        },
        {
            "value": "CRE",
            "icao_code": "KCRE",
            "label": "Grand Strand Airport , North Myrtle Beach"
        },
        {
            "value": "CRF",
            "icao_code": "FEFC",
            "label": "Carnot Airport , Carnot"
        },
        {
            "value": "CRG",
            "icao_code": "KCRG",
            "label": "Jacksonville Executive at Craig Airport , Jacksonville"
        },
        {
            "value": "CRH",
            "icao_code": null,
            "label": "Cherrabah Airport , Cherrabah"
        },
        {
            "value": "CRI",
            "icao_code": "MYCI",
            "label": "Colonel Hill Airport , Colonel Hill"
        },
        {
            "value": "CRJ",
            "icao_code": null,
            "label": "Coorabie Airport , Coorabie"
        },
        {
            "value": "CRK",
            "icao_code": "RPLC",
            "label": "Clark International Airport , Clark Special Economic Zone"
        },
        {
            "value": "CRL",
            "icao_code": "EBCI",
            "label": "Brussels South Charleroi Airport , Charleroi"
        },
        {
            "value": "CRM",
            "icao_code": "RPVF",
            "label": "Catarman National Airport , Catarman"
        },
        {
            "value": "CRO",
            "icao_code": "KCRO",
            "label": "Corcoran Airport , Corcoran"
        },
        {
            "value": "CRP",
            "icao_code": "KCRP",
            "label": "Corpus Christi International Airport , Corpus Christi"
        },
        {
            "value": "CRQ",
            "icao_code": "SBCV",
            "label": "Caravelas Airport , Caravelas"
        },
        {
            "value": "CRR",
            "icao_code": "SANW",
            "label": "Ceres Airport , Ceres"
        },
        {
            "value": "CRS",
            "icao_code": "KCRS",
            "label": "C. David Campbell Field , Corsicana"
        },
        {
            "value": "CRT",
            "icao_code": "KCRT",
            "label": "Z. M. Jack Stell Field , Crossett"
        },
        {
            "value": "CRU",
            "icao_code": "TGPZ",
            "label": "Lauriston Airport , Carriacou"
        },
        {
            "value": "CRV",
            "icao_code": "LIBC",
            "label": "Crotone Airport , Crotone"
        },
        {
            "value": "CRW",
            "icao_code": "KCRW",
            "label": "Yeager Airport , Charleston"
        },
        {
            "value": "CRX",
            "icao_code": "KCRX",
            "label": "Roscoe Turner Airport , Corinth"
        },
        {
            "value": "CRY",
            "icao_code": null,
            "label": "Carlton Hill Airport , Carlton Hill Station"
        },
        {
            "value": "CRZ",
            "icao_code": "UTAV",
            "label": "Turkmenabat Airport , T\u00fcrkmenabat"
        },
        {
            "value": "CSA",
            "icao_code": null,
            "label": "Colonsay Airport , Colonsay"
        },
        {
            "value": "CSB",
            "icao_code": "LRCS",
            "label": "Caransebe\u0219 Airport , Caransebe\u0219"
        },
        {
            "value": "CSC",
            "icao_code": "MRMJ",
            "label": "Ca\u00f1as Mojica Airport , Ca\u00f1as"
        },
        {
            "value": "CSD",
            "icao_code": null,
            "label": "Cresswell Downs Airport , Cresswell Downs"
        },
        {
            "value": "CSE",
            "icao_code": null,
            "label": "Buckhorn Ranch Airport , Crested Butte"
        },
        {
            "value": "CSF",
            "icao_code": "LFPC",
            "label": "Creil Air Base , Creil"
        },
        {
            "value": "CSG",
            "icao_code": "KCSG",
            "label": "Columbus Airport , Columbus"
        },
        {
            "value": "CSH",
            "icao_code": "ULAS",
            "label": "Solovki Airport , Solovetsky Islands"
        },
        {
            "value": "CSI",
            "icao_code": "YCAS",
            "label": "Casino Airport , Casino"
        },
        {
            "value": "CSK",
            "icao_code": "GOGS",
            "label": "Cap Skirring Airport , Cap Skirring"
        },
        {
            "value": "CSM",
            "icao_code": "KCSM",
            "label": "Clinton-Sherman Airport , Clinton"
        },
        {
            "value": "CSN",
            "icao_code": "KCXP",
            "label": "Carson Airport , Carson City"
        },
        {
            "value": "CSO",
            "icao_code": "EDBC",
            "label": "Magdeburg\u2013Cochstedt Airport , Magdeburg"
        },
        {
            "value": "CSQ",
            "icao_code": "KCSQ",
            "label": "Creston Municipal Airport , Creston"
        },
        {
            "value": "CSR",
            "icao_code": null,
            "label": "Casuarito Airport , Casuarito"
        },
        {
            "value": "CSS",
            "icao_code": "SSCL",
            "label": "Cassil\u00e2ndia Airport , Cassil\u00e2ndia"
        },
        {
            "value": "CST",
            "icao_code": "NFCS",
            "label": "Castaway Island Seaplane Base , Castaway Island"
        },
        {
            "value": "CSU",
            "icao_code": null,
            "label": "Santa Cruz do Sul Airport , Santa Cruz do Sul"
        },
        {
            "value": "CSV",
            "icao_code": "KCSV",
            "label": "Crossville Memorial-Whitson Field , Crossville"
        },
        {
            "value": "CSX",
            "icao_code": "ZGHA",
            "label": "Changsha Huanghua International Airport , Changsha"
        },
        {
            "value": "CSY",
            "icao_code": "UWKS",
            "label": "Cheboksary Airport , Cheboksary"
        },
        {
            "value": "CSZ",
            "icao_code": "SAZC",
            "label": "Brigadier Hector Eduardo Ruiz Airport , Coronel Su\u00e1rez"
        },
        {
            "value": "CTA",
            "icao_code": "LICC",
            "label": "Catania\u2013Fontanarossa Airport , Catania"
        },
        {
            "value": "CTB",
            "icao_code": "KCTB",
            "label": "Cut Bank Municipal Airport , Cut Bank"
        },
        {
            "value": "CTC",
            "icao_code": "SANC",
            "label": "Coronel Felipe Varela International Airport , Catamarca"
        },
        {
            "value": "CTD",
            "icao_code": "MPCE",
            "label": "Chitr\u00e9 Alonso Valderrama Airport , Chitr\u00e9"
        },
        {
            "value": "CTE",
            "icao_code": null,
            "label": "Cart\u00ed Airport , Cart\u00ed"
        },
        {
            "value": "CTF",
            "icao_code": "MGCT",
            "label": "Coatepeque Airport , Coatepeque"
        },
        {
            "value": "CTG",
            "icao_code": "SKCG",
            "label": "Rafael N\u00fa\u00f1ez International Airport , Cartagena"
        },
        {
            "value": "CTH",
            "icao_code": "KMQS",
            "label": "Chester County G. O. Carlson Airport , Coatesville"
        },
        {
            "value": "CTI",
            "icao_code": "FNCV",
            "label": "Cuito Cuanavale Airport , Cuito Cuanavale"
        },
        {
            "value": "CTK",
            "icao_code": null,
            "label": "Canton Municipal Airport , Canton"
        },
        {
            "value": "CTL",
            "icao_code": "YBCV",
            "label": "Charleville Airport , Charleville"
        },
        {
            "value": "CTM",
            "icao_code": "MMCM",
            "label": "Chetumal International Airport , Chetumal"
        },
        {
            "value": "CTN",
            "icao_code": "YCKN",
            "label": "Cooktown Airport , Cooktown"
        },
        {
            "value": "CTO",
            "icao_code": null,
            "label": "Calverton Executive Airpark , Calverton"
        },
        {
            "value": "CTP",
            "icao_code": "SNCP",
            "label": "Carutapera Airport , Carutapera"
        },
        {
            "value": "CTQ",
            "icao_code": "SSVP",
            "label": "Do Palmar Airport , Santa Vit\u00f3ria"
        },
        {
            "value": "CTR",
            "icao_code": null,
            "label": "Cattle Creek Airport , Cattle Creek"
        },
        {
            "value": "CTS",
            "icao_code": "RJCC",
            "label": "New Chitose Airport , Sapporo"
        },
        {
            "value": "CTT",
            "icao_code": "LFMQ",
            "label": "Le Castellet Airport , Le Castellet"
        },
        {
            "value": "CTU",
            "icao_code": "ZUUU",
            "label": "Chengdu Shuangliu International Airport , Chengdu"
        },
        {
            "value": "CTW",
            "icao_code": null,
            "label": "Cottonwood Airport , Cottonwood"
        },
        {
            "value": "CTX",
            "icao_code": null,
            "label": "Cortland County Airport , Cortland"
        },
        {
            "value": "CTY",
            "icao_code": "KCTY",
            "label": "Cross City Airport , Cross City"
        },
        {
            "value": "CTZ",
            "icao_code": "KCTZ",
            "label": "Clinton\u2013Sampson County Airport , Clinton"
        },
        {
            "value": "CUA",
            "icao_code": "MMDA",
            "label": "Ciudad Constituci\u00f3n Airport , Ciudad Constituci\u00f3n"
        },
        {
            "value": "CUB",
            "icao_code": "KCUB",
            "label": "Jim Hamilton\u2013L.B. Owens Airport , Columbia"
        },
        {
            "value": "CUC",
            "icao_code": "SKCC",
            "label": "Camilo Daza International Airport , C\u00facuta"
        },
        {
            "value": "CUD",
            "icao_code": "YCDR",
            "label": "Caloundra Airport , Caloundra"
        },
        {
            "value": "CUE",
            "icao_code": "SECU",
            "label": "Mariscal Lamar International Airport , Cuenca"
        },
        {
            "value": "CUF",
            "icao_code": "LIMZ",
            "label": "Cuneo International Airport , Cuneo"
        },
        {
            "value": "CUG",
            "icao_code": "YCUA",
            "label": "Cudal Airport , Cudal"
        },
        {
            "value": "CUH",
            "icao_code": "KCUH",
            "label": "Cushing Municipal Airport , Cushing"
        },
        {
            "value": "CUI",
            "icao_code": null,
            "label": "Curillo Airport , Curillo"
        },
        {
            "value": "CUJ",
            "icao_code": null,
            "label": "Culion Airport , Culion"
        },
        {
            "value": "CUK",
            "icao_code": null,
            "label": "Caye Caulker Airport , Caye Caulker"
        },
        {
            "value": "CUL",
            "icao_code": "MMCL",
            "label": "Bachigualato Federal International Airport , Culiac\u00e1n"
        },
        {
            "value": "CUM",
            "icao_code": "SVCU",
            "label": "Antonio Jos\u00e9 de Sucre Airport , Cuman\u00e1"
        },
        {
            "value": "CUN",
            "icao_code": "MMUN",
            "label": "Canc\u00fan International Airport , Canc\u00fan"
        },
        {
            "value": "CUO",
            "icao_code": "SKCR",
            "label": "Caruru Airport , Caruru"
        },
        {
            "value": "CUP",
            "icao_code": "SVCP",
            "label": "General Jos\u00e9 Francisco Berm\u00fadez Airport , Car\u00fapano"
        },
        {
            "value": "CUQ",
            "icao_code": "YCOE",
            "label": "Coen Airport , Coen"
        },
        {
            "value": "CUR",
            "icao_code": "TNCC",
            "label": "Cura\u00e7ao International Airport , Willemstad"
        },
        {
            "value": "CUS",
            "icao_code": null,
            "label": "Columbus Municipal Airport , Columbus"
        },
        {
            "value": "CUT",
            "icao_code": "SAZW",
            "label": "Cutral C\u00f3 Airport , Cutral C\u00f3"
        },
        {
            "value": "CUU",
            "icao_code": "MMCU",
            "label": "General Roberto Fierro Villalobos International Airport , Chihuahua"
        },
        {
            "value": "CUV",
            "icao_code": "SVCG",
            "label": "Casigua El Cubo Airport , Casigua El Cubo"
        },
        {
            "value": "CUX",
            "icao_code": null,
            "label": "Cuddihy Field , Corpus Christi"
        },
        {
            "value": "CUY",
            "icao_code": "YCUE",
            "label": "Cue Airport , Cue"
        },
        {
            "value": "CUZ",
            "icao_code": "SPZO",
            "label": "Alejandro Velasco Astete International Airport , Cusco"
        },
        {
            "value": "CVB",
            "icao_code": "AYCB",
            "label": "Chungrebu Airport , Chungrebu"
        },
        {
            "value": "CVC",
            "icao_code": "YCEE",
            "label": "Cleve Airport , Cleve"
        },
        {
            "value": "CVE",
            "icao_code": "SKCV",
            "label": "Cove\u00f1as Airport , Cove\u00f1as"
        },
        {
            "value": "CVF",
            "icao_code": "LFLJ",
            "label": "Courchevel Altiport , Courchevel"
        },
        {
            "value": "CVG",
            "icao_code": "KCVG",
            "label": "Cincinnati/Northern Kentucky International Airport , Hebron"
        },
        {
            "value": "CVH",
            "icao_code": null,
            "label": "Caviahue Airport , Caviahue"
        },
        {
            "value": "CVI",
            "icao_code": null,
            "label": "Caleta Olivia Airport , Caleta Olivia"
        },
        {
            "value": "CVJ",
            "icao_code": "MMCB",
            "label": "General Mariano Matamoros Airport , Cuernavaca"
        },
        {
            "value": "CVL",
            "icao_code": null,
            "label": "Cape Vogel Airport , Cape Vogel"
        },
        {
            "value": "CVM",
            "icao_code": "MMCV",
            "label": "General Pedro J. M\u00e9ndez International Airport , Ciudad Victoria"
        },
        {
            "value": "CVN",
            "icao_code": "KCVN",
            "label": "Clovis Municipal Airport , Clovis"
        },
        {
            "value": "CVO",
            "icao_code": "KCVO",
            "label": "Corvallis Municipal Airport , Corvallis"
        },
        {
            "value": "CVQ",
            "icao_code": "YCAR",
            "label": "Carnarvon Airport , Carnarvon"
        },
        {
            "value": "CVS",
            "icao_code": "KCVS",
            "label": "Cannon Air Force Base , Clovis"
        },
        {
            "value": "CVT",
            "icao_code": "EGBE",
            "label": "Coventry Airport , Coventry"
        },
        {
            "value": "CVU",
            "icao_code": "LPCR",
            "label": "Corvo Airport , Corvo Island"
        },
        {
            "value": "CWA",
            "icao_code": "KCWA",
            "label": "Central Wisconsin Airport , Wausau"
        },
        {
            "value": "CWB",
            "icao_code": "SBCT",
            "label": "Afonso Pena International Airport , Curitiba"
        },
        {
            "value": "CWC",
            "icao_code": "UKLN",
            "label": "Chernivtsi International Airport , Chernivtsi"
        },
        {
            "value": "CWF",
            "icao_code": "KCWF",
            "label": "Chennault International Airport , Lake Charles"
        },
        {
            "value": "CWI",
            "icao_code": "KCWI",
            "label": "Clinton Municipal Airport , Clinton"
        },
        {
            "value": "CWJ",
            "icao_code": "ZPCW",
            "label": "Cangyuan Washan Airport , Nuoliang Township"
        },
        {
            "value": "CWL",
            "icao_code": "EGFF",
            "label": "Cardiff Airport , Cardiff"
        },
        {
            "value": "CWR",
            "icao_code": "YCWI",
            "label": "Cowarie Airport , Cowarie"
        },
        {
            "value": "CWS",
            "icao_code": null,
            "label": "Center Island Airport , Center Island"
        },
        {
            "value": "CWT",
            "icao_code": "YCWR",
            "label": "Cowra Airport , Cowra"
        },
        {
            "value": "CWW",
            "icao_code": "YCOR",
            "label": "Corowa Airport , Corowa"
        },
        {
            "value": "CWX",
            "icao_code": null,
            "label": "Cochise County Airport , Willcox"
        },
        {
            "value": "CXA",
            "icao_code": "SVCD",
            "label": "Caicara del Orinoco Airport , Caicara del Orinoco"
        },
        {
            "value": "CXB",
            "icao_code": "VGCB",
            "label": "Cox's Bazar Airport , Cox's Bazar"
        },
        {
            "value": "CXC",
            "icao_code": null,
            "label": "Chitina Airport , Chitina"
        },
        {
            "value": "CXF",
            "icao_code": "PACX",
            "label": "Coldfoot Airport , Coldfoot"
        },
        {
            "value": "CXH",
            "icao_code": "CYHC",
            "label": "Vancouver Harbour Flight Centre , Vancouver"
        },
        {
            "value": "CXI",
            "icao_code": "PLCH",
            "label": "Cassidy International Airport , Christmas Island"
        },
        {
            "value": "CXJ",
            "icao_code": "SBCX",
            "label": "Caxias do Sul Airport , Caxias do Sul"
        },
        {
            "value": "CXL",
            "icao_code": "KCXL",
            "label": "Calexico International Airport , Calexico"
        },
        {
            "value": "CXN",
            "icao_code": "HCMC",
            "label": "Candala Airport , Candala"
        },
        {
            "value": "CXO",
            "icao_code": "KCXO",
            "label": "Lone Star Executive Airport , Houston"
        },
        {
            "value": "CXP",
            "icao_code": "WIHL",
            "label": "Tunggul Wulung Airport , Cilacap"
        },
        {
            "value": "CXQ",
            "icao_code": "YCRK",
            "label": "Christmas Creek Airport , Christmas Creek Station"
        },
        {
            "value": "CXR",
            "icao_code": "VVCR",
            "label": "Cam Ranh International Airport , Cam Ranh"
        },
        {
            "value": "CXT",
            "icao_code": "YCHT",
            "label": "Charters Towers Airport , Charters Towers"
        },
        {
            "value": "CXY",
            "icao_code": "MYCC",
            "label": "Cat Cay Airport , Cat Cays"
        },
        {
            "value": "CYA",
            "icao_code": "MTCA",
            "label": "Antoine-Simon Airport , Les Cayes"
        },
        {
            "value": "CYB",
            "icao_code": "MWCB",
            "label": "Charles Kirkconnell International Airport , Cayman Brac"
        },
        {
            "value": "CYC",
            "icao_code": null,
            "label": "Caye Chapel Airport , Caye Chapel"
        },
        {
            "value": "CYD",
            "icao_code": "MZMF",
            "label": "San Ignacio Town Airstrip , San Ignacio"
        },
        {
            "value": "CYF",
            "icao_code": "PACK",
            "label": "Chefornak Airport , Chefornak"
        },
        {
            "value": "CYG",
            "icao_code": "YCRG",
            "label": "Corryong Airport , Corryong"
        },
        {
            "value": "CYI",
            "icao_code": "RCKU",
            "label": "Chiayi Airport , Chiayi"
        },
        {
            "value": "CYL",
            "icao_code": "MHCS",
            "label": "Coyoles Airport , Coyoles"
        },
        {
            "value": "CYM",
            "icao_code": null,
            "label": "Chatham Seaplane Base , Chatham"
        },
        {
            "value": "CYO",
            "icao_code": "MUCL",
            "label": "Vilo Acu\u00f1a Airport , Cayo Largo del Sur"
        },
        {
            "value": "CYP",
            "icao_code": "RPVC",
            "label": "Calbayog Airport , Calbayog"
        },
        {
            "value": "CYR",
            "icao_code": "SUCA",
            "label": "Colonia Airport , Colonia del Sacramento"
        },
        {
            "value": "CYS",
            "icao_code": "KCYS",
            "label": "Cheyenne Regional Airport , Cheyenne"
        },
        {
            "value": "CYT",
            "icao_code": "PACY",
            "label": "Yakataga Airport , Yakataga"
        },
        {
            "value": "CYU",
            "icao_code": "RPLO",
            "label": "Cuyo Airport , Cuyo"
        },
        {
            "value": "CYW",
            "icao_code": "MMCY",
            "label": "Captain Rogelio Castillo National Airport , Celaya"
        },
        {
            "value": "CYX",
            "icao_code": "UESS",
            "label": "Chersky Airport , Chersky"
        },
        {
            "value": "CYZ",
            "icao_code": "RPUY",
            "label": "Cauayan Airport , Cauayan"
        },
        {
            "value": "CZA",
            "icao_code": "MMCT",
            "label": "Chichen Itza International Airport , Chichen Itza"
        },
        {
            "value": "CZB",
            "icao_code": null,
            "label": "Carlos Ruhl Airport , Cruz Alta"
        },
        {
            "value": "CZC",
            "icao_code": null,
            "label": "Copper Center 2 Airport , Copper Center"
        },
        {
            "value": "CZE",
            "icao_code": "SVCR",
            "label": "Jos\u00e9 Leonardo Chirino Airport , Coro"
        },
        {
            "value": "CZF",
            "icao_code": "PACZ",
            "label": "Cape Romanzof LRRS Airport , Cape Romanzof"
        },
        {
            "value": "CZH",
            "icao_code": null,
            "label": "Corozal Airport , Corozal"
        },
        {
            "value": "CZJ",
            "icao_code": null,
            "label": "Coraz\u00f3n de Jes\u00fas Airport , Coraz\u00f3n de Jes\u00fas"
        },
        {
            "value": "CZK",
            "icao_code": "KCZK",
            "label": "Cascade Locks State Airport , Cascade Locks"
        },
        {
            "value": "CZL",
            "icao_code": "DABC",
            "label": "Mohamed Boudiaf International Airport , Constantine"
        },
        {
            "value": "CZM",
            "icao_code": "MMCZ",
            "label": "Cozumel International Airport , Cozumel"
        },
        {
            "value": "CZN",
            "icao_code": null,
            "label": "Chisana Airport , Chisana"
        },
        {
            "value": "CZO",
            "icao_code": null,
            "label": "Chistochina Airport , Chistochina"
        },
        {
            "value": "CZP",
            "icao_code": null,
            "label": "Cape Pole Seaplane Base , Cape Pole"
        },
        {
            "value": "CZS",
            "icao_code": "SBCZ",
            "label": "Cruzeiro do Sul International Airport , Cruzeiro do Sul"
        },
        {
            "value": "CZT",
            "icao_code": "KCZT",
            "label": "Dimmit County Airport , Carrizo Springs"
        },
        {
            "value": "CZU",
            "icao_code": "SKCZ",
            "label": "Las Brujas Airport , Corozal"
        },
        {
            "value": "CZW",
            "icao_code": "EPCH",
            "label": "Cz\u0119stochowa-Rudniki Airport , Cz\u0119stochowa"
        },
        {
            "value": "CZX",
            "icao_code": "ZSCG",
            "label": "Changzhou Benniu Airport , Changzhou"
        },
        {
            "value": "CZY",
            "icao_code": "YUNY",
            "label": "Cluny Airport , Cluny"
        },
        {
            "value": "DAA",
            "icao_code": "KDAA",
            "label": "Davison Army Airfield , Fort Belvoir"
        },
        {
            "value": "DAB",
            "icao_code": "KDAB",
            "label": "Daytona Beach International Airport , Daytona Beach"
        },
        {
            "value": "DAC",
            "icao_code": "VGHS",
            "label": "Shahjalal International Airport , Dhaka"
        },
        {
            "value": "DAD",
            "icao_code": "VVDN",
            "label": "Da Nang International Airport , Da Nang"
        },
        {
            "value": "DAF",
            "icao_code": null,
            "label": "Daup Airport , Daup"
        },
        {
            "value": "DAG",
            "icao_code": "KDAG",
            "label": "Barstow-Daggett Airport , Daggett"
        },
        {
            "value": "DAH",
            "icao_code": null,
            "label": "Dathina Airport , Dathina"
        },
        {
            "value": "DAK",
            "icao_code": "HEDK",
            "label": "Dakhla Oasis Airport , Dakhla Oasis"
        },
        {
            "value": "DAL",
            "icao_code": "KDAL",
            "label": "Dallas Love Field , Dallas"
        },
        {
            "value": "DAM",
            "icao_code": "OSDI",
            "label": "Damascus International Airport , Damascus"
        },
        {
            "value": "DAN",
            "icao_code": "KDAN",
            "label": "Danville Regional Airport , Danville"
        },
        {
            "value": "DAO",
            "icao_code": null,
            "label": "Dabo Airport , Dabo"
        },
        {
            "value": "DAP",
            "icao_code": "VNDL",
            "label": "Darchula Airport , Darchula"
        },
        {
            "value": "DAR",
            "icao_code": "HTDA",
            "label": "Julius Nyerere International Airport , Dar es Salaam"
        },
        {
            "value": "DAS",
            "icao_code": null,
            "label": "Great Bear Lake Airport , Great Bear Lake"
        },
        {
            "value": "DAT",
            "icao_code": "ZBDT",
            "label": "Datong Yungang Airport , Datong"
        },
        {
            "value": "DAU",
            "icao_code": "AYDU",
            "label": "Daru Airport , Daru"
        },
        {
            "value": "DAV",
            "icao_code": "MPDA",
            "label": "Enrique Malek International Airport , David"
        },
        {
            "value": "DAX",
            "icao_code": "ZUDX",
            "label": "Dazhou Heshi Airport , Dazhou"
        },
        {
            "value": "DAY",
            "icao_code": "KDAY",
            "label": "Dayton International Airport , Dayton"
        },
        {
            "value": "DAZ",
            "icao_code": "OADZ",
            "label": "Darwaz Airport , Darwaz"
        },
        {
            "value": "DBA",
            "icao_code": "OPDB",
            "label": "Dalbandin Airport , Dalbandin"
        },
        {
            "value": "DBB",
            "icao_code": "HEAL",
            "label": "Al Alamain International Airport , El Alamein"
        },
        {
            "value": "DBC",
            "icao_code": "ZYBA",
            "label": "Baicheng Chang'an Airport , Baicheng"
        },
        {
            "value": "DBD",
            "icao_code": "VEDB",
            "label": "Dhanbad Airport , Dhanbad"
        },
        {
            "value": "DBK",
            "icao_code": null,
            "label": "Dutch Bay Seaplane Base , Kalpitiya"
        },
        {
            "value": "DBM",
            "icao_code": "HADM",
            "label": "Debre Marqos Airport , Debre Marqos"
        },
        {
            "value": "DBN",
            "icao_code": "KDBN",
            "label": "W. H. 'Bud' Barron Airport , Dublin"
        },
        {
            "value": "DBO",
            "icao_code": "YSDU",
            "label": "Dubbo City Regional Airport , Dubbo"
        },
        {
            "value": "DBP",
            "icao_code": "AYDB",
            "label": "Debepare Airport , Debepare"
        },
        {
            "value": "DBQ",
            "icao_code": "KDBQ",
            "label": "Dubuque Regional Airport , Dubuque"
        },
        {
            "value": "DBR",
            "icao_code": "VE89",
            "label": "Darbhanga Airport , Darbhanga"
        },
        {
            "value": "DBS",
            "icao_code": null,
            "label": "Dubois Municipal Airport , Dubois"
        },
        {
            "value": "DBT",
            "icao_code": "HADT",
            "label": "Debre Tabor Airport , Debre Tabor"
        },
        {
            "value": "DBU",
            "icao_code": null,
            "label": "Dambulu Oya Tank Seaplane Base , Dambulla"
        },
        {
            "value": "DBV",
            "icao_code": "LDDU",
            "label": "Dubrovnik Airport , Dubrovnik"
        },
        {
            "value": "DBY",
            "icao_code": "YDAY",
            "label": "Dalby Airport , Dalby"
        },
        {
            "value": "DCA",
            "icao_code": "KDCA",
            "label": "Ronald Reagan Washington National Airport , Arlington County"
        },
        {
            "value": "DCF",
            "icao_code": "TDCF",
            "label": "Canefield Airport , Canefield"
        },
        {
            "value": "DCG",
            "icao_code": null,
            "label": "Dubai Creek Seaplane Base , Dubai"
        },
        {
            "value": "DCI",
            "icao_code": "LIED",
            "label": "Decimomannu Air Base , Decimomannu"
        },
        {
            "value": "DCK",
            "icao_code": null,
            "label": "Dahl Creek Airport , Dahl Creek"
        },
        {
            "value": "DCM",
            "icao_code": "LFCK",
            "label": "Castres\u2013Mazamet Airport , Castres"
        },
        {
            "value": "DCN",
            "icao_code": "YCIN",
            "label": "RAAF Base Curtin , Derby"
        },
        {
            "value": "DCT",
            "icao_code": "MYRD",
            "label": "Duncan Town Airport , Duncan Town"
        },
        {
            "value": "DCU",
            "icao_code": "KDCU",
            "label": "Pryor Field Regional Airport , Decatur"
        },
        {
            "value": "DCY",
            "icao_code": "ZUDC",
            "label": "Daocheng Yading Airport , Daocheng"
        },
        {
            "value": "DDC",
            "icao_code": "KDDC",
            "label": "Dodge City Regional Airport , Dodge City"
        },
        {
            "value": "DDD",
            "icao_code": "VRMU",
            "label": "Dhaalu Airport , Dhaalu Atoll"
        },
        {
            "value": "DDG",
            "icao_code": "ZYDD",
            "label": "Dandong Langtou Airport , Dandong"
        },
        {
            "value": "DDM",
            "icao_code": null,
            "label": "Dodoima Airport , Dodoima"
        },
        {
            "value": "DDN",
            "icao_code": "YDLT",
            "label": "Delta Downs Airport , Delta Downs"
        },
        {
            "value": "DDU",
            "icao_code": "OPDD",
            "label": "Dadu Airport , Dadu"
        },
        {
            "value": "DEA",
            "icao_code": "OPDG",
            "label": "Dera Ghazi Khan International Airport , Dera Ghazi Khan"
        },
        {
            "value": "DEB",
            "icao_code": "LHDC",
            "label": "Debrecen International Airport , Debrecen"
        },
        {
            "value": "DEC",
            "icao_code": "KDEC",
            "label": "Decatur Airport , Decatur"
        },
        {
            "value": "DED",
            "icao_code": "VIDN",
            "label": "Jolly Grant Airport , Dehradun"
        },
        {
            "value": "DEE",
            "icao_code": "UHSM",
            "label": "Yuzhno-Kurilsk Mendeleyevo Airport , Yuzhno-Kurilsk"
        },
        {
            "value": "DEF",
            "icao_code": "OIAD",
            "label": "Dezful Airport , Dezful"
        },
        {
            "value": "DEH",
            "icao_code": "KDEH",
            "label": "Decorah Municipal Airport , Decorah"
        },
        {
            "value": "DEI",
            "icao_code": "FSSD",
            "label": "Denis Island Airport , Denis Island"
        },
        {
            "value": "DEL",
            "icao_code": "VIDP",
            "label": "Indira Gandhi International Airport , Delhi"
        },
        {
            "value": "DEM",
            "icao_code": "HADD",
            "label": "Dembidolo Airport , Dembidolo"
        },
        {
            "value": "DEN",
            "icao_code": "KDEN",
            "label": "Denver International Airport , Denver"
        },
        {
            "value": "DEP",
            "icao_code": "VEDZ",
            "label": "Daporijo Airport , Daporijo"
        },
        {
            "value": "DEQ",
            "icao_code": null,
            "label": "Deqing Moganshan Airport , Deqing"
        },
        {
            "value": "DER",
            "icao_code": "AYDE",
            "label": "Derim Airport , Derim"
        },
        {
            "value": "DES",
            "icao_code": "FSDR",
            "label": "Desroches Airport , Desroches Island"
        },
        {
            "value": "DET",
            "icao_code": "KDET",
            "label": "Coleman A. Young International Airport , Detroit"
        },
        {
            "value": "DEX",
            "icao_code": null,
            "label": "Nop Goliat Dekai Airport , Yahukimo"
        },
        {
            "value": "DEZ",
            "icao_code": "OSDZ",
            "label": "Deir ez-Zor Airport , Deir ez-Zor"
        },
        {
            "value": "DFI",
            "icao_code": "KDFI",
            "label": "Defiance Memorial Airport , Defiance"
        },
        {
            "value": "DFP",
            "icao_code": null,
            "label": "Drumduff Airport , Drumduff"
        },
        {
            "value": "DFW",
            "icao_code": "KDFW",
            "label": "Dallas/Fort Worth International Airport , Dallas"
        },
        {
            "value": "DGA",
            "icao_code": "MZPB",
            "label": "Dangriga Airport , Dangriga"
        },
        {
            "value": "DGC",
            "icao_code": null,
            "label": "Degeh Bur Airport , Degeh Bur"
        },
        {
            "value": "DGD",
            "icao_code": "YDGA",
            "label": "Dalgaranga Airport , Dalgaranga Gold Mine"
        },
        {
            "value": "DGE",
            "icao_code": "YMDG",
            "label": "Mudgee Airport , Mudgee"
        },
        {
            "value": "DGF",
            "icao_code": null,
            "label": "Douglas Lake Airport , Douglas Lake"
        },
        {
            "value": "DGH",
            "icao_code": "VEDO",
            "label": "Deoghar Airport , Deoghar"
        },
        {
            "value": "DGK",
            "icao_code": null,
            "label": "Dugong Beach Lodge Airstrip , Dugong Beach Lodge"
        },
        {
            "value": "DGL",
            "icao_code": "KDGL",
            "label": "Douglas Municipal Airport , Douglas"
        },
        {
            "value": "DGM",
            "icao_code": null,
            "label": "Dandugama Seaplane Base , Colombo"
        },
        {
            "value": "DGN",
            "icao_code": "KNDY",
            "label": "Naval Surface Warfare Center Dahlgren Division , Dahlgren"
        },
        {
            "value": "DGO",
            "icao_code": "MMDO",
            "label": "General Guadalupe Victoria International Airport , Durango"
        },
        {
            "value": "DGP",
            "icao_code": "EVDA",
            "label": "Daugavpils International Airport , Daugavpils"
        },
        {
            "value": "DGR",
            "icao_code": "NZDA",
            "label": "Dargaville Aerodrome , Dargaville"
        },
        {
            "value": "DGT",
            "icao_code": "RPVD",
            "label": "Sibulan Airport , Dumaguete"
        },
        {
            "value": "DGU",
            "icao_code": "DFOD",
            "label": "D\u00e9dougou Airport , D\u00e9dougou"
        },
        {
            "value": "DGW",
            "icao_code": "KDGW",
            "label": "Converse County Airport , Douglas"
        },
        {
            "value": "DHA",
            "icao_code": "OEDR",
            "label": "King Abdulaziz Air Base , Dhahran"
        },
        {
            "value": "DHB",
            "icao_code": null,
            "label": "Deer Harbor Sea Plane Base , Deer Harbor"
        },
        {
            "value": "DHD",
            "icao_code": "YDRH",
            "label": "Durham Downs Airport , Durham Downs"
        },
        {
            "value": "DHF",
            "icao_code": "OMAM",
            "label": "Al Dhafra Air Base , Abu Dhabi"
        },
        {
            "value": "DHI",
            "icao_code": "VNDH",
            "label": "Dhangadhi Airport , Dhangadhi"
        },
        {
            "value": "DHL",
            "icao_code": "ODAL",
            "label": "Dhala Airport , Dhala"
        },
        {
            "value": "DHM",
            "icao_code": "VIGG",
            "label": "Gaggal Airport , Kangra"
        },
        {
            "value": "DHN",
            "icao_code": "KDHN",
            "label": "Dothan Regional Airport , Dothan"
        },
        {
            "value": "DHR",
            "icao_code": "EHKD",
            "label": "De Kooy Airfield , Den Helder"
        },
        {
            "value": "DHT",
            "icao_code": "KDHT",
            "label": "Dalhart Municipal Airport , Dalhart"
        },
        {
            "value": "DIA",
            "icao_code": "OTBD",
            "label": "Doha International Airport , Doha"
        },
        {
            "value": "DIB",
            "icao_code": "VEMN",
            "label": "Dibrugarh Airport , Dibrugarh"
        },
        {
            "value": "DIE",
            "icao_code": "FMNA",
            "label": "Arrachart Airport , Antsiranana"
        },
        {
            "value": "DIG",
            "icao_code": "ZPDQ",
            "label": "Diqing Shangri-La Airport , Shangri-La"
        },
        {
            "value": "DIJ",
            "icao_code": "LFSD",
            "label": "Dijon Air Base , Dijon"
        },
        {
            "value": "DIK",
            "icao_code": "KDIK",
            "label": "Dickinson Theodore Roosevelt Regional Airport , Dickinson"
        },
        {
            "value": "DIL",
            "icao_code": "WPDL",
            "label": "Presidente Nicolau Lobato International Airport , Dili"
        },
        {
            "value": "DIM",
            "icao_code": "DIDK",
            "label": "Dimbokro Airport , Dimbokro"
        },
        {
            "value": "DIN",
            "icao_code": "VVDB",
            "label": "Dien Bien Phu Airport , Dien Bien Phu"
        },
        {
            "value": "DIO",
            "icao_code": "PPDM",
            "label": "Diomede Heliport , Diomede, Alaska"
        },
        {
            "value": "DIP",
            "icao_code": "DFED",
            "label": "Diapaga Airport , Diapaga"
        },
        {
            "value": "DIQ",
            "icao_code": "SNDV",
            "label": "Divin\u00f3polis Airport , Divin\u00f3polis"
        },
        {
            "value": "DIR",
            "icao_code": "HADR",
            "label": "Aba Tenna Dejazmach Yilma International Airport , Dire Dawa"
        },
        {
            "value": "DIS",
            "icao_code": "FCPL",
            "label": "Dolisie Airport , Dolisie"
        },
        {
            "value": "DIU",
            "icao_code": null,
            "label": "Diu Airport , Diu"
        },
        {
            "value": "DIV",
            "icao_code": "DIDV",
            "label": "Divo Airport , Divo"
        },
        {
            "value": "DIW",
            "icao_code": null,
            "label": "Mawella Lagoon Seaplane Base , Dickwella"
        },
        {
            "value": "DIY",
            "icao_code": "LTCC",
            "label": "Diyarbak\u0131r Airport , Diyarbak\u0131r"
        },
        {
            "value": "DJA",
            "icao_code": "DBBD",
            "label": "Djougou Airport , Djougou"
        },
        {
            "value": "DJB",
            "icao_code": "WIPA",
            "label": "Sultan Thaha Airport , Jambi"
        },
        {
            "value": "DJE",
            "icao_code": "DTTJ",
            "label": "Djerba\u2013Zarzis International Airport , Djerba"
        },
        {
            "value": "DJG",
            "icao_code": "DAAJ",
            "label": "Tiska Djanet Airport , Djanet"
        },
        {
            "value": "DJH",
            "icao_code": null,
            "label": "Jebel Ali Seaplane Base , Dubai"
        },
        {
            "value": "DJJ",
            "icao_code": "WAJJ",
            "label": "Sentani Airport , Jayapura"
        },
        {
            "value": "DJM",
            "icao_code": "FCBD",
            "label": "Djambala Airport , Djambala"
        },
        {
            "value": "DJN",
            "icao_code": null,
            "label": "Delta Junction Airport , Delta Junction"
        },
        {
            "value": "DJO",
            "icao_code": "DIDL",
            "label": "Daloa Airport , Daloa"
        },
        {
            "value": "DJU",
            "icao_code": "BIDV",
            "label": "Dj\u00fapivogur Airport , Dj\u00fapivogur"
        },
        {
            "value": "DKA",
            "icao_code": null,
            "label": "Katsina Airport , Katsina"
        },
        {
            "value": "DKI",
            "icao_code": "YDKI",
            "label": "Dunk Island Airport , Dunk Island"
        },
        {
            "value": "DKK",
            "icao_code": "KDKK",
            "label": "Chautauqua County/Dunkirk Airport , Dunkirk"
        },
        {
            "value": "DKR",
            "icao_code": "GOOY",
            "label": "L\u00e9opold S\u00e9dar Senghor International Airport , Dakar"
        },
        {
            "value": "DKS",
            "icao_code": "UODD",
            "label": "Dikson Airport , Dikson"
        },
        {
            "value": "DKV",
            "icao_code": "YDVR",
            "label": "Docker River Airport , Docker River"
        },
        {
            "value": "DLA",
            "icao_code": "FKKD",
            "label": "Douala International Airport , Douala"
        },
        {
            "value": "DLB",
            "icao_code": null,
            "label": "Dalbertis Airport , Dalbertis"
        },
        {
            "value": "DLC",
            "icao_code": "ZYTL",
            "label": "Dalian Zhoushuizi International Airport , Dalian"
        },
        {
            "value": "DLE",
            "icao_code": "LFGJ",
            "label": "Dole\u2013Jura Airport , Dole"
        },
        {
            "value": "DLF",
            "icao_code": "KDLF",
            "label": "Laughlin Air Force Base , Del Rio"
        },
        {
            "value": "DLG",
            "icao_code": "PADL",
            "label": "Dillingham Airport , Dillingham"
        },
        {
            "value": "DLH",
            "icao_code": "KDLH",
            "label": "Duluth International Airport , Duluth"
        },
        {
            "value": "DLI",
            "icao_code": "VVDL",
            "label": "Lien Khuong Airport , Da Lat"
        },
        {
            "value": "DLK",
            "icao_code": "YDLK",
            "label": "Dulkaninna Airport , Dulkaninna"
        },
        {
            "value": "DLL",
            "icao_code": "KDLC",
            "label": "Dillon County Airport , Dillon"
        },
        {
            "value": "DLM",
            "icao_code": "LTBS",
            "label": "Dalaman Airport , Dalaman"
        },
        {
            "value": "DLN",
            "icao_code": "KDLN",
            "label": "Dillon Airport , Dillon"
        },
        {
            "value": "DLS",
            "icao_code": "KDLS",
            "label": "Columbia Gorge Regional Airport , The Dalles"
        },
        {
            "value": "DLU",
            "icao_code": "ZPDL",
            "label": "Dali Airport , Dali"
        },
        {
            "value": "DLV",
            "icao_code": "YDLV",
            "label": "Delissaville Airport , Delissaville"
        },
        {
            "value": "DLY",
            "icao_code": "NVVD",
            "label": "Dillon's Bay Airport , Dillon's Bay"
        },
        {
            "value": "DLZ",
            "icao_code": "ZMDZ",
            "label": "Dalanzadgad Airport , Dalanzadgad"
        },
        {
            "value": "DMA",
            "icao_code": "KDMA",
            "label": "Davis\u2013Monthan Air Force Base , Tucson"
        },
        {
            "value": "DMB",
            "icao_code": "UADD",
            "label": "Taraz Airport , Taraz"
        },
        {
            "value": "DMD",
            "icao_code": "YDMG",
            "label": "Doomadgee Airport , Doomadgee"
        },
        {
            "value": "DME",
            "icao_code": "UUDD",
            "label": "Domodedovo International Airport , Moscow"
        },
        {
            "value": "DMK",
            "icao_code": "VTBD",
            "label": "Don Mueang International Airport , Bangkok"
        },
        {
            "value": "DMM",
            "icao_code": "OEDF",
            "label": "King Fahd International Airport , Dammam"
        },
        {
            "value": "DMN",
            "icao_code": "KDMN",
            "label": "Deming Municipal Airport , Deming"
        },
        {
            "value": "DMO",
            "icao_code": "KDMO",
            "label": "Sedalia Regional Airport , Sedalia"
        },
        {
            "value": "DMR",
            "icao_code": null,
            "label": "Dhamar Airport , Dhamar"
        },
        {
            "value": "DMT",
            "icao_code": "SWDM",
            "label": "Diamantino Airport , Diamantino"
        },
        {
            "value": "DMU",
            "icao_code": "VEMR",
            "label": "Dimapur Airport , Dimapur"
        },
        {
            "value": "DNA",
            "icao_code": "RODN",
            "label": "Kadena Air Base , Kadena"
        },
        {
            "value": "DNB",
            "icao_code": "YDBR",
            "label": "Dunbar Airport , Dunbar"
        },
        {
            "value": "DND",
            "icao_code": "EGPN",
            "label": "Dundee Airport , Dundee"
        },
        {
            "value": "DNF",
            "icao_code": null,
            "label": "Martuba Air Base , Derna"
        },
        {
            "value": "DNG",
            "icao_code": null,
            "label": "Doongan Airport , Doongan"
        },
        {
            "value": "DNH",
            "icao_code": "ZLDH",
            "label": "Dunhuang Airport , Dunhuang"
        },
        {
            "value": "DNI",
            "icao_code": "HSWD",
            "label": "Wad Medani Airport , Wad Madani"
        },
        {
            "value": "DNK",
            "icao_code": "UKDD",
            "label": "Dnipro International Airport , Dnipro"
        },
        {
            "value": "DNL",
            "icao_code": "KDNL",
            "label": "Daniel Field , Augusta"
        },
        {
            "value": "DNM",
            "icao_code": null,
            "label": "Denham Airport , Denham"
        },
        {
            "value": "DNN",
            "icao_code": "KDNN",
            "label": "Dalton Municipal Airport , Dalton"
        },
        {
            "value": "DNO",
            "icao_code": "SWDN",
            "label": "Dian\u00f3polis Airport , Dian\u00f3polis"
        },
        {
            "value": "DNP",
            "icao_code": "VNDG",
            "label": "Tribhuvannagar Airport , Tribhuwannagar"
        },
        {
            "value": "DNQ",
            "icao_code": "YDLQ",
            "label": "Deniliquin Airport , Deniliquin"
        },
        {
            "value": "DNR",
            "icao_code": "LFRD",
            "label": "Dinard\u2013Pleurtuit\u2013Saint-Malo Airport , Dinard"
        },
        {
            "value": "DNS",
            "icao_code": "KDNS",
            "label": "Denison Municipal Airport , Denison"
        },
        {
            "value": "DNU",
            "icao_code": "AYDN",
            "label": "Dinangat Airport , Dinangat"
        },
        {
            "value": "DNV",
            "icao_code": "KDNV",
            "label": "Vermilion Regional Airport , Danville"
        },
        {
            "value": "DNX",
            "icao_code": "HSGG",
            "label": "Galegu Airport , Dinder"
        },
        {
            "value": "DNZ",
            "icao_code": "LTAY",
            "label": "Denizli \u00c7ardak Airport , Denizli"
        },
        {
            "value": "DOA",
            "icao_code": null,
            "label": "Doany Airport , Doany"
        },
        {
            "value": "DOB",
            "icao_code": "WAPD",
            "label": "Dobo Airport , Dobo"
        },
        {
            "value": "DOC",
            "icao_code": null,
            "label": "Dornoch Airfield , Dornoch"
        },
        {
            "value": "DOD",
            "icao_code": "HTDO",
            "label": "Dodoma Airport , Dodoma"
        },
        {
            "value": "DOE",
            "icao_code": null,
            "label": "Djumu Airstrip , Djumu"
        },
        {
            "value": "DOG",
            "icao_code": "HSDN",
            "label": "Dongola Airport , Dongola"
        },
        {
            "value": "DOH",
            "icao_code": "OTHH",
            "label": "Hamad International Airport , Doha"
        },
        {
            "value": "DOI",
            "icao_code": "AYDO",
            "label": "Doini Island Airport , Doini Island"
        },
        {
            "value": "DOK",
            "icao_code": "UKCC",
            "label": "Donetsk International Airport , Donetsk"
        },
        {
            "value": "DOL",
            "icao_code": "LFRG",
            "label": "Deauville \u2013 Saint-Gatien Airport , Deauville"
        },
        {
            "value": "DOM",
            "icao_code": "TDPD",
            "label": "Douglas\u2013Charles Airport , St Andrew Parish"
        },
        {
            "value": "DON",
            "icao_code": null,
            "label": "Dos Lagunas Airport , Dos Lagunas"
        },
        {
            "value": "DOO",
            "icao_code": "AYDR",
            "label": "Dorobisoro Airstrip , Dorobisoro"
        },
        {
            "value": "DOP",
            "icao_code": "VNDP",
            "label": "Dolpa Airport , Dolpa"
        },
        {
            "value": "DOR",
            "icao_code": "DFEE",
            "label": "Dori Airport , Dori"
        },
        {
            "value": "DOS",
            "icao_code": null,
            "label": "Dios Airport , Dios"
        },
        {
            "value": "DOU",
            "icao_code": "SSDO",
            "label": "Dourados Airport , Dourados"
        },
        {
            "value": "DOV",
            "icao_code": "KDOV",
            "label": "Dover Air Force Base , Dover"
        },
        {
            "value": "DOX",
            "icao_code": "YDRA",
            "label": "Dongara Airport , Dongara"
        },
        {
            "value": "DOY",
            "icao_code": "ZSDY",
            "label": "Dongying Shengli Airport , Dongying"
        },
        {
            "value": "DPA",
            "icao_code": "KDPA",
            "label": "DuPage Airport , West Chicago"
        },
        {
            "value": "DPB",
            "icao_code": "SCBI",
            "label": "Pampa Guanaco Airport , Camer\u00f3n"
        },
        {
            "value": "DPE",
            "icao_code": "LFAB",
            "label": "Dieppe - Saint-Aubin Airport , Dieppe"
        },
        {
            "value": "DPG",
            "icao_code": "KDPG",
            "label": "Michael Army Airfield , Dugway"
        },
        {
            "value": "DPL",
            "icao_code": "RPMG",
            "label": "Dipolog Airport , Dipolog"
        },
        {
            "value": "DPO",
            "icao_code": "YDPO",
            "label": "Devonport Airport , Devonport"
        },
        {
            "value": "DPS",
            "icao_code": "WADD",
            "label": "Ngurah Rai International Airport , Denpasar"
        },
        {
            "value": "DPT",
            "icao_code": null,
            "label": "Deputatsky Airport , Deputatsky"
        },
        {
            "value": "DPU",
            "icao_code": null,
            "label": "Dumpu Airport , Dumpu"
        },
        {
            "value": "DQA",
            "icao_code": "ZYDQ",
            "label": "Daqing Sartu Airport , Daqing"
        },
        {
            "value": "DQM",
            "icao_code": null,
            "label": "Duqm International Airport , Duqm"
        },
        {
            "value": "DRA",
            "icao_code": "KDRA",
            "label": "Desert Rock Airport , Mercury"
        },
        {
            "value": "DRB",
            "icao_code": "YDBY",
            "label": "Derby Airport , Derby"
        },
        {
            "value": "DRC",
            "icao_code": null,
            "label": "Dirico Airport , Dirico"
        },
        {
            "value": "DRD",
            "icao_code": "YDOR",
            "label": "Dorunda Airport , Dorunda"
        },
        {
            "value": "DRE",
            "icao_code": "KDRM",
            "label": "Drummond Island Airport , Drummond Island"
        },
        {
            "value": "DRF",
            "icao_code": null,
            "label": "Drift River Airport , Drift River"
        },
        {
            "value": "DRG",
            "icao_code": "PADE",
            "label": "Deering Airport , Deering"
        },
        {
            "value": "DRH",
            "icao_code": null,
            "label": "Dabra Airport , Dabra"
        },
        {
            "value": "DRI",
            "icao_code": "KDRI",
            "label": "Beauregard Regional Airport , DeRidder"
        },
        {
            "value": "DRJ",
            "icao_code": "SMDA",
            "label": "Drietabbetje Airstrip , Drietabbetje"
        },
        {
            "value": "DRK",
            "icao_code": "MRDK",
            "label": "Drake Bay Airport , Drake Bay"
        },
        {
            "value": "DRN",
            "icao_code": "YDBI",
            "label": "Dirranbandi Airport , Dirranbandi"
        },
        {
            "value": "DRO",
            "icao_code": "KDRO",
            "label": "Durango\u2013La Plata County Airport , Durango"
        },
        {
            "value": "DRP",
            "icao_code": "RPLK",
            "label": "Bicol International Airport , Daraga, Albay"
        },
        {
            "value": "DRR",
            "icao_code": "YDRI",
            "label": "Durrie Airport , Durrie"
        },
        {
            "value": "DRS",
            "icao_code": "EDDC",
            "label": "Dresden Airport , Dresden"
        },
        {
            "value": "DRT",
            "icao_code": "KDRT",
            "label": "Del Rio International Airport , Del Rio"
        },
        {
            "value": "DRU",
            "icao_code": null,
            "label": "Drummond Airport , Drummond"
        },
        {
            "value": "DRV",
            "icao_code": "VRMD",
            "label": "Dharavandhoo Airport , Dharavandhoo Island"
        },
        {
            "value": "DRW",
            "icao_code": "YPDN",
            "label": "Darwin International Airport , Darwin"
        },
        {
            "value": "DRY",
            "icao_code": "YDRD",
            "label": "Drysdale River Airport , Drysdale River"
        },
        {
            "value": "DSA",
            "icao_code": "EGCN",
            "label": "Robin Hood Airport Doncaster Sheffield , Doncaster"
        },
        {
            "value": "DSC",
            "icao_code": "FKKS",
            "label": "Dschang Airport , Dschang"
        },
        {
            "value": "DSD",
            "icao_code": "TFFA",
            "label": "La D\u00e9sirade Airport , La D\u00e9sirade"
        },
        {
            "value": "DSE",
            "icao_code": "HADC",
            "label": "Combolcha Airport , Dessie"
        },
        {
            "value": "DSG",
            "icao_code": null,
            "label": "Dilasag Airport , Dilasag"
        },
        {
            "value": "DSI",
            "icao_code": "KDTS",
            "label": "Destin Executive Airport , Destin"
        },
        {
            "value": "DSK",
            "icao_code": "OPDI",
            "label": "Dera Ismail Khan Airport , Dera Ismail Khan"
        },
        {
            "value": "DSM",
            "icao_code": "KDSM",
            "label": "Des Moines International Airport , Des Moines"
        },
        {
            "value": "DSN",
            "icao_code": "ZBDS",
            "label": "Ordos Ejin Horo Airport , Ordos"
        },
        {
            "value": "DSO",
            "icao_code": "ZKSD",
            "label": "Sondok Airport , Sondok"
        },
        {
            "value": "DSS",
            "icao_code": "GOBD",
            "label": "Blaise Diagne International Airport , Dakar"
        },
        {
            "value": "DSV",
            "icao_code": "KDSV",
            "label": "Dansville Municipal Airport , Dansville"
        },
        {
            "value": "DSX",
            "icao_code": "RCLM",
            "label": "Dongsha Island Airport , Pratas (Tungsha/Dongsha) Island"
        },
        {
            "value": "DTA",
            "icao_code": "KDTA",
            "label": "Delta Municipal Airport , Delta"
        },
        {
            "value": "DTB",
            "icao_code": "WIMN",
            "label": "Silangit Airport , Siborong-Borong"
        },
        {
            "value": "DTD",
            "icao_code": "WALJ",
            "label": "Datadawai Airport , Datadawai"
        },
        {
            "value": "DTE",
            "icao_code": "RPUD",
            "label": "Bagasbas Airport , Daet"
        },
        {
            "value": "DTH",
            "icao_code": null,
            "label": "Furnace Creek Airport , Death Valley"
        },
        {
            "value": "DTI",
            "icao_code": "SNDT",
            "label": "Diamantina Airport , Diamantina"
        },
        {
            "value": "DTL",
            "icao_code": "KDTL",
            "label": "Detroit Lakes Airport , Detroit Lakes"
        },
        {
            "value": "DTM",
            "icao_code": "EDLW",
            "label": "Dortmund Airport , Dortmund"
        },
        {
            "value": "DTN",
            "icao_code": "KDTN",
            "label": "Shreveport Downtown Airport , Shreveport"
        },
        {
            "value": "DTR",
            "icao_code": null,
            "label": "Decatur Shores Airport , Decatur Island"
        },
        {
            "value": "DTT",
            "icao_code": null,
            "label": "metropolitan area , Detroit"
        },
        {
            "value": "DTU",
            "icao_code": null,
            "label": "Wudalianchi Airport , Wudalianchi"
        },
        {
            "value": "DTW",
            "icao_code": "KDTW",
            "label": "Detroit Metropolitan Wayne County Airport , Detroit"
        },
        {
            "value": "DUA",
            "icao_code": "KDUA",
            "label": "Durant Regional Airport\u2013Eaker Field , Durant"
        },
        {
            "value": "DUB",
            "icao_code": "EIDW",
            "label": "Dublin Airport , Dublin"
        },
        {
            "value": "DUC",
            "icao_code": "KDUC",
            "label": "Halliburton Field , Duncan"
        },
        {
            "value": "DUD",
            "icao_code": "NZDN",
            "label": "Dunedin International Airport , Dunedin"
        },
        {
            "value": "DUE",
            "icao_code": "FNDU",
            "label": "Dundo Airport , Dundo"
        },
        {
            "value": "DUF",
            "icao_code": null,
            "label": "Pine Island Airport , Corolla"
        },
        {
            "value": "DUG",
            "icao_code": "KDUG",
            "label": "Bisbee Douglas International Airport , Bisbee"
        },
        {
            "value": "DUJ",
            "icao_code": "KDUJ",
            "label": "DuBois Regional Airport , DuBois"
        },
        {
            "value": "DUK",
            "icao_code": "FADK",
            "label": "Dukuduku Airport , Mtubatuba"
        },
        {
            "value": "DUM",
            "icao_code": "WIBD",
            "label": "Pinang Kampai Airport , Dumai"
        },
        {
            "value": "DUQ",
            "icao_code": null,
            "label": "Duncan Airport , Duncan"
        },
        {
            "value": "DUR",
            "icao_code": "FALE",
            "label": "King Shaka International Airport , Durban"
        },
        {
            "value": "DUS",
            "icao_code": "EDDL",
            "label": "D\u00fcsseldorf Airport , D\u00fcsseldorf"
        },
        {
            "value": "DUT",
            "icao_code": "PADU",
            "label": "Unalaska Airport , Unalaska"
        },
        {
            "value": "DVD",
            "icao_code": null,
            "label": "Andavadoaka Airport , Andavadoaka"
        },
        {
            "value": "DVK",
            "icao_code": null,
            "label": "Diavik Airport , Diavik Diamond Mine"
        },
        {
            "value": "DVL",
            "icao_code": "KDVL",
            "label": "Devils Lake Regional Airport , Devils Lake"
        },
        {
            "value": "DVN",
            "icao_code": "KDVN",
            "label": "Davenport Municipal Airport , Davenport"
        },
        {
            "value": "DVO",
            "icao_code": "RPMD",
            "label": "Francisco Bangoy International Airport , Davao City"
        },
        {
            "value": "DVP",
            "icao_code": "YDPD",
            "label": "Davenport Downs Airport , Davenport Downs"
        },
        {
            "value": "DVR",
            "icao_code": null,
            "label": "Daly River Airport , Daly River"
        },
        {
            "value": "DVT",
            "icao_code": "KDVT",
            "label": "Phoenix Deer Valley Airport , Phoenix"
        },
        {
            "value": "DWA",
            "icao_code": "FWDW",
            "label": "Dwanga Airport , Dwangwa"
        },
        {
            "value": "DWB",
            "icao_code": "FMNO",
            "label": "Soalala Airport , Soalala"
        },
        {
            "value": "DWC",
            "icao_code": "OMDW",
            "label": "Al Maktoum International Airport , Dubai"
        },
        {
            "value": "DWD",
            "icao_code": "OEDW",
            "label": "Dawadmi Domestic Airport , Dawadmi"
        },
        {
            "value": "DWH",
            "icao_code": "KDWH",
            "label": "David Wayne Hooks Memorial Airport , Houston"
        },
        {
            "value": "DWO",
            "icao_code": null,
            "label": "Diyawanna Oya Seaplane Base , Sri Jayawardenepura Kotte"
        },
        {
            "value": "DWR",
            "icao_code": "OADY",
            "label": "Dwyer Airport , Camp Dwyer"
        },
        {
            "value": "DXB",
            "icao_code": "OMDB",
            "label": "Dubai International Airport , Dubai"
        },
        {
            "value": "DXD",
            "icao_code": "YDIX",
            "label": "Dixie Airport , Dixie"
        },
        {
            "value": "DXE",
            "icao_code": "KMBO",
            "label": "Bruce Campbell Field , Madison"
        },
        {
            "value": "DXR",
            "icao_code": "KDXR",
            "label": "Danbury Municipal Airport , Danbury"
        },
        {
            "value": "DYA",
            "icao_code": "YDYS",
            "label": "Dysart Airport , Dysart"
        },
        {
            "value": "DYG",
            "icao_code": "ZGDY",
            "label": "Zhangjiajie Hehua Airport , Zhangjiajie"
        },
        {
            "value": "DYL",
            "icao_code": "KDYL",
            "label": "Doylestown Airport , Doylestown"
        },
        {
            "value": "DYM",
            "icao_code": null,
            "label": "Diamantina Lakes Airport , Diamantina Lakes"
        },
        {
            "value": "DYR",
            "icao_code": "UHMA",
            "label": "Ugolny Airport , Anadyr"
        },
        {
            "value": "DYS",
            "icao_code": "KDYS",
            "label": "Dyess Air Force Base , Abilene"
        },
        {
            "value": "DYU",
            "icao_code": "UTDD",
            "label": "Dushanbe International Airport , Dushanbe"
        },
        {
            "value": "DYW",
            "icao_code": null,
            "label": "Daly Waters Airport , Daly Waters"
        },
        {
            "value": "DZA",
            "icao_code": "FMCZ",
            "label": "Dzaoudzi\u2013Pamandzi International Airport , Dzaoudzi"
        },
        {
            "value": "DZH",
            "icao_code": "ZUDA",
            "label": "Dazhou Jinya Airport , Dazhou"
        },
        {
            "value": "DZI",
            "icao_code": null,
            "label": "Codazzi Airport , Codazzi"
        },
        {
            "value": "DZN",
            "icao_code": "UAKD",
            "label": "Zhezkazgan Airport , Zhezkazgan"
        },
        {
            "value": "DZO",
            "icao_code": "SUDU",
            "label": "Santa Bernardina International Airport , Durazno"
        },
        {
            "value": "DZU",
            "icao_code": "ZUDZ",
            "label": "Dazu Air Base , Dazu"
        },
        {
            "value": "EAA",
            "icao_code": "PAEG",
            "label": "Eagle Airport , Eagle"
        },
        {
            "value": "EAB",
            "icao_code": "ODAS",
            "label": "Abbs Airport , Abbs"
        },
        {
            "value": "EAE",
            "icao_code": "NVSE",
            "label": "Siwo Airport , Emae Island"
        },
        {
            "value": "EAL",
            "icao_code": null,
            "label": "Elenak Airport , Mejato Island, Kwajalein Atoll"
        },
        {
            "value": "EAM",
            "icao_code": "OENG",
            "label": "Najran Domestic Airport , Najran"
        },
        {
            "value": "EAN",
            "icao_code": "KEAN",
            "label": "Phifer Airfield , Wheatland"
        },
        {
            "value": "EAP",
            "icao_code": null,
            "label": "metropolitan area , Basel"
        },
        {
            "value": "EAR",
            "icao_code": "KEAR",
            "label": "Kearney Regional Airport , Kearney"
        },
        {
            "value": "EAS",
            "icao_code": "LESO",
            "label": "San Sebasti\u00e1n Airport , San Sebasti\u00e1n"
        },
        {
            "value": "EAT",
            "icao_code": "KEAT",
            "label": "Pangborn Memorial Airport , Wenatchee"
        },
        {
            "value": "EAU",
            "icao_code": "KEAU",
            "label": "Chippewa Valley Regional Airport , Eau Claire"
        },
        {
            "value": "EBA",
            "icao_code": "LIRJ",
            "label": "Marina di Campo Airport , Elba Island"
        },
        {
            "value": "EBB",
            "icao_code": "HUEN",
            "label": "Entebbe International Airport , Entebbe"
        },
        {
            "value": "EBD",
            "icao_code": "HSOB",
            "label": "El Obeid Airport , El Obeid"
        },
        {
            "value": "EBG",
            "icao_code": "SKEB",
            "label": "El Bagre Airport , El Bagre"
        },
        {
            "value": "EBH",
            "icao_code": "DAOY",
            "label": "El Bayadh Airport , El Bayadh"
        },
        {
            "value": "EBJ",
            "icao_code": "EKEB",
            "label": "Esbjerg Airport , Esbjerg"
        },
        {
            "value": "EBL",
            "icao_code": "ORER",
            "label": "Erbil International Airport , Erbil"
        },
        {
            "value": "EBM",
            "icao_code": "DTTR",
            "label": "El Borma Airport , El Borma"
        },
        {
            "value": "EBN",
            "icao_code": null,
            "label": "Ebadon Airstrip , Ebadon Island, Kwajalein Atoll"
        },
        {
            "value": "EBO",
            "icao_code": null,
            "label": "Ebon Airport , Ebon Atoll"
        },
        {
            "value": "EBS",
            "icao_code": "KEBS",
            "label": "Webster City Municipal Airport , Webster City"
        },
        {
            "value": "EBU",
            "icao_code": "LFMH",
            "label": "Saint-\u00c9tienne\u2013Bouth\u00e9on Airport , Saint-\u00c9tienne"
        },
        {
            "value": "EBW",
            "icao_code": "FKKW",
            "label": "Ebolowa Airport , Ebolowa"
        },
        {
            "value": "ECA",
            "icao_code": null,
            "label": "Iosco County Airport , East Tawas"
        },
        {
            "value": "ECG",
            "icao_code": "KECG",
            "label": "Elizabeth City Regional Airport , Elizabeth City"
        },
        {
            "value": "ECH",
            "icao_code": "YECH",
            "label": "Echuca Airport , Echuca"
        },
        {
            "value": "ECI",
            "icao_code": "MNLP",
            "label": "Costa Esmeralda Airport , Rivas"
        },
        {
            "value": "ECN",
            "icao_code": "LCEN",
            "label": "Ercan International Airport , Nicosia (Lefko\u015fa)"
        },
        {
            "value": "ECO",
            "icao_code": null,
            "label": "El Encanto Airport , El Encanto"
        },
        {
            "value": "ECP",
            "icao_code": "KECP",
            "label": "Northwest Florida Beaches International Airport , Panama City"
        },
        {
            "value": "ECR",
            "icao_code": "SKEH",
            "label": "El Charco Airport , El Charco"
        },
        {
            "value": "ECS",
            "icao_code": "KECS",
            "label": "Mondell Field , Newcastle"
        },
        {
            "value": "EDA",
            "icao_code": null,
            "label": "Edna Bay Seaplane Base , Edna Bay"
        },
        {
            "value": "EDB",
            "icao_code": "HSDB",
            "label": "El Debba Airport , El Debba"
        },
        {
            "value": "EDD",
            "icao_code": null,
            "label": "Erldunda Airport , Erldunda"
        },
        {
            "value": "EDE",
            "icao_code": "KEDE",
            "label": "Northeastern Regional Airport , Edenton"
        },
        {
            "value": "EDF",
            "icao_code": "PAED",
            "label": "Elmendorf Air Force Base , Anchorage"
        },
        {
            "value": "EDI",
            "icao_code": "EGPH",
            "label": "Edinburgh Airport , Edinburgh"
        },
        {
            "value": "EDK",
            "icao_code": "KEQA",
            "label": "Captain Jack Thomas/El Dorado Airport , El Dorado"
        },
        {
            "value": "EDL",
            "icao_code": "HKEL",
            "label": "Eldoret International Airport , Eldoret"
        },
        {
            "value": "EDM",
            "icao_code": "LFRI",
            "label": "La Roche-sur-Yon Aerodrome , La Roche-sur-Yon"
        },
        {
            "value": "EDO",
            "icao_code": "LTFD",
            "label": "Bal\u0131kesir Koca Seyit Airport , Edremit"
        },
        {
            "value": "EDQ",
            "icao_code": null,
            "label": "Erandique Airport , Erandique"
        },
        {
            "value": "EDR",
            "icao_code": "YPMP",
            "label": "Edward River Airport , Pormpuraaw"
        },
        {
            "value": "EDW",
            "icao_code": "KEDW",
            "label": "Edwards Air Force Base , Edwards"
        },
        {
            "value": "EEA",
            "icao_code": "SNCP",
            "label": "Planalto Serrano Regional Airport , Correia Pinto"
        },
        {
            "value": "EED",
            "icao_code": "KEED",
            "label": "Needles Airport , Needles"
        },
        {
            "value": "EEK",
            "icao_code": "PAEE",
            "label": "Eek Airport , Eek"
        },
        {
            "value": "EEN",
            "icao_code": "KEEN",
            "label": "Dillant\u2013Hopkins Airport , Keene"
        },
        {
            "value": "EFD",
            "icao_code": "KEFD",
            "label": "Ellington Field , Houston"
        },
        {
            "value": "EFG",
            "icao_code": "AYEF",
            "label": "Efogi Airport , Efogi"
        },
        {
            "value": "EFK",
            "icao_code": "KEFK",
            "label": "Newport State Airport , Newport"
        },
        {
            "value": "EFL",
            "icao_code": "LGKF",
            "label": "Cephalonia International Airport , Cephalonia"
        },
        {
            "value": "EFW",
            "icao_code": "KEFW",
            "label": "Jefferson Municipal Airport , Jefferson"
        },
        {
            "value": "EGA",
            "icao_code": "AYEN",
            "label": "Engati Airport , Engati"
        },
        {
            "value": "EGC",
            "icao_code": "LFBE",
            "label": "Bergerac Dordogne P\u00e9rigord Airport , Bergerac"
        },
        {
            "value": "EGE",
            "icao_code": "KEGE",
            "label": "Eagle County Regional Airport , Eagle"
        },
        {
            "value": "EGI",
            "icao_code": "KEGI",
            "label": "Duke Field , Crestview"
        },
        {
            "value": "EGL",
            "icao_code": "HANG",
            "label": "Neghelle Airport , Neghelle"
        },
        {
            "value": "EGM",
            "icao_code": "AGGS",
            "label": "Seghe Airport , Seghe"
        },
        {
            "value": "EGN",
            "icao_code": "HSGN",
            "label": "Geneina Airport , Geneina"
        },
        {
            "value": "EGO",
            "icao_code": "UUOB",
            "label": "Belgorod International Airport , Belgorod"
        },
        {
            "value": "EGP",
            "icao_code": null,
            "label": "Maverick County Memorial International Airport , Eagle Pass"
        },
        {
            "value": "EGS",
            "icao_code": "BIEG",
            "label": "Egilssta\u00f0ir Airport , Egilssta\u00f0ir"
        },
        {
            "value": "EGV",
            "icao_code": "KEGV",
            "label": "Eagle River Union Airport , Eagle River"
        },
        {
            "value": "EGX",
            "icao_code": "PAII",
            "label": "Egegik Airport , Egegik"
        },
        {
            "value": "EHL",
            "icao_code": "SAVB",
            "label": "El Bols\u00f3n Airport , El Bols\u00f3n"
        },
        {
            "value": "EHM",
            "icao_code": "PAEH",
            "label": "Cape Newenham LRRS Airport , Cape Newenham"
        },
        {
            "value": "EHU",
            "icao_code": "ZHEC",
            "label": "Ezhou Huahu Airport , Echeng District"
        },
        {
            "value": "EIA",
            "icao_code": null,
            "label": "Popondetta Airport , Eia"
        },
        {
            "value": "EIB",
            "icao_code": "EDGE",
            "label": "Eisenach-Kindel Airport , Eisenach"
        },
        {
            "value": "EIE",
            "icao_code": "UNII",
            "label": "Yeniseysk Airport , Yeniseysk"
        },
        {
            "value": "EIH",
            "icao_code": null,
            "label": "Einasleigh Airport , Einasleigh"
        },
        {
            "value": "EIK",
            "icao_code": "URKE",
            "label": "Yeysk Airport , Yeysk"
        },
        {
            "value": "EIL",
            "icao_code": "PAEI",
            "label": "Eielson Air Force Base , Fairbanks"
        },
        {
            "value": "EIN",
            "icao_code": "EHEH",
            "label": "Eindhoven Airport , Eindhoven"
        },
        {
            "value": "EIS",
            "icao_code": "TUPJ",
            "label": "Terrance B. Lettsome International Airport , Tortola"
        },
        {
            "value": "EIY",
            "icao_code": "LLEY",
            "label": "Ein Yahav Airfield , Ein Yahav"
        },
        {
            "value": "EJA",
            "icao_code": "SKEJ",
            "label": "Yarigu\u00edes Airport , Barrancabermeja"
        },
        {
            "value": "EJH",
            "icao_code": "OEWJ",
            "label": "Al Wajh Domestic Airport , Al Wajh"
        },
        {
            "value": "EJN",
            "icao_code": null,
            "label": "Ejin Banner Taolai Airport , Ejin Banner"
        },
        {
            "value": "EJT",
            "icao_code": null,
            "label": "Enejit Airport , Enejit"
        },
        {
            "value": "EKA",
            "icao_code": "KEKA",
            "label": "Murray Field , Eureka"
        },
        {
            "value": "EKB",
            "icao_code": "UASB",
            "label": "Ekibastuz Airport , Ekibastuz"
        },
        {
            "value": "EKD",
            "icao_code": null,
            "label": "Elkedra Airport , Elkedra"
        },
        {
            "value": "EKE",
            "icao_code": null,
            "label": "Ekereku Airport , Ekereku"
        },
        {
            "value": "EKI",
            "icao_code": "KEKM",
            "label": "Elkhart Municipal Airport , Elkhart"
        },
        {
            "value": "EKN",
            "icao_code": "KEKN",
            "label": "Elkins-Randolph County Airport , Elkins"
        },
        {
            "value": "EKO",
            "icao_code": "KEKO",
            "label": "Elko Regional Airport , Elko"
        },
        {
            "value": "EKS",
            "icao_code": "UHSK",
            "label": "Shakhtyorsk Airport , Shakhtyorsk"
        },
        {
            "value": "EKT",
            "icao_code": "ESSU",
            "label": "Eskilstuna Airport , Eskilstuna"
        },
        {
            "value": "EKX",
            "icao_code": "KEKX",
            "label": "Elizabethtown Regional Airport , Elizabethtown"
        },
        {
            "value": "ELA",
            "icao_code": "KELA",
            "label": "Eagle Lake Airport , Eagle Lake"
        },
        {
            "value": "ELB",
            "icao_code": "SKBC",
            "label": "Las Flores Airport , El Banco"
        },
        {
            "value": "ELC",
            "icao_code": "YELD",
            "label": "Elcho Island Airport , Elcho Island"
        },
        {
            "value": "ELD",
            "icao_code": "KELD",
            "label": "South Arkansas Regional Airport at Goodwin Field , El Dorado"
        },
        {
            "value": "ELE",
            "icao_code": null,
            "label": "El Real Airport , El Real"
        },
        {
            "value": "ELF",
            "icao_code": "HSFS",
            "label": "El Fasher Airport , El Fasher"
        },
        {
            "value": "ELG",
            "icao_code": "DAUE",
            "label": "El Golea Airport , El Gol\u00e9a"
        },
        {
            "value": "ELH",
            "icao_code": "MYEH",
            "label": "North Eleuthera Airport , North Eleuthera"
        },
        {
            "value": "ELI",
            "icao_code": "PFEL",
            "label": "Elim Airport , Elim"
        },
        {
            "value": "ELJ",
            "icao_code": "SVWX",
            "label": "El Recreo Airport , El Recreo"
        },
        {
            "value": "ELK",
            "icao_code": "KELK",
            "label": "Elk City Regional Business Airport , Elk City"
        },
        {
            "value": "ELL",
            "icao_code": "FAER",
            "label": "Ellisras Airport , Ellisras"
        },
        {
            "value": "ELM",
            "icao_code": "KELM",
            "label": "Elmira/Corning Regional Airport , Elmira"
        },
        {
            "value": "ELN",
            "icao_code": "KELN",
            "label": "Bowers Field , Ellensburg"
        },
        {
            "value": "ELO",
            "icao_code": "SATD",
            "label": "Eldorado Airport , Eldorado"
        },
        {
            "value": "ELP",
            "icao_code": "KELP",
            "label": "El Paso International Airport , El Paso"
        },
        {
            "value": "ELQ",
            "icao_code": "OEGS",
            "label": "Prince Nayef bin Abdulaziz Regional Airport , Buraidah"
        },
        {
            "value": "ELR",
            "icao_code": null,
            "label": "Elelim Airport , Elelim"
        },
        {
            "value": "ELS",
            "icao_code": "FAEL",
            "label": "East London Airport , East London"
        },
        {
            "value": "ELT",
            "icao_code": "HETR",
            "label": "El Tor Airport , El Tor"
        },
        {
            "value": "ELU",
            "icao_code": "DAUO",
            "label": "Guemar Airport , El Oued"
        },
        {
            "value": "ELV",
            "icao_code": "PAEL",
            "label": "Elfin Cove Seaplane Base , Elfin Cove"
        },
        {
            "value": "ELW",
            "icao_code": null,
            "label": "Ellamar Seaplane Base , Ellamar"
        },
        {
            "value": "ELX",
            "icao_code": null,
            "label": "El Tigre Airport , El Tigre"
        },
        {
            "value": "ELY",
            "icao_code": "KELY",
            "label": "Ely Airport , Ely"
        },
        {
            "value": "ELZ",
            "icao_code": "KELZ",
            "label": "Wellsville Municipal Airport , Wellsville"
        },
        {
            "value": "EMA",
            "icao_code": "EGNX",
            "label": "East Midlands Airport , Nottingham"
        },
        {
            "value": "EMD",
            "icao_code": "YEML",
            "label": "Emerald Airport , Emerald"
        },
        {
            "value": "EME",
            "icao_code": "EDWE",
            "label": "Emden Airport , Emden"
        },
        {
            "value": "EMG",
            "icao_code": "FAEM",
            "label": "Empangeni Airport , Empangeni"
        },
        {
            "value": "EMI",
            "icao_code": "AYEE",
            "label": "Emirau Airport , Emirau Island"
        },
        {
            "value": "EMK",
            "icao_code": "PAEM",
            "label": "Emmonak Airport , Emmonak"
        },
        {
            "value": "EML",
            "icao_code": "LSME",
            "label": "Emmen Air Base , Emmen"
        },
        {
            "value": "EMM",
            "icao_code": "KEMM",
            "label": "Kemmerer Municipal Airport , Kemmerer"
        },
        {
            "value": "EMN",
            "icao_code": "GQNI",
            "label": "N\u00e9ma Airport , N\u00e9ma"
        },
        {
            "value": "EMO",
            "icao_code": "AYEO",
            "label": "Emo Airport , Emo"
        },
        {
            "value": "EMP",
            "icao_code": "KEMP",
            "label": "Emporia Municipal Airport , Emporia"
        },
        {
            "value": "EMS",
            "icao_code": "AYEB",
            "label": "Embessa Airport , Embessa"
        },
        {
            "value": "EMT",
            "icao_code": "KEMT",
            "label": "San Gabriel Valley Airport , El Monte"
        },
        {
            "value": "EMX",
            "icao_code": "SAVD",
            "label": "El Mait\u00e9n Airport , El Mait\u00e9n"
        },
        {
            "value": "EMY",
            "icao_code": "HEMN",
            "label": "El Minya Airport , El Minya"
        },
        {
            "value": "ENA",
            "icao_code": "PAEN",
            "label": "Kenai Municipal Airport , Kenai"
        },
        {
            "value": "ENB",
            "icao_code": "YEEB",
            "label": "Eneabba Airport , Eneabba"
        },
        {
            "value": "ENC",
            "icao_code": "LFSN",
            "label": "Nancy-Essey Airport , Nancy"
        },
        {
            "value": "END",
            "icao_code": "KEND",
            "label": "Vance Air Force Base , Enid"
        },
        {
            "value": "ENE",
            "icao_code": "WATE",
            "label": "H. Hasan Aroeboesman Airport , Ende"
        },
        {
            "value": "ENF",
            "icao_code": "EFET",
            "label": "Enonteki\u00f6 Airport , Enonteki\u00f6"
        },
        {
            "value": "ENH",
            "icao_code": "ZHES",
            "label": "Enshi Xujiaping Airport , Enshi"
        },
        {
            "value": "ENI",
            "icao_code": "RPEN",
            "label": "El Nido Airport , El Nido"
        },
        {
            "value": "ENJ",
            "icao_code": null,
            "label": "El Naranjo Airport , El Naranjo"
        },
        {
            "value": "ENK",
            "icao_code": "EGAB",
            "label": "Enniskillen/St Angelo Airport , Enniskillen"
        },
        {
            "value": "ENL",
            "icao_code": "KENL",
            "label": "Centralia Municipal Airport , Centralia"
        },
        {
            "value": "ENN",
            "icao_code": "PANN",
            "label": "Nenana Municipal Airport , Nenana"
        },
        {
            "value": "ENO",
            "icao_code": "SGEN",
            "label": "Teniente Amin Ayub Gonzalez Airport , Encarnacion"
        },
        {
            "value": "ENS",
            "icao_code": "EHTW",
            "label": "Enschede Airport Twente , Enschede"
        },
        {
            "value": "ENT",
            "icao_code": "PKMA",
            "label": "Enewetak Auxiliary Airfield , Enewetak"
        },
        {
            "value": "ENU",
            "icao_code": "DNEN",
            "label": "Akanu Ibiam International Airport , Enugu"
        },
        {
            "value": "ENV",
            "icao_code": "KENV",
            "label": "Wendover Airport , Wendover"
        },
        {
            "value": "ENW",
            "icao_code": "KENW",
            "label": "Kenosha Regional Airport , Kenosha"
        },
        {
            "value": "ENY",
            "icao_code": "ZLYA",
            "label": "Yan'an Nanniwan Airport , Yan'an"
        },
        {
            "value": "EOH",
            "icao_code": "SKMD",
            "label": "Olaya Herrera Airport , Medell\u00edn"
        },
        {
            "value": "EOI",
            "icao_code": "EGED",
            "label": "Eday Airport , Eday"
        },
        {
            "value": "EOK",
            "icao_code": "KEOK",
            "label": "Keokuk Municipal Airport , Keokuk"
        },
        {
            "value": "EOR",
            "icao_code": "SVED",
            "label": "El Dorado Airport , El Dorado"
        },
        {
            "value": "EOS",
            "icao_code": "KEOS",
            "label": "Neosho Hugh Robinson Airport , Neosho"
        },
        {
            "value": "EOZ",
            "icao_code": "SVEZ",
            "label": "Elorza Airport , Elorza"
        },
        {
            "value": "EPA",
            "icao_code": "SADP",
            "label": "El Palomar Airport , El Palomar"
        },
        {
            "value": "EPG",
            "icao_code": null,
            "label": "Browns Airport , Weeping Water"
        },
        {
            "value": "EPH",
            "icao_code": "KEPH",
            "label": "Ephrata Municipal Airport , Ephrata"
        },
        {
            "value": "EPL",
            "icao_code": "LFSG",
            "label": "\u00c9pinal \u2013 Mirecourt Airport , \u00c9pinal"
        },
        {
            "value": "EPN",
            "icao_code": null,
            "label": "Ep\u00e9na Airport , Ep\u00e9na"
        },
        {
            "value": "EPR",
            "icao_code": "YESP",
            "label": "Esperance Airport , Esperance"
        },
        {
            "value": "EPS",
            "icao_code": "MDAB",
            "label": "Arroyo Barril Airport , Saman\u00e1"
        },
        {
            "value": "EPT",
            "icao_code": "AYEL",
            "label": "Eliptamin Airport , Eliptamin"
        },
        {
            "value": "EPU",
            "icao_code": "EEPU",
            "label": "P\u00e4rnu Airport , P\u00e4rnu"
        },
        {
            "value": "EQS",
            "icao_code": "SAVE",
            "label": "Esquel Airport , Esquel"
        },
        {
            "value": "ERA",
            "icao_code": "HCMU",
            "label": "Erigavo Airport , Erigavo"
        },
        {
            "value": "ERB",
            "icao_code": "YERN",
            "label": "Pukatja Airport , Pukatja"
        },
        {
            "value": "ERC",
            "icao_code": "LTCD",
            "label": "Erzincan Airport , Erzincan"
        },
        {
            "value": "ERD",
            "icao_code": "UKDB",
            "label": "Berdiansk Airport , Berdiansk"
        },
        {
            "value": "ERE",
            "icao_code": "AYEV",
            "label": "Erave Airport , Erave"
        },
        {
            "value": "ERF",
            "icao_code": "EDDE",
            "label": "Erfurt\u2013Weimar Airport , Erfurt"
        },
        {
            "value": "ERG",
            "icao_code": "UIKE",
            "label": "Erbogachen Airport , Yerbogachen"
        },
        {
            "value": "ERH",
            "icao_code": "GMFK",
            "label": "Moulay Ali Cherif Airport , Errachidia"
        },
        {
            "value": "ERI",
            "icao_code": "KERI",
            "label": "Erie International Airport , Erie"
        },
        {
            "value": "ERL",
            "icao_code": "ZBER",
            "label": "Erenhot Saiwusu International Airport , Erenhot"
        },
        {
            "value": "ERM",
            "icao_code": "SSER",
            "label": "Erechim Airport , Erechim"
        },
        {
            "value": "ERN",
            "icao_code": "SWEI",
            "label": "Eirunep\u00e9 Airport , Eirunep\u00e9"
        },
        {
            "value": "ERQ",
            "icao_code": "YESE",
            "label": "Elrose Airport , Eloise Copper Mine"
        },
        {
            "value": "ERR",
            "icao_code": "KERR",
            "label": "Errol Airport , Errol"
        },
        {
            "value": "ERS",
            "icao_code": "FYWE",
            "label": "Eros Airport , Windhoek"
        },
        {
            "value": "ERT",
            "icao_code": null,
            "label": "Erdenet Airport , Erdenet"
        },
        {
            "value": "ERU",
            "icao_code": "AYER",
            "label": "Erume Airport , Erume"
        },
        {
            "value": "ERV",
            "icao_code": "KERV",
            "label": "Kerrville Municipal Airport , Kerrville"
        },
        {
            "value": "ERZ",
            "icao_code": "LTCE",
            "label": "Erzurum Airport , Erzurum"
        },
        {
            "value": "ESA",
            "icao_code": null,
            "label": "Esa'ala Airport , Esa'ala"
        },
        {
            "value": "ESB",
            "icao_code": "LTAC",
            "label": "Esenbo\u011fa International Airport , Ankara"
        },
        {
            "value": "ESC",
            "icao_code": "KESC",
            "label": "Delta County Airport , Escanaba"
        },
        {
            "value": "ESD",
            "icao_code": "KORS",
            "label": "Orcas Island Airport , Eastsound"
        },
        {
            "value": "ESE",
            "icao_code": "MMES",
            "label": "Ensenada Airport , Ensenada"
        },
        {
            "value": "ESF",
            "icao_code": "KESF",
            "label": "Esler Airfield , Alexandria"
        },
        {
            "value": "ESG",
            "icao_code": "SGME",
            "label": "Dr. Luis Mar\u00eda Arga\u00f1a International Airport , Mariscal Estigarribia"
        },
        {
            "value": "ESH",
            "icao_code": "EGKA",
            "label": "Shoreham Airport , Shoreham-by-Sea"
        },
        {
            "value": "ESI",
            "icao_code": null,
            "label": "Espinosa Airport , Espinosa"
        },
        {
            "value": "ESK",
            "icao_code": "LTBI",
            "label": "Eski\u015fehir Airport , Eski\u015fehir"
        },
        {
            "value": "ESL",
            "icao_code": "URWI",
            "label": "Elista Airport , Elista"
        },
        {
            "value": "ESM",
            "icao_code": "SETN",
            "label": "Carlos Concha Torres International Airport , Esmeraldas"
        },
        {
            "value": "ESN",
            "icao_code": "KESN",
            "label": "Easton Airport , Easton"
        },
        {
            "value": "ESO",
            "icao_code": null,
            "label": "Ohkay Owingeh Airport , Espa\u00f1ola"
        },
        {
            "value": "ESP",
            "icao_code": null,
            "label": "Stroudsburg\u2013Pocono Airport , East Stroudsburg"
        },
        {
            "value": "ESR",
            "icao_code": "SCES",
            "label": "Ricardo Garc\u00eda Posada Airport , El Salvador"
        },
        {
            "value": "ESS",
            "icao_code": "EDLE",
            "label": "Essen/M\u00fclheim Airport , Essen"
        },
        {
            "value": "EST",
            "icao_code": "KEST",
            "label": "Estherville Municipal Airport , Estherville"
        },
        {
            "value": "ESU",
            "icao_code": "GMMI",
            "label": "Essaouira-Mogador Airport , Essaouira"
        },
        {
            "value": "ESW",
            "icao_code": "KESW",
            "label": "Easton State Airport , Easton"
        },
        {
            "value": "ETB",
            "icao_code": "KETB",
            "label": "West Bend Municipal Airport , West Bend"
        },
        {
            "value": "ETD",
            "icao_code": "YEDA",
            "label": "Etadunna Airport , Etadunna"
        },
        {
            "value": "ETE",
            "icao_code": "HAMM",
            "label": "Genda Wuha Airport , Metemma"
        },
        {
            "value": "ETH",
            "icao_code": "LLET",
            "label": "J. Hozman Airport , Eilat"
        },
        {
            "value": "ETM",
            "icao_code": "LLER",
            "label": "Ramon Airport , Eilat"
        },
        {
            "value": "ETN",
            "icao_code": "KETN",
            "label": "Eastland Municipal Airport , Eastland"
        },
        {
            "value": "ETR",
            "icao_code": "SERO",
            "label": "Santa Rosa International Airport , Santa Rosa"
        },
        {
            "value": "ETS",
            "icao_code": "KEDN",
            "label": "Enterprise Municipal Airport , Enterprise"
        },
        {
            "value": "ETZ",
            "icao_code": "LFJL",
            "label": "Metz\u2013Nancy\u2013Lorraine Airport , Metz"
        },
        {
            "value": "EUA",
            "icao_code": "NFTE",
            "label": "Eua Airport , Eua"
        },
        {
            "value": "EUC",
            "icao_code": "YECL",
            "label": "Eucla Airport , Eucla"
        },
        {
            "value": "EUE",
            "icao_code": null,
            "label": "Eureka Airport , Eureka"
        },
        {
            "value": "EUF",
            "icao_code": "KEUF",
            "label": "Weedon Field , Eufaula"
        },
        {
            "value": "EUG",
            "icao_code": "KEUG",
            "label": "Eugene Airport , Eugene"
        },
        {
            "value": "EUM",
            "icao_code": "EDHN",
            "label": "Neum\u00fcnster Airport , Neum\u00fcnster"
        },
        {
            "value": "EUN",
            "icao_code": "GMML",
            "label": "Hassan I Airport , Laayoune"
        },
        {
            "value": "EUO",
            "icao_code": null,
            "label": "Paratebueno Airport , Paratebueno"
        },
        {
            "value": "EUQ",
            "icao_code": "RPVS",
            "label": "Evelio Javier Airport , San Jose de Buenavista"
        },
        {
            "value": "EUX",
            "icao_code": "TNCE",
            "label": "F. D. Roosevelt Airport , Oranjestad"
        },
        {
            "value": "EVA",
            "icao_code": null,
            "label": "Ben Bruce Memorial Airpark , Evadale"
        },
        {
            "value": "EVD",
            "icao_code": null,
            "label": "Eva Downs Airport , Eva Downs"
        },
        {
            "value": "EVE",
            "icao_code": "ENEV",
            "label": "Harstad/Narvik Airport, Evenes , Harstad"
        },
        {
            "value": "EVG",
            "icao_code": "ESND",
            "label": "Sveg Airport , Sveg"
        },
        {
            "value": "EVH",
            "icao_code": "YEVD",
            "label": "Evans Head Memorial Aerodrome , Evans Head"
        },
        {
            "value": "EVM",
            "icao_code": "KEVM",
            "label": "Eveleth-Virginia Municipal Airport , Eveleth"
        },
        {
            "value": "EVN",
            "icao_code": "UDYZ",
            "label": "Zvartnots International Airport , Yerevan"
        },
        {
            "value": "EVV",
            "icao_code": "KEVV",
            "label": "Evansville Regional Airport , Evansville"
        },
        {
            "value": "EVW",
            "icao_code": "KEVW",
            "label": "Evanston-Uinta County Burns Field , Evanston"
        },
        {
            "value": "EVX",
            "icao_code": "LFOE",
            "label": "\u00c9vreux-Fauville Air Base , \u00c9vreux"
        },
        {
            "value": "EWB",
            "icao_code": "KEWB",
            "label": "New Bedford Regional Airport , New Bedford"
        },
        {
            "value": "EWE",
            "icao_code": null,
            "label": "Ewer Airport , Ewer"
        },
        {
            "value": "EWI",
            "icao_code": "WABT",
            "label": "Enarotali Airport , Enarotali"
        },
        {
            "value": "EWK",
            "icao_code": "KEWK",
            "label": "Newton City/County Airport , Newton"
        },
        {
            "value": "EWN",
            "icao_code": "KEWN",
            "label": "Coastal Carolina Regional Airport , New Bern"
        },
        {
            "value": "EWO",
            "icao_code": "FCOE",
            "label": "Ewo Airport , Ewo"
        },
        {
            "value": "EWR",
            "icao_code": "KEWR",
            "label": "Newark Liberty International Airport , Newark"
        },
        {
            "value": "EXI",
            "icao_code": null,
            "label": "Excursion Inlet Seaplane Base , Excursion Inlet"
        },
        {
            "value": "EXM",
            "icao_code": "YEXM",
            "label": "Exmouth Airport , Exmouth"
        },
        {
            "value": "EXT",
            "icao_code": "EGTE",
            "label": "Exeter International Airport , Exeter"
        },
        {
            "value": "EYK",
            "icao_code": "USHQ",
            "label": "Beloyarsk Airport , Beloyarsky"
        },
        {
            "value": "EYL",
            "icao_code": "GAYE",
            "label": "Y\u00e9liman\u00e9 Airport , Y\u00e9liman\u00e9"
        },
        {
            "value": "EYP",
            "icao_code": "SKYP",
            "label": "El Alcarav\u00e1n Airport , Yopal"
        },
        {
            "value": "EYR",
            "icao_code": null,
            "label": "Yerington Municipal Airport , Yerington"
        },
        {
            "value": "EYS",
            "icao_code": "HKES",
            "label": "Eliye Springs Airport , Eliye Springs"
        },
        {
            "value": "EYW",
            "icao_code": "KEYW",
            "label": "Key West International Airport , Key West"
        },
        {
            "value": "EZE",
            "icao_code": "SAEZ",
            "label": "Ministro Pistarini International Airport , Buenos Aires"
        },
        {
            "value": "EZS",
            "icao_code": "LTCA",
            "label": "Elaz\u0131\u011f Airport , Elaz\u0131\u011f"
        },
        {
            "value": "EZV",
            "icao_code": null,
            "label": "Beryozovo Airport , Beryozovo"
        },
        {
            "value": "FAA",
            "icao_code": "GUFH",
            "label": "Faranah Airport , Faranah"
        },
        {
            "value": "FAB",
            "icao_code": "EGLF",
            "label": "Farnborough Airport , Farnborough"
        },
        {
            "value": "FAC",
            "icao_code": "NTKF",
            "label": "Faaite Airport , Faaite"
        },
        {
            "value": "FAE",
            "icao_code": "EKVG",
            "label": "V\u00e1gar Airport , V\u00e1gar"
        },
        {
            "value": "FAF",
            "icao_code": "KFAF",
            "label": "Felker Army Airfield , Fort Eustis"
        },
        {
            "value": "FAG",
            "icao_code": "BIFM",
            "label": "Fagurh\u00f3lsm\u00fdri Airport , Fagurh\u00f3lsm\u00fdri"
        },
        {
            "value": "FAH",
            "icao_code": "OAFR",
            "label": "Farah Airport , Farah"
        },
        {
            "value": "FAI",
            "icao_code": "PAFA",
            "label": "Fairbanks International Airport , Fairbanks"
        },
        {
            "value": "FAJ",
            "icao_code": "TJFA",
            "label": "Diego Jim\u00e9nez Torres Airport , Fajardo"
        },
        {
            "value": "FAK",
            "icao_code": null,
            "label": "False Island Seaplane Base , False Island"
        },
        {
            "value": "FAM",
            "icao_code": "KFAM",
            "label": "Farmington Regional Airport , Farmington"
        },
        {
            "value": "FAO",
            "icao_code": "LPFR",
            "label": "Faro Airport , Faro"
        },
        {
            "value": "FAQ",
            "icao_code": "AYFR",
            "label": "Frieda River Airport , Frieda River"
        },
        {
            "value": "FAR",
            "icao_code": "KFAR",
            "label": "Hector International Airport , Fargo"
        },
        {
            "value": "FAS",
            "icao_code": "BIFF",
            "label": "F\u00e1skr\u00fa\u00f0sfj\u00f6r\u00f0ur Airport , F\u00e1skr\u00fa\u00f0sfj\u00f6r\u00f0ur"
        },
        {
            "value": "FAT",
            "icao_code": "KFAT",
            "label": "Fresno Yosemite International Airport , Fresno"
        },
        {
            "value": "FAU",
            "icao_code": "OOFD",
            "label": "Fahud Airport , Fahud"
        },
        {
            "value": "FAV",
            "icao_code": "NTGF",
            "label": "Fakarava Airport , Fakarava"
        },
        {
            "value": "FAY",
            "icao_code": "KFAY",
            "label": "Fayetteville Regional Airport , Fayetteville"
        },
        {
            "value": "FAZ",
            "icao_code": "OISF",
            "label": "Fasa Airport , Fasa"
        },
        {
            "value": "FBA",
            "icao_code": "SWOB",
            "label": "Fonte Boa Airport , Fonte Boa"
        },
        {
            "value": "FBD",
            "icao_code": "OAFZ",
            "label": "Fayzabad Airport , Fayzabad"
        },
        {
            "value": "FBE",
            "icao_code": "SSFB",
            "label": "Francisco Beltr\u00e3o Airport , Francisco Beltr\u00e3o"
        },
        {
            "value": "FBG",
            "icao_code": "KFBG",
            "label": "Simmons Army Airfield , Fort Liberty"
        },
        {
            "value": "FBK",
            "icao_code": "PAFB",
            "label": "Ladd Army Airfield , Fairbanks"
        },
        {
            "value": "FBL",
            "icao_code": "KFBL",
            "label": "Faribault Municipal Airport , Faribault"
        },
        {
            "value": "FBM",
            "icao_code": "FZQA",
            "label": "Lubumbashi International Airport , Lubumbashi"
        },
        {
            "value": "FBR",
            "icao_code": "KFBR",
            "label": "Fort Bridger Airport , Fort Bridger"
        },
        {
            "value": "FBS",
            "icao_code": null,
            "label": "Friday Harbor Seaplane Base , Friday Harbor"
        },
        {
            "value": "FBY",
            "icao_code": "KFBY",
            "label": "Fairbury Municipal Airport , Fairbury"
        },
        {
            "value": "FCA",
            "icao_code": "KGPI",
            "label": "Glacier Park International Airport , Kalispell"
        },
        {
            "value": "FCB",
            "icao_code": "FAFB",
            "label": "Ficksburg Airport , Ficksburg"
        },
        {
            "value": "FCH",
            "icao_code": "KFCH",
            "label": "Fresno Chandler Executive Airport , Fresno"
        },
        {
            "value": "FCM",
            "icao_code": "KFCM",
            "label": "Flying Cloud Airport , Eden Prairie"
        },
        {
            "value": "FCN",
            "icao_code": "ETMN",
            "label": "Sea-Airport Cuxhaven/Nordholz , Cuxhaven"
        },
        {
            "value": "FCO",
            "icao_code": "LIRF",
            "label": "Leonardo da Vinci\u2013Fiumicino Airport , Rome"
        },
        {
            "value": "FCS",
            "icao_code": "KFCS",
            "label": "Butts Army Airfield , Colorado Springs"
        },
        {
            "value": "FCY",
            "icao_code": "KFCY",
            "label": "Forrest City Municipal Airport , Forrest City"
        },
        {
            "value": "FDE",
            "icao_code": "ENBL",
            "label": "F\u00f8rde Airport, Bringeland , F\u00f8rde"
        },
        {
            "value": "FDF",
            "icao_code": "TFFF",
            "label": "Martinique Aim\u00e9 C\u00e9saire International Airport , Fort-de-France"
        },
        {
            "value": "FDH",
            "icao_code": "EDNY",
            "label": "Friedrichshafen Airport , Friedrichshafen"
        },
        {
            "value": "FDK",
            "icao_code": "KFDK",
            "label": "Frederick Municipal Airport , Frederick"
        },
        {
            "value": "FDR",
            "icao_code": "KFDR",
            "label": "Frederick Regional Airport , Frederick"
        },
        {
            "value": "FDU",
            "icao_code": "FZBO",
            "label": "Bandundu Airport , Bandundu"
        },
        {
            "value": "FDY",
            "icao_code": "KFDY",
            "label": "Findlay Airport , Findlay"
        },
        {
            "value": "FEA",
            "icao_code": null,
            "label": "Fetlar Airport , Fetlar"
        },
        {
            "value": "FEB",
            "icao_code": "VNSR",
            "label": "Sanfebagar Airport , Sanfebagar"
        },
        {
            "value": "FEC",
            "icao_code": "SNJD",
            "label": "Feira de Santana Airport , Feira de Santana"
        },
        {
            "value": "FEG",
            "icao_code": "UTKF",
            "label": "Fergana International Airport , Fergana"
        },
        {
            "value": "FEJ",
            "icao_code": "SNOU",
            "label": "Feij\u00f3 Airport , Feij\u00f3"
        },
        {
            "value": "FEK",
            "icao_code": "DIFK",
            "label": "Ferkess\u00e9dougou Airport , Ferkess\u00e9dougou"
        },
        {
            "value": "FEL",
            "icao_code": "ETSF",
            "label": "F\u00fcrstenfeldbruck Air Base , F\u00fcrstenfeldbruck"
        },
        {
            "value": "FEN",
            "icao_code": "SBFN",
            "label": "Fernando de Noronha Airport , Fernando de Noronha"
        },
        {
            "value": "FEP",
            "icao_code": "KFEP",
            "label": "Albertus Airport , Freeport"
        },
        {
            "value": "FET",
            "icao_code": "KFET",
            "label": "Fremont Municipal Airport , Fremont"
        },
        {
            "value": "FEZ",
            "icao_code": "GMFF",
            "label": "Fes\u2013Sa\u00efss Airport , Fez"
        },
        {
            "value": "FFA",
            "icao_code": "KFFA",
            "label": "First Flight Airport , Kill Devil Hills"
        },
        {
            "value": "FFD",
            "icao_code": "EGVA",
            "label": "RAF Fairford , Fairford"
        },
        {
            "value": "FFL",
            "icao_code": "KFFL",
            "label": "Fairfield Municipal Airport , Fairfield"
        },
        {
            "value": "FFM",
            "icao_code": "KFFM",
            "label": "Fergus Falls Municipal Airport , Fergus Falls"
        },
        {
            "value": "FFO",
            "icao_code": "KFFO",
            "label": "Wright-Patterson Air Force Base , Dayton"
        },
        {
            "value": "FFT",
            "icao_code": "KFFT",
            "label": "Capital City Airport , Frankfort"
        },
        {
            "value": "FFU",
            "icao_code": "SCFT",
            "label": "Futaleuf\u00fa Airfield , Futaleuf\u00fa"
        },
        {
            "value": "FGD",
            "icao_code": "GQPF",
            "label": "Fderik Airport , Fder\u00eeck"
        },
        {
            "value": "FGI",
            "icao_code": "NSFI",
            "label": "Fagali'i Airport , Apia"
        },
        {
            "value": "FGU",
            "icao_code": "NTGB",
            "label": "Fangatau Airport , Fangatau"
        },
        {
            "value": "FHU",
            "icao_code": "KFHU",
            "label": "Sierra Vista Municipal Airport , Sierra Vista"
        },
        {
            "value": "FHZ",
            "icao_code": "NTKH",
            "label": "Fakahina Airport , Fakahina"
        },
        {
            "value": "FID",
            "icao_code": null,
            "label": "Elizabeth Field , Fishers Island"
        },
        {
            "value": "FIE",
            "icao_code": "EGEF",
            "label": "Fair Isle Airport , Fair Isle"
        },
        {
            "value": "FIG",
            "icao_code": "GUFA",
            "label": "Fria Airport , Fria"
        },
        {
            "value": "FIH",
            "icao_code": "FZAA",
            "label": "N'djili Airport , Kinshasa"
        },
        {
            "value": "FIK",
            "icao_code": "YFNE",
            "label": "Finke Airport , Finke"
        },
        {
            "value": "FIL",
            "icao_code": "KFOM",
            "label": "Fillmore Municipal Airport , Fillmore"
        },
        {
            "value": "FIN",
            "icao_code": "AYFI",
            "label": "Finschhafen Airport , Finschhafen"
        },
        {
            "value": "FIZ",
            "icao_code": "YFTZ",
            "label": "Fitzroy Crossing Airport , Fitzroy Crossing"
        },
        {
            "value": "FJR",
            "icao_code": "OMFJ",
            "label": "Fujairah International Airport , Fujairah"
        },
        {
            "value": "FKB",
            "icao_code": "EDSB",
            "label": "Karlsruhe/Baden-Baden Airport , Karlsruhe"
        },
        {
            "value": "FKI",
            "icao_code": "FZIC",
            "label": "Bangoka International Airport , Kisangani"
        },
        {
            "value": "FKJ",
            "icao_code": "RJNF",
            "label": "Fukui Airport , Fukui"
        },
        {
            "value": "FKL",
            "icao_code": "KFKL",
            "label": "Venango Regional Airport , Franklin"
        },
        {
            "value": "FKN",
            "icao_code": "KFKN",
            "label": "Franklin Municipal\u2013John Beverly Rose Airport , Franklin"
        },
        {
            "value": "FKQ",
            "icao_code": "WASF",
            "label": "Fakfak Torea Airport , Fakfak"
        },
        {
            "value": "FKS",
            "icao_code": "RJSF",
            "label": "Fukushima Airport , Sukagawa"
        },
        {
            "value": "FLA",
            "icao_code": "SKFL",
            "label": "Gustavo Artunduaga Paredes Airport , Florencia (Caquet\u00e1)"
        },
        {
            "value": "FLB",
            "icao_code": "SNQG",
            "label": "Cangapara Airport , Floriano"
        },
        {
            "value": "FLD",
            "icao_code": "KFLD",
            "label": "Fond du Lac County Airport , Fond du Lac"
        },
        {
            "value": "FLF",
            "icao_code": "EDXF",
            "label": "Flensburg-Sch\u00e4ferhaus Airport , Flensburg"
        },
        {
            "value": "FLG",
            "icao_code": "KFLG",
            "label": "Flagstaff Pulliam Airport , Flagstaff"
        },
        {
            "value": "FLH",
            "icao_code": null,
            "label": "Flotta Airport , Flotta"
        },
        {
            "value": "FLI",
            "icao_code": null,
            "label": "Holt Airport , Flateyri"
        },
        {
            "value": "FLL",
            "icao_code": "KFLL",
            "label": "Fort Lauderdale\u2013Hollywood International Airport , Fort Lauderdale"
        },
        {
            "value": "FLM",
            "icao_code": "SGFI",
            "label": "Filadelfia Airport , Filadelfia"
        },
        {
            "value": "FLN",
            "icao_code": "SBFL",
            "label": "Herc\u00edlio Luz International Airport , Florian\u00f3polis"
        },
        {
            "value": "FLO",
            "icao_code": "KFLO",
            "label": "Florence Regional Airport , Florence"
        },
        {
            "value": "FLP",
            "icao_code": "KFLP",
            "label": "Marion County Regional Airport , Flippin"
        },
        {
            "value": "FLR",
            "icao_code": "LIRQ",
            "label": "Florence Airport, Peretola , Florence"
        },
        {
            "value": "FLS",
            "icao_code": "YFLI",
            "label": "Flinders Island Airport , Flinders Island"
        },
        {
            "value": "FLT",
            "icao_code": null,
            "label": "Flat Airport , Flat"
        },
        {
            "value": "FLV",
            "icao_code": "KFLV",
            "label": "Sherman Army Airfield , Fort Leavenworth"
        },
        {
            "value": "FLW",
            "icao_code": "LPFL",
            "label": "Flores Airport , Flores Island"
        },
        {
            "value": "FLX",
            "icao_code": "KFLX",
            "label": "Fallon Municipal Airport , Fallon"
        },
        {
            "value": "FLY",
            "icao_code": "YFIL",
            "label": "Finley Airport , Finley"
        },
        {
            "value": "FLZ",
            "icao_code": "WIMS",
            "label": "Ferdinand Lumban Tobing Airport , Sibolga"
        },
        {
            "value": "FMA",
            "icao_code": "SARF",
            "label": "Formosa International Airport , Formosa"
        },
        {
            "value": "FME",
            "icao_code": "KFME",
            "label": "Tipton Airport , Fort Meade"
        },
        {
            "value": "FMH",
            "icao_code": "KFMH",
            "label": "Otis Air National Guard Base , Falmouth"
        },
        {
            "value": "FMI",
            "icao_code": "FZRF",
            "label": "Kalemie Airport , Kalemie"
        },
        {
            "value": "FMM",
            "icao_code": "EDJA",
            "label": "Memmingen Airport , Memmingen"
        },
        {
            "value": "FMN",
            "icao_code": "KFMN",
            "label": "Four Corners Regional Airport , Farmington"
        },
        {
            "value": "FMO",
            "icao_code": "EDDG",
            "label": "M\u00fcnster Osnabr\u00fcck Airport , M\u00fcnster"
        },
        {
            "value": "FMS",
            "icao_code": "KFSW",
            "label": "Fort Madison Municipal Airport , Fort Madison"
        },
        {
            "value": "FMU",
            "icao_code": null,
            "label": "Florence Municipal Airport , Florence"
        },
        {
            "value": "FMY",
            "icao_code": "KFMY",
            "label": "Page Field , Fort Myers"
        },
        {
            "value": "FNA",
            "icao_code": "GFLL",
            "label": "Lungi International Airport , Freetown"
        },
        {
            "value": "FNB",
            "icao_code": "ETNU",
            "label": "Neubrandenburg Airport , Neubrandenburg"
        },
        {
            "value": "FNC",
            "icao_code": "LPMA",
            "label": "Madeira Airport , Funchal"
        },
        {
            "value": "FND",
            "icao_code": null,
            "label": "Funadhoo Airport , Funadhoo"
        },
        {
            "value": "FNE",
            "icao_code": "AYFA",
            "label": "Fane Airport , Fane"
        },
        {
            "value": "FNG",
            "icao_code": "DFEF",
            "label": "Fada N'gourma Airport , Fada N'gourma"
        },
        {
            "value": "FNH",
            "icao_code": "HAFN",
            "label": "Fincha Airport , Fincha"
        },
        {
            "value": "FNI",
            "icao_code": "LFTW",
            "label": "N\u00eemes\u2013Al\u00e8s\u2013Camargue\u2013C\u00e9vennes Airport , N\u00eemes"
        },
        {
            "value": "FNJ",
            "icao_code": "ZKPY",
            "label": "Pyongyang Sunan International Airport , Pyongyang"
        },
        {
            "value": "FNL",
            "icao_code": "KFNL",
            "label": "Fort Collins\u2013Loveland Municipal Airport , Fort Collins"
        },
        {
            "value": "FNR",
            "icao_code": "PANR",
            "label": "Funter Bay Seaplane Base , Funter Bay"
        },
        {
            "value": "FNT",
            "icao_code": "KFNT",
            "label": "Bishop International Airport , Flint"
        },
        {
            "value": "FNU",
            "icao_code": "LIER",
            "label": "Oristano-Fenosu Airport , Oristano"
        },
        {
            "value": "FOA",
            "icao_code": null,
            "label": "Foula Airfield , Foula"
        },
        {
            "value": "FOB",
            "icao_code": null,
            "label": "Fort Bragg Airport , Fort Bragg"
        },
        {
            "value": "FOC",
            "icao_code": "ZSFZ",
            "label": "Fuzhou Changle International Airport , Fuzhou"
        },
        {
            "value": "FOD",
            "icao_code": "KFOD",
            "label": "Fort Dodge Regional Airport , Fort Dodge"
        },
        {
            "value": "FOE",
            "icao_code": "KFOE",
            "label": "Topeka Regional Airport , Topeka"
        },
        {
            "value": "FOG",
            "icao_code": "LIBF",
            "label": "Foggia \"Gino Lisa\" Airport , Foggia"
        },
        {
            "value": "FOK",
            "icao_code": "KFOK",
            "label": "Francis S. Gabreski Airport , Westhampton Beach"
        },
        {
            "value": "FOM",
            "icao_code": "FKKM",
            "label": "Foumban Nkounja Airport , Foumban"
        },
        {
            "value": "FON",
            "icao_code": "MRAN",
            "label": "La Fortuna Arenal Airport , La Fortuna"
        },
        {
            "value": "FOO",
            "icao_code": null,
            "label": "Kornasoren Airport , Numfoor"
        },
        {
            "value": "FOR",
            "icao_code": "SBFZ",
            "label": "Pinto Martins \u2013 Fortaleza International Airport , Fortaleza"
        },
        {
            "value": "FOS",
            "icao_code": "YFRT",
            "label": "Forrest Airport , Forrest"
        },
        {
            "value": "FOT",
            "icao_code": "YFST",
            "label": "Forster (Wallis Island) Airport , Forster"
        },
        {
            "value": "FOU",
            "icao_code": "FOGF",
            "label": "Fougamou Airport , Fougamou"
        },
        {
            "value": "FOY",
            "icao_code": null,
            "label": "Foya Airport , Foya"
        },
        {
            "value": "FPO",
            "icao_code": "MYGF",
            "label": "Grand Bahama International Airport , Freeport"
        },
        {
            "value": "FPR",
            "icao_code": "KFPR",
            "label": "St. Lucie County International Airport , Fort Pierce"
        },
        {
            "value": "FPY",
            "icao_code": null,
            "label": "Perry-Foley Airport , Perry"
        },
        {
            "value": "FRA",
            "icao_code": "EDDF",
            "label": "Frankfurt Airport , Frankfurt"
        },
        {
            "value": "FRB",
            "icao_code": "YFBS",
            "label": "Forbes Airport , Forbes"
        },
        {
            "value": "FRC",
            "icao_code": "SIMK",
            "label": "Franca Airport , Franca"
        },
        {
            "value": "FRD",
            "icao_code": "KFHR",
            "label": "Friday Harbor Airport , Friday Harbor"
        },
        {
            "value": "FRE",
            "icao_code": "AGGF",
            "label": "Fera Airport , Fera Island"
        },
        {
            "value": "FRG",
            "icao_code": "KFRG",
            "label": "Republic Airport , Farmingdale"
        },
        {
            "value": "FRH",
            "icao_code": "KFRH",
            "label": "French Lick Municipal Airport , French Lick"
        },
        {
            "value": "FRI",
            "icao_code": "KFRI",
            "label": "Marshall Army Airfield , Fort Riley"
        },
        {
            "value": "FRK",
            "icao_code": "FSSF",
            "label": "Fr\u00e9gate Island Airport , Fr\u00e9gate Island"
        },
        {
            "value": "FRL",
            "icao_code": "LIPK",
            "label": "Forl\u00ec International Airport , Forl\u00ec"
        },
        {
            "value": "FRM",
            "icao_code": "KFRM",
            "label": "Fairmont Municipal Airport , Fairmont"
        },
        {
            "value": "FRN",
            "icao_code": "PAFR",
            "label": "Bryant Army Airport , Anchorage"
        },
        {
            "value": "FRO",
            "icao_code": "ENFL",
            "label": "Flor\u00f8 Airport , Flor\u00f8"
        },
        {
            "value": "FRQ",
            "icao_code": "AYFE",
            "label": "Feramin Airport , Feramin"
        },
        {
            "value": "FRR",
            "icao_code": "KFRR",
            "label": "Front Royal\u2013Warren County Airport , Front Royal"
        },
        {
            "value": "FRS",
            "icao_code": "MGTK",
            "label": "Mundo Maya International Airport , Flores"
        },
        {
            "value": "FRT",
            "icao_code": "SCFI",
            "label": "Frutillar Airport , Frutillar"
        },
        {
            "value": "FRU",
            "icao_code": "UAFM",
            "label": "Manas International Airport , Bishkek"
        },
        {
            "value": "FRW",
            "icao_code": "FBFT",
            "label": "Francistown Airport , Francistown"
        },
        {
            "value": "FRY",
            "icao_code": "KIZG",
            "label": "Eastern Slopes Regional Airport , Fryeburg"
        },
        {
            "value": "FRZ",
            "icao_code": "ETHF",
            "label": "Fritzlar Air Base , Fritzlar"
        },
        {
            "value": "FSC",
            "icao_code": "LFKF",
            "label": "Figari\u2013Sud Corse Airport , Figari"
        },
        {
            "value": "FSD",
            "icao_code": "KFSD",
            "label": "Sioux Falls Regional Airport , Sioux Falls"
        },
        {
            "value": "FSI",
            "icao_code": "KFSI",
            "label": "Henry Post Army Airfield , Lawton"
        },
        {
            "value": "FSK",
            "icao_code": "KFSK",
            "label": "Fort Scott Municipal Airport , Fort Scott"
        },
        {
            "value": "FSL",
            "icao_code": null,
            "label": "Fossil Downs Airport , Fossil Downs Station"
        },
        {
            "value": "FSM",
            "icao_code": "KFSM",
            "label": "Fort Smith Regional Airport , Fort Smith"
        },
        {
            "value": "FSP",
            "icao_code": "LFVP",
            "label": "Saint-Pierre Airport , Saint-Pierre"
        },
        {
            "value": "FSS",
            "icao_code": "EGQK",
            "label": "RAF Kinloss , Forres"
        },
        {
            "value": "FST",
            "icao_code": "KFST",
            "label": "Fort Stockton\u2013Pecos County Airport , Fort Stockton"
        },
        {
            "value": "FSU",
            "icao_code": "KFSU",
            "label": "Fort Sumner Municipal Airport , Fort Sumner"
        },
        {
            "value": "FSZ",
            "icao_code": "RJNS",
            "label": "Shizuoka Airport , Shizuoka"
        },
        {
            "value": "FTA",
            "icao_code": "NVVF",
            "label": "Futuna Airport , Futuna Island"
        },
        {
            "value": "FTE",
            "icao_code": "SAWA",
            "label": "Comandante Armando Tola International Airport , El Calafate"
        },
        {
            "value": "FTI",
            "icao_code": "NSFQ",
            "label": "Fitiuta Airport , Fitiuta"
        },
        {
            "value": "FTK",
            "icao_code": "KFTK",
            "label": "Godman Army Airfield , Fort Knox"
        },
        {
            "value": "FTU",
            "icao_code": "FMSD",
            "label": "T\u00f4lanaro Airport , T\u00f4lanaro"
        },
        {
            "value": "FTW",
            "icao_code": "KFTW",
            "label": "Fort Worth Meacham International Airport , Fort Worth"
        },
        {
            "value": "FTX",
            "icao_code": "FCOO",
            "label": "Owando Airport , Owando"
        },
        {
            "value": "FTY",
            "icao_code": "KFTY",
            "label": "Fulton County Airport , Atlanta"
        },
        {
            "value": "FUB",
            "icao_code": null,
            "label": "Fulleborn Airport , Fulleborn"
        },
        {
            "value": "FUE",
            "icao_code": "GCFV",
            "label": "Fuerteventura Airport , Fuerteventura"
        },
        {
            "value": "FUG",
            "icao_code": "ZSFY",
            "label": "Fuyang Xiguan Airport , Fuyang"
        },
        {
            "value": "FUJ",
            "icao_code": "RJFE",
            "label": "Fukue Airport , Got\u014d"
        },
        {
            "value": "FUK",
            "icao_code": "RJFF",
            "label": "Fukuoka Airport , Fukuoka"
        },
        {
            "value": "FUL",
            "icao_code": "KFUL",
            "label": "Fullerton Municipal Airport , Fullerton"
        },
        {
            "value": "FUM",
            "icao_code": null,
            "label": "Fuma Airport , Fuma"
        },
        {
            "value": "FUN",
            "icao_code": "NGFU",
            "label": "Funafuti International Airport , Funafuti"
        },
        {
            "value": "FUO",
            "icao_code": "ZGFS",
            "label": "Foshan Shadi Airport , Foshan"
        },
        {
            "value": "FUT",
            "icao_code": "NLWF",
            "label": "Pointe Vele Airport , Futuna Island"
        },
        {
            "value": "FVL",
            "icao_code": "YFLO",
            "label": "Flora Valley Airport , Flora Valley"
        },
        {
            "value": "FVM",
            "icao_code": "VRMF",
            "label": "Fuvahmulah Airport , Fuvahmulah"
        },
        {
            "value": "FVR",
            "icao_code": "YFRV",
            "label": "Forrest River Airport , Forrest River Mission"
        },
        {
            "value": "FWA",
            "icao_code": "KFWA",
            "label": "Fort Wayne International Airport , Fort Wayne"
        },
        {
            "value": "FWH",
            "icao_code": "KNFW",
            "label": "NAS Fort Worth JRB , Fort Worth"
        },
        {
            "value": "FWL",
            "icao_code": "PAFW",
            "label": "Farewell Airport , Farewell"
        },
        {
            "value": "FXE",
            "icao_code": "KFXE",
            "label": "Fort Lauderdale Executive Airport , Fort Lauderdale"
        },
        {
            "value": "FXO",
            "icao_code": "FQCB",
            "label": "Cuamba Airport , Cuamba"
        },
        {
            "value": "FXY",
            "icao_code": "KFXY",
            "label": "Forest City Municipal Airport , Forest City"
        },
        {
            "value": "FYJ",
            "icao_code": "ZYFY",
            "label": "Fuyuan Dongji Airport , Fuyuan"
        },
        {
            "value": "FYM",
            "icao_code": "KFYM",
            "label": "Fayetteville Municipal Airport , Fayetteville"
        },
        {
            "value": "FYN",
            "icao_code": "ZWFY",
            "label": "Fuyun Koktokay Airport , Fuyun"
        },
        {
            "value": "FYT",
            "icao_code": "FTTY",
            "label": "Faya-Largeau Airport , Faya-Largeau"
        },
        {
            "value": "FYU",
            "icao_code": "PFYU",
            "label": "Fort Yukon Airport , Fort Yukon"
        },
        {
            "value": "FYV",
            "icao_code": "KFYV",
            "label": "Drake Field , Fayetteville"
        },
        {
            "value": "FZO",
            "icao_code": "EGTG",
            "label": "Bristol Filton Airport , Bristol"
        },
        {
            "value": "FZL",
            "icao_code": "UBBF",
            "label": "Fuzuli International Airport , Fuzuli"
        },
        {
            "value": "GAA",
            "icao_code": null,
            "label": "Guamal Airport , Guamal"
        },
        {
            "value": "GAB",
            "icao_code": "KGAB",
            "label": "Gabbs Airport , Gabbs"
        },
        {
            "value": "GAC",
            "icao_code": "MHGS",
            "label": "Gracias Airport , Gracias"
        },
        {
            "value": "GAD",
            "icao_code": "KGAD",
            "label": "Northeast Alabama Regional Airport , Gadsden"
        },
        {
            "value": "GAE",
            "icao_code": "DTTG",
            "label": "Gab\u00e8s \u2013 Matmata International Airport , Gab\u00e8s"
        },
        {
            "value": "GAF",
            "icao_code": "DTTF",
            "label": "Gafsa \u2013 Ksar International Airport , Gafsa"
        },
        {
            "value": "GAG",
            "icao_code": "KGAG",
            "label": "Gage Airport , Gage"
        },
        {
            "value": "GAH",
            "icao_code": "YGAY",
            "label": "Gayndah Airport , Gayndah"
        },
        {
            "value": "GAI",
            "icao_code": "KGAI",
            "label": "Montgomery County Airpark , Gaithersburg"
        },
        {
            "value": "GAJ",
            "icao_code": "RJSC",
            "label": "Yamagata Airport , Yamagata"
        },
        {
            "value": "GAL",
            "icao_code": "PAGA",
            "label": "Edward G. Pitka Sr. Airport , Galena"
        },
        {
            "value": "GAM",
            "icao_code": "PAGM",
            "label": "Gambell Airport , Gambell"
        },
        {
            "value": "GAN",
            "icao_code": "VRMG",
            "label": "Gan International Airport , Gan Island"
        },
        {
            "value": "GAO",
            "icao_code": "MUGT",
            "label": "Mariana Grajales Airport , Guant\u00e1namo"
        },
        {
            "value": "GAP",
            "icao_code": "AYGP",
            "label": "Gusap Airport , Gusap"
        },
        {
            "value": "GAQ",
            "icao_code": "GAGO",
            "label": "Gao International Airport , Gao"
        },
        {
            "value": "GAR",
            "icao_code": "AYGI",
            "label": "Garaina Airport , Garaina"
        },
        {
            "value": "GAS",
            "icao_code": "HKGA",
            "label": "Garissa Airport , Garissa"
        },
        {
            "value": "GAT",
            "icao_code": "LFNA",
            "label": "Gap\u2013Tallard Airport , Gap"
        },
        {
            "value": "GAU",
            "icao_code": "VEGT",
            "label": "Lokpriya Gopinath Bordoloi International Airport , Guwahati"
        },
        {
            "value": "GAV",
            "icao_code": null,
            "label": "Gag Island Airport , Gag Island"
        },
        {
            "value": "GAW",
            "icao_code": "VYGG",
            "label": "Gangaw Airport , Gangaw"
        },
        {
            "value": "GAX",
            "icao_code": null,
            "label": "Gamba Airport , Gamba"
        },
        {
            "value": "GAY",
            "icao_code": "VEGY",
            "label": "Gaya Airport , Gaya"
        },
        {
            "value": "GAZ",
            "icao_code": null,
            "label": "Guasopa Airport , Guasopa"
        },
        {
            "value": "GBA",
            "icao_code": "EGBP",
            "label": "Cotswold Airport , Kemble"
        },
        {
            "value": "GBB",
            "icao_code": "UBBQ",
            "label": "Qabala International Airport , Qabala"
        },
        {
            "value": "GBC",
            "icao_code": "AYGS",
            "label": "Gasuke Airport , Gasuke"
        },
        {
            "value": "GBD",
            "icao_code": "KGBD",
            "label": "Great Bend Municipal Airport , Great Bend"
        },
        {
            "value": "GBE",
            "icao_code": "FBSK",
            "label": "Sir Seretse Khama International Airport , Gaborone"
        },
        {
            "value": "GBF",
            "icao_code": "AYNE",
            "label": "Negarbo Airport , Negarbo"
        },
        {
            "value": "GBG",
            "icao_code": "KGBG",
            "label": "Galesburg Municipal Airport , Galesburg"
        },
        {
            "value": "GBH",
            "icao_code": "PAGB",
            "label": "Galbraith Lake Airport , Galbraith Lake"
        },
        {
            "value": "GBI",
            "icao_code": null,
            "label": "Kalaburagi Airport , Gulbarga"
        },
        {
            "value": "GBJ",
            "icao_code": "TFFM",
            "label": "Marie-Galante Airport , Grand-Bourg"
        },
        {
            "value": "GBK",
            "icao_code": "GFGK",
            "label": "Gbangbatok Airport , Gbangbatok"
        },
        {
            "value": "GBL",
            "icao_code": "YGBI",
            "label": "South Goulburn Island Airport , Goulburn Islands"
        },
        {
            "value": "GBM",
            "icao_code": null,
            "label": "Garbaharey Airport , Garbaharey"
        },
        {
            "value": "GBP",
            "icao_code": "YGAM",
            "label": "Gamboola Airport , Gamboola"
        },
        {
            "value": "GBR",
            "icao_code": "KGBR",
            "label": "Walter J. Koladza Airport , Great Barrington"
        },
        {
            "value": "GBT",
            "icao_code": "OING",
            "label": "Gorgan Airport , Gorgan"
        },
        {
            "value": "GBU",
            "icao_code": "HSKG",
            "label": "Khashm el Girba Airport , Khashm el Girba"
        },
        {
            "value": "GBV",
            "icao_code": "YGIB",
            "label": "Gibb River Airport , Gibb River"
        },
        {
            "value": "GBW",
            "icao_code": "YGIA",
            "label": "Ginbata Airport , Ginbata"
        },
        {
            "value": "GBZ",
            "icao_code": "NZGB",
            "label": "Great Barrier Aerodrome , Great Barrier Island"
        },
        {
            "value": "GCA",
            "icao_code": null,
            "label": "Guacamayas Airport , Guacamayas"
        },
        {
            "value": "GCC",
            "icao_code": "KGCC",
            "label": "Gillette\u2013Campbell County Airport , Gillette"
        },
        {
            "value": "GCD",
            "icao_code": null,
            "label": "Grand Coulee Dam Airport , Electric City"
        },
        {
            "value": "GCH",
            "icao_code": "OIAH",
            "label": "Gachsaran Airport , Gachsaran"
        },
        {
            "value": "GCI",
            "icao_code": "EGJB",
            "label": "Guernsey Airport , The Forest"
        },
        {
            "value": "GCJ",
            "icao_code": "FAGC",
            "label": "Grand Central Airport , Johannesburg"
        },
        {
            "value": "GCK",
            "icao_code": "KGCK",
            "label": "Garden City Regional Airport , Garden City"
        },
        {
            "value": "GCM",
            "icao_code": "MWCR",
            "label": "Owen Roberts International Airport , Grand Cayman"
        },
        {
            "value": "GCN",
            "icao_code": "KGCN",
            "label": "Grand Canyon National Park Airport , Tusayan"
        },
        {
            "value": "GCT",
            "icao_code": null,
            "label": "Grand Canyon Bar 10 Airport , Whitmore"
        },
        {
            "value": "GCW",
            "icao_code": null,
            "label": "Grand Canyon West Airport , Peach Springs"
        },
        {
            "value": "GCY",
            "icao_code": "KGCY",
            "label": "Greeneville\u2013Greene County Municipal Airport , Greeneville"
        },
        {
            "value": "GDA",
            "icao_code": null,
            "label": "Gounda Airport , Gounda"
        },
        {
            "value": "GDC",
            "icao_code": "KGYH",
            "label": "Donaldson Center Airport , Greenville"
        },
        {
            "value": "GDD",
            "icao_code": "YGDN",
            "label": "Gordon Downs Airport , Gordon Downs"
        },
        {
            "value": "GDE",
            "icao_code": "HAGO",
            "label": "Gode Airport , Gode"
        },
        {
            "value": "GDG",
            "icao_code": "UHBI",
            "label": "Magdagachi Airport , Magdagachi"
        },
        {
            "value": "GDH",
            "icao_code": null,
            "label": "Golden Horn Lodge Seaplane Base , Golden Horn Lodge"
        },
        {
            "value": "GDI",
            "icao_code": null,
            "label": "Gordil Airport , Gordil"
        },
        {
            "value": "GDJ",
            "icao_code": "FZWC",
            "label": "Gandajika Airport , Gandajika"
        },
        {
            "value": "GDL",
            "icao_code": "MMGL",
            "label": "Guadalajara International Airport , Guadalajara"
        },
        {
            "value": "GDM",
            "icao_code": "KGDM",
            "label": "Gardner Municipal Airport , Gardner"
        },
        {
            "value": "GDN",
            "icao_code": "EPGD",
            "label": "Gda\u0144sk Lech Wa\u0142\u0119sa Airport , Gda\u0144sk"
        },
        {
            "value": "GDO",
            "icao_code": "SVGD",
            "label": "Guasdualito Airport , Guasdualito"
        },
        {
            "value": "GDP",
            "icao_code": "SNGD",
            "label": "Guadalupe Airport , Guadalupe"
        },
        {
            "value": "GDQ",
            "icao_code": "HAGN",
            "label": "Gondar Airport , Gondar"
        },
        {
            "value": "GDT",
            "icao_code": "MBGT",
            "label": "JAGS McCartney International Airport , Grand Turk Island"
        },
        {
            "value": "GDV",
            "icao_code": "KGDV",
            "label": "Dawson Community Airport , Glendive"
        },
        {
            "value": "GDW",
            "icao_code": "KGDW",
            "label": "Gladwin Zettel Memorial Airport , Gladwin"
        },
        {
            "value": "GDX",
            "icao_code": "UHMM",
            "label": "Sokol Airport , Magadan"
        },
        {
            "value": "GDZ",
            "icao_code": "URKG",
            "label": "Gelendzhik Airport , Gelendzhik"
        },
        {
            "value": "GEA",
            "icao_code": "NWWM",
            "label": "Noum\u00e9a Magenta Airport , Noum\u00e9a"
        },
        {
            "value": "GEB",
            "icao_code": "WAMJ",
            "label": "Gebe Airport , Gebe"
        },
        {
            "value": "GEC",
            "icao_code": null,
            "label": "Ge\u00e7itkale Air Base , Ge\u00e7itkale"
        },
        {
            "value": "GED",
            "icao_code": "KGED",
            "label": "Delaware Coastal Airport , Georgetown"
        },
        {
            "value": "GEE",
            "icao_code": "YGTO",
            "label": "George Town Aerodrome , George Town"
        },
        {
            "value": "GEF",
            "icao_code": "AGEV",
            "label": "Geva Airport , Liangai"
        },
        {
            "value": "GEG",
            "icao_code": "KGEG",
            "label": "Spokane International Airport , Spokane"
        },
        {
            "value": "GEL",
            "icao_code": "SBNM",
            "label": "Sep\u00e9 Tiaraju Airport , Santo \u00c2ngelo"
        },
        {
            "value": "GEM",
            "icao_code": "FGMY",
            "label": "President Obiang Nguema International Airport , Mengomey\u00e9n"
        },
        {
            "value": "GEO",
            "icao_code": "SYCJ",
            "label": "Cheddi Jagan International Airport , Georgetown"
        },
        {
            "value": "GER",
            "icao_code": "MUNG",
            "label": "Rafael Cabrera Mustelier Airport , Nueva Gerona"
        },
        {
            "value": "GES",
            "icao_code": "RPMR",
            "label": "General Santos International Airport , General Santos"
        },
        {
            "value": "GET",
            "icao_code": "YGEL",
            "label": "Geraldton Airport , Geraldton"
        },
        {
            "value": "GEV",
            "icao_code": "ESNG",
            "label": "G\u00e4llivare Airport , G\u00e4llivare"
        },
        {
            "value": "GEW",
            "icao_code": "AYGC",
            "label": "Gewoia Airport , Gewoia"
        },
        {
            "value": "GEX",
            "icao_code": "YGLG",
            "label": "Geelong Airport , Geelong"
        },
        {
            "value": "GEY",
            "icao_code": "KGEY",
            "label": "South Big Horn County Airport , Greybull"
        },
        {
            "value": "GFD",
            "icao_code": "KGFD",
            "label": "Pope Field , Greenfield"
        },
        {
            "value": "GFE",
            "icao_code": null,
            "label": "Grenfell Airport , Grenfell"
        },
        {
            "value": "GFF",
            "icao_code": "YGTH",
            "label": "Griffith Airport , Griffith"
        },
        {
            "value": "GFK",
            "icao_code": "KGFK",
            "label": "Grand Forks International Airport , Grand Forks"
        },
        {
            "value": "GFL",
            "icao_code": "KGFL",
            "label": "Floyd Bennett Memorial Airport , Glens Falls"
        },
        {
            "value": "GFN",
            "icao_code": "YGFN",
            "label": "Clarence Valley Regional Airport , Grafton"
        },
        {
            "value": "GFO",
            "icao_code": "SYBT",
            "label": "Bartica Airport , Bartica"
        },
        {
            "value": "GFR",
            "icao_code": "LFRF",
            "label": "Granville\u2013Mont-Saint-Michel Aerodrome , Granville"
        },
        {
            "value": "GFY",
            "icao_code": "FYGF",
            "label": "Grootfontein Air Base , Grootfontein"
        },
        {
            "value": "GGB",
            "icao_code": "SWHP",
            "label": "\u00c1gua Boa Airport , \u00c1gua Boa"
        },
        {
            "value": "GGC",
            "icao_code": null,
            "label": "Lumbala N'guimbo Airport , Lumbala N'guimbo"
        },
        {
            "value": "GGD",
            "icao_code": "YGDS",
            "label": "Gregory Downs Airport , Gregory Downs"
        },
        {
            "value": "GGE",
            "icao_code": "KGGE",
            "label": "Georgetown County Airport , Georgetown"
        },
        {
            "value": "GGF",
            "icao_code": "SNYA",
            "label": "Almeirim Airport , Almeirim"
        },
        {
            "value": "GGG",
            "icao_code": "KGGG",
            "label": "East Texas Regional Airport , Longview"
        },
        {
            "value": "GGH",
            "icao_code": "SSCT",
            "label": "Gast\u00e3o Mesquita Airport , Cianorte"
        },
        {
            "value": "GGJ",
            "icao_code": "SSGY",
            "label": "Gua\u00edra Airport , Gua\u00edra"
        },
        {
            "value": "GGL",
            "icao_code": null,
            "label": "Gilgal Airport , Gilgal"
        },
        {
            "value": "GGM",
            "icao_code": "HKKG",
            "label": "Kakamega Airport , Kakamega"
        },
        {
            "value": "GGN",
            "icao_code": "DIGA",
            "label": "Gagnoa Airport , Gagnoa"
        },
        {
            "value": "GGO",
            "icao_code": "DIGL",
            "label": "Guiglo Airport , Guiglo"
        },
        {
            "value": "GGR",
            "icao_code": null,
            "label": "Garowe International Airport , Garowe"
        },
        {
            "value": "GGS",
            "icao_code": "SAWR",
            "label": "Gobernador Gregores Airport , Gobernador Gregores"
        },
        {
            "value": "GGT",
            "icao_code": "MYEF",
            "label": "Exuma International Airport , Moss Town"
        },
        {
            "value": "GGW",
            "icao_code": "KGGW",
            "label": "Glasgow Airport , Glasgow"
        },
        {
            "value": "GHA",
            "icao_code": "DAUG",
            "label": "Noum\u00e9rat \u2013 Moufdi Zakaria Airport , Gharda\u00efa"
        },
        {
            "value": "GHB",
            "icao_code": "MYEM",
            "label": "Governor's Harbour Airport , Governor's Harbour"
        },
        {
            "value": "GHC",
            "icao_code": "MYBG",
            "label": "Great Harbour Cay Airport , Great Harbour Cay"
        },
        {
            "value": "GHE",
            "icao_code": null,
            "label": "Garachin\u00e9 Airport , Garachin\u00e9"
        },
        {
            "value": "GHF",
            "icao_code": "ETEU",
            "label": "Giebelstadt Airport , Giebelstadt"
        },
        {
            "value": "GHK",
            "icao_code": null,
            "label": "Gahcho Kue Aerodrome , Kennedy Lake"
        },
        {
            "value": "GHM",
            "icao_code": "KGHM",
            "label": "Centerville Municipal Airport , Centerville"
        },
        {
            "value": "GHN",
            "icao_code": "ZUGH",
            "label": "Guanghan Airport , Guanghan"
        },
        {
            "value": "GHS",
            "icao_code": "WALE",
            "label": "West Kutai Melalan Airport , Melak"
        },
        {
            "value": "GHT",
            "icao_code": "HLGT",
            "label": "Ghat Airport , Ghat"
        },
        {
            "value": "GHU",
            "icao_code": "SAAG",
            "label": "Gualeguaych\u00fa Airport , Gualeguaych\u00fa"
        },
        {
            "value": "GHV",
            "icao_code": "LRBV",
            "label": "Bra\u0219ov-Ghimbav International Airport , Bra\u0219ov"
        },
        {
            "value": "GIB",
            "icao_code": "LXGB",
            "label": "Gibraltar International Airport , British Overseas Territory"
        },
        {
            "value": "GIC",
            "icao_code": "YBOI",
            "label": "Boigu Island Airport , Boigu Island"
        },
        {
            "value": "GID",
            "icao_code": "HBBE",
            "label": "Gitega Airport , Gitega"
        },
        {
            "value": "GIF",
            "icao_code": "KGIF",
            "label": "Winter Haven's Gilbert Airport , Winter Haven"
        },
        {
            "value": "GIG",
            "icao_code": "SBGL",
            "label": "Rio de Janeiro\u2013Gale\u00e3o International Airport , Rio de Janeiro"
        },
        {
            "value": "GII",
            "icao_code": "GUSI",
            "label": "Siguiri Airport , Siguiri"
        },
        {
            "value": "GIL",
            "icao_code": "OPGT",
            "label": "Gilgit Airport , Gilgit"
        },
        {
            "value": "GIM",
            "icao_code": null,
            "label": "Miele Mimbale Airport , Miele Mimbale"
        },
        {
            "value": "GIR",
            "icao_code": "SKGI",
            "label": "Santiago Vila Airport , Girardot"
        },
        {
            "value": "GIS",
            "icao_code": "NZGS",
            "label": "Gisborne Airport , Gisborne"
        },
        {
            "value": "GIT",
            "icao_code": null,
            "label": "Geita Airport , Geita"
        },
        {
            "value": "GIU",
            "icao_code": "VCCS",
            "label": "Sigiriya Airport , Dambulla"
        },
        {
            "value": "GIY",
            "icao_code": "FAGI",
            "label": "Giyani Airport , Giyani"
        },
        {
            "value": "GIZ",
            "icao_code": "OEGN",
            "label": "Jizan Regional Airport , Jizan"
        },
        {
            "value": "GJA",
            "icao_code": "MHNJ",
            "label": "Guanaja Airport , Guanaja"
        },
        {
            "value": "GJL",
            "icao_code": "DAAV",
            "label": "Jijel Ferhat Abbas Airport , Jijel"
        },
        {
            "value": "GJM",
            "icao_code": "SBGM",
            "label": "Guajar\u00e1-Mirim Airport , Guajar\u00e1-Mirim"
        },
        {
            "value": "GJR",
            "icao_code": "BIGJ",
            "label": "Gj\u00f6gur Airport , Gj\u00f6gur"
        },
        {
            "value": "GJT",
            "icao_code": "KGJT",
            "label": "Grand Junction Regional Airport , Grand Junction"
        },
        {
            "value": "GKA",
            "icao_code": "AYGA",
            "label": "Goroka Airport , Goroka"
        },
        {
            "value": "GKD",
            "icao_code": "LTFK",
            "label": "G\u00f6k\u00e7eada Airport , G\u00f6k\u00e7eada"
        },
        {
            "value": "GKE",
            "icao_code": "ETNG",
            "label": "NATO Air Base Geilenkirchen , Geilenkirchen"
        },
        {
            "value": "GKH",
            "icao_code": "VNGK",
            "label": "Palungtar Airport , Gorkha"
        },
        {
            "value": "GKK",
            "icao_code": "VRMO",
            "label": "Kooddoo Airport , Kooddoo"
        },
        {
            "value": "GKL",
            "icao_code": "YGKL",
            "label": "Great Keppel Island Airport , Great Keppel Island"
        },
        {
            "value": "GKN",
            "icao_code": "PAGK",
            "label": "Gulkana Airport , Gulkana"
        },
        {
            "value": "GKO",
            "icao_code": null,
            "label": "Kongo Boumba Airport , Kongo Boumba"
        },
        {
            "value": "GKT",
            "icao_code": "KGKT",
            "label": "Gatlinburg\u2013Pigeon Forge Airport , Sevierville"
        },
        {
            "value": "GLA",
            "icao_code": "EGPF",
            "label": "Glasgow Airport , Glasgow"
        },
        {
            "value": "GLB",
            "icao_code": null,
            "label": "San Carlos Apache Airport , Globe"
        },
        {
            "value": "GLC",
            "icao_code": null,
            "label": "Geladi Airport , Geladi"
        },
        {
            "value": "GLD",
            "icao_code": "KGLD",
            "label": "Goodland Municipal Airport , Goodland"
        },
        {
            "value": "GLE",
            "icao_code": "KGLE",
            "label": "Gainesville Municipal Airport , Gainesville"
        },
        {
            "value": "GLF",
            "icao_code": "MRGF",
            "label": "Golfito Airport , Golfito"
        },
        {
            "value": "GLG",
            "icao_code": "YGLE",
            "label": "Glengyle Airport , Glengyle"
        },
        {
            "value": "GLH",
            "icao_code": "KGLH",
            "label": "Mid-Delta Regional Airport , Greenville"
        },
        {
            "value": "GLI",
            "icao_code": "YGLI",
            "label": "Glen Innes Airport , Glen Innes"
        },
        {
            "value": "GLK",
            "icao_code": "HCMR",
            "label": "Abdullahi Yusuf International Airport , Galkayo"
        },
        {
            "value": "GLL",
            "icao_code": "ENKL",
            "label": "Gol Airport, Klanten , Gol"
        },
        {
            "value": "GLM",
            "icao_code": "YGLO",
            "label": "Glenormiston Airport , Glenormiston"
        },
        {
            "value": "GLN",
            "icao_code": null,
            "label": "Guelmim Airport , Guelmim"
        },
        {
            "value": "GLO",
            "icao_code": "EGBJ",
            "label": "Gloucestershire Airport , Gloucester"
        },
        {
            "value": "GLP",
            "icao_code": "AYUP",
            "label": "Gulgubip Airport , Gulgubip"
        },
        {
            "value": "GLR",
            "icao_code": "KGLR",
            "label": "Gaylord Regional Airport , Gaylord"
        },
        {
            "value": "GLS",
            "icao_code": "KGLS",
            "label": "Scholes International Airport at Galveston , Galveston"
        },
        {
            "value": "GLT",
            "icao_code": "YGLA",
            "label": "Gladstone Airport , Gladstone"
        },
        {
            "value": "GLU",
            "icao_code": "VQGP",
            "label": "Gelephu Airport , Gelephu"
        },
        {
            "value": "GLV",
            "icao_code": "PAGL",
            "label": "Golovin Airport , Golovin"
        },
        {
            "value": "GLW",
            "icao_code": "KGLW",
            "label": "Glasgow Municipal Airport , Glasgow"
        },
        {
            "value": "GLX",
            "icao_code": "WAMA",
            "label": "Gamar Malamo Airport , Galela"
        },
        {
            "value": "GLY",
            "icao_code": null,
            "label": "Goldsworthy Airport , Goldsworthy"
        },
        {
            "value": "GLZ",
            "icao_code": "EHGR",
            "label": "Gilze-Rijen Air Base , Breda"
        },
        {
            "value": "GMA",
            "icao_code": "FZFK",
            "label": "Gemena Airport , Gemena"
        },
        {
            "value": "GMB",
            "icao_code": "HAGM",
            "label": "Gambela Airport , Gambela"
        },
        {
            "value": "GMC",
            "icao_code": null,
            "label": "Guerima Airport , Guerima"
        },
        {
            "value": "GMD",
            "icao_code": "GMMB",
            "label": "Ben Slimane Airport , Ben Slimane"
        },
        {
            "value": "GME",
            "icao_code": "UMGG",
            "label": "Gomel Airport , Gomel"
        },
        {
            "value": "GMI",
            "icao_code": "AYGT",
            "label": "Gasmata Airport , Gasmata"
        },
        {
            "value": "GMM",
            "icao_code": "FCOG",
            "label": "Gamboma Airport , Gamboma"
        },
        {
            "value": "GMN",
            "icao_code": "NZGM",
            "label": "Greymouth Airport , Greymouth"
        },
        {
            "value": "GMO",
            "icao_code": "DNGO",
            "label": "Gombe Lawanti International Airport , Gombe"
        },
        {
            "value": "GMP",
            "icao_code": "RKSS",
            "label": "Gimpo International Airport , Seoul"
        },
        {
            "value": "GMQ",
            "icao_code": "ZLGL",
            "label": "Golog Maqin Airport , Golog"
        },
        {
            "value": "GMR",
            "icao_code": "NTGJ",
            "label": "Totegegie Airport , Mangareva"
        },
        {
            "value": "GMS",
            "icao_code": "SNGM",
            "label": "Guimar\u00e3es Airport , Guimar\u00e3es"
        },
        {
            "value": "GMT",
            "icao_code": "PAGZ",
            "label": "Granite Mountain Air Station , Granite Mountain"
        },
        {
            "value": "GMU",
            "icao_code": "KGMU",
            "label": "Greenville Downtown Airport , Greenville"
        },
        {
            "value": "GMV",
            "icao_code": null,
            "label": "Monument Valley Airport , Monument Valley"
        },
        {
            "value": "GMZ",
            "icao_code": "GCGM",
            "label": "La Gomera Airport , La Gomera"
        },
        {
            "value": "GNA",
            "icao_code": "UMMG",
            "label": "Grodno Airport , Grodno"
        },
        {
            "value": "GNB",
            "icao_code": "LFLS",
            "label": "Grenoble\u2013Is\u00e8re Airport , Grenoble"
        },
        {
            "value": "GND",
            "icao_code": "TGPY",
            "label": "Maurice Bishop International Airport , St. George's"
        },
        {
            "value": "GNF",
            "icao_code": null,
            "label": "Gansner Field , Quincy"
        },
        {
            "value": "GNG",
            "icao_code": "KGNG",
            "label": "Gooding Municipal Airport , Gooding"
        },
        {
            "value": "GNI",
            "icao_code": "RCGI",
            "label": "Lyudao Airport , Lyudao"
        },
        {
            "value": "GNJ",
            "icao_code": "UBBG",
            "label": "Ganja International Airport , Ganja"
        },
        {
            "value": "GNM",
            "icao_code": "SNGI",
            "label": "Guanambi Airport , Guanambi"
        },
        {
            "value": "GNN",
            "icao_code": "HAGH",
            "label": "Ginir Airport , Ginir"
        },
        {
            "value": "GNR",
            "icao_code": "SAHR",
            "label": "Dr. Arturo Umberto Illia Airport , General Roca"
        },
        {
            "value": "GNS",
            "icao_code": "WIMB",
            "label": "Binaka Airport , Gunungsitoli"
        },
        {
            "value": "GNT",
            "icao_code": "KGNT",
            "label": "Grants-Milan Municipal Airport , Grants"
        },
        {
            "value": "GNU",
            "icao_code": null,
            "label": "Goodnews Airport , Goodnews Bay"
        },
        {
            "value": "GNV",
            "icao_code": "KGNV",
            "label": "Gainesville Regional Airport , Gainesville"
        },
        {
            "value": "GNY",
            "icao_code": "LTCS",
            "label": "\u015eanl\u0131urfa GAP Airport , \u015eanl\u0131urfa"
        },
        {
            "value": "GNZ",
            "icao_code": "FBGZ",
            "label": "Ghanzi Airport , Ghanzi"
        },
        {
            "value": "GOA",
            "icao_code": "LIMJ",
            "label": "Genoa Cristoforo Colombo Airport , Genoa"
        },
        {
            "value": "GOB",
            "icao_code": "HAGB",
            "label": "Robe Airport , Goba"
        },
        {
            "value": "GOC",
            "icao_code": "AYGX",
            "label": "Gora Airport , Gora"
        },
        {
            "value": "GOE",
            "icao_code": null,
            "label": "Gonaili Airstrip , Gonaili"
        },
        {
            "value": "GOG",
            "icao_code": "FYGB",
            "label": "Gobabis Airport , Gobabis"
        },
        {
            "value": "GOH",
            "icao_code": "BGGH",
            "label": "Nuuk Airport , Nuuk"
        },
        {
            "value": "GOI",
            "icao_code": "VOGO",
            "label": "Dabolim Airport , Dabolim"
        },
        {
            "value": "GOJ",
            "icao_code": "UWGG",
            "label": "Nizhny Novgorod International Airport , Nizhny Novgorod"
        },
        {
            "value": "GOK",
            "icao_code": "KGOK",
            "label": "Guthrie\u2013Edmond Regional Airport , Guthrie"
        },
        {
            "value": "GOL",
            "icao_code": null,
            "label": "Gold Beach Municipal Airport , Gold Beach"
        },
        {
            "value": "GOM",
            "icao_code": "FZNA",
            "label": "Goma International Airport , Goma"
        },
        {
            "value": "GON",
            "icao_code": "KGON",
            "label": "Groton\u2013New London Airport , Groton"
        },
        {
            "value": "GOO",
            "icao_code": "YGDI",
            "label": "Goondiwindi Airport , Goondiwindi"
        },
        {
            "value": "GOP",
            "icao_code": "VEGK",
            "label": "Gorakhpur Airport , Gorakhpur"
        },
        {
            "value": "GOQ",
            "icao_code": "ZLGM",
            "label": "Golmud Airport , Golmud"
        },
        {
            "value": "GOR",
            "icao_code": "HAGR",
            "label": "Gore Airport , Gore"
        },
        {
            "value": "GOS",
            "icao_code": null,
            "label": "Gosford Airport , Gosford"
        },
        {
            "value": "GOT",
            "icao_code": "ESGG",
            "label": "G\u00f6teborg Landvetter Airport , Gothenburg"
        },
        {
            "value": "GOU",
            "icao_code": "FKKR",
            "label": "Garoua International Airport , Garoua"
        },
        {
            "value": "GOV",
            "icao_code": "YPGV",
            "label": "Gove Airport , Nhulunbuy"
        },
        {
            "value": "GOX",
            "icao_code": "VOGA",
            "label": "Manohar International Airport , Mopa"
        },
        {
            "value": "GOZ",
            "icao_code": "LBGO",
            "label": "Gorna Oryahovitsa Airport , Gorna Oryahovitsa"
        },
        {
            "value": "GPA",
            "icao_code": "LGRX",
            "label": "Araxos Airport , Patras"
        },
        {
            "value": "GPB",
            "icao_code": "SBGU",
            "label": "Tancredo Thomas de Faria Airport , Guarapuava"
        },
        {
            "value": "GPD",
            "icao_code": "YGON",
            "label": "Mount Gordon Airport , Mount Gordon Mine"
        },
        {
            "value": "GPI",
            "icao_code": "SKGP",
            "label": "Guapi Airport , Guapi"
        },
        {
            "value": "GPL",
            "icao_code": "MRGP",
            "label": "Gu\u00e1piles Airport , Gu\u00e1piles"
        },
        {
            "value": "GPN",
            "icao_code": "YGPT",
            "label": "Garden Point Airport , Pularumpi"
        },
        {
            "value": "GPO",
            "icao_code": "SAZG",
            "label": "General Pico Airport , General Pico"
        },
        {
            "value": "GPS",
            "icao_code": "SEGS",
            "label": "Seymour Airport , Baltra Island"
        },
        {
            "value": "GPT",
            "icao_code": "KGPT",
            "label": "Gulfport\u2013Biloxi International Airport , Gulfport"
        },
        {
            "value": "GPZ",
            "icao_code": "KGPZ",
            "label": "Grand Rapids\u2013Itasca County Airport , Grand Rapids"
        },
        {
            "value": "GQQ",
            "icao_code": "KGQQ",
            "label": "Galion Municipal Airport , Galion"
        },
        {
            "value": "GRA",
            "icao_code": null,
            "label": "Gamarra Airport , Gamarra"
        },
        {
            "value": "GRB",
            "icao_code": "KGRB",
            "label": "Austin Straubel International Airport , Green Bay"
        },
        {
            "value": "GRC",
            "icao_code": null,
            "label": "Grand Cess Airport , Grand Cess"
        },
        {
            "value": "GRD",
            "icao_code": "KGRD",
            "label": "Greenwood County Airport , Greenwood"
        },
        {
            "value": "GRE",
            "icao_code": "KGRE",
            "label": "Greenville Airport , Greenville"
        },
        {
            "value": "GRF",
            "icao_code": "KGRF",
            "label": "Gray Army Airfield , Tacoma"
        },
        {
            "value": "GRG",
            "icao_code": "OAGZ",
            "label": "Gardez Airport , Gardez"
        },
        {
            "value": "GRH",
            "icao_code": null,
            "label": "Garuahi Airport , Garuahi"
        },
        {
            "value": "GRI",
            "icao_code": "KGRI",
            "label": "Central Nebraska Regional Airport , Grand Island"
        },
        {
            "value": "GRJ",
            "icao_code": "FAGG",
            "label": "George Airport , George"
        },
        {
            "value": "GRK",
            "icao_code": "KGRK",
            "label": "Killeen\u2013Fort Hood Regional Airport , Fort Cavazos"
        },
        {
            "value": "GRL",
            "icao_code": "AYGG",
            "label": "Garasa Airport , Garasa"
        },
        {
            "value": "GRM",
            "icao_code": "KCKC",
            "label": "Grand Marais/Cook County Airport , Grand Marais"
        },
        {
            "value": "GRN",
            "icao_code": "KGRN",
            "label": "Gordon Municipal Airport , Gordon"
        },
        {
            "value": "GRO",
            "icao_code": "LEGE",
            "label": "Girona\u2013Costa Brava Airport , Girona"
        },
        {
            "value": "GRP",
            "icao_code": "SWGI",
            "label": "Gurupi Airport , Gurupi"
        },
        {
            "value": "GRQ",
            "icao_code": "EHGG",
            "label": "Groningen Airport Eelde , Groningen"
        },
        {
            "value": "GRR",
            "icao_code": "KGRR",
            "label": "Gerald R. Ford International Airport , Grand Rapids"
        },
        {
            "value": "GRS",
            "icao_code": "LIRS",
            "label": "Grosseto Airport , Grosseto"
        },
        {
            "value": "GRT",
            "icao_code": null,
            "label": "Gujrat Airport , Gujrat"
        },
        {
            "value": "GRU",
            "icao_code": "SBGR",
            "label": "S\u00e3o Paulo/Guarulhos\u2013Governador Andr\u00e9 Franco Montoro International Airport , S\u00e3o Paulo"
        },
        {
            "value": "GRV",
            "icao_code": "URMG",
            "label": "Grozny Airport , Grozny"
        },
        {
            "value": "GRW",
            "icao_code": "LPGR",
            "label": "Graciosa Airport , Graciosa"
        },
        {
            "value": "GRX",
            "icao_code": "LEGR",
            "label": "Federico Garc\u00eda Lorca Airport , Granada"
        },
        {
            "value": "GRY",
            "icao_code": "BIGR",
            "label": "Gr\u00edmsey Airport , Gr\u00edmsey"
        },
        {
            "value": "GRZ",
            "icao_code": "LOWG",
            "label": "Graz Airport , Graz"
        },
        {
            "value": "GSA",
            "icao_code": "WBKN",
            "label": "Long Pasia Airport , Long Pasia"
        },
        {
            "value": "GSB",
            "icao_code": "KGSB",
            "label": "Seymour Johnson Air Force Base , Goldsboro"
        },
        {
            "value": "GSC",
            "icao_code": "YGSC",
            "label": "Gascoyne Junction Airport , Gascoyne Junction"
        },
        {
            "value": "GSE",
            "icao_code": "ESGP",
            "label": "G\u00f6teborg City Airport , Gothenburg"
        },
        {
            "value": "GSH",
            "icao_code": "KGSH",
            "label": "Goshen Municipal Airport , Goshen"
        },
        {
            "value": "GSI",
            "icao_code": "SOGS",
            "label": "Grand-Santi Airport , Grand-Santi"
        },
        {
            "value": "GSJ",
            "icao_code": "MGSJ",
            "label": "San Jos\u00e9 Airport , Puerto San Jos\u00e9"
        },
        {
            "value": "GSL",
            "icao_code": null,
            "label": "Taltheilei Narrows Airport , Taltheilei Narrows"
        },
        {
            "value": "GSM",
            "icao_code": "OIKQ",
            "label": "Dayrestan Airport , Qeshm"
        },
        {
            "value": "GSN",
            "icao_code": "YMGN",
            "label": "Mount Gunson Airport , Mount Gunson"
        },
        {
            "value": "GSO",
            "icao_code": "KGSO",
            "label": "Piedmont Triad International Airport , Greensboro"
        },
        {
            "value": "GSP",
            "icao_code": "KGSP",
            "label": "Greenville\u2013Spartanburg International Airport , Greenville"
        },
        {
            "value": "GSQ",
            "icao_code": "HEOW",
            "label": "Sharq Al-Owainat Airport , Sharq Al-Owainat"
        },
        {
            "value": "GSR",
            "icao_code": "HCMG",
            "label": "Qardho Airport , Qardho"
        },
        {
            "value": "GSS",
            "icao_code": null,
            "label": "Sabi Sabi Airport , Sabie"
        },
        {
            "value": "GST",
            "icao_code": "PAGS",
            "label": "Gustavus Airport , Gustavus"
        },
        {
            "value": "GSU",
            "icao_code": "HSGF",
            "label": "Azaza Airport , Gedaref"
        },
        {
            "value": "GSV",
            "icao_code": null,
            "label": "Saratov Gagarin Airport , Saratov"
        },
        {
            "value": "GTA",
            "icao_code": "AGOK",
            "label": "Gatokae Aerodrome , Gatokae"
        },
        {
            "value": "GTE",
            "icao_code": "YGTE",
            "label": "Groote Eylandt Airport , Groote Eylandt"
        },
        {
            "value": "GTF",
            "icao_code": "KGTF",
            "label": "Great Falls International Airport , Great Falls"
        },
        {
            "value": "GTG",
            "icao_code": "KGTG",
            "label": "Grantsburg Municipal Airport , Grantsburg"
        },
        {
            "value": "GTI",
            "icao_code": "EDCG",
            "label": "R\u00fcgen Airport , G\u00fcttin"
        },
        {
            "value": "GTN",
            "icao_code": "NZGT",
            "label": "Glentanner Aerodrome , Mount Cook"
        },
        {
            "value": "GTO",
            "icao_code": "WAMG",
            "label": "Jalaluddin Airport , Gorontalo"
        },
        {
            "value": "GTP",
            "icao_code": null,
            "label": "Grants Pass Airport , Grants Pass"
        },
        {
            "value": "GTR",
            "icao_code": "KGTR",
            "label": "Golden Triangle Regional Airport , Columbus"
        },
        {
            "value": "GTS",
            "icao_code": "YTGT",
            "label": "The Granites Airport , The Granites"
        },
        {
            "value": "GTT",
            "icao_code": "YGTN",
            "label": "Georgetown Airport , Georgetown"
        },
        {
            "value": "GTW",
            "icao_code": "LKHO",
            "label": "Hole\u0161ov Airport , Zl\u00edn"
        },
        {
            "value": "GTY",
            "icao_code": null,
            "label": "Gettysburg Regional Airport , Gettysburg"
        },
        {
            "value": "GTZ",
            "icao_code": null,
            "label": "Kirawira B Airstrip , Grumeti"
        },
        {
            "value": "GUA",
            "icao_code": "MGGT",
            "label": "La Aurora International Airport , Guatemala City"
        },
        {
            "value": "GUB",
            "icao_code": "MMGR",
            "label": "Guerrero Negro Airport , Guerrero Negro"
        },
        {
            "value": "GUC",
            "icao_code": "KGUC",
            "label": "Gunnison\u2013Crested Butte Regional Airport , Gunnison"
        },
        {
            "value": "GUD",
            "icao_code": "GAGM",
            "label": "Goundam Airport , Goundam"
        },
        {
            "value": "GUE",
            "icao_code": null,
            "label": "Guriaso Airport , Guriaso"
        },
        {
            "value": "GUF",
            "icao_code": "KJKA",
            "label": "Jack Edwards Airport , Gulf Shores"
        },
        {
            "value": "GUG",
            "icao_code": "AYGF",
            "label": "Guari Airport , Guari"
        },
        {
            "value": "GUH",
            "icao_code": "YGDH",
            "label": "Gunnedah Airport , Gunnedah"
        },
        {
            "value": "GUI",
            "icao_code": "SVGI",
            "label": "G\u00fciria Airport , G\u00fciria"
        },
        {
            "value": "GUJ",
            "icao_code": "SBGW",
            "label": "Guaratinguet\u00e1 Airport , Guaratinguet\u00e1"
        },
        {
            "value": "GUL",
            "icao_code": "YGLB",
            "label": "Goulburn Airport , Goulburn"
        },
        {
            "value": "GUM",
            "icao_code": "PGUM",
            "label": "Antonio B. Won Pat International Airport , Hag\u00e5t\u00f1a"
        },
        {
            "value": "GUP",
            "icao_code": "KGUP",
            "label": "Gallup Municipal Airport , Gallup"
        },
        {
            "value": "GUQ",
            "icao_code": "SVGU",
            "label": "Guanare Airport , Guanare"
        },
        {
            "value": "GUR",
            "icao_code": "AYGN",
            "label": "Gurney Airport , Alotau"
        },
        {
            "value": "GUS",
            "icao_code": "KGUS",
            "label": "Grissom Air Reserve Base , Peru"
        },
        {
            "value": "GUT",
            "icao_code": "ETUO",
            "label": "RAF G\u00fctersloh , G\u00fctersloh"
        },
        {
            "value": "GUU",
            "icao_code": "BIGF",
            "label": "Grundarfj\u00f6r\u00f0ur Airport , Grundarfj\u00f6r\u00f0ur"
        },
        {
            "value": "GUV",
            "icao_code": "AYML",
            "label": "Mougulu Airport , Mougulu"
        },
        {
            "value": "GUW",
            "icao_code": "UATG",
            "label": "Atyrau Airport , Atyrau"
        },
        {
            "value": "GUX",
            "icao_code": "VAGN",
            "label": "Guna Airport , Guna"
        },
        {
            "value": "GUY",
            "icao_code": "KGUY",
            "label": "Guymon Municipal Airport , Guymon"
        },
        {
            "value": "GUZ",
            "icao_code": "SNGA",
            "label": "Guarapari Airport , Guarapari"
        },
        {
            "value": "GVA",
            "icao_code": "LSGG",
            "label": "Geneva Airport , Geneva"
        },
        {
            "value": "GVE",
            "icao_code": "KGVE",
            "label": "Gordonsville Municipal Airport , Gordonsville"
        },
        {
            "value": "GVI",
            "icao_code": "AYGV",
            "label": "Green River Airport , Green River"
        },
        {
            "value": "GVL",
            "icao_code": "KGVL",
            "label": "Lee Gilmer Memorial Airport , Gainesville"
        },
        {
            "value": "GVN",
            "icao_code": "UHKM",
            "label": "Sovetskaya Gavan Airport , Sovetskaya Gavan"
        },
        {
            "value": "GVP",
            "icao_code": "YGNV",
            "label": "Greenvale Airport , Greenvale"
        },
        {
            "value": "GVR",
            "icao_code": "SBGV",
            "label": "Coronel Altino Machado de Oliveira Airport , Governador Valadares"
        },
        {
            "value": "GVT",
            "icao_code": "KGVT",
            "label": "Majors Airport , Greenville"
        },
        {
            "value": "GVX",
            "icao_code": "ESSK",
            "label": "G\u00e4vle-Sandviken Airport , G\u00e4vle"
        },
        {
            "value": "GWA",
            "icao_code": "VYGW",
            "label": "Gwa Airport , Gwa"
        },
        {
            "value": "GWD",
            "icao_code": "OPGD",
            "label": "Gwadar International Airport , Gwadar"
        },
        {
            "value": "GWE",
            "icao_code": "FVTL",
            "label": "Thornhill Air Base , Gweru"
        },
        {
            "value": "GWL",
            "icao_code": "VIGR",
            "label": "Rajmata Vijaya Raje Scindia Airport , Gwalior"
        },
        {
            "value": "GWN",
            "icao_code": null,
            "label": "Gnarowein Airport , Gnarowein"
        },
        {
            "value": "GWO",
            "icao_code": "KGWO",
            "label": "Greenwood\u2013Leflore Airport , Greenwood"
        },
        {
            "value": "GWS",
            "icao_code": "KGWS",
            "label": "Glenwood Springs Municipal Airport , Glenwood Springs"
        },
        {
            "value": "GWT",
            "icao_code": "EDXW",
            "label": "Sylt Airport , Sylt"
        },
        {
            "value": "GWV",
            "icao_code": null,
            "label": "Glendale Fokker Field , Glen Dale"
        },
        {
            "value": "GWY",
            "icao_code": "EICM",
            "label": "Galway Airport , Galway"
        },
        {
            "value": "GXA",
            "icao_code": "WAOM",
            "label": "Beringin Airport , North Barito"
        },
        {
            "value": "GXF",
            "icao_code": "OYSY",
            "label": "Sayun Airport , Sayun"
        },
        {
            "value": "GXG",
            "icao_code": "FNNG",
            "label": "Negage Airport , Negage"
        },
        {
            "value": "GXH",
            "icao_code": "ZLXH",
            "label": "Gannan Xiahe Airport , Xiahe"
        },
        {
            "value": "GXQ",
            "icao_code": "SCCY",
            "label": "Teniente Vidal Airfield , Coyhaique"
        },
        {
            "value": "GXX",
            "icao_code": "FKKJ",
            "label": "Yagoua Airport , Yagoua"
        },
        {
            "value": "GXY",
            "icao_code": "KGXY",
            "label": "Greeley\u2013Weld County Airport , Greeley"
        },
        {
            "value": "GYA",
            "icao_code": "SLGY",
            "label": "Guayaramer\u00edn Airport , Guayaramer\u00edn"
        },
        {
            "value": "GYD",
            "icao_code": "UBBB",
            "label": "Heydar Aliyev International Airport , Baku"
        },
        {
            "value": "GYE",
            "icao_code": "SEGU",
            "label": "Jos\u00e9 Joaqu\u00edn de Olmedo International Airport , Guayaquil"
        },
        {
            "value": "GYG",
            "icao_code": "UEMM",
            "label": "Magan Airport , Magan"
        },
        {
            "value": "GYI",
            "icao_code": "HRYG",
            "label": "Gisenyi Airport , Gisenyi"
        },
        {
            "value": "GYL",
            "icao_code": "YARG",
            "label": "Argyle Airport , Argyle"
        },
        {
            "value": "GYM",
            "icao_code": "MMGM",
            "label": "General Jos\u00e9 Mar\u00eda Y\u00e1\u00f1ez International Airport , Guaymas"
        },
        {
            "value": "GYN",
            "icao_code": "SBGO",
            "label": "Santa Genoveva Airport , Goi\u00e2nia"
        },
        {
            "value": "GYP",
            "icao_code": "YGYM",
            "label": "Gympie Airport , Gympie"
        },
        {
            "value": "GYR",
            "icao_code": "KGYR",
            "label": "Phoenix Goodyear Airport , Goodyear"
        },
        {
            "value": "GYS",
            "icao_code": "ZUGU",
            "label": "Guangyuan Panlong Airport , Guangyuan"
        },
        {
            "value": "GYU",
            "icao_code": "ZLGY",
            "label": "Guyuan Liupanshan Airport , Guyuan"
        },
        {
            "value": "GZA",
            "icao_code": "LVGZ",
            "label": "Yasser Arafat International Airport , Gaza"
        },
        {
            "value": "GZG",
            "icao_code": "ZUGZ",
            "label": "Garze Gesar Airport , Garz\u00ea County"
        },
        {
            "value": "GZI",
            "icao_code": "OAGN",
            "label": "Ghazni Airport , Ghazni"
        },
        {
            "value": "GZO",
            "icao_code": "AGGN",
            "label": "Nusatupe Airport , Gizo"
        },
        {
            "value": "GZP",
            "icao_code": "LTFG",
            "label": "Gazipa\u015fa\u2013Alanya Airport , Gazipa\u015fa"
        },
        {
            "value": "GZT",
            "icao_code": "LTAJ",
            "label": "Gaziantep O\u011fuzeli International Airport , Gaziantep"
        },
        {
            "value": "GZW",
            "icao_code": "OIIK",
            "label": "Qazvin Airport , Qazvin"
        },
        {
            "value": "HAA",
            "icao_code": "ENHK",
            "label": "Hasvik Airport , Hasvik"
        },
        {
            "value": "HAB",
            "icao_code": "KHAB",
            "label": "Marion County \u2013 Rankin Fite Airport , Hamilton"
        },
        {
            "value": "HAC",
            "icao_code": "RJTH",
            "label": "Hachijojima Airport , Hachij\u014d-jima"
        },
        {
            "value": "HAD",
            "icao_code": "ESMT",
            "label": "Halmstad Airport , Halmstad"
        },
        {
            "value": "HAF",
            "icao_code": "KHAF",
            "label": "Half Moon Bay Airport , Half Moon Bay"
        },
        {
            "value": "HAH",
            "icao_code": "FMCH",
            "label": "Prince Said Ibrahim International Airport , Moroni"
        },
        {
            "value": "HAI",
            "icao_code": "KHAI",
            "label": "Three Rivers Municipal Airport , Three Rivers"
        },
        {
            "value": "HAJ",
            "icao_code": "EDDV",
            "label": "Hannover Airport , Hanover"
        },
        {
            "value": "HAK",
            "icao_code": "ZJHK",
            "label": "Haikou Meilan International Airport , Haikou"
        },
        {
            "value": "HAL",
            "icao_code": "FYHI",
            "label": "Halali Airport , Halali"
        },
        {
            "value": "HAM",
            "icao_code": "EDDH",
            "label": "Hamburg Airport , Hamburg"
        },
        {
            "value": "HAN",
            "icao_code": "VVNB",
            "label": "Noi Bai International Airport , Hanoi"
        },
        {
            "value": "HAO",
            "icao_code": "KHAO",
            "label": "Butler County Regional Airport , Hamilton"
        },
        {
            "value": "HAQ",
            "icao_code": "VRMH",
            "label": "Hanimaadhoo International Airport , Hanimaadhoo"
        },
        {
            "value": "HAR",
            "icao_code": "KCXY",
            "label": "Capital City Airport , Harrisburg"
        },
        {
            "value": "HAS",
            "icao_code": "OEHL",
            "label": "Ha'il Regional Airport , Ha'il"
        },
        {
            "value": "HAT",
            "icao_code": "YHTL",
            "label": "Heathlands Airport , Heathlands"
        },
        {
            "value": "HAU",
            "icao_code": "ENHD",
            "label": "Haugesund Airport, Karm\u00f8y , Haugesund"
        },
        {
            "value": "HAV",
            "icao_code": "MUHA",
            "label": "Jos\u00e9 Mart\u00ed International Airport , Havana"
        },
        {
            "value": "HAW",
            "icao_code": "EGFE",
            "label": "Haverfordwest Aerodrome , Haverfordwest"
        },
        {
            "value": "HAY",
            "icao_code": null,
            "label": "Hacaritama Airport , Aguachica"
        },
        {
            "value": "HAZ",
            "icao_code": null,
            "label": "Hatzfeldthaven Airport , Hatzfeldthaven"
        },
        {
            "value": "HBA",
            "icao_code": "YMHB",
            "label": "Hobart International Airport , Hobart"
        },
        {
            "value": "HBB",
            "icao_code": null,
            "label": "Hobbs Industrial Airpark , Hobbs"
        },
        {
            "value": "HBD",
            "icao_code": null,
            "label": "Habi Airport , Habi"
        },
        {
            "value": "HBE",
            "icao_code": "HEBA",
            "label": "Borg El Arab Airport , Alexandria"
        },
        {
            "value": "HBG",
            "icao_code": "KHBG",
            "label": "Hattiesburg Bobby L. Chain Municipal Airport , Hattiesburg"
        },
        {
            "value": "HBH",
            "icao_code": null,
            "label": "Entrance Island Seaplane Base , Entrance Island"
        },
        {
            "value": "HBK",
            "icao_code": null,
            "label": "Holbrook Municipal Airport , Holbrook"
        },
        {
            "value": "HBQ",
            "icao_code": null,
            "label": "Qilian Airport , Qilian"
        },
        {
            "value": "HBR",
            "icao_code": "KHBR",
            "label": "Hobart Regional Airport , Hobart"
        },
        {
            "value": "HBT",
            "icao_code": null,
            "label": "Hambantota Sea Plane Base , Hambantota"
        },
        {
            "value": "HBU",
            "icao_code": "ZMBS",
            "label": "Bulgan Airport, Khovd , Bulgan (Khovd Province)"
        },
        {
            "value": "HBX",
            "icao_code": "VAHB",
            "label": "Hubli Airport , Hubli"
        },
        {
            "value": "HCA",
            "icao_code": "KBPG",
            "label": "Big Spring McMahon\u2013Wrinkle Airport , Big Spring"
        },
        {
            "value": "HCC",
            "icao_code": null,
            "label": "Columbia County Airport , Hudson"
        },
        {
            "value": "HCJ",
            "icao_code": "ZGHC",
            "label": "Hechi Jinchengjiang Airport , Hechi"
        },
        {
            "value": "HCM",
            "icao_code": "HCME",
            "label": "Eyl Airport , Eyl"
        },
        {
            "value": "HCN",
            "icao_code": "RCKW",
            "label": "Hengchun Airport , Hengchun"
        },
        {
            "value": "HCQ",
            "icao_code": "YHLC",
            "label": "Halls Creek Airport , Halls Creek"
        },
        {
            "value": "HCR",
            "icao_code": "PAHC",
            "label": "Holy Cross Airport , Holy Cross"
        },
        {
            "value": "HCW",
            "icao_code": "KCQW",
            "label": "Cheraw Municipal Airport , Cheraw"
        },
        {
            "value": "HCZ",
            "icao_code": "ZGCZ",
            "label": "Chenzhou Beihu Airport , Chenzhou"
        },
        {
            "value": "HDB",
            "icao_code": "EDIU",
            "label": "Heidelberg Airport , Heidelberg"
        },
        {
            "value": "HDD",
            "icao_code": "OPKD",
            "label": "Hyderabad Airport , Hyderabad"
        },
        {
            "value": "HDE",
            "icao_code": "KHDE",
            "label": "Brewster Field , Holdrege"
        },
        {
            "value": "HDF",
            "icao_code": "EDAH",
            "label": "Heringsdorf Airport , Heringsdorf"
        },
        {
            "value": "HDG",
            "icao_code": "ZBHD",
            "label": "Handan Airport , Handan"
        },
        {
            "value": "HDH",
            "icao_code": "PHDH",
            "label": "Dillingham Airfield , Mokul\u0113ia"
        },
        {
            "value": "HDK",
            "icao_code": "VRBK",
            "label": "Kulhudhuffushi Airport , Haa Dhaalu Atoll"
        },
        {
            "value": "HDM",
            "icao_code": "OIHH",
            "label": "Hamadan Airport , Hamadan"
        },
        {
            "value": "HDN",
            "icao_code": "KHDN",
            "label": "Yampa Valley Airport , Hayden"
        },
        {
            "value": "HDR",
            "icao_code": "OIKP",
            "label": "Havadarya Airport , Bandar Abbas"
        },
        {
            "value": "HDS",
            "icao_code": "FAHS",
            "label": "Air Force Base Hoedspruit , Hoedspruit"
        },
        {
            "value": "HDY",
            "icao_code": "VTSS",
            "label": "Hat Yai International Airport , Hat Yai"
        },
        {
            "value": "HEA",
            "icao_code": "OAHR",
            "label": "Herat International Airport , Herat"
        },
        {
            "value": "HEB",
            "icao_code": "VBHD",
            "label": "Hinthada Airport , Hinthada"
        },
        {
            "value": "HED",
            "icao_code": null,
            "label": "Herendeen Bay Airport , Herendeen Bay"
        },
        {
            "value": "HEE",
            "icao_code": "KHEE",
            "label": "Thompson\u2013Robbins Airport , Helena-West Helena"
        },
        {
            "value": "HEH",
            "icao_code": "VYHH",
            "label": "Heho Airport , Heho"
        },
        {
            "value": "HEI",
            "icao_code": "EDXB",
            "label": "Heide\u2013B\u00fcsum Airport , Heide"
        },
        {
            "value": "HEK",
            "icao_code": "ZYHE",
            "label": "Heihe Airport , Heihe"
        },
        {
            "value": "HEL",
            "icao_code": "EFHK",
            "label": "Helsinki Airport , Helsinki"
        },
        {
            "value": "HEM",
            "icao_code": "EFHF",
            "label": "Helsinki-Malmi Airport , Helsinki"
        },
        {
            "value": "HEO",
            "icao_code": null,
            "label": "Haelogo Airport , Haelogo"
        },
        {
            "value": "HER",
            "icao_code": "LGIR",
            "label": "Heraklion International Airport , Heraklion"
        },
        {
            "value": "HES",
            "icao_code": "KHRI",
            "label": "Hermiston Municipal Airport , Hermiston"
        },
        {
            "value": "HET",
            "icao_code": "ZBHH",
            "label": "Hohhot Baita International Airport , Hohhot"
        },
        {
            "value": "HEZ",
            "icao_code": "KHEZ",
            "label": "Natchez\u2013Adams County Airport , Natchez"
        },
        {
            "value": "HFA",
            "icao_code": "LLHA",
            "label": "Haifa Airport , Haifa"
        },
        {
            "value": "HFD",
            "icao_code": "KHFD",
            "label": "Hartford\u2013Brainard Airport , Hartford"
        },
        {
            "value": "HFE",
            "icao_code": "ZSOF",
            "label": "Hefei Xinqiao International Airport , Hefei"
        },
        {
            "value": "HFF",
            "icao_code": "KHFF",
            "label": "Mackall Army Airfield , Hoffman"
        },
        {
            "value": "HFN",
            "icao_code": "BIHN",
            "label": "Hornafj\u00f6r\u00f0ur Airport , H\u00f6fn"
        },
        {
            "value": "HFS",
            "icao_code": "ESOH",
            "label": "Hagfors Airport , Hagfors"
        },
        {
            "value": "HFT",
            "icao_code": "ENHF",
            "label": "Hammerfest Airport , Hammerfest"
        },
        {
            "value": "HGA",
            "icao_code": "HCMH",
            "label": "Hargeisa International Airport , Hargeisa"
        },
        {
            "value": "HGD",
            "icao_code": "YHUG",
            "label": "Hughenden Airport , Hughenden"
        },
        {
            "value": "HGE",
            "icao_code": "SVHG",
            "label": "Higuerote Airport , Higuerote"
        },
        {
            "value": "HGH",
            "icao_code": "ZSHC",
            "label": "Hangzhou Xiaoshan International Airport , Hangzhou"
        },
        {
            "value": "HGI",
            "icao_code": "HSFA",
            "label": "Paloich Airport , Higlieg"
        },
        {
            "value": "HGL",
            "icao_code": "EDXH",
            "label": "Heligoland Airfield , Heligoland"
        },
        {
            "value": "HGN",
            "icao_code": "VTCH",
            "label": "Mae Hong Son Airport , Mae Hong Son"
        },
        {
            "value": "HGO",
            "icao_code": "DIKO",
            "label": "Korhogo Airport , Korhogo"
        },
        {
            "value": "HGR",
            "icao_code": "KHGR",
            "label": "Hagerstown Regional Airport , Hagerstown"
        },
        {
            "value": "HGS",
            "icao_code": "GFHA",
            "label": "Hastings Airport , Freetown"
        },
        {
            "value": "HGU",
            "icao_code": "AYMH",
            "label": "Mount Hagen Airport , Mount Hagen"
        },
        {
            "value": "HGZ",
            "icao_code": null,
            "label": "Hog River Airport , Hogatza"
        },
        {
            "value": "HHE",
            "icao_code": "RJSH",
            "label": "JMSDF Hachinohe Air Base , Hachinohe"
        },
        {
            "value": "HHH",
            "icao_code": "KHXD",
            "label": "Hilton Head Airport , Hilton Head Island"
        },
        {
            "value": "HHI",
            "icao_code": "PHHI",
            "label": "Wheeler Army Airfield , Wahiawa"
        },
        {
            "value": "HHN",
            "icao_code": "EDFH",
            "label": "Frankfurt\u2013Hahn Airport , Hahn"
        },
        {
            "value": "HHQ",
            "icao_code": "VTPH",
            "label": "Hua Hin Airport , Hua Hin"
        },
        {
            "value": "HHR",
            "icao_code": "KHHR",
            "label": "Hawthorne Municipal Airport , Hawthorne"
        },
        {
            "value": "HHZ",
            "icao_code": "NTGH",
            "label": "Hikueru Airport , Hikueru Atoll"
        },
        {
            "value": "HIA",
            "icao_code": "ZSSH",
            "label": "Huai'an Lianshui Airport , Huai'an"
        },
        {
            "value": "HIB",
            "icao_code": "KHIB",
            "label": "Range Regional Airport , Hibbing"
        },
        {
            "value": "HID",
            "icao_code": "YHID",
            "label": "Horn Island Airport , Horn Island"
        },
        {
            "value": "HIE",
            "icao_code": "KHIE",
            "label": "Mount Washington Regional Airport , Whitefield"
        },
        {
            "value": "HIF",
            "icao_code": "KHIF",
            "label": "Hill Air Force Base , Ogden"
        },
        {
            "value": "HIG",
            "icao_code": "YHHY",
            "label": "Highbury Airport , Highbury"
        },
        {
            "value": "HII",
            "icao_code": "KHII",
            "label": "Lake Havasu City Airport , Lake Havasu City"
        },
        {
            "value": "HIJ",
            "icao_code": "RJOA",
            "label": "Hiroshima Airport , Hiroshima"
        },
        {
            "value": "HIL",
            "icao_code": "HASL",
            "label": "Shilavo Airport , Shilavo"
        },
        {
            "value": "HIM",
            "icao_code": "VCCH",
            "label": "Hingurakgoda Airport , Hingurakgoda"
        },
        {
            "value": "HIN",
            "icao_code": "RKPS",
            "label": "Sacheon Airport , Jinju"
        },
        {
            "value": "HIO",
            "icao_code": "KHIO",
            "label": "Hillsboro Airport , Hillsboro"
        },
        {
            "value": "HIP",
            "icao_code": "YHDY",
            "label": "Headingly Airport , Headingly"
        },
        {
            "value": "HIR",
            "icao_code": "AGGH",
            "label": "Honiara International Airport , Honiara"
        },
        {
            "value": "HIT",
            "icao_code": "AYHO",
            "label": "Haivaro Airport , Haivaro"
        },
        {
            "value": "HIW",
            "icao_code": "RJBH",
            "label": "Hiroshima\u2013Nishi Airport , Hiroshima"
        },
        {
            "value": "HJJ",
            "icao_code": "ZGCJ",
            "label": "Huaihua Zhijiang Airport , Huaihua"
        },
        {
            "value": "HJR",
            "icao_code": "VAKJ",
            "label": "Civil Aerodrome Khajuraho , Khajuraho"
        },
        {
            "value": "HJT",
            "icao_code": "ZMHU",
            "label": "Khujirt Airport , Khujirt"
        },
        {
            "value": "HKA",
            "icao_code": "KHKA",
            "label": "Blytheville Municipal Airport , Blytheville"
        },
        {
            "value": "HKB",
            "icao_code": null,
            "label": "Healy Lake Airport , Healy Lake"
        },
        {
            "value": "HKD",
            "icao_code": "RJCH",
            "label": "Hakodate Airport , Hakodate"
        },
        {
            "value": "HKG",
            "icao_code": "VHHH",
            "label": "Hong Kong International Airport , Hong Kong"
        },
        {
            "value": "HKK",
            "icao_code": "NZHK",
            "label": "Hokitika Airport , Hokitika"
        },
        {
            "value": "HKN",
            "icao_code": "AYHK",
            "label": "Hoskins Airport , Kimbe"
        },
        {
            "value": "HKS",
            "icao_code": "KHKS",
            "label": "Hawkins Field , Jackson"
        },
        {
            "value": "HKT",
            "icao_code": "VTSP",
            "label": "Phuket International Airport , Phuket"
        },
        {
            "value": "HKV",
            "icao_code": "LBHS",
            "label": "Haskovo Malevo Airport , Haskovo"
        },
        {
            "value": "HKY",
            "icao_code": "KHKY",
            "label": "Hickory Regional Airport , Hickory"
        },
        {
            "value": "HLA",
            "icao_code": "FALA",
            "label": "Lanseria International Airport , Johannesburg"
        },
        {
            "value": "HLB",
            "icao_code": "KHLB",
            "label": "Hillenbrand Industries Airport , Batesville"
        },
        {
            "value": "HLC",
            "icao_code": "KHLC",
            "label": "Hill City Municipal Airport , Hill City"
        },
        {
            "value": "HLD",
            "icao_code": "ZBLA",
            "label": "Hulunbuir Hailar Airport , Hailar"
        },
        {
            "value": "HLE",
            "icao_code": "FHSH",
            "label": "Saint Helena Airport , Jamestown"
        },
        {
            "value": "HLF",
            "icao_code": "ESSF",
            "label": "Hultsfred-Vimmerby Airport , Hultsfred"
        },
        {
            "value": "HLG",
            "icao_code": "KHLG",
            "label": "Wheeling Ohio County Airport , Wheeling"
        },
        {
            "value": "HLH",
            "icao_code": "ZBUL",
            "label": "Ulanhot Airport , Ulanhot"
        },
        {
            "value": "HLI",
            "icao_code": "KCVH",
            "label": "Hollister Municipal Airport , Hollister"
        },
        {
            "value": "HLL",
            "icao_code": "YHIL",
            "label": "Hillside Airport , Hillside"
        },
        {
            "value": "HLM",
            "icao_code": "KHLM",
            "label": "Park Township Airport , Holland"
        },
        {
            "value": "HLN",
            "icao_code": "KHLN",
            "label": "Helena Regional Airport , Helena"
        },
        {
            "value": "HLP",
            "icao_code": "WIHH",
            "label": "Halim Perdanakusuma International Airport , Jakarta"
        },
        {
            "value": "HLR",
            "icao_code": "KHLR",
            "label": "Yoakum\u2013DeFrenn Army Heliport , Fort Cavazos"
        },
        {
            "value": "HLS",
            "icao_code": "YSTH",
            "label": "St Helens Airport , St Helens"
        },
        {
            "value": "HLT",
            "icao_code": "YHML",
            "label": "Hamilton Airport , Hamilton"
        },
        {
            "value": "HLV",
            "icao_code": null,
            "label": "Helenvale Airport , Helenvale"
        },
        {
            "value": "HLW",
            "icao_code": "FAHL",
            "label": "Hluhluwe Airport , Hluhluwe"
        },
        {
            "value": "HLZ",
            "icao_code": "NZHN",
            "label": "Hamilton Airport , Hamilton"
        },
        {
            "value": "HMA",
            "icao_code": "USHH",
            "label": "Khanty-Mansiysk Airport , Khanty-Mansiysk"
        },
        {
            "value": "HMB",
            "icao_code": "HEMK",
            "label": "Sohag International Airport , Sohag"
        },
        {
            "value": "HME",
            "icao_code": "DAUH",
            "label": "Oued Irara\u2013Krim Belkacem Airport , Hassi Messaoud"
        },
        {
            "value": "HMG",
            "icao_code": "YHMB",
            "label": "Hermannsburg Airport , Hermannsburg"
        },
        {
            "value": "HMI",
            "icao_code": "ZWHM",
            "label": "Hami Airport , Hami"
        },
        {
            "value": "HMJ",
            "icao_code": "UKLH",
            "label": "Khmelnytskyi Airport , Khmelnytsky"
        },
        {
            "value": "HMN",
            "icao_code": "KHMN",
            "label": "Holloman Air Force Base , Alamogordo"
        },
        {
            "value": "HMO",
            "icao_code": "MMHO",
            "label": "General Ignacio Pesqueira Garc\u00eda International Airport , Hermosillo"
        },
        {
            "value": "HMR",
            "icao_code": "ENHA",
            "label": "Hamar Airport, Stafsberg , Hamar"
        },
        {
            "value": "HMT",
            "icao_code": "KHMT",
            "label": "Hemet-Ryan Airport , Hemet"
        },
        {
            "value": "HMV",
            "icao_code": "ESUT",
            "label": "Hemavan T\u00e4rnaby Airport , Hemavan"
        },
        {
            "value": "HMY",
            "icao_code": "RKTP",
            "label": "Seosan Air Base , Seosan"
        },
        {
            "value": "HNA",
            "icao_code": "RJSI",
            "label": "Hanamaki Airport , Hanamaki"
        },
        {
            "value": "HNB",
            "icao_code": "KHNB",
            "label": "Huntingburg Airport , Huntingburg"
        },
        {
            "value": "HNC",
            "icao_code": "KHSE",
            "label": "Billy Mitchell Airport , Hatteras"
        },
        {
            "value": "HND",
            "icao_code": "RJTT",
            "label": "Haneda Airport , Tokyo"
        },
        {
            "value": "HNH",
            "icao_code": "PAOH",
            "label": "Hoonah Airport , Hoonah"
        },
        {
            "value": "HNI",
            "icao_code": null,
            "label": "Heiweni Airport , Heiweni"
        },
        {
            "value": "HNL",
            "icao_code": "PHNL",
            "label": "Honolulu International Airport , Honolulu"
        },
        {
            "value": "HNM",
            "icao_code": "PHHN",
            "label": "Hana Airport , Hana"
        },
        {
            "value": "HNN",
            "icao_code": null,
            "label": "Honinabi Airport , Honinabi"
        },
        {
            "value": "HNS",
            "icao_code": "PAHN",
            "label": "Haines Airport , Haines"
        },
        {
            "value": "HNY",
            "icao_code": "ZGHY",
            "label": "Hengyang Nanyue Airport , Hengyang"
        },
        {
            "value": "HOA",
            "icao_code": "HKHO",
            "label": "Hola Airport , Hola"
        },
        {
            "value": "HOB",
            "icao_code": "KHOB",
            "label": "Lea County Regional Airport , Hobbs"
        },
        {
            "value": "HOC",
            "icao_code": null,
            "label": "Komako Airport , Komako"
        },
        {
            "value": "HOD",
            "icao_code": "OYHD",
            "label": "Hodeida International Airport , Al Hudaydah"
        },
        {
            "value": "HOE",
            "icao_code": "VLHS",
            "label": "Ban Huoeisay Airport , Ban Houayxay"
        },
        {
            "value": "HOF",
            "icao_code": "OEAH",
            "label": "Al-Ahsa International Airport , Hofuf"
        },
        {
            "value": "HOG",
            "icao_code": "MUHG",
            "label": "Frank Pa\u00eds Airport , Holgu\u00edn"
        },
        {
            "value": "HOH",
            "icao_code": "LOIH",
            "label": "Hohenems-Dornbirn Airport , Dornbirn"
        },
        {
            "value": "HOI",
            "icao_code": "NTTO",
            "label": "Hao Airport , Hao"
        },
        {
            "value": "HOK",
            "icao_code": "YHOO",
            "label": "Hooker Creek Airport , Lajamanu"
        },
        {
            "value": "HOM",
            "icao_code": "PAHO",
            "label": "Homer Airport , Homer"
        },
        {
            "value": "HON",
            "icao_code": "KHON",
            "label": "Huron Regional Airport , Huron"
        },
        {
            "value": "HOO",
            "icao_code": null,
            "label": "Nhon Co Airport , Quang Duc"
        },
        {
            "value": "HOP",
            "icao_code": "KHOP",
            "label": "Campbell Army Airfield , Fort Campbell"
        },
        {
            "value": "HOQ",
            "icao_code": "EDQM",
            "label": "Hof\u2013Plauen Airport , Hof"
        },
        {
            "value": "HOR",
            "icao_code": "LPHR",
            "label": "Horta Airport , Horta"
        },
        {
            "value": "HOS",
            "icao_code": "SAHC",
            "label": "Chos Malal Airport , Chos Malal"
        },
        {
            "value": "HOT",
            "icao_code": "KHOT",
            "label": "Memorial Field Airport , Hot Springs"
        },
        {
            "value": "HOU",
            "icao_code": "KHOU",
            "label": "William P. Hobby Airport , Houston"
        },
        {
            "value": "HOV",
            "icao_code": "ENOV",
            "label": "\u00d8rsta\u2013Volda Airport, Hovden , \u00d8rsta"
        },
        {
            "value": "HOX",
            "icao_code": "VYHL",
            "label": "Homalin Airport , Homalin"
        },
        {
            "value": "HOY",
            "icao_code": null,
            "label": "Hoy Island Airport , Longhope"
        },
        {
            "value": "HPA",
            "icao_code": "NFTL",
            "label": "Lifuka Island Airport , Lifuka"
        },
        {
            "value": "HPB",
            "icao_code": "PAHP",
            "label": "Hooper Bay Airport , Hooper Bay"
        },
        {
            "value": "HPE",
            "icao_code": null,
            "label": "Hopevale Airport , Hopevale"
        },
        {
            "value": "HPG",
            "icao_code": "ZHSN",
            "label": "Shennongjia Hongping Airport , Shennongjia"
        },
        {
            "value": "HPH",
            "icao_code": "VVCI",
            "label": "Cat Bi International Airport , Haiphong"
        },
        {
            "value": "HPN",
            "icao_code": "KHPN",
            "label": "Westchester County Airport , White Plains"
        },
        {
            "value": "HPT",
            "icao_code": "KHPT",
            "label": "Hampton Municipal Airport , Hampton"
        },
        {
            "value": "HPV",
            "icao_code": null,
            "label": "Princeville Airport , Hanalei"
        },
        {
            "value": "HPY",
            "icao_code": "KHPY",
            "label": "Baytown Airport , Baytown"
        },
        {
            "value": "HQM",
            "icao_code": "KHQM",
            "label": "Bowerman Airport , Hoquiam"
        },
        {
            "value": "HRB",
            "icao_code": "ZYHB",
            "label": "Harbin Taiping International Airport , Harbin"
        },
        {
            "value": "HRC",
            "icao_code": null,
            "label": "Zhayrem Airport , Zhayrem"
        },
        {
            "value": "HRE",
            "icao_code": "FVHA",
            "label": "Harare International Airport , Harare"
        },
        {
            "value": "HRG",
            "icao_code": "HEGN",
            "label": "Hurghada International Airport , Hurghada"
        },
        {
            "value": "HRI",
            "icao_code": "VCRI",
            "label": "Mattala Rajapaksa International Airport , Hambantota"
        },
        {
            "value": "HRK",
            "icao_code": "UKHH",
            "label": "Kharkiv International Airport , Kharkiv"
        },
        {
            "value": "HRL",
            "icao_code": "KHRL",
            "label": "Valley International Airport , Harlingen"
        },
        {
            "value": "HRM",
            "icao_code": "DAFH",
            "label": "Hassi R'Mel Airport , Hassi R'Mel"
        },
        {
            "value": "HRO",
            "icao_code": "KHRO",
            "label": "Boone County Airport , Harrison"
        },
        {
            "value": "HRR",
            "icao_code": null,
            "label": "La Herrera Airport , La Herrera"
        },
        {
            "value": "HRS",
            "icao_code": "FAHR",
            "label": "Harrismith Airport , Harrismith"
        },
        {
            "value": "HRT",
            "icao_code": "EGXU",
            "label": "RAF Linton-on-Ouse , Linton-on-Ouse"
        },
        {
            "value": "HRY",
            "icao_code": "YHBY",
            "label": "Henbury Airport , Henbury"
        },
        {
            "value": "HRZ",
            "icao_code": "SSHZ",
            "label": "Horizontina Airport , Horizontina"
        },
        {
            "value": "HSA",
            "icao_code": "UAIT",
            "label": "Hazret Sultan International Airport , Turkistan"
        },
        {
            "value": "HSB",
            "icao_code": "KHSB",
            "label": "Harrisburg-Raleigh Airport , Harrisburg"
        },
        {
            "value": "HSC",
            "icao_code": null,
            "label": "Shaoguan Guitou Airport , Shaoguan"
        },
        {
            "value": "HSG",
            "icao_code": "RJFS",
            "label": "Saga Airport , Saga"
        },
        {
            "value": "HSH",
            "icao_code": "KHND",
            "label": "Henderson Executive Airport , Las Vegas"
        },
        {
            "value": "HSI",
            "icao_code": "KHSI",
            "label": "Hastings Municipal Airport , Hastings"
        },
        {
            "value": "HSJ",
            "icao_code": null,
            "label": "Zhengzhou Shangjie Airport , Zhengzhou"
        },
        {
            "value": "HSK",
            "icao_code": "LEHC",
            "label": "Huesca\u2013Pirineos Airport , Huesca"
        },
        {
            "value": "HSL",
            "icao_code": "PAHL",
            "label": "Huslia Airport , Huslia"
        },
        {
            "value": "HSM",
            "icao_code": "YHSM",
            "label": "Horsham Airport , Horsham"
        },
        {
            "value": "HSN",
            "icao_code": "ZSZS",
            "label": "Zhoushan Putuoshan Airport , Zhoushan"
        },
        {
            "value": "HSP",
            "icao_code": "KHSP",
            "label": "Ingalls Field , Hot Springs"
        },
        {
            "value": "HSS",
            "icao_code": "VIHR",
            "label": "Hisar Airport , Hisar"
        },
        {
            "value": "HST",
            "icao_code": "KHST",
            "label": "Homestead Air Reserve Base , Homestead"
        },
        {
            "value": "HSV",
            "icao_code": "KHSV",
            "label": "Huntsville International Airport , Huntsville"
        },
        {
            "value": "HSZ",
            "icao_code": "RCPO",
            "label": "Hsinchu Air Base , Hsinchu"
        },
        {
            "value": "HTA",
            "icao_code": "UIAA",
            "label": "Chita International Airport , Chita"
        },
        {
            "value": "HTG",
            "icao_code": "UOHH",
            "label": "Khatanga Airport , Khatanga"
        },
        {
            "value": "HTH",
            "icao_code": "KHTH",
            "label": "Hawthorne Industrial Airport , Hawthorne"
        },
        {
            "value": "HTI",
            "icao_code": "YBHM",
            "label": "Great Barrier Reef Airport , Hamilton Island"
        },
        {
            "value": "HTL",
            "icao_code": "KHTL",
            "label": "Roscommon County\u2013Blodgett Memorial Airport , Houghton Lake"
        },
        {
            "value": "HTM",
            "icao_code": "ZMHG",
            "label": "Khatgal Airport , Khatgal"
        },
        {
            "value": "HTN",
            "icao_code": "ZWTN",
            "label": "Hotan Airport , Hotan"
        },
        {
            "value": "HTO",
            "icao_code": "KHTO",
            "label": "East Hampton Airport , East Hampton"
        },
        {
            "value": "HTR",
            "icao_code": "RORH",
            "label": "Hateruma Airport , Hateruma"
        },
        {
            "value": "HTS",
            "icao_code": "KHTS",
            "label": "Tri-State Airport , Huntington"
        },
        {
            "value": "HTT",
            "icao_code": null,
            "label": "Huatugou Airport , Huatugou"
        },
        {
            "value": "HTU",
            "icao_code": "YHPN",
            "label": "Hopetoun Airport , Hopetoun"
        },
        {
            "value": "HTV",
            "icao_code": "KUTS",
            "label": "Huntsville Municipal Airport , Huntsville"
        },
        {
            "value": "HTW",
            "icao_code": "KHTW",
            "label": "Lawrence County Airpark , Chesapeake"
        },
        {
            "value": "HTY",
            "icao_code": "LTDA",
            "label": "Hatay Airport , Hatay"
        },
        {
            "value": "HTZ",
            "icao_code": "SKHC",
            "label": "Hato Corozal Airport , Hato Corozal"
        },
        {
            "value": "HUA",
            "icao_code": "KHUA",
            "label": "Redstone Army Airfield , Redstone Arsenal"
        },
        {
            "value": "HUB",
            "icao_code": "YHBR",
            "label": "Humbert River Airport , Humbert River"
        },
        {
            "value": "HUC",
            "icao_code": null,
            "label": "Humacao Airport , Humacao"
        },
        {
            "value": "HUD",
            "icao_code": null,
            "label": "Humboldt Municipal Airport , Humboldt"
        },
        {
            "value": "HUE",
            "icao_code": "HAHU",
            "label": "Humera Airport , Humera"
        },
        {
            "value": "HUF",
            "icao_code": "KHUF",
            "label": "Terre Haute International Airport , Terre Haute"
        },
        {
            "value": "HUG",
            "icao_code": "MGHT",
            "label": "Huehuetenango Airport , Huehuetenango"
        },
        {
            "value": "HUH",
            "icao_code": "NTTH",
            "label": "Huahine \u2013 Fare Airport , Huahine"
        },
        {
            "value": "HUI",
            "icao_code": "VVPB",
            "label": "Phu Bai International Airport , Hu\u1ebf"
        },
        {
            "value": "HUJ",
            "icao_code": "KHHW",
            "label": "Stan Stamper Municipal Airport , Hugo"
        },
        {
            "value": "HUK",
            "icao_code": null,
            "label": "Hukuntsi Airport , Hukuntsi"
        },
        {
            "value": "HUL",
            "icao_code": "KHUL",
            "label": "Houlton International Airport , Houlton"
        },
        {
            "value": "HUM",
            "icao_code": "KHUM",
            "label": "Houma\u2013Terrebonne Airport , Houma"
        },
        {
            "value": "HUN",
            "icao_code": "RCYU",
            "label": "Hualien Airport , Hualien"
        },
        {
            "value": "HUO",
            "icao_code": "ZBHZ",
            "label": "Holingol Huolinhe Airport , Holingol"
        },
        {
            "value": "HUQ",
            "icao_code": "HLON",
            "label": "Hun Airport , Hun"
        },
        {
            "value": "HUS",
            "icao_code": "PAHU",
            "label": "Hughes Airport , Hughes"
        },
        {
            "value": "HUT",
            "icao_code": "KHUT",
            "label": "Hutchinson Municipal Airport , Hutchinson"
        },
        {
            "value": "HUU",
            "icao_code": "SPNC",
            "label": "Alf\u00e9rez FAP David Figueroa Fernandini Airport , Hu\u00e1nuco"
        },
        {
            "value": "HUV",
            "icao_code": "ESNH",
            "label": "Hudiksvall Airport , Hudiksvall"
        },
        {
            "value": "HUW",
            "icao_code": "SWHT",
            "label": "Francisco Correa da Cruz Airport , Humait\u00e1"
        },
        {
            "value": "HUX",
            "icao_code": "MMBT",
            "label": "Bah\u00edas de Huatulco International Airport , Huatulco"
        },
        {
            "value": "HUY",
            "icao_code": "EGNJ",
            "label": "Humberside Airport , Kingston upon Hull"
        },
        {
            "value": "HUZ",
            "icao_code": "ZGHZ",
            "label": "Huizhou Pingtan Airport , Huizhou"
        },
        {
            "value": "HVA",
            "icao_code": "FMNL",
            "label": "Analalava Airport , Analalava"
        },
        {
            "value": "HVB",
            "icao_code": "YHBA",
            "label": "Hervey Bay Airport , Hervey Bay"
        },
        {
            "value": "HVD",
            "icao_code": "ZMKD",
            "label": "Khovd Airport , Khovd"
        },
        {
            "value": "HVE",
            "icao_code": "KHVE",
            "label": "Hanksville Airport , Hanksville"
        },
        {
            "value": "HVG",
            "icao_code": "ENHV",
            "label": "Honningsv\u00e5g Airport, Valan , Honningsv\u00e5g"
        },
        {
            "value": "HVK",
            "icao_code": "BIHK",
            "label": "H\u00f3lmav\u00edk Airport , H\u00f3lmav\u00edk"
        },
        {
            "value": "HVN",
            "icao_code": "KHVN",
            "label": "Tweed New Haven Airport , New Haven"
        },
        {
            "value": "HVR",
            "icao_code": "KHVR",
            "label": "Havre City\u2013County Airport , Havre"
        },
        {
            "value": "HVS",
            "icao_code": "KHVS",
            "label": "Hartsville Regional Airport , Hartsville"
        },
        {
            "value": "HWA",
            "icao_code": null,
            "label": "Hawabango Airport , Hawabango"
        },
        {
            "value": "HWD",
            "icao_code": "KHWD",
            "label": "Hayward Executive Airport , Hayward"
        },
        {
            "value": "HWI",
            "icao_code": null,
            "label": "Hawk Inlet Seaplane Base , Hawk Inlet"
        },
        {
            "value": "HWK",
            "icao_code": "YHAW",
            "label": "Wilpena Pound Airport , Hawker"
        },
        {
            "value": "HWN",
            "icao_code": "FVWN",
            "label": "Hwange National Park Airport , Hwange National Park"
        },
        {
            "value": "HWO",
            "icao_code": "KHWO",
            "label": "North Perry Airport , Hollywood"
        },
        {
            "value": "HXD",
            "icao_code": "ZLDL",
            "label": "Delingha Airport , Delingha"
        },
        {
            "value": "HXX",
            "icao_code": "YHAY",
            "label": "Hay Airport , Hay"
        },
        {
            "value": "HYA",
            "icao_code": "KHYA",
            "label": "Barnstable Municipal Airport , Hyannis"
        },
        {
            "value": "HYC",
            "icao_code": "EGTB",
            "label": "Wycombe Air Park , High Wycombe"
        },
        {
            "value": "HYD",
            "icao_code": "VOHS",
            "label": "Rajiv Gandhi International Airport , Hyderabad"
        },
        {
            "value": "HYF",
            "icao_code": null,
            "label": "Hayfields Airport , Hayfields"
        },
        {
            "value": "HYG",
            "icao_code": "PAHY",
            "label": "Hydaburg Seaplane Base , Hydaburg"
        },
        {
            "value": "HYL",
            "icao_code": null,
            "label": "Hollis Clark Bay Seaplane Base , Hollis"
        },
        {
            "value": "HYN",
            "icao_code": "ZSLQ",
            "label": "Taizhou Luqiao Airport , Taizhou"
        },
        {
            "value": "HYR",
            "icao_code": "KHYR",
            "label": "Sawyer County Airport , Hayward"
        },
        {
            "value": "HYS",
            "icao_code": "KHYS",
            "label": "Hays Regional Airport , Hays"
        },
        {
            "value": "HYV",
            "icao_code": "EFHV",
            "label": "Hyvink\u00e4\u00e4 Airfield , Hyvink\u00e4\u00e4"
        },
        {
            "value": "HZA",
            "icao_code": "ZSHZ",
            "label": "Heze Mudan Airport , Heze"
        },
        {
            "value": "HZB",
            "icao_code": "LFQT",
            "label": "Merville\u2013Calonne Airport , Hazebrouck"
        },
        {
            "value": "HZG",
            "icao_code": "ZLHZ",
            "label": "Hanzhong Chenggu Airport , Hanzhong"
        },
        {
            "value": "HZH",
            "icao_code": "ZUNP",
            "label": "Liping Airport , Liping"
        },
        {
            "value": "HZK",
            "icao_code": "BIHU",
            "label": "H\u00fasav\u00edk Airport , H\u00fasav\u00edk"
        },
        {
            "value": "HZL",
            "icao_code": "KHZL",
            "label": "Hazleton Municipal Airport , Hazleton"
        },
        {
            "value": "HZP",
            "icao_code": "CYNR",
            "label": "Fort MacKay/Horizon Airport , Fort McKay"
        },
        {
            "value": "HZU",
            "icao_code": null,
            "label": "Chengdu Huaizhou Airport , Chengdu"
        },
        {
            "value": "HZV",
            "icao_code": "FAHW",
            "label": "Hazyview Airport , Hazyview"
        },
        {
            "value": "IAA",
            "icao_code": "UOII",
            "label": "Igarka Airport , Igarka"
        },
        {
            "value": "IAB",
            "icao_code": "KIAB",
            "label": "McConnell Air Force Base , Wichita"
        },
        {
            "value": "IAD",
            "icao_code": "KIAD",
            "label": "Washington Dulles International Airport , Washington, D.C."
        },
        {
            "value": "IAG",
            "icao_code": "KIAG",
            "label": "Niagara Falls International Airport , Niagara Falls"
        },
        {
            "value": "IAH",
            "icao_code": "KIAH",
            "label": "George Bush Intercontinental Airport , Houston"
        },
        {
            "value": "IAM",
            "icao_code": "DAUZ",
            "label": "In Amenas Airport , In Amenas"
        },
        {
            "value": "IAN",
            "icao_code": "PAIK",
            "label": "Bob Baker Memorial Airport , Kiana"
        },
        {
            "value": "IAO",
            "icao_code": "RPNS",
            "label": "Sayak Airport , Del Carmen"
        },
        {
            "value": "IAQ",
            "icao_code": "OIBH",
            "label": "Bahregan Airport , Bahregan"
        },
        {
            "value": "IAR",
            "icao_code": "UUDL",
            "label": "Tunoshna Airport , Yaroslavl"
        },
        {
            "value": "IAS",
            "icao_code": "LRIA",
            "label": "Ia\u0219i International Airport , Ia\u0219i"
        },
        {
            "value": "IAU",
            "icao_code": null,
            "label": "Iaura Airport , Iaura"
        },
        {
            "value": "IBA",
            "icao_code": "DNIB",
            "label": "Ibadan Airport , Ibadan"
        },
        {
            "value": "IBB",
            "icao_code": "SEII",
            "label": "General Villamil Airport , Isabela Island"
        },
        {
            "value": "IBE",
            "icao_code": "SKIB",
            "label": "Perales Airport , Ibagu\u00e9"
        },
        {
            "value": "IBI",
            "icao_code": null,
            "label": "Iboki Airport , Iboki"
        },
        {
            "value": "IBL",
            "icao_code": null,
            "label": "Indigo Bay Lodge Airport , Bazaruto Island"
        },
        {
            "value": "IBO",
            "icao_code": null,
            "label": "Ibo Island Airport , Ibo Island"
        },
        {
            "value": "IBP",
            "icao_code": "SPBR",
            "label": "Iberia Airport , Iberia"
        },
        {
            "value": "IBR",
            "icao_code": "RJAH",
            "label": "Ibaraki Airport , Omitama"
        },
        {
            "value": "IBZ",
            "icao_code": "LEIB",
            "label": "Ibiza Airport , Ibiza"
        },
        {
            "value": "ICA",
            "icao_code": "SVIC",
            "label": "Icabar\u00fa Airport , Icabar\u00fa"
        },
        {
            "value": "ICC",
            "icao_code": "SVIE",
            "label": "Andr\u00e9s Miguel Salazar Marcano Airport , Coche Island"
        },
        {
            "value": "ICI",
            "icao_code": "NFCI",
            "label": "Cicia Airport , Cicia Island"
        },
        {
            "value": "ICK",
            "icao_code": "SMNI",
            "label": "Majoor Henk Fernandes Airport , Nieuw Nickerie"
        },
        {
            "value": "ICL",
            "icao_code": "KICL",
            "label": "Schenck Field , Clarinda"
        },
        {
            "value": "ICN",
            "icao_code": "RKSI",
            "label": "Incheon International Airport , Seoul"
        },
        {
            "value": "ICO",
            "icao_code": "RPSG",
            "label": "Sicogon Airport , Sicogon"
        },
        {
            "value": "ICS",
            "icao_code": null,
            "label": "Cascade Airport , Cascade"
        },
        {
            "value": "ICT",
            "icao_code": "KICT",
            "label": "Wichita Dwight D. Eisenhower National Airport , Wichita"
        },
        {
            "value": "ICY",
            "icao_code": null,
            "label": "Icy Bay Airport , Icy Bay"
        },
        {
            "value": "IDA",
            "icao_code": "KIDA",
            "label": "Idaho Falls Regional Airport , Idaho Falls"
        },
        {
            "value": "IDB",
            "icao_code": "ESUE",
            "label": "Idre Airport , Idre"
        },
        {
            "value": "IDC",
            "icao_code": null,
            "label": "Chilonzuine Island Airstrip , Chilonzuine Island"
        },
        {
            "value": "IDF",
            "icao_code": "FZCB",
            "label": "Idiofa Airport , Idiofa"
        },
        {
            "value": "IDG",
            "icao_code": "KIDG",
            "label": "Ida Grove Municipal Airport , Ida Grove"
        },
        {
            "value": "IDH",
            "icao_code": "KGIC",
            "label": "Idaho County Airport , Grangeville"
        },
        {
            "value": "IDI",
            "icao_code": "KIDI",
            "label": "Indiana County\u2013Jimmy Stewart Airport , Indiana"
        },
        {
            "value": "IDK",
            "icao_code": "YIDK",
            "label": "Indulkana Airport , Indulkana"
        },
        {
            "value": "IDN",
            "icao_code": null,
            "label": "Indagen Airport , Indagen"
        },
        {
            "value": "IDO",
            "icao_code": "SWIY",
            "label": "Santa Isabel do Morro Airport , Santa Isabel do Morro"
        },
        {
            "value": "IDP",
            "icao_code": "KIDP",
            "label": "Independence Municipal Airport , Independence"
        },
        {
            "value": "IDR",
            "icao_code": "VAID",
            "label": "Devi Ahilyabai Holkar International Airport , Indore"
        },
        {
            "value": "IDY",
            "icao_code": "LFEY",
            "label": "\u00cele d'Yeu Aerodrome , \u00cele d'Yeu"
        },
        {
            "value": "IEG",
            "icao_code": "EPZG",
            "label": "Zielona G\u00f3ra Airport , Zielona G\u00f3ra"
        },
        {
            "value": "IEJ",
            "icao_code": "RORE",
            "label": "Iejima Airport , Iejima"
        },
        {
            "value": "IES",
            "icao_code": "EDAU",
            "label": "Riesa\u2013G\u00f6hlis Airfield , Riesa"
        },
        {
            "value": "IEV",
            "icao_code": "UKKK",
            "label": "Kyiv International Airport (Zhuliany) , Kyiv"
        },
        {
            "value": "IFA",
            "icao_code": "KIFA",
            "label": "Iowa Falls Municipal Airport , Iowa Falls"
        },
        {
            "value": "IFF",
            "icao_code": "YIFY",
            "label": "Iffley Airport , Iffley"
        },
        {
            "value": "IFH",
            "icao_code": "OIFE",
            "label": "Hesa Air Base , Shahin Shahr"
        },
        {
            "value": "IFJ",
            "icao_code": "BIIS",
            "label": "\u00cdsafj\u00f6r\u00f0ur Airport , \u00cdsafj\u00f6r\u00f0ur"
        },
        {
            "value": "IFL",
            "icao_code": "YIFL",
            "label": "Innisfail Airport , Innisfail"
        },
        {
            "value": "IFN",
            "icao_code": "OIFM",
            "label": "Isfahan International Airport , Isfahan"
        },
        {
            "value": "IFO",
            "icao_code": "UKLI",
            "label": "Ivano-Frankivsk International Airport , Ivano-Frankivsk"
        },
        {
            "value": "IFP",
            "icao_code": "KIFP",
            "label": "Laughlin/Bullhead International Airport , Bullhead City"
        },
        {
            "value": "IFU",
            "icao_code": "VREI",
            "label": "Ifuru Airport , Ifuru"
        },
        {
            "value": "IGA",
            "icao_code": "MYIG",
            "label": "Inagua Airport , Inagua Islands"
        },
        {
            "value": "IGB",
            "icao_code": "SAVJ",
            "label": "Ingeniero Jacobacci Airport , Ingeniero Jacobacci"
        },
        {
            "value": "IGD",
            "icao_code": "LTCT",
            "label": "I\u011fd\u0131r Airport , I\u011fd\u0131r"
        },
        {
            "value": "IGE",
            "icao_code": "FOOI",
            "label": "Tchongorove Airport , Iguela"
        },
        {
            "value": "IGG",
            "icao_code": "PAIG",
            "label": "Igiugig Airport , Igiugig"
        },
        {
            "value": "IGH",
            "icao_code": "YIGM",
            "label": "Ingham Airport , Ingham"
        },
        {
            "value": "IGL",
            "icao_code": "LTBL",
            "label": "\u00c7i\u011fli Air Base , \u0130zmir"
        },
        {
            "value": "IGM",
            "icao_code": "KIGM",
            "label": "Kingman Airport , Kingman"
        },
        {
            "value": "IGN",
            "icao_code": "RPMI",
            "label": "Maria Cristina Airport , Iligan"
        },
        {
            "value": "IGO",
            "icao_code": "SKIG",
            "label": "Jaime Ort\u00edz Betancur Airport , Chigorod\u00f3"
        },
        {
            "value": "IGR",
            "icao_code": "SARI",
            "label": "Cataratas del Iguaz\u00fa International Airport , Puerto Iguaz\u00fa"
        },
        {
            "value": "IGS",
            "icao_code": "ETSI",
            "label": "Ingolstadt Manching Airport , Ingolstadt"
        },
        {
            "value": "IGT",
            "icao_code": "URMS",
            "label": "Magas Airport , Nazran"
        },
        {
            "value": "IGU",
            "icao_code": "SBFI",
            "label": "Foz do Igua\u00e7u International Airport , Foz do Igua\u00e7u"
        },
        {
            "value": "IHC",
            "icao_code": "FQIA",
            "label": "Inhaca Airport , Inhaca Island"
        },
        {
            "value": "IHN",
            "icao_code": "OYQN",
            "label": "Qishn Airport , Qishn"
        },
        {
            "value": "IHO",
            "icao_code": "FMSI",
            "label": "Ihosy Airport , Ihosy"
        },
        {
            "value": "IHR",
            "icao_code": "OIZI",
            "label": "Iranshahr Airport , Iranshahr"
        },
        {
            "value": "IHU",
            "icao_code": null,
            "label": "Ihu Airport , Ihu"
        },
        {
            "value": "IIA",
            "icao_code": "EIMN",
            "label": "Inishmaan Aerodrome , Inishmaan"
        },
        {
            "value": "IIL",
            "icao_code": "OICI",
            "label": "Ilam Airport , Ilam"
        },
        {
            "value": "IIS",
            "icao_code": "AYIA",
            "label": "Nissan Island Airport , Nissan Island"
        },
        {
            "value": "IJK",
            "icao_code": "USII",
            "label": "Izhevsk Airport , Izhevsk"
        },
        {
            "value": "IJU",
            "icao_code": "SSIJ",
            "label": "Jo\u00e3o Batista Bos Filho Airport , Iju\u00ed"
        },
        {
            "value": "IJX",
            "icao_code": "KIJX",
            "label": "Jacksonville Municipal Airport , Jacksonville"
        },
        {
            "value": "IKA",
            "icao_code": "OIIE",
            "label": "Tehran Imam Khomeini International Airport , Tehran"
        },
        {
            "value": "IKB",
            "icao_code": "KUKF",
            "label": "Wilkes County Airport , North Wilkesboro"
        },
        {
            "value": "IKI",
            "icao_code": "RJDB",
            "label": "Iki Airport , Iki"
        },
        {
            "value": "IKK",
            "icao_code": "KIKK",
            "label": "Greater Kankakee Airport , Kankakee"
        },
        {
            "value": "IKL",
            "icao_code": "FZGV",
            "label": "Ikela Airport , Ikela"
        },
        {
            "value": "IKO",
            "icao_code": "PAKO",
            "label": "Nikolski Air Station , Nikolski"
        },
        {
            "value": "IKP",
            "icao_code": "YIKM",
            "label": "Inkerman Airport , Inkerman"
        },
        {
            "value": "IKS",
            "icao_code": "UEST",
            "label": "Tiksi Airport , Tiksi"
        },
        {
            "value": "IKT",
            "icao_code": "UIII",
            "label": "International Airport Irkutsk , Irkutsk"
        },
        {
            "value": "IKU",
            "icao_code": "UCFL",
            "label": "Issyk-Kul International Airport , Tamchy"
        },
        {
            "value": "ILA",
            "icao_code": "WABL",
            "label": "Illaga Airport , Illaga"
        },
        {
            "value": "ILD",
            "icao_code": "LEDA",
            "label": "Lleida\u2013Alguaire Airport , Lleida"
        },
        {
            "value": "ILE",
            "icao_code": "KILE",
            "label": "Skylark Field , Killeen"
        },
        {
            "value": "ILF",
            "icao_code": "CZBD",
            "label": "Ilford Airport , Ilford"
        },
        {
            "value": "ILG",
            "icao_code": "KILG",
            "label": "Wilmington Airport , Wilmington"
        },
        {
            "value": "ILH",
            "icao_code": "ETIK",
            "label": "Storck Barracks , Illesheim"
        },
        {
            "value": "ILI",
            "icao_code": "PAIL",
            "label": "Iliamna Airport , Iliamna"
        },
        {
            "value": "ILK",
            "icao_code": "FMMQ",
            "label": "Ilaka-Est Airport , Ilaka"
        },
        {
            "value": "ILL",
            "icao_code": "KBDH",
            "label": "Willmar Municipal Airport , Willmar"
        },
        {
            "value": "ILM",
            "icao_code": "KILM",
            "label": "Wilmington International Airport , Wilmington"
        },
        {
            "value": "ILN",
            "icao_code": "KILN",
            "label": "Wilmington Air Park , Wilmington"
        },
        {
            "value": "ILO",
            "icao_code": "RPVI",
            "label": "Iloilo International Airport , Iloilo"
        },
        {
            "value": "ILP",
            "icao_code": "NWWE",
            "label": "\u00cele des Pins Airport , \u00cele des Pins"
        },
        {
            "value": "ILQ",
            "icao_code": "SPLO",
            "label": "Ilo Airport , Ilo"
        },
        {
            "value": "ILR",
            "icao_code": "DNIL",
            "label": "Ilorin International Airport , Ilorin"
        },
        {
            "value": "ILS",
            "icao_code": "MSSS",
            "label": "Ilopango International Airport , San Salvador"
        },
        {
            "value": "ILU",
            "icao_code": "HKKL",
            "label": "Kilaguni Airport , Kilaguni"
        },
        {
            "value": "ILX",
            "icao_code": null,
            "label": "Ileg Airport , Ileg"
        },
        {
            "value": "ILY",
            "icao_code": "EGPI",
            "label": "Islay Airport , Islay"
        },
        {
            "value": "ILZ",
            "icao_code": "LZZI",
            "label": "\u017dilina Airport , \u017dilina"
        },
        {
            "value": "IMA",
            "icao_code": null,
            "label": "Iamalele Airport , Iamalele"
        },
        {
            "value": "IMB",
            "icao_code": "SYIB",
            "label": "Imbaimadai Airport , Imbaimadai"
        },
        {
            "value": "IMD",
            "icao_code": null,
            "label": "Imonda Airport , Imonda"
        },
        {
            "value": "IMF",
            "icao_code": "VEIM",
            "label": "Imphal International Airport , Imphal"
        },
        {
            "value": "IMG",
            "icao_code": null,
            "label": "Inhaminga Airport , Inhaminga"
        },
        {
            "value": "IMI",
            "icao_code": null,
            "label": "Ine Airport , Ine"
        },
        {
            "value": "IMK",
            "icao_code": "VNST",
            "label": "Simikot Airport , Simikot"
        },
        {
            "value": "IML",
            "icao_code": "KIML",
            "label": "Imperial Municipal Airport , Imperial"
        },
        {
            "value": "IMM",
            "icao_code": "KIMM",
            "label": "Immokalee Regional Airport , Immokalee"
        },
        {
            "value": "IMN",
            "icao_code": "AYII",
            "label": "Imane Airport , Imane"
        },
        {
            "value": "IMO",
            "icao_code": "FEFZ",
            "label": "Zemio Airport , Zemio"
        },
        {
            "value": "IMP",
            "icao_code": "SBIZ",
            "label": "Imperatriz Airport , Imperatriz"
        },
        {
            "value": "IMQ",
            "icao_code": "OITU",
            "label": "Maku International Airport , Maku"
        },
        {
            "value": "IMT",
            "icao_code": "KIMT",
            "label": "Ford Airport , Iron Mountain"
        },
        {
            "value": "INA",
            "icao_code": "UUYI",
            "label": "Inta Airport , Inta"
        },
        {
            "value": "INB",
            "icao_code": null,
            "label": "Independence Airport , Independence"
        },
        {
            "value": "INC",
            "icao_code": "ZLIC",
            "label": "Yinchuan Hedong International Airport , Yinchuan"
        },
        {
            "value": "IND",
            "icao_code": "KIND",
            "label": "Indianapolis International Airport , Indianapolis"
        },
        {
            "value": "INE",
            "icao_code": null,
            "label": "Chinde Airport , Chinde"
        },
        {
            "value": "INF",
            "icao_code": "DATG",
            "label": "In Guezzam Airport , In Guezzam"
        },
        {
            "value": "INH",
            "icao_code": "FQIN",
            "label": "Inhambane Airport , Inhambane"
        },
        {
            "value": "INI",
            "icao_code": "LYNI",
            "label": "Ni\u0161 Constantine the Great Airport , Ni\u0161"
        },
        {
            "value": "INJ",
            "icao_code": "YINJ",
            "label": "Injune Airport , Injune"
        },
        {
            "value": "INK",
            "icao_code": "KINK",
            "label": "Winkler County Airport , Wink"
        },
        {
            "value": "INL",
            "icao_code": "KINL",
            "label": "Falls International Airport , International Falls"
        },
        {
            "value": "INM",
            "icao_code": "YINN",
            "label": "Innamincka Airport , Innamincka"
        },
        {
            "value": "INN",
            "icao_code": "LOWI",
            "label": "Innsbruck Airport , Innsbruck"
        },
        {
            "value": "INO",
            "icao_code": "FZBA",
            "label": "Inongo Airport , Inongo"
        },
        {
            "value": "INQ",
            "icao_code": "EIIR",
            "label": "Inisheer Aerodrome , Inisheer"
        },
        {
            "value": "INS",
            "icao_code": "KINS",
            "label": "Creech Air Force Base , Indian Springs"
        },
        {
            "value": "INT",
            "icao_code": "KINT",
            "label": "Smith Reynolds Airport , Winston-Salem"
        },
        {
            "value": "INU",
            "icao_code": "ANYN",
            "label": "Nauru International Airport , Yaren"
        },
        {
            "value": "INV",
            "icao_code": "EGPE",
            "label": "Inverness Airport , Inverness"
        },
        {
            "value": "INW",
            "icao_code": "KINW",
            "label": "Winslow\u2013Lindbergh Regional Airport , Winslow"
        },
        {
            "value": "INX",
            "icao_code": "WASI",
            "label": "Inanwatan Airport , Inanwatan"
        },
        {
            "value": "INY",
            "icao_code": null,
            "label": "Inyati Airport , Inyati"
        },
        {
            "value": "INZ",
            "icao_code": "DAUI",
            "label": "In Salah Airport , In Salah"
        },
        {
            "value": "IOA",
            "icao_code": "LGIO",
            "label": "Ioannina National Airport , Ioannina"
        },
        {
            "value": "IOK",
            "icao_code": null,
            "label": "Iokea Airport , Iokea"
        },
        {
            "value": "IOM",
            "icao_code": "EGNS",
            "label": "Isle of Man Airport , Ronaldsway"
        },
        {
            "value": "ION",
            "icao_code": "FCOI",
            "label": "Impfondo Airport , Impfondo"
        },
        {
            "value": "IOP",
            "icao_code": null,
            "label": "Ioma Airport , Ioma"
        },
        {
            "value": "IOR",
            "icao_code": "EIIM",
            "label": "Inishmore Aerodrome , Inishmore"
        },
        {
            "value": "IOS",
            "icao_code": "SBIL",
            "label": "Ilh\u00e9us Jorge Amado Airport , Ilh\u00e9us"
        },
        {
            "value": "IOT",
            "icao_code": "BGLL",
            "label": "Illorsuit Heliport , Avannaata"
        },
        {
            "value": "IOW",
            "icao_code": "KIOW",
            "label": "Iowa City Municipal Airport , Iowa City"
        },
        {
            "value": "IPA",
            "icao_code": "NVVI",
            "label": "Ipota Airport , Erromango"
        },
        {
            "value": "IPC",
            "icao_code": "SCIP",
            "label": "Mataveri International Airport , Easter Island"
        },
        {
            "value": "IPE",
            "icao_code": "RPMV",
            "label": "Ipil Airport , Ipil"
        },
        {
            "value": "IPG",
            "icao_code": "SWII",
            "label": "Ipiranga Airport , Santo Ant\u00f4nio do I\u00e7\u00e1"
        },
        {
            "value": "IPH",
            "icao_code": "WMKI",
            "label": "Sultan Azlan Shah Airport , Ipoh"
        },
        {
            "value": "IPI",
            "icao_code": "SKIP",
            "label": "San Luis Airport , Ipiales"
        },
        {
            "value": "IPL",
            "icao_code": "KIPL",
            "label": "Imperial County Airport , Imperial"
        },
        {
            "value": "IPN",
            "icao_code": "SBIP",
            "label": "Usiminas Airport , Ipatinga"
        },
        {
            "value": "IPT",
            "icao_code": "KIPT",
            "label": "Williamsport Regional Airport , Williamsport"
        },
        {
            "value": "IPU",
            "icao_code": "SNIU",
            "label": "Ipia\u00fa Airport , Ipia\u00fa"
        },
        {
            "value": "IPZ",
            "icao_code": "MRSI",
            "label": "San Isidro de El General Airport , San Isidro de El General"
        },
        {
            "value": "IQA",
            "icao_code": "ORAA",
            "label": "Al Asad Airbase , Anbar Province"
        },
        {
            "value": "IQM",
            "icao_code": "ZWCM",
            "label": "Qiemo Airport , Qiemo"
        },
        {
            "value": "IQN",
            "icao_code": "ZLQY",
            "label": "Qingyang Airport , Qingyang"
        },
        {
            "value": "IQQ",
            "icao_code": "SCDA",
            "label": "Diego Aracena International Airport , Iquique"
        },
        {
            "value": "IQT",
            "icao_code": "SPQT",
            "label": "Coronel FAP Francisco Secada Vignetta International Airport , Iquitos"
        },
        {
            "value": "IRA",
            "icao_code": "AGGK",
            "label": "Kirakira Airport , Kirakira"
        },
        {
            "value": "IRB",
            "icao_code": null,
            "label": "Iraan Municipal Airport , Iraan"
        },
        {
            "value": "IRC",
            "icao_code": "PACR",
            "label": "Circle City Airport , Circle"
        },
        {
            "value": "IRD",
            "icao_code": "VGIS",
            "label": "Ishwardi Airport , Ishwardi"
        },
        {
            "value": "IRE",
            "icao_code": "SNIC",
            "label": "Irec\u00ea Airport , Irec\u00ea"
        },
        {
            "value": "IRG",
            "icao_code": "YLHR",
            "label": "Lockhart River Airport , Lockhart River"
        },
        {
            "value": "IRI",
            "icao_code": "HTIR",
            "label": "Iringa Airport , Iringa"
        },
        {
            "value": "IRJ",
            "icao_code": "SANL",
            "label": "Capit\u00e1n Vicente Almandos Almonacid Airport , La Rioja"
        },
        {
            "value": "IRK",
            "icao_code": "KIRK",
            "label": "Kirksville Regional Airport , Kirksville"
        },
        {
            "value": "IRM",
            "icao_code": "USHI",
            "label": "Igrim Airport , Igrim"
        },
        {
            "value": "IRN",
            "icao_code": "MHIR",
            "label": "Iriona Airport , Iriona"
        },
        {
            "value": "IRO",
            "icao_code": "FEFI",
            "label": "Birao Airport , Birao"
        },
        {
            "value": "IRP",
            "icao_code": "FZJH",
            "label": "Matari Airport , Isiro"
        },
        {
            "value": "IRS",
            "icao_code": "KIRS",
            "label": "Kirsch Municipal Airport , Sturgis"
        },
        {
            "value": "IRU",
            "icao_code": null,
            "label": "Iranamadu Waterdrome , Kilinochchi"
        },
        {
            "value": "IRZ",
            "icao_code": "SWTP",
            "label": "Tapuruquara Airport , Santa Isabel do Rio Negro"
        },
        {
            "value": "ISA",
            "icao_code": "YBMA",
            "label": "Mount Isa Airport , Mount Isa"
        },
        {
            "value": "ISB",
            "icao_code": "OPIS",
            "label": "Islamabad  International Airport , Islamabad"
        },
        {
            "value": "ISC",
            "icao_code": "EGHE",
            "label": "St Mary's Airport , St Mary's"
        },
        {
            "value": "ISD",
            "icao_code": null,
            "label": "Iscuande Airport , Iscuande"
        },
        {
            "value": "ISE",
            "icao_code": "LTFC",
            "label": "Isparta S\u00fcleyman Demirel Airport , Isparta"
        },
        {
            "value": "ISG",
            "icao_code": "ROIG",
            "label": "New Ishigaki Airport , Ishigaki"
        },
        {
            "value": "ISI",
            "icao_code": "YISF",
            "label": "Isisford Airport , Isisford"
        },
        {
            "value": "ISJ",
            "icao_code": "MMIM",
            "label": "Isla Mujeres Airport , Isla Mujeres"
        },
        {
            "value": "ISK",
            "icao_code": "VAOZ",
            "label": "Ozar Airport , Nashik"
        },
        {
            "value": "ISL",
            "icao_code": "LTBA",
            "label": "Istanbul Atat\u00fcrk Airport , Istanbul"
        },
        {
            "value": "ISM",
            "icao_code": "KISM",
            "label": "Kissimmee Gateway Airport , Orlando"
        },
        {
            "value": "ISN",
            "icao_code": "KISN",
            "label": "Sloulin Field International Airport , Williston"
        },
        {
            "value": "ISO",
            "icao_code": "KISO",
            "label": "Kinston Regional Jetport , Kinston"
        },
        {
            "value": "ISP",
            "icao_code": "KISP",
            "label": "Long Island MacArthur Airport , Islip"
        },
        {
            "value": "ISQ",
            "icao_code": "KISQ",
            "label": "Schoolcraft County Airport , Manistique"
        },
        {
            "value": "ISS",
            "icao_code": "KIWI",
            "label": "Wiscasset Airport , Wiscasset"
        },
        {
            "value": "IST",
            "icao_code": "LTFM",
            "label": "Istanbul Airport , Istanbul"
        },
        {
            "value": "ISU",
            "icao_code": "ORSU",
            "label": "Sulaimaniyah International Airport , Sulaymaniyah"
        },
        {
            "value": "ISW",
            "icao_code": "KISW",
            "label": "South Wood County Airport , Wisconsin Rapids"
        },
        {
            "value": "ITA",
            "icao_code": "SBIC",
            "label": "Itacoatiara Airport , Itacoatiara"
        },
        {
            "value": "ITB",
            "icao_code": "SBIH",
            "label": "Itaituba Airport , Itaituba"
        },
        {
            "value": "ITE",
            "icao_code": "SNZW",
            "label": "Ituber\u00e1 Airport , Ituber\u00e1"
        },
        {
            "value": "ITH",
            "icao_code": "KITH",
            "label": "Ithaca Tompkins Regional Airport , Ithaca"
        },
        {
            "value": "ITI",
            "icao_code": null,
            "label": "Itambacuri Airport , Itambacuri"
        },
        {
            "value": "ITK",
            "icao_code": null,
            "label": "Itokama Airport , Itokama"
        },
        {
            "value": "ITM",
            "icao_code": "RJOO",
            "label": "Osaka International Airport , Osaka"
        },
        {
            "value": "ITN",
            "icao_code": "SNHA",
            "label": "Itabuna Airport , Itabuna"
        },
        {
            "value": "ITO",
            "icao_code": "PHTO",
            "label": "Hilo International Airport , Hilo"
        },
        {
            "value": "ITP",
            "icao_code": "SDUN",
            "label": "Itaperuna Airport , Itaperuna"
        },
        {
            "value": "ITQ",
            "icao_code": "SSIQ",
            "label": "Itaqui Airport , Itaqui"
        },
        {
            "value": "ITR",
            "icao_code": "SBIT",
            "label": "Itumbiara Airport , Itumbiara"
        },
        {
            "value": "ITU",
            "icao_code": "UHSI",
            "label": "Iturup Airport , Kurilsk"
        },
        {
            "value": "IUE",
            "icao_code": "NIUE",
            "label": "Niue International Airport , Alofi"
        },
        {
            "value": "IUL",
            "icao_code": null,
            "label": "Ilu Airport , Ilu"
        },
        {
            "value": "IUS",
            "icao_code": null,
            "label": "Inus Airport , Inus"
        },
        {
            "value": "IVA",
            "icao_code": "FMNJ",
            "label": "Ambanja Airport , Ambanja"
        },
        {
            "value": "IVC",
            "icao_code": "NZNV",
            "label": "Invercargill Airport , Invercargill"
        },
        {
            "value": "IVG",
            "icao_code": "LYBR",
            "label": "Dolac Airport , Berane"
        },
        {
            "value": "IVI",
            "icao_code": null,
            "label": "Viveros Island Airport , Viveros Island"
        },
        {
            "value": "IVL",
            "icao_code": "EFIV",
            "label": "Ivalo Airport , Ivalo"
        },
        {
            "value": "IVO",
            "icao_code": null,
            "label": "Chibolo Airport , Chibolo"
        },
        {
            "value": "IVR",
            "icao_code": "YIVL",
            "label": "Inverell Airport , Inverell"
        },
        {
            "value": "IVW",
            "icao_code": "YINW",
            "label": "Inverway Airport , Inverway"
        },
        {
            "value": "IWA",
            "icao_code": "UUBI",
            "label": "Ivanovo Yuzhny Airport , Ivanovo"
        },
        {
            "value": "IWD",
            "icao_code": "KIWD",
            "label": "Gogebic\u2013Iron County Airport , Ironwood"
        },
        {
            "value": "IWJ",
            "icao_code": "RJOW",
            "label": "Iwami Airport , Masuda"
        },
        {
            "value": "IWK",
            "icao_code": "RJOI",
            "label": "Marine Corps Air Station Iwakuni , Iwakuni"
        },
        {
            "value": "IWO",
            "icao_code": "RJAW",
            "label": "Iwo Jima Air Base , Iwo Jima"
        },
        {
            "value": "IWS",
            "icao_code": "KIWS",
            "label": "West Houston Airport , Houston"
        },
        {
            "value": "IXA",
            "icao_code": "VEAT",
            "label": "Agartala Airport , Agartala"
        },
        {
            "value": "IXB",
            "icao_code": "VEBD",
            "label": "Bagdogra Airport , Siliguri"
        },
        {
            "value": "IXC",
            "icao_code": "VICG",
            "label": "Chandigarh Airport , Chandigarh"
        },
        {
            "value": "IXD",
            "icao_code": "VIAL",
            "label": "Allahabad Airport , Allahabad"
        },
        {
            "value": "IXE",
            "icao_code": "VOML",
            "label": "Mangalore Airport , Mangalore"
        },
        {
            "value": "IXG",
            "icao_code": "VABM",
            "label": "Belgaum Airport , Belgaum"
        },
        {
            "value": "IXH",
            "icao_code": "VEKR",
            "label": "Kailashahar Airport , Kailashahar"
        },
        {
            "value": "IXI",
            "icao_code": "VELR",
            "label": "Lilabari Airport , North Lakhimpur"
        },
        {
            "value": "IXJ",
            "icao_code": "VIJU",
            "label": "Jammu Airport , Jammu"
        },
        {
            "value": "IXK",
            "icao_code": "VAKS",
            "label": "Keshod Airport , Keshod"
        },
        {
            "value": "IXL",
            "icao_code": "VILH",
            "label": "Kushok Bakula Rimpochee Airport , Leh"
        },
        {
            "value": "IXM",
            "icao_code": "VOMD",
            "label": "Madurai Airport , Madurai"
        },
        {
            "value": "IXN",
            "icao_code": "VEKW",
            "label": "Khowai Airport , Khowai"
        },
        {
            "value": "IXP",
            "icao_code": "VIPK",
            "label": "Pathankot Airport , Pathankot"
        },
        {
            "value": "IXQ",
            "icao_code": "VEKM",
            "label": "Kamalpur Airport , Kamalpur"
        },
        {
            "value": "IXR",
            "icao_code": "VERC",
            "label": "Birsa Munda Airport , Ranchi"
        },
        {
            "value": "IXS",
            "icao_code": "VEKU",
            "label": "Silchar Airport , Silchar"
        },
        {
            "value": "IXT",
            "icao_code": "VEPG",
            "label": "Pasighat Airport , Pasighat"
        },
        {
            "value": "IXU",
            "icao_code": "VAAU",
            "label": "Aurangabad Airport , Aurangabad"
        },
        {
            "value": "IXV",
            "icao_code": "VEAN",
            "label": "Along Airport , Along"
        },
        {
            "value": "IXW",
            "icao_code": "VEJS",
            "label": "Sonari Airport , Jamshedpur"
        },
        {
            "value": "IXX",
            "icao_code": "VOBR",
            "label": "Bidar Airport , Bidar"
        },
        {
            "value": "IXY",
            "icao_code": "VAKE",
            "label": "Kandla Airport , Kandla"
        },
        {
            "value": "IXZ",
            "icao_code": "VOPB",
            "label": "Veer Savarkar International Airport , Port Blair"
        },
        {
            "value": "IYK",
            "icao_code": "KIYK",
            "label": "Inyokern Airport , Inyokern"
        },
        {
            "value": "IZA",
            "icao_code": "SBZM",
            "label": "Presidente Itamar Franco Airport , Juiz de Fora"
        },
        {
            "value": "IZM",
            "icao_code": null,
            "label": "metropolitan area , \u0130zmir"
        },
        {
            "value": "IZO",
            "icao_code": "RJOC",
            "label": "Izumo Airport , Izumo"
        },
        {
            "value": "IZT",
            "icao_code": "MMIT",
            "label": "Ixtepec Airport , Ixtepec"
        },
        {
            "value": "JAA",
            "icao_code": "OAJL",
            "label": "Jalalabad Airport , Jalalabad"
        },
        {
            "value": "JAB",
            "icao_code": "YJAB",
            "label": "Jabiru Airport , Jabiru"
        },
        {
            "value": "JAC",
            "icao_code": "KJAC",
            "label": "Jackson Hole Airport , Jackson"
        },
        {
            "value": "JAD",
            "icao_code": "YPJT",
            "label": "Jandakot Airport , Perth"
        },
        {
            "value": "JAE",
            "icao_code": "SPJE",
            "label": "Ja\u00e9n Airport , Ja\u00e9n"
        },
        {
            "value": "JAF",
            "icao_code": "VCCJ",
            "label": "Jaffna International Airport , Jaffna"
        },
        {
            "value": "JAG",
            "icao_code": "OPJA",
            "label": "PAF Base Shahbaz , Jacobabad"
        },
        {
            "value": "JAI",
            "icao_code": "VIJP",
            "label": "Jaipur International Airport , Jaipur"
        },
        {
            "value": "JAK",
            "icao_code": "MTJA",
            "label": "Jacmel Airport , Jacmel"
        },
        {
            "value": "JAL",
            "icao_code": "MMJA",
            "label": "El Lencero Airport , Xalapa"
        },
        {
            "value": "JAM",
            "icao_code": null,
            "label": "Bezmer Air Base , Yambol"
        },
        {
            "value": "JAN",
            "icao_code": "KJAN",
            "label": "Jackson\u2013Evers International Airport , Jackson"
        },
        {
            "value": "JAP",
            "icao_code": "MRCH",
            "label": "Chacarita Airport , Puntarenas"
        },
        {
            "value": "JAQ",
            "icao_code": null,
            "label": "Jacquinot Bay Airport , Jacquinot Bay"
        },
        {
            "value": "JAR",
            "icao_code": "OISJ",
            "label": "Jahrom Airport , Jahrom"
        },
        {
            "value": "JAS",
            "icao_code": "KJAS",
            "label": "Jasper County Airport , Jasper"
        },
        {
            "value": "JAT",
            "icao_code": null,
            "label": "Jabot Airport , Jabat Island"
        },
        {
            "value": "JAU",
            "icao_code": "SPJJ",
            "label": "Francisco Carle Airport , Jauja"
        },
        {
            "value": "JAV",
            "icao_code": "BGJN",
            "label": "Ilulissat Airport , Ilulissat"
        },
        {
            "value": "JAX",
            "icao_code": "KJAX",
            "label": "Jacksonville International Airport , Jacksonville"
        },
        {
            "value": "JBB",
            "icao_code": "WARE",
            "label": "Notohadinegoro Airport , Jember"
        },
        {
            "value": "JBQ",
            "icao_code": "MDJB",
            "label": "La Isabela International Airport , La Isabela"
        },
        {
            "value": "JBR",
            "icao_code": "KJBR",
            "label": "Jonesboro Municipal Airport , Jonesboro"
        },
        {
            "value": "JBS",
            "icao_code": "SSSB",
            "label": "S\u00e3o Borja Airport , S\u00e3o Borja"
        },
        {
            "value": "JBT",
            "icao_code": null,
            "label": "Bethel Seaplane Base , Bethel"
        },
        {
            "value": "JCB",
            "icao_code": "SSJA",
            "label": "Santa Terezinha Municipal Airport , Joa\u00e7aba"
        },
        {
            "value": "JCI",
            "icao_code": "KIXD",
            "label": "New Century AirCenter , Olathe"
        },
        {
            "value": "JCK",
            "icao_code": "YJLC",
            "label": "Julia Creek Airport , Julia Creek"
        },
        {
            "value": "JCM",
            "icao_code": "SNJB",
            "label": "Jacobina Airport , Jacobina"
        },
        {
            "value": "JCR",
            "icao_code": "SBEK",
            "label": "Jacareacanga Airport , Jacareacanga"
        },
        {
            "value": "JCT",
            "icao_code": "KJCT",
            "label": "Kimble County Airport , Junction"
        },
        {
            "value": "JCY",
            "icao_code": null,
            "label": "LBJ Ranch Airport , Johnson City"
        },
        {
            "value": "JDA",
            "icao_code": "KGCD",
            "label": "Grant County Regional Airport , John Day"
        },
        {
            "value": "JDF",
            "icao_code": "SBJF",
            "label": "Francisco \u00c1lvares de Assis Airport , Juiz de Fora"
        },
        {
            "value": "JDG",
            "icao_code": "RKPD",
            "label": "Jeongseok Airport , Seogwipo"
        },
        {
            "value": "JDH",
            "icao_code": "VIJO",
            "label": "Jodhpur Airport , Jodhpur"
        },
        {
            "value": "JDN",
            "icao_code": "KJDN",
            "label": "Jordan Airport , Jordan"
        },
        {
            "value": "JDO",
            "icao_code": "SBJU",
            "label": "Juazeiro do Norte Airport , Juazeiro do Norte"
        },
        {
            "value": "JDR",
            "icao_code": "SNJR",
            "label": "Prefeito Oct\u00e1vio de Almeida Neves Airport , S\u00e3o Jo\u00e3o del Rei"
        },
        {
            "value": "JDZ",
            "icao_code": "ZSJD",
            "label": "Jingdezhen Luojia Airport , Jingdezhen"
        },
        {
            "value": "JED",
            "icao_code": "OEJN",
            "label": "King Abdulaziz International Airport , Jeddah"
        },
        {
            "value": "JEE",
            "icao_code": "MTJE",
            "label": "J\u00e9r\u00e9mie Airport , J\u00e9r\u00e9mie"
        },
        {
            "value": "JEF",
            "icao_code": "KJEF",
            "label": "Jefferson City Memorial Airport , Jefferson City"
        },
        {
            "value": "JEG",
            "icao_code": "BGAA",
            "label": "Aasiaat Airport , Aasiaat"
        },
        {
            "value": "JEJ",
            "icao_code": null,
            "label": "Jeh Airport , Jeh Island"
        },
        {
            "value": "JEK",
            "icao_code": null,
            "label": "Jeki Airstrip , Jeki"
        },
        {
            "value": "JEQ",
            "icao_code": "SNJK",
            "label": "Jequi\u00e9 Airport , Jequi\u00e9"
        },
        {
            "value": "JER",
            "icao_code": "EGJJ",
            "label": "Jersey Airport , Jersey"
        },
        {
            "value": "JFK",
            "icao_code": "KJFK",
            "label": "John F. Kennedy International Airport , New York City"
        },
        {
            "value": "JFN",
            "icao_code": "KHZY",
            "label": "Northeast Ohio Regional Airport , Ashtabula"
        },
        {
            "value": "JFR",
            "icao_code": "BGPT",
            "label": "Paamiut Airport , Paamiut"
        },
        {
            "value": "JGA",
            "icao_code": "VAJM",
            "label": "Jamnagar Airport , Jamnagar"
        },
        {
            "value": "JGB",
            "icao_code": null,
            "label": "Jagdalpur Airport , Jagdalpur"
        },
        {
            "value": "JGD",
            "icao_code": "ZYJD",
            "label": "Jiagedaqi Airport , Jiagedaqi"
        },
        {
            "value": "JGN",
            "icao_code": "ZLJQ",
            "label": "Jiayuguan Airport , Jiayuguan"
        },
        {
            "value": "JGS",
            "icao_code": "ZSJA",
            "label": "Jinggangshan Airport , Ji'an"
        },
        {
            "value": "JHB",
            "icao_code": "WMKJ",
            "label": "Senai International Airport , Johor Bahru"
        },
        {
            "value": "JHG",
            "icao_code": "ZPJH",
            "label": "Xishuangbanna Gasa Airport , Jinghong"
        },
        {
            "value": "JHL",
            "icao_code": null,
            "label": "Fort MacKay/Albian Aerodrome , Fort McKay"
        },
        {
            "value": "JHM",
            "icao_code": "PHJH",
            "label": "Kapalua Airport , Kapalua"
        },
        {
            "value": "JHS",
            "icao_code": "BGSS",
            "label": "Sisimiut Airport , Sisimiut"
        },
        {
            "value": "JHW",
            "icao_code": "KJHW",
            "label": "Chautauqua County-Jamestown Airport , Jamestown"
        },
        {
            "value": "JIA",
            "icao_code": "SWJN",
            "label": "Ju\u00edna Airport , Ju\u00edna"
        },
        {
            "value": "JIB",
            "icao_code": "HDAM",
            "label": "Djibouti\u2013Ambouli International Airport , Djibouti"
        },
        {
            "value": "JIC",
            "icao_code": "ZLJC",
            "label": "Jinchang Jinchuan Airport , Jinchang"
        },
        {
            "value": "JIJ",
            "icao_code": "HAJJ",
            "label": "Wilwal International Airport , Jijiga"
        },
        {
            "value": "JIK",
            "icao_code": "LGIK",
            "label": "Ikaria Island National Airport , Ikaria Island"
        },
        {
            "value": "JIL",
            "icao_code": "ZYJL",
            "label": "Jilin Ertaizi Airport , Jilin City"
        },
        {
            "value": "JIM",
            "icao_code": "HAJM",
            "label": "Aba Segud Airport , Jimma"
        },
        {
            "value": "JIN",
            "icao_code": "HUJI",
            "label": "Jinja Airport , Jinja"
        },
        {
            "value": "JIO",
            "icao_code": null,
            "label": "Jos Orno Imsula Airport , Tiakur"
        },
        {
            "value": "JIP",
            "icao_code": "SEJI",
            "label": "Jipijapa Airport , Jipijapa"
        },
        {
            "value": "JIQ",
            "icao_code": "ZUQJ",
            "label": "Qianjiang Wulingshan Airport , Qianjiang"
        },
        {
            "value": "JIR",
            "icao_code": "VNJI",
            "label": "Jiri Airport , Jiri"
        },
        {
            "value": "JIU",
            "icao_code": "ZSJJ",
            "label": "Jiujiang Lushan Airport , Jiujiang"
        },
        {
            "value": "JIW",
            "icao_code": "OPJI",
            "label": "Jiwani Airport , Jiwani"
        },
        {
            "value": "JJA",
            "icao_code": "AGJO",
            "label": "Jajao Airport , Jajao"
        },
        {
            "value": "JJD",
            "icao_code": "SBJE",
            "label": "Comte. Ariston Pessoa Regional Airport , Jijoca de Jericoacoara"
        },
        {
            "value": "JJG",
            "icao_code": "SBJA",
            "label": "Humberto Ghizzo Bortoluzzi Regional Airport , Jaguaruna"
        },
        {
            "value": "JJI",
            "icao_code": "SPJI",
            "label": "Juanju\u00ed Airport , Juanju\u00ed"
        },
        {
            "value": "JJM",
            "icao_code": "HKMK",
            "label": "Mulika Lodge Airport , Meru National Park"
        },
        {
            "value": "JJN",
            "icao_code": "ZSQZ",
            "label": "Quanzhou Jinjiang International Airport , Quanzhou"
        },
        {
            "value": "JKG",
            "icao_code": "ESGJ",
            "label": "J\u00f6nk\u00f6ping Airport , J\u00f6nk\u00f6ping"
        },
        {
            "value": "JKH",
            "icao_code": "LGHI",
            "label": "Chios Island National Airport , Chios"
        },
        {
            "value": "JKL",
            "icao_code": "LGKY",
            "label": "Kalymnos Island National Airport , Kalymnos"
        },
        {
            "value": "JKR",
            "icao_code": "VNJP",
            "label": "Janakpur Airport , Janakpur"
        },
        {
            "value": "JKT",
            "icao_code": null,
            "label": "metropolitan area , Jakarta"
        },
        {
            "value": "JKV",
            "icao_code": "KJSO",
            "label": "Cherokee County Airport , Jacksonville"
        },
        {
            "value": "JLA",
            "icao_code": null,
            "label": "Quartz Creek Airport , Cooper Landing"
        },
        {
            "value": "JLN",
            "icao_code": "KJLN",
            "label": "Joplin Regional Airport , Joplin"
        },
        {
            "value": "JLR",
            "icao_code": "VAJB",
            "label": "Jabalpur Airport , Jabalpur"
        },
        {
            "value": "JLS",
            "icao_code": "SDJL",
            "label": "Jales Airport , Jales"
        },
        {
            "value": "JMB",
            "icao_code": null,
            "label": "Jamba Airport , Jamba"
        },
        {
            "value": "JMJ",
            "icao_code": "ZPJM",
            "label": "Lancang Jingmai Airport , Donghui Township"
        },
        {
            "value": "JMK",
            "icao_code": "LGMK",
            "label": "Mykonos Island National Airport , Mykonos"
        },
        {
            "value": "JMO",
            "icao_code": "VNJS",
            "label": "Jomsom Airport , Jomsom"
        },
        {
            "value": "JMS",
            "icao_code": "KJMS",
            "label": "Jamestown Regional Airport , Jamestown"
        },
        {
            "value": "JMU",
            "icao_code": "ZYJM",
            "label": "Jiamusi Dongjiao Airport , Jiamusi"
        },
        {
            "value": "JNA",
            "icao_code": "SNJN",
            "label": "Janu\u00e1ria Airport , Janu\u00e1ria"
        },
        {
            "value": "JNB",
            "icao_code": "FAOR",
            "label": "O. R. Tambo International Airport , Johannesburg"
        },
        {
            "value": "JNG",
            "icao_code": "ZLJN",
            "label": "Jining Qufu Airport , Jining"
        },
        {
            "value": "JNI",
            "icao_code": "SAAJ",
            "label": "Jun\u00edn Airport , Jun\u00edn"
        },
        {
            "value": "JNJ",
            "icao_code": "OOJA",
            "label": "Ja'Aluni Airport , Duqm"
        },
        {
            "value": "JNU",
            "icao_code": "PAJN",
            "label": "Juneau International Airport , Juneau"
        },
        {
            "value": "JNX",
            "icao_code": "LGNX",
            "label": "Naxos Island National Airport , Naxos"
        },
        {
            "value": "JNZ",
            "icao_code": "ZYJZ",
            "label": "Jinzhou Bay Airport , Jinzhou"
        },
        {
            "value": "JOE",
            "icao_code": "EFJO",
            "label": "Joensuu Airport , Joensuu"
        },
        {
            "value": "JOG",
            "icao_code": "WIIJ",
            "label": "Adisucipto International Airport , Yogyakarta"
        },
        {
            "value": "JOH",
            "icao_code": "FAPJ",
            "label": "Port St. Johns Airport , Port St. Johns"
        },
        {
            "value": "JOI",
            "icao_code": "SBJV",
            "label": "Joinville-Lauro Carneiro de Loyola Airport , Joinville"
        },
        {
            "value": "JOJ",
            "icao_code": null,
            "label": "Doris Lake Aerodrome , Doris Lake"
        },
        {
            "value": "JOK",
            "icao_code": "UWKJ",
            "label": "Yoshkar-Ola Airport , Yoshkar-Ola"
        },
        {
            "value": "JOL",
            "icao_code": "RPMJ",
            "label": "Jolo Airport , Jolo"
        },
        {
            "value": "JOM",
            "icao_code": "HTNJ",
            "label": "Njombe Airport , Njombe"
        },
        {
            "value": "JOP",
            "icao_code": null,
            "label": "Josephstaal Airport , Josephstaal"
        },
        {
            "value": "JOS",
            "icao_code": "DNJO",
            "label": "Yakubu Gowon Airport , Jos"
        },
        {
            "value": "JOT",
            "icao_code": "KJOT",
            "label": "Joliet Regional Airport , Joliet"
        },
        {
            "value": "JPA",
            "icao_code": "SBJP",
            "label": "Presidente Castro Pinto International Airport , Jo\u00e3o Pessoa"
        },
        {
            "value": "JPR",
            "icao_code": "SWJI",
            "label": "Jos\u00e9 Coleto Airport , Ji-Paran\u00e1"
        },
        {
            "value": "JQA",
            "icao_code": "BGUQ",
            "label": "Qaarsut Airport , Qaarsut"
        },
        {
            "value": "JQE",
            "icao_code": "MPJE",
            "label": "Jaqu\u00e9 Airport , Jaqu\u00e9"
        },
        {
            "value": "JRF",
            "icao_code": "PHJR",
            "label": "Kalaeloa Airport , Kapalua"
        },
        {
            "value": "JRG",
            "icao_code": "VEJH",
            "label": "Veer Surendra Sai Airport , Jharsuguda"
        },
        {
            "value": "JRH",
            "icao_code": "VEJT",
            "label": "Jorhat Airport , Jorhat"
        },
        {
            "value": "JRN",
            "icao_code": "SWJU",
            "label": "Juruena Airport , Juruena"
        },
        {
            "value": "JRO",
            "icao_code": "HTKJ",
            "label": "Kilimanjaro International Airport , Kilimanjaro"
        },
        {
            "value": "JRS",
            "icao_code": "OJJR",
            "label": "Atarot Airport , Jerusalem"
        },
        {
            "value": "JSA",
            "icao_code": "VIJR",
            "label": "Jaisalmer Airport , Jaisalmer"
        },
        {
            "value": "JSH",
            "icao_code": "LGST",
            "label": "Sitia Public Airport , Sitia"
        },
        {
            "value": "JSI",
            "icao_code": "LGSK",
            "label": "Skiathos Island National Airport , Skiathos"
        },
        {
            "value": "JSJ",
            "icao_code": "XYJS",
            "label": "Jiansanjiang Airport , Jiansanjiang, Heilongjiang, China"
        },
        {
            "value": "JSK",
            "icao_code": "OIZJ",
            "label": "Jask Airport , Jask"
        },
        {
            "value": "JSM",
            "icao_code": "SAWS",
            "label": "Jos\u00e9 de San Mart\u00edn Airport , Jos\u00e9 de San Mart\u00edn"
        },
        {
            "value": "JSR",
            "icao_code": "VGJR",
            "label": "Jessore Airport , Jessore"
        },
        {
            "value": "JST",
            "icao_code": "KJST",
            "label": "John Murtha Johnstown\u2013Cambria County Airport , Johnstown"
        },
        {
            "value": "JSU",
            "icao_code": "BGMQ",
            "label": "Maniitsoq Airport , Maniitsoq"
        },
        {
            "value": "JSY",
            "icao_code": "LGSO",
            "label": "Syros Island National Airport , Syros"
        },
        {
            "value": "JTC",
            "icao_code": "SBAE",
            "label": "Moussa Nakhl Tobias\u2013Bauru/Arealva State Airport , Bauru"
        },
        {
            "value": "JTI",
            "icao_code": "SWJW",
            "label": "Jata\u00ed Airport , Jata\u00ed"
        },
        {
            "value": "JTR",
            "icao_code": "LGSR",
            "label": "Santorini (Thira) National Airport , Santorini"
        },
        {
            "value": "JTY",
            "icao_code": "LGPL",
            "label": "Astypalaia Island National Airport , Astypalaia"
        },
        {
            "value": "JUA",
            "icao_code": "SIZX",
            "label": "In\u00e1cio Lu\u00eds do Nascimento Airport , Juara"
        },
        {
            "value": "JUB",
            "icao_code": "HJJJ",
            "label": "Juba International Airport , Juba"
        },
        {
            "value": "JUH",
            "icao_code": null,
            "label": "Chizhou Jiuhuashan Airport , Chizhou"
        },
        {
            "value": "JUI",
            "icao_code": "EDWJ",
            "label": "Juist Airport , Juist"
        },
        {
            "value": "JUJ",
            "icao_code": "SASJ",
            "label": "Gobernador Horacio Guzm\u00e1n International Airport , San Salvador de Jujuy"
        },
        {
            "value": "JUL",
            "icao_code": "SPJL",
            "label": "Inca Manco C\u00e1pac International Airport , Juliaca"
        },
        {
            "value": "JUM",
            "icao_code": "VNJL",
            "label": "Jumla Airport , Jumla"
        },
        {
            "value": "JUN",
            "icao_code": "YJDA",
            "label": "Jundah Airport , Jundah"
        },
        {
            "value": "JUO",
            "icao_code": "SKJU",
            "label": "Jurad\u00f3 Airport , Jurad\u00f3"
        },
        {
            "value": "JUR",
            "icao_code": "YJNB",
            "label": "Jurien Bay Airport , Jurien Bay"
        },
        {
            "value": "JUT",
            "icao_code": "MHJU",
            "label": "Juticalpa Airport , Juticalpa"
        },
        {
            "value": "JUV",
            "icao_code": "BGUK",
            "label": "Upernavik Airport , Upernavik"
        },
        {
            "value": "JUZ",
            "icao_code": "ZSJU",
            "label": "Quzhou Airport , Quzhou"
        },
        {
            "value": "JVA",
            "icao_code": "FMMK",
            "label": "Ankavandra Airport , Ankavandra"
        },
        {
            "value": "JVI",
            "icao_code": null,
            "label": "Central Jersey Regional Airport , Manville"
        },
        {
            "value": "JVL",
            "icao_code": "KJVL",
            "label": "Southern Wisconsin Regional Airport , Janesville"
        },
        {
            "value": "JWA",
            "icao_code": "FBJW",
            "label": "Jwaneng Airport , Jwaneng"
        },
        {
            "value": "JWN",
            "icao_code": "OITZ",
            "label": "Zanjan Airport , Zanjan"
        },
        {
            "value": "JWO",
            "icao_code": "RKTI",
            "label": "Jungwon Air Base , Chungju"
        },
        {
            "value": "JXA",
            "icao_code": "ZYJX",
            "label": "Jixi Xingkaihu Airport , Jixi"
        },
        {
            "value": "JXN",
            "icao_code": "KJXN",
            "label": "Jackson County Airport , Jackson"
        },
        {
            "value": "JYR",
            "icao_code": "OIKJ",
            "label": "Jiroft Airport , Jiroft"
        },
        {
            "value": "JYV",
            "icao_code": "EFJY",
            "label": "Jyv\u00e4skyl\u00e4 Airport , Jyv\u00e4skyl\u00e4"
        },
        {
            "value": "JZH",
            "icao_code": "ZUJZ",
            "label": "Jiuzhai Huanglong Airport , Jiuzhaigou"
        },
        {
            "value": "KAA",
            "icao_code": "FLKS",
            "label": "Kasama Airport , Kasama"
        },
        {
            "value": "KAB",
            "icao_code": "FVKB",
            "label": "Kariba Airport , Kariba"
        },
        {
            "value": "KAC",
            "icao_code": "OSKL",
            "label": "Kamishly Airport , Qamishli"
        },
        {
            "value": "KAD",
            "icao_code": "DNKA",
            "label": "Kaduna Airport , Kaduna"
        },
        {
            "value": "KAE",
            "icao_code": null,
            "label": "Kake Seaplane Base , Kake"
        },
        {
            "value": "KAF",
            "icao_code": null,
            "label": "Karato Airport , Karato"
        },
        {
            "value": "KAG",
            "icao_code": "RKNN",
            "label": "Gangneung Air Base , Gangneung"
        },
        {
            "value": "KAI",
            "icao_code": "SYKA",
            "label": "Kaieteur International Airport , Kaieteur"
        },
        {
            "value": "KAJ",
            "icao_code": "EFKI",
            "label": "Kajaani Airport , Kajaani"
        },
        {
            "value": "KAK",
            "icao_code": null,
            "label": "Kar Airport , Kar"
        },
        {
            "value": "KAL",
            "icao_code": "PAKV",
            "label": "Kaltag Airport , Kaltag"
        },
        {
            "value": "KAM",
            "icao_code": "ODKM",
            "label": "Kamaran Airport , Kamaran"
        },
        {
            "value": "KAN",
            "icao_code": "DNKN",
            "label": "Mallam Aminu Kano International Airport , Kano"
        },
        {
            "value": "KAO",
            "icao_code": "EFKS",
            "label": "Kuusamo Airport , Kuusamo"
        },
        {
            "value": "KAP",
            "icao_code": "FZSK",
            "label": "Kapanga Airport , Kapanga"
        },
        {
            "value": "KAQ",
            "icao_code": "AYKH",
            "label": "Kamulai Airport , Kamulai"
        },
        {
            "value": "KAR",
            "icao_code": "SYKM",
            "label": "Kamarang Airport , Kamarang"
        },
        {
            "value": "KAS",
            "icao_code": "FYKB",
            "label": "Karasburg Airport , Karasburg"
        },
        {
            "value": "KAT",
            "icao_code": "NZKT",
            "label": "Kaitaia Airport , Kaitaia"
        },
        {
            "value": "KAU",
            "icao_code": "EFKA",
            "label": "Kauhava Airfield , Kauhava"
        },
        {
            "value": "KAV",
            "icao_code": "SVKA",
            "label": "Kavanay\u00e9n Airport , Kavanay\u00e9n"
        },
        {
            "value": "KAW",
            "icao_code": "VYKT",
            "label": "Kawthaung Airport , Kawthaung"
        },
        {
            "value": "KAX",
            "icao_code": "YKBR",
            "label": "Kalbarri Airport , Kalbarri"
        },
        {
            "value": "KAY",
            "icao_code": "NFNW",
            "label": "Wakaya Airport , Wakaya Island"
        },
        {
            "value": "KAZ",
            "icao_code": "WAMK",
            "label": "Kuabang Airport , Kao"
        },
        {
            "value": "KBA",
            "icao_code": "GFKB",
            "label": "Kabala Airport , Kabala"
        },
        {
            "value": "KBB",
            "icao_code": null,
            "label": "Kirkimbie Airport , Kirkimbie Station"
        },
        {
            "value": "KBC",
            "icao_code": null,
            "label": "Birch Creek Airport , Birch Creek"
        },
        {
            "value": "KBD",
            "icao_code": null,
            "label": "Kimberley Downs Airport , Kimberley Downs"
        },
        {
            "value": "KBE",
            "icao_code": null,
            "label": "Bell Island Hot Springs Seaplane Base , Bell Island"
        },
        {
            "value": "KBF",
            "icao_code": "WABK",
            "label": "Karubaga Airport , Karubaga"
        },
        {
            "value": "KBG",
            "icao_code": "HUKF",
            "label": "Kabalega Falls Airport , Kabalega Falls"
        },
        {
            "value": "KBI",
            "icao_code": "FKKB",
            "label": "Kribi Airport , Kribi"
        },
        {
            "value": "KBJ",
            "icao_code": "YKCA",
            "label": "Kings Canyon Airport , Kings Canyon"
        },
        {
            "value": "KBL",
            "icao_code": "OAKB",
            "label": "Kabul International Airport , Kabul"
        },
        {
            "value": "KBM",
            "icao_code": "AYKB",
            "label": "Kabwum Airport , Kabwum"
        },
        {
            "value": "KBN",
            "icao_code": "FZWT",
            "label": "Tunta Airport , Kabinda"
        },
        {
            "value": "KBO",
            "icao_code": "FZRM",
            "label": "Kabalo Airport , Kabalo"
        },
        {
            "value": "KBP",
            "icao_code": "UKBB",
            "label": "Boryspil International Airport , Kyiv"
        },
        {
            "value": "KBQ",
            "icao_code": "FWKG",
            "label": "Kasungu Airport , Kasungu"
        },
        {
            "value": "KBR",
            "icao_code": "WMKC",
            "label": "Sultan Ismail Petra Airport , Kota Bharu"
        },
        {
            "value": "KBS",
            "icao_code": "GFBO",
            "label": "Bo Airport , Bo"
        },
        {
            "value": "KBT",
            "icao_code": null,
            "label": "Kaben Airport , Kaben"
        },
        {
            "value": "KBU",
            "icao_code": "WRBK",
            "label": "Gusti Syamsir Alam Airport , Kotabaru"
        },
        {
            "value": "KBV",
            "icao_code": "VTSG",
            "label": "Krabi Airport , Krabi"
        },
        {
            "value": "KBW",
            "icao_code": null,
            "label": "Chignik Bay Seaplane Base , Chignik"
        },
        {
            "value": "KBX",
            "icao_code": "WASU",
            "label": "Kambuaya Airport , Ayamaru"
        },
        {
            "value": "KBY",
            "icao_code": "YKBY",
            "label": "Streaky Bay Airport , Streaky Bay"
        },
        {
            "value": "KBZ",
            "icao_code": "NZKI",
            "label": "Kaikoura Aerodrome , Kaikoura"
        },
        {
            "value": "KCA",
            "icao_code": "ZWKC",
            "label": "Kuqa Qiuci Airport , Kuqa"
        },
        {
            "value": "KCB",
            "icao_code": "SMTP",
            "label": "Tepoe Airstrip , Kasikasima"
        },
        {
            "value": "KCC",
            "icao_code": null,
            "label": "Coffman Cove Seaplane Base , Coffman Cove"
        },
        {
            "value": "KCD",
            "icao_code": null,
            "label": "Kamur Airport , Kamur"
        },
        {
            "value": "KCE",
            "icao_code": "YCSV",
            "label": "Collinsville Airport , Collinsville"
        },
        {
            "value": "KCF",
            "icao_code": null,
            "label": "Kadanwari Airport , Kadanwari"
        },
        {
            "value": "KCG",
            "icao_code": null,
            "label": "Chignik Fisheries Airport , Chignik"
        },
        {
            "value": "KCH",
            "icao_code": "WBGG",
            "label": "Kuching International Airport , Kuching"
        },
        {
            "value": "KCJ",
            "icao_code": null,
            "label": "Komaio Airport , Komaio"
        },
        {
            "value": "KCK",
            "icao_code": "UIKK",
            "label": "Kirensk Airport , Kirensk"
        },
        {
            "value": "KCL",
            "icao_code": null,
            "label": "Chignik Lagoon Airport , Chignik Lagoon"
        },
        {
            "value": "KCM",
            "icao_code": "LTCN",
            "label": "Kahramanmara\u015f Airport , Kahramanmara\u015f"
        },
        {
            "value": "KCN",
            "icao_code": null,
            "label": "Chernofski Harbor Seaplane Base , Chernofski Harbor"
        },
        {
            "value": "KCO",
            "icao_code": "LTBQ",
            "label": "Cengiz Topel Naval Air Station , \u0130zmit"
        },
        {
            "value": "KCP",
            "icao_code": null,
            "label": "Kamianets-Podilskyi Airport , Kamianets-Podilskyi"
        },
        {
            "value": "KCQ",
            "icao_code": null,
            "label": "Chignik Lake Airport , Chignik Lake"
        },
        {
            "value": "KCR",
            "icao_code": null,
            "label": "Colorado Creek Airport , Colorado Creek"
        },
        {
            "value": "KCS",
            "icao_code": "YKCS",
            "label": "Kings Creek Station Airport , Kings Creek Station"
        },
        {
            "value": "KCT",
            "icao_code": "VCCK",
            "label": "Koggala Airport , Koggala"
        },
        {
            "value": "KCU",
            "icao_code": "HUMI",
            "label": "Masindi Airport , Masindi"
        },
        {
            "value": "KCZ",
            "icao_code": "RJOK",
            "label": "K\u014dchi Ry\u014dma Airport , K\u014dchi"
        },
        {
            "value": "KDA",
            "icao_code": "GOGK",
            "label": "Kolda North Airport , Kolda"
        },
        {
            "value": "KDB",
            "icao_code": "YKBL",
            "label": "Kambalda Airport , Kambalda"
        },
        {
            "value": "KDC",
            "icao_code": "DBBK",
            "label": "Kandi Airport , Kandi"
        },
        {
            "value": "KDD",
            "icao_code": "OPKH",
            "label": "Khuzdar Airport , Khuzdar"
        },
        {
            "value": "KDE",
            "icao_code": "AYOW",
            "label": "Koroba Airport , Koroba"
        },
        {
            "value": "KDH",
            "icao_code": "OAKN",
            "label": "Kandahar International Airport , Kandahar"
        },
        {
            "value": "KDI",
            "icao_code": "WAWW",
            "label": "Haluoleo Airport , Kendari"
        },
        {
            "value": "KDJ",
            "icao_code": "FOGJ",
            "label": "Ndjol\u00e9 Ville Airport , Ndjol\u00e9"
        },
        {
            "value": "KDK",
            "icao_code": "PAKD",
            "label": "Kodiak Municipal Airport , Kodiak"
        },
        {
            "value": "KDL",
            "icao_code": "EEKA",
            "label": "K\u00e4rdla Airport , K\u00e4rdla"
        },
        {
            "value": "KDM",
            "icao_code": "VRMT",
            "label": "Kaadedhdhoo Airport , Kaadedhdhoo Island"
        },
        {
            "value": "KDN",
            "icao_code": "FOGE",
            "label": "Ndend\u00e9 Airport , Ndend\u00e9"
        },
        {
            "value": "KDO",
            "icao_code": "VRMK",
            "label": "Kadhdhoo Airport , Kadhdhoo Island"
        },
        {
            "value": "KDP",
            "icao_code": "AYNN",
            "label": "Kandep Airport , Kandep"
        },
        {
            "value": "KDQ",
            "icao_code": "AYTO",
            "label": "Kamberatoro Airport , Kamberatoro"
        },
        {
            "value": "KDR",
            "icao_code": "AYKC",
            "label": "Kandrian Airport , Kandrian"
        },
        {
            "value": "KDS",
            "icao_code": null,
            "label": "Kamaran Downs Airport , Kamaran Downs"
        },
        {
            "value": "KDT",
            "icao_code": "VTBK",
            "label": "Kamphaeng Saen Airport , Kamphaeng Saen"
        },
        {
            "value": "KDU",
            "icao_code": "OPSD",
            "label": "Skardu Airport , Skardu"
        },
        {
            "value": "KDV",
            "icao_code": "NFKD",
            "label": "Vunisea Airport , Vunisea"
        },
        {
            "value": "KDW",
            "icao_code": null,
            "label": "Victoria Reservoir Seaplane Base , Kandy"
        },
        {
            "value": "KDX",
            "icao_code": "HSLI",
            "label": "Kadugli Airport , Kaduqli"
        },
        {
            "value": "KDY",
            "icao_code": "UEMH",
            "label": "Teply Klyuch Airport , Khandyga"
        },
        {
            "value": "KDZ",
            "icao_code": null,
            "label": "Polgolla Reservoir Seaplane Base , Kandy"
        },
        {
            "value": "KEA",
            "icao_code": "UTAE",
            "label": "Kerki Airport , Kerki"
        },
        {
            "value": "KEB",
            "icao_code": null,
            "label": "Nanwalek Airport , Nanwalek"
        },
        {
            "value": "KEC",
            "icao_code": "FZQG",
            "label": "Kasenga Airport , Kasenga"
        },
        {
            "value": "KED",
            "icao_code": "GQNK",
            "label": "Ka\u00e9di Airport , Ka\u00e9di"
        },
        {
            "value": "KEE",
            "icao_code": "FCOK",
            "label": "Kelle Airport , Kell\u00e9"
        },
        {
            "value": "KEF",
            "icao_code": "BIKF",
            "label": "Keflav\u00edk International Airport , Reykjav\u00edk"
        },
        {
            "value": "KEG",
            "icao_code": "AYLG",
            "label": "Keglsugl Airport , Keglsugl"
        },
        {
            "value": "KEH",
            "icao_code": null,
            "label": "Kenmore Air Harbor , Kenmore"
        },
        {
            "value": "KEI",
            "icao_code": "WAKP",
            "label": "Kepi Airport , Kepi"
        },
        {
            "value": "KEJ",
            "icao_code": "UNEE",
            "label": "Kemerovo International Airport , Kemerovo"
        },
        {
            "value": "KEK",
            "icao_code": null,
            "label": "Ekwok Airport , Ekwok"
        },
        {
            "value": "KEL",
            "icao_code": "EDHK",
            "label": "Kiel Airport , Kiel"
        },
        {
            "value": "KEM",
            "icao_code": "EFKE",
            "label": "Kemi-Tornio Airport , Kemi"
        },
        {
            "value": "KEN",
            "icao_code": "GFKE",
            "label": "Kenema Airport , Kenema"
        },
        {
            "value": "KEO",
            "icao_code": "DIOD",
            "label": "Odienn\u00e9 Airport , Odienn\u00e9"
        },
        {
            "value": "KEP",
            "icao_code": "VNNG",
            "label": "Nepalgunj Airport , Nepalgunj"
        },
        {
            "value": "KEQ",
            "icao_code": "WASE",
            "label": "Kebar Airport , Kebar"
        },
        {
            "value": "KER",
            "icao_code": "OIKK",
            "label": "Kerman Airport , Kerman"
        },
        {
            "value": "KES",
            "icao_code": "CZEE",
            "label": "Kelsey Airport , Kelsey"
        },
        {
            "value": "KET",
            "icao_code": "VYKG",
            "label": "Kengtung Airport , Kengtung"
        },
        {
            "value": "KEU",
            "icao_code": "HKKE",
            "label": "Keekorok Airport , Keekorok"
        },
        {
            "value": "KEV",
            "icao_code": "EFHA",
            "label": "Halli Airport , Kuorevesi"
        },
        {
            "value": "KEW",
            "icao_code": null,
            "label": "Keewaywin Airport , Keewaywin"
        },
        {
            "value": "KEX",
            "icao_code": "AYNB",
            "label": "Kanabea Airport , Kanabea"
        },
        {
            "value": "KEY",
            "icao_code": "HKKR",
            "label": "Kericho Airport , Kericho"
        },
        {
            "value": "KEZ",
            "icao_code": null,
            "label": "Kelani River-Peliyagoda Waterdrome , Colombo"
        },
        {
            "value": "KFA",
            "icao_code": "GQNF",
            "label": "Kiffa Airport , Kiffa"
        },
        {
            "value": "KFE",
            "icao_code": "YFDF",
            "label": "Fortescue Dave Forrest Airport , Cloudbreak"
        },
        {
            "value": "KFG",
            "icao_code": "YKKG",
            "label": "Kalkgurung Airport , Kalkarindji"
        },
        {
            "value": "KFM",
            "icao_code": null,
            "label": "Kirby Lake Airport , Wood Buffalo"
        },
        {
            "value": "KFP",
            "icao_code": "PAKF",
            "label": "False Pass Airport , False Pass"
        },
        {
            "value": "KFS",
            "icao_code": "LTAL",
            "label": "Kastamonu Airport , Kastamonu"
        },
        {
            "value": "KFZ",
            "icao_code": "LAKU",
            "label": "Kuk\u00ebs International Airport , Kuk\u00ebs"
        },
        {
            "value": "KGA",
            "icao_code": "FZUA",
            "label": "Kananga Airport , Kananga"
        },
        {
            "value": "KGB",
            "icao_code": "AYOE",
            "label": "Konge Airport , Konge"
        },
        {
            "value": "KGC",
            "icao_code": "YKSC",
            "label": "Kingscote Airport , Cygnet River"
        },
        {
            "value": "KGD",
            "icao_code": "UMKK",
            "label": "Khrabrovo Airport , Kaliningrad"
        },
        {
            "value": "KGE",
            "icao_code": "AGKG",
            "label": "Kaghau Airport , Kaghau Island"
        },
        {
            "value": "KGF",
            "icao_code": "UAKK",
            "label": "Sary-Arka Airport , Karaganda"
        },
        {
            "value": "KGG",
            "icao_code": "GOTK",
            "label": "K\u00e9dougou Airport , K\u00e9dougou"
        },
        {
            "value": "KGH",
            "icao_code": "AYJO",
            "label": "Yongai Airport , Yongai"
        },
        {
            "value": "KGI",
            "icao_code": "YPKG",
            "label": "Kalgoorlie-Boulder Airport , Kalgoorlie"
        },
        {
            "value": "KGJ",
            "icao_code": "FWKA",
            "label": "Karonga Airport , Karonga"
        },
        {
            "value": "KGK",
            "icao_code": "PAJZ",
            "label": "Koliganek Airport , Koliganek"
        },
        {
            "value": "KGL",
            "icao_code": "HRYR",
            "label": "Kigali International Airport , Kigali"
        },
        {
            "value": "KGM",
            "icao_code": null,
            "label": "Kungim Airport , Kungim"
        },
        {
            "value": "KGN",
            "icao_code": "FZOK",
            "label": "Kasongo Lunda Airport , Kasongo Lunda"
        },
        {
            "value": "KGO",
            "icao_code": "UKKG",
            "label": "Kirovohrad Airport , Kropyvnytskyi"
        },
        {
            "value": "KGP",
            "icao_code": "USRK",
            "label": "Kogalym International Airport , Kogalym"
        },
        {
            "value": "KGR",
            "icao_code": null,
            "label": "Kulgera Airport , Kulgera"
        },
        {
            "value": "KGS",
            "icao_code": "LGKO",
            "label": "Kos Island International Airport , Kos"
        },
        {
            "value": "KGT",
            "icao_code": "ZUKD",
            "label": "Kangding Airport , Kangding"
        },
        {
            "value": "KGU",
            "icao_code": "WBKG",
            "label": "Keningau Airport , Keningau"
        },
        {
            "value": "KGW",
            "icao_code": "AYKQ",
            "label": "Kagi Airport , Kagi"
        },
        {
            "value": "KGX",
            "icao_code": "PAGX",
            "label": "Grayling Airport , Grayling"
        },
        {
            "value": "KGY",
            "icao_code": "YKRY",
            "label": "Kingaroy Airport , Kingaroy"
        },
        {
            "value": "KGZ",
            "icao_code": null,
            "label": "Glacier Creek Airport , Glacier Creek"
        },
        {
            "value": "KHA",
            "icao_code": "OITH",
            "label": "Khaneh Airport , Piranshahr"
        },
        {
            "value": "KHC",
            "icao_code": "UKFK",
            "label": "Kerch Airport , Kerch"
        },
        {
            "value": "KHD",
            "icao_code": "OICK",
            "label": "Khorramabad Airport , Khorramabad"
        },
        {
            "value": "KHE",
            "icao_code": "UKOH",
            "label": "Kherson International Airport , Kherson"
        },
        {
            "value": "KHG",
            "icao_code": "ZWSH",
            "label": "Kashgar Airport , Kashgar"
        },
        {
            "value": "KHH",
            "icao_code": "RCKH",
            "label": "Kaohsiung International Airport , Kaohsiung"
        },
        {
            "value": "KHI",
            "icao_code": "OPKC",
            "label": "Jinnah International Airport , Karachi"
        },
        {
            "value": "KHJ",
            "icao_code": "EFKJ",
            "label": "Kauhajoki Airfield , Kauhajoki"
        },
        {
            "value": "KHK",
            "icao_code": "OIBQ",
            "label": "Kharg Airport , Kharg Island"
        },
        {
            "value": "KHM",
            "icao_code": "VYKI",
            "label": "Khamti Airport , Khamti"
        },
        {
            "value": "KHN",
            "icao_code": "ZSCN",
            "label": "Nanchang Changbei International Airport , Nanchang"
        },
        {
            "value": "KHO",
            "icao_code": null,
            "label": "Khoka Moya Airport , Manyeleti"
        },
        {
            "value": "KHR",
            "icao_code": "ZMHH",
            "label": "Kharkhorin Airport , Kharkhorin"
        },
        {
            "value": "KHS",
            "icao_code": "OOKB",
            "label": "Khasab Airport , Khasab"
        },
        {
            "value": "KHT",
            "icao_code": "OAKS",
            "label": "Khost Airfield , Khost"
        },
        {
            "value": "KHU",
            "icao_code": null,
            "label": "Kremenchuk Airport , Kremenchuk"
        },
        {
            "value": "KHV",
            "icao_code": "UHHH",
            "label": "Khabarovsk Novy Airport , Khabarovsk"
        },
        {
            "value": "KHW",
            "icao_code": "FBKR",
            "label": "Khwai River Airport , Khwai River Lodge"
        },
        {
            "value": "KHX",
            "icao_code": null,
            "label": "Kihihi Airstrip , Kihihi"
        },
        {
            "value": "KHY",
            "icao_code": "OITK",
            "label": "Khoy Airport , Khoy"
        },
        {
            "value": "KHZ",
            "icao_code": "NTKA",
            "label": "Kauehi Aerodrome , Kauehi"
        },
        {
            "value": "KIA",
            "icao_code": null,
            "label": "Kaiapit Airport , Kaiapit"
        },
        {
            "value": "KIB",
            "icao_code": null,
            "label": "Ivanof Bay Seaplane Base , Ivanof Bay"
        },
        {
            "value": "KIC",
            "icao_code": "KKIC",
            "label": "Mesa Del Rey Airport , King City"
        },
        {
            "value": "KID",
            "icao_code": "ESMK",
            "label": "Kristianstad Airport , Kristianstad"
        },
        {
            "value": "KIE",
            "icao_code": "AYIQ",
            "label": "Aropa Airport , Kieta"
        },
        {
            "value": "KIF",
            "icao_code": null,
            "label": "Kingfisher Lake Airport , Kingfisher Lake"
        },
        {
            "value": "KIG",
            "icao_code": null,
            "label": "Koingnaas Airport , Koingnaas"
        },
        {
            "value": "KIH",
            "icao_code": "OIBK",
            "label": "Kish International Airport , Kish Island"
        },
        {
            "value": "KII",
            "icao_code": "AYLI",
            "label": "Kibuli Airport , Kibuli"
        },
        {
            "value": "KIJ",
            "icao_code": "RJSN",
            "label": "Niigata Airport , Niigata"
        },
        {
            "value": "KIK",
            "icao_code": "ORKK",
            "label": "Kirkuk Airport , Kirkuk"
        },
        {
            "value": "KIL",
            "icao_code": null,
            "label": "Kilwa Airport , Kilwa"
        },
        {
            "value": "KIM",
            "icao_code": "FAKM",
            "label": "Kimberley Airport , Kimberley"
        },
        {
            "value": "KIN",
            "icao_code": "MKJP",
            "label": "Norman Manley International Airport , Kingston"
        },
        {
            "value": "KIO",
            "icao_code": null,
            "label": "Kili Airport , Kili Island"
        },
        {
            "value": "KIP",
            "icao_code": "KCWC",
            "label": "Kickapoo Downtown Airport , Wichita Falls"
        },
        {
            "value": "KIQ",
            "icao_code": "AYRA",
            "label": "Kira Airport , Kira"
        },
        {
            "value": "KIR",
            "icao_code": "EIKY",
            "label": "Kerry Airport , Kerry"
        },
        {
            "value": "KIS",
            "icao_code": "HKKI",
            "label": "Kisumu International Airport , Kisumu"
        },
        {
            "value": "KIT",
            "icao_code": "LGKC",
            "label": "Kithira Island National Airport , Kythira"
        },
        {
            "value": "KIU",
            "icao_code": null,
            "label": "Kiunga Airport , Kiunga"
        },
        {
            "value": "KIV",
            "icao_code": "LUKK",
            "label": "Chi\u0219in\u0103u International Airport , Chi\u0219in\u0103u"
        },
        {
            "value": "KIW",
            "icao_code": "FLSO",
            "label": "Southdowns Airport , Kitwe"
        },
        {
            "value": "KIX",
            "icao_code": "RJBB",
            "label": "Kansai International Airport , Osaka"
        },
        {
            "value": "KIY",
            "icao_code": "HTKI",
            "label": "Kilwa Masoko Airport , Kilwa Masoko"
        },
        {
            "value": "KIZ",
            "icao_code": null,
            "label": "Kikinonda Airport , Kikinonda"
        },
        {
            "value": "KJA",
            "icao_code": "UNKL",
            "label": "Yemelyanovo International Airport , Krasnoyarsk"
        },
        {
            "value": "KJB",
            "icao_code": "VOKU",
            "label": "Kurnool Airport , Orvakal"
        },
        {
            "value": "KJH",
            "icao_code": null,
            "label": "Kaili Huangping Airport , Kaili"
        },
        {
            "value": "KJI",
            "icao_code": "ZWKN",
            "label": "Kanas Airport , Burqin"
        },
        {
            "value": "KJK",
            "icao_code": "EBKT",
            "label": "Kortrijk\u2013Wevelgem International Airport , Kortrijk"
        },
        {
            "value": "KJP",
            "icao_code": "ROKR",
            "label": "Kerama Airport , Fukaji Island"
        },
        {
            "value": "KJT",
            "icao_code": "WICA",
            "label": "Kertajati International Airport , Majalengka"
        },
        {
            "value": "KJU",
            "icao_code": null,
            "label": "Kamiraba Airport , Kamiraba"
        },
        {
            "value": "KJX",
            "icao_code": null,
            "label": "Blangpidie Airport , Blangpidie"
        },
        {
            "value": "KKA",
            "icao_code": "PAKK",
            "label": "Koyuk Alfred Adams Airport , Koyuk"
        },
        {
            "value": "KKB",
            "icao_code": null,
            "label": "Kitoi Bay Seaplane Base , Kitoi Bay"
        },
        {
            "value": "KKC",
            "icao_code": "VTUK",
            "label": "Khon Kaen Airport , Khon Kaen"
        },
        {
            "value": "KKD",
            "icao_code": "AYKO",
            "label": "Kokoda Airport , Kokoda"
        },
        {
            "value": "KKE",
            "icao_code": "NZKK",
            "label": "Kerikeri Airport , Kerikeri"
        },
        {
            "value": "KKG",
            "icao_code": null,
            "label": "Konawaruk Airport , Konawaruk"
        },
        {
            "value": "KKH",
            "icao_code": "PADY",
            "label": "Kongiganak Airport , Kongiganak"
        },
        {
            "value": "KKI",
            "icao_code": null,
            "label": "Akiachak Airport , Akiachak"
        },
        {
            "value": "KKJ",
            "icao_code": "RJFR",
            "label": "Kitakyushu Airport , Kitakyushu"
        },
        {
            "value": "KKK",
            "icao_code": null,
            "label": "Kalakaket Creek Air Station , Kalakaket Creek"
        },
        {
            "value": "KKL",
            "icao_code": null,
            "label": "Karluk Lake Seaplane Base , Karluk Lake"
        },
        {
            "value": "KKM",
            "icao_code": "VTBL",
            "label": "Khok Kathiam Air Force Base , Lopburi"
        },
        {
            "value": "KKN",
            "icao_code": "ENKR",
            "label": "Kirkenes Airport, H\u00f8ybuktmoen , Kirkenes"
        },
        {
            "value": "KKO",
            "icao_code": "NZKO",
            "label": "Kaikohe Aerodrome , Kaikohe"
        },
        {
            "value": "KKP",
            "icao_code": "YKLB",
            "label": "Koolburra Airport , Koolburra"
        },
        {
            "value": "KKQ",
            "icao_code": "USDP",
            "label": "Krasnoselkup Airport , Krasnoselkup"
        },
        {
            "value": "KKR",
            "icao_code": "NTGK",
            "label": "Kaukura Airport , Kaukura Atoll"
        },
        {
            "value": "KKS",
            "icao_code": "OIFK",
            "label": "Kashan Airport , Kashan"
        },
        {
            "value": "KKT",
            "icao_code": null,
            "label": "Kentland Municipal Airport , Kentland"
        },
        {
            "value": "KKU",
            "icao_code": null,
            "label": "Ekuk Airport , Ekuk"
        },
        {
            "value": "KKW",
            "icao_code": "FZCA",
            "label": "Kikwit Airport , Kikwit"
        },
        {
            "value": "KKX",
            "icao_code": "RJKI",
            "label": "Kikai Airport , Kikai"
        },
        {
            "value": "KKY",
            "icao_code": "EIKK",
            "label": "Kilkenny Airport , Kilkenny"
        },
        {
            "value": "KKZ",
            "icao_code": "VDKK",
            "label": "Koh Kong Airport , Koh Kong"
        },
        {
            "value": "KLB",
            "icao_code": "FLKL",
            "label": "Kalabo Airport , Kalabo"
        },
        {
            "value": "KLC",
            "icao_code": "GOOK",
            "label": "Kaolack Airport , Kaolack"
        },
        {
            "value": "KLD",
            "icao_code": "UUEM",
            "label": "Migalovo Air Base , Tver"
        },
        {
            "value": "KLE",
            "icao_code": "FKKH",
            "label": "Ka\u00e9l\u00e9 Airport , Ka\u00e9l\u00e9"
        },
        {
            "value": "KLF",
            "icao_code": "UUBC",
            "label": "Grabtsevo Airport , Kaluga"
        },
        {
            "value": "KLG",
            "icao_code": "PALG",
            "label": "Kalskag Airport , Kalskag"
        },
        {
            "value": "KLH",
            "icao_code": "VAKP",
            "label": "Kolhapur Airport , Kolhapur"
        },
        {
            "value": "KLI",
            "icao_code": "FZFP",
            "label": "Kotakoli Air Base , Kotakoli"
        },
        {
            "value": "KLK",
            "icao_code": "HKFG",
            "label": "Kalokol Airport , Kalokol"
        },
        {
            "value": "KLL",
            "icao_code": null,
            "label": "Levelock Airport , Levelock"
        },
        {
            "value": "KLM",
            "icao_code": "OINE",
            "label": "Kalaleh Airport , Kalaleh"
        },
        {
            "value": "KLN",
            "icao_code": "PALB",
            "label": "Larsen Bay Airport , Larsen Bay"
        },
        {
            "value": "KLO",
            "icao_code": "RPVK",
            "label": "Kalibo International Airport , Kalibo"
        },
        {
            "value": "KLQ",
            "icao_code": "WIPV",
            "label": "Keluang Airport , Keluang"
        },
        {
            "value": "KLR",
            "icao_code": "ESMQ",
            "label": "Kalmar Airport , Kalmar"
        },
        {
            "value": "KLS",
            "icao_code": "KKLS",
            "label": "Southwest Washington Regional Airport , Kelso"
        },
        {
            "value": "KLU",
            "icao_code": "LOWK",
            "label": "Klagenfurt Airport , Klagenfurt"
        },
        {
            "value": "KLV",
            "icao_code": "LKKV",
            "label": "Karlovy Vary Airport , Karlovy Vary"
        },
        {
            "value": "KLW",
            "icao_code": "PAKW",
            "label": "Klawock Airport , Klawock"
        },
        {
            "value": "KLX",
            "icao_code": "LGKL",
            "label": "Kalamata International Airport , Kalamata"
        },
        {
            "value": "KLY",
            "icao_code": "FZOD",
            "label": "Kamisuku Airport , Kalima"
        },
        {
            "value": "KLZ",
            "icao_code": "FAKZ",
            "label": "Kleinzee Airport , Kleinzee"
        },
        {
            "value": "KMA",
            "icao_code": "AYKM",
            "label": "Kerema Airport , Kerema"
        },
        {
            "value": "KMB",
            "icao_code": null,
            "label": "Koinambe Airport , Koinambe"
        },
        {
            "value": "KMC",
            "icao_code": "OEKK",
            "label": "King Khaled Military City Airport , King Khalid Military City"
        },
        {
            "value": "KME",
            "icao_code": "HRZA",
            "label": "Kamembe Airport , Cyangugu"
        },
        {
            "value": "KMF",
            "icao_code": "AYKD",
            "label": "Kamina Airport , Kamina"
        },
        {
            "value": "KMG",
            "icao_code": "ZPPP",
            "label": "Kunming Changshui International Airport , Kunming"
        },
        {
            "value": "KMH",
            "icao_code": "FAKU",
            "label": "Johan Pienaar Airport , Kuruman"
        },
        {
            "value": "KMI",
            "icao_code": "RJFM",
            "label": "Miyazaki Airport , Miyazaki"
        },
        {
            "value": "KMJ",
            "icao_code": "RJFT",
            "label": "Kumamoto Airport , Mashiki"
        },
        {
            "value": "KMK",
            "icao_code": "FCPA",
            "label": "Makabana Airport , Makabana"
        },
        {
            "value": "KML",
            "icao_code": "YKML",
            "label": "Kamileroi Airport , Kamileroi"
        },
        {
            "value": "KMM",
            "icao_code": null,
            "label": "Kimam Airport , Kimam"
        },
        {
            "value": "KMN",
            "icao_code": "FZSB",
            "label": "Kamina Airport , Kamina"
        },
        {
            "value": "KMO",
            "icao_code": "PAMB",
            "label": "Manokotak Airport , Manokotak"
        },
        {
            "value": "KMP",
            "icao_code": "FYKT",
            "label": "Keetmanshoop Airport , Keetmanshoop"
        },
        {
            "value": "KMQ",
            "icao_code": "RJNK",
            "label": "Komatsu Airport , Komatsu"
        },
        {
            "value": "KMR",
            "icao_code": "AYRI",
            "label": "Karimui Airport , Karimui"
        },
        {
            "value": "KMS",
            "icao_code": "DGSI",
            "label": "Kumasi Airport , Kumasi"
        },
        {
            "value": "KMT",
            "icao_code": null,
            "label": "Kampot Airport , Kampot"
        },
        {
            "value": "KMU",
            "icao_code": "HCMK",
            "label": "Kismayo Airport , Kismayo"
        },
        {
            "value": "KMV",
            "icao_code": "VYKL",
            "label": "Kalaymyo Airport , Kalaymyo"
        },
        {
            "value": "KMW",
            "icao_code": "UUBA",
            "label": "Kostroma Airport , Kostroma"
        },
        {
            "value": "KMX",
            "icao_code": "OEKM",
            "label": "King Khalid Air Base , Khamis Mushait"
        },
        {
            "value": "KMY",
            "icao_code": null,
            "label": "Moser Bay Seaplane Base , Moser Bay"
        },
        {
            "value": "KMZ",
            "icao_code": "FLKO",
            "label": "Kaoma Airport , Kaoma"
        },
        {
            "value": "KNA",
            "icao_code": "SCVM",
            "label": "Vi\u00f1a del Mar Airport , Vi\u00f1a del Mar"
        },
        {
            "value": "KNB",
            "icao_code": "KKNB",
            "label": "Kanab Municipal Airport , Kanab"
        },
        {
            "value": "KND",
            "icao_code": "FZOA",
            "label": "Kindu Airport , Kindu"
        },
        {
            "value": "KNE",
            "icao_code": "AYKJ",
            "label": "Kanainj Airport , Kanainj"
        },
        {
            "value": "KNF",
            "icao_code": "EGYM",
            "label": "RAF Marham , King's Lynn"
        },
        {
            "value": "KNG",
            "icao_code": "WASK",
            "label": "Kaimana Airport , Kaimana"
        },
        {
            "value": "KNH",
            "icao_code": "RCBS",
            "label": "Kinmen Airport , Kinmen"
        },
        {
            "value": "KNI",
            "icao_code": "YKNG",
            "label": "Katanning Airport , Katanning"
        },
        {
            "value": "KNJ",
            "icao_code": "FCBK",
            "label": "Kindamba Airport , Kindamba"
        },
        {
            "value": "KNK",
            "icao_code": "PFKK",
            "label": "Kokhanok Airport , Kokhanok"
        },
        {
            "value": "KNL",
            "icao_code": null,
            "label": "Kelanoa Airport , Kelanoa"
        },
        {
            "value": "KNM",
            "icao_code": "FZTK",
            "label": "Kaniama Airport , Kaniama"
        },
        {
            "value": "KNN",
            "icao_code": "GUXN",
            "label": "Kankan Airport , Kankan"
        },
        {
            "value": "KNO",
            "icao_code": "WIMM",
            "label": "Kualanamu International Airport , Medan"
        },
        {
            "value": "KNP",
            "icao_code": "FNCP",
            "label": "Kapanda Airport , Capanda"
        },
        {
            "value": "KNQ",
            "icao_code": "NWWD",
            "label": "Kon\u00e9 Airport , Kon\u00e9"
        },
        {
            "value": "KNR",
            "icao_code": "OIBJ",
            "label": "Jam Airport , Kangan"
        },
        {
            "value": "KNS",
            "icao_code": "YKII",
            "label": "King Island Airport , King Island"
        },
        {
            "value": "KNT",
            "icao_code": "KTKX",
            "label": "Kennett Memorial Airport , Kennett"
        },
        {
            "value": "KNU",
            "icao_code": "VIKA",
            "label": "Kanpur Airport , Kanpur"
        },
        {
            "value": "KNW",
            "icao_code": "PANW",
            "label": "New Stuyahok Airport , New Stuyahok"
        },
        {
            "value": "KNX",
            "icao_code": "YPKU",
            "label": "East Kimberley Regional Airport , Kununurra"
        },
        {
            "value": "KNZ",
            "icao_code": "GAKA",
            "label": "K\u00e9ni\u00e9ba Airport , K\u00e9ni\u00e9ba"
        },
        {
            "value": "KOA",
            "icao_code": "PHKO",
            "label": "Kona International Airport at Ke\u0101hole , Kailua"
        },
        {
            "value": "KOC",
            "icao_code": "NWWK",
            "label": "Koumac Airport , Koumac"
        },
        {
            "value": "KOD",
            "icao_code": null,
            "label": "Kotabangun Airport , Kotabangun"
        },
        {
            "value": "KOE",
            "icao_code": "WATT",
            "label": "El Tari Airport , Kupang"
        },
        {
            "value": "KOF",
            "icao_code": "FAKP",
            "label": "Komatipoort Airport , Komatipoort"
        },
        {
            "value": "KOG",
            "icao_code": "VLKG",
            "label": "Khong Island Airport , Khong"
        },
        {
            "value": "KOH",
            "icao_code": "YKLA",
            "label": "Koolatah Airport , Koolatah"
        },
        {
            "value": "KOI",
            "icao_code": "EGPA",
            "label": "Kirkwall Airport , Kirkwall"
        },
        {
            "value": "KOJ",
            "icao_code": "RJFK",
            "label": "Kagoshima Airport , Kagoshima"
        },
        {
            "value": "KOK",
            "icao_code": "EFKK",
            "label": "Kokkola-Pietarsaari Airport , Kokkola"
        },
        {
            "value": "KOL",
            "icao_code": null,
            "label": "Koumala Airport , Koumala"
        },
        {
            "value": "KOM",
            "icao_code": null,
            "label": "Komo-Manda Airport , Komo-Manda"
        },
        {
            "value": "KON",
            "icao_code": null,
            "label": "Kontum Airport , Kon Tum"
        },
        {
            "value": "KOO",
            "icao_code": "FZRQ",
            "label": "Kongolo Airport , Kongolo"
        },
        {
            "value": "KOP",
            "icao_code": "VTUW",
            "label": "Nakhon Phanom Airport , Nakhon Phanom"
        },
        {
            "value": "KOQ",
            "icao_code": "EDCK",
            "label": "K\u00f6then Airport , K\u00f6then"
        },
        {
            "value": "KOR",
            "icao_code": "AYRO",
            "label": "Kakoro Airport , Kakoro"
        },
        {
            "value": "KOS",
            "icao_code": "VDSV",
            "label": "Sihanoukville International Airport , Sihanoukville"
        },
        {
            "value": "KOT",
            "icao_code": "PFKO",
            "label": "Kotlik Airport , Kotlik"
        },
        {
            "value": "KOU",
            "icao_code": "FOGK",
            "label": "Koulamoutou Airport , Koulamoutou"
        },
        {
            "value": "KOV",
            "icao_code": "UACK",
            "label": "Kokshetau Airport , Kokshetau"
        },
        {
            "value": "KOW",
            "icao_code": "ZSGZ",
            "label": "Ganzhou Huangjin Airport , Ganzhou"
        },
        {
            "value": "KOX",
            "icao_code": "WABN",
            "label": "Kokonao Airport , Kokonao"
        },
        {
            "value": "KOY",
            "icao_code": null,
            "label": "Olga Bay Seaplane Base , Olga Bay"
        },
        {
            "value": "KOZ",
            "icao_code": null,
            "label": "Ouzinkie Airport , Ouzinkie"
        },
        {
            "value": "KPA",
            "icao_code": "AYKG",
            "label": "Kopiago Airport , Kopiago"
        },
        {
            "value": "KPB",
            "icao_code": null,
            "label": "Point Baker Seaplane Base , Point Baker"
        },
        {
            "value": "KPC",
            "icao_code": "PAPC",
            "label": "Port Clarence Coast Guard Station , Port Clarence"
        },
        {
            "value": "KPE",
            "icao_code": "AYYP",
            "label": "Yapsiei Airport , Yapsiei"
        },
        {
            "value": "KPF",
            "icao_code": "AYDL",
            "label": "Kondubol Airport , Kondubol"
        },
        {
            "value": "KPG",
            "icao_code": null,
            "label": "Kurupung Airport , Kurupung"
        },
        {
            "value": "KPI",
            "icao_code": "WBGP",
            "label": "Kapit Airport , Kapit"
        },
        {
            "value": "KPL",
            "icao_code": null,
            "label": "Kapal Airport , Kapal"
        },
        {
            "value": "KPM",
            "icao_code": "AYAQ",
            "label": "Kompiam Airport , Kompiam"
        },
        {
            "value": "KPN",
            "icao_code": "PAKI",
            "label": "Kipnuk Airport , Kipnuk"
        },
        {
            "value": "KPO",
            "icao_code": "RKTH",
            "label": "Pohang Airport , Pohang"
        },
        {
            "value": "KPP",
            "icao_code": "YKPR",
            "label": "Kalpowar Airport , Kalpowar"
        },
        {
            "value": "KPR",
            "icao_code": null,
            "label": "Port Williams Seaplane Base , Port Williams"
        },
        {
            "value": "KPS",
            "icao_code": "YKMP",
            "label": "Kempsey Airport , Kempsey"
        },
        {
            "value": "KPT",
            "icao_code": null,
            "label": "Jackpot Airport , Jackpot"
        },
        {
            "value": "KPV",
            "icao_code": "PAPE",
            "label": "Perryville Airport , Perryville"
        },
        {
            "value": "KPW",
            "icao_code": "UHMK",
            "label": "Keperveyem Airport , Keperveyem"
        },
        {
            "value": "KPY",
            "icao_code": null,
            "label": "Port Bailey Seaplane Base , Port Bailey"
        },
        {
            "value": "KQA",
            "icao_code": "PAUT",
            "label": "Akutan Airport , Akutan"
        },
        {
            "value": "KQH",
            "icao_code": "VIKG",
            "label": "Ajmer Kishangarh Airport , Kishangarh"
        },
        {
            "value": "KQL",
            "icao_code": "AYOL",
            "label": "Kol Airport , Kol"
        },
        {
            "value": "KQR",
            "icao_code": "YKAR",
            "label": "Karara Airport , Karara"
        },
        {
            "value": "KQT",
            "icao_code": "UTDT",
            "label": "Bokhtar International Airport , Bokhtar"
        },
        {
            "value": "KRA",
            "icao_code": "YKER",
            "label": "Kerang Airport , Kerang"
        },
        {
            "value": "KRB",
            "icao_code": "YKMB",
            "label": "Karumba Airport , Karumba"
        },
        {
            "value": "KRC",
            "icao_code": "WIPH",
            "label": "Depati Parbo Airport , Kerinci"
        },
        {
            "value": "KRD",
            "icao_code": null,
            "label": "Kurundi Airport , Kurundi Station"
        },
        {
            "value": "KRE",
            "icao_code": "HBBO",
            "label": "Kirundo Airport , Kirundo"
        },
        {
            "value": "KRF",
            "icao_code": "ESNK",
            "label": "H\u00f6ga Kusten Airport , Kramfors"
        },
        {
            "value": "KRG",
            "icao_code": "SYKS",
            "label": "Karasabai Airport , Karasabai"
        },
        {
            "value": "KRI",
            "icao_code": "AYKK",
            "label": "Kikori Airport , Kikori"
        },
        {
            "value": "KRJ",
            "icao_code": "AYQA",
            "label": "Karawari Airport , Karawari"
        },
        {
            "value": "KRK",
            "icao_code": "EPKK",
            "label": "John Paul II International Airport Krak\u00f3w\u2013Balice , Krak\u00f3w"
        },
        {
            "value": "KRL",
            "icao_code": "ZWKL",
            "label": "Korla Airport , Korla"
        },
        {
            "value": "KRM",
            "icao_code": "SYKR",
            "label": "Karanambo Airport , Karanambo"
        },
        {
            "value": "KRN",
            "icao_code": "ESNQ",
            "label": "Kiruna Airport , Kiruna"
        },
        {
            "value": "KRO",
            "icao_code": "USUU",
            "label": "Kurgan Airport , Kurgan"
        },
        {
            "value": "KRP",
            "icao_code": "EKKA",
            "label": "Karup Airport , Karup"
        },
        {
            "value": "KRQ",
            "icao_code": "UKCK",
            "label": "Kramatorsk Airport , Kramatorsk"
        },
        {
            "value": "KRR",
            "icao_code": "URKK",
            "label": "Krasnodar International Airport , Krasnodar"
        },
        {
            "value": "KRS",
            "icao_code": "ENCN",
            "label": "Kristiansand Airport, Kjevik , Kristiansand"
        },
        {
            "value": "KRT",
            "icao_code": "HSSK",
            "label": "Khartoum International Airport , Khartoum"
        },
        {
            "value": "KRU",
            "icao_code": "AYEA",
            "label": "Kerau Airport , Kerau"
        },
        {
            "value": "KRV",
            "icao_code": null,
            "label": "Kimwarer Airport , Kimwarer"
        },
        {
            "value": "KRW",
            "icao_code": "UTAK",
            "label": "Turkmenbashi International Airport , T\u00fcrkmenba\u015fy"
        },
        {
            "value": "KRX",
            "icao_code": "AYKR",
            "label": "Karkar Airport , Karkar Island"
        },
        {
            "value": "KRY",
            "icao_code": "ZWKM",
            "label": "Karamay Airport , Karamay"
        },
        {
            "value": "KRZ",
            "icao_code": "FZBT",
            "label": "Basango Mboliasa Airport , Kiri"
        },
        {
            "value": "KSA",
            "icao_code": "PTSA",
            "label": "Kosrae International Airport , Kosrae"
        },
        {
            "value": "KSB",
            "icao_code": "AYNM",
            "label": "Kasanombe Airport , Kasanombe"
        },
        {
            "value": "KSC",
            "icao_code": "LZKZ",
            "label": "Ko\u0161ice International Airport , Ko\u0161ice"
        },
        {
            "value": "KSD",
            "icao_code": "ESOK",
            "label": "Karlstad Airport , Karlstad"
        },
        {
            "value": "KSE",
            "icao_code": "HUKS",
            "label": "Kasese Airport , Kasese"
        },
        {
            "value": "KSF",
            "icao_code": "EDVK",
            "label": "Kassel Airport , Kassel"
        },
        {
            "value": "KSG",
            "icao_code": null,
            "label": "Kisengam Airport , Kisengam"
        },
        {
            "value": "KSH",
            "icao_code": "OICC",
            "label": "Shahid Ashrafi Esfahani Airport , Kermanshah"
        },
        {
            "value": "KSI",
            "icao_code": "GUKU",
            "label": "Kissidougou Airport , Kissidougou"
        },
        {
            "value": "KSJ",
            "icao_code": "LGKS",
            "label": "Kasos Island Public Airport , Kasos Island"
        },
        {
            "value": "KSK",
            "icao_code": "ESKK",
            "label": "Karlskoga Airport , Karlskoga"
        },
        {
            "value": "KSL",
            "icao_code": "HSKA",
            "label": "Kassala Airport , Kassala"
        },
        {
            "value": "KSM",
            "icao_code": "PASM",
            "label": "St. Mary's Airport , St. Mary's"
        },
        {
            "value": "KSN",
            "icao_code": "UAUU",
            "label": "Kostanay Airport , Kostanay"
        },
        {
            "value": "KSO",
            "icao_code": "LGKA",
            "label": "Kastoria National Airport , Kastoria"
        },
        {
            "value": "KSP",
            "icao_code": "AYOP",
            "label": "Kosipe Airport , Kosipe"
        },
        {
            "value": "KSQ",
            "icao_code": "UTSL",
            "label": "Karshi Airport , Karshi"
        },
        {
            "value": "KSR",
            "icao_code": "WAWH",
            "label": "H. Aroeppala Airport , Selayar Islands"
        },
        {
            "value": "KSS",
            "icao_code": "GASK",
            "label": "Sikasso Airport , Sikasso"
        },
        {
            "value": "KST",
            "icao_code": "HSKI",
            "label": "Rabak Airport , Kosti"
        },
        {
            "value": "KSU",
            "icao_code": "ENKB",
            "label": "Kristiansund Airport, Kvernberget , Kristiansund"
        },
        {
            "value": "KSV",
            "icao_code": "YSPV",
            "label": "Springvale Airport , Springvale"
        },
        {
            "value": "KSW",
            "icao_code": "LLKS",
            "label": "Kiryat Shmona Airport , Kiryat Shmona"
        },
        {
            "value": "KSX",
            "icao_code": "AYYR",
            "label": "Yasuru Airport , Yasuru"
        },
        {
            "value": "KSY",
            "icao_code": "LTCF",
            "label": "Kars Harakani Airport , Kars"
        },
        {
            "value": "KSZ",
            "icao_code": "ULKK",
            "label": "Kotlas Airport , Kotlas"
        },
        {
            "value": "KTA",
            "icao_code": "YPKA",
            "label": "Karratha Airport , Karratha"
        },
        {
            "value": "KTB",
            "icao_code": null,
            "label": "Thorne Bay Seaplane Base , Thorne Bay"
        },
        {
            "value": "KTC",
            "icao_code": null,
            "label": "Katiola Airport , Katiola"
        },
        {
            "value": "KTD",
            "icao_code": "RORK",
            "label": "Kitadaito Airport , Kitadaito"
        },
        {
            "value": "KTE",
            "icao_code": "WMKE",
            "label": "Kerteh Airport , Kerteh"
        },
        {
            "value": "KTF",
            "icao_code": "NZTK",
            "label": "Takaka Aerodrome , Takaka"
        },
        {
            "value": "KTG",
            "icao_code": "WIOK",
            "label": "Rahadi Osman Airport , Ketapang"
        },
        {
            "value": "KTH",
            "icao_code": null,
            "label": "Tikchik Lodge Seaplane Base , Tikchik"
        },
        {
            "value": "KTI",
            "icao_code": "VDKT",
            "label": "Krati\u00e9 Airport , Krati\u00e9"
        },
        {
            "value": "KTJ",
            "icao_code": null,
            "label": "Kichwa Tembo Airport , Kichwa Tembo"
        },
        {
            "value": "KTK",
            "icao_code": null,
            "label": "Kunua Airport , Kunua"
        },
        {
            "value": "KTL",
            "icao_code": "HKKT",
            "label": "Kitale Airport , Kitale"
        },
        {
            "value": "KTM",
            "icao_code": "VNKT",
            "label": "Tribhuvan International Airport , Kathmandu"
        },
        {
            "value": "KTN",
            "icao_code": "PAKT",
            "label": "Ketchikan International Airport , Ketchikan"
        },
        {
            "value": "KTO",
            "icao_code": "SYKT",
            "label": "Kato Airport , Kato"
        },
        {
            "value": "KTP",
            "icao_code": "MKTP",
            "label": "Tinson Pen Aerodrome , Kingston"
        },
        {
            "value": "KTQ",
            "icao_code": "EFIT",
            "label": "Kitee Airfield , Kitee"
        },
        {
            "value": "KTR",
            "icao_code": "YPTN",
            "label": "RAAF Base Tindal , Katherine"
        },
        {
            "value": "KTS",
            "icao_code": "PFKT",
            "label": "Brevig Mission Airport , Brevig Mission"
        },
        {
            "value": "KTT",
            "icao_code": "EFKT",
            "label": "Kittil\u00e4 Airport , Kittil\u00e4"
        },
        {
            "value": "KTU",
            "icao_code": "VIKO",
            "label": "Kota Airport , Kota"
        },
        {
            "value": "KTV",
            "icao_code": "SVKM",
            "label": "Kamarata Airport , Kamarata"
        },
        {
            "value": "KTW",
            "icao_code": "EPKT",
            "label": "Katowice International Airport , Katowice"
        },
        {
            "value": "KTX",
            "icao_code": "GAKO",
            "label": "Koutiala Airport , Koutiala"
        },
        {
            "value": "KTY",
            "icao_code": "VCCN",
            "label": "Katukurunda Airport , Kalutara"
        },
        {
            "value": "KUA",
            "icao_code": "WMKD",
            "label": "Sultan Haji Ahmad Shah Airport , Kuantan"
        },
        {
            "value": "KUC",
            "icao_code": "NGKT",
            "label": "Kuria Airport , Kuria"
        },
        {
            "value": "KUD",
            "icao_code": "WBKT",
            "label": "Kudat Airport , Kudat"
        },
        {
            "value": "KUE",
            "icao_code": "AGKU",
            "label": "Kukundu Airport , Kolombangara"
        },
        {
            "value": "KUF",
            "icao_code": "UWWW",
            "label": "Kurumoch International Airport , Samara"
        },
        {
            "value": "KUG",
            "icao_code": "YKUB",
            "label": "Kubin Airport , Moa Island"
        },
        {
            "value": "KUH",
            "icao_code": "RJCK",
            "label": "Kushiro Airport , Kushiro"
        },
        {
            "value": "KUK",
            "icao_code": "PFKA",
            "label": "Kasigluk Airport , Kasigluk"
        },
        {
            "value": "KUL",
            "icao_code": "WMKK",
            "label": "Kuala Lumpur International Airport , Kuala Lumpur"
        },
        {
            "value": "KUM",
            "icao_code": "RJFC",
            "label": "Yakushima Airport , Yakushima"
        },
        {
            "value": "KUN",
            "icao_code": "EYKA",
            "label": "Kaunas International Airport , Kaunas"
        },
        {
            "value": "KUO",
            "icao_code": "EFKU",
            "label": "Kuopio Airport , Kuopio"
        },
        {
            "value": "KUP",
            "icao_code": null,
            "label": "Kupiano Airport , Kupiano"
        },
        {
            "value": "KUQ",
            "icao_code": "AYKU",
            "label": "Kuri Airport , Kuri"
        },
        {
            "value": "KUR",
            "icao_code": "OARZ",
            "label": "Razer Airport , Koran va Monjan"
        },
        {
            "value": "KUS",
            "icao_code": "BGKK",
            "label": "Kulusuk Airport , Kulusuk"
        },
        {
            "value": "KUT",
            "icao_code": "UGKO",
            "label": "David the Builder Kutaisi International Airport , Kutaisi"
        },
        {
            "value": "KUU",
            "icao_code": "VIBR",
            "label": "Bhuntar Airport , Kullu"
        },
        {
            "value": "KUV",
            "icao_code": "RKJK",
            "label": "Gunsan Airport , Gunsan"
        },
        {
            "value": "KUX",
            "icao_code": null,
            "label": "Kuyol Airport , Kuyol"
        },
        {
            "value": "KUY",
            "icao_code": null,
            "label": "Kamusi Airport , Kamusi"
        },
        {
            "value": "KVA",
            "icao_code": "LGKV",
            "label": "Kavala International Airport , Kavala"
        },
        {
            "value": "KVB",
            "icao_code": "ESGR",
            "label": "Sk\u00f6vde Airport , Sk\u00f6vde"
        },
        {
            "value": "KVC",
            "icao_code": "PAVC",
            "label": "King Cove Airport , King Cove"
        },
        {
            "value": "KVD",
            "icao_code": "UBBG",
            "label": "Ganja International Airport , Ganja"
        },
        {
            "value": "KVE",
            "icao_code": null,
            "label": "Kitava Island Airport , Kitava Island"
        },
        {
            "value": "KVG",
            "icao_code": "AYKV",
            "label": "Kavieng Airport , Kavieng"
        },
        {
            "value": "KVK",
            "icao_code": "ULMK",
            "label": "Kirovsk-Apatity Airport , Kirovsk"
        },
        {
            "value": "KVL",
            "icao_code": "PAVL",
            "label": "Kivalina Airport , Kivalina"
        },
        {
            "value": "KVM",
            "icao_code": "UHMO",
            "label": "Markovo Airport , Markovo"
        },
        {
            "value": "KVO",
            "icao_code": "LYKV",
            "label": "Morava Airport , La\u0111evci"
        },
        {
            "value": "KVR",
            "icao_code": null,
            "label": "Kavalerovo Airport , Kavalerovo"
        },
        {
            "value": "KVU",
            "icao_code": null,
            "label": "Korolevu Seaplane Base , Korolevu"
        },
        {
            "value": "KVX",
            "icao_code": "USKK",
            "label": "Pobedilovo Airport , Kirov"
        },
        {
            "value": "KWA",
            "icao_code": "PKWA",
            "label": "Bucholz Army Airfield , Kwajalein"
        },
        {
            "value": "KWB",
            "icao_code": null,
            "label": "Dewadaru Airport , Karimun Java"
        },
        {
            "value": "KWD",
            "icao_code": null,
            "label": "Kavadja Airport , Kavadja"
        },
        {
            "value": "KWE",
            "icao_code": "ZUGY",
            "label": "Guiyang Longdongbao International Airport , Guiyang"
        },
        {
            "value": "KWF",
            "icao_code": null,
            "label": "Waterfall Seaplane Base , Waterfall"
        },
        {
            "value": "KWG",
            "icao_code": "UKDR",
            "label": "Kryvyi Rih International Airport , Kryvyi Rih"
        },
        {
            "value": "KWH",
            "icao_code": "OAHN",
            "label": "Khwahan Airport , Khwahan"
        },
        {
            "value": "KWI",
            "icao_code": "OKBK",
            "label": "Kuwait International Airport , Kuwait City"
        },
        {
            "value": "KWJ",
            "icao_code": "RKJJ",
            "label": "Gwangju Airport , Gwangju"
        },
        {
            "value": "KWK",
            "icao_code": "PAGG",
            "label": "Kwigillingok Airport , Kwigillingok"
        },
        {
            "value": "KWL",
            "icao_code": "ZGKL",
            "label": "Guilin Liangjiang International Airport , Guilin"
        },
        {
            "value": "KWM",
            "icao_code": "YKOW",
            "label": "Kowanyama Airport , Kowanyama"
        },
        {
            "value": "KWN",
            "icao_code": "PAQH",
            "label": "Quinhagak Airport , Quinhagak"
        },
        {
            "value": "KWO",
            "icao_code": "AYKW",
            "label": "Kawito Airport , Kawito"
        },
        {
            "value": "KWP",
            "icao_code": null,
            "label": "West Point Village Seaplane Base , West Point"
        },
        {
            "value": "KWR",
            "icao_code": null,
            "label": "Kwai Harbour Airport , Kwai Harbour"
        },
        {
            "value": "KWS",
            "icao_code": "AGKW",
            "label": "Kwailabesi Airport , Kwailabesi"
        },
        {
            "value": "KWT",
            "icao_code": "PFKW",
            "label": "Kwethluk Airport , Kwethluk"
        },
        {
            "value": "KWV",
            "icao_code": null,
            "label": "Kurwina Airport , Kurwina"
        },
        {
            "value": "KWX",
            "icao_code": null,
            "label": "Kiwai Island Airport , Kiwai Island"
        },
        {
            "value": "KWY",
            "icao_code": null,
            "label": "Kiwayu Airport , Kiwayu"
        },
        {
            "value": "KWZ",
            "icao_code": "FZQM",
            "label": "Kolwezi Airport , Kolwezi"
        },
        {
            "value": "KXA",
            "icao_code": null,
            "label": "Kasaan Seaplane Base , Kasaan"
        },
        {
            "value": "KXD",
            "icao_code": "USHK",
            "label": "Kondinskoye Airport , Kondinskoye"
        },
        {
            "value": "KXE",
            "icao_code": "FAKD",
            "label": "Klerksdorp Airport , Klerksdorp"
        },
        {
            "value": "KXF",
            "icao_code": "NFNO",
            "label": "Koro Airport , Koro Island"
        },
        {
            "value": "KXK",
            "icao_code": "UHKK",
            "label": "Komsomolsk-on-Amur Airport , Komsomolsk-on-Amur"
        },
        {
            "value": "KXR",
            "icao_code": null,
            "label": "Karoola Airport , Karoola"
        },
        {
            "value": "KXU",
            "icao_code": "NTKT",
            "label": "Katiu Airport , Katiu"
        },
        {
            "value": "KYA",
            "icao_code": "LTAN",
            "label": "Konya Airport , Konya"
        },
        {
            "value": "KYD",
            "icao_code": "RCLY",
            "label": "Lanyu Airport , Orchid Island"
        },
        {
            "value": "KYE",
            "icao_code": "OLKA",
            "label": "Rene Mouawad Air Base , Tripoli"
        },
        {
            "value": "KYF",
            "icao_code": "YYLR",
            "label": "Yeelirrie Airport , Yeelirrie"
        },
        {
            "value": "KYI",
            "icao_code": "YYTA",
            "label": "Yalata Airport , Yalata Mission"
        },
        {
            "value": "KYK",
            "icao_code": "PAKY",
            "label": "Karluk Airport , Karluk"
        },
        {
            "value": "KYO",
            "icao_code": null,
            "label": "Tampa North Aero Park , Tampa"
        },
        {
            "value": "KYP",
            "icao_code": "VYKP",
            "label": "Kyaukpyu Airport , Kyaukpyu"
        },
        {
            "value": "KYS",
            "icao_code": "GAKY",
            "label": "Kayes Airport , Kayes"
        },
        {
            "value": "KYT",
            "icao_code": "VYKU",
            "label": "Kyauktu Airport , Kyaukhtu"
        },
        {
            "value": "KYU",
            "icao_code": "PFKU",
            "label": "Koyukuk Airport , Koyukuk"
        },
        {
            "value": "KYX",
            "icao_code": "AYYE",
            "label": "Yalumet Airport , Yalumet"
        },
        {
            "value": "KYZ",
            "icao_code": "UNKY",
            "label": "Kyzyl Airport , Kyzyl"
        },
        {
            "value": "KZB",
            "icao_code": null,
            "label": "Zachar Bay Seaplane Base , Zachar Bay"
        },
        {
            "value": "KZC",
            "icao_code": "VDKH",
            "label": "Kampong Chhnang Airport , Kampong Chhnang"
        },
        {
            "value": "KZD",
            "icao_code": null,
            "label": "Krakor Airport , Krakor"
        },
        {
            "value": "KZF",
            "icao_code": "AYKT",
            "label": "Kaintiba Airport , Kaintiba"
        },
        {
            "value": "KZG",
            "icao_code": "ETIN",
            "label": "Kitzingen Airport , Kitzingen"
        },
        {
            "value": "KZI",
            "icao_code": "LGKZ",
            "label": "Kozani National Airport , Kozani"
        },
        {
            "value": "KZN",
            "icao_code": "UWKD",
            "label": "Kazan International Airport , Kazan"
        },
        {
            "value": "KZO",
            "icao_code": "UAOO",
            "label": "Kyzylorda Airport , Kyzylorda"
        },
        {
            "value": "KZR",
            "icao_code": "LTBZ",
            "label": "Zafer Airport , K\u00fctahya"
        },
        {
            "value": "KZS",
            "icao_code": "LGKJ",
            "label": "Kastellorizo Island Public Airport , Kastellorizo"
        },
        {
            "value": "LAA",
            "icao_code": "KLAA",
            "label": "Lamar Municipal Airport , Lamar"
        },
        {
            "value": "LAB",
            "icao_code": null,
            "label": "Lab Lab Airport , Lab Lab"
        },
        {
            "value": "LAC",
            "icao_code": null,
            "label": "Layang-Layang Airport , Layang-Layang Island"
        },
        {
            "value": "LAD",
            "icao_code": "FNLU",
            "label": "Quatro de Fevereiro Airport , Luanda"
        },
        {
            "value": "LAE",
            "icao_code": "AYNZ",
            "label": "Lae Nadzab Airport , Lae"
        },
        {
            "value": "LAF",
            "icao_code": "KLAF",
            "label": "Purdue University Airport , Lafayette"
        },
        {
            "value": "LAH",
            "icao_code": "WAPH",
            "label": "Oesman Sadik Airport , Labuha"
        },
        {
            "value": "LAI",
            "icao_code": "LFRO",
            "label": "Lannion \u2013 C\u00f4te de Granit Airport , Lannion"
        },
        {
            "value": "LAJ",
            "icao_code": "SBLJ",
            "label": "Ant\u00f4nio Correia Pinto de Macedo Airport , Lages"
        },
        {
            "value": "LAK",
            "icao_code": "CYKD",
            "label": "Aklavik/Freddie Carmichael Airport , Aklavik"
        },
        {
            "value": "LAL",
            "icao_code": "KLAL",
            "label": "Lakeland Linder International Airport , Lakeland"
        },
        {
            "value": "LAM",
            "icao_code": "KLAM",
            "label": "Los Alamos County Airport , Los Alamos"
        },
        {
            "value": "LAN",
            "icao_code": "KLAN",
            "label": "Capital Region International Airport , Lansing"
        },
        {
            "value": "LAO",
            "icao_code": "RPLI",
            "label": "Laoag International Airport , Laoag"
        },
        {
            "value": "LAP",
            "icao_code": "MMLP",
            "label": "Manuel M\u00e1rquez de Le\u00f3n International Airport , La Paz"
        },
        {
            "value": "LAQ",
            "icao_code": "HLLQ",
            "label": "Al Abraq International Airport , Bayda"
        },
        {
            "value": "LAR",
            "icao_code": "KLAR",
            "label": "Laramie Regional Airport , Laramie"
        },
        {
            "value": "LAS",
            "icao_code": "KLAS",
            "label": "Harry Reid International Airport , Las Vegas"
        },
        {
            "value": "LAU",
            "icao_code": "HKLU",
            "label": "Manda Airport , Lamu"
        },
        {
            "value": "LAW",
            "icao_code": "KLAW",
            "label": "Lawton\u2013Fort Sill Regional Airport , Lawton"
        },
        {
            "value": "LAX",
            "icao_code": "KLAX",
            "label": "Los Angeles International Airport , Los Angeles"
        },
        {
            "value": "LAY",
            "icao_code": "FALY",
            "label": "Ladysmith Airport , Ladysmith"
        },
        {
            "value": "LAZ",
            "icao_code": "SBLP",
            "label": "Bom Jesus da Lapa Airport , Bom Jesus da Lapa"
        },
        {
            "value": "LBA",
            "icao_code": "EGNM",
            "label": "Leeds Bradford Airport , Leeds"
        },
        {
            "value": "LBB",
            "icao_code": "KLBB",
            "label": "Lubbock Preston Smith International Airport , Lubbock"
        },
        {
            "value": "LBC",
            "icao_code": "EDHL",
            "label": "L\u00fcbeck Airport , L\u00fcbeck"
        },
        {
            "value": "LBD",
            "icao_code": "UTDL",
            "label": "Khujand Airport , Khujand"
        },
        {
            "value": "LBE",
            "icao_code": "KLBE",
            "label": "Arnold Palmer Regional Airport , Latrobe"
        },
        {
            "value": "LBF",
            "icao_code": "KLBF",
            "label": "North Platte Regional Airport , North Platte"
        },
        {
            "value": "LBG",
            "icao_code": "LFPB",
            "label": "Paris\u2013Le Bourget Airport , Paris"
        },
        {
            "value": "LBH",
            "icao_code": null,
            "label": "Palm Beach Water Airport , Palm Beach"
        },
        {
            "value": "LBI",
            "icao_code": "LFCI",
            "label": "Le Sequestre Airport , Albi"
        },
        {
            "value": "LBJ",
            "icao_code": "WATO",
            "label": "Komodo Airport , Labuan Bajo"
        },
        {
            "value": "LBK",
            "icao_code": null,
            "label": "Liboi Airport , Liboi"
        },
        {
            "value": "LBL",
            "icao_code": "KLBL",
            "label": "Liberal Mid-America Regional Airport , Liberal"
        },
        {
            "value": "LBM",
            "icao_code": null,
            "label": "Luabo Airport , Luabo"
        },
        {
            "value": "LBN",
            "icao_code": null,
            "label": "Lake Baringo Airport , Lake Baringo"
        },
        {
            "value": "LBO",
            "icao_code": "FZVI",
            "label": "Lusambo Airport , Lusambo"
        },
        {
            "value": "LBP",
            "icao_code": null,
            "label": "Long Banga Airport , Long Banga"
        },
        {
            "value": "LBQ",
            "icao_code": "FOGR",
            "label": "Lambar\u00e9n\u00e9 Airport , Lambar\u00e9n\u00e9"
        },
        {
            "value": "LBR",
            "icao_code": "SWLB",
            "label": "L\u00e1brea Airport , L\u00e1brea"
        },
        {
            "value": "LBS",
            "icao_code": "NFNL",
            "label": "Labasa Airport , Labasa"
        },
        {
            "value": "LBT",
            "icao_code": "KLBT",
            "label": "Lumberton Municipal Airport , Lumberton"
        },
        {
            "value": "LBU",
            "icao_code": "WBKL",
            "label": "Labuan Airport , Labuan"
        },
        {
            "value": "LBV",
            "icao_code": "FOOL",
            "label": "Libreville International Airport , Libreville"
        },
        {
            "value": "LBW",
            "icao_code": "WRLB",
            "label": "Juvai Semaring Airport , Long Bawan"
        },
        {
            "value": "LBX",
            "icao_code": "RPLU",
            "label": "Lubang Airport , Lubang"
        },
        {
            "value": "LBY",
            "icao_code": "LFRE",
            "label": "La Baule-Escoublac Airport , La Baule-Escoublac"
        },
        {
            "value": "LBZ",
            "icao_code": "FNLK",
            "label": "Lucapa Airport , Lucapa"
        },
        {
            "value": "LCA",
            "icao_code": "LCLK",
            "label": "Larnaca International Airport , Larnaca"
        },
        {
            "value": "LCB",
            "icao_code": null,
            "label": "Pontes e Lacerda Airport , Pontes e Lacerda"
        },
        {
            "value": "LCC",
            "icao_code": "LIBN",
            "label": "Galatina Air Base , Lecce"
        },
        {
            "value": "LCD",
            "icao_code": "FALO",
            "label": "Louis Trichardt Airport , Louis Trichardt"
        },
        {
            "value": "LCE",
            "icao_code": "MHLC",
            "label": "Golos\u00f3n International Airport , La Ceiba"
        },
        {
            "value": "LCF",
            "icao_code": "MGRD",
            "label": "R\u00edo Dulce Airport , R\u00edo Dulce"
        },
        {
            "value": "LCG",
            "icao_code": "LECO",
            "label": "A Coru\u00f1a Airport , A Coru\u00f1a"
        },
        {
            "value": "LCH",
            "icao_code": "KLCH",
            "label": "Lake Charles Regional Airport , Lake Charles"
        },
        {
            "value": "LCI",
            "icao_code": "KLCI",
            "label": "Laconia Municipal Airport , Laconia"
        },
        {
            "value": "LCJ",
            "icao_code": "EPLL",
            "label": "\u0141\u00f3d\u017a W\u0142adys\u0142aw Reymont Airport , \u0141\u00f3d\u017a"
        },
        {
            "value": "LCK",
            "icao_code": "KLCK",
            "label": "Rickenbacker International Airport , Columbus"
        },
        {
            "value": "LCL",
            "icao_code": "MULM",
            "label": "La Coloma Airport , Pinar del R\u00edo"
        },
        {
            "value": "LCM",
            "icao_code": "SACC",
            "label": "La Cumbre Airport , La Cumbre"
        },
        {
            "value": "LCN",
            "icao_code": "YBLC",
            "label": "Balcanoona Airport , Balcanoona"
        },
        {
            "value": "LCO",
            "icao_code": "FCBL",
            "label": "Lague Airport , Lague"
        },
        {
            "value": "LCP",
            "icao_code": null,
            "label": "Loncopu\u00e9 Airport , Loncopu\u00e9"
        },
        {
            "value": "LCQ",
            "icao_code": "KLCQ",
            "label": "Lake City Gateway Airport , Lake City"
        },
        {
            "value": "LCR",
            "icao_code": null,
            "label": "La Chorrera Airport , La Chorrera"
        },
        {
            "value": "LCT",
            "icao_code": null,
            "label": "Shijiazhuang Luancheng Airport , Shijiazhuang"
        },
        {
            "value": "LCV",
            "icao_code": "LIQL",
            "label": "Lucca-Tassignano Airport , Lucca"
        },
        {
            "value": "LCX",
            "icao_code": "ZSLO",
            "label": "Longyan Guanzhishan Airport , Longyan"
        },
        {
            "value": "LCY",
            "icao_code": "EGLC",
            "label": "London City Airport , London"
        },
        {
            "value": "LDA",
            "icao_code": "VEMH",
            "label": "Malda Airport , Malda"
        },
        {
            "value": "LDB",
            "icao_code": "SBLO",
            "label": "Londrina\u2013Governador Jos\u00e9 Richa Airport , Londrina"
        },
        {
            "value": "LDC",
            "icao_code": "YLIN",
            "label": "Lindeman Island Airport , Lindeman Island"
        },
        {
            "value": "LDE",
            "icao_code": "LFBT",
            "label": "Tarbes\u2013Lourdes\u2013Pyr\u00e9n\u00e9es Airport , Tarbes"
        },
        {
            "value": "LDG",
            "icao_code": "ULAL",
            "label": "Leshukonskoye Airport , Leshukonskoye"
        },
        {
            "value": "LDH",
            "icao_code": "YLHI",
            "label": "Lord Howe Island Airport , Lord Howe Island"
        },
        {
            "value": "LDI",
            "icao_code": "HTLI",
            "label": "Lindi Airport , Lindi"
        },
        {
            "value": "LDJ",
            "icao_code": "KLDJ",
            "label": "Linden Airport , Linden"
        },
        {
            "value": "LDK",
            "icao_code": "ESGL",
            "label": "Lidk\u00f6ping-Hovby Airport , Lidk\u00f6ping"
        },
        {
            "value": "LDM",
            "icao_code": "KLDM",
            "label": "Mason County Airport , Ludington"
        },
        {
            "value": "LDN",
            "icao_code": "VNLD",
            "label": "Lamidanda Airport , Lamidanda"
        },
        {
            "value": "LDO",
            "icao_code": "SMDO",
            "label": "Laduani Airstrip , Aurora"
        },
        {
            "value": "LDR",
            "icao_code": null,
            "label": "Lawdar Airport , Lawdar"
        },
        {
            "value": "LDS",
            "icao_code": "ZYLD",
            "label": "Yichun Lindu Airport , Yichun"
        },
        {
            "value": "LDU",
            "icao_code": "WBKD",
            "label": "Lahad Datu Airport , Lahad Datu"
        },
        {
            "value": "LDV",
            "icao_code": "LFRJ",
            "label": "Landivisiau Air Base , Landivisiau"
        },
        {
            "value": "LDW",
            "icao_code": null,
            "label": "Lansdowne Airport , Lansdowne"
        },
        {
            "value": "LDX",
            "icao_code": "SOOM",
            "label": "Saint-Laurent-du-Maroni Airport , Saint-Laurent-du-Maroni"
        },
        {
            "value": "LDY",
            "icao_code": "EGAE",
            "label": "City of Derry Airport , Derry"
        },
        {
            "value": "LDZ",
            "icao_code": "FALD",
            "label": "Londolozi Airport , Londolozi"
        },
        {
            "value": "LEA",
            "icao_code": "YPLM",
            "label": "Learmonth Airport , Exmouth"
        },
        {
            "value": "LEB",
            "icao_code": "KLEB",
            "label": "Lebanon Municipal Airport , Lebanon"
        },
        {
            "value": "LEC",
            "icao_code": "SBLE",
            "label": "Coronel Hor\u00e1cio de Mattos Airport , Len\u00e7\u00f3is"
        },
        {
            "value": "LED",
            "icao_code": "ULLI",
            "label": "Pulkovo Airport , Saint Petersburg"
        },
        {
            "value": "LEE",
            "icao_code": "KLEE",
            "label": "Leesburg International Airport , Leesburg"
        },
        {
            "value": "LEF",
            "icao_code": "FXLK",
            "label": "Lebakeng Airport , Lebakeng"
        },
        {
            "value": "LEG",
            "icao_code": null,
            "label": "Aleg Airport , Aleg"
        },
        {
            "value": "LEH",
            "icao_code": "LFOH",
            "label": "Le Havre \u2013 Octeville Airport , Le Havre"
        },
        {
            "value": "LEI",
            "icao_code": "LEAM",
            "label": "Almer\u00eda Airport , Almer\u00eda"
        },
        {
            "value": "LEJ",
            "icao_code": "EDDP",
            "label": "Leipzig/Halle Airport , Leipzig"
        },
        {
            "value": "LEK",
            "icao_code": "GULB",
            "label": "Tata Airport , Lab\u00e9"
        },
        {
            "value": "LEL",
            "icao_code": "YLEV",
            "label": "Lake Evella Airport , Gapuwiyak"
        },
        {
            "value": "LEM",
            "icao_code": "KLEM",
            "label": "Lemmon Municipal Airport , Lemmon"
        },
        {
            "value": "LEN",
            "icao_code": "LELN",
            "label": "Le\u00f3n Airport , Le\u00f3n"
        },
        {
            "value": "LEO",
            "icao_code": null,
            "label": "L\u00e9koni Airport , L\u00e9koni"
        },
        {
            "value": "LEP",
            "icao_code": "SNDN",
            "label": "Leopoldina Airport , Leopoldina"
        },
        {
            "value": "LEQ",
            "icao_code": "EGHC",
            "label": "Land's End Airport , St Just"
        },
        {
            "value": "LER",
            "icao_code": "YLST",
            "label": "Leinster Airport , Leinster"
        },
        {
            "value": "LES",
            "icao_code": "FXLS",
            "label": "Lesobeng Airport , Lesobeng"
        },
        {
            "value": "LET",
            "icao_code": "SKLT",
            "label": "Alfredo V\u00e1squez Cobo International Airport , Leticia"
        },
        {
            "value": "LEU",
            "icao_code": "LESU",
            "label": "La Seu d'Urgell Airport , La Seu d'Urgell"
        },
        {
            "value": "LEV",
            "icao_code": "NFNB",
            "label": "Levuka Airfield , Levuka"
        },
        {
            "value": "LEW",
            "icao_code": "KLEW",
            "label": "Auburn/Lewiston Municipal Airport , Auburn"
        },
        {
            "value": "LEX",
            "icao_code": "KLEX",
            "label": "Blue Grass Airport , Lexington"
        },
        {
            "value": "LEY",
            "icao_code": "EHLE",
            "label": "Lelystad Airport , Lelystad"
        },
        {
            "value": "LEZ",
            "icao_code": "MHLE",
            "label": "La Esperanza Airport , La Esperanza"
        },
        {
            "value": "LFB",
            "icao_code": "FQLU",
            "label": "Lumbo Airport , Lumbo"
        },
        {
            "value": "LFI",
            "icao_code": "KLFI",
            "label": "Langley Air Force Base , Hampton"
        },
        {
            "value": "LFK",
            "icao_code": "KLFK",
            "label": "Angelina County Airport , Lufkin"
        },
        {
            "value": "LFM",
            "icao_code": "OISR",
            "label": "Lamerd Airport , Lamerd"
        },
        {
            "value": "LFN",
            "icao_code": "KLHZ",
            "label": "Triangle North Executive Airport , Louisburg"
        },
        {
            "value": "LFO",
            "icao_code": "HAKL",
            "label": "Kelafo Airport , Kelafo"
        },
        {
            "value": "LFP",
            "icao_code": "YLFD",
            "label": "Lakefield Airport , Lakefield"
        },
        {
            "value": "LFQ",
            "icao_code": null,
            "label": "Linfen Qiaoli Airport , Linfen"
        },
        {
            "value": "LFR",
            "icao_code": "SVLF",
            "label": "La Fr\u00eda Airport , La Fr\u00eda"
        },
        {
            "value": "LFT",
            "icao_code": "KLFT",
            "label": "Lafayette Regional Airport , Lafayette"
        },
        {
            "value": "LFW",
            "icao_code": "DXXX",
            "label": "Lom\u00e9\u2013Tokoin Airport , Lom\u00e9"
        },
        {
            "value": "LGA",
            "icao_code": "KLGA",
            "label": "LaGuardia Airport , New York City"
        },
        {
            "value": "LGB",
            "icao_code": "KLGB",
            "label": "Long Beach Airport , Long Beach"
        },
        {
            "value": "LGC",
            "icao_code": "KLGC",
            "label": "LaGrange Callaway Airport , LaGrange"
        },
        {
            "value": "LGD",
            "icao_code": "KLGD",
            "label": "La Grande/Union County Airport , La Grande"
        },
        {
            "value": "LGE",
            "icao_code": null,
            "label": "Lake Gregory Airport , Lake Gregory"
        },
        {
            "value": "LGF",
            "icao_code": "KLGF",
            "label": "Laguna Army Airfield , Yuma Proving Ground"
        },
        {
            "value": "LGG",
            "icao_code": "EBLG",
            "label": "Li\u00e8ge Airport , Li\u00e8ge"
        },
        {
            "value": "LGH",
            "icao_code": "YLEC",
            "label": "Leigh Creek Airport , Leigh Creek"
        },
        {
            "value": "LGI",
            "icao_code": "MYLD",
            "label": "Deadman's Cay Airport , Deadman's Cay"
        },
        {
            "value": "LGK",
            "icao_code": "WMKL",
            "label": "Langkawi International Airport , Langkawi"
        },
        {
            "value": "LGL",
            "icao_code": "WBGF",
            "label": "Long Lellang Airport , Long Lellang"
        },
        {
            "value": "LGM",
            "icao_code": null,
            "label": "Laiagam Airport , Laiagam"
        },
        {
            "value": "LGN",
            "icao_code": null,
            "label": "Linga Linga Airport , Linga Linga"
        },
        {
            "value": "LGO",
            "icao_code": "EDWL",
            "label": "Langeoog Airport , Langeoog"
        },
        {
            "value": "LGQ",
            "icao_code": "SENL",
            "label": "Lago Agrio Airport , Nueva Loja"
        },
        {
            "value": "LGR",
            "icao_code": "SCHR",
            "label": "Cochrane Airfield , Cochrane"
        },
        {
            "value": "LGS",
            "icao_code": "SAMM",
            "label": "Comodoro D. Ricardo Salom\u00f3n Airport , Malarg\u00fce"
        },
        {
            "value": "LGT",
            "icao_code": "SKGA",
            "label": "Las Gaviotas Airport , Gaviotas"
        },
        {
            "value": "LGU",
            "icao_code": "KLGU",
            "label": "Logan-Cache Airport , Logan"
        },
        {
            "value": "LGW",
            "icao_code": "EGKK",
            "label": "Gatwick Airport , London"
        },
        {
            "value": "LGX",
            "icao_code": "HCMJ",
            "label": "Lugh Ganane Airport , Lugh Ganane"
        },
        {
            "value": "LGY",
            "icao_code": null,
            "label": "Lagunillas Airport , Lagunillas"
        },
        {
            "value": "LHA",
            "icao_code": "EDTL",
            "label": "Flughafen Lahr , Lahr"
        },
        {
            "value": "LHE",
            "icao_code": "OPLA",
            "label": "Allama Iqbal International Airport , Lahore"
        },
        {
            "value": "LHG",
            "icao_code": "YLRD",
            "label": "Lightning Ridge Airport , Lightning Ridge"
        },
        {
            "value": "LHI",
            "icao_code": "WAJL",
            "label": "Lereh Airport , Lereh"
        },
        {
            "value": "LHK",
            "icao_code": "ZHGH",
            "label": "Laohekou Airport , Laohekou"
        },
        {
            "value": "LHP",
            "icao_code": null,
            "label": "Lehu Airport , Lehu"
        },
        {
            "value": "LHR",
            "icao_code": "EGLL",
            "label": "Heathrow Airport , London"
        },
        {
            "value": "LHS",
            "icao_code": "SAVH",
            "label": "Las Heras Airport , Las Heras"
        },
        {
            "value": "LHU",
            "icao_code": "FYLS",
            "label": "Lianshulu Airport , Lianshulu"
        },
        {
            "value": "LHV",
            "icao_code": "KLHV",
            "label": "William T. Piper Memorial Airport , Lock Haven"
        },
        {
            "value": "LHW",
            "icao_code": "ZLLL",
            "label": "Lanzhou Zhongchuan International Airport , Lanzhou"
        },
        {
            "value": "LIA",
            "icao_code": "ZULP",
            "label": "Liangping Airport , Liangping"
        },
        {
            "value": "LIB",
            "icao_code": null,
            "label": "Limbunya Airport , Limbunya Station"
        },
        {
            "value": "LIC",
            "icao_code": "KLIC",
            "label": "Limon Municipal Airport , Limon"
        },
        {
            "value": "LIE",
            "icao_code": "FZFA",
            "label": "Libenge Airport , Libenge"
        },
        {
            "value": "LIF",
            "icao_code": "NWWL",
            "label": "Ouanaham Airport , Lifou"
        },
        {
            "value": "LIG",
            "icao_code": "LFBL",
            "label": "Limoges \u2013 Bellegarde Airport , Limoges"
        },
        {
            "value": "LIH",
            "icao_code": "PHLI",
            "label": "Lihue Airport , Lihue"
        },
        {
            "value": "LII",
            "icao_code": "WAJM",
            "label": "Mulia Airport , Mulia"
        },
        {
            "value": "LIK",
            "icao_code": null,
            "label": "Likiep Airport , Likiep Atoll"
        },
        {
            "value": "LIL",
            "icao_code": "LFQQ",
            "label": "Lille Airport , Lille"
        },
        {
            "value": "LIM",
            "icao_code": "SPJC",
            "label": "Jorge Ch\u00e1vez International Airport , Lima"
        },
        {
            "value": "LIN",
            "icao_code": "LIML",
            "label": "Linate Airport , Milan"
        },
        {
            "value": "LIO",
            "icao_code": "MRLM",
            "label": "Lim\u00f3n International Airport , Lim\u00f3n"
        },
        {
            "value": "LIP",
            "icao_code": "SBLN",
            "label": "Lins Airport , Lins"
        },
        {
            "value": "LIQ",
            "icao_code": "FZGA",
            "label": "Lisala Airport , Lisala"
        },
        {
            "value": "LIR",
            "icao_code": "MRLB",
            "label": "Daniel Oduber Quir\u00f3s International Airport , Liberia"
        },
        {
            "value": "LIS",
            "icao_code": "LPPT",
            "label": "Lisbon Portela Airport , Lisbon"
        },
        {
            "value": "LIT",
            "icao_code": "KLIT",
            "label": "Clinton National Airport , Little Rock"
        },
        {
            "value": "LIV",
            "icao_code": null,
            "label": "Livengood Camp Airport , Livengood"
        },
        {
            "value": "LIW",
            "icao_code": "VYLK",
            "label": "Loikaw Airport , Loikaw"
        },
        {
            "value": "LIX",
            "icao_code": "FWLK",
            "label": "Likoma Airport , Likoma Island"
        },
        {
            "value": "LIY",
            "icao_code": "KLHW",
            "label": "MidCoast Regional Airport at Wright Army Airfield , Fort Stewart"
        },
        {
            "value": "LIZ",
            "icao_code": null,
            "label": "Loring International Airport , Limestone"
        },
        {
            "value": "LJA",
            "icao_code": "FZVA",
            "label": "Lodja Airport , Lodja"
        },
        {
            "value": "LJG",
            "icao_code": "ZPLJ",
            "label": "Lijiang Sanyi Airport , Lijiang"
        },
        {
            "value": "LJN",
            "icao_code": "KLBX",
            "label": "Texas Gulf Coast Regional Airport , Angleton"
        },
        {
            "value": "LJU",
            "icao_code": "LJLJ",
            "label": "Ljubljana Jo\u017ee Pu\u010dnik Airport , Ljubljana"
        },
        {
            "value": "LKA",
            "icao_code": "WATL",
            "label": "Gewayantana Airport , Larantuka"
        },
        {
            "value": "LKB",
            "icao_code": "NFNK",
            "label": "Lakeba Airport , Lakeba Island"
        },
        {
            "value": "LKC",
            "icao_code": null,
            "label": "Lekana Airport , Lekana"
        },
        {
            "value": "LKD",
            "icao_code": "YLND",
            "label": "Lakeland Downs Airport , Lakeland Downs"
        },
        {
            "value": "LKE",
            "icao_code": null,
            "label": "Kenmore Air Harbor Seaplane Base , Seattle"
        },
        {
            "value": "LKG",
            "icao_code": "HKLK",
            "label": "Lokichogio Airport , Lokichogio"
        },
        {
            "value": "LKH",
            "icao_code": "WBGL",
            "label": "Long Akah Airport , Long Akah"
        },
        {
            "value": "LKK",
            "icao_code": "PAKL",
            "label": "Kulik Lake Airport , Kulik Lake"
        },
        {
            "value": "LKL",
            "icao_code": "ENNA",
            "label": "Lakselv Airport, Banak , Lakselv"
        },
        {
            "value": "LKN",
            "icao_code": "ENLK",
            "label": "Leknes Airport , Leknes"
        },
        {
            "value": "LKO",
            "icao_code": "VILK",
            "label": "Chaudhary Charan Singh International Airport , Lucknow"
        },
        {
            "value": "LKP",
            "icao_code": "KLKP",
            "label": "Lake Placid Airport , Lake Placid"
        },
        {
            "value": "LKR",
            "icao_code": null,
            "label": "Las Khorey Airport , Las Khorey"
        },
        {
            "value": "LKU",
            "icao_code": null,
            "label": "Lake Turkana Airport , Lake Turkana"
        },
        {
            "value": "LKV",
            "icao_code": "KLKV",
            "label": "Lake County Airport , Lakeview"
        },
        {
            "value": "LKW",
            "icao_code": "OOLK",
            "label": "Lekhwair Airport , Lekhwair"
        },
        {
            "value": "LKY",
            "icao_code": "HTLM",
            "label": "Lake Manyara Airport , Lake Manyara"
        },
        {
            "value": "LKZ",
            "icao_code": "EGUL",
            "label": "RAF Lakenheath , Brandon"
        },
        {
            "value": "LLA",
            "icao_code": "ESPA",
            "label": "Lule\u00e5 Airport , Lule\u00e5"
        },
        {
            "value": "LLB",
            "icao_code": "ZULB",
            "label": "Libo Airport , Libo"
        },
        {
            "value": "LLC",
            "icao_code": "RPLH",
            "label": "Cagayan North International Airport , Lal-lo"
        },
        {
            "value": "LLE",
            "icao_code": "FAMN",
            "label": "Malelane Airport , Malalane"
        },
        {
            "value": "LLF",
            "icao_code": "ZGLG",
            "label": "Yongzhou Lingling Airport , Yongzhou"
        },
        {
            "value": "LLG",
            "icao_code": "YCGO",
            "label": "Chillagoe Airport , Chillagoe"
        },
        {
            "value": "LLH",
            "icao_code": null,
            "label": "Las Limas Airport , Las Limas"
        },
        {
            "value": "LLI",
            "icao_code": "HALL",
            "label": "Lalibela Airport , Lalibela"
        },
        {
            "value": "LLJ",
            "icao_code": "WIPB",
            "label": "Silampari Airport , Lubuklinggau"
        },
        {
            "value": "LLK",
            "icao_code": "UBBL",
            "label": "Lankaran International Airport , Lankaran"
        },
        {
            "value": "LLL",
            "icao_code": null,
            "label": "Lissadell Airport , Lissadell"
        },
        {
            "value": "LLM",
            "icao_code": null,
            "label": "Lomlom Airport , Lomlom"
        },
        {
            "value": "LLN",
            "icao_code": "WAJV",
            "label": "Kelila Airport , Kelila"
        },
        {
            "value": "LLO",
            "icao_code": null,
            "label": "Palopo Lagaligo Airport , Palopo"
        },
        {
            "value": "LLP",
            "icao_code": null,
            "label": "Linda Downs Airport , Linda Downs"
        },
        {
            "value": "LLS",
            "icao_code": "SATK",
            "label": "Alf\u00e9rez Armando Rodr\u00edguez Airport , Las Lomitas"
        },
        {
            "value": "LLT",
            "icao_code": "FNLB",
            "label": "Lobito Airport , Lobito"
        },
        {
            "value": "LLV",
            "icao_code": "ZBLL",
            "label": "L\u00fcliang Airport , L\u00fcliang"
        },
        {
            "value": "LLW",
            "icao_code": "FWKI",
            "label": "Lilongwe International Airport , Lilongwe"
        },
        {
            "value": "LLX",
            "icao_code": "KCDA",
            "label": "Caledonia County Airport , Lyndonville"
        },
        {
            "value": "LLY",
            "icao_code": "KVAY",
            "label": "South Jersey Regional Airport , Mount Holly"
        },
        {
            "value": "LMA",
            "icao_code": "PAMH",
            "label": "Lake Minchumina Airport , Lake Minchumina"
        },
        {
            "value": "LMB",
            "icao_code": "FWSM",
            "label": "Salima Airport , Salima"
        },
        {
            "value": "LMC",
            "icao_code": "SKNA",
            "label": "La Macarena Airport , La Macarena"
        },
        {
            "value": "LMD",
            "icao_code": null,
            "label": "Los Menucos Airport , Los Menucos"
        },
        {
            "value": "LME",
            "icao_code": "LFRM",
            "label": "Le Mans Arnage Airport , Le Mans"
        },
        {
            "value": "LMG",
            "icao_code": null,
            "label": "Lamassa Airport , Lamassa"
        },
        {
            "value": "LMH",
            "icao_code": null,
            "label": "Lim\u00f3n Airport , Lim\u00f3n"
        },
        {
            "value": "LMI",
            "icao_code": null,
            "label": "Lumi Airport , Lumi"
        },
        {
            "value": "LML",
            "icao_code": null,
            "label": "Lae Airport , Lae Atoll"
        },
        {
            "value": "LMM",
            "icao_code": "MMLM",
            "label": "Fort Valley Federal International Airport , Los Mochis"
        },
        {
            "value": "LMN",
            "icao_code": "WBGJ",
            "label": "Limbang Airport , Limbang"
        },
        {
            "value": "LMO",
            "icao_code": "EGQS",
            "label": "RAF Lossiemouth , Lossiemouth"
        },
        {
            "value": "LMP",
            "icao_code": "LICD",
            "label": "Lampedusa Airport , Lampedusa"
        },
        {
            "value": "LMQ",
            "icao_code": "HLMB",
            "label": "Marsa Brega Airport , Brega"
        },
        {
            "value": "LMR",
            "icao_code": "FALC",
            "label": "Finsch Mine Airport , Lime Acres"
        },
        {
            "value": "LMS",
            "icao_code": "KLMS",
            "label": "Louisville Winston County Airport , Louisville"
        },
        {
            "value": "LMT",
            "icao_code": "KLMT",
            "label": "Klamath Falls Airport , Klamath Falls"
        },
        {
            "value": "LMU",
            "icao_code": "WIDL",
            "label": "Letung Airport , Anambas Islands"
        },
        {
            "value": "LMV",
            "icao_code": null,
            "label": "Madivaru Airport , Madivaru"
        },
        {
            "value": "LMX",
            "icao_code": null,
            "label": "Lop\u00e9z de Micay Airport , El Trapiche"
        },
        {
            "value": "LMY",
            "icao_code": null,
            "label": "Lake Murray Airport , Lake Murray"
        },
        {
            "value": "LMZ",
            "icao_code": null,
            "label": "Palma Airport , Palma"
        },
        {
            "value": "LNA",
            "icao_code": "KLNA",
            "label": "Palm Beach County Park Airport , West Palm Beach"
        },
        {
            "value": "LNB",
            "icao_code": "NVSM",
            "label": "Lamen Bay Airport , Lamen Bay"
        },
        {
            "value": "LNC",
            "icao_code": null,
            "label": "Lengbati Airport , Lengbati"
        },
        {
            "value": "LND",
            "icao_code": "KLND",
            "label": "Hunt Field , Lander"
        },
        {
            "value": "LNE",
            "icao_code": "NVSO",
            "label": "Lonorore Airport , Lonorore"
        },
        {
            "value": "LNF",
            "icao_code": null,
            "label": "Munbil Airport , Munbil"
        },
        {
            "value": "LNG",
            "icao_code": null,
            "label": "Lese Airport , Lese"
        },
        {
            "value": "LNH",
            "icao_code": "YLKN",
            "label": "Lake Nash Airport , Alpurrurulam"
        },
        {
            "value": "LNI",
            "icao_code": "PALN",
            "label": "Point Lonely Short Range Radar Site , Lonely"
        },
        {
            "value": "LNJ",
            "icao_code": "ZPLC",
            "label": "Lincang Airport , Lincang"
        },
        {
            "value": "LNK",
            "icao_code": "KLNK",
            "label": "Lincoln Airport , Lincoln"
        },
        {
            "value": "LNL",
            "icao_code": "ZLLN",
            "label": "Longnan Chengzhou Airport , Longnan"
        },
        {
            "value": "LNM",
            "icao_code": null,
            "label": "Langimar Airport , Langimar"
        },
        {
            "value": "LNN",
            "icao_code": "KLNN",
            "label": "Lost Nation Airport , Willoughby"
        },
        {
            "value": "LNO",
            "icao_code": "YLEO",
            "label": "Leonora Airport , Leonora"
        },
        {
            "value": "LNP",
            "icao_code": "KLNP",
            "label": "Lonesome Pine Airport , Wise"
        },
        {
            "value": "LNQ",
            "icao_code": null,
            "label": "Loani Airport , Loani"
        },
        {
            "value": "LNR",
            "icao_code": "KLNR",
            "label": "Tri-County Regional Airport , Lone Rock"
        },
        {
            "value": "LNS",
            "icao_code": "KLNS",
            "label": "Lancaster Airport , Lancaster"
        },
        {
            "value": "LNU",
            "icao_code": "WALM",
            "label": "Robert Atty Bessing Airport , Malinau"
        },
        {
            "value": "LNV",
            "icao_code": "AYKY",
            "label": "Lihir Island Airport , Lihir Island"
        },
        {
            "value": "LNX",
            "icao_code": "UUBS",
            "label": "Smolensk South Airport , Smolensk"
        },
        {
            "value": "LNY",
            "icao_code": "PHNY",
            "label": "Lanai Airport , Lanai City"
        },
        {
            "value": "LNZ",
            "icao_code": "LOWL",
            "label": "Linz Airport , Linz"
        },
        {
            "value": "LOA",
            "icao_code": "YLOR",
            "label": "Lorraine Airport , Lorraine"
        },
        {
            "value": "LOB",
            "icao_code": "SCAN",
            "label": "San Rafael Airport , Los Andes"
        },
        {
            "value": "LOC",
            "icao_code": "YLOK",
            "label": "Lock Airport , Lock"
        },
        {
            "value": "LOD",
            "icao_code": "NVSG",
            "label": "Longana Airport , Longana"
        },
        {
            "value": "LOE",
            "icao_code": "VTUL",
            "label": "Loei Airport , Loei"
        },
        {
            "value": "LOF",
            "icao_code": null,
            "label": "Loen Airport , Loen Island"
        },
        {
            "value": "LOH",
            "icao_code": "SETM",
            "label": "Ciudad de Catamayo Airport , Loja"
        },
        {
            "value": "LOI",
            "icao_code": "SSLN",
            "label": "Helmuth Baungartem Airport , Lontras"
        },
        {
            "value": "LOK",
            "icao_code": "HKLO",
            "label": "Lodwar Airport , Lodwar"
        },
        {
            "value": "LOL",
            "icao_code": "KLOL",
            "label": "Derby Field , Lovelock"
        },
        {
            "value": "LOM",
            "icao_code": null,
            "label": "Francisco Primo de Verdad National Airport , Lagos de Moreno"
        },
        {
            "value": "LON",
            "icao_code": null,
            "label": "metropolitan area , London"
        },
        {
            "value": "LOO",
            "icao_code": "DAUL",
            "label": "L'Mekrareg Airport , Laghouat"
        },
        {
            "value": "LOP",
            "icao_code": "WADL",
            "label": "Lombok International Airport , Praya"
        },
        {
            "value": "LOQ",
            "icao_code": "FBLO",
            "label": "Lobatse Airport , Lobatse"
        },
        {
            "value": "LOS",
            "icao_code": "DNMM",
            "label": "Murtala Muhammed International Airport , Lagos"
        },
        {
            "value": "LOT",
            "icao_code": "KLOT",
            "label": "Lewis University Airport , Romeoville"
        },
        {
            "value": "LOU",
            "icao_code": "KLOU",
            "label": "Bowman Field , Louisville"
        },
        {
            "value": "LOV",
            "icao_code": "MMMV",
            "label": "Venustiano Carranza International Airport , Monclova"
        },
        {
            "value": "LOW",
            "icao_code": "KLKU",
            "label": "Louisa County Airport , Louisa"
        },
        {
            "value": "LOX",
            "icao_code": null,
            "label": "Los Tablones Airport , Los Tablones"
        },
        {
            "value": "LOY",
            "icao_code": "HKLY",
            "label": "Loiyangalani Airport , Loiyangalani"
        },
        {
            "value": "LOZ",
            "icao_code": "KLOZ",
            "label": "London-Corbin Airport , London"
        },
        {
            "value": "LPA",
            "icao_code": "GCLP",
            "label": "Gran Canaria Airport , Gran Canaria"
        },
        {
            "value": "LPB",
            "icao_code": "SLLP",
            "label": "El Alto International Airport , La Paz"
        },
        {
            "value": "LPC",
            "icao_code": "KLPC",
            "label": "Lompoc Airport , Lompoc"
        },
        {
            "value": "LPD",
            "icao_code": "SKLP",
            "label": "La Pedrera Airport , La Pedrera"
        },
        {
            "value": "LPE",
            "icao_code": "SKIM",
            "label": "La Primavera Airport , La Primavera"
        },
        {
            "value": "LPF",
            "icao_code": "ZUPS",
            "label": "Liupanshui Yuezhao Airport , Liupanshui"
        },
        {
            "value": "LPG",
            "icao_code": "SADL",
            "label": "La Plata Airport , La Plata"
        },
        {
            "value": "LPI",
            "icao_code": "ESSL",
            "label": "Link\u00f6ping/Saab Airport , Link\u00f6ping"
        },
        {
            "value": "LPJ",
            "icao_code": "SVAS",
            "label": "Armando Schwarck Airport , Los Pijiguaos"
        },
        {
            "value": "LPK",
            "icao_code": "UUOL",
            "label": "Lipetsk Airport , Lipetsk"
        },
        {
            "value": "LPL",
            "icao_code": "EGGP",
            "label": "Liverpool John Lennon Airport , Liverpool"
        },
        {
            "value": "LPM",
            "icao_code": "NVSL",
            "label": "Malekoula Airport , Lamap"
        },
        {
            "value": "LPN",
            "icao_code": null,
            "label": "Leron Plains Airport , Leron Plains"
        },
        {
            "value": "LPO",
            "icao_code": "KPPO",
            "label": "La Porte Municipal Airport , La Porte"
        },
        {
            "value": "LPP",
            "icao_code": "EFLP",
            "label": "Lappeenranta Airport , Lappeenranta"
        },
        {
            "value": "LPQ",
            "icao_code": "VLLB",
            "label": "Luang Prabang International Airport , Luang Prabang"
        },
        {
            "value": "LPS",
            "icao_code": null,
            "label": "Fishermans Bay/LPS Seaplane Base , Lopez Island"
        },
        {
            "value": "LPT",
            "icao_code": "VTCL",
            "label": "Lampang Airport , Lampang"
        },
        {
            "value": "LPU",
            "icao_code": "WRLP",
            "label": "Long Apung Airport , Long Apung"
        },
        {
            "value": "LPX",
            "icao_code": "EVLA",
            "label": "Liep\u0101ja International Airport , Liep\u0101ja"
        },
        {
            "value": "LPY",
            "icao_code": "LFHP",
            "label": "Le Puy \u2013 Loudes Airport , Le Puy-en-Velay"
        },
        {
            "value": "LQK",
            "icao_code": "KLQK",
            "label": "Pickens County Airport , Pickens"
        },
        {
            "value": "LQM",
            "icao_code": "SKLG",
            "label": "Caucay\u00e1 Airport , Puerto Legu\u00edzamo"
        },
        {
            "value": "LQN",
            "icao_code": "OAQN",
            "label": "Qala i Naw Airport , Qala i Naw"
        },
        {
            "value": "LRA",
            "icao_code": "LGLR",
            "label": "Larissa National Airport , Larissa"
        },
        {
            "value": "LRB",
            "icao_code": "FXLR",
            "label": "Leribe Airport , Leribe"
        },
        {
            "value": "LRD",
            "icao_code": "KLRD",
            "label": "Laredo International Airport , Laredo"
        },
        {
            "value": "LRE",
            "icao_code": "YLRE",
            "label": "Longreach Airport , Longreach"
        },
        {
            "value": "LRF",
            "icao_code": "KLRF",
            "label": "Little Rock Air Force Base , Jacksonville"
        },
        {
            "value": "LRG",
            "icao_code": "OPLL",
            "label": "Loralai Airport , Loralai"
        },
        {
            "value": "LRH",
            "icao_code": "LFBH",
            "label": "La Rochelle \u2013 \u00cele de R\u00e9 Airport , La Rochelle"
        },
        {
            "value": "LRI",
            "icao_code": null,
            "label": "Lorica Airport , Lorica"
        },
        {
            "value": "LRJ",
            "icao_code": "KLRJ",
            "label": "Le Mars Municipal Airport , Le Mars"
        },
        {
            "value": "LRL",
            "icao_code": "DXNG",
            "label": "Niamtougou International Airport , Niamtougou"
        },
        {
            "value": "LRM",
            "icao_code": "MDLR",
            "label": "La Romana International Airport , La Romana"
        },
        {
            "value": "LRQ",
            "icao_code": null,
            "label": "Laurie River Airport , Laurie River"
        },
        {
            "value": "LRR",
            "icao_code": "OISL",
            "label": "Larestan International Airport , Lar"
        },
        {
            "value": "LRS",
            "icao_code": "LGLE",
            "label": "Leros Municipal Airport , Leros"
        },
        {
            "value": "LRT",
            "icao_code": "LFRH",
            "label": "Lorient South Brittany Airport , Lorient"
        },
        {
            "value": "LRU",
            "icao_code": "KLRU",
            "label": "Las Cruces International Airport , Las Cruces"
        },
        {
            "value": "LRV",
            "icao_code": "SVRS",
            "label": "Los Roques Airport , Los Roques"
        },
        {
            "value": "LSA",
            "icao_code": "AYKA",
            "label": "Losuia Airport , Kiriwina Island"
        },
        {
            "value": "LSB",
            "icao_code": "KLSB",
            "label": "Lordsburg Municipal Airport , Lordsburg"
        },
        {
            "value": "LSC",
            "icao_code": "SCSE",
            "label": "La Florida Airport , La Serena"
        },
        {
            "value": "LSE",
            "icao_code": "KLSE",
            "label": "La Crosse Regional Airport , La Crosse"
        },
        {
            "value": "LSF",
            "icao_code": "KLSF",
            "label": "Lawson Army Airfield , Fort Benning"
        },
        {
            "value": "LSH",
            "icao_code": "VYLS",
            "label": "Lashio Airport , Lashio"
        },
        {
            "value": "LSI",
            "icao_code": "EGPB",
            "label": "Sumburgh Airport , Shetland Islands"
        },
        {
            "value": "LSJ",
            "icao_code": null,
            "label": "Long Island Airport , Long Island"
        },
        {
            "value": "LSK",
            "icao_code": "KLSK",
            "label": "Lusk Municipal Airport , Lusk"
        },
        {
            "value": "LSL",
            "icao_code": "MRLC",
            "label": "Los Chiles Airport , Los Chiles"
        },
        {
            "value": "LSM",
            "icao_code": "WBGD",
            "label": "Long Semado Airport , Long Semado"
        },
        {
            "value": "LSN",
            "icao_code": "KLSN",
            "label": "Los Banos Municipal Airport , Los Banos"
        },
        {
            "value": "LSO",
            "icao_code": "LFOO",
            "label": "Les Sables-d'Olonne \u2013 Talmont Airport , Les Sables-d'Olonne"
        },
        {
            "value": "LSP",
            "icao_code": "SVJC",
            "label": "Josefa Camejo International Airport , Punto Fijo"
        },
        {
            "value": "LSQ",
            "icao_code": "SCGE",
            "label": "Maria Dolores Airport , Los \u00c1ngeles"
        },
        {
            "value": "LSS",
            "icao_code": "TFFS",
            "label": "Les Saintes Airport , Terre-de-Haut Island"
        },
        {
            "value": "LST",
            "icao_code": "YMLT",
            "label": "Launceston Airport , Launceston"
        },
        {
            "value": "LSU",
            "icao_code": "WBGU",
            "label": "Long Sukang Airport , Long Sukang"
        },
        {
            "value": "LSV",
            "icao_code": "KLSV",
            "label": "Nellis Air Force Base , Las Vegas"
        },
        {
            "value": "LSW",
            "icao_code": "WITM",
            "label": "Malikus Saleh Airport , Lhokseumawe"
        },
        {
            "value": "LSX",
            "icao_code": "WITL",
            "label": "Lhok Sukon Airport , Lhoksukon"
        },
        {
            "value": "LSY",
            "icao_code": "YLIS",
            "label": "Lismore Airport , Lismore"
        },
        {
            "value": "LSZ",
            "icao_code": "LDLO",
            "label": "Lo\u0161inj Airport , Lo\u0161inj"
        },
        {
            "value": "LTA",
            "icao_code": "FATZ",
            "label": "Tzaneen Airport , Tzaneen"
        },
        {
            "value": "LTB",
            "icao_code": null,
            "label": "Latrobe Airport , Latrobe"
        },
        {
            "value": "LTC",
            "icao_code": "FTTH",
            "label": "La\u00ef Airport , La\u00ef"
        },
        {
            "value": "LTD",
            "icao_code": "HLTD",
            "label": "Ghadames Airport , Ghadames"
        },
        {
            "value": "LTF",
            "icao_code": null,
            "label": "Leitre Airport , Leitre"
        },
        {
            "value": "LTG",
            "icao_code": "VNLT",
            "label": "Langtang Airport , Langtang"
        },
        {
            "value": "LTI",
            "icao_code": "ZMAT",
            "label": "Altai Airport , Altai"
        },
        {
            "value": "LTK",
            "icao_code": "OSLK",
            "label": "Bassel Al-Assad International Airport , Latakia"
        },
        {
            "value": "LTL",
            "icao_code": "FOOR",
            "label": "Lastourville Airport , Lastoursville"
        },
        {
            "value": "LTM",
            "icao_code": "SYLT",
            "label": "Lethem Airport , Lethem"
        },
        {
            "value": "LTN",
            "icao_code": "EGGW",
            "label": "Luton Airport , London"
        },
        {
            "value": "LTO",
            "icao_code": "MMLT",
            "label": "Loreto International Airport , Loreto"
        },
        {
            "value": "LTP",
            "icao_code": "YLHS",
            "label": "Lyndhurst Airport , Lyndhurst"
        },
        {
            "value": "LTQ",
            "icao_code": "LFAT",
            "label": "Le Touquet \u2013 C\u00f4te d'Opale Airport , Le Touquet-Paris-Plage"
        },
        {
            "value": "LTR",
            "icao_code": "EILT",
            "label": "Letterkenny Airfield , Letterkenny"
        },
        {
            "value": "LTS",
            "icao_code": "KLTS",
            "label": "Altus Air Force Base , Altus"
        },
        {
            "value": "LTT",
            "icao_code": "LFTZ",
            "label": "La M\u00f4le \u2013 Saint-Tropez Airport , Saint-Tropez"
        },
        {
            "value": "LTU",
            "icao_code": "VALT",
            "label": "Latur Airport , Latur"
        },
        {
            "value": "LTV",
            "icao_code": "YLOV",
            "label": "Lotus Vale Station Airport , Lotus Vale Station"
        },
        {
            "value": "LTW",
            "icao_code": null,
            "label": "St. Mary's County Regional Airport , Leonardtown"
        },
        {
            "value": "LTX",
            "icao_code": null,
            "label": "Cotopaxi International Airport , Latacunga"
        },
        {
            "value": "LUA",
            "icao_code": "VNLK",
            "label": "Tenzing-Hillary Airport , Lukla"
        },
        {
            "value": "LUB",
            "icao_code": "SYLP",
            "label": "Lumid Pau Airport , Lumid Pau"
        },
        {
            "value": "LUC",
            "icao_code": "NFNH",
            "label": "Laucala Airport , Laucala Island"
        },
        {
            "value": "LUD",
            "icao_code": "FYLZ",
            "label": "L\u00fcderitz Airport , L\u00fcderitz"
        },
        {
            "value": "LUE",
            "icao_code": "LZLU",
            "label": "Bo\u013ekovce Airport , Lu\u010denec"
        },
        {
            "value": "LUF",
            "icao_code": "KLUF",
            "label": "Luke Air Force Base , Glendale"
        },
        {
            "value": "LUG",
            "icao_code": "LSZA",
            "label": "Lugano Airport , Lugano"
        },
        {
            "value": "LUH",
            "icao_code": "VILD",
            "label": "Sahnewal Airport , Ludhiana"
        },
        {
            "value": "LUI",
            "icao_code": null,
            "label": "La Uni\u00f3n Airport , La Uni\u00f3n"
        },
        {
            "value": "LUK",
            "icao_code": "KLUK",
            "label": "Cincinnati Municipal Airport , Cincinnati"
        },
        {
            "value": "LUL",
            "icao_code": "KLUL",
            "label": "Hesler-Noble Field , Laurel"
        },
        {
            "value": "LUM",
            "icao_code": "ZPMS",
            "label": "Dehong Mangshi Airport , Mangshi"
        },
        {
            "value": "LUN",
            "icao_code": "FLLS",
            "label": "Kenneth Kaunda International Airport , Lusaka"
        },
        {
            "value": "LUO",
            "icao_code": "FNUE",
            "label": "Luena Airport , Luena"
        },
        {
            "value": "LUP",
            "icao_code": "PHLU",
            "label": "Kalaupapa Airport , Kalaupapa"
        },
        {
            "value": "LUQ",
            "icao_code": "SAOU",
            "label": "Brigadier Mayor C\u00e9sar Ra\u00fal Ojeda Airport , San Luis"
        },
        {
            "value": "LUR",
            "icao_code": "PALU",
            "label": "Cape Lisburne LRRS Airport , Cape Lisburne"
        },
        {
            "value": "LUS",
            "icao_code": "FZCE",
            "label": "Lusanga Airport , Lusanga"
        },
        {
            "value": "LUT",
            "icao_code": "YLRS",
            "label": "New Laura Airport , New Laura"
        },
        {
            "value": "LUU",
            "icao_code": "YLRA",
            "label": "Laura Airport , Laura"
        },
        {
            "value": "LUV",
            "icao_code": "WAPL",
            "label": "Karel Sadsuitubun Airport , Langgur"
        },
        {
            "value": "LUW",
            "icao_code": "WAMW",
            "label": "Syukuran Aminuddin Amir Airport , Luwuk"
        },
        {
            "value": "LUX",
            "icao_code": "ELLX",
            "label": "Luxembourg Findel Airport , Luxembourg"
        },
        {
            "value": "LUZ",
            "icao_code": "EPLB",
            "label": "Lublin Airport , Lublin"
        },
        {
            "value": "LVA",
            "icao_code": "LFOV",
            "label": "Laval Entrammes Airport , Laval"
        },
        {
            "value": "LVB",
            "icao_code": "SSLI",
            "label": "Galp\u00f5es Airport , Santana do Livramento"
        },
        {
            "value": "LVD",
            "icao_code": null,
            "label": "Lime Village Airport , Lime Village"
        },
        {
            "value": "LVI",
            "icao_code": "FLLI",
            "label": "Harry Mwanga Nkumbula International Airport , Livingstone"
        },
        {
            "value": "LVK",
            "icao_code": "KLVK",
            "label": "Livermore Municipal Airport , Livermore"
        },
        {
            "value": "LVL",
            "icao_code": "KLVL",
            "label": "Lawrenceville/Brunswick Municipal Airport , Lawrenceville"
        },
        {
            "value": "LVM",
            "icao_code": "KLVM",
            "label": "Mission Field , Livingston"
        },
        {
            "value": "LVO",
            "icao_code": "YLTN",
            "label": "Laverton Airport , Laverton"
        },
        {
            "value": "LVP",
            "icao_code": "OIBV",
            "label": "Lavan Airport , Lavan Island"
        },
        {
            "value": "LVR",
            "icao_code": "SILC",
            "label": "Bom Futuro Municipal Airport , Lucas do Rio Verde"
        },
        {
            "value": "LVS",
            "icao_code": "KLVS",
            "label": "Las Vegas Municipal Airport , Las Vegas"
        },
        {
            "value": "LWA",
            "icao_code": null,
            "label": "Lebak Airport , Lebak"
        },
        {
            "value": "LWB",
            "icao_code": "KLWB",
            "label": "Greenbrier Valley Airport , Lewisburg"
        },
        {
            "value": "LWC",
            "icao_code": "KLWC",
            "label": "Lawrence Municipal Airport , Lawrence"
        },
        {
            "value": "LWE",
            "icao_code": "WATW",
            "label": "Wonopito Airport , Lewoleba"
        },
        {
            "value": "LWH",
            "icao_code": "YLAH",
            "label": "Lawn Hill Airport , Lawn Hill"
        },
        {
            "value": "LWI",
            "icao_code": null,
            "label": "Lowai Airport , Lowai"
        },
        {
            "value": "LWK",
            "icao_code": "EGET",
            "label": "Tingwall Airport , Lerwick"
        },
        {
            "value": "LWL",
            "icao_code": "KLWL",
            "label": "Wells Municipal Airport , Wells"
        },
        {
            "value": "LWM",
            "icao_code": "KLWM",
            "label": "Lawrence Municipal Airport , Lawrence"
        },
        {
            "value": "LWN",
            "icao_code": "UDSG",
            "label": "Shirak Airport , Gyumri"
        },
        {
            "value": "LWO",
            "icao_code": "UKLL",
            "label": "Lviv Danylo Halytskyi International Airport , Lviv"
        },
        {
            "value": "LWR",
            "icao_code": "EHLW",
            "label": "Leeuwarden Air Base , Leeuwarden"
        },
        {
            "value": "LWS",
            "icao_code": "KLWS",
            "label": "Lewiston\u2013Nez Perce County Airport , Lewiston"
        },
        {
            "value": "LWT",
            "icao_code": "KLWT",
            "label": "Lewistown Municipal Airport , Lewistown"
        },
        {
            "value": "LWV",
            "icao_code": "KLWV",
            "label": "Lawrenceville\u2013Vincennes International Airport , Lawrenceville"
        },
        {
            "value": "LWY",
            "icao_code": "WBGW",
            "label": "Lawas Airport , Lawas"
        },
        {
            "value": "LXA",
            "icao_code": "ZULS",
            "label": "Lhasa Gonggar Airport , Lhasa"
        },
        {
            "value": "LXG",
            "icao_code": "VLLN",
            "label": "Louang Namtha Airport , Luang Namtha"
        },
        {
            "value": "LXN",
            "icao_code": "KLXN",
            "label": "Jim Kelly Field , Lexington"
        },
        {
            "value": "LXR",
            "icao_code": "HELX",
            "label": "Luxor International Airport , Luxor"
        },
        {
            "value": "LXS",
            "icao_code": "LGLM",
            "label": "Lemnos International Airport , Lemnos"
        },
        {
            "value": "LXU",
            "icao_code": "FLLK",
            "label": "Lukulu Airport , Lukulu"
        },
        {
            "value": "LXV",
            "icao_code": "KLXV",
            "label": "Lake County Airport , Leadville"
        },
        {
            "value": "LYA",
            "icao_code": "ZHLY",
            "label": "Luoyang Beijiao Airport , Luoyang"
        },
        {
            "value": "LYB",
            "icao_code": "MWCL",
            "label": "Edward Bodden Airfield , Little Cayman"
        },
        {
            "value": "LYC",
            "icao_code": "ESNL",
            "label": "Lycksele Airport , Lycksele"
        },
        {
            "value": "LYE",
            "icao_code": "EGDL",
            "label": "RAF Lyneham , Lyneham"
        },
        {
            "value": "LYG",
            "icao_code": "ZSLG",
            "label": "Lianyungang Baitabu Airport , Lianyungang"
        },
        {
            "value": "LYH",
            "icao_code": "KLYH",
            "label": "Lynchburg Regional Airport , Lynchburg"
        },
        {
            "value": "LYI",
            "icao_code": "ZSLY",
            "label": "Linyi Qiyang Airport (formerly , Linyi"
        },
        {
            "value": "LYK",
            "icao_code": null,
            "label": "Lunyuk Airport , Lunyuk"
        },
        {
            "value": "LYN",
            "icao_code": "LFLY",
            "label": "Lyon\u2013Bron Airport , Lyon"
        },
        {
            "value": "LYO",
            "icao_code": "KLYO",
            "label": "Lyons\u2013Rice County Municipal Airport , Lyons"
        },
        {
            "value": "LYP",
            "icao_code": "OPFA",
            "label": "Faisalabad International Airport , Faisalabad"
        },
        {
            "value": "LYR",
            "icao_code": "ENSB",
            "label": "Svalbard Airport, Longyear , Longyearbyen"
        },
        {
            "value": "LYS",
            "icao_code": "LFLL",
            "label": "Lyon\u2013Saint-Exup\u00e9ry Airport , Lyon"
        },
        {
            "value": "LYT",
            "icao_code": null,
            "label": "Lady Elliot Island Airport , Lady Elliot Island"
        },
        {
            "value": "LYU",
            "icao_code": "KELO",
            "label": "Ely Municipal Airport , Ely"
        },
        {
            "value": "LYX",
            "icao_code": "EGMD",
            "label": "Lydd Airport , Lydd"
        },
        {
            "value": "LZA",
            "icao_code": "FZUG",
            "label": "Luiza Airport , Luiza"
        },
        {
            "value": "LZC",
            "icao_code": "MMLC",
            "label": "L\u00e1zaro C\u00e1rdenas Airport , L\u00e1zaro C\u00e1rdenas"
        },
        {
            "value": "LZH",
            "icao_code": "ZGZH",
            "label": "Liuzhou Bailian Airport , Liuzhou"
        },
        {
            "value": "LZI",
            "icao_code": "FZAL",
            "label": "Luozi Airport , Luozi"
        },
        {
            "value": "LZM",
            "icao_code": "FNLZ",
            "label": "Cuango-Luzamba Airport , Cuango"
        },
        {
            "value": "LZN",
            "icao_code": "RCFG",
            "label": "Matsu Nangan Airport , Nangan"
        },
        {
            "value": "LZO",
            "icao_code": "ZULZ",
            "label": "Luzhou Yunlong Airport , Luzhou"
        },
        {
            "value": "LZR",
            "icao_code": "YLZI",
            "label": "Lizard Island Airport , Lizard Island"
        },
        {
            "value": "LZU",
            "icao_code": "KLZU",
            "label": "Gwinnett County Airport , LaGrange"
        },
        {
            "value": "LZY",
            "icao_code": "ZUNZ",
            "label": "Nyingchi Mainling Airport , Nyingchi"
        },
        {
            "value": "MAA",
            "icao_code": "VOMM",
            "label": "Chennai International Airport , Chennai"
        },
        {
            "value": "MAB",
            "icao_code": "SBMA",
            "label": "Jo\u00e3o Correa da Rocha Airport , Marab\u00e1"
        },
        {
            "value": "MAC",
            "icao_code": "KMAC",
            "label": "Macon Downtown Airport , Macon"
        },
        {
            "value": "MAD",
            "icao_code": "LEMD",
            "label": "Adolfo Su\u00e1rez Madrid\u2013Barajas Airport , Madrid"
        },
        {
            "value": "MAE",
            "icao_code": "KMAE",
            "label": "Madera Municipal Airport , Madera"
        },
        {
            "value": "MAF",
            "icao_code": "KMAF",
            "label": "Midland International Air and Space Port , Midland"
        },
        {
            "value": "MAG",
            "icao_code": "AYMD",
            "label": "Madang Airport , Madang"
        },
        {
            "value": "MAH",
            "icao_code": "LEMH",
            "label": "Menorca Airport , Menorca"
        },
        {
            "value": "MAI",
            "icao_code": "FWMG",
            "label": "Mangochi Airport , Mangochi"
        },
        {
            "value": "MAJ",
            "icao_code": "PKMJ",
            "label": "Marshall Islands International Airport , Majuro"
        },
        {
            "value": "MAK",
            "icao_code": "HSSM",
            "label": "Malakal Airport , Malakal"
        },
        {
            "value": "MAL",
            "icao_code": "WAPE",
            "label": "Mangole Airport , Mangole Island"
        },
        {
            "value": "MAM",
            "icao_code": "MMMA",
            "label": "General Servando Canales International Airport , Matamoros"
        },
        {
            "value": "MAN",
            "icao_code": "EGCC",
            "label": "Manchester Airport , Manchester"
        },
        {
            "value": "MAO",
            "icao_code": "SBEG",
            "label": "Eduardo Gomes International Airport , Manaus"
        },
        {
            "value": "MAP",
            "icao_code": null,
            "label": "Mamai Airport , Mamai"
        },
        {
            "value": "MAQ",
            "icao_code": "VTPM",
            "label": "Mae Sot Airport , Mae Sot"
        },
        {
            "value": "MAR",
            "icao_code": "SVMC",
            "label": "La Chinita International Airport , Maracaibo"
        },
        {
            "value": "MAS",
            "icao_code": "AYMO",
            "label": "Momote Airport , Manus Island"
        },
        {
            "value": "MAT",
            "icao_code": "FZAM",
            "label": "Matadi Tshimpi Airport , Matadi"
        },
        {
            "value": "MAU",
            "icao_code": "NTTP",
            "label": "Maupiti Airport , Maupiti"
        },
        {
            "value": "MAV",
            "icao_code": null,
            "label": "Maloelap Airport , Taroa Island"
        },
        {
            "value": "MAW",
            "icao_code": "KMAW",
            "label": "Malden Regional Airport , Malden"
        },
        {
            "value": "MAX",
            "icao_code": "GOSM",
            "label": "Ouro Sogui Airport , Matam"
        },
        {
            "value": "MAY",
            "icao_code": "MYAB",
            "label": "Clarence A. Bain Airport , Mangrove Cay"
        },
        {
            "value": "MAZ",
            "icao_code": "TJMZ",
            "label": "Eugenio Mar\u00eda de Hostos Airport , Mayag\u00fcez"
        },
        {
            "value": "MBA",
            "icao_code": "HKMO",
            "label": "Moi International Airport , Mombasa"
        },
        {
            "value": "MBB",
            "icao_code": "YMBL",
            "label": "Marble Bar Airport , Marble Bar"
        },
        {
            "value": "MBC",
            "icao_code": "FOGG",
            "label": "Mbigou Airport , Mbigou"
        },
        {
            "value": "MBD",
            "icao_code": "FAMM",
            "label": "Mahikeng Airport , Mmabatho"
        },
        {
            "value": "MBE",
            "icao_code": "RJEB",
            "label": "Monbetsu Airport , Monbetsu, Hokkaido"
        },
        {
            "value": "MBF",
            "icao_code": "YPOK",
            "label": "Porepunkah Airfield , Mount Buffalo"
        },
        {
            "value": "MBG",
            "icao_code": "KMBG",
            "label": "Mobridge Municipal Airport , Mobridge"
        },
        {
            "value": "MBH",
            "icao_code": "YMYB",
            "label": "Maryborough Airport , Maryborough"
        },
        {
            "value": "MBI",
            "icao_code": "HTMB",
            "label": "Mbeya Airport , Mbeya"
        },
        {
            "value": "MBJ",
            "icao_code": "MKJS",
            "label": "Sangster International Airport , Montego Bay"
        },
        {
            "value": "MBK",
            "icao_code": "SWXM",
            "label": "Orlando Villas-B\u00f4as Regional Airport , Matup\u00e1"
        },
        {
            "value": "MBL",
            "icao_code": "KMBL",
            "label": "Manistee County Blacker Airport , Manistee"
        },
        {
            "value": "MBM",
            "icao_code": null,
            "label": "Mkambati Airport , Mkambati"
        },
        {
            "value": "MBN",
            "icao_code": null,
            "label": "Mount Barnett Airport , Mount Barnett"
        },
        {
            "value": "MBO",
            "icao_code": "RPUM",
            "label": "Mamburao Airport , Mamburao"
        },
        {
            "value": "MBP",
            "icao_code": "SPBB",
            "label": "Moyobamba Airport , Moyobamba"
        },
        {
            "value": "MBQ",
            "icao_code": "HUMA",
            "label": "Mbarara Airport , Mbarara"
        },
        {
            "value": "MBR",
            "icao_code": "GQNU",
            "label": "M'Bout Airport , M'Bout"
        },
        {
            "value": "MBS",
            "icao_code": "KMBS",
            "label": "MBS International Airport , Saginaw"
        },
        {
            "value": "MBT",
            "icao_code": "RPVJ",
            "label": "Moises R. Espinosa Airport , Masbate"
        },
        {
            "value": "MBU",
            "icao_code": "AGGI",
            "label": "Mbambanakira Airport , Mbambanakira"
        },
        {
            "value": "MBV",
            "icao_code": null,
            "label": "Masa Airport , Masa"
        },
        {
            "value": "MBW",
            "icao_code": "YMMB",
            "label": "Moorabbin Airport , Melbourne"
        },
        {
            "value": "MBX",
            "icao_code": "LJMB",
            "label": "Maribor Edvard Rusjan Airport , Maribor"
        },
        {
            "value": "MBY",
            "icao_code": "KMBY",
            "label": "Omar N. Bradley Airport , Moberly"
        },
        {
            "value": "MBZ",
            "icao_code": "SWMW",
            "label": "Mau\u00e9s Airport , Mau\u00e9s"
        },
        {
            "value": "MCA",
            "icao_code": "GUMA",
            "label": "Macenta Airport , Macenta"
        },
        {
            "value": "MCB",
            "icao_code": "KMCB",
            "label": "McComb\u2013Pike County Airport , McComb"
        },
        {
            "value": "MCC",
            "icao_code": "KMCC",
            "label": "McClellan Airfield , Sacramento"
        },
        {
            "value": "MCD",
            "icao_code": "KMCD",
            "label": "Mackinac Island Airport , Mackinac Island"
        },
        {
            "value": "MCE",
            "icao_code": "KMCE",
            "label": "Merced Regional Airport , Merced"
        },
        {
            "value": "MCF",
            "icao_code": "KMCF",
            "label": "MacDill Air Force Base , Tampa"
        },
        {
            "value": "MCG",
            "icao_code": "PAMC",
            "label": "McGrath Airport , McGrath"
        },
        {
            "value": "MCH",
            "icao_code": "SEMH",
            "label": "General Manuel Serrano Airport , Machala"
        },
        {
            "value": "MCI",
            "icao_code": "KMCI",
            "label": "Kansas City International Airport , Kansas City"
        },
        {
            "value": "MCJ",
            "icao_code": "SKLM",
            "label": "Jorge Isaacs Airport , Maicao"
        },
        {
            "value": "MCK",
            "icao_code": "KMCK",
            "label": "McCook Ben Nelson Regional Airport , McCook"
        },
        {
            "value": "MCL",
            "icao_code": "PAIN",
            "label": "McKinley National Park Airport , McKinley Park"
        },
        {
            "value": "MCN",
            "icao_code": "KMCN",
            "label": "Middle Georgia Regional Airport , Macon"
        },
        {
            "value": "MCO",
            "icao_code": "KMCO",
            "label": "Orlando International Airport , Orlando"
        },
        {
            "value": "MCP",
            "icao_code": "SBMQ",
            "label": "Alberto Alcolumbre International Airport , Macap\u00e1"
        },
        {
            "value": "MCQ",
            "icao_code": "LHMC",
            "label": "Miskolc Airport , Miskolc"
        },
        {
            "value": "MCS",
            "icao_code": "SARM",
            "label": "Monte Caseros Airport , Monte Caseros"
        },
        {
            "value": "MCT",
            "icao_code": "OOMS",
            "label": "Muscat International Airport , Muscat"
        },
        {
            "value": "MCU",
            "icao_code": "LFBK",
            "label": "Montlu\u00e7on \u2013 Gu\u00e9ret Airport , Montlu\u00e7on"
        },
        {
            "value": "MCV",
            "icao_code": "YMHU",
            "label": "McArthur River Mine Airport , McArthur River"
        },
        {
            "value": "MCW",
            "icao_code": "KMCW",
            "label": "Mason City Municipal Airport , Mason City"
        },
        {
            "value": "MCX",
            "icao_code": "URML",
            "label": "Uytash Airport , Makhachkala"
        },
        {
            "value": "MCY",
            "icao_code": "YBSU",
            "label": "Sunshine Coast Airport , Sunshine Coast"
        },
        {
            "value": "MCZ",
            "icao_code": "SBMO",
            "label": "Zumbi dos Palmares International Airport , Macei\u00f3"
        },
        {
            "value": "MDB",
            "icao_code": "MZML",
            "label": "Melinda Airport , Melinda"
        },
        {
            "value": "MDC",
            "icao_code": "WAMM",
            "label": "Sam Ratulangi International Airport , Manado"
        },
        {
            "value": "MDD",
            "icao_code": "KMDD",
            "label": "Midland Airpark , Midland"
        },
        {
            "value": "MDE",
            "icao_code": "SKRG",
            "label": "Jos\u00e9 Mar\u00eda C\u00f3rdova International Airport , Medell\u00edn"
        },
        {
            "value": "MDF",
            "icao_code": "KMDZ",
            "label": "Taylor County Airport , Medford"
        },
        {
            "value": "MDG",
            "icao_code": "ZYMD",
            "label": "Mudanjiang Hailang Airport , Mudanjiang"
        },
        {
            "value": "MDH",
            "icao_code": "KMDH",
            "label": "Southern Illinois Airport , Carbondale"
        },
        {
            "value": "MDI",
            "icao_code": "DNMK",
            "label": "Makurdi Airport , Makurdi"
        },
        {
            "value": "MDJ",
            "icao_code": null,
            "label": "Madras Municipal Airport , Madras"
        },
        {
            "value": "MDK",
            "icao_code": "FZEA",
            "label": "Mbandaka Airport , Mbandaka"
        },
        {
            "value": "MDL",
            "icao_code": "VYMD",
            "label": "Mandalay International Airport , Mandalay"
        },
        {
            "value": "MDM",
            "icao_code": null,
            "label": "Munduku Airport , Munduku"
        },
        {
            "value": "MDN",
            "icao_code": "KIMS",
            "label": "Madison Municipal Airport , Madison"
        },
        {
            "value": "MDO",
            "icao_code": "PAMD",
            "label": "Middleton Island Airport , Middleton Island"
        },
        {
            "value": "MDP",
            "icao_code": "WAKD",
            "label": "Mindiptana Airport , Mindiptana"
        },
        {
            "value": "MDQ",
            "icao_code": "SAZM",
            "label": "Astor Piazzolla International Airport , Mar del Plata"
        },
        {
            "value": "MDS",
            "icao_code": "MBMC",
            "label": "Middle Caicos Airport , Middle Caicos"
        },
        {
            "value": "MDT",
            "icao_code": "KMDT",
            "label": "Harrisburg International Airport , Harrisburg"
        },
        {
            "value": "MDU",
            "icao_code": "AYMN",
            "label": "Mendi Airport , Mendi"
        },
        {
            "value": "MDV",
            "icao_code": null,
            "label": "M\u00e9douneu Airport , M\u00e9douneu"
        },
        {
            "value": "MDW",
            "icao_code": "KMDW",
            "label": "Midway International Airport , Chicago"
        },
        {
            "value": "MDX",
            "icao_code": "SATM",
            "label": "Mercedes Airport , Mercedes"
        },
        {
            "value": "MDY",
            "icao_code": "PMDY",
            "label": "Henderson Field , Sand Island"
        },
        {
            "value": "MDZ",
            "icao_code": "SAME",
            "label": "Gov. Francisco Gabrielli International Airport , Mendoza"
        },
        {
            "value": "MEA",
            "icao_code": "SBME",
            "label": "Benedito Lacerda Airport , Maca\u00e9"
        },
        {
            "value": "MEB",
            "icao_code": "YMEN",
            "label": "Essendon Airport , Melbourne"
        },
        {
            "value": "MEC",
            "icao_code": "SEMT",
            "label": "Eloy Alfaro International Airport , Manta"
        },
        {
            "value": "MED",
            "icao_code": "OEMA",
            "label": "Prince Mohammad bin Abdulaziz Airport , Medina"
        },
        {
            "value": "MEE",
            "icao_code": "NWWR",
            "label": "Mar\u00e9 Airport , Mar\u00e9"
        },
        {
            "value": "MEF",
            "icao_code": null,
            "label": "Melfi Airport , Melfi"
        },
        {
            "value": "MEG",
            "icao_code": "FNMA",
            "label": "Malanje Airport , Malanje"
        },
        {
            "value": "MEH",
            "icao_code": "ENMH",
            "label": "Mehamn Airport , Mehamn"
        },
        {
            "value": "MEI",
            "icao_code": "KMEI",
            "label": "Meridian Regional Airport , Meridian"
        },
        {
            "value": "MEJ",
            "icao_code": "KGKJ",
            "label": "Port Meadville Airport , Meadville"
        },
        {
            "value": "MEK",
            "icao_code": "GMFM",
            "label": "Bassatine Air Base , Meknes"
        },
        {
            "value": "MEL",
            "icao_code": "YMML",
            "label": "Melbourne Airport , Melbourne"
        },
        {
            "value": "MEM",
            "icao_code": "KMEM",
            "label": "Memphis International Airport , Memphis"
        },
        {
            "value": "MEN",
            "icao_code": "LFNB",
            "label": "Brenoux Airport , Mende"
        },
        {
            "value": "MEO",
            "icao_code": "KMQI",
            "label": "Dare County Regional Airport , Manteo"
        },
        {
            "value": "MEP",
            "icao_code": "WMAU",
            "label": "Mersing Airport , Mersing"
        },
        {
            "value": "MEQ",
            "icao_code": "WITC",
            "label": "Cut Nyak Dhien Airport , Meulaboh"
        },
        {
            "value": "MER",
            "icao_code": "KMER",
            "label": "Castle Airport , Merced"
        },
        {
            "value": "MES",
            "icao_code": "WIMK",
            "label": "Soewondo Air Force Base , Medan"
        },
        {
            "value": "MET",
            "icao_code": "YMOT",
            "label": "Moreton Airport , Moreton"
        },
        {
            "value": "MEU",
            "icao_code": "SBMD",
            "label": "Serra do Are\u00e3o Airport , Monte Dourado"
        },
        {
            "value": "MEV",
            "icao_code": "KMEV",
            "label": "Minden\u2013Tahoe Airport , Minden"
        },
        {
            "value": "MEW",
            "icao_code": "FZVM",
            "label": "Mweka Airport , Mweka"
        },
        {
            "value": "MEX",
            "icao_code": "MMMX",
            "label": "Mexico City International Airport , Mexico City"
        },
        {
            "value": "MEY",
            "icao_code": "VNMG",
            "label": "Meghauli Airport , Meghauli"
        },
        {
            "value": "MEZ",
            "icao_code": "FAMS",
            "label": "Messina Airport , Messina"
        },
        {
            "value": "MFA",
            "icao_code": "HTMA",
            "label": "Mafia Airport , Mafia Island"
        },
        {
            "value": "MFB",
            "icao_code": null,
            "label": "Monfort Airport , Monfort"
        },
        {
            "value": "MFC",
            "icao_code": "FXMF",
            "label": "Mafeteng Airport , Mafeteng"
        },
        {
            "value": "MFD",
            "icao_code": "KMFD",
            "label": "Mansfield Lahm Regional Airport , Mansfield"
        },
        {
            "value": "MFE",
            "icao_code": "KMFE",
            "label": "McAllen Miller International Airport , McAllen"
        },
        {
            "value": "MFF",
            "icao_code": "FOOD",
            "label": "Moanda Airport , Moanda"
        },
        {
            "value": "MFG",
            "icao_code": "OPMF",
            "label": "Muzaffarabad Airport , Muzaffarabad"
        },
        {
            "value": "MFH",
            "icao_code": null,
            "label": "Mesquite Airport , Mesquite"
        },
        {
            "value": "MFI",
            "icao_code": "KMFI",
            "label": "Marshfield Municipal Airport , Marshfield"
        },
        {
            "value": "MFJ",
            "icao_code": "NFMO",
            "label": "Moala Airport , Moala Island"
        },
        {
            "value": "MFK",
            "icao_code": "RCMT",
            "label": "Matsu Beigan Airport , Beigan"
        },
        {
            "value": "MFL",
            "icao_code": null,
            "label": "Mount Full Stop Airport , Wandovale"
        },
        {
            "value": "MFM",
            "icao_code": "VMMC",
            "label": "Macau International Airport , Macau"
        },
        {
            "value": "MFN",
            "icao_code": "NZMF",
            "label": "Milford Sound Airport , Milford Sound"
        },
        {
            "value": "MFO",
            "icao_code": null,
            "label": "Manguna Airport , Manguna"
        },
        {
            "value": "MFP",
            "icao_code": "YMCR",
            "label": "Manners Creek Airport , Manners Creek Station"
        },
        {
            "value": "MFQ",
            "icao_code": "DRRM",
            "label": "Maradi Airport , Maradi"
        },
        {
            "value": "MFR",
            "icao_code": "KMFR",
            "label": "Rogue Valley International\u2013Medford Airport , Medford"
        },
        {
            "value": "MFS",
            "icao_code": "SKMF",
            "label": "Miraflores Airport , Miraflores"
        },
        {
            "value": "MFU",
            "icao_code": "FLMF",
            "label": "Mfuwe Airport , Mfuwe"
        },
        {
            "value": "MFV",
            "icao_code": "KMFV",
            "label": "Accomack County Airport , Melfa"
        },
        {
            "value": "MFW",
            "icao_code": null,
            "label": "Magaruque Airport , Magaruque Island"
        },
        {
            "value": "MFX",
            "icao_code": "LFKX",
            "label": "M\u00e9ribel Altiport , M\u00e9ribel"
        },
        {
            "value": "MFY",
            "icao_code": null,
            "label": "Mayfa'ah Airport , Mayfa'ah"
        },
        {
            "value": "MFZ",
            "icao_code": null,
            "label": "Meselia Airport , Meselia"
        },
        {
            "value": "MGA",
            "icao_code": "MNMG",
            "label": "Augusto C. Sandino International Airport , Managua"
        },
        {
            "value": "MGB",
            "icao_code": "YMTG",
            "label": "Mount Gambier Airport , Mount Gambier"
        },
        {
            "value": "MGC",
            "icao_code": "KMGC",
            "label": "Michigan City Municipal Airport , Michigan City"
        },
        {
            "value": "MGD",
            "icao_code": "SLMG",
            "label": "Magdalena Airport , Magdalena"
        },
        {
            "value": "MGE",
            "icao_code": "KMGE",
            "label": "Dobbins Air Reserve Base , Marietta"
        },
        {
            "value": "MGF",
            "icao_code": "SBMG",
            "label": "S\u00edlvio label J\u00fanior Regional Airport , Maring\u00e1"
        },
        {
            "value": "MGG",
            "icao_code": null,
            "label": "Margarima Airport , Margarima"
        },
        {
            "value": "MGH",
            "icao_code": "FAMG",
            "label": "Margate Airport , Margate"
        },
        {
            "value": "MGI",
            "icao_code": null,
            "label": "Aransas National Wildlife Refuge Airport , Matagorda Island"
        },
        {
            "value": "MGJ",
            "icao_code": "KMGJ",
            "label": "Orange County Airport , Montgomery"
        },
        {
            "value": "MGK",
            "icao_code": "VYMT",
            "label": "Mong Ton Airport , Mong Ton"
        },
        {
            "value": "MGL",
            "icao_code": "EDLN",
            "label": "D\u00fcsseldorf M\u00f6nchengladbach Airport , M\u00f6nchengladbach"
        },
        {
            "value": "MGM",
            "icao_code": "KMGM",
            "label": "Montgomery Regional Airport , Montgomery"
        },
        {
            "value": "MGN",
            "icao_code": "SKMG",
            "label": "Baracoa Regional Airport , Magangu\u00e9"
        },
        {
            "value": "MGO",
            "icao_code": null,
            "label": "Manega Airport , Manega"
        },
        {
            "value": "MGP",
            "icao_code": null,
            "label": "Manga Airport , Manga"
        },
        {
            "value": "MGQ",
            "icao_code": "HCMM",
            "label": "Aden Adde International Airport , Mogadishu"
        },
        {
            "value": "MGR",
            "icao_code": "KMGR",
            "label": "Moultrie Municipal Airport , Moultrie"
        },
        {
            "value": "MGS",
            "icao_code": "NCMG",
            "label": "Mangaia Airport , Mangaia Island"
        },
        {
            "value": "MGT",
            "icao_code": "YMGB",
            "label": "Milingimbi Airport , Milingimbi Island"
        },
        {
            "value": "MGU",
            "icao_code": "VYMN",
            "label": "Manaung Airport , Manaung"
        },
        {
            "value": "MGV",
            "icao_code": "YMGR",
            "label": "Margaret River Station Airport , Margaret River Station"
        },
        {
            "value": "MGW",
            "icao_code": "KMGW",
            "label": "Morgantown Municipal Airport , Morgantown"
        },
        {
            "value": "MGX",
            "icao_code": "FOGI",
            "label": "Moabi Airport , Moabi"
        },
        {
            "value": "MGY",
            "icao_code": "KMGY",
            "label": "Dayton\u2013Wright Brothers Airport , Dayton"
        },
        {
            "value": "MGZ",
            "icao_code": "VYME",
            "label": "Myeik Airport , Myeik"
        },
        {
            "value": "MHA",
            "icao_code": "SYMD",
            "label": "Mahdia Airport , Mahdia"
        },
        {
            "value": "MHC",
            "icao_code": "SCPQ",
            "label": "Mocopulli Airport , Castro"
        },
        {
            "value": "MHD",
            "icao_code": "OIMM",
            "label": "Mashhad International Airport , Mashhad"
        },
        {
            "value": "MHE",
            "icao_code": "KMHE",
            "label": "Mitchell Municipal Airport , Mitchell"
        },
        {
            "value": "MHF",
            "icao_code": null,
            "label": "Morichal Airport , Morichal"
        },
        {
            "value": "MHG",
            "icao_code": "EDFM",
            "label": "Mannheim City Airport , Mannheim"
        },
        {
            "value": "MHH",
            "icao_code": "MYAM",
            "label": "Marsh Harbour Airport , Marsh Harbour"
        },
        {
            "value": "MHI",
            "icao_code": "HDMO",
            "label": "Moucha Airport , Moucha Island"
        },
        {
            "value": "MHK",
            "icao_code": "KMHK",
            "label": "Manhattan Regional Airport , Manhattan"
        },
        {
            "value": "MHL",
            "icao_code": "KMHL",
            "label": "Marshall Memorial Municipal Airport , Marshall"
        },
        {
            "value": "MHN",
            "icao_code": "KMHN",
            "label": "Hooker County Airport , Mullen"
        },
        {
            "value": "MHO",
            "icao_code": "YMHO",
            "label": "Mount House Airport , Mount House Station"
        },
        {
            "value": "MHP",
            "icao_code": "UMMM",
            "label": "Minsk-1 Airport , Minsk, Belarus"
        },
        {
            "value": "MHQ",
            "icao_code": "EFMA",
            "label": "Mariehamn Airport , Mariehamn"
        },
        {
            "value": "MHR",
            "icao_code": "KMHR",
            "label": "Sacramento Mather Airport , Sacramento"
        },
        {
            "value": "MHS",
            "icao_code": null,
            "label": "Dunsmuir Municipal-Mott Airport , Mount Shasta"
        },
        {
            "value": "MHT",
            "icao_code": "KMHT",
            "label": "Manchester\u2013Boston Regional Airport , Manchester"
        },
        {
            "value": "MHU",
            "icao_code": "YHOT",
            "label": "Mount Hotham Airport , Mount Hotham"
        },
        {
            "value": "MHV",
            "icao_code": "KMHV",
            "label": "Mojave Air and Space Port , Mojave"
        },
        {
            "value": "MHW",
            "icao_code": "SLAG",
            "label": "Monteagudo Airport , Monteagudo"
        },
        {
            "value": "MHX",
            "icao_code": "NCMH",
            "label": "Manihiki Island Airport , Manihiki"
        },
        {
            "value": "MHY",
            "icao_code": null,
            "label": "Morehead Airport , Morehead"
        },
        {
            "value": "MHZ",
            "icao_code": "EGUN",
            "label": "RAF Mildenhall , Mildenhall"
        },
        {
            "value": "MIA",
            "icao_code": "KMIA",
            "label": "Miami International Airport , Miami"
        },
        {
            "value": "MIB",
            "icao_code": "KMIB",
            "label": "Minot Air Force Base , Minot"
        },
        {
            "value": "MIC",
            "icao_code": "KMIC",
            "label": "Crystal Airport , Minneapolis"
        },
        {
            "value": "MID",
            "icao_code": "MMMD",
            "label": "Manuel Crescencio Rej\u00f3n International Airport , M\u00e9rida"
        },
        {
            "value": "MIE",
            "icao_code": "KMIE",
            "label": "Delaware County Regional Airport , Muncie"
        },
        {
            "value": "MIF",
            "icao_code": null,
            "label": "Roy Hurd Memorial Airport , Monahans"
        },
        {
            "value": "MIG",
            "icao_code": "ZUMY",
            "label": "Mianyang Nanjiao Airport , Mianyang"
        },
        {
            "value": "MIH",
            "icao_code": "YMIP",
            "label": "Mitchell Plateau Airport , Mitchell Plateau"
        },
        {
            "value": "MII",
            "icao_code": "SBML",
            "label": "Frank Miloye Milenkowichi\u2013Mar\u00edlia State Airport , Mar\u00edlia"
        },
        {
            "value": "MIJ",
            "icao_code": "MLIP",
            "label": "Mili Airport , Mili Atoll"
        },
        {
            "value": "MIK",
            "icao_code": "EFMI",
            "label": "Mikkeli Airport , Mikkeli"
        },
        {
            "value": "MIL",
            "icao_code": null,
            "label": "metropolitan area , Milan"
        },
        {
            "value": "MIM",
            "icao_code": "YMER",
            "label": "Merimbula Airport , Merimbula"
        },
        {
            "value": "MIN",
            "icao_code": "YMPA",
            "label": "Minnipa Airport , Minnipa"
        },
        {
            "value": "MIO",
            "icao_code": "KMIO",
            "label": "Miami Municipal Airport , Miami"
        },
        {
            "value": "MIP",
            "icao_code": "LLMR",
            "label": "Mitzpe Ramon Airport , Mitzpe Ramon"
        },
        {
            "value": "MIQ",
            "icao_code": "KMLE",
            "label": "Millard Airport , Omaha"
        },
        {
            "value": "MIR",
            "icao_code": "DTMB",
            "label": "Monastir Habib Bourguiba International Airport , Monastir"
        },
        {
            "value": "MIS",
            "icao_code": "AYMS",
            "label": "Misima Island Airport , Misima Island"
        },
        {
            "value": "MIT",
            "icao_code": "KMIT",
            "label": "Shafter Airport , Shafter"
        },
        {
            "value": "MIU",
            "icao_code": "DNMA",
            "label": "Maiduguri International Airport , Maiduguri"
        },
        {
            "value": "MIV",
            "icao_code": "KMIV",
            "label": "Millville Municipal Airport , Millville"
        },
        {
            "value": "MIW",
            "icao_code": "KMIW",
            "label": "Marshalltown Municipal Airport , Marshalltown"
        },
        {
            "value": "MIX",
            "icao_code": null,
            "label": "Mirit\u00ed-Paran\u00e1 Airport , Mirit\u00ed-Paran\u00e1"
        },
        {
            "value": "MIY",
            "icao_code": null,
            "label": "Mittiebah Airport , Mittiebah Station"
        },
        {
            "value": "MIZ",
            "icao_code": null,
            "label": "Mainoru Airport , Mainoru"
        },
        {
            "value": "MJA",
            "icao_code": "FMSJ",
            "label": "Manja Airport , Manja"
        },
        {
            "value": "MJB",
            "icao_code": null,
            "label": "Mejit Airport , Mejit Island"
        },
        {
            "value": "MJC",
            "icao_code": "DIMN",
            "label": "Man Airport , Man"
        },
        {
            "value": "MJD",
            "icao_code": "OPMJ",
            "label": "Moenjodaro Airport , Mohenjo-daro"
        },
        {
            "value": "MJE",
            "icao_code": null,
            "label": "Majkin Airport , Majkin"
        },
        {
            "value": "MJF",
            "icao_code": "ENMS",
            "label": "Mosj\u00f8en Airport, Kj\u00e6rstad , Mosj\u00f8en"
        },
        {
            "value": "MJI",
            "icao_code": "HLLM",
            "label": "Mitiga International Airport , Tripoli"
        },
        {
            "value": "MJJ",
            "icao_code": null,
            "label": "Moki Airport , Moki"
        },
        {
            "value": "MJK",
            "icao_code": "YSHK",
            "label": "Shark Bay Airport , Monkey Mia"
        },
        {
            "value": "MJL",
            "icao_code": "FOGM",
            "label": "Mouila Airport , Mouila"
        },
        {
            "value": "MJM",
            "icao_code": "FZWA",
            "label": "Mbuji Mayi Airport , Mbuji-Mayi"
        },
        {
            "value": "MJN",
            "icao_code": "FMNM",
            "label": "Amborovy Airport , Mahajanga"
        },
        {
            "value": "MJO",
            "icao_code": "FYME",
            "label": "Mount Etjo Airport , Mount Etjo"
        },
        {
            "value": "MJP",
            "icao_code": "YMJM",
            "label": "Manjimup Airport , Manjimup"
        },
        {
            "value": "MJQ",
            "icao_code": "KMJQ",
            "label": "Jackson Municipal Airport , Jackson"
        },
        {
            "value": "MJR",
            "icao_code": null,
            "label": "Miramar Airport , Miramar"
        },
        {
            "value": "MJS",
            "icao_code": null,
            "label": "Maganja da Costa Airport , Maganja da Costa"
        },
        {
            "value": "MJT",
            "icao_code": "LGMT",
            "label": "Mytilene International Airport , Mytilene"
        },
        {
            "value": "MJU",
            "icao_code": "WAAJ",
            "label": "Tampa Padang Airport , Mamuju"
        },
        {
            "value": "MJV",
            "icao_code": "LELC",
            "label": "Murcia\u2013San Javier Airport , Murcia"
        },
        {
            "value": "MJW",
            "icao_code": "FVMH",
            "label": "Mahenye Airport , Mahenye"
        },
        {
            "value": "MJX",
            "icao_code": "KMJX",
            "label": "Robert J. Miller Air Park , Toms River"
        },
        {
            "value": "MJZ",
            "icao_code": "UERR",
            "label": "Mirny Airport , Mirny"
        },
        {
            "value": "MKA",
            "icao_code": "LKMR",
            "label": "Mari\u00e1nsk\u00e9 L\u00e1zn\u011b Airport , Mari\u00e1nsk\u00e9 L\u00e1zn\u011b"
        },
        {
            "value": "MKB",
            "icao_code": "FOOE",
            "label": "M\u00e9kambo Airport , M\u00e9kambo"
        },
        {
            "value": "MKC",
            "icao_code": "KMKC",
            "label": "Charles B. Wheeler Downtown Airport , Kansas City"
        },
        {
            "value": "MKE",
            "icao_code": "KMKE",
            "label": "Milwaukee Mitchell International Airport , Milwaukee"
        },
        {
            "value": "MKG",
            "icao_code": "KMKG",
            "label": "Muskegon County Airport , Muskegon"
        },
        {
            "value": "MKH",
            "icao_code": "FXMK",
            "label": "Mokhotlong Airport , Mokhotlong"
        },
        {
            "value": "MKI",
            "icao_code": "FEGE",
            "label": "M'Boki Airport , Obo"
        },
        {
            "value": "MKJ",
            "icao_code": "FCOM",
            "label": "Makoua Airport , Makoua"
        },
        {
            "value": "MKK",
            "icao_code": "PHMK",
            "label": "Molokai Airport , Kaunakakai"
        },
        {
            "value": "MKL",
            "icao_code": "KMKL",
            "label": "McKellar\u2013Sipes Regional Airport , Jackson"
        },
        {
            "value": "MKM",
            "icao_code": "WBGK",
            "label": "Mukah Airport , Mukah"
        },
        {
            "value": "MKN",
            "icao_code": null,
            "label": "Malekolon Airport , Malekolon"
        },
        {
            "value": "MKO",
            "icao_code": "KMKO",
            "label": "Davis Field , Muskogee"
        },
        {
            "value": "MKP",
            "icao_code": "NTGM",
            "label": "Makemo Airport , Makemo"
        },
        {
            "value": "MKQ",
            "icao_code": "WAKK",
            "label": "Mopah Airport , Merauke"
        },
        {
            "value": "MKR",
            "icao_code": "YMEK",
            "label": "Meekatharra Airport , Meekatharra"
        },
        {
            "value": "MKS",
            "icao_code": "HAMA",
            "label": "Mekane Selam Airport , Mekane Selam"
        },
        {
            "value": "MKT",
            "icao_code": "KMKT",
            "label": "Mankato Regional Airport , Mankato"
        },
        {
            "value": "MKU",
            "icao_code": "FOOK",
            "label": "Makokou Airport , Makokou"
        },
        {
            "value": "MKV",
            "icao_code": "YMVG",
            "label": "Mount Cavenagh Airport , Mount Cavenagh"
        },
        {
            "value": "MKW",
            "icao_code": "WASR",
            "label": "Rendani Airport , Manokwari"
        },
        {
            "value": "MKY",
            "icao_code": "YBMK",
            "label": "Mackay Airport , Mackay"
        },
        {
            "value": "MKZ",
            "icao_code": "WMKM",
            "label": "Malacca International Airport , Malacca City"
        },
        {
            "value": "MLA",
            "icao_code": "LMML",
            "label": "Malta International Airport , Luqa"
        },
        {
            "value": "MLB",
            "icao_code": "KMLB",
            "label": "Melbourne Orlando International Airport , Melbourne"
        },
        {
            "value": "MLC",
            "icao_code": "KMLC",
            "label": "McAlester Regional Airport , McAlester"
        },
        {
            "value": "MLD",
            "icao_code": "KMLD",
            "label": "Malad City Airport , Malad City"
        },
        {
            "value": "MLE",
            "icao_code": "VRMM",
            "label": "Ibrahim Nasir International Airport , Mal\u00e9"
        },
        {
            "value": "MLF",
            "icao_code": "KMLF",
            "label": "Milford Municipal Airport , Milford"
        },
        {
            "value": "MLG",
            "icao_code": "WARA",
            "label": "Abdul Rachman Saleh Airport , Malang"
        },
        {
            "value": "MLH",
            "icao_code": "LFSB",
            "label": "EuroAirport Basel Mulhouse Freiburg , Mulhouse"
        },
        {
            "value": "MLI",
            "icao_code": "KMLI",
            "label": "Quad Cities International Airport , Moline"
        },
        {
            "value": "MLJ",
            "icao_code": "KMLJ",
            "label": "Baldwin County Airport , Milledgeville"
        },
        {
            "value": "MLK",
            "icao_code": null,
            "label": "Malta Airport , Malta"
        },
        {
            "value": "MLL",
            "icao_code": "PADM",
            "label": "Marshall Don Hunter Sr. Airport , Marshall"
        },
        {
            "value": "MLM",
            "icao_code": "MMMM",
            "label": "General Francisco J. Mujica International Airport , Morelia"
        },
        {
            "value": "MLN",
            "icao_code": "GEML",
            "label": "Melilla Airport , Melilla"
        },
        {
            "value": "MLO",
            "icao_code": "LGML",
            "label": "Milos Island National Airport , Milos"
        },
        {
            "value": "MLP",
            "icao_code": "RPMM",
            "label": "Malabang Airport , Malabang"
        },
        {
            "value": "MLQ",
            "icao_code": null,
            "label": "Malalaua Airport , Malalaua"
        },
        {
            "value": "MLR",
            "icao_code": "YMCT",
            "label": "Millicent Airport , Millicent"
        },
        {
            "value": "MLS",
            "icao_code": "KMLS",
            "label": "Miles City Airport , Miles City"
        },
        {
            "value": "MLT",
            "icao_code": "KMLT",
            "label": "Millinocket Municipal Airport , Millinocket"
        },
        {
            "value": "MLU",
            "icao_code": "KMLU",
            "label": "Monroe Regional Airport , Monroe"
        },
        {
            "value": "MLV",
            "icao_code": "YMEU",
            "label": "Merluna Airport , Merluna"
        },
        {
            "value": "MLW",
            "icao_code": "GLMR",
            "label": "Spriggs Payne Airport , Monrovia"
        },
        {
            "value": "MLX",
            "icao_code": "LTAT",
            "label": "Malatya Erha\u00e7 Airport , Malatya"
        },
        {
            "value": "MLY",
            "icao_code": "PAML",
            "label": "Manley Hot Springs Airport , Manley Hot Springs"
        },
        {
            "value": "MLZ",
            "icao_code": "SUMO",
            "label": "Cerro Largo International Airport , Melo"
        },
        {
            "value": "MMA",
            "icao_code": null,
            "label": "metropolitan area , Malm\u00f6"
        },
        {
            "value": "MMB",
            "icao_code": "RJCM",
            "label": "Memanbetsu Airport , \u014czora"
        },
        {
            "value": "MMC",
            "icao_code": "MMDM",
            "label": "Ciudad Mante National Airport , Ciudad Mante"
        },
        {
            "value": "MMD",
            "icao_code": "ROMD",
            "label": "Minami-Daito Airport , Minamidait\u014d"
        },
        {
            "value": "MME",
            "icao_code": "EGNV",
            "label": "Teesside International Airport , Teesside"
        },
        {
            "value": "MMF",
            "icao_code": "FKKF",
            "label": "Mamfe Airport , Mamfe"
        },
        {
            "value": "MMG",
            "icao_code": "YMOG",
            "label": "Mount Magnet Airport , Mount Magnet"
        },
        {
            "value": "MMH",
            "icao_code": "KMMH",
            "label": "Mammoth Yosemite Airport , Mammoth Lakes"
        },
        {
            "value": "MMI",
            "icao_code": "KMMI",
            "label": "McMinn County Airport , Athens"
        },
        {
            "value": "MMJ",
            "icao_code": "RJAF",
            "label": "Matsumoto Airport , Matsumoto"
        },
        {
            "value": "MMK",
            "icao_code": "ULMM",
            "label": "Murmansk Airport , Murmansk"
        },
        {
            "value": "MML",
            "icao_code": "KMML",
            "label": "Southwest Minnesota Regional Airport , Marshall"
        },
        {
            "value": "MMM",
            "icao_code": "YMMU",
            "label": "Middlemount Airport , Middlemount"
        },
        {
            "value": "MMN",
            "icao_code": null,
            "label": "Minute Man Air Field , Stow"
        },
        {
            "value": "MMO",
            "icao_code": "GVMA",
            "label": "Maio Airport , Maio Island"
        },
        {
            "value": "MMP",
            "icao_code": "SKMP",
            "label": "San Bernardo Airport , Momp\u00f3s"
        },
        {
            "value": "MMQ",
            "icao_code": "FLBA",
            "label": "Mbala Airport , Mbala"
        },
        {
            "value": "MMS",
            "icao_code": "KMMS",
            "label": "Selfs Airport , Marks"
        },
        {
            "value": "MMT",
            "icao_code": "KMMT",
            "label": "McEntire Joint National Guard Base , Columbia"
        },
        {
            "value": "MMU",
            "icao_code": "KMMU",
            "label": "Morristown Municipal Airport , Morristown"
        },
        {
            "value": "MMV",
            "icao_code": null,
            "label": "Mal Island Airport , Mal Island"
        },
        {
            "value": "MMW",
            "icao_code": null,
            "label": "Moma Airport , Moma"
        },
        {
            "value": "MMX",
            "icao_code": "ESMS",
            "label": "Malm\u00f6 Airport , Malm\u00f6"
        },
        {
            "value": "MMY",
            "icao_code": "ROMY",
            "label": "Miyako Airport , Miyakojima"
        },
        {
            "value": "MMZ",
            "icao_code": "OAMN",
            "label": "Maymana Airport , Maymana"
        },
        {
            "value": "MNA",
            "icao_code": "WAMN",
            "label": "Melangguane Airport , Melonguane"
        },
        {
            "value": "MNB",
            "icao_code": "FZAG",
            "label": "Muanda Airport , Moanda"
        },
        {
            "value": "MNC",
            "icao_code": "FQNC",
            "label": "Nacala Airport , Nacala"
        },
        {
            "value": "MND",
            "icao_code": null,
            "label": "Medina Airport , Medina"
        },
        {
            "value": "MNE",
            "icao_code": "YMUG",
            "label": "Mungeranie Airport , Mungeranie"
        },
        {
            "value": "MNF",
            "icao_code": "NFMA",
            "label": "Mana Island Airport , Mana Island"
        },
        {
            "value": "MNG",
            "icao_code": "YMGD",
            "label": "Maningrida Airport , Maningrida"
        },
        {
            "value": "MNH",
            "icao_code": null,
            "label": "Al-Musannah Airport , Al-Musannah"
        },
        {
            "value": "MNI",
            "icao_code": "TRPG",
            "label": "John A. Osborne Airport , British Overseas Territory"
        },
        {
            "value": "MNJ",
            "icao_code": "FMSM",
            "label": "Mananjary Airport , Mananjary"
        },
        {
            "value": "MNK",
            "icao_code": "NGMA",
            "label": "Maiana Airport , Maiana"
        },
        {
            "value": "MNL",
            "icao_code": "RPLL",
            "label": "Ninoy Aquino International Airport , Manila"
        },
        {
            "value": "MNM",
            "icao_code": "KMNM",
            "label": "Menominee-Marinette Twin County Airport , Menominee"
        },
        {
            "value": "MNN",
            "icao_code": "KMNN",
            "label": "Marion Municipal Airport , Marion"
        },
        {
            "value": "MNO",
            "icao_code": "FZRA",
            "label": "Manono Airport , Manono"
        },
        {
            "value": "MNP",
            "icao_code": null,
            "label": "Maron Island Airport , Maron Island"
        },
        {
            "value": "MNQ",
            "icao_code": "YMTO",
            "label": "Monto Airport , Monto"
        },
        {
            "value": "MNR",
            "icao_code": "FLMG",
            "label": "Mongu Airport , Mongu"
        },
        {
            "value": "MNS",
            "icao_code": "FLMA",
            "label": "Mansa Airport , Mansa"
        },
        {
            "value": "MNT",
            "icao_code": null,
            "label": "Minto Al Wright Airport , Minto"
        },
        {
            "value": "MNU",
            "icao_code": "VYMM",
            "label": "Mawlamyaing Airport , Mawlamyine"
        },
        {
            "value": "MNV",
            "icao_code": null,
            "label": "Mount Valley Airport , Mount Valley"
        },
        {
            "value": "MNW",
            "icao_code": "YMDS",
            "label": "MacDonald Downs Airport , MacDonald Downs"
        },
        {
            "value": "MNX",
            "icao_code": "SBMY",
            "label": "Manicor\u00e9 Airport , Manicor\u00e9"
        },
        {
            "value": "MNY",
            "icao_code": "AGGO",
            "label": "Mono Airport , Mono Island"
        },
        {
            "value": "MNZ",
            "icao_code": "KHEF",
            "label": "Manassas Regional Airport , Manassas"
        },
        {
            "value": "MOA",
            "icao_code": "MUMO",
            "label": "Orestes Acosta Airport , Moa"
        },
        {
            "value": "MOB",
            "icao_code": "KMOB",
            "label": "Mobile Regional Airport , Mobile"
        },
        {
            "value": "MOC",
            "icao_code": "SBMK",
            "label": "Montes Claros/M\u00e1rio Ribeiro Airport , Montes Claros"
        },
        {
            "value": "MOD",
            "icao_code": "KMOD",
            "label": "Modesto City\u2013County Airport , Modesto"
        },
        {
            "value": "MOE",
            "icao_code": "VYMO",
            "label": "Momeik Airport , Momeik"
        },
        {
            "value": "MOF",
            "icao_code": "WATC",
            "label": "Frans Seda Airport , Maumere"
        },
        {
            "value": "MOG",
            "icao_code": "VYMS",
            "label": "Monghsat Airport , Mong Hsat"
        },
        {
            "value": "MOH",
            "icao_code": null,
            "label": "Maleo Airport , Morowali"
        },
        {
            "value": "MOI",
            "icao_code": "NCMR",
            "label": "Mitiaro Airport , Mitiaro Island"
        },
        {
            "value": "MOJ",
            "icao_code": "MEMO",
            "label": "Moengo Airstrip , Moengo"
        },
        {
            "value": "MOK",
            "icao_code": "UNTM",
            "label": "Muynak Airport , Muynak"
        },
        {
            "value": "MOL",
            "icao_code": "ENML",
            "label": "Molde Airport, \u00c5r\u00f8 , Molde"
        },
        {
            "value": "MOM",
            "icao_code": "GQNL",
            "label": "Letfotar Airport , Moudjeria"
        },
        {
            "value": "MON",
            "icao_code": "NZMC",
            "label": "Mount Cook Aerodrome , Mount Cook"
        },
        {
            "value": "MOO",
            "icao_code": "YOOM",
            "label": "Moomba Airport , Moomba"
        },
        {
            "value": "MOP",
            "icao_code": "KMOP",
            "label": "Mount Pleasant Municipal Airport , Mount Pleasant"
        },
        {
            "value": "MOQ",
            "icao_code": "FMMV",
            "label": "Morondava Airport , Morondava"
        },
        {
            "value": "MOR",
            "icao_code": "KMOR",
            "label": "Morristown Regional Airport , Morristown"
        },
        {
            "value": "MOS",
            "icao_code": null,
            "label": "Moses Point Airport , Elim"
        },
        {
            "value": "MOT",
            "icao_code": "KMOT",
            "label": "Minot International Airport , Minot"
        },
        {
            "value": "MOU",
            "icao_code": "PAMO",
            "label": "Mountain Village Airport , Mountain Village"
        },
        {
            "value": "MOV",
            "icao_code": "YMRB",
            "label": "Moranbah Airport , Moranbah"
        },
        {
            "value": "MOW",
            "icao_code": null,
            "label": "metropolitan area , Moscow"
        },
        {
            "value": "MOX",
            "icao_code": "KMOX",
            "label": "Morris Municipal Airport , Morris"
        },
        {
            "value": "MOY",
            "icao_code": null,
            "label": "Monterrey Airport , Monterrey"
        },
        {
            "value": "MOZ",
            "icao_code": "NTTM",
            "label": "Moorea Airport , Mo'orea"
        },
        {
            "value": "MPA",
            "icao_code": "FYKM",
            "label": "Katima Mulilo Airport , Katima Mulilo"
        },
        {
            "value": "MPB",
            "icao_code": null,
            "label": "Miami Seaplane Base , Miami"
        },
        {
            "value": "MPC",
            "icao_code": "WIPU",
            "label": "Mukomuko Airport , Muko-Muko"
        },
        {
            "value": "MPD",
            "icao_code": "OPMP",
            "label": "Sindhri Airport , Mirpur Khas"
        },
        {
            "value": "MPF",
            "icao_code": null,
            "label": "Mapoda Airport , Mapoda"
        },
        {
            "value": "MPG",
            "icao_code": null,
            "label": "Makini Airport , Makini"
        },
        {
            "value": "MPH",
            "icao_code": "RPVE",
            "label": "Godofredo P. Ramos Airport , Malay"
        },
        {
            "value": "MPI",
            "icao_code": null,
            "label": "Mamitupu Airport , Mamitupo"
        },
        {
            "value": "MPJ",
            "icao_code": "KMPJ",
            "label": "Petit Jean Park Airport , Morrilton"
        },
        {
            "value": "MPL",
            "icao_code": "LFMT",
            "label": "Montpellier\u2013M\u00e9diterran\u00e9e Airport , Montpellier"
        },
        {
            "value": "MPM",
            "icao_code": "FQMA",
            "label": "Maputo International Airport , Maputo"
        },
        {
            "value": "MPN",
            "icao_code": "EGYP",
            "label": "RAF Mount Pleasant , British Overseas Territory"
        },
        {
            "value": "MPO",
            "icao_code": "KMPO",
            "label": "Pocono Mountains Municipal Airport , Mount Pocono"
        },
        {
            "value": "MPP",
            "icao_code": null,
            "label": "Mulatupo Airport , Mulatupo"
        },
        {
            "value": "MPQ",
            "icao_code": "OJMN",
            "label": "Ma'an Airport , Ma'an"
        },
        {
            "value": "MPR",
            "icao_code": "KMPR",
            "label": "McPherson Airport , McPherson"
        },
        {
            "value": "MPS",
            "icao_code": "KOSA",
            "label": "Mount Pleasant Regional Airport , Mount Pleasant"
        },
        {
            "value": "MPT",
            "icao_code": "WPMN",
            "label": "Maliana Airport , Maliana"
        },
        {
            "value": "MPU",
            "icao_code": null,
            "label": "Mabua Airstrip , Mabua"
        },
        {
            "value": "MPV",
            "icao_code": "KMPV",
            "label": "Edward F. Knapp State Airport , Barre"
        },
        {
            "value": "MPW",
            "icao_code": "UKCM",
            "label": "Mariupol International Airport , Mariupol"
        },
        {
            "value": "MPX",
            "icao_code": null,
            "label": "Miyanmin Airport , Miyanmin"
        },
        {
            "value": "MPY",
            "icao_code": "SOOA",
            "label": "Maripasoula Airport , Maripasoula"
        },
        {
            "value": "MPZ",
            "icao_code": "KMPZ",
            "label": "Mount Pleasant Municipal Airport , Mount Pleasant"
        },
        {
            "value": "MQA",
            "icao_code": "YMDI",
            "label": "Mandora Station Airport , Mandora Station"
        },
        {
            "value": "MQB",
            "icao_code": "KMQB",
            "label": "Macomb Municipal Airport , Macomb"
        },
        {
            "value": "MQC",
            "icao_code": "LFVM",
            "label": "Miquelon Airport , Miquelon"
        },
        {
            "value": "MQD",
            "icao_code": "SAVQ",
            "label": "Maquinchao Airport , Maquinchao"
        },
        {
            "value": "MQE",
            "icao_code": "YMQA",
            "label": "Marqua Airport , Marqua"
        },
        {
            "value": "MQF",
            "icao_code": "USCM",
            "label": "Magnitogorsk International Airport , Magnitogorsk"
        },
        {
            "value": "MQG",
            "icao_code": "FYMG",
            "label": "Midgard Airport , Midgard"
        },
        {
            "value": "MQH",
            "icao_code": "SBMC",
            "label": "Mina\u00e7u Airport , Mina\u00e7u"
        },
        {
            "value": "MQJ",
            "icao_code": "UEMA",
            "label": "Moma Airport , Khonuu"
        },
        {
            "value": "MQK",
            "icao_code": "SLTI",
            "label": "San Mat\u00edas Airport , San Mat\u00edas"
        },
        {
            "value": "MQL",
            "icao_code": "YMIA",
            "label": "Mildura Airport , Mildura"
        },
        {
            "value": "MQM",
            "icao_code": "LTCR",
            "label": "Mardin Airport , Mardin"
        },
        {
            "value": "MQN",
            "icao_code": "ENRA",
            "label": "Mo i Rana Airport, R\u00f8ssvoll , Mo i Rana"
        },
        {
            "value": "MQO",
            "icao_code": null,
            "label": "Malam Airport , Malam"
        },
        {
            "value": "MQP",
            "icao_code": "FAKN",
            "label": "Kruger Mpumalanga International Airport , Mbombela"
        },
        {
            "value": "MQQ",
            "icao_code": "FTTD",
            "label": "Moundou Airport , Moundou"
        },
        {
            "value": "MQR",
            "icao_code": null,
            "label": "Mosquera Airport , Mosquera"
        },
        {
            "value": "MQS",
            "icao_code": "TVSM",
            "label": "Mustique Airport , Mustique Island"
        },
        {
            "value": "MQT",
            "icao_code": "KSAW",
            "label": "Sawyer International Airport , Marquette"
        },
        {
            "value": "MQU",
            "icao_code": "SKQU",
            "label": "Mariquita Airport , Mariquita"
        },
        {
            "value": "MQV",
            "icao_code": null,
            "label": "Mostaganem Airport , Mostaganem"
        },
        {
            "value": "MQW",
            "icao_code": "KMQW",
            "label": "Telfair\u2013Wheeler Airport , McRae"
        },
        {
            "value": "MQX",
            "icao_code": "HAMK",
            "label": "Alula Aba Nega Airport , Mek'ele"
        },
        {
            "value": "MQY",
            "icao_code": "KMQY",
            "label": "Smyrna Airport , Smyrna"
        },
        {
            "value": "MQZ",
            "icao_code": "YMGT",
            "label": "Margaret River Airport , Margaret River"
        },
        {
            "value": "MRA",
            "icao_code": null,
            "label": "Misrata Airport , Misrata"
        },
        {
            "value": "MRB",
            "icao_code": "KMRB",
            "label": "Eastern WV Regional Airport , Martinsburg"
        },
        {
            "value": "MRC",
            "icao_code": "KMRC",
            "label": "Maury County Airport , Columbia"
        },
        {
            "value": "MRD",
            "icao_code": "SVMD",
            "label": "Alberto Carnevalli Airport , M\u00e9rida"
        },
        {
            "value": "MRE",
            "icao_code": null,
            "label": "Mara Serena Airport , Maasai Mara"
        },
        {
            "value": "MRF",
            "icao_code": "KMRF",
            "label": "Marfa Municipal Airport , Marfa"
        },
        {
            "value": "MRG",
            "icao_code": "YMBA",
            "label": "Mareeba Airfield , Mareeba"
        },
        {
            "value": "MRH",
            "icao_code": null,
            "label": "May River Airport , May River"
        },
        {
            "value": "MRI",
            "icao_code": "PAMR",
            "label": "Merrill Field , Anchorage"
        },
        {
            "value": "MRJ",
            "icao_code": "MHMA",
            "label": "Marcala Airport , Marcala"
        },
        {
            "value": "MRK",
            "icao_code": "KMKY",
            "label": "Marco Island Airport , Marco Island"
        },
        {
            "value": "MRL",
            "icao_code": null,
            "label": "Miners Lake Airport , Miners Lake"
        },
        {
            "value": "MRM",
            "icao_code": "AYMA",
            "label": "Manari Airport , Manari"
        },
        {
            "value": "MRN",
            "icao_code": "KMRN",
            "label": "Foothills Regional Airport , Morganton"
        },
        {
            "value": "MRO",
            "icao_code": "NZMS",
            "label": "Hood Aerodrome , Masterton"
        },
        {
            "value": "MRP",
            "icao_code": "YALA",
            "label": "Marla Airport , Marla"
        },
        {
            "value": "MRQ",
            "icao_code": "RPUW",
            "label": "Marinduque Airport , Marinduque Island"
        },
        {
            "value": "MRR",
            "icao_code": "SEMA",
            "label": "Jos\u00e9 Mar\u00eda Velasco Ibarra Airport , Macar\u00e1"
        },
        {
            "value": "MRS",
            "icao_code": "LFML",
            "label": "Marseille Provence Airport , Marseille"
        },
        {
            "value": "MRT",
            "icao_code": null,
            "label": "Moroak Airport , Moroak"
        },
        {
            "value": "MRU",
            "icao_code": "FIMP",
            "label": "Sir Seewoosagur Ramgoolam International Airport , Plaine Magnien"
        },
        {
            "value": "MRV",
            "icao_code": "URMM",
            "label": "Mineralnye Vody Airport , Mineralnye Vody"
        },
        {
            "value": "MRW",
            "icao_code": "EKMB",
            "label": "Lolland Falster Airport , Maribo"
        },
        {
            "value": "MRX",
            "icao_code": "OIAM",
            "label": "Mahshahr Airport , Bandar-e Mahshahr"
        },
        {
            "value": "MRY",
            "icao_code": "KMRY",
            "label": "Monterey Regional Airport , Monterey"
        },
        {
            "value": "MRZ",
            "icao_code": "YMOR",
            "label": "Moree Airport , Moree"
        },
        {
            "value": "MSA",
            "icao_code": "CZMD",
            "label": "Muskrat Dam Airport , Muskrat Dam"
        },
        {
            "value": "MSC",
            "icao_code": "KFFZ",
            "label": "Falcon Field Airport , Phoenix"
        },
        {
            "value": "MSD",
            "icao_code": null,
            "label": "Mount Pleasant Airport , Mount Pleasant"
        },
        {
            "value": "MSE",
            "icao_code": "EGMH",
            "label": "Manston Airport , Manston"
        },
        {
            "value": "MSF",
            "icao_code": "YMNS",
            "label": "Mount Swan Airport , Mount Swan"
        },
        {
            "value": "MSG",
            "icao_code": "FXMA",
            "label": "Matsaile Airport , Matsaile"
        },
        {
            "value": "MSH",
            "icao_code": "OOMA",
            "label": "Masirah Air Base , Masirah Island"
        },
        {
            "value": "MSI",
            "icao_code": null,
            "label": "Masalembo Airport , Masalembo"
        },
        {
            "value": "MSJ",
            "icao_code": "RJSM",
            "label": "Misawa Airport , Misawa"
        },
        {
            "value": "MSL",
            "icao_code": "KMSL",
            "label": "Northwest Alabama Regional Airport , Muscle Shoals"
        },
        {
            "value": "MSM",
            "icao_code": "FZCV",
            "label": "Masi-Manimba Airport , Masi-Manimba"
        },
        {
            "value": "MSN",
            "icao_code": "KMSN",
            "label": "Dane County Regional Airport , Madison"
        },
        {
            "value": "MSO",
            "icao_code": "KMSO",
            "label": "Missoula International Airport , Missoula"
        },
        {
            "value": "MSP",
            "icao_code": "KMSP",
            "label": "Minneapolis\u2013Saint Paul International Airport , Minneapolis"
        },
        {
            "value": "MSQ",
            "icao_code": "UMMS",
            "label": "Minsk National Airport , Minsk"
        },
        {
            "value": "MSR",
            "icao_code": "LTCK",
            "label": "Mu\u015f Airport , Mu\u015f"
        },
        {
            "value": "MSS",
            "icao_code": "KMSS",
            "label": "Massena International Airport , Massena"
        },
        {
            "value": "MST",
            "icao_code": "EHBK",
            "label": "Maastricht Aachen Airport , Maastricht"
        },
        {
            "value": "MSU",
            "icao_code": "FXMM",
            "label": "Moshoeshoe I International Airport , Maseru"
        },
        {
            "value": "MSV",
            "icao_code": "KMSV",
            "label": "Sullivan County International Airport , Monticello"
        },
        {
            "value": "MSW",
            "icao_code": "HHMS",
            "label": "Massawa International Airport , Massawa"
        },
        {
            "value": "MSX",
            "icao_code": "FCMM",
            "label": "Mossendjo Airport , Mossendjo"
        },
        {
            "value": "MSY",
            "icao_code": "KMSY",
            "label": "Louis Armstrong New Orleans International Airport , New Orleans"
        },
        {
            "value": "MSZ",
            "icao_code": "FNMO",
            "label": "Mo\u00e7\u00e2medes Airport , Mo\u00e7\u00e2medes"
        },
        {
            "value": "MTA",
            "icao_code": "NZMA",
            "label": "Matamata Airport , Matamata"
        },
        {
            "value": "MTB",
            "icao_code": "SKML",
            "label": "Montel\u00edbano Airport , Montel\u00edbano"
        },
        {
            "value": "MTC",
            "icao_code": "KMTC",
            "label": "Selfridge Air National Guard Base , Mount Clemens"
        },
        {
            "value": "MTD",
            "icao_code": "YMSF",
            "label": "Mount Sandford Station Airport , Mount Sandford Station"
        },
        {
            "value": "MTE",
            "icao_code": "SNMA",
            "label": "Monte Alegre Airport , Monte Alegre"
        },
        {
            "value": "MTF",
            "icao_code": "HAMT",
            "label": "Mizan Teferi Airport , Mizan Teferi"
        },
        {
            "value": "MTG",
            "icao_code": null,
            "label": "Mato Grosso Airport , Mato Grosso"
        },
        {
            "value": "MTH",
            "icao_code": "KMTH",
            "label": "Florida Keys Marathon Airport , Marathon"
        },
        {
            "value": "MTI",
            "icao_code": "GVMT",
            "label": "Mosteiros Airport , Mosteiros"
        },
        {
            "value": "MTJ",
            "icao_code": "KMTJ",
            "label": "Montrose Regional Airport , Montrose"
        },
        {
            "value": "MTK",
            "icao_code": "NGMN",
            "label": "Makin Airport , Makin Island"
        },
        {
            "value": "MTL",
            "icao_code": "YMND",
            "label": "Maitland Airport , Maitland"
        },
        {
            "value": "MTM",
            "icao_code": "PAMM",
            "label": "Metlakatla Seaplane Base , Metlakatla"
        },
        {
            "value": "MTN",
            "icao_code": "KMTN",
            "label": "Martin State Airport , Baltimore"
        },
        {
            "value": "MTO",
            "icao_code": "KMTO",
            "label": "Coles County Memorial Airport , Mattoon"
        },
        {
            "value": "MTP",
            "icao_code": "KMTP",
            "label": "Montauk Airport , Montauk"
        },
        {
            "value": "MTQ",
            "icao_code": "YMIT",
            "label": "Mitchell Airport , Mitchell"
        },
        {
            "value": "MTR",
            "icao_code": "SKMR",
            "label": "Los Garzones Airport , Monter\u00eda"
        },
        {
            "value": "MTS",
            "icao_code": "FDMS",
            "label": "Matsapha Airport , Manzini"
        },
        {
            "value": "MTT",
            "icao_code": "MMMT",
            "label": "Minatitl\u00e1n/Coatzacoalcos International Airport , Minatitl\u00e1n"
        },
        {
            "value": "MTU",
            "icao_code": null,
            "label": "Montepuez Airport , Montepuez"
        },
        {
            "value": "MTV",
            "icao_code": "NVSA",
            "label": "Mota Lava Airport , Mota Lava"
        },
        {
            "value": "MTW",
            "icao_code": "KMTW",
            "label": "Manitowoc County Airport , Manitowoc"
        },
        {
            "value": "MTX",
            "icao_code": "PABK",
            "label": "Metro Field , Fairbanks"
        },
        {
            "value": "MTY",
            "icao_code": "MMMY",
            "label": "General Mariano Escobedo International Airport , Monterrey"
        },
        {
            "value": "MTZ",
            "icao_code": "LLMZ",
            "label": "Bar Yehuda Airfield , Masada"
        },
        {
            "value": "MUA",
            "icao_code": "AGGM",
            "label": "Munda Airport , Munda"
        },
        {
            "value": "MUB",
            "icao_code": "FBMN",
            "label": "Maun Airport , Maun"
        },
        {
            "value": "MUC",
            "icao_code": "EDDM",
            "label": "Munich Airport , Munich"
        },
        {
            "value": "MUD",
            "icao_code": "FQMD",
            "label": "Mueda Airport , Mueda"
        },
        {
            "value": "MUE",
            "icao_code": "PHMU",
            "label": "Waimea-Kohala Airport , Kamuela"
        },
        {
            "value": "MUF",
            "icao_code": null,
            "label": "Muting Airport , Muting"
        },
        {
            "value": "MUG",
            "icao_code": "MMMG",
            "label": "Muleg\u00e9 Airstrip , Muleg\u00e9"
        },
        {
            "value": "MUH",
            "icao_code": "HEMM",
            "label": "Marsa Matruh International Airport , Mersa Matruh"
        },
        {
            "value": "MUI",
            "icao_code": "KMUI",
            "label": "Muir Army Airfield , Fort Indiantown Gap"
        },
        {
            "value": "MUJ",
            "icao_code": "HAMR",
            "label": "Mui Airport , Mui"
        },
        {
            "value": "MUK",
            "icao_code": "NCMK",
            "label": "Mauke Airport , Mauke Island"
        },
        {
            "value": "MUL",
            "icao_code": "KMUL",
            "label": "Spence Airport , Moultrie"
        },
        {
            "value": "MUN",
            "icao_code": "SVMT",
            "label": "Jos\u00e9 Tadeo Monagas International Airport , Matur\u00edn"
        },
        {
            "value": "MUO",
            "icao_code": "KMUO",
            "label": "Mountain Home Air Force Base , Mountain Home"
        },
        {
            "value": "MUP",
            "icao_code": "YMUP",
            "label": "Mulga Park Airport , Mulga Park"
        },
        {
            "value": "MUQ",
            "icao_code": "YMUC",
            "label": "Muccan Station Airport , Muccan Station"
        },
        {
            "value": "MUR",
            "icao_code": "WBGM",
            "label": "Marudi Airport , Marudi"
        },
        {
            "value": "MUS",
            "icao_code": "RJAM",
            "label": "Minami Torishima Airport , Minami-Tori-shima"
        },
        {
            "value": "MUT",
            "icao_code": "KMUT",
            "label": "Muscatine Municipal Airport , Muscatine"
        },
        {
            "value": "MUW",
            "icao_code": "DAOV",
            "label": "Ghriss Airport , Ghriss"
        },
        {
            "value": "MUX",
            "icao_code": "OPMT",
            "label": "Multan International Airport , Multan"
        },
        {
            "value": "MUY",
            "icao_code": "FCBM",
            "label": "Mouyondzi Airport , Mouyondzi"
        },
        {
            "value": "MUZ",
            "icao_code": "HTMU",
            "label": "Musoma Airport , Musoma"
        },
        {
            "value": "MVA",
            "icao_code": "BIRL",
            "label": "M\u00fdvatn Airport , M\u00fdvatn"
        },
        {
            "value": "MVB",
            "icao_code": "FOON",
            "label": "M'Vengue El Hadj Omar Bongo Ondimba International Airport , Franceville"
        },
        {
            "value": "MVC",
            "icao_code": "KMVC",
            "label": "Monroe County Airport , Monroeville"
        },
        {
            "value": "MVD",
            "icao_code": "SUMU",
            "label": "Carrasco Gral. Ces\u00e1reo L. Berisso International Airport , Montevideo"
        },
        {
            "value": "MVE",
            "icao_code": "KMVE",
            "label": "Montevideo\u2013Chippewa County Airport , Montevideo"
        },
        {
            "value": "MVF",
            "icao_code": "SBMS",
            "label": "Gov. Dix-Sept Rosado Airport , Mossor\u00f3"
        },
        {
            "value": "MVG",
            "icao_code": null,
            "label": "Mevang Airport , Mevang"
        },
        {
            "value": "MVH",
            "icao_code": null,
            "label": "Macksville Airport , Macksville"
        },
        {
            "value": "MVI",
            "icao_code": null,
            "label": "Manetai Airport , Manetai"
        },
        {
            "value": "MVK",
            "icao_code": "YMUK",
            "label": "Mulka Airport , Mulka"
        },
        {
            "value": "MVL",
            "icao_code": "KMVL",
            "label": "Morrisville\u2013Stowe State Airport , Morrisville"
        },
        {
            "value": "MVM",
            "icao_code": null,
            "label": "Kayenta Airport , Kayenta"
        },
        {
            "value": "MVN",
            "icao_code": "KMVN",
            "label": "Mount Vernon Airport , Mount Vernon"
        },
        {
            "value": "MVO",
            "icao_code": "FTTM",
            "label": "Mongo Airport , Mongo"
        },
        {
            "value": "MVP",
            "icao_code": "SKMU",
            "label": "Fabio Alberto Le\u00f3n Bentley Airport , Mit\u00fa"
        },
        {
            "value": "MVQ",
            "icao_code": "UMOO",
            "label": "Mogilev Airport , Mogilev"
        },
        {
            "value": "MVR",
            "icao_code": "FKKL",
            "label": "Salak Airport , Maroua"
        },
        {
            "value": "MVS",
            "icao_code": "SNMU",
            "label": "Mucuri Airport , Mucuri"
        },
        {
            "value": "MVT",
            "icao_code": "NTGV",
            "label": "Mataiva Airport , Mataiva"
        },
        {
            "value": "MVU",
            "icao_code": "YMGV",
            "label": "Musgrave Airport , Musgrave"
        },
        {
            "value": "MVV",
            "icao_code": "LFHM",
            "label": "Meg\u00e8ve Altiport , Meg\u00e8ve"
        },
        {
            "value": "MVW",
            "icao_code": "KBVS",
            "label": "Skagit Regional Airport , Burlington"
        },
        {
            "value": "MVX",
            "icao_code": "FOGV",
            "label": "Minvoul Airport , Minvoul"
        },
        {
            "value": "MVY",
            "icao_code": "KMVY",
            "label": "Martha's Vineyard Airport , Vineyard Haven"
        },
        {
            "value": "MVZ",
            "icao_code": "FVMV",
            "label": "Masvingo Airport , Masvingo"
        },
        {
            "value": "MWA",
            "icao_code": "KMWA",
            "label": "Williamson County Regional Airport , Marion"
        },
        {
            "value": "MWB",
            "icao_code": "YMRW",
            "label": "Morawa Airport , Morawa"
        },
        {
            "value": "MWC",
            "icao_code": "KMWC",
            "label": "Lawrence J. Timmerman Airport , Milwaukee"
        },
        {
            "value": "MWD",
            "icao_code": "OPMI",
            "label": "PAF Base M.M. Alam , Mianwali"
        },
        {
            "value": "MWE",
            "icao_code": "HSMR",
            "label": "Merowe Airport , Merowe"
        },
        {
            "value": "MWF",
            "icao_code": "NVSN",
            "label": "Maewo-Naone Airport , Maewo"
        },
        {
            "value": "MWG",
            "icao_code": null,
            "label": "Marawaka Airport , Marawaka"
        },
        {
            "value": "MWH",
            "icao_code": "KMWH",
            "label": "Grant County International Airport , Moses Lake"
        },
        {
            "value": "MWI",
            "icao_code": null,
            "label": "Maramuni Airport , Maramuni"
        },
        {
            "value": "MWJ",
            "icao_code": "SYMR",
            "label": "Matthews Ridge Airport , Matthew's Ridge"
        },
        {
            "value": "MWK",
            "icao_code": "WIOM",
            "label": "Matak Airport , Matak"
        },
        {
            "value": "MWL",
            "icao_code": "KMWL",
            "label": "Mineral Wells Airport , Mineral Wells"
        },
        {
            "value": "MWM",
            "icao_code": "KMWM",
            "label": "Windom Municipal Airport , Windom"
        },
        {
            "value": "MWN",
            "icao_code": "HTMD",
            "label": "Mwadui Airport , Mwadui"
        },
        {
            "value": "MWO",
            "icao_code": "KMWO",
            "label": "Middletown Regional Airport , Middletown"
        },
        {
            "value": "MWQ",
            "icao_code": "VYMW",
            "label": "Magway Airport , Magway"
        },
        {
            "value": "MWR",
            "icao_code": null,
            "label": "Motswari Airstrip , Motswari"
        },
        {
            "value": "MWT",
            "icao_code": "YMWT",
            "label": "Moolawatana Airport , Moolawatana Station"
        },
        {
            "value": "MWU",
            "icao_code": null,
            "label": "Mussau Island Airport , Mussau Island"
        },
        {
            "value": "MWV",
            "icao_code": "VDMK",
            "label": "Mondulkiri Airport , Senmonorom"
        },
        {
            "value": "MWX",
            "icao_code": "RKJB",
            "label": "Muan International Airport , Muan"
        },
        {
            "value": "MWY",
            "icao_code": "YMIR",
            "label": "Miranda Downs Airport , Miranda Downs"
        },
        {
            "value": "MWZ",
            "icao_code": "HTMW",
            "label": "Mwanza Airport , Mwanza"
        },
        {
            "value": "MXA",
            "icao_code": "KMXA",
            "label": "Manila Municipal Airport , Manila"
        },
        {
            "value": "MXB",
            "icao_code": "WAWM",
            "label": "Andi Jemma Airport , Masamba"
        },
        {
            "value": "MXC",
            "icao_code": null,
            "label": "Monticello Airport , Monticello"
        },
        {
            "value": "MXD",
            "icao_code": "YMWX",
            "label": "Marion Downs Airport , Marion Downs Station"
        },
        {
            "value": "MXE",
            "icao_code": "KMEB",
            "label": "Laurinburg\u2013Maxton Airport , Laurinburg"
        },
        {
            "value": "MXF",
            "icao_code": "KMXF",
            "label": "Maxwell Air Force Base , Montgomery"
        },
        {
            "value": "MXG",
            "icao_code": null,
            "label": "Marlboro Airport , Marlborough"
        },
        {
            "value": "MXH",
            "icao_code": "AYMR",
            "label": "Moro Airport , Moro"
        },
        {
            "value": "MXI",
            "icao_code": "RPMQ",
            "label": "Mati Airport , Mati"
        },
        {
            "value": "MXJ",
            "icao_code": "DNMN",
            "label": "Minna Airport , Minna"
        },
        {
            "value": "MXK",
            "icao_code": null,
            "label": "Mindik Airport , Mindik"
        },
        {
            "value": "MXL",
            "icao_code": "MMML",
            "label": "General Rodolfo S\u00e1nchez Taboada International Airport , Mexicali"
        },
        {
            "value": "MXM",
            "icao_code": "FMSR",
            "label": "Morombe Airport , Morombe"
        },
        {
            "value": "MXN",
            "icao_code": "LFRU",
            "label": "Morlaix \u2013 Ploujean Airport , Morlaix"
        },
        {
            "value": "MXO",
            "icao_code": "KMXO",
            "label": "Monticello Regional Airport , Monticello"
        },
        {
            "value": "MXP",
            "icao_code": "LIMC",
            "label": "Milan\u2013Malpensa Airport , Milan"
        },
        {
            "value": "MXR",
            "icao_code": null,
            "label": "Myrhorod Air Base , Myrhorod"
        },
        {
            "value": "MXS",
            "icao_code": "NSMA",
            "label": "Maota Airport , Maota"
        },
        {
            "value": "MXT",
            "icao_code": "FMMO",
            "label": "Maintirano Airport , Maintirano"
        },
        {
            "value": "MXU",
            "icao_code": "YMWA",
            "label": "Mullewa Airport , Mullewa"
        },
        {
            "value": "MXV",
            "icao_code": "ZMMN",
            "label": "M\u00f6r\u00f6n Airport , M\u00f6r\u00f6n"
        },
        {
            "value": "MXW",
            "icao_code": "ZMMG",
            "label": "Mandalgovi Airport , Mandalgovi"
        },
        {
            "value": "MXX",
            "icao_code": "ESKM",
            "label": "Mora\u2013Siljan Airport , Mora"
        },
        {
            "value": "MXY",
            "icao_code": "PAMX",
            "label": "McCarthy Airport , McCarthy"
        },
        {
            "value": "MXZ",
            "icao_code": "ZGMX",
            "label": "Meixian Airport , Meizhou"
        },
        {
            "value": "MYA",
            "icao_code": "YMRY",
            "label": "Moruya Airport , Moruya"
        },
        {
            "value": "MYB",
            "icao_code": "FOOY",
            "label": "Mayumba Airport , Mayumba"
        },
        {
            "value": "MYC",
            "icao_code": "SVBS",
            "label": "Mariscal Sucre Airport , Maracay"
        },
        {
            "value": "MYD",
            "icao_code": "HKML",
            "label": "Malindi Airport , Malindi"
        },
        {
            "value": "MYE",
            "icao_code": "RJTQ",
            "label": "Miyakejima Airport , Miyake-jima"
        },
        {
            "value": "MYF",
            "icao_code": "KMYF",
            "label": "Montgomery Field Airport , San Diego"
        },
        {
            "value": "MYG",
            "icao_code": "MYMM",
            "label": "Mayaguana Airport , Mayaguana"
        },
        {
            "value": "MYH",
            "icao_code": null,
            "label": "Marble Canyon Airport , Marble Canyon"
        },
        {
            "value": "MYI",
            "icao_code": "YMUI",
            "label": "Murray Island Airport , Murray Island"
        },
        {
            "value": "MYJ",
            "icao_code": "RJOM",
            "label": "Matsuyama Airport , Matsuyama"
        },
        {
            "value": "MYK",
            "icao_code": null,
            "label": "May Creek Airport , May Creek"
        },
        {
            "value": "MYL",
            "icao_code": "KMYL",
            "label": "McCall Municipal Airport , McCall"
        },
        {
            "value": "MYM",
            "icao_code": "SYMM",
            "label": "Monkey Mountain Airport , Monkey Mountain"
        },
        {
            "value": "MYN",
            "icao_code": "OYMB",
            "label": "Marib Airport , Ma'rib"
        },
        {
            "value": "MYO",
            "icao_code": "YMYR",
            "label": "Myroodah Airport , Myroodah Station"
        },
        {
            "value": "MYP",
            "icao_code": "UTAM",
            "label": "Mary International Airport , Mary"
        },
        {
            "value": "MYQ",
            "icao_code": "VOMY",
            "label": "Mysore Airport , Mysore"
        },
        {
            "value": "MYR",
            "icao_code": "KMYR",
            "label": "Myrtle Beach International Airport , Myrtle Beach"
        },
        {
            "value": "MYT",
            "icao_code": "VYMK",
            "label": "Myitkyina Airport , Myitkyina"
        },
        {
            "value": "MYU",
            "icao_code": "PAMY",
            "label": "Mekoryuk Airport , Mekoryuk"
        },
        {
            "value": "MYV",
            "icao_code": "KMYV",
            "label": "Yuba County Airport , Marysville"
        },
        {
            "value": "MYW",
            "icao_code": "HTMT",
            "label": "Mtwara Airport , Mtwara"
        },
        {
            "value": "MYX",
            "icao_code": null,
            "label": "Menyamya Airport , Menyamya"
        },
        {
            "value": "MYY",
            "icao_code": "WBGR",
            "label": "Miri Airport , Miri"
        },
        {
            "value": "MYZ",
            "icao_code": "FWMY",
            "label": "Monkey Bay Airport , Monkey Bay"
        },
        {
            "value": "MZA",
            "icao_code": "SPMF",
            "label": "Manuel Prado Ugarteche Airport , Mazamari"
        },
        {
            "value": "MZB",
            "icao_code": "FQMP",
            "label": "Moc\u00edmboa da Praia Airport , Moc\u00edmboa da Praia"
        },
        {
            "value": "MZC",
            "icao_code": "FOOM",
            "label": "Mitzic Airport , Mitzic"
        },
        {
            "value": "MZD",
            "icao_code": null,
            "label": "M\u00e9ndez Airport , Santiago de M\u00e9ndez"
        },
        {
            "value": "MZE",
            "icao_code": "MZSL",
            "label": "Manatee Airport , Spanish Lookout"
        },
        {
            "value": "MZF",
            "icao_code": "FAMW",
            "label": "Wild Coast Sun Airport , Mzamba"
        },
        {
            "value": "MZG",
            "icao_code": "RCQC",
            "label": "Magong Airport , Magong"
        },
        {
            "value": "MZH",
            "icao_code": "LTAP",
            "label": "Amasya Merzifon Airport , Amasya"
        },
        {
            "value": "MZI",
            "icao_code": "GAMB",
            "label": "Mopti Airport , Mopti"
        },
        {
            "value": "MZJ",
            "icao_code": "KMZJ",
            "label": "Pinal Airpark , Marana"
        },
        {
            "value": "MZK",
            "icao_code": "NGMK",
            "label": "Marakei Airport , Marakei"
        },
        {
            "value": "MZL",
            "icao_code": "SKMZ",
            "label": "La Nubia Airport , Manizales"
        },
        {
            "value": "MZM",
            "icao_code": "LFSF",
            "label": "Metz-Frescaty Air Base , Metz"
        },
        {
            "value": "MZN",
            "icao_code": null,
            "label": "Minj Airport , Minj"
        },
        {
            "value": "MZO",
            "icao_code": "MUMZ",
            "label": "Sierra Maestra Airport , Manzanillo"
        },
        {
            "value": "MZP",
            "icao_code": "NZMK",
            "label": "Motueka Aerodrome , Motueka"
        },
        {
            "value": "MZQ",
            "icao_code": "FAMU",
            "label": "Mkuze Airport , Mkuze"
        },
        {
            "value": "MZR",
            "icao_code": "OAMS",
            "label": "Mazar-e Sharif International Airport , Mazar-i-Sharif"
        },
        {
            "value": "MZT",
            "icao_code": "MMMZ",
            "label": "General Rafael Buelna International Airport , Mazatl\u00e1n"
        },
        {
            "value": "MZU",
            "icao_code": "VEMZ",
            "label": "Muzaffarpur Airport , Muzaffarpur"
        },
        {
            "value": "MZV",
            "icao_code": "WBMU",
            "label": "Mulu Airport , Mulu"
        },
        {
            "value": "MZW",
            "icao_code": "DAAY",
            "label": "M\u00e9cheria Airport , M\u00e9cheria"
        },
        {
            "value": "MZX",
            "icao_code": "HAML",
            "label": "Mena Airport , Mena"
        },
        {
            "value": "MZY",
            "icao_code": "FAMO",
            "label": "Mossel Bay Airport , Mossel Bay"
        },
        {
            "value": "MZZ",
            "icao_code": "KMZZ",
            "label": "Marion Municipal Airport , Marion"
        },
        {
            "value": "NAA",
            "icao_code": "YNBR",
            "label": "Narrabri Airport , Narrabri"
        },
        {
            "value": "NAC",
            "icao_code": "YNRC",
            "label": "Naracoorte Airport , Naracoorte, South Australia"
        },
        {
            "value": "NAD",
            "icao_code": null,
            "label": "Macanal Airport , Macanal"
        },
        {
            "value": "NAE",
            "icao_code": "DBBN",
            "label": "Bound\u00e9tingou Airport , Natitingou"
        },
        {
            "value": "NAF",
            "icao_code": null,
            "label": "Banaina Airport , Banaina"
        },
        {
            "value": "NAG",
            "icao_code": "VANP",
            "label": "Dr. Babasaheb Ambedkar International Airport , Nagpur"
        },
        {
            "value": "NAH",
            "icao_code": "WAMH",
            "label": "Naha Airport , Tahuna"
        },
        {
            "value": "NAI",
            "icao_code": "SYAN",
            "label": "Annai Airport , Annai"
        },
        {
            "value": "NAJ",
            "icao_code": "UBBN",
            "label": "Nakhchivan International Airport , Nakhchivan"
        },
        {
            "value": "NAK",
            "icao_code": "VTUQ",
            "label": "Nakhon Ratchasima Airport , Nakhon Ratchasima"
        },
        {
            "value": "NAL",
            "icao_code": "URMN",
            "label": "Nalchik Airport , Nalchik"
        },
        {
            "value": "NAM",
            "icao_code": "WAPR",
            "label": "Namlea Airport , Namlea"
        },
        {
            "value": "NAN",
            "icao_code": "NFFN",
            "label": "Nadi International Airport , Nadi"
        },
        {
            "value": "NAO",
            "icao_code": "ZUNC",
            "label": "Nanchong Gaoping Airport , Nanchong"
        },
        {
            "value": "NAP",
            "icao_code": "LIRN",
            "label": "Naples International Airport , Naples"
        },
        {
            "value": "NAQ",
            "icao_code": "BGQQ",
            "label": "Qaanaaq Airport , Qaanaaq"
        },
        {
            "value": "NAR",
            "icao_code": "SKPN",
            "label": "Puerto Nare Airport , Puerto Nare"
        },
        {
            "value": "NAS",
            "icao_code": "MYNN",
            "label": "Lynden Pindling International Airport , Nassau"
        },
        {
            "value": "NAT",
            "icao_code": "SBNT",
            "label": "S\u00e3o Gon\u00e7alo do Amarante\u2013Governador Alu\u00edzio Alves International Airport , Natal"
        },
        {
            "value": "NAU",
            "icao_code": "NTGN",
            "label": "Napuka Airport , Napuka"
        },
        {
            "value": "NAV",
            "icao_code": "LTAZ",
            "label": "Nev\u015fehir Kapadokya Airport , Nev\u015fehir"
        },
        {
            "value": "NAW",
            "icao_code": "VTSC",
            "label": "Narathiwat Airport , Narathiwat"
        },
        {
            "value": "NAY",
            "icao_code": "ZBNY",
            "label": "Beijing Nanyuan Airport , Beijing"
        },
        {
            "value": "NAZ",
            "icao_code": "AGNA",
            "label": "Nana Airport , Star Harbour"
        },
        {
            "value": "NBA",
            "icao_code": null,
            "label": "Nambaiyufa Airport , Nambaiyufa"
        },
        {
            "value": "NBB",
            "icao_code": "SKBM",
            "label": "Barranco Minas Airport , Barranco Minas"
        },
        {
            "value": "NBC",
            "icao_code": "UWKE",
            "label": "Begishevo Airport , Nizhnekamsk"
        },
        {
            "value": "NBE",
            "icao_code": "DTNH",
            "label": "Enfidha-Hammamet International Airport , Enfidha"
        },
        {
            "value": "NBG",
            "icao_code": "KNBG",
            "label": "NAS JRB New Orleans , New Orleans"
        },
        {
            "value": "NBH",
            "icao_code": "YNHS",
            "label": "Nambucca Heads Airport , Nambucca Heads"
        },
        {
            "value": "NBL",
            "icao_code": "MPWN",
            "label": "Wannukandi Airport , San Blas"
        },
        {
            "value": "NBN",
            "icao_code": "FGAB",
            "label": "Annob\u00f3n Airport , Annob\u00f3n"
        },
        {
            "value": "NBO",
            "icao_code": "HKJK",
            "label": "Jomo Kenyatta International Airport , Nairobi"
        },
        {
            "value": "NBS",
            "icao_code": "ZYBS",
            "label": "Changbaishan Airport , Baishan"
        },
        {
            "value": "NBW",
            "icao_code": "KNBW",
            "label": "Leeward Point Field , Guant\u00e1namo Bay"
        },
        {
            "value": "NBX",
            "icao_code": "WABI",
            "label": "Nabire Airport , Nabire"
        },
        {
            "value": "NCA",
            "icao_code": "MBNC",
            "label": "North Caicos Airport , North Caicos"
        },
        {
            "value": "NCE",
            "icao_code": "LFMN",
            "label": "Nice C\u00f4te d'Azur Airport , Nice"
        },
        {
            "value": "NCG",
            "icao_code": "MMCG",
            "label": "Nuevo Casas Grandes Municipal Airport , Nuevo Casas Grandes"
        },
        {
            "value": "NCH",
            "icao_code": "HTNA",
            "label": "Nachingwea Airport , Nachingwea"
        },
        {
            "value": "NCI",
            "icao_code": "SKNC",
            "label": "Antioquia Airport , Necocl\u00ed"
        },
        {
            "value": "NCJ",
            "icao_code": "SAFS",
            "label": "Sunchales Aeroclub Airport , Sunchales"
        },
        {
            "value": "NCL",
            "icao_code": "EGNT",
            "label": "Newcastle Airport , Newcastle upon Tyne"
        },
        {
            "value": "NCN",
            "icao_code": "PFCB",
            "label": "Chenega Bay Airport , Chenega"
        },
        {
            "value": "NCO",
            "icao_code": "KOQU",
            "label": "Quonset State Airport , North Kingstown"
        },
        {
            "value": "NCR",
            "icao_code": "MNSC",
            "label": "San Carlos Airport , San Carlos"
        },
        {
            "value": "NCS",
            "icao_code": "FANC",
            "label": "Newcastle Airport , Newcastle"
        },
        {
            "value": "NCT",
            "icao_code": "MRNC",
            "label": "Nicoya Guanacaste Airport , Nicoya"
        },
        {
            "value": "NCU",
            "icao_code": "UTNN",
            "label": "Nukus Airport , Nukus"
        },
        {
            "value": "NCY",
            "icao_code": "LFLP",
            "label": "Annecy \u2013 Haute-Savoie \u2013 Mont Blanc Airport , Annecy"
        },
        {
            "value": "NDA",
            "icao_code": "WAPC",
            "label": "Bandanaira Airport , Banda Islands"
        },
        {
            "value": "NDB",
            "icao_code": "GQPP",
            "label": "Nouadhibou International Airport , Nouadhibou"
        },
        {
            "value": "NDC",
            "icao_code": "VAND",
            "label": "Shri Guru Gobind Singh Ji Airport , Nanded"
        },
        {
            "value": "NDD",
            "icao_code": "FNSU",
            "label": "Sumbe Airport , Sumbe"
        },
        {
            "value": "NDE",
            "icao_code": "HKMA",
            "label": "Mandera Airport , Mandera"
        },
        {
            "value": "NDF",
            "icao_code": null,
            "label": "N'dalatando Airport , N'dalatando"
        },
        {
            "value": "NDG",
            "icao_code": "ZYQQ",
            "label": "Qiqihar Sanjiazi Airport , Qiqihar"
        },
        {
            "value": "NDI",
            "icao_code": null,
            "label": "Namudi Airport , Namudi"
        },
        {
            "value": "NDJ",
            "icao_code": "FTTJ",
            "label": "N'Djamena International Airport , N'Djamena"
        },
        {
            "value": "NDK",
            "icao_code": null,
            "label": "Namorik Airport , Namdrik Atoll"
        },
        {
            "value": "NDL",
            "icao_code": "FEFN",
            "label": "N'D\u00e9l\u00e9 Airport , N'D\u00e9l\u00e9"
        },
        {
            "value": "NDM",
            "icao_code": "HAMN",
            "label": "Mendi Airport , Mendi"
        },
        {
            "value": "NDN",
            "icao_code": null,
            "label": "Nadunumu Airport , Nadunumu"
        },
        {
            "value": "NDP",
            "icao_code": null,
            "label": "Naval Air Station Ellyson Field , Pensacola"
        },
        {
            "value": "NDR",
            "icao_code": "GMMW",
            "label": "Nador International Airport , Nador"
        },
        {
            "value": "NDS",
            "icao_code": "YSAN",
            "label": "Sandstone Airport , Sandstone, Western Australia"
        },
        {
            "value": "NDU",
            "icao_code": "FYRU",
            "label": "Rundu Airport , Rundu"
        },
        {
            "value": "NDY",
            "icao_code": "EGES",
            "label": "Sanday Airport , Sanday"
        },
        {
            "value": "NEC",
            "icao_code": "SAZO",
            "label": "Necochea Airport , Necochea"
        },
        {
            "value": "NEF",
            "icao_code": "UWUF",
            "label": "Neftekamsk Airport , Neftekamsk"
        },
        {
            "value": "NEG",
            "icao_code": "MKNG",
            "label": "Negril Aerodrome , Negril"
        },
        {
            "value": "NEJ",
            "icao_code": "HANJ",
            "label": "Nejjo Airport , Nejo"
        },
        {
            "value": "NEK",
            "icao_code": "HANK",
            "label": "Nekemte Airport , Nekemte"
        },
        {
            "value": "NEL",
            "icao_code": "KNEL",
            "label": "NAES Lakehurst , Lakehurst"
        },
        {
            "value": "NEN",
            "icao_code": "KNEN",
            "label": "NOLF Whitehouse , Jacksonville"
        },
        {
            "value": "NER",
            "icao_code": "UELL",
            "label": "Chulman Neryungri Airport , Neryungri"
        },
        {
            "value": "NEU",
            "icao_code": "VLSN",
            "label": "Nathong Airport , Xam Neua"
        },
        {
            "value": "NEV",
            "icao_code": "TKPN",
            "label": "Vance W. Amory International Airport , Nevis"
        },
        {
            "value": "NEW",
            "icao_code": "KNEW",
            "label": "Lakefront Airport , New Orleans"
        },
        {
            "value": "NFG",
            "icao_code": "USRN",
            "label": "Nefteyugansk Airport , Nefteyugansk"
        },
        {
            "value": "NFL",
            "icao_code": "KNFL",
            "label": "NAS Fallon , Fallon"
        },
        {
            "value": "NFO",
            "icao_code": "NFTO",
            "label": "Niuafo'ou Airport , Niuafo'ou"
        },
        {
            "value": "NFR",
            "icao_code": "HLNR",
            "label": "Nafoora Airport , Nafoora"
        },
        {
            "value": "NGA",
            "icao_code": "YYNG",
            "label": "Young Airport , Young, New South Wales"
        },
        {
            "value": "NGB",
            "icao_code": "ZSNB",
            "label": "Ningbo Lishe International Airport , Ningbo"
        },
        {
            "value": "NGD",
            "icao_code": "TUPA",
            "label": "Auguste George Airport , Anegada"
        },
        {
            "value": "NGE",
            "icao_code": "FKKN",
            "label": "Ngaound\u00e9r\u00e9 Airport , Ngaound\u00e9r\u00e9"
        },
        {
            "value": "NGF",
            "icao_code": "PHNG",
            "label": "MCAS Kaneohe Bay , Kaneohe"
        },
        {
            "value": "NGI",
            "icao_code": "NFNG",
            "label": "Gau Airport , Gau Island"
        },
        {
            "value": "NGK",
            "icao_code": "UHSN",
            "label": "Nogliki Airport , Nogliki"
        },
        {
            "value": "NGL",
            "icao_code": "FANG",
            "label": "Ngala Airfield , Ngala"
        },
        {
            "value": "NGN",
            "icao_code": null,
            "label": "Nargan\u00e1 Airport , Nargan\u00e1"
        },
        {
            "value": "NGO",
            "icao_code": "RJGG",
            "label": "Ch\u016bbu Centrair International Airport , Nagoya"
        },
        {
            "value": "NGP",
            "icao_code": "KNGP",
            "label": "NAS Corpus Christi , Corpus Christi"
        },
        {
            "value": "NGQ",
            "icao_code": "ZUAL",
            "label": "Ngari Gunsa Airport , Shiquanhe"
        },
        {
            "value": "NGR",
            "icao_code": null,
            "label": "Ningerum Airport , Ningerum"
        },
        {
            "value": "NGS",
            "icao_code": "RJFU",
            "label": "Nagasaki Airport , Nagasaki"
        },
        {
            "value": "NGU",
            "icao_code": "KNGU",
            "label": "NS Norfolk , Norfolk"
        },
        {
            "value": "NGW",
            "icao_code": "KNGW",
            "label": "NOLF Cabaniss Field , Corpus Christi"
        },
        {
            "value": "NGX",
            "icao_code": "VNMA",
            "label": "Manang Airport , Manang"
        },
        {
            "value": "NHA",
            "icao_code": "VVNT",
            "label": "Nha Trang Air Base , Nha Trang"
        },
        {
            "value": "NHD",
            "icao_code": "OMDM",
            "label": "Al Minhad Air Base , Dubai"
        },
        {
            "value": "NHF",
            "icao_code": "HSNW",
            "label": "New Halfa Airport , New Halfa"
        },
        {
            "value": "NHK",
            "icao_code": "KNHK",
            "label": "NAS Patuxent River , Patuxent River"
        },
        {
            "value": "NHS",
            "icao_code": null,
            "label": "Nushki Airport , Nushki"
        },
        {
            "value": "NHT",
            "icao_code": "EGWU",
            "label": "RAF Northolt , Northolt"
        },
        {
            "value": "NHV",
            "icao_code": "NTMD",
            "label": "Nuku Hiva Airport , Nuku Hiva"
        },
        {
            "value": "NHX",
            "icao_code": "KNBJ",
            "label": "NOLF Barin , Foley"
        },
        {
            "value": "NHZ",
            "icao_code": "KBXM",
            "label": "Brunswick Executive Airport , Brunswick"
        },
        {
            "value": "NIA",
            "icao_code": "GLNA",
            "label": "Nimba Airport , Nimba"
        },
        {
            "value": "NIB",
            "icao_code": "PAFS",
            "label": "Nikolai Airport , Nikolai"
        },
        {
            "value": "NIC",
            "icao_code": "LCNC",
            "label": "Nicosia International Airport , Nicosia"
        },
        {
            "value": "NIF",
            "icao_code": "YCNF",
            "label": "Nifty Airport , Nifty Copper Mine"
        },
        {
            "value": "NIG",
            "icao_code": "NGNU",
            "label": "Nikunau Airport , Nikunau"
        },
        {
            "value": "NIK",
            "icao_code": null,
            "label": "Niokolo-Koba Airport , Niokolo-Koba"
        },
        {
            "value": "NIM",
            "icao_code": "DRRN",
            "label": "Diori Hamani International Airport , Niamey"
        },
        {
            "value": "NIN",
            "icao_code": null,
            "label": "Ninilchik Airport , Ninilchik"
        },
        {
            "value": "NIO",
            "icao_code": "FZBI",
            "label": "Nioki Airport , Nioki"
        },
        {
            "value": "NIP",
            "icao_code": "KNIP",
            "label": "NAS Jacksonville , Jacksonville"
        },
        {
            "value": "NIR",
            "icao_code": null,
            "label": "Chase Field Industrial Complex , Beeville"
        },
        {
            "value": "NIS",
            "icao_code": null,
            "label": "Simberi Airport , Simberi Island"
        },
        {
            "value": "NIT",
            "icao_code": "LFBN",
            "label": "Niort - Souch\u00e9 Airport , Niort"
        },
        {
            "value": "NIU",
            "icao_code": "NTKN",
            "label": "Niau Airport , Niau"
        },
        {
            "value": "NIX",
            "icao_code": "GANR",
            "label": "Nioro Airport , Nioro du Sahel"
        },
        {
            "value": "NJA",
            "icao_code": "RJTA",
            "label": "Naval Air Facility Atsugi , Atsugi"
        },
        {
            "value": "NJC",
            "icao_code": "USNN",
            "label": "Nizhnevartovsk Airport , Nizhnevartovsk"
        },
        {
            "value": "NJF",
            "icao_code": "ORNI",
            "label": "Al Najaf International Airport , Al Najaf"
        },
        {
            "value": "NJK",
            "icao_code": "KNJK",
            "label": "NAF El Centro , El Centro"
        },
        {
            "value": "NKA",
            "icao_code": null,
            "label": "Nkan Airport , Ntoum"
        },
        {
            "value": "NKB",
            "icao_code": null,
            "label": "Noonkanbah Airport , Noonkanbah Station"
        },
        {
            "value": "NKC",
            "icao_code": "GQNO",
            "label": "Nouakchott\u2013Oumtounsy International Airport , Nouakchott"
        },
        {
            "value": "NKD",
            "icao_code": null,
            "label": "Sinak Airport , Sinak"
        },
        {
            "value": "NKG",
            "icao_code": "ZSNJ",
            "label": "Nanjing Lukou International Airport , Nanjing"
        },
        {
            "value": "NKI",
            "icao_code": null,
            "label": "Naukati Bay Seaplane Base , Naukati Bay"
        },
        {
            "value": "NKL",
            "icao_code": "FZAR",
            "label": "Nkolo-Fuma Airport , Kolo Fuma"
        },
        {
            "value": "NKM",
            "icao_code": "RJNA",
            "label": "Nagoya Airfield , Nagoya"
        },
        {
            "value": "NKN",
            "icao_code": null,
            "label": "Nankina Airport , Nankina"
        },
        {
            "value": "NKO",
            "icao_code": null,
            "label": "Ankokoambo Airport , Ankokoambo"
        },
        {
            "value": "NKP",
            "icao_code": null,
            "label": "Nukutepipi Airport , Nukutepipi"
        },
        {
            "value": "NKS",
            "icao_code": "FKAN",
            "label": "Nkongsamba Airport , Nkongsamba"
        },
        {
            "value": "NKT",
            "icao_code": "LTCV",
            "label": "\u015e\u0131rnak Airport , \u015e\u0131rnak"
        },
        {
            "value": "NKU",
            "icao_code": "FXNK",
            "label": "Nkaus Airport , Nkau"
        },
        {
            "value": "NKX",
            "icao_code": "KNKX",
            "label": "MCAS Miramar , San Diego"
        },
        {
            "value": "NKY",
            "icao_code": "FCBY",
            "label": "Yokangassi Airport , Nkayi"
        },
        {
            "value": "NLA",
            "icao_code": "FLND",
            "label": "Simon Mwansa Kapwepwe International Airport , Ndola"
        },
        {
            "value": "NLC",
            "icao_code": "KNLC",
            "label": "NAS Lemoore , Lemoore"
        },
        {
            "value": "NLD",
            "icao_code": "MMNL",
            "label": "Quetzalc\u00f3atl International Airport , Nuevo Laredo"
        },
        {
            "value": "NLE",
            "icao_code": null,
            "label": "Jerry Tyler Memorial Airport , Niles"
        },
        {
            "value": "NLF",
            "icao_code": "YDNI",
            "label": "Darnley Island Airport , Darnley Island"
        },
        {
            "value": "NLG",
            "icao_code": "PAOU",
            "label": "Nelson Lagoon Airport , Nelson Lagoon"
        },
        {
            "value": "NLH",
            "icao_code": null,
            "label": "Ninglang Luguhu Airport , Ninglang"
        },
        {
            "value": "NLI",
            "icao_code": "UHNN",
            "label": "Nikolayevsk-on-Amur Airport , Nikolayevsk-on-Amur"
        },
        {
            "value": "NLK",
            "icao_code": "YSNF",
            "label": "Norfolk Island Airport , Norfolk Island"
        },
        {
            "value": "NLL",
            "icao_code": "YNUL",
            "label": "Nullagine Airport , Nullagine"
        },
        {
            "value": "NLN",
            "icao_code": null,
            "label": "Kneeland Airport , Arcata"
        },
        {
            "value": "NLO",
            "icao_code": "FZAB",
            "label": "N'Dolo Airport , Kinshasa"
        },
        {
            "value": "NLP",
            "icao_code": "FANS",
            "label": "Nelspruit Airport , Mbombela"
        },
        {
            "value": "NLS",
            "icao_code": null,
            "label": "Nicholson Airport , Nicholson, Western Australia"
        },
        {
            "value": "NLT",
            "icao_code": "ZWNL",
            "label": "Xinyuan Nalati Airport , Xinyuan"
        },
        {
            "value": "NLU",
            "icao_code": "MMSM",
            "label": "Santa Luc\u00eda Air Force Base Num 1 , Zumpango"
        },
        {
            "value": "NLV",
            "icao_code": "UKON",
            "label": "Mykolaiv International Airport , Mykolaiv"
        },
        {
            "value": "NMA",
            "icao_code": "UTFN",
            "label": "Namangan Airport , Namangan"
        },
        {
            "value": "NMB",
            "icao_code": "VADN",
            "label": "Daman Airport , Daman"
        },
        {
            "value": "NMC",
            "icao_code": "MYEN",
            "label": "Norman's Cay Airport , Norman's Cay"
        },
        {
            "value": "NME",
            "icao_code": "PAGT",
            "label": "Nightmute Airport , Nightmute"
        },
        {
            "value": "NMF",
            "icao_code": "VRDA",
            "label": "Maafaru International Airport , Maafaru"
        },
        {
            "value": "NMG",
            "icao_code": null,
            "label": "San Miguel Airport , San Miguel"
        },
        {
            "value": "NML",
            "icao_code": null,
            "label": "Fort McMurray/Mildred Lake Airport , Fort McMurray"
        },
        {
            "value": "NMN",
            "icao_code": null,
            "label": "Nomane Airport , Nomane"
        },
        {
            "value": "NMP",
            "icao_code": null,
            "label": "New Moon Airport , New Moon, Queensland"
        },
        {
            "value": "NMR",
            "icao_code": "YNAP",
            "label": "Nappa Merrie Airport , Nappa Merrie"
        },
        {
            "value": "NMS",
            "icao_code": "VYNS",
            "label": "Nansang Airport , Nansang"
        },
        {
            "value": "NMT",
            "icao_code": "VYNT",
            "label": "Namtu Airport , Namtu"
        },
        {
            "value": "NNA",
            "icao_code": "GMMY",
            "label": "Kenitra Air Base , Kenitra"
        },
        {
            "value": "NNB",
            "icao_code": "AGGT",
            "label": "Santa Ana Airport , Santa Ana Island"
        },
        {
            "value": "NND",
            "icao_code": null,
            "label": "Nangade Airport , Nangade"
        },
        {
            "value": "NNG",
            "icao_code": "ZGNN",
            "label": "Nanning Wuxu International Airport , Nanning"
        },
        {
            "value": "NNI",
            "icao_code": "FYNA",
            "label": "Namutoni Airport , Namutoni"
        },
        {
            "value": "NNK",
            "icao_code": null,
            "label": "Naknek Airport , Naknek"
        },
        {
            "value": "NNL",
            "icao_code": "PANO",
            "label": "Nondalton Airport , Nondalton"
        },
        {
            "value": "NNM",
            "icao_code": "ULAM",
            "label": "Naryan-Mar Airport , Naryan-Mar"
        },
        {
            "value": "NNR",
            "icao_code": "EICA",
            "label": "Connemara Airport , Spiddal"
        },
        {
            "value": "NNT",
            "icao_code": "VTCN",
            "label": "Nan Nakhon Airport , Nan"
        },
        {
            "value": "NNU",
            "icao_code": "SNNU",
            "label": "Nanuque Airport , Nanuque"
        },
        {
            "value": "NNX",
            "icao_code": "WRLF",
            "label": "Nunukan Airport , Nunukan"
        },
        {
            "value": "NNY",
            "icao_code": "ZHNY",
            "label": "Nanyang Jiangying Airport , Nanyang"
        },
        {
            "value": "NOA",
            "icao_code": "YSNW",
            "label": "NAS Nowra , Nowra"
        },
        {
            "value": "NOB",
            "icao_code": "MRNS",
            "label": "Nosara Airport , Nosara"
        },
        {
            "value": "NOC",
            "icao_code": "EIKN",
            "label": "Ireland West Airport Knock , Knock"
        },
        {
            "value": "NOD",
            "icao_code": "EDWS",
            "label": "Norddeich Airport , Norden"
        },
        {
            "value": "NOG",
            "icao_code": "MMNG",
            "label": "Nogales International Airport , Nogales"
        },
        {
            "value": "NOI",
            "icao_code": null,
            "label": "Novorossiysk Airport , Novorossiysk"
        },
        {
            "value": "NOJ",
            "icao_code": "USRO",
            "label": "Noyabrsk Airport , Noyabrsk"
        },
        {
            "value": "NOK",
            "icao_code": "SWXV",
            "label": "Nova Xavantina Airport , Nova Xavantina"
        },
        {
            "value": "NOM",
            "icao_code": "ATNR",
            "label": "Nomad River Airport , Nomad River"
        },
        {
            "value": "NON",
            "icao_code": "NGTO",
            "label": "Nonouti Airport , Nonouti"
        },
        {
            "value": "NOO",
            "icao_code": null,
            "label": "Naoro Airport , Naoro"
        },
        {
            "value": "NOP",
            "icao_code": "LTCM",
            "label": "Sinop Airport , Sinop"
        },
        {
            "value": "NOR",
            "icao_code": "BINF",
            "label": "Nor\u00f0fj\u00f6r\u00f0ur Airport , Nordfjordur"
        },
        {
            "value": "NOS",
            "icao_code": "FMNN",
            "label": "Fascene Airport , Nosy Be"
        },
        {
            "value": "NOT",
            "icao_code": "KDVO",
            "label": "Marin County Airport , Novato"
        },
        {
            "value": "NOU",
            "icao_code": "NWWW",
            "label": "La Tontouta International Airport , Noum\u00e9a"
        },
        {
            "value": "NOV",
            "icao_code": "FNHU",
            "label": "Albano Machado Airport , Huambo"
        },
        {
            "value": "NOZ",
            "icao_code": "UNWW",
            "label": "Spichenkovo Airport , Novokuznetsk"
        },
        {
            "value": "NPA",
            "icao_code": "KNPA",
            "label": "NAS Pensacola , Pensacola"
        },
        {
            "value": "NPE",
            "icao_code": "NZNR",
            "label": "Hawke's Bay Airport , Napier"
        },
        {
            "value": "NPG",
            "icao_code": null,
            "label": "Nipa Airport , Nipa"
        },
        {
            "value": "NPH",
            "icao_code": null,
            "label": "Nephi Municipal Airport , Nephi"
        },
        {
            "value": "NPL",
            "icao_code": "NZNP",
            "label": "New Plymouth Airport , New Plymouth"
        },
        {
            "value": "NPO",
            "icao_code": "WIOG",
            "label": "Nanga Pinoh Airport , Nanga Pinoh"
        },
        {
            "value": "NPP",
            "icao_code": null,
            "label": "Napperby Airport , Napperby Station"
        },
        {
            "value": "NPR",
            "icao_code": "SJNP",
            "label": "Novo Progresso Airport , Novo Progresso"
        },
        {
            "value": "NPT",
            "icao_code": "KUUU",
            "label": "Newport State Airport , Newport"
        },
        {
            "value": "NPU",
            "icao_code": null,
            "label": "San Pedro de Urab\u00e1 Airport , San Pedro de Urab\u00e1"
        },
        {
            "value": "NPY",
            "icao_code": "HTMP",
            "label": "Mpanda Airport , Mpanda"
        },
        {
            "value": "NQA",
            "icao_code": "KNQA",
            "label": "Millington Regional Jetport , Millington"
        },
        {
            "value": "NQI",
            "icao_code": "KNQI",
            "label": "NAS Kingsville , Kingsville"
        },
        {
            "value": "NQL",
            "icao_code": "SWNQ",
            "label": "Niquel\u00e2ndia Airport , Niquel\u00e2ndia"
        },
        {
            "value": "NQN",
            "icao_code": "SAZN",
            "label": "Presidente Per\u00f3n International Airport , Neuqu\u00e9n"
        },
        {
            "value": "NQT",
            "icao_code": "EGBN",
            "label": "Nottingham Airport , Nottingham"
        },
        {
            "value": "NQU",
            "icao_code": "SKNQ",
            "label": "Reyes Murillo Airport , Nuqu\u00ed"
        },
        {
            "value": "NQX",
            "icao_code": "KNQX",
            "label": "NAS Key West , Key West"
        },
        {
            "value": "NQY",
            "icao_code": "EGHQ",
            "label": "Newquay Airport , Newquay"
        },
        {
            "value": "NQZ",
            "icao_code": "UACC",
            "label": "Nursultan Nazarbayev International Airport , Nur-Sultan"
        },
        {
            "value": "NRA",
            "icao_code": "YNAR",
            "label": "Narrandera Airport , Narrandera"
        },
        {
            "value": "NRB",
            "icao_code": "KNRB",
            "label": "NS Mayport , Mayport"
        },
        {
            "value": "NRC",
            "icao_code": "KNRC",
            "label": "NASA Crows Landing Airport , Crows Landing"
        },
        {
            "value": "NRD",
            "icao_code": "EDWY",
            "label": "Norderney Airfield , Norderney"
        },
        {
            "value": "NRE",
            "icao_code": "WAPG",
            "label": "Namrole Airport , Namrole"
        },
        {
            "value": "NRG",
            "icao_code": "YNRG",
            "label": "Narrogin Airport , Narrogin"
        },
        {
            "value": "NRI",
            "icao_code": null,
            "label": "Grand Lake Regional Airport , Afton"
        },
        {
            "value": "NRK",
            "icao_code": "ESSP",
            "label": "Norrk\u00f6ping Airport , Norrk\u00f6ping"
        },
        {
            "value": "NRL",
            "icao_code": "EGEN",
            "label": "North Ronaldsay Airport , North Ronaldsay"
        },
        {
            "value": "NRM",
            "icao_code": "GANK",
            "label": "Keibane Airport , Nara"
        },
        {
            "value": "NRN",
            "icao_code": "EDLV",
            "label": "Weeze Airport , Weeze"
        },
        {
            "value": "NRR",
            "icao_code": "TJRV",
            "label": "Jos\u00e9 Aponte de la Torre Airport , Ceiba"
        },
        {
            "value": "NRS",
            "icao_code": "KNRS",
            "label": "NOLF Imperial Beach , Imperial Beach"
        },
        {
            "value": "NRT",
            "icao_code": "RJAA",
            "label": "Narita International Airport , Tokyo"
        },
        {
            "value": "NRY",
            "icao_code": null,
            "label": "Newry Airport , Newry Station"
        },
        {
            "value": "NSB",
            "icao_code": null,
            "label": "North Seaplane Base , Bimini"
        },
        {
            "value": "NSE",
            "icao_code": "KNSE",
            "label": "NAS Whiting Field \u2013 North , Milton"
        },
        {
            "value": "NSH",
            "icao_code": "OINN",
            "label": "Noshahr Airport , Nowshahr"
        },
        {
            "value": "NSI",
            "icao_code": "FKYS",
            "label": "Yaound\u00e9 Nsimalen International Airport , Yaound\u00e9"
        },
        {
            "value": "NSK",
            "icao_code": "UOOO",
            "label": "Alykel Airport , Norilsk"
        },
        {
            "value": "NSL",
            "icao_code": "KDVP",
            "label": "Slayton Municipal Airport , Slayton"
        },
        {
            "value": "NSM",
            "icao_code": "YNSM",
            "label": "Norseman Airport , Norseman, Western Australia"
        },
        {
            "value": "NSN",
            "icao_code": "NZNS",
            "label": "Nelson Airport , Nelson"
        },
        {
            "value": "NSO",
            "icao_code": "YSCO",
            "label": "Scone Airport , Scone, New South Wales"
        },
        {
            "value": "NST",
            "icao_code": "VTSF",
            "label": "Nakhon Si Thammarat Airport , Nakhon Si Thammarat"
        },
        {
            "value": "NSV",
            "icao_code": "YNSH",
            "label": "Noosa Airport , Noosaville"
        },
        {
            "value": "NSY",
            "icao_code": "LICZ",
            "label": "Naval Air Station Sigonella , Catania"
        },
        {
            "value": "NTA",
            "icao_code": null,
            "label": "Natadola Seaplane Base , Natadola Bay"
        },
        {
            "value": "NTB",
            "icao_code": "ENNO",
            "label": "Notodden Airport, Tuven , Notodden"
        },
        {
            "value": "NTC",
            "icao_code": null,
            "label": "Santa Carolina Airport , Santa Carolina"
        },
        {
            "value": "NTD",
            "icao_code": "KNTD",
            "label": "NAS Point Mugu , Point Mugu"
        },
        {
            "value": "NTE",
            "icao_code": "LFRS",
            "label": "Nantes Atlantique Airport , Nantes"
        },
        {
            "value": "NTG",
            "icao_code": "ZSNT",
            "label": "Nantong Xingdong Airport , Nantong"
        },
        {
            "value": "NTI",
            "icao_code": null,
            "label": "Stenkool Airport , Bintuni"
        },
        {
            "value": "NTJ",
            "icao_code": null,
            "label": "Manti-Ephraim Airport , Manti"
        },
        {
            "value": "NTL",
            "icao_code": "YWLM",
            "label": "Newcastle Airport , Newcastle, New South Wales"
        },
        {
            "value": "NTM",
            "icao_code": "SNRW",
            "label": "Miracema do Tocantins Airport , Miracema do Tocantins"
        },
        {
            "value": "NTN",
            "icao_code": "YNTN",
            "label": "Normanton Airport , Normanton, Queensland"
        },
        {
            "value": "NTO",
            "icao_code": "GVAN",
            "label": "Agostinho Neto Airport , Santo Ant\u00e3o"
        },
        {
            "value": "NTQ",
            "icao_code": "RJNW",
            "label": "Noto Airport , Wajima"
        },
        {
            "value": "NTR",
            "icao_code": "MMAN",
            "label": "Del Norte International Airport , Monterrey"
        },
        {
            "value": "NTT",
            "icao_code": "NFTP",
            "label": "Niuatoputapu Airport , Niuatoputapu"
        },
        {
            "value": "NTU",
            "icao_code": "KNTU",
            "label": "NAS Oceana , Virginia Beach"
        },
        {
            "value": "NTX",
            "icao_code": "WION",
            "label": "Ranai Airport , Natuna Islands"
        },
        {
            "value": "NTY",
            "icao_code": "FAPN",
            "label": "Pilanesberg International Airport , Sun City"
        },
        {
            "value": "NUA",
            "icao_code": null,
            "label": "Gregory Lake Seaplane Base , Nuwara Eliya"
        },
        {
            "value": "NUB",
            "icao_code": "YNUM",
            "label": "Numbulwar Airport , Numbulwar"
        },
        {
            "value": "NUD",
            "icao_code": "HSNH",
            "label": "En Nahud Airport , En Nahud"
        },
        {
            "value": "NUE",
            "icao_code": "EDDN",
            "label": "Nuremberg Airport , Nuremberg"
        },
        {
            "value": "NUF",
            "icao_code": null,
            "label": "Castlereigh Reservoir Seaplane Base , Hatton"
        },
        {
            "value": "NUG",
            "icao_code": null,
            "label": "Nuguria Airstrip , Nuguria"
        },
        {
            "value": "NUH",
            "icao_code": null,
            "label": "Nunch\u00eda Airport , Nunch\u00eda"
        },
        {
            "value": "NUI",
            "icao_code": "PAQT",
            "label": "Nuiqsut Airport , Nuiqsut"
        },
        {
            "value": "NUJ",
            "icao_code": "OIHS",
            "label": "Hamedan Air Base , Hamadan"
        },
        {
            "value": "NUK",
            "icao_code": "NTGW",
            "label": "Nukutavake Airport , Nukutavake"
        },
        {
            "value": "NUL",
            "icao_code": "PANU",
            "label": "Nulato Airport , Nulato"
        },
        {
            "value": "NUM",
            "icao_code": "OENN",
            "label": "Neom Bay Airport , Neom"
        },
        {
            "value": "NUN",
            "icao_code": "KNUN",
            "label": "NOLF Saufley Field , Pensacola"
        },
        {
            "value": "NUP",
            "icao_code": null,
            "label": "Nunapitchuk Airport , Nunapitchuk"
        },
        {
            "value": "NUQ",
            "icao_code": "KNUQ",
            "label": "Moffett Federal Airfield , Mountain View"
        },
        {
            "value": "NUR",
            "icao_code": "YNUB",
            "label": "Nullabor Motel Airport , Nullarbor, South Australia"
        },
        {
            "value": "NUS",
            "icao_code": "NVSP",
            "label": "Norsup Airport , Norsup"
        },
        {
            "value": "NUT",
            "icao_code": null,
            "label": "Nutuve Airport , Nutuve"
        },
        {
            "value": "NUU",
            "icao_code": "HKNK",
            "label": "Nakuru Airport , Nakuru"
        },
        {
            "value": "NUW",
            "icao_code": "KNUW",
            "label": "NAS Whidbey Island , Oak Harbor"
        },
        {
            "value": "NUX",
            "icao_code": "USMU",
            "label": "Novy Urengoy Airport , Novy Urengoy"
        },
        {
            "value": "NVA",
            "icao_code": "SKNV",
            "label": "Benito Salas Airport , Neiva"
        },
        {
            "value": "NVD",
            "icao_code": "KNVD",
            "label": "Nevada Municipal Airport , Nevada"
        },
        {
            "value": "NVG",
            "icao_code": "MNNG",
            "label": "Nueva Guinea Airport , Nueva Guinea"
        },
        {
            "value": "NVI",
            "icao_code": "UTSA",
            "label": "Navoi International Airport , Navoiy"
        },
        {
            "value": "NVK",
            "icao_code": "ENNK",
            "label": "Narvik Airport, Framnes , Narvik"
        },
        {
            "value": "NVN",
            "icao_code": null,
            "label": "Nervino Airport , Beckwourth"
        },
        {
            "value": "NVP",
            "icao_code": null,
            "label": "Novo Aripuan\u00e3 Airport , Novo Aripuan\u00e3"
        },
        {
            "value": "NVR",
            "icao_code": "ULNN",
            "label": "Novgorod Airport , Veliky Novgorod"
        },
        {
            "value": "NVS",
            "icao_code": "LFQG",
            "label": "Nevers - Fourchambault Airport , Nevers"
        },
        {
            "value": "NVT",
            "icao_code": "SBNF",
            "label": "Navegantes\u2013Ministro Victor Konder International Airport , Navegantes"
        },
        {
            "value": "NVY",
            "icao_code": "VONV",
            "label": "Neyveli Airport , Neyveli"
        },
        {
            "value": "NWA",
            "icao_code": "FMCI",
            "label": "Moh\u00e9li Bandar Es Eslam Airport , Moh\u00e9li"
        },
        {
            "value": "NWH",
            "icao_code": null,
            "label": "Parlin Field , Newport"
        },
        {
            "value": "NWI",
            "icao_code": "EGSH",
            "label": "Norwich International Airport , Norwich"
        },
        {
            "value": "NWT",
            "icao_code": null,
            "label": "Nowata Airport , Nowata"
        },
        {
            "value": "NYA",
            "icao_code": "USHN",
            "label": "Nyagan Airport , Nyagan"
        },
        {
            "value": "NYC",
            "icao_code": null,
            "label": "metropolitan area , New York City"
        },
        {
            "value": "NYE",
            "icao_code": "HKNI",
            "label": "Nyeri Airport , Nyeri"
        },
        {
            "value": "NYG",
            "icao_code": "KNYG",
            "label": "MCAF Quantico , Quantico"
        },
        {
            "value": "NYI",
            "icao_code": "DGSN",
            "label": "Sunyani Airport , Sunyani"
        },
        {
            "value": "NYK",
            "icao_code": "HKNY",
            "label": "Nanyuki Airport , Nanyuki"
        },
        {
            "value": "NYM",
            "icao_code": "USMM",
            "label": "Nadym Airport , Nadym"
        },
        {
            "value": "NYN",
            "icao_code": "YNYN",
            "label": "Nyngan Airport , Nyngan"
        },
        {
            "value": "NYO",
            "icao_code": "ESKN",
            "label": "Stockholm Skavsta Airport , Stockholm"
        },
        {
            "value": "NYR",
            "icao_code": "UENN",
            "label": "Nyurba Airport , Nyurba"
        },
        {
            "value": "NYS",
            "icao_code": null,
            "label": "New York Skyports Seaplane Base , New York City"
        },
        {
            "value": "NYT",
            "icao_code": "VYNT",
            "label": "Naypyidaw Airport , Naypyidaw"
        },
        {
            "value": "NYU",
            "icao_code": "VYBG",
            "label": "Nyaung U Airport , Bagan"
        },
        {
            "value": "NYW",
            "icao_code": "VYMY",
            "label": "Monywa Airport , Monywa"
        },
        {
            "value": "NZA",
            "icao_code": "FNZG",
            "label": "Nzagi Airport , Nzagi"
        },
        {
            "value": "NZC",
            "icao_code": "SPZA",
            "label": "Maria Reiche Neuman Airport , Nazca"
        },
        {
            "value": "NZE",
            "icao_code": "GUNZ",
            "label": "Nz\u00e9r\u00e9kor\u00e9 Airport , Nz\u00e9r\u00e9kor\u00e9"
        },
        {
            "value": "NZH",
            "icao_code": "ZBMZ",
            "label": "Manzhouli Xijiao Airport , Manzhouli"
        },
        {
            "value": "NZL",
            "icao_code": null,
            "label": "Zhalantun Chengjisihan Airport , Zhalantun"
        },
        {
            "value": "NZY",
            "icao_code": "KNZY",
            "label": "NAS North Island , San Diego"
        },
        {
            "value": "OAA",
            "icao_code": "OASH",
            "label": "Forward Operating Base Shank , Gardez"
        },
        {
            "value": "OAG",
            "icao_code": "YORG",
            "label": "Orange Airport , Orange"
        },
        {
            "value": "OAH",
            "icao_code": "OASD",
            "label": "Shindand Air Base , Shindand"
        },
        {
            "value": "OAI",
            "icao_code": "OAIX",
            "label": "Bagram Airfield , Bagram"
        },
        {
            "value": "OAJ",
            "icao_code": "KOAJ",
            "label": "Albert J. Ellis Airport , Jacksonville"
        },
        {
            "value": "OAK",
            "icao_code": "KOAK",
            "label": "Oakland International Airport , Oakland"
        },
        {
            "value": "OAL",
            "icao_code": "SSKW",
            "label": "Capital do Caf\u00e9 Airport , Cacoal"
        },
        {
            "value": "OAM",
            "icao_code": "NZOU",
            "label": "Oamaru Airport , Oamaru"
        },
        {
            "value": "OAN",
            "icao_code": "MHEA",
            "label": "El Array\u00e1n Airport , Olanchito"
        },
        {
            "value": "OAR",
            "icao_code": "KOAR",
            "label": "Marina Municipal Airport , Monterey"
        },
        {
            "value": "OAS",
            "icao_code": "OASA",
            "label": "Sharana Airstrip , Sharana"
        },
        {
            "value": "OAX",
            "icao_code": "MMOX",
            "label": "Xoxocotl\u00e1n International Airport , Oaxaca de Ju\u00e1rez"
        },
        {
            "value": "OAZ",
            "icao_code": "OAZI",
            "label": "Camp Bastion Air Base , Girishk"
        },
        {
            "value": "OBA",
            "icao_code": null,
            "label": "Oban Airport , Oban"
        },
        {
            "value": "OBC",
            "icao_code": "HDOB",
            "label": "Obock Airport , Obock"
        },
        {
            "value": "OBD",
            "icao_code": null,
            "label": "Obano Airport , Obano"
        },
        {
            "value": "OBE",
            "icao_code": "KOBE",
            "label": "Okeechobee County Airport , Okeechobee"
        },
        {
            "value": "OBF",
            "icao_code": "EDMO",
            "label": "Oberpfaffenhofen Airport , Oberpfaffenhofen"
        },
        {
            "value": "OBI",
            "icao_code": "SBTI",
            "label": "\u00d3bidos Airport , \u00d3bidos"
        },
        {
            "value": "OBL",
            "icao_code": "EBZR",
            "label": "Oostmalle Airfield , Zoersel"
        },
        {
            "value": "OBM",
            "icao_code": null,
            "label": "Morobe Airport , Morobe"
        },
        {
            "value": "OBN",
            "icao_code": "EGEO",
            "label": "Oban Airport , Oban"
        },
        {
            "value": "OBO",
            "icao_code": "RJCB",
            "label": "Tokachi\u2013Obihiro Airport , Obihiro"
        },
        {
            "value": "OBS",
            "icao_code": "LFHO",
            "label": "Aubenas Aerodrome , Aubenas"
        },
        {
            "value": "OBU",
            "icao_code": "PAOB",
            "label": "Kobuk Airport , Kobuk"
        },
        {
            "value": "OBX",
            "icao_code": "AYOB",
            "label": "Obo Airport , Obo"
        },
        {
            "value": "OCA",
            "icao_code": null,
            "label": "Ocean Reef Club Airport , Key Largo"
        },
        {
            "value": "OCC",
            "icao_code": "SECO",
            "label": "Francisco de Orellana Airport , Puerto Francisco de Orellana"
        },
        {
            "value": "OCE",
            "icao_code": "KOXB",
            "label": "Ocean City Municipal Airport , Ocean City"
        },
        {
            "value": "OCF",
            "icao_code": "KOCF",
            "label": "Ocala International Airport , Ocala"
        },
        {
            "value": "OCH",
            "icao_code": "KOCH",
            "label": "A.L. Mangham Jr. Regional Airport , Nacogdoches"
        },
        {
            "value": "OCJ",
            "icao_code": "MKBS",
            "label": "Ian Fleming International Airport , Ocho Rios"
        },
        {
            "value": "OCM",
            "icao_code": "YBGD",
            "label": "Boolgeeda Airport , Boolgeeda"
        },
        {
            "value": "OCN",
            "icao_code": "KOKB",
            "label": "Oceanside Municipal Airport , Oceanside"
        },
        {
            "value": "OCS",
            "icao_code": null,
            "label": "Corisco International Airport , Corisco Island"
        },
        {
            "value": "OCV",
            "icao_code": "SKOC",
            "label": "Aguas Claras Airport , Oca\u00f1a"
        },
        {
            "value": "OCW",
            "icao_code": "KOCW",
            "label": "Washington\u2013Warren Airport , Washington"
        },
        {
            "value": "ODA",
            "icao_code": "FEFW",
            "label": "Ouadda Airport , Ouadda"
        },
        {
            "value": "ODB",
            "icao_code": "LEBA",
            "label": "C\u00f3rdoba Airport , C\u00f3rdoba"
        },
        {
            "value": "ODC",
            "icao_code": null,
            "label": "Oakdale Airport , Oakdale"
        },
        {
            "value": "ODD",
            "icao_code": "YOOD",
            "label": "Oodnadatta Airport , Oodnadatta"
        },
        {
            "value": "ODE",
            "icao_code": "EKOD",
            "label": "Hans Christian Andersen Airport , Odense"
        },
        {
            "value": "ODH",
            "icao_code": "EGVO",
            "label": "RAF Odiham , Odiham"
        },
        {
            "value": "ODJ",
            "icao_code": "FEGO",
            "label": "Ouanda Djall\u00e9 Airport , Ouanda Djall\u00e9"
        },
        {
            "value": "ODL",
            "icao_code": null,
            "label": "Cordillo Downs Airport , Cordillo Downs"
        },
        {
            "value": "ODM",
            "icao_code": null,
            "label": "Garrett County Airport , Oakland"
        },
        {
            "value": "ODN",
            "icao_code": "WBGI",
            "label": "Long Seridan Airport , Long Seridan"
        },
        {
            "value": "ODO",
            "icao_code": "UIKB",
            "label": "Bodaybo Airport , Bodaybo"
        },
        {
            "value": "ODR",
            "icao_code": "YORV",
            "label": "Ord River Airport , Ord River"
        },
        {
            "value": "ODS",
            "icao_code": "UKOO",
            "label": "Odesa International Airport , Odesa"
        },
        {
            "value": "ODW",
            "icao_code": "KOKH",
            "label": "A.J. Eisenberg Airport , Oak Harbor"
        },
        {
            "value": "ODY",
            "icao_code": "VLOS",
            "label": "Oudomsay Airport , Muang Xay"
        },
        {
            "value": "OEC",
            "icao_code": "WPOC",
            "label": "Oecusse Airport , Oecussi"
        },
        {
            "value": "OEL",
            "icao_code": "UUOR",
            "label": "Oryol Yuzhny Airport , Oryol"
        },
        {
            "value": "OEM",
            "icao_code": "SMPA",
            "label": "Vincent Fayks Airport , Paloemeu"
        },
        {
            "value": "OEO",
            "icao_code": "KOEO",
            "label": "L.O. Simenstad Municipal Airport , Osceola"
        },
        {
            "value": "OER",
            "icao_code": "ESNO",
            "label": "\u00d6rnsk\u00f6ldsvik Airport , \u00d6rnsk\u00f6ldsvik"
        },
        {
            "value": "OES",
            "icao_code": "SAVN",
            "label": "Antoine de Saint Exup\u00e9ry Airport , San Antonio Oeste"
        },
        {
            "value": "OFF",
            "icao_code": "KOFF",
            "label": "Offutt Air Force Base , Omaha"
        },
        {
            "value": "OFI",
            "icao_code": "DIOF",
            "label": "Ouango Fitini Airport , Ouango Fitini"
        },
        {
            "value": "OFJ",
            "icao_code": "BIOF",
            "label": "\u00d3lafsfj\u00f6r\u00f0ur Airport , \u00d3lafsfj\u00f6r\u00f0ur"
        },
        {
            "value": "OFK",
            "icao_code": "KOFK",
            "label": "Norfolk Regional Airport , Norfolk"
        },
        {
            "value": "OFU",
            "icao_code": "NSAS",
            "label": "Ofu Airport , Ofu Island"
        },
        {
            "value": "OGA",
            "icao_code": "KOGA",
            "label": "Searle Field , Ogallala"
        },
        {
            "value": "OGB",
            "icao_code": "KOGB",
            "label": "Orangeburg Municipal Airport , Orangeburg"
        },
        {
            "value": "OGD",
            "icao_code": "KOGD",
            "label": "Ogden-Hinckley Airport , Ogden"
        },
        {
            "value": "OGE",
            "icao_code": null,
            "label": "Ogeramnang Airport , Ogeramnang"
        },
        {
            "value": "OGG",
            "icao_code": "PHOG",
            "label": "Kahului Airport , Kahului"
        },
        {
            "value": "OGL",
            "icao_code": "SYGO",
            "label": "Ogle Airport , Georgetown"
        },
        {
            "value": "OGM",
            "icao_code": null,
            "label": "Ustupu-Ogobsucum Airport , Ustupo"
        },
        {
            "value": "OGN",
            "icao_code": "ROYN",
            "label": "Yonaguni Airport , Yonaguni"
        },
        {
            "value": "OGO",
            "icao_code": "DIAU",
            "label": "Abengourou Airport , Abengourou"
        },
        {
            "value": "OGR",
            "icao_code": "FTTB",
            "label": "Bongor Airport , Bongor"
        },
        {
            "value": "OGS",
            "icao_code": "KOGS",
            "label": "Ogdensburg International Airport , Ogdensburg"
        },
        {
            "value": "OGU",
            "icao_code": "LTCB",
            "label": "Ordu\u2013Giresun Airport , Ordu"
        },
        {
            "value": "OGV",
            "icao_code": "FYNG",
            "label": "Ongava Airstrip , Ongava"
        },
        {
            "value": "OGX",
            "icao_code": "DAUU",
            "label": "Ain Beida Airport , Ouargla"
        },
        {
            "value": "OGZ",
            "icao_code": "URMO",
            "label": "Beslan Airport , Vladikavkaz"
        },
        {
            "value": "OHA",
            "icao_code": "NZOH",
            "label": "RNZAF Base Ohakea , Bulls"
        },
        {
            "value": "OHB",
            "icao_code": null,
            "label": "Ambohibary Airport , Moramanga"
        },
        {
            "value": "OHD",
            "icao_code": "LWOH",
            "label": "Ohrid \"St. Paul the Apostle\" Airport , Ohrid"
        },
        {
            "value": "OHE",
            "icao_code": "ZYMH",
            "label": "Mohe Gulian Airport , Mohe"
        },
        {
            "value": "OHH",
            "icao_code": "UHSH",
            "label": "Okha Airport , Okha"
        },
        {
            "value": "OHI",
            "icao_code": "FYOS",
            "label": "Oshakati Airport , Oshakati"
        },
        {
            "value": "OHO",
            "icao_code": "UHOO",
            "label": "Okhotsk Airport , Okhotsk"
        },
        {
            "value": "OHR",
            "icao_code": "EDXY",
            "label": "Wyk auf F\u00f6hr Airport , Wyk auf F\u00f6hr"
        },
        {
            "value": "OHS",
            "icao_code": "OOSH",
            "label": "Sohar Airport , Sohar"
        },
        {
            "value": "OHT",
            "icao_code": "OPKT",
            "label": "PAF Base Kohat , Kohat"
        },
        {
            "value": "OIA",
            "icao_code": "SDOW",
            "label": "Ouril\u00e2ndia do Norte Airport , Ouril\u00e2ndia do Norte"
        },
        {
            "value": "OIC",
            "icao_code": "KOIC",
            "label": "Lt. Warren Eaton Airport , Norwich"
        },
        {
            "value": "OIM",
            "icao_code": "RJTO",
            "label": "Oshima Airport , \u014cshima"
        },
        {
            "value": "OIR",
            "icao_code": "RJEO",
            "label": "Okushiri Airport , Okushiri"
        },
        {
            "value": "OIT",
            "icao_code": "RJFO",
            "label": "Oita Airport , Oita"
        },
        {
            "value": "OJC",
            "icao_code": "KOJC",
            "label": "Johnson County Executive Airport , Olathe"
        },
        {
            "value": "OJU",
            "icao_code": null,
            "label": "Tanjung Api Airport , Ampana"
        },
        {
            "value": "OKA",
            "icao_code": "ROAH",
            "label": "Naha Airport , Okinawa"
        },
        {
            "value": "OKB",
            "icao_code": "YORC",
            "label": "Orchid Beach Airport , Fraser Island"
        },
        {
            "value": "OKC",
            "icao_code": "KOKC",
            "label": "Will Rogers World Airport , Oklahoma City"
        },
        {
            "value": "OKD",
            "icao_code": "RJCO",
            "label": "Okadama Airport , Sapporo"
        },
        {
            "value": "OKE",
            "icao_code": "RJKB",
            "label": "Okinoerabu Airport , Okinoerabujima"
        },
        {
            "value": "OKF",
            "icao_code": "FYOO",
            "label": "Okaukuejo Airport , Okaukuejo"
        },
        {
            "value": "OKG",
            "icao_code": null,
            "label": "Okoyo Airport , Okoyo"
        },
        {
            "value": "OKH",
            "icao_code": "EGXJ",
            "label": "RAF Cottesmore , Oakham"
        },
        {
            "value": "OKI",
            "icao_code": "RJNO",
            "label": "Oki Airport , Dogo"
        },
        {
            "value": "OKJ",
            "icao_code": "RJOB",
            "label": "Okayama Airport , Okayama"
        },
        {
            "value": "OKK",
            "icao_code": "KOKK",
            "label": "Kokomo Municipal Airport , Kokomo"
        },
        {
            "value": "OKL",
            "icao_code": "WAJO",
            "label": "Gunung Bintang Airport , Oksibil"
        },
        {
            "value": "OKM",
            "icao_code": "KOKM",
            "label": "Okmulgee Regional Airport , Okmulgee"
        },
        {
            "value": "OKN",
            "icao_code": "FOGQ",
            "label": "Okondja Airport , Okondja"
        },
        {
            "value": "OKO",
            "icao_code": "RJTY",
            "label": "Yokota Air Base , Fussa"
        },
        {
            "value": "OKP",
            "icao_code": "AYOJ",
            "label": "Oksapmin Airport , Oksapmin"
        },
        {
            "value": "OKQ",
            "icao_code": "WAKO",
            "label": "Okaba Airport , Okaba"
        },
        {
            "value": "OKR",
            "icao_code": "YYKI",
            "label": "Yorke Island Airport , Yorke Island"
        },
        {
            "value": "OKS",
            "icao_code": "KOKS",
            "label": "Garden County Airport , Oshkosh"
        },
        {
            "value": "OKT",
            "icao_code": "UWUK",
            "label": "Oktyabrsky Airport , Oktyabrsky"
        },
        {
            "value": "OKU",
            "icao_code": "FYMO",
            "label": "Mokuti Lodge Airport , Mokuti Lodge"
        },
        {
            "value": "OKV",
            "icao_code": null,
            "label": "Okao Airport , Okao"
        },
        {
            "value": "OKY",
            "icao_code": "YBOK",
            "label": "Oakey Army Aviation Centre , Oakey"
        },
        {
            "value": "OLA",
            "icao_code": "ENOL",
            "label": "\u00d8rland Airport , \u00d8rland"
        },
        {
            "value": "OLB",
            "icao_code": "LIEO",
            "label": "Olbia Costa Smeralda Airport , Olbia"
        },
        {
            "value": "OLC",
            "icao_code": "SDCG",
            "label": "Senadora Eunice Michiles Airport , S\u00e3o Paulo de Oliven\u00e7a"
        },
        {
            "value": "OLD",
            "icao_code": "KOLD",
            "label": "Old Town Municipal Airport and Seaplane Base , Old Town"
        },
        {
            "value": "OLE",
            "icao_code": "KOLE",
            "label": "Cattaraugus County-Olean Airport , Olean"
        },
        {
            "value": "OLF",
            "icao_code": "KOLF",
            "label": "L. M. Clayton Airport , Wolf Point"
        },
        {
            "value": "OLH",
            "icao_code": null,
            "label": "Old Harbor Airport , Old Harbor"
        },
        {
            "value": "OLI",
            "icao_code": "BIRF",
            "label": "Rif Airport , \u00d3lafsv\u00edk"
        },
        {
            "value": "OLJ",
            "icao_code": "NVSZ",
            "label": "Olpoi Airport , Olpoi"
        },
        {
            "value": "OLK",
            "icao_code": "SGOL",
            "label": "Fuerte Olimpo Airport , Fuerte Olimpo"
        },
        {
            "value": "OLL",
            "icao_code": null,
            "label": "Oyo Ollombo Airport , Oyo"
        },
        {
            "value": "OLM",
            "icao_code": "KOLM",
            "label": "Olympia Regional Airport , Olympia"
        },
        {
            "value": "OLN",
            "icao_code": "SAVM",
            "label": "Lago Musters Airport , Sarmiento"
        },
        {
            "value": "OLO",
            "icao_code": "LKOL",
            "label": "Olomouc Airport , Olomouc"
        },
        {
            "value": "OLP",
            "icao_code": "YOLD",
            "label": "Olympic Dam Airport , Olympic Dam"
        },
        {
            "value": "OLQ",
            "icao_code": null,
            "label": "Olsobip Airport , Olsobip"
        },
        {
            "value": "OLR",
            "icao_code": "OASL",
            "label": "Forward Operating Base Salerno , Khost"
        },
        {
            "value": "OLS",
            "icao_code": "KOLS",
            "label": "Nogales International Airport , Nogales"
        },
        {
            "value": "OLU",
            "icao_code": "KOLU",
            "label": "Columbus Municipal Airport , Columbus"
        },
        {
            "value": "OLV",
            "icao_code": "KOLV",
            "label": "Olive Branch Airport , Olive Branch"
        },
        {
            "value": "OLX",
            "icao_code": null,
            "label": "Olkiombo Airstrip , Olkiombo"
        },
        {
            "value": "OLY",
            "icao_code": "KOLY",
            "label": "Olney-Noble Airport , Olney"
        },
        {
            "value": "OLZ",
            "icao_code": "UEMO",
            "label": "Olyokminsk Airport , Olyokminsk"
        },
        {
            "value": "OMA",
            "icao_code": "KOMA",
            "label": "Eppley Airfield , Omaha"
        },
        {
            "value": "OMB",
            "icao_code": "FOOH",
            "label": "Ombou\u00e9 Hospital Airport , Ombou\u00e9"
        },
        {
            "value": "OMC",
            "icao_code": "RPVO",
            "label": "Ormoc Airport , Ormoc"
        },
        {
            "value": "OMD",
            "icao_code": "FYOG",
            "label": "Oranjemund Airport , Oranjemund"
        },
        {
            "value": "OME",
            "icao_code": "PAOM",
            "label": "Nome Airport , Nome"
        },
        {
            "value": "OMF",
            "icao_code": "OJMF",
            "label": "King Hussein Air Base , Mafraq"
        },
        {
            "value": "OMG",
            "icao_code": "FYOE",
            "label": "Omega Airport , Omega"
        },
        {
            "value": "OMH",
            "icao_code": "OITR",
            "label": "Urmia Airport , Urmia"
        },
        {
            "value": "OMI",
            "icao_code": "OIAJ",
            "label": "Omidiyeh Air Base , Omidiyeh"
        },
        {
            "value": "OMJ",
            "icao_code": "RJDU",
            "label": "\u014cmura Airport , Nagasaki"
        },
        {
            "value": "OMK",
            "icao_code": "KOMK",
            "label": "Omak Airport , Omak"
        },
        {
            "value": "OML",
            "icao_code": null,
            "label": "Omkalai Airport , Omkalai"
        },
        {
            "value": "OMM",
            "icao_code": "OOMX",
            "label": "Marmul Airport , Marmul"
        },
        {
            "value": "OMO",
            "icao_code": "LQMO",
            "label": "Mostar Airport , Mostar"
        },
        {
            "value": "OMR",
            "icao_code": "LROD",
            "label": "Oradea International Airport , Oradea"
        },
        {
            "value": "OMS",
            "icao_code": "UNOO",
            "label": "Omsk Tsentralny Airport , Omsk"
        },
        {
            "value": "OMY",
            "icao_code": null,
            "label": "Thbeng Meanchey Airport , Phnom Tbeng Meanchey"
        },
        {
            "value": "ONA",
            "icao_code": "KONA",
            "label": "Winona Municipal Airport , Winona"
        },
        {
            "value": "ONB",
            "icao_code": "AYQQ",
            "label": "Ononge Airport , Ononge"
        },
        {
            "value": "OND",
            "icao_code": "FYOA",
            "label": "Ondangwa Airport , Ondangwa"
        },
        {
            "value": "ONE",
            "icao_code": null,
            "label": "Onepusu Airport , Onepusu"
        },
        {
            "value": "ONG",
            "icao_code": "YMTI",
            "label": "Mornington Island Airport , Mornington Island"
        },
        {
            "value": "ONH",
            "icao_code": null,
            "label": "Oneonta Municipal Airport , Oneonta"
        },
        {
            "value": "ONI",
            "icao_code": "WABD",
            "label": "Moanamani Airport , Moanemani"
        },
        {
            "value": "ONJ",
            "icao_code": "RJSR",
            "label": "Odate\u2013Noshiro Airport , \u014cdate"
        },
        {
            "value": "ONK",
            "icao_code": "UERO",
            "label": "Olenyok Airport , Olenyok"
        },
        {
            "value": "ONL",
            "icao_code": "KONL",
            "label": "O'Neill Municipal Airport , O'Neill"
        },
        {
            "value": "ONM",
            "icao_code": "KONM",
            "label": "Socorro Municipal Airport , Socorro"
        },
        {
            "value": "ONO",
            "icao_code": "KONO",
            "label": "Ontario Municipal Airport , Ontario"
        },
        {
            "value": "ONP",
            "icao_code": "KONP",
            "label": "Newport Municipal Airport , Newport"
        },
        {
            "value": "ONQ",
            "icao_code": "LTAS",
            "label": "Zonguldak Airport , Zonguldak"
        },
        {
            "value": "ONR",
            "icao_code": "YMNK",
            "label": "Monkira Airport , Monkira"
        },
        {
            "value": "ONS",
            "icao_code": "YOLW",
            "label": "Onslow Airport , Onslow"
        },
        {
            "value": "ONT",
            "icao_code": "KONT",
            "label": "Ontario International Airport , Ontario"
        },
        {
            "value": "ONU",
            "icao_code": "NFOL",
            "label": "Ono-i-Lau Airport , Ono-i-Lau"
        },
        {
            "value": "ONX",
            "icao_code": "MPEJ",
            "label": "Enrique Adolfo Jim\u00e9nez Airport , Col\u00f3n"
        },
        {
            "value": "ONY",
            "icao_code": "KONY",
            "label": "Olney Municipal Airport , Olney"
        },
        {
            "value": "OOA",
            "icao_code": "KOOA",
            "label": "Oskaloosa Municipal Airport , Oskaloosa"
        },
        {
            "value": "OOK",
            "icao_code": "PAOO",
            "label": "Toksook Bay Airport , Toksook Bay"
        },
        {
            "value": "OOL",
            "icao_code": "YBCG",
            "label": "Gold Coast Airport , Gold Coast"
        },
        {
            "value": "OOM",
            "icao_code": "YCOM",
            "label": "Cooma\u2013Snowy Mountains Airport , Cooma"
        },
        {
            "value": "OOR",
            "icao_code": "YMOO",
            "label": "Mooraberree Airport , Mooraberree"
        },
        {
            "value": "OOT",
            "icao_code": "NGON",
            "label": "Onotoa Airport , Onotoa"
        },
        {
            "value": "OPA",
            "icao_code": "BIKP",
            "label": "K\u00f3pasker Airport , K\u00f3pasker"
        },
        {
            "value": "OPB",
            "icao_code": null,
            "label": "Open Bay Airport , Open Bay"
        },
        {
            "value": "OPF",
            "icao_code": "KOPF",
            "label": "Miami-Opa Locka Executive Airport , Miami"
        },
        {
            "value": "OPI",
            "icao_code": "YOEN",
            "label": "Oenpelli Airport , Gunbalanya"
        },
        {
            "value": "OPL",
            "icao_code": "KOPL",
            "label": "St. Landry Parish Airport , Opelousas"
        },
        {
            "value": "OPO",
            "icao_code": "LPPR",
            "label": "Francisco de S\u00e1 Carneiro Airport , Porto"
        },
        {
            "value": "OPS",
            "icao_code": "SWSI",
            "label": "Presidente Jo\u00e3o Figueiredo Airport , Sinop"
        },
        {
            "value": "OPU",
            "icao_code": "AYBM",
            "label": "Balimo Airport , Balimo"
        },
        {
            "value": "OPW",
            "icao_code": "FYOP",
            "label": "Opuwa Airport , Opuwo"
        },
        {
            "value": "ORA",
            "icao_code": "SASO",
            "label": "Or\u00e1n Airport , Or\u00e1n"
        },
        {
            "value": "ORB",
            "icao_code": "ESOE",
            "label": "\u00d6rebro Airport , \u00d6rebro"
        },
        {
            "value": "ORC",
            "icao_code": "SKOE",
            "label": "Orocue Airport , Orocu\u00e9"
        },
        {
            "value": "ORD",
            "icao_code": "KORD",
            "label": "O'Hare International Airport , Chicago"
        },
        {
            "value": "ORE",
            "icao_code": "LFOZ",
            "label": "Orl\u00e9ans \u2013 Saint-Denis-de-l'H\u00f4tel Airport , Orl\u00e9ans"
        },
        {
            "value": "ORF",
            "icao_code": "KORF",
            "label": "Norfolk International Airport , Norfolk"
        },
        {
            "value": "ORG",
            "icao_code": "SMZO",
            "label": "Zorg en Hoop Airport , Paramaribo"
        },
        {
            "value": "ORH",
            "icao_code": "KORH",
            "label": "Worcester Regional Airport , Worcester"
        },
        {
            "value": "ORI",
            "icao_code": "KORI",
            "label": "Port Lions Airport , Port Lions"
        },
        {
            "value": "ORJ",
            "icao_code": "SYOR",
            "label": "Orinduik Airport , Orinduik"
        },
        {
            "value": "ORK",
            "icao_code": "EICK",
            "label": "Cork Airport , Cork"
        },
        {
            "value": "ORL",
            "icao_code": "KORL",
            "label": "Orlando Executive Airport , Orlando"
        },
        {
            "value": "ORM",
            "icao_code": "EGBK",
            "label": "Sywell Aerodrome , Northampton"
        },
        {
            "value": "ORN",
            "icao_code": "DAOO",
            "label": "Oran Es S\u00e9nia Airport , Oran"
        },
        {
            "value": "ORO",
            "icao_code": "MHYR",
            "label": "Yoro Airport , Yoro"
        },
        {
            "value": "ORP",
            "icao_code": "FBOR",
            "label": "Orapa Airport , Orapa"
        },
        {
            "value": "ORR",
            "icao_code": "YYOR",
            "label": "Yorketown Airport , Yorketown"
        },
        {
            "value": "ORS",
            "icao_code": null,
            "label": "Orpheus Island Resort Waterport , Orpheus Island"
        },
        {
            "value": "ORT",
            "icao_code": "PAOR",
            "label": "Northway Airport , Northway"
        },
        {
            "value": "ORU",
            "icao_code": "SLOR",
            "label": "Juan Mendoza Airport , Oruro"
        },
        {
            "value": "ORV",
            "icao_code": "PFNO",
            "label": "Robert (Bob) Curtis Memorial Airport , Noorvik"
        },
        {
            "value": "ORW",
            "icao_code": "OPOR",
            "label": "Ormara Airport , Ormara"
        },
        {
            "value": "ORX",
            "icao_code": "SNOX",
            "label": "Oriximin\u00e1 Airport , Oriximin\u00e1"
        },
        {
            "value": "ORY",
            "icao_code": "LFPO",
            "label": "Orly Airport , Paris"
        },
        {
            "value": "ORZ",
            "icao_code": "MZTH",
            "label": "Orange Walk Airport , Orange Walk Town"
        },
        {
            "value": "OSA",
            "icao_code": null,
            "label": "metropolitan area , Osaka"
        },
        {
            "value": "OSB",
            "icao_code": null,
            "label": "Grand Glaize-Osage Beach Airport , Osage Beach"
        },
        {
            "value": "OSC",
            "icao_code": "KOSC",
            "label": "Oscoda\u2013Wurtsmith Airport , Oscoda"
        },
        {
            "value": "OSD",
            "icao_code": "ESNZ",
            "label": "\u00c5re \u00d6stersund Airport , \u00d6stersund"
        },
        {
            "value": "OSE",
            "icao_code": null,
            "label": "Omora Airport , Omora"
        },
        {
            "value": "OSF",
            "icao_code": "UUMO",
            "label": "Ostafyevo International Airport , Moscow"
        },
        {
            "value": "OSG",
            "icao_code": null,
            "label": "Ossima Airport , Ossima"
        },
        {
            "value": "OSH",
            "icao_code": "KOSH",
            "label": "Wittman Regional Airport , Oshkosh"
        },
        {
            "value": "OSI",
            "icao_code": "LDOS",
            "label": "Osijek Airport , Osijek"
        },
        {
            "value": "OSK",
            "icao_code": "ESMO",
            "label": "Oskarshamn Airport , Oskarshamn"
        },
        {
            "value": "OSL",
            "icao_code": "ENGM",
            "label": "Oslo Airport, Gardermoen , Oslo"
        },
        {
            "value": "OSM",
            "icao_code": "ORBM",
            "label": "Mosul International Airport , Mosul"
        },
        {
            "value": "OSN",
            "icao_code": "RKSO",
            "label": "Osan Air Base , Osan"
        },
        {
            "value": "OSO",
            "icao_code": "YOSB",
            "label": "Osborne Mine Airport , Osborne Mine"
        },
        {
            "value": "OSR",
            "icao_code": "LKMT",
            "label": "Leo\u0161 Jan\u00e1\u010dek Airport Ostrava , Ostrava"
        },
        {
            "value": "OSS",
            "icao_code": "UAFO",
            "label": "Osh Airport , Osh"
        },
        {
            "value": "OST",
            "icao_code": "EBOS",
            "label": "Ostend\u2013Bruges International Airport , Ostend"
        },
        {
            "value": "OSU",
            "icao_code": "KOSU",
            "label": "Ohio State University Airport , Columbus"
        },
        {
            "value": "OSW",
            "icao_code": "UWOR",
            "label": "Orsk Airport , Orsk"
        },
        {
            "value": "OSX",
            "icao_code": "KOSX",
            "label": "Kosciusko-Attala County Airport , Kosciusko"
        },
        {
            "value": "OSY",
            "icao_code": "ENNM",
            "label": "Namsos Airport, H\u00f8knes\u00f8ra , Namsos"
        },
        {
            "value": "OSZ",
            "icao_code": "EPKZ",
            "label": "Koszalin-Zegrze Pomorskie Airport , Koszalin"
        },
        {
            "value": "OTA",
            "icao_code": null,
            "label": "Mota Airport , Mota"
        },
        {
            "value": "OTC",
            "icao_code": "FTTL",
            "label": "Bol-B\u00e9rim Airport , Bol"
        },
        {
            "value": "OTD",
            "icao_code": null,
            "label": "Contadora Airport , Contadora Island"
        },
        {
            "value": "OTG",
            "icao_code": "KOTG",
            "label": "Worthington Municipal Airport , Worthington"
        },
        {
            "value": "OTH",
            "icao_code": "KOTH",
            "label": "Southwest Oregon Regional Airport , North Bend"
        },
        {
            "value": "OTI",
            "icao_code": "WAMR",
            "label": "Pitu Airport , Morotai Island"
        },
        {
            "value": "OTJ",
            "icao_code": "FYOW",
            "label": "Otjiwarongo Airport , Otjiwarongo"
        },
        {
            "value": "OTK",
            "icao_code": "KTMK",
            "label": "Tillamook Airport , Tillamook"
        },
        {
            "value": "OTL",
            "icao_code": "GQNB",
            "label": "Boutilimit Airport , Boutilimit"
        },
        {
            "value": "OTM",
            "icao_code": "KOTM",
            "label": "Ottumwa Regional Airport , Ottumwa"
        },
        {
            "value": "OTN",
            "icao_code": null,
            "label": "Ed-Air Airport , Oaktown"
        },
        {
            "value": "OTP",
            "icao_code": "LROP",
            "label": "Henri Coand\u0103 International Airport , Bucharest"
        },
        {
            "value": "OTR",
            "icao_code": "MRCC",
            "label": "Coto 47 Airport , Coto 47"
        },
        {
            "value": "OTS",
            "icao_code": null,
            "label": "Anacortes Airport , Anacortes"
        },
        {
            "value": "OTT",
            "icao_code": null,
            "label": "Andre Maggi Airport , Cotrigua\u00e7u"
        },
        {
            "value": "OTU",
            "icao_code": "SKOT",
            "label": "Ot\u00fa Airport , Remedios"
        },
        {
            "value": "OTV",
            "icao_code": "AGGQ",
            "label": "Ontong Java Airport , Ontong Java Atoll"
        },
        {
            "value": "OTY",
            "icao_code": null,
            "label": "Oria Airport , Oria"
        },
        {
            "value": "OTZ",
            "icao_code": "PAOT",
            "label": "Ralph Wien Memorial Airport , Kotzebue"
        },
        {
            "value": "OUA",
            "icao_code": "DFFD",
            "label": "Ouagadougou Airport , Ouagadougou"
        },
        {
            "value": "OUD",
            "icao_code": "GMFO",
            "label": "Angads Airport , Oujda"
        },
        {
            "value": "OUE",
            "icao_code": "FCOU",
            "label": "Ou\u00e9sso Airport , Ou\u00e9sso"
        },
        {
            "value": "OUG",
            "icao_code": "DFCC",
            "label": "Ouahigouya Airport , Ouahigouya"
        },
        {
            "value": "OUH",
            "icao_code": "FAOH",
            "label": "Oudtshoorn Airport , Oudtshoorn"
        },
        {
            "value": "OUK",
            "icao_code": null,
            "label": "Out Skerries Airport , Out Skerries"
        },
        {
            "value": "OUL",
            "icao_code": "EFOU",
            "label": "Oulu Airport , Oulu"
        },
        {
            "value": "OUM",
            "icao_code": null,
            "label": "Oum Hadjer Airport , Oum Hadjer"
        },
        {
            "value": "OUN",
            "icao_code": "KOUN",
            "label": "University of Oklahoma Westheimer Airport , Norman"
        },
        {
            "value": "OUR",
            "icao_code": "FKKI",
            "label": "Batouri Airport , Batouri"
        },
        {
            "value": "OUS",
            "icao_code": "SDOU",
            "label": "Jornalista Benedito Pimentel\u2013Ourinhos State Airport , Ourinhos"
        },
        {
            "value": "OUT",
            "icao_code": "FTTS",
            "label": "Bousso Airport , Bousso"
        },
        {
            "value": "OUU",
            "icao_code": null,
            "label": "Ouanga Airport , Ouanga"
        },
        {
            "value": "OUZ",
            "icao_code": "GQPZ",
            "label": "Tazadit Airport , Zouerate"
        },
        {
            "value": "OVA",
            "icao_code": "FMSL",
            "label": "Bekily Airport , Bekily"
        },
        {
            "value": "OVB",
            "icao_code": "UNNT",
            "label": "Tolmachevo Airport , Novosibirsk"
        },
        {
            "value": "OVD",
            "icao_code": "LEAS",
            "label": "Asturias Airport , Oviedo"
        },
        {
            "value": "OVE",
            "icao_code": "KOVE",
            "label": "Oroville Municipal Airport , Oroville"
        },
        {
            "value": "OVG",
            "icao_code": "FAOB",
            "label": "Air Force Base Overberg , Bredasdorp"
        },
        {
            "value": "OVL",
            "icao_code": "SCOV",
            "label": "El Tuqui Airport , Ovalle"
        },
        {
            "value": "OVR",
            "icao_code": "SAZF",
            "label": "Olavarr\u00eda Airport , Olavarr\u00eda"
        },
        {
            "value": "OVS",
            "icao_code": "USHS",
            "label": "Sovetsky Airport , Sovetsky"
        },
        {
            "value": "OWA",
            "icao_code": "KOWA",
            "label": "Owatonna Degner Regional Airport , Owatonna"
        },
        {
            "value": "OWB",
            "icao_code": "KOWB",
            "label": "Owensboro\u2013Daviess County Airport , Owensboro"
        },
        {
            "value": "OWD",
            "icao_code": "KOWD",
            "label": "Norwood Memorial Airport , Norwood"
        },
        {
            "value": "OWE",
            "icao_code": null,
            "label": "Owendo Airport , Owendo"
        },
        {
            "value": "OWK",
            "icao_code": "KOWK",
            "label": "Central Maine Airport of Norridgewock , Norridgewock"
        },
        {
            "value": "OXB",
            "icao_code": "GGOV",
            "label": "Osvaldo Vieira International Airport , Bissau"
        },
        {
            "value": "OXC",
            "icao_code": "KOXC",
            "label": "Waterbury\u2013Oxford Airport , Oxford"
        },
        {
            "value": "OXD",
            "icao_code": "KOXD",
            "label": "Miami University Airport , Oxford"
        },
        {
            "value": "OXF",
            "icao_code": "EGTK",
            "label": "Oxford Airport , Oxford"
        },
        {
            "value": "OXO",
            "icao_code": null,
            "label": "Orientos Airport , Orientos"
        },
        {
            "value": "OXR",
            "icao_code": "KOXR",
            "label": "Oxnard Airport , Oxnard"
        },
        {
            "value": "OXY",
            "icao_code": "YMNY",
            "label": "Morney Airport , Morney"
        },
        {
            "value": "OYA",
            "icao_code": "SATG",
            "label": "Goya Airport , Goya"
        },
        {
            "value": "OYE",
            "icao_code": "FOGO",
            "label": "Oyem Airport , Oyem"
        },
        {
            "value": "OYG",
            "icao_code": null,
            "label": "Moyo Airport , Moyo Town"
        },
        {
            "value": "OYK",
            "icao_code": "SBOI",
            "label": "Oiapoque Airport , Oiapoque"
        },
        {
            "value": "OYL",
            "icao_code": "HKMY",
            "label": "Moyale Airport , Moyale"
        },
        {
            "value": "OYN",
            "icao_code": "YOUY",
            "label": "Ouyen Airport , Ouyen"
        },
        {
            "value": "OYO",
            "icao_code": "SAZH",
            "label": "Tres Arroyos Airport , Tres Arroyos"
        },
        {
            "value": "OYP",
            "icao_code": "SOOG",
            "label": "Saint-Georges-de-l'Oyapock Airport , Saint-Georges-de-l'Oyapock"
        },
        {
            "value": "OZA",
            "icao_code": "KOZA",
            "label": "Ozona Municipal Airport , Ozona"
        },
        {
            "value": "OZC",
            "icao_code": "RPMO",
            "label": "Labo Airport , Ozamiz"
        },
        {
            "value": "OZG",
            "icao_code": "GMAZ",
            "label": "Zagora Airport , Zagora"
        },
        {
            "value": "OZH",
            "icao_code": "UKDE",
            "label": "Zaporizhzhia International Airport , Zaporizhzhia"
        },
        {
            "value": "OZP",
            "icao_code": "LEMO",
            "label": "Mor\u00f3n Air Base , Mor\u00f3n de la Frontera"
        },
        {
            "value": "OZR",
            "icao_code": "KOZR",
            "label": "Cairns Army Airfield , Fort Novosel"
        },
        {
            "value": "OZZ",
            "icao_code": "GMMZ",
            "label": "Ouarzazate Airport , Ouarzazate"
        },
        {
            "value": "PAA",
            "icao_code": "VYPA",
            "label": "Hpa-An Airport , Hpa-An"
        },
        {
            "value": "PAB",
            "icao_code": "VEBU",
            "label": "Bilaspur Airport , Bilaspur"
        },
        {
            "value": "PAC",
            "icao_code": "MPMG",
            "label": "Albrook \"Marcos A. Gelabert\" International Airport , Panama City"
        },
        {
            "value": "PAD",
            "icao_code": "EDLP",
            "label": "Paderborn Lippstadt Airport , Paderborn"
        },
        {
            "value": "PAE",
            "icao_code": "KPAE",
            "label": "Paine Field , Everett"
        },
        {
            "value": "PAF",
            "icao_code": "HUPA",
            "label": "Pakuba Airfield , Pakuba"
        },
        {
            "value": "PAG",
            "icao_code": "RPMP",
            "label": "Pagadian Airport , Pagadian"
        },
        {
            "value": "PAH",
            "icao_code": "KPAH",
            "label": "Barkley Regional Airport , Paducah"
        },
        {
            "value": "PAJ",
            "icao_code": "OPPC",
            "label": "Parachinar Airport , Parachinar"
        },
        {
            "value": "PAK",
            "icao_code": "PHPA",
            "label": "Port Allen Airport , Hanapepe"
        },
        {
            "value": "PAL",
            "icao_code": "SKPQ",
            "label": "Captain Germ\u00e1n Olano Moreno Air Base , Palanquero"
        },
        {
            "value": "PAM",
            "icao_code": "KPAM",
            "label": "Tyndall Air Force Base , Panama City"
        },
        {
            "value": "PAN",
            "icao_code": "VTSK",
            "label": "Pattani Airport , Pattani"
        },
        {
            "value": "PAO",
            "icao_code": "KPAO",
            "label": "Palo Alto Airport , Palo Alto"
        },
        {
            "value": "PAP",
            "icao_code": "MTPP",
            "label": "Toussaint L'Ouverture International Airport , Port-au-Prince"
        },
        {
            "value": "PAQ",
            "icao_code": "PAAQ",
            "label": "Palmer Municipal Airport , Palmer"
        },
        {
            "value": "PAR",
            "icao_code": null,
            "label": "metropolitan area , Paris"
        },
        {
            "value": "PAS",
            "icao_code": "LGPA",
            "label": "Paros National Airport , Paros"
        },
        {
            "value": "PAT",
            "icao_code": "VEPT",
            "label": "Lok Nayak Jayaprakash Airport , Patna"
        },
        {
            "value": "PAU",
            "icao_code": "VYPK",
            "label": "Pauk Airport , Pauk"
        },
        {
            "value": "PAV",
            "icao_code": "SBUF",
            "label": "Paulo Afonso Airport , Paulo Afonso"
        },
        {
            "value": "PAW",
            "icao_code": null,
            "label": "Pambwa Airport , Pambwa"
        },
        {
            "value": "PAX",
            "icao_code": "MTPX",
            "label": "Port-de-Paix Airport , Port-de-Paix"
        },
        {
            "value": "PAY",
            "icao_code": "WBKP",
            "label": "Pamol Airport , Pamol"
        },
        {
            "value": "PAZ",
            "icao_code": "MMPA",
            "label": "El Taj\u00edn National Airport , Poza Rica"
        },
        {
            "value": "PBB",
            "icao_code": "SSPN",
            "label": "Parana\u00edba Airport , Parana\u00edba"
        },
        {
            "value": "PBC",
            "icao_code": "MMPB",
            "label": "Hermanos Serd\u00e1n International Airport , Puebla City"
        },
        {
            "value": "PBD",
            "icao_code": "VAPR",
            "label": "Porbandar Airport , Porbandar"
        },
        {
            "value": "PBE",
            "icao_code": "SKPR",
            "label": "Morela Airport , Puerto Berr\u00edo"
        },
        {
            "value": "PBF",
            "icao_code": "KPBF",
            "label": "Grider Field , Pine Bluff"
        },
        {
            "value": "PBG",
            "icao_code": "KPBG",
            "label": "Plattsburgh International Airport , Plattsburgh"
        },
        {
            "value": "PBH",
            "icao_code": "VQPR",
            "label": "Paro International Airport , Paro"
        },
        {
            "value": "PBI",
            "icao_code": "KPBI",
            "label": "Palm Beach International Airport , West Palm Beach"
        },
        {
            "value": "PBJ",
            "icao_code": "NVSI",
            "label": "Paama Airport , Paama"
        },
        {
            "value": "PBL",
            "icao_code": "SVPC",
            "label": "Bartolom\u00e9 Salom Airport , Puerto Cabello"
        },
        {
            "value": "PBM",
            "icao_code": "SMJP",
            "label": "Johan Adolf Pengel International Airport , Paramaribo"
        },
        {
            "value": "PBN",
            "icao_code": "FNPA",
            "label": "Porto Amboim Airport , Porto Amboim"
        },
        {
            "value": "PBO",
            "icao_code": "YPBO",
            "label": "Paraburdoo Airport , Paraburdoo"
        },
        {
            "value": "PBP",
            "icao_code": "MRIA",
            "label": "Punta Islita Airport , Punta Islita"
        },
        {
            "value": "PBQ",
            "icao_code": "SWPM",
            "label": "Pimenta Bueno Airport , Pimenta Bueno"
        },
        {
            "value": "PBR",
            "icao_code": "MGPB",
            "label": "Puerto Barrios Airport , Puerto Barrios"
        },
        {
            "value": "PBT",
            "icao_code": null,
            "label": "Puerto Leda Airport , Puerto Leda"
        },
        {
            "value": "PBU",
            "icao_code": "VYPT",
            "label": "Putao Airport , Putao"
        },
        {
            "value": "PBV",
            "icao_code": "SWPG",
            "label": "Porto dos Ga\u00fachos Airport , Porto dos Ga\u00fachos"
        },
        {
            "value": "PBX",
            "icao_code": "SWPQ",
            "label": "Fazenda Piraguassu Airport , Porto Alegre do Norte"
        },
        {
            "value": "PBZ",
            "icao_code": "FAPG",
            "label": "Plettenberg Bay Airport , Plettenberg Bay"
        },
        {
            "value": "PCA",
            "icao_code": "PAOC",
            "label": "Portage Creek Airport , Portage Creek"
        },
        {
            "value": "PCB",
            "icao_code": "WIHP",
            "label": "Pondok Cabe Airport , South Tangerang"
        },
        {
            "value": "PCC",
            "icao_code": null,
            "label": "Puerto Rico Airport , Puerto Rico"
        },
        {
            "value": "PCD",
            "icao_code": "KPDC",
            "label": "Prairie du Chien Municipal Airport , Prairie du Chien"
        },
        {
            "value": "PCF",
            "icao_code": "FAPS",
            "label": "Potchefstroom Airport , Potchefstroom"
        },
        {
            "value": "PCH",
            "icao_code": "MHPC",
            "label": "Palacios Airport , Palacios"
        },
        {
            "value": "PCJ",
            "icao_code": null,
            "label": "Puerto La Victoria Airport , Puerto Casado"
        },
        {
            "value": "PCL",
            "icao_code": "SPCL",
            "label": "FAP Captain David Abensur Rengifo International Airport , Pucallpa"
        },
        {
            "value": "PCM",
            "icao_code": null,
            "label": "Playa del Carmen Airport , Playa del Carmen"
        },
        {
            "value": "PCN",
            "icao_code": "NZOK",
            "label": "Picton Aerodrome , Picton"
        },
        {
            "value": "PCO",
            "icao_code": "MMPL",
            "label": "Punta Colorada Airstrip , La Ribera"
        },
        {
            "value": "PCP",
            "icao_code": "FPPR",
            "label": "Pr\u00edncipe Airport , Pr\u00edncipe Island"
        },
        {
            "value": "PCQ",
            "icao_code": null,
            "label": "Boun Neua Airport , Phongsaly"
        },
        {
            "value": "PCR",
            "icao_code": "SKPC",
            "label": "Germ\u00e1n Olano Airport , Puerto Carre\u00f1o"
        },
        {
            "value": "PCS",
            "icao_code": "SNPC",
            "label": "Picos Airport , Picos"
        },
        {
            "value": "PCT",
            "icao_code": null,
            "label": "Princeton Airport , Princeton"
        },
        {
            "value": "PCU",
            "icao_code": null,
            "label": "Poplarville-Pearl River County Airport , Poplarville"
        },
        {
            "value": "PCV",
            "icao_code": null,
            "label": "Punta Chivato Airstrip , Punta Chivato"
        },
        {
            "value": "PDA",
            "icao_code": "SKPD",
            "label": "Obando Airport , Puerto In\u00edrida"
        },
        {
            "value": "PDB",
            "icao_code": null,
            "label": "Pedro Bay Airport , Pedro Bay"
        },
        {
            "value": "PDC",
            "icao_code": "NWWQ",
            "label": "Mueo/Nickel Airport , Mueo"
        },
        {
            "value": "PDD",
            "icao_code": "FQPO",
            "label": "Ponta do Ouro Airport , Ponta do Ouro"
        },
        {
            "value": "PDE",
            "icao_code": "YPDI",
            "label": "Pandie Pandie Airport , Pandie Pandie Station"
        },
        {
            "value": "PDF",
            "icao_code": "SIAJ",
            "label": "Faz Taua Airport , Prado"
        },
        {
            "value": "PDG",
            "icao_code": "WIPT",
            "label": "Minangkabau International Airport , Padang"
        },
        {
            "value": "PDI",
            "icao_code": null,
            "label": "Pindiu Airport , Pindiu"
        },
        {
            "value": "PDK",
            "icao_code": "KPDK",
            "label": "DeKalb\u2013Peachtree Airport , Atlanta"
        },
        {
            "value": "PDL",
            "icao_code": "LPPD",
            "label": "Jo\u00e3o Paulo II Airport , Ponta Delgada"
        },
        {
            "value": "PDM",
            "icao_code": null,
            "label": "Pedas\u00ed Airport , Pedas\u00ed"
        },
        {
            "value": "PDN",
            "icao_code": null,
            "label": "Parndana Airport , Parndana"
        },
        {
            "value": "PDO",
            "icao_code": "WIPQ",
            "label": "Pendopo Airport , Pendopo"
        },
        {
            "value": "PDP",
            "icao_code": "SULS",
            "label": "Capit\u00e1n de Corbeta Carlos A. Curbelo International Airport , Punta del Este"
        },
        {
            "value": "PDR",
            "icao_code": null,
            "label": "Presidente Dutra Airport , Presidente Dutra"
        },
        {
            "value": "PDS",
            "icao_code": "MMPG",
            "label": "Piedras Negras International Airport , Piedras Negras"
        },
        {
            "value": "PDT",
            "icao_code": "KPDT",
            "label": "Eastern Oregon Regional Airport , Pendleton"
        },
        {
            "value": "PDU",
            "icao_code": "SUPU",
            "label": "Tydeo Larre Borges International Airport , Paysand\u00fa"
        },
        {
            "value": "PDV",
            "icao_code": "LBPD",
            "label": "Plovdiv Airport , Plovdiv"
        },
        {
            "value": "PDX",
            "icao_code": "KPDX",
            "label": "Portland International Airport , Portland"
        },
        {
            "value": "PDZ",
            "icao_code": "SVPE",
            "label": "Pedernales Airport , Pedernales"
        },
        {
            "value": "PEA",
            "icao_code": "YPSH",
            "label": "Penneshaw Airport , Penneshaw"
        },
        {
            "value": "PEB",
            "icao_code": null,
            "label": "Pebane Airport , Pebane"
        },
        {
            "value": "PEC",
            "icao_code": null,
            "label": "Pelican Seaplane Base , Pelican"
        },
        {
            "value": "PED",
            "icao_code": "LKPD",
            "label": "Pardubice Airport , Pardubice"
        },
        {
            "value": "PEE",
            "icao_code": "USPP",
            "label": "Perm International Airport , Perm"
        },
        {
            "value": "PEF",
            "icao_code": "EDCP",
            "label": "Peenem\u00fcnde Airfield , Peenem\u00fcnde"
        },
        {
            "value": "PEG",
            "icao_code": "LIRZ",
            "label": "Perugia San Francesco d'Assisi \u2013 Umbria International Airport , Perugia"
        },
        {
            "value": "PEH",
            "icao_code": "SAZP",
            "label": "Comodoro Pedro Zanni Airport , Pehuaj\u00f3"
        },
        {
            "value": "PEI",
            "icao_code": "SKPE",
            "label": "Mateca\u00f1a International Airport , Pereira"
        },
        {
            "value": "PEK",
            "icao_code": "ZBAA",
            "label": "Beijing Capital International Airport , Beijing"
        },
        {
            "value": "PEL",
            "icao_code": "FXPG",
            "label": "Pelaneng Airport , Pelaneng"
        },
        {
            "value": "PEM",
            "icao_code": "SPTU",
            "label": "Padre Aldamiz International Airport , Puerto Maldonado"
        },
        {
            "value": "PEN",
            "icao_code": "WMKP",
            "label": "Penang International Airport , Penang"
        },
        {
            "value": "PEP",
            "icao_code": null,
            "label": "Peppimenarti Airport , Peppimenarti"
        },
        {
            "value": "PEQ",
            "icao_code": "KPEQ",
            "label": "Pecos Municipal Airport , Pecos"
        },
        {
            "value": "PER",
            "icao_code": "YPPH",
            "label": "Perth Airport , Perth"
        },
        {
            "value": "PES",
            "icao_code": "ULPB",
            "label": "Petrozavodsk Airport , Petrozavodsk"
        },
        {
            "value": "PET",
            "icao_code": "SBPK",
            "label": "Jo\u00e3o Sim\u00f5es Lopes Neto International Airport , Pelotas"
        },
        {
            "value": "PEU",
            "icao_code": "MHPL",
            "label": "Puerto Lempira Airport , Puerto Lempira"
        },
        {
            "value": "PEV",
            "icao_code": "LHPP",
            "label": "P\u00e9cs-Pog\u00e1ny International Airport , P\u00e9cs"
        },
        {
            "value": "PEW",
            "icao_code": "OPPS",
            "label": "Bacha Khan International Airport , Peshawar"
        },
        {
            "value": "PEX",
            "icao_code": "UUYP",
            "label": "Pechora Airport , Pechora"
        },
        {
            "value": "PEY",
            "icao_code": null,
            "label": "Penong Airport , Penong"
        },
        {
            "value": "PEZ",
            "icao_code": "UWPP",
            "label": "Penza Airport , Penza"
        },
        {
            "value": "PFB",
            "icao_code": "SBPF",
            "label": "Lauro Kurtz Airport , Passo Fundo"
        },
        {
            "value": "PFC",
            "icao_code": "KPFC",
            "label": "Pacific City State Airport , Pacific City"
        },
        {
            "value": "PFJ",
            "icao_code": "BIPA",
            "label": "Patreksfj\u00f6r\u00f0ur Airport , Patreksfj\u00f6r\u00f0ur"
        },
        {
            "value": "PFM",
            "icao_code": null,
            "label": "Primrose Aerodrome , Wood Buffalo"
        },
        {
            "value": "PFO",
            "icao_code": "LCPH",
            "label": "Paphos International Airport , Paphos"
        },
        {
            "value": "PFQ",
            "icao_code": "OITP",
            "label": "Parsabad-Moghan Airport , Parsabad"
        },
        {
            "value": "PFR",
            "icao_code": "FZVS",
            "label": "Ilebo Airport , Ilebo"
        },
        {
            "value": "PGA",
            "icao_code": "KPGA",
            "label": "Page Municipal Airport , Page"
        },
        {
            "value": "PGB",
            "icao_code": null,
            "label": "Pangoa Airport , Pangoa"
        },
        {
            "value": "PGC",
            "icao_code": null,
            "label": "Grant County Airport , Petersburg"
        },
        {
            "value": "PGD",
            "icao_code": "KPGD",
            "label": "Punta Gorda Airport , Punta Gorda"
        },
        {
            "value": "PGE",
            "icao_code": null,
            "label": "Yegepa Airport , Yegepa"
        },
        {
            "value": "PGF",
            "icao_code": "LFMP",
            "label": "Perpignan\u2013Rivesaltes Airport , Perpignan"
        },
        {
            "value": "PGH",
            "icao_code": "VIPT",
            "label": "Pantnagar Airport , Pantnagar"
        },
        {
            "value": "PGI",
            "icao_code": "FNCH",
            "label": "Chitato Airport , Chitato"
        },
        {
            "value": "PGK",
            "icao_code": "WIPK",
            "label": "Depati Amir Airport , Pangkal Pinang"
        },
        {
            "value": "PGL",
            "icao_code": "KPQL",
            "label": "Trent Lott International Airport , Pascagoula"
        },
        {
            "value": "PGM",
            "icao_code": null,
            "label": "Port Graham Airport , Port Graham"
        },
        {
            "value": "PGN",
            "icao_code": null,
            "label": "Pangia Airport , Pangia"
        },
        {
            "value": "PGO",
            "icao_code": "KPSO",
            "label": "Stevens Field , Pagosa Springs"
        },
        {
            "value": "PGQ",
            "icao_code": "WAEM",
            "label": "Buli Airport , Buli"
        },
        {
            "value": "PGR",
            "icao_code": "KPGR",
            "label": "Kirk Field , Paragould"
        },
        {
            "value": "PGS",
            "icao_code": null,
            "label": "Grand Canyon Caverns Airport , Peach Springs"
        },
        {
            "value": "PGU",
            "icao_code": "OIBP",
            "label": "Persian Gulf Airport , Asaluyeh"
        },
        {
            "value": "PGV",
            "icao_code": "KPGV",
            "label": "Pitt\u2013Greenville Airport , Greenville"
        },
        {
            "value": "PGX",
            "icao_code": "LFBX",
            "label": "P\u00e9rigueux Bassillac Airport , P\u00e9rigueux"
        },
        {
            "value": "PGZ",
            "icao_code": "SSZW",
            "label": "Comte. Antonio Amilton Beraldo Airport , Ponta Grossa"
        },
        {
            "value": "PHA",
            "icao_code": "VVPR",
            "label": "Phan Rang Air Base , Phan Rang\u2013Th\u00e1p Ch\u00e0m"
        },
        {
            "value": "PHB",
            "icao_code": "SBPB",
            "label": "Parna\u00edba-Prefeito Dr. Jo\u00e3o Silva Filho International Airport , Parna\u00edba"
        },
        {
            "value": "PHC",
            "icao_code": "DNPO",
            "label": "Port Harcourt International Airport , Port Harcourt"
        },
        {
            "value": "PHD",
            "icao_code": "KPHD",
            "label": "Harry Clever Field , New Philadelphia"
        },
        {
            "value": "PHE",
            "icao_code": "YPPD",
            "label": "Port Hedland International Airport , Port Hedland"
        },
        {
            "value": "PHF",
            "icao_code": "KPHF",
            "label": "Newport News/Williamsburg International Airport , Newport News"
        },
        {
            "value": "PHG",
            "icao_code": null,
            "label": "Port Harcourt City Airport , Port Harcourt"
        },
        {
            "value": "PHI",
            "icao_code": "SNYE",
            "label": "Pinheiro Airport , Pinheiro"
        },
        {
            "value": "PHK",
            "icao_code": "KPHK",
            "label": "Palm Beach County Glades Airport , Pahokee"
        },
        {
            "value": "PHL",
            "icao_code": "KPHL",
            "label": "Philadelphia International Airport , Philadelphia"
        },
        {
            "value": "PHN",
            "icao_code": "KPHN",
            "label": "St. Clair County International Airport , Port Huron"
        },
        {
            "value": "PHO",
            "icao_code": "PAPO",
            "label": "Point Hope Airport , Point Hope"
        },
        {
            "value": "PHP",
            "icao_code": "KPHP",
            "label": "Philip Airport , Philip"
        },
        {
            "value": "PHQ",
            "icao_code": "YTMO",
            "label": "The Monument Airport , Phosphate Hill"
        },
        {
            "value": "PHR",
            "icao_code": "NFND",
            "label": "Pacific Harbour Airport , Pacific Harbour"
        },
        {
            "value": "PHS",
            "icao_code": "VTPP",
            "label": "Phitsanulok Airport , Phitsanulok"
        },
        {
            "value": "PHT",
            "icao_code": "KPHT",
            "label": "Henry County Airport , Paris"
        },
        {
            "value": "PHW",
            "icao_code": "FAPH",
            "label": "Hendrik Van Eck Airport , Phalaborwa"
        },
        {
            "value": "PHX",
            "icao_code": "KPHX",
            "label": "Phoenix Sky Harbor International Airport , Phoenix"
        },
        {
            "value": "PHY",
            "icao_code": "VTPB",
            "label": "Phetchabun Airport , Phetchabun"
        },
        {
            "value": "PIA",
            "icao_code": "KPIA",
            "label": "General Wayne A. Downing Peoria International Airport , Peoria"
        },
        {
            "value": "PIB",
            "icao_code": "KPIB",
            "label": "Hattiesburg\u2013Laurel Regional Airport , Hattiesburg"
        },
        {
            "value": "PIC",
            "icao_code": "MBPI",
            "label": "Pine Cay Airport , Pine Cay"
        },
        {
            "value": "PIE",
            "icao_code": "KPIE",
            "label": "St. Pete\u2013Clearwater International Airport , St. Petersburg"
        },
        {
            "value": "PIF",
            "icao_code": "RCDC",
            "label": "Pingtung South Airport , Pingtung City"
        },
        {
            "value": "PIH",
            "icao_code": "KPIH",
            "label": "Pocatello Regional Airport , Pocatello"
        },
        {
            "value": "PIK",
            "icao_code": "EGPK",
            "label": "Glasgow Prestwick Airport , Glasgow"
        },
        {
            "value": "PIL",
            "icao_code": "SGPI",
            "label": "Carlos Miguel Jim\u00e9nez Airport , Pilar"
        },
        {
            "value": "PIM",
            "icao_code": "KPIM",
            "label": "Harris County Airport , Pine Mountain"
        },
        {
            "value": "PIN",
            "icao_code": "SWPI",
            "label": "J\u00falio Bel\u00e9m Airport , Parintins"
        },
        {
            "value": "PIO",
            "icao_code": "SPSO",
            "label": "Capit\u00e1n FAP Ren\u00e1n El\u00edas Olivera Airport , Pisco"
        },
        {
            "value": "PIP",
            "icao_code": "PAPN",
            "label": "Pilot Point Airport , Pilot Point"
        },
        {
            "value": "PIQ",
            "icao_code": null,
            "label": "Pipillipai Airport , Pipillipai"
        },
        {
            "value": "PIR",
            "icao_code": "KPIR",
            "label": "Pierre Regional Airport , Pierre"
        },
        {
            "value": "PIS",
            "icao_code": "LFBI",
            "label": "Poitiers\u2013Biard Airport , Poitiers"
        },
        {
            "value": "PIT",
            "icao_code": "KPIT",
            "label": "Pittsburgh International Airport , Pittsburgh"
        },
        {
            "value": "PIU",
            "icao_code": "SPUR",
            "label": "FAP Captain Guillermo Concha Iberico International Airport , Piura"
        },
        {
            "value": "PIV",
            "icao_code": "SNPX",
            "label": "Pirapora Airport , Pirapora"
        },
        {
            "value": "PIW",
            "icao_code": "CZMN",
            "label": "Pikwitonei Airport , Pikwitonei"
        },
        {
            "value": "PIX",
            "icao_code": "LPPI",
            "label": "Pico Airport , Pico Island"
        },
        {
            "value": "PIZ",
            "icao_code": "PPIZ",
            "label": "Point Lay LRRS Airport , Point Lay"
        },
        {
            "value": "PJA",
            "icao_code": "ESUP",
            "label": "Pajala Airport , Pajala"
        },
        {
            "value": "PJB",
            "icao_code": "KPAN",
            "label": "Payson Airport , Payson"
        },
        {
            "value": "PJC",
            "icao_code": "SGPJ",
            "label": "Dr. Augusto Roberto Fuster International Airport , Pedro Juan Caballero"
        },
        {
            "value": "PJG",
            "icao_code": "OPPG",
            "label": "Panjgur Airport , Panjgur"
        },
        {
            "value": "PJM",
            "icao_code": "MRPJ",
            "label": "Puerto Jim\u00e9nez Airport , Puerto Jim\u00e9nez"
        },
        {
            "value": "PKA",
            "icao_code": "PAPK",
            "label": "Napaskiak Airport , Napaskiak"
        },
        {
            "value": "PKB",
            "icao_code": "KPKB",
            "label": "Mid-Ohio Valley Regional Airport , Parkersburg"
        },
        {
            "value": "PKC",
            "icao_code": "UHPP",
            "label": "Petropavlovsk-Kamchatsky Airport , Petropavlovsk-Kamchatsky"
        },
        {
            "value": "PKD",
            "icao_code": "KPKD",
            "label": "Park Rapids Municipal Airport , Park Rapids"
        },
        {
            "value": "PKE",
            "icao_code": "YPKS",
            "label": "Parkes Airport , Parkes"
        },
        {
            "value": "PKF",
            "icao_code": "KPKF",
            "label": "Park Falls Municipal Airport , Park Falls"
        },
        {
            "value": "PKG",
            "icao_code": "WMPA",
            "label": "Pangkor Airport , Pangkor Island"
        },
        {
            "value": "PKH",
            "icao_code": "LGHL",
            "label": "Porto Cheli Airport , Porto Cheli"
        },
        {
            "value": "PKJ",
            "icao_code": "MGPG",
            "label": "Playa Grande Airport , Playa Grande"
        },
        {
            "value": "PKK",
            "icao_code": "VYPU",
            "label": "Pakokku Airport , Pakokku"
        },
        {
            "value": "PKM",
            "icao_code": null,
            "label": "Port Kaituma Airport , Port Kaituma"
        },
        {
            "value": "PKN",
            "icao_code": "WAOI",
            "label": "Iskandar Airport , Pangkalan Bun"
        },
        {
            "value": "PKO",
            "icao_code": "DBBP",
            "label": "Parakou Airport , Parakou"
        },
        {
            "value": "PKP",
            "icao_code": "NTGP",
            "label": "Puka-Puka Airport , Puka-Puka"
        },
        {
            "value": "PKR",
            "icao_code": "VNPK",
            "label": "Pokhara Airport , Pokhara"
        },
        {
            "value": "PKT",
            "icao_code": "YKPT",
            "label": "Port Keats Airfield , Wadeye"
        },
        {
            "value": "PKU",
            "icao_code": "WIBB",
            "label": "Sultan Syarif Kasim II International Airport , Pekanbaru"
        },
        {
            "value": "PKV",
            "icao_code": "ULOO",
            "label": "Pskov Airport , Pskov"
        },
        {
            "value": "PKW",
            "icao_code": "FBSP",
            "label": "Selebi-Phikwe Airport , Selebi-Phikwe"
        },
        {
            "value": "PKX",
            "icao_code": "ZBAD",
            "label": "Beijing Daxing International Airport , Beijing"
        },
        {
            "value": "PKY",
            "icao_code": "WAGG",
            "label": "Tjilik Riwut Airport , Palangkaraya"
        },
        {
            "value": "PKZ",
            "icao_code": "VLPS",
            "label": "Pakse International Airport , Pakse"
        },
        {
            "value": "PLA",
            "icao_code": null,
            "label": "Planadas Airport , Planadas"
        },
        {
            "value": "PLC",
            "icao_code": null,
            "label": "Planeta Rica Airport , Planeta Rica"
        },
        {
            "value": "PLD",
            "icao_code": "MRCR",
            "label": "Carrillo Airport , S\u00e1mara"
        },
        {
            "value": "PLE",
            "icao_code": null,
            "label": "Paiela Airport , Paiela"
        },
        {
            "value": "PLF",
            "icao_code": "FTTP",
            "label": "Pala Airport , Pala"
        },
        {
            "value": "PLH",
            "icao_code": "EGHD",
            "label": "Plymouth City Airport , Plymouth"
        },
        {
            "value": "PLI",
            "icao_code": null,
            "label": "Palm Island Airport , Palm Island"
        },
        {
            "value": "PLJ",
            "icao_code": "MZPL",
            "label": "Placencia Airport , Placencia"
        },
        {
            "value": "PLK",
            "icao_code": "KPLK",
            "label": "M. Graham Clark Downtown Airport , Point Lookout"
        },
        {
            "value": "PLL",
            "icao_code": "SBMN",
            "label": "Manaus Air Force Base , Manaus"
        },
        {
            "value": "PLM",
            "icao_code": "WIPP",
            "label": "Sultan Mahmud Badaruddin II International Airport , Palembang"
        },
        {
            "value": "PLN",
            "icao_code": "KPLN",
            "label": "Pellston Regional Airport , Pellston"
        },
        {
            "value": "PLO",
            "icao_code": "YPLC",
            "label": "Port Lincoln Airport , Port Lincoln"
        },
        {
            "value": "PLP",
            "icao_code": "MPLP",
            "label": "Captain Ramon Xatruch Airport , La Palma"
        },
        {
            "value": "PLQ",
            "icao_code": "EYPA",
            "label": "Palanga International Airport , Palanga"
        },
        {
            "value": "PLR",
            "icao_code": "KPLR",
            "label": "St. Clair County Airport , Pell City"
        },
        {
            "value": "PLS",
            "icao_code": "MBPV",
            "label": "Providenciales International Airport , Providenciales"
        },
        {
            "value": "PLT",
            "icao_code": "SKPL",
            "label": "Plato Airport , Plato"
        },
        {
            "value": "PLU",
            "icao_code": "SBBH",
            "label": "Belo Horizonte/Pampulha \u2013 Carlos Drummond de Andrade Airport , Belo Horizonte"
        },
        {
            "value": "PLV",
            "icao_code": "UKHP",
            "label": "Poltava Airport , Poltava"
        },
        {
            "value": "PLW",
            "icao_code": "WAFF",
            "label": "Mutiara Airport , Palu"
        },
        {
            "value": "PLX",
            "icao_code": "UASS",
            "label": "Semey Airport , Semey"
        },
        {
            "value": "PLY",
            "icao_code": null,
            "label": "Plymouth Municipal Airport , Plymouth"
        },
        {
            "value": "PLZ",
            "icao_code": "FAPE",
            "label": "Port Elizabeth Airport , Port Elizabeth"
        },
        {
            "value": "PMA",
            "icao_code": "HTPE",
            "label": "Pemba Airport , Pemba Island"
        },
        {
            "value": "PMB",
            "icao_code": "KPMB",
            "label": "Pembina Municipal Airport , Pembina"
        },
        {
            "value": "PMC",
            "icao_code": "SCTE",
            "label": "El Tepual Airport , Puerto Montt"
        },
        {
            "value": "PMD",
            "icao_code": "KPMD",
            "label": "Palmdale Regional Airport , Palmdale"
        },
        {
            "value": "PMF",
            "icao_code": "LIMP",
            "label": "Parma Airport , Parma"
        },
        {
            "value": "PMG",
            "icao_code": "SBPP",
            "label": "Ponta Por\u00e3 International Airport , Ponta Por\u00e3"
        },
        {
            "value": "PMH",
            "icao_code": "KPMH",
            "label": "Greater Portsmouth Regional Airport , Portsmouth"
        },
        {
            "value": "PMI",
            "icao_code": "LEPA",
            "label": "Palma de Mallorca Airport , Palma de Mallorca"
        },
        {
            "value": "PMK",
            "icao_code": "YPAM",
            "label": "Palm Island Airport , Palm Island"
        },
        {
            "value": "PML",
            "icao_code": "PAAL",
            "label": "Port Moller Airport , Cold Bay"
        },
        {
            "value": "PMN",
            "icao_code": null,
            "label": "Pumani Airport , Pumani"
        },
        {
            "value": "PMO",
            "icao_code": "LICJ",
            "label": "Falcone\u2013Borsellino Airport , Palermo"
        },
        {
            "value": "PMP",
            "icao_code": null,
            "label": "Pimaga Airport , Pimaga"
        },
        {
            "value": "PMQ",
            "icao_code": "SAWP",
            "label": "Perito Moreno Airport , Perito Moreno"
        },
        {
            "value": "PMR",
            "icao_code": "NZPM",
            "label": "Palmerston North Airport , Palmerston North"
        },
        {
            "value": "PMS",
            "icao_code": "OSPR",
            "label": "Palmyra Airport , Palmyra"
        },
        {
            "value": "PMT",
            "icao_code": null,
            "label": "Paramakatoi Airport , Paramakatoi"
        },
        {
            "value": "PMV",
            "icao_code": "SVMG",
            "label": "Santiago Mari\u00f1o Caribbean International Airport , Porlamar"
        },
        {
            "value": "PMW",
            "icao_code": "SBPJ",
            "label": "Palmas\u2013Brigadeiro Lysias Rodrigues Airport , Palmas"
        },
        {
            "value": "PMX",
            "icao_code": null,
            "label": "Metropolitan Airport , Palmer"
        },
        {
            "value": "PMY",
            "icao_code": "SAVY",
            "label": "El Tehuelche Airport , Puerto Madryn"
        },
        {
            "value": "PMZ",
            "icao_code": "MRPM",
            "label": "Palmar Sur Airport , Palmar Sur"
        },
        {
            "value": "PNA",
            "icao_code": "LEPP",
            "label": "Pamplona Airport , Pamplona"
        },
        {
            "value": "PNB",
            "icao_code": "SBPN",
            "label": "Porto Nacional Airport , Porto Nacional"
        },
        {
            "value": "PNC",
            "icao_code": "KPNC",
            "label": "Ponca City Regional Airport , Ponca City"
        },
        {
            "value": "PND",
            "icao_code": "MZPG",
            "label": "Punta Gorda Airport , Punta Gorda"
        },
        {
            "value": "PNE",
            "icao_code": "KPNE",
            "label": "Northeast Philadelphia Airport , Philadelphia"
        },
        {
            "value": "PNG",
            "icao_code": "SSPG",
            "label": "Santos Dumont Airport , Paranagu\u00e1"
        },
        {
            "value": "PNH",
            "icao_code": "VDPP",
            "label": "Phnom Penh International Airport , Phnom Penh"
        },
        {
            "value": "PNI",
            "icao_code": "PTPN",
            "label": "Pohnpei International Airport , Pohnpei"
        },
        {
            "value": "PNJ",
            "icao_code": null,
            "label": "Penglai Shahekou Airport , Penglai"
        },
        {
            "value": "PNK",
            "icao_code": "WIOO",
            "label": "Supadio Airport , Pontianak"
        },
        {
            "value": "PNL",
            "icao_code": "LICG",
            "label": "Pantelleria Airport , Pantelleria"
        },
        {
            "value": "PNN",
            "icao_code": "KPNN",
            "label": "Princeton Municipal Airport , Princeton"
        },
        {
            "value": "PNP",
            "icao_code": "AYGR",
            "label": "Girua Airport , Popondetta"
        },
        {
            "value": "PNQ",
            "icao_code": "VAPO",
            "label": "Pune Airport , Pune"
        },
        {
            "value": "PNR",
            "icao_code": "FCPP",
            "label": "Pointe Noire Airport , Pointe-Noire"
        },
        {
            "value": "PNS",
            "icao_code": "KPNS",
            "label": "Pensacola International Airport , Pensacola"
        },
        {
            "value": "PNT",
            "icao_code": "SCNT",
            "label": "Teniente Julio Gallardo Airport , Puerto Natales"
        },
        {
            "value": "PNU",
            "icao_code": null,
            "label": "Panguitch Municipal Airport , Panguitch"
        },
        {
            "value": "PNV",
            "icao_code": "EYPP",
            "label": "Panev\u0117\u017eys Air Base , Panev\u0117\u017eys"
        },
        {
            "value": "PNX",
            "icao_code": "KGYI",
            "label": "North Texas Regional Airport , Sherman"
        },
        {
            "value": "PNY",
            "icao_code": "VOTY",
            "label": "Puducherry Airport , Pondicherry"
        },
        {
            "value": "PNZ",
            "icao_code": "SBPL",
            "label": "Senador Nilo Coelho Airport , Petrolina"
        },
        {
            "value": "POA",
            "icao_code": "SBPA",
            "label": "Salgado Filho International Airport , Porto Alegre"
        },
        {
            "value": "POB",
            "icao_code": "KPOB",
            "label": "Pope Field , Fort Liberty"
        },
        {
            "value": "POC",
            "icao_code": "KPOC",
            "label": "Brackett Field , La Verne"
        },
        {
            "value": "POD",
            "icao_code": "GOSP",
            "label": "Podor Airport , Podor"
        },
        {
            "value": "POE",
            "icao_code": "KPOE",
            "label": "Polk Army Airfield , Fort Polk"
        },
        {
            "value": "POF",
            "icao_code": "KPOF",
            "label": "Poplar Bluff Municipal Airport , Poplar Bluff"
        },
        {
            "value": "POG",
            "icao_code": "FOOG",
            "label": "Port-Gentil International Airport , Port-Gentil"
        },
        {
            "value": "POH",
            "icao_code": "KPOH",
            "label": "Pocahontas Municipal Airport , Pocahontas"
        },
        {
            "value": "POI",
            "icao_code": "SLPO",
            "label": "Captain Nicolas Rojas Airport , Potos\u00ed"
        },
        {
            "value": "POJ",
            "icao_code": "SNPD",
            "label": "Pedro Pereira dos Santos Airport , Patos de Minas"
        },
        {
            "value": "POL",
            "icao_code": "FQPB",
            "label": "Pemba Airport , Pemba"
        },
        {
            "value": "POM",
            "icao_code": "AYPY",
            "label": "Jacksons International Airport , Port Moresby"
        },
        {
            "value": "PON",
            "icao_code": "MGPP",
            "label": "Popt\u00fan Airport , Popt\u00fan"
        },
        {
            "value": "POO",
            "icao_code": "SBPC",
            "label": "Po\u00e7os de Caldas Airport , Po\u00e7os de Caldas"
        },
        {
            "value": "POP",
            "icao_code": "MDPP",
            "label": "Gregorio Luper\u00f3n International Airport , San Felipe de Puerto Plata"
        },
        {
            "value": "POR",
            "icao_code": "EFPO",
            "label": "Pori Airport , Pori"
        },
        {
            "value": "POS",
            "icao_code": "TTPP",
            "label": "Piarco International Airport , Port of Spain"
        },
        {
            "value": "POT",
            "icao_code": "MKKJ",
            "label": "Ken Jones Aerodrome , Port Antonio"
        },
        {
            "value": "POU",
            "icao_code": "KPOU",
            "label": "Dutchess County Airport , Poughkeepsie"
        },
        {
            "value": "POV",
            "icao_code": "LZPW",
            "label": "Pre\u0161ov Air Base , Pre\u0161ov"
        },
        {
            "value": "POW",
            "icao_code": "LJPZ",
            "label": "Portoro\u017e Airport , Portoro\u017e"
        },
        {
            "value": "POX",
            "icao_code": "LFPT",
            "label": "Pontoise \u2013 Cormeilles Aerodrome , Paris"
        },
        {
            "value": "POY",
            "icao_code": "KPOY",
            "label": "Powell Municipal Airport , Powell"
        },
        {
            "value": "POZ",
            "icao_code": "EPPO",
            "label": "Pozna\u0144\u2013\u0141awica Airport , Pozna\u0144"
        },
        {
            "value": "PPA",
            "icao_code": "KPPA",
            "label": "Perry Lefors Field , Pampa"
        },
        {
            "value": "PPB",
            "icao_code": "SBDN",
            "label": "Presidente Prudente Airport , Presidente Prudente"
        },
        {
            "value": "PPC",
            "icao_code": "PAPR",
            "label": "Prospect Creek Airport , Prospect Creek"
        },
        {
            "value": "PPE",
            "icao_code": "MMPE",
            "label": "Puerto Pe\u00f1asco International Airport , Puerto Pe\u00f1asco"
        },
        {
            "value": "PPF",
            "icao_code": "KPPF",
            "label": "Tri-City Airport , Parsons"
        },
        {
            "value": "PPG",
            "icao_code": "NSTU",
            "label": "Pago Pago International Airport , Pago Pago"
        },
        {
            "value": "PPH",
            "icao_code": null,
            "label": "Parai-tepu\u00ed Airport , Parai-tepu\u00ed"
        },
        {
            "value": "PPI",
            "icao_code": "YPIR",
            "label": "Port Pirie Airport , Port Pirie"
        },
        {
            "value": "PPJ",
            "icao_code": "WIIG",
            "label": "Panjang Island Airport , Panjang Island"
        },
        {
            "value": "PPK",
            "icao_code": "UACP",
            "label": "Petropavl Airport , Petropavl"
        },
        {
            "value": "PPL",
            "icao_code": "VNPL",
            "label": "Phaplu Airport , Phaphlu"
        },
        {
            "value": "PPM",
            "icao_code": "KPMP",
            "label": "Pompano Beach Airpark , Pompano Beach"
        },
        {
            "value": "PPN",
            "icao_code": "SKPP",
            "label": "Guillermo Le\u00f3n Valencia Airport , Popay\u00e1n"
        },
        {
            "value": "PPP",
            "icao_code": "YBPN",
            "label": "Whitsunday Coast Airport , Proserpine"
        },
        {
            "value": "PPQ",
            "icao_code": "NZPP",
            "label": "Kapiti Coast Airport , Paraparaumu Beach"
        },
        {
            "value": "PPR",
            "icao_code": "WIDE",
            "label": "Tuanku Tambusai Airport , Pasir Pangaraian"
        },
        {
            "value": "PPS",
            "icao_code": "RPVP",
            "label": "Puerto Princesa International Airport , Puerto Princesa"
        },
        {
            "value": "PPT",
            "icao_code": "NTAA",
            "label": "Fa'a'\u0101 International Airport , Papeete"
        },
        {
            "value": "PPU",
            "icao_code": "VYPP",
            "label": "Papun Airport , Papun"
        },
        {
            "value": "PPV",
            "icao_code": null,
            "label": "Port Protection Seaplane Base , Port Protection"
        },
        {
            "value": "PPW",
            "icao_code": "EGEP",
            "label": "Papa Westray Airport , Papa Westray"
        },
        {
            "value": "PPX",
            "icao_code": null,
            "label": "Param Airport , Param"
        },
        {
            "value": "PPY",
            "icao_code": "SNZA",
            "label": "Pouso Alegre Airport , Pouso Alegre"
        },
        {
            "value": "PPZ",
            "icao_code": null,
            "label": "Puerto P\u00e1ez Airport , Puerto P\u00e1ez"
        },
        {
            "value": "PQC",
            "icao_code": "VVPQ",
            "label": "Phu Quoc International Airport , Phu Quoc Island"
        },
        {
            "value": "PQD",
            "icao_code": null,
            "label": "Batticaloa Seaplane Base , Batticaloa"
        },
        {
            "value": "PQI",
            "icao_code": "KPQI",
            "label": "Northern Maine Regional Airport at Presque Isle , Presque Isle"
        },
        {
            "value": "PQM",
            "icao_code": "MMPQ",
            "label": "Palenque International Airport , Palenque"
        },
        {
            "value": "PQQ",
            "icao_code": "YPMQ",
            "label": "Port Macquarie Airport , Port Macquarie"
        },
        {
            "value": "PQS",
            "icao_code": null,
            "label": "Pilot Station Airport , Pilot Station"
        },
        {
            "value": "PRA",
            "icao_code": "SAAP",
            "label": "General Justo Jos\u00e9 de Urquiza Airport , Paran\u00e1"
        },
        {
            "value": "PRB",
            "icao_code": "KPRB",
            "label": "Paso Robles Municipal Airport , Paso Robles"
        },
        {
            "value": "PRC",
            "icao_code": "KPRC",
            "label": "Ernest A. Love Field , Prescott"
        },
        {
            "value": "PRD",
            "icao_code": "YPDO",
            "label": "Pardoo Airport , Pardoo Station"
        },
        {
            "value": "PRE",
            "icao_code": null,
            "label": "Pore Airport , Pore"
        },
        {
            "value": "PRG",
            "icao_code": "LKPR",
            "label": "V\u00e1clav Havel Airport Prague , Prague"
        },
        {
            "value": "PRH",
            "icao_code": "VTCP",
            "label": "Phrae Airport , Phrae"
        },
        {
            "value": "PRI",
            "icao_code": "FSPP",
            "label": "Praslin Island Airport , Praslin Island"
        },
        {
            "value": "PRK",
            "icao_code": "FAPK",
            "label": "Prieska Airport , Prieska"
        },
        {
            "value": "PRM",
            "icao_code": "LPPM",
            "label": "Portim\u00e3o Airport , Portim\u00e3o"
        },
        {
            "value": "PRN",
            "icao_code": "BKPR",
            "label": "Pristina International Airport Adem Jashari , Pristina"
        },
        {
            "value": "PRO",
            "icao_code": "KPRO",
            "label": "Perry Municipal Airport , Perry"
        },
        {
            "value": "PRP",
            "icao_code": "LFKO",
            "label": "Propriano Airport , Propriano"
        },
        {
            "value": "PRQ",
            "icao_code": "SARS",
            "label": "Presidencia Roque S\u00e1enz Pe\u00f1a Airport , Presidencia Roque S\u00e1enz Pe\u00f1a"
        },
        {
            "value": "PRR",
            "icao_code": "SYPR",
            "label": "Paruima Airport , Paruima"
        },
        {
            "value": "PRS",
            "icao_code": "AGGP",
            "label": "Parasi Airport , Parasi"
        },
        {
            "value": "PRU",
            "icao_code": "VYPY",
            "label": "Pyay Airport , Pyay"
        },
        {
            "value": "PRV",
            "icao_code": "LKPO",
            "label": "P\u0159erov Airport , P\u0159erov"
        },
        {
            "value": "PRW",
            "icao_code": null,
            "label": "Prentice Airport , Prentice"
        },
        {
            "value": "PRX",
            "icao_code": "KPRX",
            "label": "Cox Field , Paris"
        },
        {
            "value": "PRY",
            "icao_code": "FAWB",
            "label": "Wonderboom Airport , Pretoria"
        },
        {
            "value": "PRZ",
            "icao_code": null,
            "label": "Prineville Airport , Prineville"
        },
        {
            "value": "PSA",
            "icao_code": "LIRP",
            "label": "Pisa International Airport , Pisa"
        },
        {
            "value": "PSB",
            "icao_code": "KPSB",
            "label": "Mid-State Regional Airport , Rush Township, Centre County"
        },
        {
            "value": "PSC",
            "icao_code": "KPSC",
            "label": "Tri-Cities Airport , Pasco"
        },
        {
            "value": "PSD",
            "icao_code": "HEPS",
            "label": "Port Said Airport , Port Said"
        },
        {
            "value": "PSE",
            "icao_code": "TJPS",
            "label": "Mercedita Airport , Ponce"
        },
        {
            "value": "PSF",
            "icao_code": "KPSF",
            "label": "Pittsfield Municipal Airport , Pittsfield"
        },
        {
            "value": "PSG",
            "icao_code": "PAPG",
            "label": "Petersburg James A. Johnson Airport , Petersburg"
        },
        {
            "value": "PSH",
            "icao_code": "EDXO",
            "label": "Sankt Peter-Ording Airport , Sankt Peter-Ording"
        },
        {
            "value": "PSI",
            "icao_code": "OPPI",
            "label": "Pasni Airport , Pasni"
        },
        {
            "value": "PSJ",
            "icao_code": "WAMP",
            "label": "Kasiguncu Airport , Poso"
        },
        {
            "value": "PSK",
            "icao_code": "KPSK",
            "label": "New River Valley Airport , Dublin"
        },
        {
            "value": "PSL",
            "icao_code": "EGPT",
            "label": "Perth Airport , Perth"
        },
        {
            "value": "PSM",
            "icao_code": "KPSM",
            "label": "Portsmouth International Airport at Pease , Portsmouth"
        },
        {
            "value": "PSN",
            "icao_code": "KPSN",
            "label": "Palestine Municipal Airport , Palestine"
        },
        {
            "value": "PSO",
            "icao_code": "SKPS",
            "label": "Antonio Nari\u00f1o Airport , Pasto"
        },
        {
            "value": "PSP",
            "icao_code": "KPSP",
            "label": "Palm Springs International Airport , Palm Springs"
        },
        {
            "value": "PSQ",
            "icao_code": null,
            "label": "Philadelphia Seaplane Base , Philadelphia"
        },
        {
            "value": "PSR",
            "icao_code": "LIBP",
            "label": "Abruzzo Airport , Pescara"
        },
        {
            "value": "PSS",
            "icao_code": "SARP",
            "label": "Libertador General Jos\u00e9 de San Mart\u00edn Airport , Posadas"
        },
        {
            "value": "PSU",
            "icao_code": "WIOP",
            "label": "Pangsuma Airport , Putussibau"
        },
        {
            "value": "PSV",
            "icao_code": null,
            "label": "Papa Stour Airstrip , Papa Stour"
        },
        {
            "value": "PSW",
            "icao_code": "SNOS",
            "label": "Passos Airport , Passos"
        },
        {
            "value": "PSX",
            "icao_code": "KPSX",
            "label": "Palacios Municipal Airport , Palacios"
        },
        {
            "value": "PSY",
            "icao_code": "SFAL",
            "label": "Port Stanley Airport , Stanley"
        },
        {
            "value": "PSZ",
            "icao_code": "SLPS",
            "label": "Puerto Su\u00e1rez International Airport , Puerto Su\u00e1rez"
        },
        {
            "value": "PTA",
            "icao_code": "PALJ",
            "label": "Port Alsworth Airport , Port Alsworth"
        },
        {
            "value": "PTB",
            "icao_code": "KPTB",
            "label": "Dinwiddie County Airport , Petersburg"
        },
        {
            "value": "PTC",
            "icao_code": null,
            "label": "Port Alice Seaplane Base , Port Alice"
        },
        {
            "value": "PTD",
            "icao_code": "PAAP",
            "label": "Port Alexander Seaplane Base , Port Alexander"
        },
        {
            "value": "PTF",
            "icao_code": "NFFO",
            "label": "Malolo Lailai Airport , Malolo Lailai"
        },
        {
            "value": "PTG",
            "icao_code": "FAPP",
            "label": "Polokwane International Airport , Polokwane"
        },
        {
            "value": "PTH",
            "icao_code": "PAPH",
            "label": "Port Heiden Airport , Port Heiden"
        },
        {
            "value": "PTJ",
            "icao_code": "YPOD",
            "label": "Portland Airport , Portland"
        },
        {
            "value": "PTK",
            "icao_code": "KPTK",
            "label": "Oakland County International Airport , Pontiac"
        },
        {
            "value": "PTM",
            "icao_code": "SVPT",
            "label": "Palmarito Airport , Palmarito"
        },
        {
            "value": "PTN",
            "icao_code": "KPTN",
            "label": "Harry P. Williams Memorial Airport , Patterson"
        },
        {
            "value": "PTO",
            "icao_code": "SSPB",
            "label": "Juvenal Loureiro Cardoso Airport , Pato Branco"
        },
        {
            "value": "PTP",
            "icao_code": "TFFR",
            "label": "Pointe-\u00e0-Pitre International Airport , Pointe-\u00e0-Pitre"
        },
        {
            "value": "PTQ",
            "icao_code": "SNMZ",
            "label": "Porto de Moz Airport , Porto de Moz"
        },
        {
            "value": "PTS",
            "icao_code": "KPTS",
            "label": "Atkinson Municipal Airport , Pittsburg"
        },
        {
            "value": "PTT",
            "icao_code": "KPTT",
            "label": "Pratt Regional Airport , Pratt"
        },
        {
            "value": "PTU",
            "icao_code": "PAPM",
            "label": "Platinum Airport , Platinum"
        },
        {
            "value": "PTV",
            "icao_code": "KPTV",
            "label": "Porterville Municipal Airport , Porterville"
        },
        {
            "value": "PTW",
            "icao_code": "KPTW",
            "label": "Heritage Field , Pottstown"
        },
        {
            "value": "PTX",
            "icao_code": "SKPI",
            "label": "Contador Airport , Pitalito"
        },
        {
            "value": "PTY",
            "icao_code": "MPTO",
            "label": "Tocumen International Airport , Panama City"
        },
        {
            "value": "PTZ",
            "icao_code": "SEPA",
            "label": "R\u00edo Amazonas Airport , Shell"
        },
        {
            "value": "PUA",
            "icao_code": null,
            "label": "Puas Airport , Puas"
        },
        {
            "value": "PUB",
            "icao_code": "KPUB",
            "label": "Pueblo Memorial Airport , Pueblo"
        },
        {
            "value": "PUC",
            "icao_code": "KPUC",
            "label": "Carbon County Regional Airport , Price"
        },
        {
            "value": "PUD",
            "icao_code": "SAWD",
            "label": "Puerto Deseado Airport , Puerto Deseado"
        },
        {
            "value": "PUE",
            "icao_code": "MPOA",
            "label": "Puerto Obaldia Airport , Puerto Obald\u00eda"
        },
        {
            "value": "PUF",
            "icao_code": "LFBP",
            "label": "Pau Pyr\u00e9n\u00e9es Airport , Pau"
        },
        {
            "value": "PUG",
            "icao_code": "YPAG",
            "label": "Port Augusta Airport , Port Augusta"
        },
        {
            "value": "PUI",
            "icao_code": null,
            "label": "Pureni Airport , Pureni"
        },
        {
            "value": "PUJ",
            "icao_code": "MDPC",
            "label": "Punta Cana International Airport , Punta Cana"
        },
        {
            "value": "PUK",
            "icao_code": "NTGQ",
            "label": "Pukarua Airport , Pukarua"
        },
        {
            "value": "PUL",
            "icao_code": null,
            "label": "Port of Poulsbo Marina Moorage Seaplane Base , Poulsbo"
        },
        {
            "value": "PUM",
            "icao_code": "WAWP",
            "label": "Sangia Nibandera Airport , Kolaka"
        },
        {
            "value": "PUN",
            "icao_code": "FZOP",
            "label": "Punia Airport , Punia"
        },
        {
            "value": "PUP",
            "icao_code": "DFCP",
            "label": "P\u00f4 Airport , P\u00f4"
        },
        {
            "value": "PUQ",
            "icao_code": "SCCI",
            "label": "Presidente Carlos Ib\u00e1\u00f1ez del Campo International Airport , Punta Arenas"
        },
        {
            "value": "PUR",
            "icao_code": "SLPR",
            "label": "Puerto Rico Airport , Puerto Rico"
        },
        {
            "value": "PUS",
            "icao_code": "RKPK",
            "label": "Gimhae International Airport , Busan"
        },
        {
            "value": "PUT",
            "icao_code": "VOPN",
            "label": "Sri Sathya Sai Airport , Puttaparthi"
        },
        {
            "value": "PUU",
            "icao_code": "SKAS",
            "label": "Tres de Mayo Airport , Puerto As\u00eds"
        },
        {
            "value": "PUV",
            "icao_code": "NWWP",
            "label": "Malabou Airport , Poum"
        },
        {
            "value": "PUW",
            "icao_code": "KPUW",
            "label": "Pullman\u2013Moscow Regional Airport , Pullman"
        },
        {
            "value": "PUX",
            "icao_code": "SCPV",
            "label": "El Mirador Airport , Puerto Varas"
        },
        {
            "value": "PUY",
            "icao_code": "LDPL",
            "label": "Pula Airport , Pula"
        },
        {
            "value": "PUZ",
            "icao_code": "MNPC",
            "label": "Puerto Cabezas Airport , Puerto Cabezas"
        },
        {
            "value": "PVA",
            "icao_code": "SKPV",
            "label": "El Embrujo Airport , Providencia Island"
        },
        {
            "value": "PVC",
            "icao_code": "KPVC",
            "label": "Provincetown Municipal Airport , Provincetown"
        },
        {
            "value": "PVD",
            "icao_code": "KPVD",
            "label": "Theodore Francis Green State Airport , Providence"
        },
        {
            "value": "PVE",
            "icao_code": "MPVR",
            "label": "El Porvenir Airport , El Porvenir"
        },
        {
            "value": "PVF",
            "icao_code": "KPVF",
            "label": "Placerville Airport , Placerville"
        },
        {
            "value": "PVG",
            "icao_code": "ZSPD",
            "label": "Shanghai Pudong International Airport , Shanghai"
        },
        {
            "value": "PVH",
            "icao_code": "SBPV",
            "label": "Governador Jorge Teixeira de Oliveira International Airport , Porto Velho"
        },
        {
            "value": "PVI",
            "icao_code": "SSPI",
            "label": "Edu Chaves Airport , Paranava\u00ed"
        },
        {
            "value": "PVK",
            "icao_code": "LGPZ",
            "label": "Aktion National Airport , Preveza"
        },
        {
            "value": "PVL",
            "icao_code": "KPBX",
            "label": "Pike County Airport , Pikeville"
        },
        {
            "value": "PVO",
            "icao_code": "SEPV",
            "label": "Reales Tamarindos Airport , Portoviejo"
        },
        {
            "value": "PVR",
            "icao_code": "MMPR",
            "label": "Licenciado Gustavo D\u00edaz Ordaz International Airport , Puerto Vallarta"
        },
        {
            "value": "PVS",
            "icao_code": "UHMD",
            "label": "Provideniya Bay Airport , Provideniya"
        },
        {
            "value": "PVU",
            "icao_code": "KPVU",
            "label": "Provo Municipal Airport , Provo"
        },
        {
            "value": "PVW",
            "icao_code": "KPVW",
            "label": "Hale County Airport , Plainview"
        },
        {
            "value": "PWA",
            "icao_code": "KPWA",
            "label": "Wiley Post Airport , Oklahoma City"
        },
        {
            "value": "PWD",
            "icao_code": "KPWD",
            "label": "Sher-Wood Airport , Plentywood"
        },
        {
            "value": "PWE",
            "icao_code": "UHMP",
            "label": "Pevek Airport , Pevek"
        },
        {
            "value": "PWI",
            "icao_code": null,
            "label": "Beles Airport , Pawi"
        },
        {
            "value": "PWK",
            "icao_code": "KPWK",
            "label": "Chicago Executive Airport , Chicago"
        },
        {
            "value": "PWL",
            "icao_code": "WICP",
            "label": "Wirasaba Airport , Purwokerto"
        },
        {
            "value": "PWM",
            "icao_code": "KPWM",
            "label": "Portland International Jetport , Portland"
        },
        {
            "value": "PWN",
            "icao_code": "MYCP",
            "label": "Pitts Town Airport , Pitts Town"
        },
        {
            "value": "PWO",
            "icao_code": "FZQC",
            "label": "Pweto Airport , Pweto"
        },
        {
            "value": "PWQ",
            "icao_code": "UASP",
            "label": "Pavlodar Airport , Pavlodar"
        },
        {
            "value": "PWR",
            "icao_code": null,
            "label": "Port Walter Seaplane Base , Port Walter"
        },
        {
            "value": "PWT",
            "icao_code": "KPWT",
            "label": "Bremerton National Airport , Bremerton"
        },
        {
            "value": "PWY",
            "icao_code": "KPNA",
            "label": "Ralph Wenz Field , Pinedale"
        },
        {
            "value": "PXA",
            "icao_code": "WIPY",
            "label": "Atung Bungsu Airport , Pagar Alam"
        },
        {
            "value": "PXH",
            "icao_code": "YPMH",
            "label": "Prominent Hill Airport , Prominent Hill"
        },
        {
            "value": "PXL",
            "icao_code": null,
            "label": "Polacca Airport , Polacca"
        },
        {
            "value": "PXM",
            "icao_code": "MMPS",
            "label": "Puerto Escondido International Airport , Puerto Escondido"
        },
        {
            "value": "PXO",
            "icao_code": "LPPS",
            "label": "Porto Santo Airport , Porto Santo Island"
        },
        {
            "value": "PXR",
            "icao_code": "VTUJ",
            "label": "Surin Airport , Surin"
        },
        {
            "value": "PXU",
            "icao_code": "VVPK",
            "label": "Pleiku Airport , Pleiku"
        },
        {
            "value": "PYA",
            "icao_code": "SKVL",
            "label": "Velasquez Airport , Puerto Boyac\u00e1"
        },
        {
            "value": "PYB",
            "icao_code": "VEJP",
            "label": "Jeypore Airport , Jeypore"
        },
        {
            "value": "PYC",
            "icao_code": null,
            "label": "Play\u00f3n Chico Airport , Ukupseni"
        },
        {
            "value": "PYE",
            "icao_code": "NCPY",
            "label": "Tongareva Airport , Penrhyn Atoll"
        },
        {
            "value": "PYG",
            "icao_code": null,
            "label": "Pakyong Airport , Pakyong"
        },
        {
            "value": "PYH",
            "icao_code": "SVPA",
            "label": "Cacique Aramare Airport , Puerto Ayacucho"
        },
        {
            "value": "PYJ",
            "icao_code": "UERP",
            "label": "Polyarny Airport , Polyarny"
        },
        {
            "value": "PYK",
            "icao_code": "OIIP",
            "label": "Payam International Airport , Karaj"
        },
        {
            "value": "PYL",
            "icao_code": null,
            "label": "Perry Island Seaplane Base , Perry Island"
        },
        {
            "value": "PYM",
            "icao_code": "KPYM",
            "label": "Plymouth Municipal Airport , Plymouth"
        },
        {
            "value": "PYN",
            "icao_code": null,
            "label": "Pay\u00e1n Airport , Pay\u00e1n"
        },
        {
            "value": "PYO",
            "icao_code": "SEPT",
            "label": "Putumayo Airport , Puerto El Carmen de Putumayo"
        },
        {
            "value": "PYR",
            "icao_code": "LGAD",
            "label": "Andravida Air Base , Pyrgos"
        },
        {
            "value": "PYS",
            "icao_code": null,
            "label": "Paradise Skypark , Paradise"
        },
        {
            "value": "PYV",
            "icao_code": null,
            "label": "Yaviza Airport , Yaviza"
        },
        {
            "value": "PYY",
            "icao_code": null,
            "label": "Pai Airport , Pai"
        },
        {
            "value": "PZA",
            "icao_code": "SKPZ",
            "label": "Paz de Ariporo Airport , Paz de Ariporo"
        },
        {
            "value": "PZB",
            "icao_code": "FAPM",
            "label": "Pietermaritzburg Airport , Pietermaritzburg"
        },
        {
            "value": "PZH",
            "icao_code": "OPZB",
            "label": "Zhob Airport , Zhob"
        },
        {
            "value": "PZI",
            "icao_code": "ZUZH",
            "label": "Panzhihua Bao'anying Airport , Panzhihua"
        },
        {
            "value": "PZK",
            "icao_code": "NCPK",
            "label": "Pukapuka Island Airfield , Pukapuka Island"
        },
        {
            "value": "PZL",
            "icao_code": "FADQ",
            "label": "Zulu Inyala Airport , Phinda"
        },
        {
            "value": "PZO",
            "icao_code": "SVPR",
            "label": "Manuel Carlos Piar Guayana Airport , Ciudad Guayana"
        },
        {
            "value": "PZS",
            "icao_code": "SCTC",
            "label": "Maquehue Airport , Temuco"
        },
        {
            "value": "PZU",
            "icao_code": "HSPN",
            "label": "Port Sudan New International Airport , Port Sudan"
        },
        {
            "value": "PZY",
            "icao_code": "LZPP",
            "label": "Pie\u0161\u0165any Airport , Pie\u0161\u0165any"
        },
        {
            "value": "QAH",
            "icao_code": "VIDX",
            "label": "Hindon Airport , Delhi"
        },
        {
            "value": "QAQ",
            "icao_code": "LIAP",
            "label": "L'Aquila\u2013Preturo Airport , L'Aquila"
        },
        {
            "value": "QBC",
            "icao_code": "CYBD",
            "label": "Bella Coola Airport , British Columbia"
        },
        {
            "value": "QCY",
            "icao_code": "EGXC",
            "label": "RAF Coningsby , Coningsby"
        },
        {
            "value": "QHU",
            "icao_code": "EDXJ",
            "label": "Husum Schwesing Airport , Husum"
        },
        {
            "value": "QLR",
            "icao_code": "LPMR",
            "label": "Monte Real Air Base , Monte Real"
        },
        {
            "value": "QOW",
            "icao_code": "DNIM",
            "label": "Sam Mbakwe Airport , Owerri"
        },
        {
            "value": "QPG",
            "icao_code": "WSAP",
            "label": "Paya Lebar Air Base , Singapore"
        },
        {
            "value": "QRA",
            "icao_code": "FAGM",
            "label": "Rand Airport , Johannesburg"
        },
        {
            "value": "QRC",
            "icao_code": "SCRG",
            "label": "Rancagua de la Independencia Airport , Rancagua"
        },
        {
            "value": "QRO",
            "icao_code": "MMQT",
            "label": "Quer\u00e9taro Intercontinental Airport , Quer\u00e9taro City"
        },
        {
            "value": "QRW",
            "icao_code": "DNSU",
            "label": "Warri Airport , Warri"
        },
        {
            "value": "QSC",
            "icao_code": "SDSC",
            "label": "M\u00e1rio Pereira Lopes Airport , S\u00e3o Carlos"
        },
        {
            "value": "QSF",
            "icao_code": "DAAS",
            "label": "Ain Arnat Airport , S\u00e9tif"
        },
        {
            "value": "QSR",
            "icao_code": "LIRI",
            "label": "Salerno Costa d'Amalfi Airport , Salerno"
        },
        {
            "value": "QSZ",
            "icao_code": "ZWSC",
            "label": "Shache Airport , Shache"
        },
        {
            "value": "QUB",
            "icao_code": "HLUB",
            "label": "Ubari Airport , Ubari"
        },
        {
            "value": "QUG",
            "icao_code": "EGHR",
            "label": "Chichester/Goodwood Airport , Chichester"
        },
        {
            "value": "QUO",
            "icao_code": "DNAI",
            "label": "Akwa Ibom International Airport , Uyo"
        },
        {
            "value": "QUY",
            "icao_code": "EGUY",
            "label": "RAF Wyton , Wyton"
        },
        {
            "value": "QWG",
            "icao_code": null,
            "label": "Wilgrove Air Park , Charlotte"
        },
        {
            "value": "QXB",
            "icao_code": "LFMA",
            "label": "Aix-en-Provence Aerodrome , Aix-en-Provence"
        },
        {
            "value": "RAA",
            "icao_code": null,
            "label": "Rakanda Airport , Rakanda"
        },
        {
            "value": "RAB",
            "icao_code": "AYTK",
            "label": "Rabaul Airport , Rabaul"
        },
        {
            "value": "RAC",
            "icao_code": "KRAC",
            "label": "John H. Batten Airport , Racine"
        },
        {
            "value": "RAD",
            "icao_code": null,
            "label": "Road Town Seaplane Base , Tortola"
        },
        {
            "value": "RAE",
            "icao_code": "OERR",
            "label": "Arar Domestic Airport , Arar"
        },
        {
            "value": "RAF",
            "icao_code": "SAFR",
            "label": "Rafaela Airport , Rafaela"
        },
        {
            "value": "RAG",
            "icao_code": "NZRA",
            "label": "Raglan Aerodrome , Raglan"
        },
        {
            "value": "RAH",
            "icao_code": "OERF",
            "label": "Rafha Domestic Airport , Rafha"
        },
        {
            "value": "RAI",
            "icao_code": "GVNP",
            "label": "Nelson Mandela International Airport , Praia"
        },
        {
            "value": "RAJ",
            "icao_code": "VARK",
            "label": "Rajkot Airport , Rajkot"
        },
        {
            "value": "RAK",
            "icao_code": "GMMX",
            "label": "Marrakesh Menara Airport , Marrakesh"
        },
        {
            "value": "RAL",
            "icao_code": "KRAL",
            "label": "Riverside Municipal Airport , Riverside"
        },
        {
            "value": "RAM",
            "icao_code": "YRNG",
            "label": "Ramingining Airport , Ramingining"
        },
        {
            "value": "RAN",
            "icao_code": "LIDR",
            "label": "Ravenna Airport , Ravenna"
        },
        {
            "value": "RAO",
            "icao_code": "SBRP",
            "label": "Leite Lopes Airport , Ribeir\u00e3o Preto"
        },
        {
            "value": "RAP",
            "icao_code": "KRAP",
            "label": "Rapid City Regional Airport , Rapid City"
        },
        {
            "value": "RAQ",
            "icao_code": "WAWR",
            "label": "Sugimanuru Airport , Muna Island"
        },
        {
            "value": "RAR",
            "icao_code": "NCRG",
            "label": "Rarotonga International Airport , Avarua"
        },
        {
            "value": "RAS",
            "icao_code": "OIGG",
            "label": "Rasht Airport , Rasht"
        },
        {
            "value": "RAT",
            "icao_code": "USNR",
            "label": "Raduzhny Airport , Raduzhny"
        },
        {
            "value": "RAV",
            "icao_code": "SKCN",
            "label": "Cravo Norte Airport , Cravo Norte"
        },
        {
            "value": "RAW",
            "icao_code": null,
            "label": "Arawa Airport , Arawa"
        },
        {
            "value": "RAX",
            "icao_code": null,
            "label": "Oram Airport , Oram"
        },
        {
            "value": "RAZ",
            "icao_code": "OPRT",
            "label": "Rawalakot Airport , Rawalakot"
        },
        {
            "value": "RBA",
            "icao_code": "GMME",
            "label": "Rabat\u2013Sal\u00e9 Airport , Rabat"
        },
        {
            "value": "RBB",
            "icao_code": "SWBR",
            "label": "Borba Airport , Borba"
        },
        {
            "value": "RBC",
            "icao_code": "YROI",
            "label": "Robinvale Airport , Robinvale"
        },
        {
            "value": "RBD",
            "icao_code": "KRBD",
            "label": "Dallas Executive Airport , Dallas"
        },
        {
            "value": "RBE",
            "icao_code": "VDRK",
            "label": "Ratanakiri Airport , Ratanakiri"
        },
        {
            "value": "RBF",
            "icao_code": null,
            "label": "Big Bear City Airport , Big Bear City"
        },
        {
            "value": "RBG",
            "icao_code": "KRBG",
            "label": "Roseburg Regional Airport , Roseburg"
        },
        {
            "value": "RBI",
            "icao_code": "NFFR",
            "label": "Rabi Airport , Rabi Island"
        },
        {
            "value": "RBJ",
            "icao_code": "RJCR",
            "label": "Rebun Airport , Rebun"
        },
        {
            "value": "RBK",
            "icao_code": null,
            "label": "French Valley Airport , Murrieta"
        },
        {
            "value": "RBL",
            "icao_code": "KRBL",
            "label": "Red Bluff Municipal Airport , Red Bluff"
        },
        {
            "value": "RBM",
            "icao_code": "EDMS",
            "label": "Straubing Wallm\u00fchle Airport , Straubing"
        },
        {
            "value": "RBO",
            "icao_code": "SLRB",
            "label": "Robor\u00e9 Airport , Robor\u00e9"
        },
        {
            "value": "RBP",
            "icao_code": null,
            "label": "Rabaraba Airport , Rabaraba"
        },
        {
            "value": "RBQ",
            "icao_code": "SLRQ",
            "label": "Rurrenabaque Airport , Rurrenabaque"
        },
        {
            "value": "RBR",
            "icao_code": "SBRB",
            "label": "Pl\u00e1cido de Castro International Airport , Rio Branco"
        },
        {
            "value": "RBS",
            "icao_code": "YORB",
            "label": "Orbost Airport , Orbost"
        },
        {
            "value": "RBT",
            "icao_code": "HKMB",
            "label": "Marsabit Airport , Marsabit"
        },
        {
            "value": "RBU",
            "icao_code": "YROE",
            "label": "Roebourne Airport , Roebourne"
        },
        {
            "value": "RBV",
            "icao_code": "AGRM",
            "label": "Ramata Airport , Ramata Island"
        },
        {
            "value": "RBW",
            "icao_code": "KRBW",
            "label": "Lowcountry Regional Airport , Walterboro"
        },
        {
            "value": "RBX",
            "icao_code": "HSMK",
            "label": "Rumbek Airport , Rumbek"
        },
        {
            "value": "RBY",
            "icao_code": "PARY",
            "label": "Ruby Airport , Ruby"
        },
        {
            "value": "RCA",
            "icao_code": "KRCA",
            "label": "Ellsworth Air Force Base , Rapid City"
        },
        {
            "value": "RCB",
            "icao_code": "FARB",
            "label": "Richards Bay Airport , Richards Bay"
        },
        {
            "value": "RCE",
            "icao_code": null,
            "label": "Roche Harbor Seaplane Base , Roche Harbor"
        },
        {
            "value": "RCH",
            "icao_code": "SKRH",
            "label": "Almirante Padilla Airport , Riohacha"
        },
        {
            "value": "RCK",
            "icao_code": "KRCK",
            "label": "H. H. Coffield Regional Airport , Rockdale"
        },
        {
            "value": "RCL",
            "icao_code": "NVSR",
            "label": "Redcliffe Airport , Redcliffe"
        },
        {
            "value": "RCM",
            "icao_code": "YRMD",
            "label": "Richmond Airport , Richmond"
        },
        {
            "value": "RCN",
            "icao_code": null,
            "label": "American River Airport , American River"
        },
        {
            "value": "RCO",
            "icao_code": "LFDN",
            "label": "Rochefort \u2013 Saint-Agnant Airport , Rochefort"
        },
        {
            "value": "RCQ",
            "icao_code": "SATR",
            "label": "Reconquista Airport , Reconquista"
        },
        {
            "value": "RCR",
            "icao_code": "KRCR",
            "label": "Fulton County Airport , Rochester"
        },
        {
            "value": "RCS",
            "icao_code": "EGTO",
            "label": "Rochester Airport , Rochester"
        },
        {
            "value": "RCT",
            "icao_code": "KRCT",
            "label": "Nartron Field , Reed City"
        },
        {
            "value": "RCU",
            "icao_code": "SAOC",
            "label": "Las Higueras Airport , R\u00edo Cuarto"
        },
        {
            "value": "RCY",
            "icao_code": "MYRP",
            "label": "Port Nelson Airport , Port Nelson"
        },
        {
            "value": "RDA",
            "icao_code": "YRKD",
            "label": "Rockhampton Downs Airport , Rockhampton Downs"
        },
        {
            "value": "RDB",
            "icao_code": "PADG",
            "label": "Red Dog Airport , Red Dog"
        },
        {
            "value": "RDC",
            "icao_code": "SNDC",
            "label": "Reden\u00e7\u00e3o Airport , Reden\u00e7\u00e3o"
        },
        {
            "value": "RDD",
            "icao_code": "KRDD",
            "label": "Redding Municipal Airport , Redding"
        },
        {
            "value": "RDE",
            "icao_code": null,
            "label": "Merdey Airport , Merdey"
        },
        {
            "value": "RDG",
            "icao_code": "KRDG",
            "label": "Reading Regional Airport , Reading"
        },
        {
            "value": "RDM",
            "icao_code": "KRDM",
            "label": "Roberts Field , Redmond"
        },
        {
            "value": "RDN",
            "icao_code": "WMPR",
            "label": "Redang Airport , Redang Island"
        },
        {
            "value": "RDO",
            "icao_code": "EPRA",
            "label": "Radom Airport , Radom"
        },
        {
            "value": "RDP",
            "icao_code": "VEDG",
            "label": "Kazi Nazrul Islam Airport , Durgapur"
        },
        {
            "value": "RDR",
            "icao_code": "KRDR",
            "label": "Grand Forks Air Force Base , Grand Forks"
        },
        {
            "value": "RDS",
            "icao_code": "SAHS",
            "label": "Rinc\u00f3n de los Sauces Airport , Rinc\u00f3n de los Sauces"
        },
        {
            "value": "RDT",
            "icao_code": "GOSR",
            "label": "Richard Toll Airport , Richard Toll"
        },
        {
            "value": "RDU",
            "icao_code": "KRDU",
            "label": "Raleigh\u2013Durham International Airport , Raleigh"
        },
        {
            "value": "RDV",
            "icao_code": null,
            "label": "Red Devil Airport , Red Devil"
        },
        {
            "value": "RDZ",
            "icao_code": "LFCR",
            "label": "Rodez\u2013Marcillac Airport , Rodez"
        },
        {
            "value": "REA",
            "icao_code": "NTGE",
            "label": "Reao Airport , Reao"
        },
        {
            "value": "REB",
            "icao_code": "EDAX",
            "label": "Rechlin\u2013L\u00e4rz Airfield , Rechlin"
        },
        {
            "value": "REC",
            "icao_code": "SBRF",
            "label": "Recife/Guararapes\u2013Gilberto Freyre International Airport , Recife"
        },
        {
            "value": "RED",
            "icao_code": "KRVL",
            "label": "Mifflin County Airport , Reedsville"
        },
        {
            "value": "REE",
            "icao_code": null,
            "label": "Reese Airpark , Lubbock"
        },
        {
            "value": "REG",
            "icao_code": "LICR",
            "label": "Reggio di Calabria Airport , Reggio di Calabria"
        },
        {
            "value": "REI",
            "icao_code": "SOOR",
            "label": "R\u00e9gina Airport , R\u00e9gina"
        },
        {
            "value": "REK",
            "icao_code": null,
            "label": "metropolitan area , Reykjav\u00edk"
        },
        {
            "value": "REL",
            "icao_code": "SAVT",
            "label": "Almirante Marcos A. Zar Airport , Trelew"
        },
        {
            "value": "REN",
            "icao_code": "UWOO",
            "label": "Orenburg Tsentralny Airport , Orenburg"
        },
        {
            "value": "REO",
            "icao_code": "KREO",
            "label": "Rome State Airport , Rome"
        },
        {
            "value": "REP",
            "icao_code": "VDSR",
            "label": "Siem Reap International Airport , Siem Reap"
        },
        {
            "value": "REQ",
            "icao_code": null,
            "label": "Reko Diq Airport , Reko Diq"
        },
        {
            "value": "RER",
            "icao_code": "MGRT",
            "label": "Retalhuleu Airport , Retalhuleu"
        },
        {
            "value": "RES",
            "icao_code": "SARE",
            "label": "Resistencia International Airport , Resistencia"
        },
        {
            "value": "RET",
            "icao_code": "ENRS",
            "label": "R\u00f8st Airport , R\u00f8st"
        },
        {
            "value": "REU",
            "icao_code": "LERS",
            "label": "Reus Airport , Reus"
        },
        {
            "value": "REW",
            "icao_code": null,
            "label": "Churhata Airport , Rewa"
        },
        {
            "value": "REX",
            "icao_code": "MMRX",
            "label": "General Lucio Blanco International Airport , Reynosa"
        },
        {
            "value": "REY",
            "icao_code": "SLRY",
            "label": "Reyes Airport , Reyes"
        },
        {
            "value": "REZ",
            "icao_code": "SDRS",
            "label": "Resende Airport , Resende"
        },
        {
            "value": "RFA",
            "icao_code": "FEGR",
            "label": "Rafa\u00ef Airport , Rafa\u00ef"
        },
        {
            "value": "RFD",
            "icao_code": "KRFD",
            "label": "Chicago Rockford International Airport , Rockford"
        },
        {
            "value": "RFG",
            "icao_code": "KRFG",
            "label": "Rooke Field , Refugio"
        },
        {
            "value": "RFK",
            "icao_code": null,
            "label": "Rollang Field , Rolling Fork"
        },
        {
            "value": "RFN",
            "icao_code": "BIRG",
            "label": "Raufarh\u00f6fn Airport , Raufarh\u00f6fn"
        },
        {
            "value": "RFP",
            "icao_code": "NTTR",
            "label": "Raiatea Airport , Raiatea"
        },
        {
            "value": "RFR",
            "icao_code": "MRRF",
            "label": "R\u00edo Fr\u00edo Airport , R\u00edo Fr\u00edo"
        },
        {
            "value": "RFS",
            "icao_code": "MNRT",
            "label": "Rosita Airport , Rosita"
        },
        {
            "value": "RGA",
            "icao_code": "SAWE",
            "label": "Hermes Quijada International Airport , R\u00edo Grande"
        },
        {
            "value": "RGE",
            "icao_code": null,
            "label": "Porgera Airport , Porgera"
        },
        {
            "value": "RGH",
            "icao_code": "VEBG",
            "label": "Balurghat Airport , Balurghat"
        },
        {
            "value": "RGI",
            "icao_code": "NTTG",
            "label": "Rangiroa Airport , Rangiroa"
        },
        {
            "value": "RGK",
            "icao_code": "UNBG",
            "label": "Gorno-Altaysk Airport , Gorno-Altaysk"
        },
        {
            "value": "RGL",
            "icao_code": "SAWG",
            "label": "Piloto Civil Norberto Fern\u00e1ndez International Airport , R\u00edo Gallegos"
        },
        {
            "value": "RGN",
            "icao_code": "VYYY",
            "label": "Yangon International Airport , Yangon"
        },
        {
            "value": "RGO",
            "icao_code": "ZKHM",
            "label": "Chongjin Airport , Chongjin"
        },
        {
            "value": "RGR",
            "icao_code": null,
            "label": "Ranger Municipal Airport , Ranger"
        },
        {
            "value": "RGS",
            "icao_code": "LEBG",
            "label": "Burgos Airport , Burgos"
        },
        {
            "value": "RGT",
            "icao_code": "WIPR",
            "label": "Japura Airport , Rengat"
        },
        {
            "value": "RHA",
            "icao_code": "BIRE",
            "label": "Reykh\u00f3lar Airport , Reykh\u00f3lar"
        },
        {
            "value": "RHD",
            "icao_code": "SANR",
            "label": "Termas de R\u00edo Hondo Airport , Termas de R\u00edo Hondo"
        },
        {
            "value": "RHE",
            "icao_code": "LFSR",
            "label": "Reims \u2013 Champagne Air Base , Reims"
        },
        {
            "value": "RHG",
            "icao_code": "HRYU",
            "label": "Ruhengeri Airport , Ruhengeri"
        },
        {
            "value": "RHI",
            "icao_code": "KRHI",
            "label": "Rhinelander\u2013Oneida County Airport , Rhinelander"
        },
        {
            "value": "RHL",
            "icao_code": "YRYH",
            "label": "Roy Hill Station Airport , Roy Hill Station"
        },
        {
            "value": "RHN",
            "icao_code": "FYSA",
            "label": "Skorpion Mine Airport , Rosh Pinah"
        },
        {
            "value": "RHO",
            "icao_code": "LGRP",
            "label": "Rhodes International Airport , Rhodes"
        },
        {
            "value": "RHP",
            "icao_code": "VNRC",
            "label": "Ramechhap Airport , Ramechhap"
        },
        {
            "value": "RHR",
            "icao_code": null,
            "label": "Al Hamra Seaplane Base , Ras al-Khaimah"
        },
        {
            "value": "RHT",
            "icao_code": null,
            "label": "Alxa Right Banner Badanjilin Airport , Alxa Right Banner"
        },
        {
            "value": "RHV",
            "icao_code": "KRHV",
            "label": "Reid\u2013Hillview Airport of Santa Clara County , San Jose"
        },
        {
            "value": "RIA",
            "icao_code": "SBSM",
            "label": "Santa Maria Airport , Santa Maria"
        },
        {
            "value": "RIB",
            "icao_code": "SLRI",
            "label": "Riberalta Airport , Riberalta"
        },
        {
            "value": "RIC",
            "icao_code": "KRIC",
            "label": "Richmond International Airport , Richmond"
        },
        {
            "value": "RID",
            "icao_code": "KRID",
            "label": "Richmond Municipal Airport , Richmond"
        },
        {
            "value": "RIE",
            "icao_code": "KRPD",
            "label": "Rice Lake Regional Airport , Rice Lake"
        },
        {
            "value": "RIF",
            "icao_code": "KRIF",
            "label": "Richfield Municipal Airport , Richfield"
        },
        {
            "value": "RIG",
            "icao_code": "SBRG",
            "label": "Rio Grande Regional Airport , Rio Grande"
        },
        {
            "value": "RIH",
            "icao_code": "MPSM",
            "label": "Scarlett Martinez International Airport , R\u00edo Hato"
        },
        {
            "value": "RIJ",
            "icao_code": "SPJA",
            "label": "Juan Simons Vela Airport , Rioja"
        },
        {
            "value": "RIL",
            "icao_code": "KRIL",
            "label": "Garfield County Regional Airport , Rifle"
        },
        {
            "value": "RIM",
            "icao_code": "SPLN",
            "label": "San Nicolas Airport , Rodr\u00edguez de Mendoza"
        },
        {
            "value": "RIN",
            "icao_code": "AGRC",
            "label": "Ringgi Cove Airport , Ringgi Cove"
        },
        {
            "value": "RIO",
            "icao_code": null,
            "label": "metropolitan area , Rio de Janeiro"
        },
        {
            "value": "RIR",
            "icao_code": "KRIR",
            "label": "Flabob Airport , Riverside"
        },
        {
            "value": "RIS",
            "icao_code": "RJER",
            "label": "Rishiri Airport , Rishiri"
        },
        {
            "value": "RIV",
            "icao_code": "KRIV",
            "label": "March Air Reserve Base , Riverside"
        },
        {
            "value": "RIW",
            "icao_code": "KRIW",
            "label": "Central Wyoming Regional Airport , Riverton"
        },
        {
            "value": "RIX",
            "icao_code": "EVRA",
            "label": "Riga International Airport , Riga"
        },
        {
            "value": "RIY",
            "icao_code": "OYRN",
            "label": "Riyan Airport , Mukalla"
        },
        {
            "value": "RIZ",
            "icao_code": null,
            "label": "Rizhao Shanzihe Airport , Rizhao"
        },
        {
            "value": "RJA",
            "icao_code": "VORY",
            "label": "Rajahmundry Airport , Rajahmundry"
        },
        {
            "value": "RJB",
            "icao_code": "VNRB",
            "label": "Rajbiraj Airport , Rajbiraj"
        },
        {
            "value": "RJH",
            "icao_code": "VGRJ",
            "label": "Shah Makhdum Airport , Rajshahi"
        },
        {
            "value": "RJI",
            "icao_code": null,
            "label": "Rajouri Airport , Rajouri"
        },
        {
            "value": "RJK",
            "icao_code": "LDRI",
            "label": "Rijeka Airport , Rijeka"
        },
        {
            "value": "RJL",
            "icao_code": "LELO",
            "label": "Logro\u00f1o\u2013Agoncillo Airport , Logro\u00f1o"
        },
        {
            "value": "RJM",
            "icao_code": null,
            "label": "Marinda Airport , Waisai"
        },
        {
            "value": "RJN",
            "icao_code": "OIKR",
            "label": "Rafsanjan Airport , Rafsanjan"
        },
        {
            "value": "RKA",
            "icao_code": "NTKK",
            "label": "Aratika-Nord Airport , Aratika"
        },
        {
            "value": "RKD",
            "icao_code": "KRKD",
            "label": "Knox County Regional Airport , Rockland"
        },
        {
            "value": "RKE",
            "icao_code": "EKRK",
            "label": "Roskilde Airport , Copenhagen"
        },
        {
            "value": "RKH",
            "icao_code": "KUZA",
            "label": "Rock Hill/York County Airport , Rock Hill"
        },
        {
            "value": "RKI",
            "icao_code": "WIBR",
            "label": "Rokot Airport , Sipura"
        },
        {
            "value": "RKP",
            "icao_code": "KRKP",
            "label": "Aransas County Airport , Rockport"
        },
        {
            "value": "RKR",
            "icao_code": "KRKR",
            "label": "Robert S. Kerr Airport , Poteau"
        },
        {
            "value": "RKS",
            "icao_code": "KRKS",
            "label": "Rock Springs\u2013Sweetwater County Airport , Rock Springs"
        },
        {
            "value": "RKT",
            "icao_code": "OMRK",
            "label": "Ras Al Khaimah International Airport , Ras al-Khaimah"
        },
        {
            "value": "RKU",
            "icao_code": null,
            "label": "Kairuku Airport , Yule Island"
        },
        {
            "value": "RKV",
            "icao_code": "BIRK",
            "label": "Reykjav\u00edk Airport , Reykjav\u00edk"
        },
        {
            "value": "RKW",
            "icao_code": "KRKW",
            "label": "Rockwood Municipal Airport , Rockwood"
        },
        {
            "value": "RKY",
            "icao_code": null,
            "label": "Rokeby Airport , Rokeby"
        },
        {
            "value": "RKZ",
            "icao_code": "ZURK",
            "label": "Shigatse Peace Airport , Shigatse"
        },
        {
            "value": "RLD",
            "icao_code": "KRLD",
            "label": "Richland Airport , Richland"
        },
        {
            "value": "RLG",
            "icao_code": "ETNL",
            "label": "Rostock\u2013Laage Airport , Rostock"
        },
        {
            "value": "RLK",
            "icao_code": "ZBYZ",
            "label": "Bayannur Tianjitai Airport , Bayannur"
        },
        {
            "value": "RLO",
            "icao_code": "SAOS",
            "label": "Valle del Conlara Airport , Villa de Merlo"
        },
        {
            "value": "RLP",
            "icao_code": null,
            "label": "Rosella Plains Airport , Rosella Plains"
        },
        {
            "value": "RLR",
            "icao_code": null,
            "label": "Relais de la Reine Airport , Isalo"
        },
        {
            "value": "RLT",
            "icao_code": "DRZL",
            "label": "Arlit Airport , Arlit"
        },
        {
            "value": "RMA",
            "icao_code": "YROM",
            "label": "Roma Airport , Roma"
        },
        {
            "value": "RMB",
            "icao_code": "OOBR",
            "label": "Buraimi Airport , Buraimi"
        },
        {
            "value": "RMD",
            "icao_code": "VORG",
            "label": "Ramagundam Airport , Ramagundam"
        },
        {
            "value": "RME",
            "icao_code": "KRME",
            "label": "Griffiss International Airport , Rome"
        },
        {
            "value": "RMF",
            "icao_code": "HEMA",
            "label": "Marsa Alam International Airport , Marsa Alam"
        },
        {
            "value": "RMG",
            "icao_code": "KRMG",
            "label": "Richard B. Russell Airport , Rome"
        },
        {
            "value": "RMI",
            "icao_code": "LIPR",
            "label": "Federico Fellini International Airport , Rimini"
        },
        {
            "value": "RMK",
            "icao_code": "YREN",
            "label": "Renmark Airport , Renmark"
        },
        {
            "value": "RML",
            "icao_code": "VCCC",
            "label": "Colombo International Airport, Ratmalana , Colombo"
        },
        {
            "value": "RMN",
            "icao_code": null,
            "label": "Rumginae Airport , Rumginae"
        },
        {
            "value": "RMP",
            "icao_code": "PFMP",
            "label": "Rampart Airport , Rampart"
        },
        {
            "value": "RMQ",
            "icao_code": "RCMQ",
            "label": "Taichung Airport , Taichung"
        },
        {
            "value": "RMS",
            "icao_code": "ETAR",
            "label": "Ramstein Air Base , Ramstein"
        },
        {
            "value": "RMT",
            "icao_code": "NTAM",
            "label": "Rimatara Airport , Rimatara"
        },
        {
            "value": "RMU",
            "icao_code": "LEMI",
            "label": "Regi\u00f3n de Murcia International Airport , Murcia"
        },
        {
            "value": "RMY",
            "icao_code": "KMPI",
            "label": "Mariposa-Yosemite Airport , Mariposa"
        },
        {
            "value": "RNA",
            "icao_code": "AGAR",
            "label": "Ulawa Airport , Arona"
        },
        {
            "value": "RNB",
            "icao_code": "ESDF",
            "label": "Ronneby Airport , Ronneby"
        },
        {
            "value": "RNC",
            "icao_code": "KRNC",
            "label": "Warren County Memorial Airport , McMinnville"
        },
        {
            "value": "RND",
            "icao_code": "KRND",
            "label": "Randolph Air Force Base , San Antonio"
        },
        {
            "value": "RNE",
            "icao_code": "LFLO",
            "label": "Roanne Renaison Airport , Roanne"
        },
        {
            "value": "RNG",
            "icao_code": null,
            "label": "Rangely Airport , Rangely"
        },
        {
            "value": "RNH",
            "icao_code": "KRNH",
            "label": "New Richmond Regional Airport , New Richmond"
        },
        {
            "value": "RNI",
            "icao_code": "MNCI",
            "label": "Corn Island Airport , Corn Islands"
        },
        {
            "value": "RNJ",
            "icao_code": "RORY",
            "label": "Yoron Airport , Yoronjima"
        },
        {
            "value": "RNL",
            "icao_code": "AGGR",
            "label": "Rennell/Tingoa Airport , Rennell Island"
        },
        {
            "value": "RNM",
            "icao_code": "OOGB",
            "label": "Qarn Alam Airport , Qarn Alam"
        },
        {
            "value": "RNN",
            "icao_code": "EKRN",
            "label": "Bornholm Airport , R\u00f8nne"
        },
        {
            "value": "RNO",
            "icao_code": "KRNO",
            "label": "Reno\u2013Tahoe International Airport , Reno"
        },
        {
            "value": "RNP",
            "icao_code": null,
            "label": "Rongelap Airport , Rongelap Atoll"
        },
        {
            "value": "RNR",
            "icao_code": null,
            "label": "Robinson River Airport , Robinson River"
        },
        {
            "value": "RNS",
            "icao_code": "LFRN",
            "label": "Rennes\u2013Saint-Jacques Airport , Rennes"
        },
        {
            "value": "RNT",
            "icao_code": "KRNT",
            "label": "Renton Municipal Airport , Renton"
        },
        {
            "value": "RNU",
            "icao_code": "WBKR",
            "label": "Ranau Airport , Ranau"
        },
        {
            "value": "RNZ",
            "icao_code": "KRZL",
            "label": "Jasper County Airport , Rensselaer"
        },
        {
            "value": "ROA",
            "icao_code": "KROA",
            "label": "Roanoke\u2013Blacksburg Regional Airport , Roanoke"
        },
        {
            "value": "ROB",
            "icao_code": "GLRB",
            "label": "Roberts International Airport , Monrovia"
        },
        {
            "value": "ROC",
            "icao_code": "KROC",
            "label": "Greater Rochester International Airport , Rochester"
        },
        {
            "value": "ROD",
            "icao_code": "FARS",
            "label": "Robertson Airfield , Robertson"
        },
        {
            "value": "ROF",
            "icao_code": null,
            "label": "Montague Airport , Montague"
        },
        {
            "value": "ROG",
            "icao_code": "KROG",
            "label": "Rogers Municipal Airport , Rogers"
        },
        {
            "value": "ROH",
            "icao_code": "YROB",
            "label": "Robinhood Airport , Robinhood"
        },
        {
            "value": "ROI",
            "icao_code": "VTUV",
            "label": "Roi Et Airport , Roi Et"
        },
        {
            "value": "ROK",
            "icao_code": "YBRK",
            "label": "Rockhampton Airport , Rockhampton"
        },
        {
            "value": "ROL",
            "icao_code": null,
            "label": "Roosevelt Municipal Airport , Roosevelt"
        },
        {
            "value": "ROM",
            "icao_code": null,
            "label": "metropolitan area , Rome"
        },
        {
            "value": "RON",
            "icao_code": "SKPA",
            "label": "Juan Jos\u00e9 Rond\u00f3n Airport , Paipa"
        },
        {
            "value": "ROO",
            "icao_code": "SWRD",
            "label": "Maestro Marinho Franco Airport , Rondon\u00f3polis"
        },
        {
            "value": "ROP",
            "icao_code": "PGRO",
            "label": "Rota International Airport , Rota"
        },
        {
            "value": "ROR",
            "icao_code": "PTRO",
            "label": "Roman Tmetuchl International Airport , Koror"
        },
        {
            "value": "ROS",
            "icao_code": "SAAR",
            "label": "Rosario \u2013 Islas Malvinas International Airport , Rosario"
        },
        {
            "value": "ROT",
            "icao_code": "NZRO",
            "label": "Rotorua Regional Airport , Rotorua"
        },
        {
            "value": "ROU",
            "icao_code": "LBRS",
            "label": "Ruse Airport , Ruse"
        },
        {
            "value": "ROV",
            "icao_code": "URRP",
            "label": "Platov International Airport , Novocherkassk"
        },
        {
            "value": "ROW",
            "icao_code": "KROW",
            "label": "Roswell International Air Center , Roswell"
        },
        {
            "value": "ROX",
            "icao_code": "KROX",
            "label": "Roseau Municipal Airport , Roseau"
        },
        {
            "value": "ROY",
            "icao_code": "SAWM",
            "label": "R\u00edo Mayo Airport , R\u00edo Mayo"
        },
        {
            "value": "ROZ",
            "icao_code": "LERT",
            "label": "US Naval Station Rota , Rota"
        },
        {
            "value": "RPA",
            "icao_code": "VNRP",
            "label": "Rolpa Airport , Rolpa"
        },
        {
            "value": "RPB",
            "icao_code": "YRRB",
            "label": "Roper Bar Airport , Roper Bar"
        },
        {
            "value": "RPI",
            "icao_code": null,
            "label": "Rampi Airport , Rampi"
        },
        {
            "value": "RPM",
            "icao_code": "YNGU",
            "label": "Ngukurr Airport , Ngukurr"
        },
        {
            "value": "RPN",
            "icao_code": "LLIB",
            "label": "Rosh Pina Airport , Rosh Pinna"
        },
        {
            "value": "RPR",
            "icao_code": "VARP",
            "label": "Swami Vivekananda Airport , Raipur"
        },
        {
            "value": "RPV",
            "icao_code": null,
            "label": "Roper Valley Airport , Roper Valley"
        },
        {
            "value": "RPX",
            "icao_code": "KRPX",
            "label": "Roundup Airport , Roundup"
        },
        {
            "value": "RQA",
            "icao_code": "ZWRQ",
            "label": "Ruoqiang Loulan Airport , Ruoqiang"
        },
        {
            "value": "RQW",
            "icao_code": null,
            "label": "Qayyarah Airfield West , Qayyarah"
        },
        {
            "value": "RRE",
            "icao_code": null,
            "label": "Marree Airport , Marree"
        },
        {
            "value": "RRG",
            "icao_code": "FIMR",
            "label": "Sir Ga\u00ebtan Duval Airport , Rodrigues Island"
        },
        {
            "value": "RRI",
            "icao_code": null,
            "label": "Barora Airport , Barora"
        },
        {
            "value": "RRJ",
            "icao_code": "SBJR",
            "label": "Jacarepagu\u00e1 Airport , Barra da Tijuca"
        },
        {
            "value": "RRK",
            "icao_code": "VERK",
            "label": "Rourkela Airport , Rourkela"
        },
        {
            "value": "RRL",
            "icao_code": "KRRL",
            "label": "Merrill Municipal Airport , Merrill"
        },
        {
            "value": "RRM",
            "icao_code": null,
            "label": "Marromeu Airport , Marromeu"
        },
        {
            "value": "RRR",
            "icao_code": "NTKO",
            "label": "Raroia Airport , Raroia"
        },
        {
            "value": "RRS",
            "icao_code": "ENRO",
            "label": "R\u00f8ros Airport , R\u00f8ros"
        },
        {
            "value": "RRT",
            "icao_code": "KRRT",
            "label": "Warroad International Memorial Airport , Warroad"
        },
        {
            "value": "RRV",
            "icao_code": null,
            "label": "Robinson River Airport , Robinson River"
        },
        {
            "value": "RSA",
            "icao_code": "SAZR",
            "label": "Santa Rosa Airport , Santa Rosa"
        },
        {
            "value": "RSB",
            "icao_code": null,
            "label": "Roseberth Airport , Roseberth"
        },
        {
            "value": "RSD",
            "icao_code": "MYER",
            "label": "Rock Sound International Airport , Rock Sound"
        },
        {
            "value": "RSE",
            "icao_code": null,
            "label": "Rose Bay Water Airport , Sydney"
        },
        {
            "value": "RSH",
            "icao_code": "PARS",
            "label": "Russian Mission Airport , Russian Mission"
        },
        {
            "value": "RSI",
            "icao_code": null,
            "label": "Red Sea International Airport , Red Sea Project"
        },
        {
            "value": "RSJ",
            "icao_code": null,
            "label": "Rosario Seaplane Base , Orcas Island"
        },
        {
            "value": "RSK",
            "icao_code": "WASC",
            "label": "Abresso Airport , Ransiki"
        },
        {
            "value": "RSL",
            "icao_code": "KRSL",
            "label": "Russell Municipal Airport , Russell"
        },
        {
            "value": "RSN",
            "icao_code": "KRSN",
            "label": "Ruston Regional Airport , Ruston"
        },
        {
            "value": "RSS",
            "icao_code": "HSDZ",
            "label": "Damazin Airport , Ad-Damazin"
        },
        {
            "value": "RST",
            "icao_code": "KRST",
            "label": "Rochester International Airport , Rochester"
        },
        {
            "value": "RSU",
            "icao_code": "RKJY",
            "label": "Yeosu/Suncheon Airport , Yeosu"
        },
        {
            "value": "RSW",
            "icao_code": "KRSW",
            "label": "Southwest Florida International Airport , Fort Myers"
        },
        {
            "value": "RSX",
            "icao_code": null,
            "label": "Rouses Point Seaplane Base , Rouses Point"
        },
        {
            "value": "RTA",
            "icao_code": "NFNR",
            "label": "Rotuma Airport , Rotuma Island"
        },
        {
            "value": "RTB",
            "icao_code": "MHRO",
            "label": "Juan Manuel G\u00e1lvez International Airport , Roat\u00e1n"
        },
        {
            "value": "RTC",
            "icao_code": "VARG",
            "label": "Ratnagiri Airport , Ratnagiri"
        },
        {
            "value": "RTG",
            "icao_code": "WATG",
            "label": "Frans Sales Lega Airport , Ruteng"
        },
        {
            "value": "RTI",
            "icao_code": null,
            "label": "David Constantijn Saudale Airport , Rote Island"
        },
        {
            "value": "RTL",
            "icao_code": null,
            "label": "Spirit Lake Municipal Airport , Spirit Lake"
        },
        {
            "value": "RTM",
            "icao_code": "EHRD",
            "label": "Rotterdam The Hague Airport , Rotterdam"
        },
        {
            "value": "RTN",
            "icao_code": "KRTN",
            "label": "Raton Municipal Airport , Raton"
        },
        {
            "value": "RTP",
            "icao_code": "YRTP",
            "label": "Rutland Plains Airport , Rutland Plains"
        },
        {
            "value": "RTS",
            "icao_code": "YRTI",
            "label": "Rottnest Island Airport , Rottnest Island"
        },
        {
            "value": "RTW",
            "icao_code": "UWSS",
            "label": "Saratov Tsentralny Airport , Saratov"
        },
        {
            "value": "RTY",
            "icao_code": "YMYT",
            "label": "Merty Merty Airport , Merty Merty"
        },
        {
            "value": "RUA",
            "icao_code": "HUAR",
            "label": "Arua Airport , Arua"
        },
        {
            "value": "RUD",
            "icao_code": "OIMJ",
            "label": "Shahroud Airport , Shahrud"
        },
        {
            "value": "RUE",
            "icao_code": null,
            "label": "Butembo Airport , Butembo"
        },
        {
            "value": "RUF",
            "icao_code": "WAJE",
            "label": "Yuruf Airport , Yuruf"
        },
        {
            "value": "RUG",
            "icao_code": "ZSRG",
            "label": "Rugao Air Base , Rugao"
        },
        {
            "value": "RUH",
            "icao_code": "OERK",
            "label": "King Khalid International Airport , Riyadh"
        },
        {
            "value": "RUI",
            "icao_code": "KSRR",
            "label": "Sierra Blanca Regional Airport , Ruidoso"
        },
        {
            "value": "RUK",
            "icao_code": "VNRK",
            "label": "Chaurjahari Airport , Rukumkot"
        },
        {
            "value": "RUL",
            "icao_code": "VRQM",
            "label": "Maavarulu Airport , Gaafu Dhaalu Atoll"
        },
        {
            "value": "RUM",
            "icao_code": "VNRT",
            "label": "Rumjatar Airport , Rumjatar"
        },
        {
            "value": "RUN",
            "icao_code": "FMEE",
            "label": "Roland Garros Airport , St-Denis"
        },
        {
            "value": "RUP",
            "icao_code": "VERU",
            "label": "Rupsi Airport , Rupsi"
        },
        {
            "value": "RUR",
            "icao_code": "NTAR",
            "label": "Rurutu Airport , Rurutu"
        },
        {
            "value": "RUS",
            "icao_code": "AGGU",
            "label": "Marau Airport , Marau"
        },
        {
            "value": "RUT",
            "icao_code": "KRUT",
            "label": "Rutland \u2013 Southern Vermont Regional Airport , Rutland"
        },
        {
            "value": "RUU",
            "icao_code": null,
            "label": "Ruti Airport , Kawbenaberi"
        },
        {
            "value": "RUV",
            "icao_code": "MGRB",
            "label": "Rubelsanto Airport , Rubelsanto"
        },
        {
            "value": "RUY",
            "icao_code": "MHRU",
            "label": "Cop\u00e1n Ruinas Airport , Cop\u00e1n Ruinas"
        },
        {
            "value": "RVA",
            "icao_code": "FMSG",
            "label": "Farafangana Airport , Farafangana"
        },
        {
            "value": "RVC",
            "icao_code": null,
            "label": "River Cess Airport , River Cess"
        },
        {
            "value": "RVD",
            "icao_code": "SWLC",
            "label": "General Leite de Castro Airport , Rio Verde"
        },
        {
            "value": "RVE",
            "icao_code": "SKSA",
            "label": "Los Colonizadores Airport , Saravena"
        },
        {
            "value": "RVH",
            "icao_code": "ULSS",
            "label": "Rzhevka Airport , Saint Petersburg"
        },
        {
            "value": "RVI",
            "icao_code": "URRR",
            "label": "Rostov-on-Don Airport , Rostov-on-Don"
        },
        {
            "value": "RVK",
            "icao_code": "ENRM",
            "label": "R\u00f8rvik Airport, Ryum , R\u00f8rvik"
        },
        {
            "value": "RVN",
            "icao_code": "EFRO",
            "label": "Rovaniemi Airport , Rovaniemi"
        },
        {
            "value": "RVO",
            "icao_code": "FARI",
            "label": "Reivilo Airport , Reivilo"
        },
        {
            "value": "RVR",
            "icao_code": null,
            "label": "Green River Municipal Airport , Green River"
        },
        {
            "value": "RVS",
            "icao_code": "KRVS",
            "label": "Richard Lloyd Jones Jr. Airport , Tulsa"
        },
        {
            "value": "RVT",
            "icao_code": "YNRV",
            "label": "Ravensthorpe Airport , Ravensthorpe"
        },
        {
            "value": "RVV",
            "icao_code": "NTAV",
            "label": "Raivavae Airport , Raivavae"
        },
        {
            "value": "RVY",
            "icao_code": "SURV",
            "label": "Pres. Gral. \u00d3scar D. Gestido International Airport , Rivera"
        },
        {
            "value": "RWF",
            "icao_code": "KRWF",
            "label": "Redwood Falls Municipal Airport , Redwood Falls"
        },
        {
            "value": "RWI",
            "icao_code": "KRWI",
            "label": "Rocky Mount\u2013Wilson Regional Airport , Rocky Mount"
        },
        {
            "value": "RWL",
            "icao_code": "KRWL",
            "label": "Rawlins Municipal Airport , Rawlins"
        },
        {
            "value": "RWN",
            "icao_code": "UKLR",
            "label": "Rivne International Airport , Rivne"
        },
        {
            "value": "RXA",
            "icao_code": null,
            "label": "Ar Rawdah Airport , Ar Rawdah"
        },
        {
            "value": "RXE",
            "icao_code": "KRXE",
            "label": "Rexburg\u2013Madison County Airport , Rexburg"
        },
        {
            "value": "RXS",
            "icao_code": "RPVR",
            "label": "Roxas Airport , Roxas"
        },
        {
            "value": "RYB",
            "icao_code": "UUBK",
            "label": "Staroselye Airport , Rybinsk"
        },
        {
            "value": "RYG",
            "icao_code": "ENRY",
            "label": "Moss Airport, Rygge , Oslo"
        },
        {
            "value": "RYK",
            "icao_code": "OPRK",
            "label": "Shaikh Zayed International Airport , Rahim Yar Khan"
        },
        {
            "value": "RYL",
            "icao_code": null,
            "label": "Royal Airstrip , Lower Zambezi National Park"
        },
        {
            "value": "RYN",
            "icao_code": "LFCY",
            "label": "Royan \u2013 M\u00e9dis Aerodrome , Royan"
        },
        {
            "value": "RYO",
            "icao_code": "SAWT",
            "label": "Rio Turbio Airport , R\u00edo Turbio"
        },
        {
            "value": "RZA",
            "icao_code": "SAWU",
            "label": "Santa Cruz Airport , Puerto Santa Cruz"
        },
        {
            "value": "RZE",
            "icao_code": "EPRZ",
            "label": "Rzesz\u00f3w\u2013Jasionka Airport , Rzesz\u00f3w"
        },
        {
            "value": "RZN",
            "icao_code": "UUWR",
            "label": "Turlatovo Airport , Ryazan"
        },
        {
            "value": "RZP",
            "icao_code": "RPSD",
            "label": "Cesar Lim Rodriguez Airport , Taytay"
        },
        {
            "value": "RZR",
            "icao_code": "OINR",
            "label": "Ramsar International Airport , Ramsar"
        },
        {
            "value": "RZS",
            "icao_code": "OPSW",
            "label": "Sawan Airport , Sawan"
        },
        {
            "value": "RZV",
            "icao_code": "LTFO",
            "label": "Rize\u2013Artvin Airport , Rize Province"
        },
        {
            "value": "RZZ",
            "icao_code": "KRZZ",
            "label": "Halifax County Airport , Roanoke Rapids"
        },
        {
            "value": "SAA",
            "icao_code": "KSAA",
            "label": "Shively Field , Saratoga"
        },
        {
            "value": "SAB",
            "icao_code": "TNCS",
            "label": "Juancho E. Yrausquin Airport , Saba"
        },
        {
            "value": "SAC",
            "icao_code": "KSAC",
            "label": "Sacramento Executive Airport , Sacramento"
        },
        {
            "value": "SAD",
            "icao_code": "KSAD",
            "label": "Safford Regional Airport , Safford"
        },
        {
            "value": "SAF",
            "icao_code": "KSAF",
            "label": "Santa Fe Municipal Airport , Santa Fe"
        },
        {
            "value": "SAG",
            "icao_code": null,
            "label": "Shirdi Airport , Shirdi"
        },
        {
            "value": "SAH",
            "icao_code": "OYSN",
            "label": "Sana'a International Airport , Sana'a"
        },
        {
            "value": "SAK",
            "icao_code": "BIKR",
            "label": "Sau\u00f0\u00e1rkr\u00f3kur Airport , Sau\u00f0\u00e1rkr\u00f3kur"
        },
        {
            "value": "SAL",
            "icao_code": "MSLP",
            "label": "Monse\u00f1or \u00d3scar Arnulfo Romero International Airport , San Salvador"
        },
        {
            "value": "SAM",
            "icao_code": null,
            "label": "Salamo Airport , Salamo"
        },
        {
            "value": "SAN",
            "icao_code": "KSAN",
            "label": "San Diego International Airport , San Diego"
        },
        {
            "value": "SAO",
            "icao_code": null,
            "label": "metropolitan area , S\u00e3o Paulo"
        },
        {
            "value": "SAP",
            "icao_code": "MHLM",
            "label": "Ram\u00f3n Villeda Morales International Airport , San Pedro Sula"
        },
        {
            "value": "SAQ",
            "icao_code": "MYAN",
            "label": "San Andros Airport , Nicholls Town"
        },
        {
            "value": "SAR",
            "icao_code": "KSAR",
            "label": "Sparta Community Airport , Sparta"
        },
        {
            "value": "SAS",
            "icao_code": "KSAS",
            "label": "Salton Sea Airport , Salton City"
        },
        {
            "value": "SAT",
            "icao_code": "KSAT",
            "label": "San Antonio International Airport , San Antonio"
        },
        {
            "value": "SAU",
            "icao_code": "WATS",
            "label": "Tardamu Airport , Savu"
        },
        {
            "value": "SAV",
            "icao_code": "KSAV",
            "label": "Savannah/Hilton Head International Airport , Savannah"
        },
        {
            "value": "SAW",
            "icao_code": "LTFJ",
            "label": "Sabiha G\u00f6k\u00e7en International Airport , Istanbul"
        },
        {
            "value": "SAX",
            "icao_code": null,
            "label": "Samb\u00fa Airport , Samb\u00fa"
        },
        {
            "value": "SAY",
            "icao_code": "LIQS",
            "label": "Siena\u2013Ampugnano Airport , Siena"
        },
        {
            "value": "SAZ",
            "icao_code": "GLST",
            "label": "Sasstown Airport , Sasstown"
        },
        {
            "value": "SBA",
            "icao_code": "KSBA",
            "label": "Santa Barbara Municipal Airport , Santa Barbara"
        },
        {
            "value": "SBB",
            "icao_code": "SVSB",
            "label": "Santa B\u00e1rbara de Barinas Airport , Santa B\u00e1rbara"
        },
        {
            "value": "SBC",
            "icao_code": "AYSO",
            "label": "Selbang Airport , Selbang"
        },
        {
            "value": "SBD",
            "icao_code": "KSBD",
            "label": "San Bernardino International Airport , San Bernardino"
        },
        {
            "value": "SBE",
            "icao_code": "AYSA",
            "label": "Suabi Airport , Suabi"
        },
        {
            "value": "SBF",
            "icao_code": "OADS",
            "label": "Sardeh Band Airport , Band-e Sardeh Dam"
        },
        {
            "value": "SBG",
            "icao_code": "WITN",
            "label": "Maimun Saleh Airport , Sabang"
        },
        {
            "value": "SBH",
            "icao_code": "TFFJ",
            "label": "Gustaf III Airport , Gustavia"
        },
        {
            "value": "SBI",
            "icao_code": "GUSB",
            "label": "Sambailo Airport , Koundara"
        },
        {
            "value": "SBJ",
            "icao_code": "SNMX",
            "label": "S\u00e3o Mateus Airport , S\u00e3o Mateus"
        },
        {
            "value": "SBK",
            "icao_code": "LFRT",
            "label": "Saint-Brieuc \u2013 Armor Airport , Saint-Brieuc"
        },
        {
            "value": "SBL",
            "icao_code": "SLSA",
            "label": "Santa Ana del Yacuma Airport , Santa Ana del Yacuma"
        },
        {
            "value": "SBM",
            "icao_code": "KSBM",
            "label": "Sheboygan County Memorial Airport , Sheboygan"
        },
        {
            "value": "SBN",
            "icao_code": "KSBN",
            "label": "South Bend International Airport , South Bend"
        },
        {
            "value": "SBO",
            "icao_code": null,
            "label": "Salina-Gunnison Airport , Salina"
        },
        {
            "value": "SBP",
            "icao_code": "KSBP",
            "label": "San Luis Obispo County Regional Airport , San Luis Obispo"
        },
        {
            "value": "SBQ",
            "icao_code": "OPSB",
            "label": "Sibi Airport , Sibi"
        },
        {
            "value": "SBR",
            "icao_code": "YSII",
            "label": "Saibai Island Airport , Saibai Island"
        },
        {
            "value": "SBS",
            "icao_code": "KSBS",
            "label": "Steamboat Springs Airport , Steamboat Springs"
        },
        {
            "value": "SBT",
            "icao_code": "USDA",
            "label": "Sabetta International Airport , Sabetta"
        },
        {
            "value": "SBU",
            "icao_code": "FASB",
            "label": "Springbok Airport , Springbok"
        },
        {
            "value": "SBV",
            "icao_code": "AYSH",
            "label": "Sabah Airport , Sabah"
        },
        {
            "value": "SBW",
            "icao_code": "WBGS",
            "label": "Sibu Airport , Sibu"
        },
        {
            "value": "SBX",
            "icao_code": "KSBX",
            "label": "Shelby Airport , Shelby"
        },
        {
            "value": "SBY",
            "icao_code": "KSBY",
            "label": "Salisbury\u2013Ocean City\u2013Wicomico Regional Airport , Salisbury"
        },
        {
            "value": "SBZ",
            "icao_code": "LRSB",
            "label": "Sibiu International Airport , Sibiu"
        },
        {
            "value": "SCA",
            "icao_code": null,
            "label": "Santa Catalina Airport , Santa Catalina"
        },
        {
            "value": "SCB",
            "icao_code": "KSCB",
            "label": "Scribner State Airport , Scribner"
        },
        {
            "value": "SCC",
            "icao_code": "PASC",
            "label": "Deadhorse Airport , Prudhoe Bay"
        },
        {
            "value": "SCD",
            "icao_code": "MHUL",
            "label": "Sulaco Airport , Sulaco"
        },
        {
            "value": "SCE",
            "icao_code": "KUNV",
            "label": "University Park Airport , State College"
        },
        {
            "value": "SCF",
            "icao_code": "KSDL",
            "label": "Scottsdale Airport , Phoenix"
        },
        {
            "value": "SCG",
            "icao_code": "YSPK",
            "label": "Spring Creek Airport , Conjuboy, Queensland"
        },
        {
            "value": "SCH",
            "icao_code": "KSCH",
            "label": "Schenectady County Airport , Schenectady"
        },
        {
            "value": "SCI",
            "icao_code": "SVPM",
            "label": "Paramillo Airport , San Crist\u00f3bal"
        },
        {
            "value": "SCK",
            "icao_code": "KSCK",
            "label": "Stockton Metropolitan Airport , Stockton"
        },
        {
            "value": "SCL",
            "icao_code": "SCEL",
            "label": "Arturo Merino Ben\u00edtez International Airport , Santiago"
        },
        {
            "value": "SCM",
            "icao_code": "PACM",
            "label": "Scammon Bay Airport , Scammon Bay"
        },
        {
            "value": "SCN",
            "icao_code": "EDDR",
            "label": "Saarbr\u00fccken Airport , Saarbr\u00fccken"
        },
        {
            "value": "SCO",
            "icao_code": "UATE",
            "label": "Aktau Airport , Aktau"
        },
        {
            "value": "SCP",
            "icao_code": "LFNC",
            "label": "Mont-Dauphin - Saint-Cr\u00e9pin Airport , Mont-Dauphin"
        },
        {
            "value": "SCQ",
            "icao_code": "LEST",
            "label": "Santiago de Compostela Airport , Santiago de Compostela"
        },
        {
            "value": "SCR",
            "icao_code": null,
            "label": "Scandinavian Mountains Airport , Malung-S\u00e4len"
        },
        {
            "value": "SCS",
            "icao_code": "EGPM",
            "label": "Scatsta Airport , Shetland Islands"
        },
        {
            "value": "SCT",
            "icao_code": "OYSQ",
            "label": "Socotra Airport , Socotra"
        },
        {
            "value": "SCU",
            "icao_code": "MUCU",
            "label": "Antonio Maceo International Airport , Santiago de Cuba"
        },
        {
            "value": "SCV",
            "icao_code": "LRSV",
            "label": "Suceava International Airport , Suceava"
        },
        {
            "value": "SCW",
            "icao_code": "UUYY",
            "label": "Syktyvkar Airport , Syktyvkar"
        },
        {
            "value": "SCX",
            "icao_code": "MMSZ",
            "label": "Salina Cruz Airport , Salina Cruz"
        },
        {
            "value": "SCY",
            "icao_code": "SEST",
            "label": "San Crist\u00f3bal Airport , San Crist\u00f3bal Island"
        },
        {
            "value": "SCZ",
            "icao_code": "AGGL",
            "label": "Santa Cruz/Graciosa Bay/Luova Airport , Santa Cruz Islands"
        },
        {
            "value": "SDB",
            "icao_code": "FALW",
            "label": "Air Force Base Langebaanweg , Saldanha Bay"
        },
        {
            "value": "SDC",
            "icao_code": null,
            "label": "Sand Creek Airport , Sand Creek Village"
        },
        {
            "value": "SDD",
            "icao_code": "FNUB",
            "label": "Lubango Mukanka Airport , Lubango"
        },
        {
            "value": "SDE",
            "icao_code": "SANE",
            "label": "Vicecomodoro \u00c1ngel de la Paz Aragon\u00e9s Airport , Santiago del Estero"
        },
        {
            "value": "SDF",
            "icao_code": "KSDF",
            "label": "Louisville International Airport , Louisville"
        },
        {
            "value": "SDG",
            "icao_code": "OICS",
            "label": "Sanandaj Airport , Sanandaj"
        },
        {
            "value": "SDH",
            "icao_code": "MHSR",
            "label": "Santa Rosa de Cop\u00e1n Airport , Santa Rosa de Cop\u00e1n"
        },
        {
            "value": "SDI",
            "icao_code": "AYSD",
            "label": "Saidor Airport , Saidor"
        },
        {
            "value": "SDJ",
            "icao_code": "RJSS",
            "label": "Sendai Airport , Sendai"
        },
        {
            "value": "SDK",
            "icao_code": "WBKS",
            "label": "Sandakan Airport , Sandakan"
        },
        {
            "value": "SDL",
            "icao_code": "ESNN",
            "label": "Sundsvall-Timr\u00e5 Airport , Sundsvall"
        },
        {
            "value": "SDM",
            "icao_code": "KSDM",
            "label": "Brown Field Municipal Airport , San Diego"
        },
        {
            "value": "SDN",
            "icao_code": "ENSD",
            "label": "Sandane Airport, Anda , Sandane"
        },
        {
            "value": "SDP",
            "icao_code": "PASD",
            "label": "Sand Point Airport , Sand Point"
        },
        {
            "value": "SDQ",
            "icao_code": "MDSD",
            "label": "Las Am\u00e9ricas International Airport , Santo Domingo"
        },
        {
            "value": "SDR",
            "icao_code": "LEXJ",
            "label": "Santander Airport , Santander"
        },
        {
            "value": "SDS",
            "icao_code": "RJSD",
            "label": "Sado Airport , Sado"
        },
        {
            "value": "SDT",
            "icao_code": "OPSS",
            "label": "Saidu Sharif Airport , Saidu Sharif"
        },
        {
            "value": "SDU",
            "icao_code": "SBRJ",
            "label": "Santos Dumont Airport , Rio de Janeiro"
        },
        {
            "value": "SDV",
            "icao_code": "LLSD",
            "label": "Sde Dov Airport , Tel Aviv"
        },
        {
            "value": "SDX",
            "icao_code": "KSEZ",
            "label": "Sedona Airport , Sedona"
        },
        {
            "value": "SDY",
            "icao_code": "KSDY",
            "label": "Sidney\u2013Richland Municipal Airport , Sidney"
        },
        {
            "value": "SDZ",
            "icao_code": null,
            "label": "metropolitan area , Shetland Islands"
        },
        {
            "value": "SEA",
            "icao_code": "KSEA",
            "label": "Seattle\u2013Tacoma International Airport , Seattle"
        },
        {
            "value": "SEB",
            "icao_code": "HLLS",
            "label": "Sabha Airport , Sabha"
        },
        {
            "value": "SED",
            "icao_code": null,
            "label": "Sdom Airfield , Neve Zohar"
        },
        {
            "value": "SEE",
            "icao_code": "KSEE",
            "label": "Gillespie Field , San Diego"
        },
        {
            "value": "SEF",
            "icao_code": "KSEF",
            "label": "Sebring Regional Airport , Sebring"
        },
        {
            "value": "SEG",
            "icao_code": "KSEG",
            "label": "Penn Valley Airport , Selinsgrove"
        },
        {
            "value": "SEH",
            "icao_code": "WAJS",
            "label": "Senggeh Airport , Senggeh"
        },
        {
            "value": "SEK",
            "icao_code": "UESK",
            "label": "Srednekolymsk Airport , Srednekolymsk"
        },
        {
            "value": "SEL",
            "icao_code": null,
            "label": "metropolitan area , Seoul"
        },
        {
            "value": "SEM",
            "icao_code": "KSEM",
            "label": "Craig Field , Selma"
        },
        {
            "value": "SEN",
            "icao_code": "EGMC",
            "label": "London Southend Airport , London"
        },
        {
            "value": "SEO",
            "icao_code": "DISG",
            "label": "S\u00e9gu\u00e9la Airport , S\u00e9gu\u00e9la"
        },
        {
            "value": "SEP",
            "icao_code": "KSEP",
            "label": "Stephenville Clark Regional Airport , Stephenville"
        },
        {
            "value": "SEQ",
            "icao_code": "WIBS",
            "label": "Sei Pakning Airport , Bengkalis"
        },
        {
            "value": "SER",
            "icao_code": "KSER",
            "label": "Freeman Municipal Airport , Seymour"
        },
        {
            "value": "SEU",
            "icao_code": "HTSN",
            "label": "Seronera Airstrip , Seronera"
        },
        {
            "value": "SEV",
            "icao_code": null,
            "label": "Severodonetsk Airport , Severodonetsk"
        },
        {
            "value": "SEW",
            "icao_code": null,
            "label": "Siwa Oasis North Airport , Siwa Oasis"
        },
        {
            "value": "SEY",
            "icao_code": "GQNS",
            "label": "S\u00e9libaby Airport , S\u00e9libaby"
        },
        {
            "value": "SEZ",
            "icao_code": "FSIA",
            "label": "Seychelles International Airport , Mah\u00e9"
        },
        {
            "value": "SFA",
            "icao_code": "DTTX",
            "label": "Sfax\u2013Thyna International Airport , Sfax"
        },
        {
            "value": "SFB",
            "icao_code": "KSFB",
            "label": "Orlando Sanford International Airport , Orlando"
        },
        {
            "value": "SFC",
            "icao_code": "TFFC",
            "label": "Saint-Fran\u00e7ois Airport , Saint-Fran\u00e7ois"
        },
        {
            "value": "SFD",
            "icao_code": "SVSR",
            "label": "Las Flecheras Airport , San Fernando de Apure"
        },
        {
            "value": "SFE",
            "icao_code": "RPUS",
            "label": "San Fernando Airport , San Fernando"
        },
        {
            "value": "SFF",
            "icao_code": "KSFF",
            "label": "Felts Field , Spokane"
        },
        {
            "value": "SFG",
            "icao_code": "TFFG",
            "label": "L'Esp\u00e9rance Airport , Grand Case"
        },
        {
            "value": "SFH",
            "icao_code": "MMSF",
            "label": "San Felipe International Airport , San Felipe"
        },
        {
            "value": "SFI",
            "icao_code": "GMMS",
            "label": "Safi Airport , Safi"
        },
        {
            "value": "SFJ",
            "icao_code": "BGSF",
            "label": "Kangerlussuaq Airport , Kangerlussuaq"
        },
        {
            "value": "SFK",
            "icao_code": "SNSW",
            "label": "Soure Airport , Soure"
        },
        {
            "value": "SFL",
            "icao_code": "GVSF",
            "label": "S\u00e3o Filipe Airport , S\u00e3o Filipe"
        },
        {
            "value": "SFM",
            "icao_code": "KSFM",
            "label": "Sanford Seacoast Regional Airport , Sanford"
        },
        {
            "value": "SFN",
            "icao_code": "SAAV",
            "label": "Sauce Viejo Airport , Santa Fe de la Vera Cruz"
        },
        {
            "value": "SFO",
            "icao_code": "KSFO",
            "label": "San Francisco International Airport , San Francisco"
        },
        {
            "value": "SFQ",
            "icao_code": "LTCH",
            "label": "\u015eanl\u0131urfa Airport , \u015eanl\u0131urfa"
        },
        {
            "value": "SFS",
            "icao_code": "RPLB",
            "label": "Subic Bay International Airport , Subic Bay"
        },
        {
            "value": "SFT",
            "icao_code": "ESNS",
            "label": "Skellefte\u00e5 Airport , Skellefte\u00e5"
        },
        {
            "value": "SFU",
            "icao_code": null,
            "label": "Safia Airport , Safia"
        },
        {
            "value": "SFV",
            "icao_code": null,
            "label": "Santa F\u00e9 do Sul Airport , Santa F\u00e9 do Sul"
        },
        {
            "value": "SFX",
            "icao_code": null,
            "label": "Macagua Airport , San F\u00e9lix"
        },
        {
            "value": "SFY",
            "icao_code": null,
            "label": "metropolitan area , Springfield"
        },
        {
            "value": "SFZ",
            "icao_code": "KSFZ",
            "label": "North Central State Airport , Pawtucket"
        },
        {
            "value": "SGA",
            "icao_code": "OASN",
            "label": "Sheghnan Airport , Shighnan"
        },
        {
            "value": "SGB",
            "icao_code": null,
            "label": "Singaua Airport , Singaua"
        },
        {
            "value": "SGC",
            "icao_code": "USRR",
            "label": "Surgut International Airport , Surgut"
        },
        {
            "value": "SGD",
            "icao_code": "EKSB",
            "label": "S\u00f8nderborg Airport , S\u00f8nderborg"
        },
        {
            "value": "SGE",
            "icao_code": "EDGS",
            "label": "Siegerland Airport , Siegen"
        },
        {
            "value": "SGF",
            "icao_code": "KSGF",
            "label": "Springfield\u2013Branson National Airport , Springfield"
        },
        {
            "value": "SGG",
            "icao_code": "WBGY",
            "label": "Simanggang Airport , Sri Aman"
        },
        {
            "value": "SGH",
            "icao_code": "KSGH",
            "label": "Springfield\u2013Beckley Municipal Airport , Springfield"
        },
        {
            "value": "SGI",
            "icao_code": "OPSR",
            "label": "PAF Base Mushaf , Sargodha"
        },
        {
            "value": "SGJ",
            "icao_code": null,
            "label": "Sagarai Airport , Sagarai"
        },
        {
            "value": "SGK",
            "icao_code": "AYSK",
            "label": "Sangapi Airport , Sangapi"
        },
        {
            "value": "SGL",
            "icao_code": "RPLS",
            "label": "Sangley Point Airport , Manila"
        },
        {
            "value": "SGM",
            "icao_code": null,
            "label": "San Ignacio Airfield , San Ignacio"
        },
        {
            "value": "SGN",
            "icao_code": "VVTS",
            "label": "Tan Son Nhat International Airport , Ho Chi Minh City"
        },
        {
            "value": "SGO",
            "icao_code": "YSGE",
            "label": "St George Airport , St George"
        },
        {
            "value": "SGP",
            "icao_code": "YSHG",
            "label": "Shay Gap Airport , Shay Gap"
        },
        {
            "value": "SGQ",
            "icao_code": "WRLA",
            "label": "Sangkimah Airport , Sangatta"
        },
        {
            "value": "SGR",
            "icao_code": "KSGR",
            "label": "Sugar Land Regional Airport , Houston"
        },
        {
            "value": "SGT",
            "icao_code": "KSGT",
            "label": "Stuttgart Municipal Airport , Stuttgart"
        },
        {
            "value": "SGU",
            "icao_code": "KSGU",
            "label": "St. George Regional Airport , St. George"
        },
        {
            "value": "SGV",
            "icao_code": "SAVS",
            "label": "Sierra Grande Airport , Sierra Grande"
        },
        {
            "value": "SGW",
            "icao_code": null,
            "label": "Saginaw Seaplane Base , Saginaw Bay"
        },
        {
            "value": "SGX",
            "icao_code": "HTSO",
            "label": "Songea Airport , Songea"
        },
        {
            "value": "SGY",
            "icao_code": "PAGY",
            "label": "Skagway Airport , Skagway"
        },
        {
            "value": "SGZ",
            "icao_code": "VTSH",
            "label": "Songkhla Airport , Songkhla"
        },
        {
            "value": "SHA",
            "icao_code": "ZSSS",
            "label": "Shanghai Hongqiao International Airport , Shanghai"
        },
        {
            "value": "SHB",
            "icao_code": "RJCN",
            "label": "Nakashibetsu Airport , Nakashibetsu"
        },
        {
            "value": "SHC",
            "icao_code": null,
            "label": "Shire Airport , Shire"
        },
        {
            "value": "SHD",
            "icao_code": "KSHD",
            "label": "Shenandoah Valley Regional Airport , Staunton"
        },
        {
            "value": "SHE",
            "icao_code": "ZYTX",
            "label": "Shenyang Taoxian International Airport , Shenyang"
        },
        {
            "value": "SHF",
            "icao_code": "ZWHZ",
            "label": "Shihezi Huayuan Airport , Shihezi"
        },
        {
            "value": "SHG",
            "icao_code": "PAHG",
            "label": "Shungnak Airport , Shungnak"
        },
        {
            "value": "SHH",
            "icao_code": "PASH",
            "label": "Shishmaref Airport , Shishmaref"
        },
        {
            "value": "SHI",
            "icao_code": "RORS",
            "label": "Shimojishima Airport , Shimoji-shima"
        },
        {
            "value": "SHJ",
            "icao_code": "OMSJ",
            "label": "Sharjah International Airport , Sharjah"
        },
        {
            "value": "SHK",
            "icao_code": "FXSH",
            "label": "Sehonghong Airport , Sehonghong"
        },
        {
            "value": "SHL",
            "icao_code": "VEBI",
            "label": "Shillong Airport , Shillong"
        },
        {
            "value": "SHM",
            "icao_code": "RJBD",
            "label": "Nanki\u2013Shirahama Airport , Shirahama"
        },
        {
            "value": "SHN",
            "icao_code": "KSHN",
            "label": "Sanderson Field , Shelton"
        },
        {
            "value": "SHO",
            "icao_code": "FDSK",
            "label": "King Mswati III International Airport , Manzini"
        },
        {
            "value": "SHQ",
            "icao_code": "YSPT",
            "label": "Southport Airport , Southport"
        },
        {
            "value": "SHR",
            "icao_code": "KSHR",
            "label": "Sheridan County Airport , Sheridan"
        },
        {
            "value": "SHS",
            "icao_code": "ZHSS",
            "label": "Shashi Airport , Jingzhou"
        },
        {
            "value": "SHT",
            "icao_code": "YSHT",
            "label": "Shepparton Airport , Shepparton"
        },
        {
            "value": "SHU",
            "icao_code": "YSMP",
            "label": "Smith Point Airport , Smith Point"
        },
        {
            "value": "SHV",
            "icao_code": "KSHV",
            "label": "Shreveport Regional Airport , Shreveport"
        },
        {
            "value": "SHW",
            "icao_code": "OESH",
            "label": "Sharurah Domestic Airport , Sharurah"
        },
        {
            "value": "SHX",
            "icao_code": "PAHX",
            "label": "Shageluk Airport , Shageluk"
        },
        {
            "value": "SHY",
            "icao_code": "HTSY",
            "label": "Shinyanga Airport , Shinyanga"
        },
        {
            "value": "SHZ",
            "icao_code": "FXSS",
            "label": "Seshutes Airport , Seshote"
        },
        {
            "value": "SIA",
            "icao_code": "ZLSN",
            "label": "Xi'an Xiguan Airport , Xi'an"
        },
        {
            "value": "SIB",
            "icao_code": "FCBS",
            "label": "Sibiti Airport , Sibiti"
        },
        {
            "value": "SIC",
            "icao_code": null,
            "label": "San Jos\u00e9 Airport , San Jos\u00e9 Island"
        },
        {
            "value": "SID",
            "icao_code": "GVAC",
            "label": "Am\u00edlcar Cabral International Airport , Sal"
        },
        {
            "value": "SIE",
            "icao_code": "LPSI",
            "label": "Sines Airport , Sines"
        },
        {
            "value": "SIF",
            "icao_code": "VNSI",
            "label": "Simara Airport , Simara"
        },
        {
            "value": "SIG",
            "icao_code": "TJIG",
            "label": "Fernando Luis Ribas Dominicci Airport , San Juan"
        },
        {
            "value": "SIH",
            "icao_code": "VNDT",
            "label": "Silgadhi Airport , Dipayal Silgadhi"
        },
        {
            "value": "SII",
            "icao_code": "GMMF",
            "label": "Sidi Ifni Airport , Sidi Ifni"
        },
        {
            "value": "SIJ",
            "icao_code": "BISI",
            "label": "Siglufj\u00f6r\u00f0ur Airport , Siglufj\u00f6r\u00f0ur"
        },
        {
            "value": "SIK",
            "icao_code": "KSIK",
            "label": "Sikeston Memorial Municipal Airport , Sikeston"
        },
        {
            "value": "SIL",
            "icao_code": "AYSG",
            "label": "Sila Airport , Sila"
        },
        {
            "value": "SIM",
            "icao_code": "AYSJ",
            "label": "Simbai Airport , Simbai"
        },
        {
            "value": "SIN",
            "icao_code": "WSSS",
            "label": "Singapore Changi Airport , Singapore"
        },
        {
            "value": "SIO",
            "icao_code": "YSMI",
            "label": "Smithton Airport , Smithton"
        },
        {
            "value": "SIP",
            "icao_code": "UKFF",
            "label": "Simferopol International Airport , Simferopol"
        },
        {
            "value": "SIQ",
            "icao_code": "WIDS",
            "label": "Dabo Singkep Airport , Singkep"
        },
        {
            "value": "SIR",
            "icao_code": "LSGS",
            "label": "Sion Airport , Sion"
        },
        {
            "value": "SIS",
            "icao_code": "FASS",
            "label": "Sishen Airport , Dingleton"
        },
        {
            "value": "SIT",
            "icao_code": "PASI",
            "label": "Sitka Rocky Gutierrez Airport , Sitka"
        },
        {
            "value": "SIU",
            "icao_code": "MNSI",
            "label": "Siuna Airport , Siuna"
        },
        {
            "value": "SIV",
            "icao_code": "KSIV",
            "label": "Sullivan County Airport , Sullivan"
        },
        {
            "value": "SIW",
            "icao_code": "WIMP",
            "label": "Sibisa Airport , Parapat"
        },
        {
            "value": "SIX",
            "icao_code": "YSGT",
            "label": "Singleton Airport , Singleton"
        },
        {
            "value": "SIY",
            "icao_code": "KSIY",
            "label": "Siskiyou County Airport , Montague"
        },
        {
            "value": "SIZ",
            "icao_code": "AYZN",
            "label": "Sissano Airport , Sissano"
        },
        {
            "value": "SJA",
            "icao_code": "SPJN",
            "label": "San Juan de Marcona Airport , San Juan de Marcona"
        },
        {
            "value": "SJB",
            "icao_code": "SLJO",
            "label": "San Joaqu\u00edn Airport , San Joaqu\u00edn"
        },
        {
            "value": "SJC",
            "icao_code": "KSJC",
            "label": "San Jose International Airport , San Jose"
        },
        {
            "value": "SJD",
            "icao_code": "MMSD",
            "label": "Los Cabos International Airport , San Jos\u00e9 del Cabo"
        },
        {
            "value": "SJE",
            "icao_code": "SKSJ",
            "label": "Jorge Enrique Gonz\u00e1lez Torres Airport , San Jos\u00e9 del Guaviare"
        },
        {
            "value": "SJF",
            "icao_code": null,
            "label": "Cruz Bay Seaplane Base , Saint John"
        },
        {
            "value": "SJG",
            "icao_code": null,
            "label": "San Pedro de Jagua Airport , San Pedro de Jagua"
        },
        {
            "value": "SJH",
            "icao_code": null,
            "label": "San Juan del Cesar Airport , San Juan del Cesar"
        },
        {
            "value": "SJI",
            "icao_code": "RPUH",
            "label": "San Jose Airport , San Jose"
        },
        {
            "value": "SJJ",
            "icao_code": "LQSA",
            "label": "Sarajevo International Airport , Sarajevo"
        },
        {
            "value": "SJK",
            "icao_code": "SBSJ",
            "label": "Professor Urbano Ernesto Stumpf Airport , S\u00e3o Jos\u00e9 dos Campos"
        },
        {
            "value": "SJL",
            "icao_code": "SBUA",
            "label": "S\u00e3o Gabriel da Cachoeira Airport , S\u00e3o Gabriel da Cachoeira"
        },
        {
            "value": "SJM",
            "icao_code": "MDSJ",
            "label": "San Juan de la Maguana Airport , San Juan de la Maguana"
        },
        {
            "value": "SJN",
            "icao_code": "KSJN",
            "label": "St. Johns Industrial Air Park , St. Johns"
        },
        {
            "value": "SJO",
            "icao_code": "MROC",
            "label": "Juan Santamar\u00eda International Airport , San Jos\u00e9"
        },
        {
            "value": "SJP",
            "icao_code": "SBSR",
            "label": "Prof. Eribelto Manoel Reino State Airport , S\u00e3o Jos\u00e9 do Rio Preto"
        },
        {
            "value": "SJQ",
            "icao_code": "FLSS",
            "label": "Sesheke Airport , Sesheke"
        },
        {
            "value": "SJR",
            "icao_code": null,
            "label": "San Juan de Urab\u00e1 Airport , San Juan de Urab\u00e1"
        },
        {
            "value": "SJS",
            "icao_code": "SLJE",
            "label": "San Jos\u00e9 de Chiquitos Airport , San Jos\u00e9 de Chiquitos"
        },
        {
            "value": "SJT",
            "icao_code": "KSJT",
            "label": "San Angelo Regional Airport , San Angelo"
        },
        {
            "value": "SJU",
            "icao_code": "TJSJ",
            "label": "Luis Mu\u00f1oz Mar\u00edn International Airport , San Juan"
        },
        {
            "value": "SJV",
            "icao_code": "SLJV",
            "label": "San Javier Airport , San Javier"
        },
        {
            "value": "SJW",
            "icao_code": "ZBSJ",
            "label": "Shijiazhuang Zhengding International Airport , Shijiazhuang"
        },
        {
            "value": "SJX",
            "icao_code": "MZSJ",
            "label": "Sarteneja Airport , Sarteneja"
        },
        {
            "value": "SJY",
            "icao_code": "EFSI",
            "label": "Sein\u00e4joki Airport , Sein\u00e4joki"
        },
        {
            "value": "SJZ",
            "icao_code": "LPSJ",
            "label": "S\u00e3o Jorge Airport , S\u00e3o Jorge Island"
        },
        {
            "value": "SKA",
            "icao_code": "KSKA",
            "label": "Fairchild Air Force Base , Spokane"
        },
        {
            "value": "SKB",
            "icao_code": "TKPK",
            "label": "Robert L. Bradshaw International Airport , Saint Kitts"
        },
        {
            "value": "SKC",
            "icao_code": "AYSU",
            "label": "Suki Airport , Suki"
        },
        {
            "value": "SKD",
            "icao_code": "UTSS",
            "label": "Samarkand International Airport , Samarkand"
        },
        {
            "value": "SKE",
            "icao_code": "ENSN",
            "label": "Skien Airport, Geiteryggen , Skien"
        },
        {
            "value": "SKF",
            "icao_code": "KSKF",
            "label": "Kelly Field Annex , San Antonio"
        },
        {
            "value": "SKG",
            "icao_code": "LGTS",
            "label": "Thessaloniki Airport \"Macedonia\" , Thessaloniki"
        },
        {
            "value": "SKH",
            "icao_code": "VNSK",
            "label": "Surkhet Airport , Surkhet"
        },
        {
            "value": "SKI",
            "icao_code": "DABP",
            "label": "Skikda Airport , Skikda"
        },
        {
            "value": "SKK",
            "icao_code": "PFSH",
            "label": "Shaktoolik Airport , Shaktoolik"
        },
        {
            "value": "SKL",
            "icao_code": "EGEI",
            "label": "Broadford Airfield , Isle of Skye"
        },
        {
            "value": "SKM",
            "icao_code": null,
            "label": "Skeldon Airport , Skeldon"
        },
        {
            "value": "SKN",
            "icao_code": "ENSK",
            "label": "Stokmarknes Airport, Skagen , Stokmarknes"
        },
        {
            "value": "SKO",
            "icao_code": "DNSO",
            "label": "Sadiq Abubakar III International Airport , Sokoto"
        },
        {
            "value": "SKP",
            "icao_code": "LWSK",
            "label": "Skopje \"Alexander the Great\" Airport , Skopje"
        },
        {
            "value": "SKQ",
            "icao_code": "FXSK",
            "label": "Sekake Airport , Sekake"
        },
        {
            "value": "SKR",
            "icao_code": "HASK",
            "label": "Shakiso Airport , Shakiso"
        },
        {
            "value": "SKS",
            "icao_code": "EKSP",
            "label": "Vojens Airport , Vojens"
        },
        {
            "value": "SKT",
            "icao_code": "OPST",
            "label": "Sialkot International Airport , Sialkot"
        },
        {
            "value": "SKU",
            "icao_code": "LGSY",
            "label": "Skyros Island National Airport , Skyros"
        },
        {
            "value": "SKV",
            "icao_code": "HESC",
            "label": "St. Catherine International Airport , Saint Catherine"
        },
        {
            "value": "SKW",
            "icao_code": "PASW",
            "label": "Skwentna Airport , Skwentna"
        },
        {
            "value": "SKX",
            "icao_code": "UWPS",
            "label": "Saransk Airport , Saransk"
        },
        {
            "value": "SKY",
            "icao_code": "KSKY",
            "label": "Griffing Sandusky Airport , Sandusky"
        },
        {
            "value": "SKZ",
            "icao_code": "OPSK",
            "label": "Sukkur Airport , Sukkur"
        },
        {
            "value": "SLA",
            "icao_code": "SASA",
            "label": "Mart\u00edn Miguel de G\u00fcemes International Airport , Salta"
        },
        {
            "value": "SLB",
            "icao_code": "KSLB",
            "label": "Storm Lake Municipal Airport , Storm Lake"
        },
        {
            "value": "SLC",
            "icao_code": "KSLC",
            "label": "Salt Lake City International Airport , Salt Lake City"
        },
        {
            "value": "SLD",
            "icao_code": "LZSL",
            "label": "Slia\u010d Airport , Slia\u010d"
        },
        {
            "value": "SLE",
            "icao_code": "KSLE",
            "label": "McNary Field , Salem"
        },
        {
            "value": "SLF",
            "icao_code": "OESL",
            "label": "Sulayel Airport , As Sulayyil"
        },
        {
            "value": "SLG",
            "icao_code": "KSLG",
            "label": "Smith Field , Siloam Springs"
        },
        {
            "value": "SLH",
            "icao_code": "NVSC",
            "label": "Vanua Lava Airport , Sola"
        },
        {
            "value": "SLI",
            "icao_code": "FLSW",
            "label": "Solwezi Airport , Solwezi"
        },
        {
            "value": "SLJ",
            "icao_code": "YSOL",
            "label": "Solomon Airport , Karijini National Park"
        },
        {
            "value": "SLK",
            "icao_code": "KSLK",
            "label": "Adirondack Regional Airport , Saranac Lake"
        },
        {
            "value": "SLL",
            "icao_code": "OOSA",
            "label": "Salalah International Airport , Salalah"
        },
        {
            "value": "SLM",
            "icao_code": "LESA",
            "label": "Salamanca Airport , Salamanca"
        },
        {
            "value": "SLN",
            "icao_code": "KSLN",
            "label": "Salina Regional Airport , Salina"
        },
        {
            "value": "SLO",
            "icao_code": "KSLO",
            "label": "Salem\u2013Leckrone Airport , Salem"
        },
        {
            "value": "SLP",
            "icao_code": "MMSP",
            "label": "Ponciano Arriaga International Airport , San Luis Potos\u00ed City"
        },
        {
            "value": "SLQ",
            "icao_code": "PASL",
            "label": "Sleetmute Airport , Sleetmute"
        },
        {
            "value": "SLR",
            "icao_code": "KSLR",
            "label": "Sulphur Springs Municipal Airport , Sulphur Springs"
        },
        {
            "value": "SLS",
            "icao_code": "LBSS",
            "label": "Silistra Airfield , Silistra"
        },
        {
            "value": "SLT",
            "icao_code": "KANK",
            "label": "Harriet Alexander Field , Salida"
        },
        {
            "value": "SLU",
            "icao_code": "TLPC",
            "label": "George F. L. Charles Airport , Castries"
        },
        {
            "value": "SLV",
            "icao_code": "VISM",
            "label": "Shimla Airport , Simla"
        },
        {
            "value": "SLW",
            "icao_code": "MMIO",
            "label": "Plan de Guadalupe International Airport , Saltillo"
        },
        {
            "value": "SLX",
            "icao_code": "MBSY",
            "label": "Salt Cay Airport , Salt Cay"
        },
        {
            "value": "SLY",
            "icao_code": "USDD",
            "label": "Salekhard Airport , Salekhard"
        },
        {
            "value": "SLZ",
            "icao_code": "SBSL",
            "label": "Marechal Cunha Machado International Airport , S\u00e3o Lu\u00eds"
        },
        {
            "value": "SMA",
            "icao_code": "LPAZ",
            "label": "Santa Maria Airport , Santa Maria Island"
        },
        {
            "value": "SMB",
            "icao_code": "SCSB",
            "label": "Franco Bianco Airport , Cerro Sombrero"
        },
        {
            "value": "SMC",
            "icao_code": null,
            "label": "Santa Mar\u00eda Airport , Santa Mar\u00eda"
        },
        {
            "value": "SMD",
            "icao_code": "KSMD",
            "label": "Smith Field , Fort Wayne"
        },
        {
            "value": "SME",
            "icao_code": "KSME",
            "label": "Lake Cumberland Regional Airport , Somerset"
        },
        {
            "value": "SMF",
            "icao_code": "KSMF",
            "label": "Sacramento International Airport , Sacramento"
        },
        {
            "value": "SMG",
            "icao_code": "SPMR",
            "label": "Santa Mar\u00eda Airport , Santa Mar\u00eda del Mar"
        },
        {
            "value": "SMH",
            "icao_code": "AYSP",
            "label": "Sapmanga Airport , Sapmanga"
        },
        {
            "value": "SMI",
            "icao_code": "LGSM",
            "label": "Samos International Airport , Samos"
        },
        {
            "value": "SMJ",
            "icao_code": null,
            "label": "Sim Airport , Sim"
        },
        {
            "value": "SMK",
            "icao_code": "PAMK",
            "label": "St. Michael Airport , St. Michael"
        },
        {
            "value": "SML",
            "icao_code": "MYLS",
            "label": "Stella Maris Airport , Stella Maris"
        },
        {
            "value": "SMM",
            "icao_code": "WBKA",
            "label": "Semporna Airport , Semporna"
        },
        {
            "value": "SMN",
            "icao_code": "KSMN",
            "label": "Lemhi County Airport , Salmon"
        },
        {
            "value": "SMO",
            "icao_code": "KSMO",
            "label": "Santa Monica Municipal Airport , Santa Monica"
        },
        {
            "value": "SMP",
            "icao_code": null,
            "label": "Stockholm Airport , Stockholm"
        },
        {
            "value": "SMQ",
            "icao_code": "WAOS",
            "label": "H. Asan Airport , Sampit"
        },
        {
            "value": "SMR",
            "icao_code": "SKSM",
            "label": "Sim\u00f3n Bol\u00edvar International Airport , Santa Marta"
        },
        {
            "value": "SMS",
            "icao_code": "FMMS",
            "label": "Sainte Marie Airport , \u00cele Sainte-Marie"
        },
        {
            "value": "SMU",
            "icao_code": "PASP",
            "label": "Sheep Mountain Airport , Sheep Mountain"
        },
        {
            "value": "SMV",
            "icao_code": "LSZS",
            "label": "Samedan Airport , St. Moritz"
        },
        {
            "value": "SMW",
            "icao_code": "GMMA",
            "label": "Smara Airport , Smara"
        },
        {
            "value": "SMX",
            "icao_code": "KSMX",
            "label": "Santa Maria Public Airport , Santa Maria"
        },
        {
            "value": "SMY",
            "icao_code": "GOTS",
            "label": "Simenti Airport , Simenti"
        },
        {
            "value": "SMZ",
            "icao_code": "SMST",
            "label": "Stoelmans Eiland Airstrip , Stoelmans Eiland"
        },
        {
            "value": "SNA",
            "icao_code": "KSNA",
            "label": "John Wayne Airport , Santa Ana"
        },
        {
            "value": "SNB",
            "icao_code": "YSNB",
            "label": "Snake Bay Airport , Milikapiti"
        },
        {
            "value": "SNC",
            "icao_code": "SESA",
            "label": "General Ulpiano Paez Airport , Salinas"
        },
        {
            "value": "SNE",
            "icao_code": "GVSN",
            "label": "Pregui\u00e7a Airport , S\u00e3o Nicolau"
        },
        {
            "value": "SNF",
            "icao_code": "SVSP",
            "label": "Sub Teniente Nestor Arias Airport , San Felipe"
        },
        {
            "value": "SNG",
            "icao_code": "SLSI",
            "label": "Capit\u00e1n Av. Juan Cochamanidis Airport , San Ignacio de Velasco"
        },
        {
            "value": "SNH",
            "icao_code": "YSPE",
            "label": "Stanthorpe Airport , Stanthorpe"
        },
        {
            "value": "SNI",
            "icao_code": "GLGE",
            "label": "Greenville/Sinoe Airport , Greenville"
        },
        {
            "value": "SNJ",
            "icao_code": "MUSJ",
            "label": "San Juli\u00e1n Air Base , Guane"
        },
        {
            "value": "SNK",
            "icao_code": "KSNK",
            "label": "Winston Field Airport , Snyder"
        },
        {
            "value": "SNL",
            "icao_code": "KSNL",
            "label": "Shawnee Regional Airport , Shawnee"
        },
        {
            "value": "SNM",
            "icao_code": "SLSM",
            "label": "San Ignacio de Moxos Airport , San Ignacio de Moxos"
        },
        {
            "value": "SNN",
            "icao_code": "EINN",
            "label": "Shannon Airport , Shannon"
        },
        {
            "value": "SNO",
            "icao_code": "VTUI",
            "label": "Sakon Nakhon Airport , Sakon Nakhon"
        },
        {
            "value": "SNP",
            "icao_code": "PASN",
            "label": "St. Paul Island Airport , St. Paul Island"
        },
        {
            "value": "SNQ",
            "icao_code": null,
            "label": "San Quint\u00edn Military Airstrip , San Quint\u00edn"
        },
        {
            "value": "SNR",
            "icao_code": "LFRZ",
            "label": "Saint-Nazaire Montoir Airport , Saint-Nazaire"
        },
        {
            "value": "SNS",
            "icao_code": "KSNS",
            "label": "Salinas Municipal Airport , Salinas"
        },
        {
            "value": "SNT",
            "icao_code": "SKRU",
            "label": "Las Cruces Airport , Sabana de Torres"
        },
        {
            "value": "SNU",
            "icao_code": "MUSC",
            "label": "Abel Santamar\u00eda Airport , Santa Clara"
        },
        {
            "value": "SNV",
            "icao_code": "SVSE",
            "label": "Santa Elena de Uair\u00e9n Airport , Santa Elena de Uair\u00e9n"
        },
        {
            "value": "SNW",
            "icao_code": "VYTD",
            "label": "Thandwe Airport , Thandwe"
        },
        {
            "value": "SNX",
            "icao_code": "OIIS",
            "label": "Semnan Municipal Airport , Semnan"
        },
        {
            "value": "SNY",
            "icao_code": "KSNY",
            "label": "Sidney Municipal Airport , Sidney"
        },
        {
            "value": "SNZ",
            "icao_code": "SBSC",
            "label": "Santa Cruz Air Force Base , Rio de Janeiro"
        },
        {
            "value": "SOA",
            "icao_code": null,
            "label": "S\u00f3c Tr\u0103ng Airfield , S\u00f3c Tr\u0103ng"
        },
        {
            "value": "SOB",
            "icao_code": "LHSM",
            "label": "H\u00e9v\u00edz\u2013Balaton Airport , H\u00e9v\u00edz"
        },
        {
            "value": "SOC",
            "icao_code": "WARQ",
            "label": "Adisumarmo International Airport , Surakarta"
        },
        {
            "value": "SOD",
            "icao_code": "SDCO",
            "label": "Bertram Luiz Leupolz\u2013Sorocaba Airport , Sorocaba"
        },
        {
            "value": "SOE",
            "icao_code": "FCOS",
            "label": "Souank\u00e9 Airport , Souank\u00e9"
        },
        {
            "value": "SOF",
            "icao_code": "LBSF",
            "label": "Sofia Airport , Sofia"
        },
        {
            "value": "SOG",
            "icao_code": "ENSG",
            "label": "Sogndal Airport, Hauk\u00e5sen , Sogndal"
        },
        {
            "value": "SOJ",
            "icao_code": "ENSR",
            "label": "S\u00f8rkjosen Airport , S\u00f8rkjosen"
        },
        {
            "value": "SOK",
            "icao_code": "FXSM",
            "label": "Semonkong Airport , Semonkong"
        },
        {
            "value": "SOL",
            "icao_code": null,
            "label": "Solomon State Field Airport , Solomon"
        },
        {
            "value": "SOM",
            "icao_code": "SVST",
            "label": "San Tom\u00e9 Airport , San Tom\u00e9"
        },
        {
            "value": "SON",
            "icao_code": "NVSS",
            "label": "Santo-Pekoa International Airport , Luganville"
        },
        {
            "value": "SOO",
            "icao_code": "ESNY",
            "label": "S\u00f6derhamn Airport , S\u00f6derhamn"
        },
        {
            "value": "SOP",
            "icao_code": "KSOP",
            "label": "Moore County Airport , Pinehurst"
        },
        {
            "value": "SOQ",
            "icao_code": "WASS",
            "label": "Dominique Edward Osok Airport , Sorong"
        },
        {
            "value": "SOT",
            "icao_code": "EFSO",
            "label": "Sodankyl\u00e4 Airfield , Sodankyl\u00e4"
        },
        {
            "value": "SOU",
            "icao_code": "EGHI",
            "label": "Southampton Airport , Southampton"
        },
        {
            "value": "SOV",
            "icao_code": "PASO",
            "label": "Seldovia Airport , Seldovia"
        },
        {
            "value": "SOW",
            "icao_code": "KSOW",
            "label": "Show Low Regional Airport , Show Low"
        },
        {
            "value": "SOX",
            "icao_code": "SKSO",
            "label": "Alberto Lleras Camargo Airport , Sogamoso"
        },
        {
            "value": "SOY",
            "icao_code": "EGER",
            "label": "Stronsay Airport , Stronsay"
        },
        {
            "value": "SOZ",
            "icao_code": "LFKS",
            "label": "Solenzara Air Base , Sari-Solenzara"
        },
        {
            "value": "SPA",
            "icao_code": "KSPA",
            "label": "Spartanburg Downtown Memorial Airport , Spartanburg"
        },
        {
            "value": "SPB",
            "icao_code": null,
            "label": "Charlotte Amalie Harbor Seaplane Base , St. Thomas Island"
        },
        {
            "value": "SPC",
            "icao_code": "GCLA",
            "label": "La Palma Airport , La Palma"
        },
        {
            "value": "SPD",
            "icao_code": "VGSD",
            "label": "Saidpur Airport , Saidpur"
        },
        {
            "value": "SPE",
            "icao_code": "WBKO",
            "label": "Sepulot Airport , Sapulut"
        },
        {
            "value": "SPF",
            "icao_code": "KSPF",
            "label": "Black Hills Airport , Spearfish"
        },
        {
            "value": "SPG",
            "icao_code": "KSPG",
            "label": "Albert Whitted Airport , St. Petersburg"
        },
        {
            "value": "SPH",
            "icao_code": "AYQO",
            "label": "Sopu Airport , Sopu"
        },
        {
            "value": "SPI",
            "icao_code": "KSPI",
            "label": "Abraham Lincoln Capital Airport , Springfield"
        },
        {
            "value": "SPJ",
            "icao_code": "LGSP",
            "label": "Sparti Airport , Sparti"
        },
        {
            "value": "SPK",
            "icao_code": null,
            "label": "metropolitan area , Sapporo"
        },
        {
            "value": "SPM",
            "icao_code": "ETAD",
            "label": "Spangdahlem Air Base , Spangdahlem"
        },
        {
            "value": "SPN",
            "icao_code": "PGSN",
            "label": "Saipan International Airport , Saipan"
        },
        {
            "value": "SPP",
            "icao_code": "FNME",
            "label": "Menongue Airport , Menongue"
        },
        {
            "value": "SPR",
            "icao_code": "MZSP",
            "label": "John Greif II Airport , San Pedro Town"
        },
        {
            "value": "SPS",
            "icao_code": "KSPS",
            "label": "Wichita Falls Municipal Airport , Wichita Falls"
        },
        {
            "value": "SPT",
            "icao_code": null,
            "label": "Sipitang Airstrip , Sipitang"
        },
        {
            "value": "SPU",
            "icao_code": "LDSP",
            "label": "Split Airport , Split"
        },
        {
            "value": "SPV",
            "icao_code": null,
            "label": "Sepik Plains Airport , Sepik Plains"
        },
        {
            "value": "SPW",
            "icao_code": "KSPW",
            "label": "Spencer Municipal Airport , Spencer"
        },
        {
            "value": "SPY",
            "icao_code": "DISP",
            "label": "San P\u00e9dro Airport , San-P\u00e9dro"
        },
        {
            "value": "SPX",
            "icao_code": null,
            "label": "Sphinx International Airport , Egypt"
        },
        {
            "value": "SPZ",
            "icao_code": "KASG",
            "label": "Springdale Municipal Airport , Springdale"
        },
        {
            "value": "SQA",
            "icao_code": "KIZA",
            "label": "Santa Ynez Airport , Santa Ynez"
        },
        {
            "value": "SQB",
            "icao_code": null,
            "label": "Santa Ana Airport , Piedras"
        },
        {
            "value": "SQC",
            "icao_code": "YSCR",
            "label": "Southern Cross Airport , Southern Cross"
        },
        {
            "value": "SQD",
            "icao_code": "ZSSR",
            "label": "Shangrao Sanqingshan Airport , Shangrao"
        },
        {
            "value": "SQE",
            "icao_code": null,
            "label": "San Luis de Palenque Airport , San Luis de Palenque"
        },
        {
            "value": "SQG",
            "icao_code": "WIOS",
            "label": "Tebelian Airport , Sintang"
        },
        {
            "value": "SQH",
            "icao_code": "VVNS",
            "label": "N\u00e0 S\u1ea3n Airport , S\u01a1n La"
        },
        {
            "value": "SQI",
            "icao_code": "KSQI",
            "label": "Whiteside County Airport , Sterling"
        },
        {
            "value": "SQJ",
            "icao_code": null,
            "label": "Sanming Shaxian Airport , Sanming"
        },
        {
            "value": "SQK",
            "icao_code": null,
            "label": "Sidi Barrani Airport , Sidi Barrani"
        },
        {
            "value": "SQL",
            "icao_code": "KSQL",
            "label": "San Carlos Airport , San Carlos"
        },
        {
            "value": "SQM",
            "icao_code": "SWUA",
            "label": "S\u00e3o Miguel do Araguaia Airport , S\u00e3o Miguel do Araguaia"
        },
        {
            "value": "SQN",
            "icao_code": "WAPN",
            "label": "Sanana Airport , Sanana Island"
        },
        {
            "value": "SQO",
            "icao_code": "ESUD",
            "label": "Storuman Airport , Storuman"
        },
        {
            "value": "SQQ",
            "icao_code": "EYSA",
            "label": "\u0160iauliai International Airport , \u0160iauliai"
        },
        {
            "value": "SQR",
            "icao_code": "WAWS",
            "label": "Soroako Airport , Soroako"
        },
        {
            "value": "SQS",
            "icao_code": null,
            "label": "Matthew Spain Airport , San Ignacio"
        },
        {
            "value": "SQT",
            "icao_code": null,
            "label": "China Strait Airstrip , Samarai"
        },
        {
            "value": "SQU",
            "icao_code": "SPOA",
            "label": "Saposoa Airport , Saposoa"
        },
        {
            "value": "SQV",
            "icao_code": null,
            "label": "Sequim Valley Airport , Sequim"
        },
        {
            "value": "SQW",
            "icao_code": "EKSV",
            "label": "Skive Airport , Skive"
        },
        {
            "value": "SQX",
            "icao_code": "SSOE",
            "label": "H\u00e9lio Wasum Airport , S\u00e3o Miguel do Oeste"
        },
        {
            "value": "SQY",
            "icao_code": "SSRU",
            "label": "S\u00e3o Louren\u00e7o do Sul Airport , S\u00e3o Louren\u00e7o do Sul"
        },
        {
            "value": "SQZ",
            "icao_code": "EGXP",
            "label": "RAF Scampton , Scampton"
        },
        {
            "value": "SRA",
            "icao_code": "SSZR",
            "label": "Santa Rosa Airport , Santa Rosa"
        },
        {
            "value": "SRB",
            "icao_code": "SLSR",
            "label": "Santa Rosa Airport , Santa Rosa de Yacuma"
        },
        {
            "value": "SRC",
            "icao_code": "KSRC",
            "label": "Searcy Municipal Airport , Searcy"
        },
        {
            "value": "SRD",
            "icao_code": "SLRA",
            "label": "San Ram\u00f3n Airport , San Ram\u00f3n"
        },
        {
            "value": "SRE",
            "icao_code": "SLAL",
            "label": "Alcantar\u00ed Airport , Sucre"
        },
        {
            "value": "SRF",
            "icao_code": null,
            "label": "San Rafael Airport , San Rafael"
        },
        {
            "value": "SRG",
            "icao_code": "WARS",
            "label": "Achmad Yani International Airport , Semarang"
        },
        {
            "value": "SRH",
            "icao_code": "FTTA",
            "label": "Sarh Airport , Sarh"
        },
        {
            "value": "SRI",
            "icao_code": "WALS",
            "label": "Temindung Airport , Samarinda"
        },
        {
            "value": "SRJ",
            "icao_code": "SLSB",
            "label": "Capit\u00e1n Germ\u00e1n Quiroga Guardia Airport , San Borja"
        },
        {
            "value": "SRL",
            "icao_code": null,
            "label": "Palo Verde Airport , Santa Rosal\u00eda"
        },
        {
            "value": "SRM",
            "icao_code": null,
            "label": "Sandringham Station Airport , Sandringham Station"
        },
        {
            "value": "SRN",
            "icao_code": "YSRN",
            "label": "Strahan Airport , Strahan"
        },
        {
            "value": "SRO",
            "icao_code": null,
            "label": "Santana Ramos Airport , Santana Ramos"
        },
        {
            "value": "SRP",
            "icao_code": "ENSA",
            "label": "Stord Airport, S\u00f8rstokken , Stord"
        },
        {
            "value": "SRQ",
            "icao_code": "KSRQ",
            "label": "Sarasota\u2013Bradenton International Airport , Sarasota"
        },
        {
            "value": "SRR",
            "icao_code": null,
            "label": "Dunwich Airport , North Stradbroke Island"
        },
        {
            "value": "SRS",
            "icao_code": null,
            "label": "San Marcos Airport , San Marcos"
        },
        {
            "value": "SRT",
            "icao_code": "HUSO",
            "label": "Soroti Airport , Soroti"
        },
        {
            "value": "SRV",
            "icao_code": null,
            "label": "Stony River Airport , Stony River"
        },
        {
            "value": "SRW",
            "icao_code": "KRUQ",
            "label": "Rowan County Airport , Salisbury"
        },
        {
            "value": "SRX",
            "icao_code": "HLGD",
            "label": "Gardabya Airport , Sirte"
        },
        {
            "value": "SRY",
            "icao_code": "OINZ",
            "label": "Dasht-e Naz Airport , Sari"
        },
        {
            "value": "SRZ",
            "icao_code": "SLET",
            "label": "El Trompillo Airport , Santa Cruz de la Sierra"
        },
        {
            "value": "SSA",
            "icao_code": "SBSV",
            "label": "Deputado Lu\u00eds Eduardo Magalh\u00e3es International Airport , Salvador"
        },
        {
            "value": "SSB",
            "icao_code": null,
            "label": "Christiansted Harbor Seaplane Base , Saint Croix"
        },
        {
            "value": "SSC",
            "icao_code": "KSSC",
            "label": "Shaw Air Force Base , Sumter"
        },
        {
            "value": "SSD",
            "icao_code": null,
            "label": "San Felipe Airport , San Felipe"
        },
        {
            "value": "SSE",
            "icao_code": "VASL",
            "label": "Solapur Airport , Solapur"
        },
        {
            "value": "SSF",
            "icao_code": "KSSF",
            "label": "Stinson Municipal Airport , San Antonio"
        },
        {
            "value": "SSG",
            "icao_code": "FGSL",
            "label": "Malabo International Airport , Malabo"
        },
        {
            "value": "SSH",
            "icao_code": "HESH",
            "label": "Sharm el-Sheikh International Airport , Sharm el-Sheikh"
        },
        {
            "value": "SSI",
            "icao_code": "KSSI",
            "label": "Malcolm McKinnon Airport , Brunswick"
        },
        {
            "value": "SSJ",
            "icao_code": "ENST",
            "label": "Sandnessj\u00f8en Airport, Stokka , Sandnessj\u00f8en"
        },
        {
            "value": "SSK",
            "icao_code": null,
            "label": "Sturt Creek Airport , Sturt Creek"
        },
        {
            "value": "SSL",
            "icao_code": "SKSL",
            "label": "Santa Rosal\u00eda Airport , Santa Rosal\u00eda"
        },
        {
            "value": "SSN",
            "icao_code": "RKSM",
            "label": "Seoul Air Base , Seoul"
        },
        {
            "value": "SSO",
            "icao_code": "SNLO",
            "label": "S\u00e3o Louren\u00e7o Airport , S\u00e3o Louren\u00e7o"
        },
        {
            "value": "SSP",
            "icao_code": null,
            "label": "Silver Plains Airport , Silver Plains"
        },
        {
            "value": "SSQ",
            "icao_code": null,
            "label": "La Sarre Airport , La Sarre"
        },
        {
            "value": "SSR",
            "icao_code": "NVSH",
            "label": "Sara Airport , Sara"
        },
        {
            "value": "SSS",
            "icao_code": null,
            "label": "Siassi Airport , Umboi Island"
        },
        {
            "value": "SST",
            "icao_code": "SAZL",
            "label": "Santa Teresita Airport , Santa Teresita"
        },
        {
            "value": "SSV",
            "icao_code": null,
            "label": "Siasi Airport , Siasi"
        },
        {
            "value": "SSW",
            "icao_code": null,
            "label": "Stuart Island Airpark , Stuart Island"
        },
        {
            "value": "SSY",
            "icao_code": "FNBC",
            "label": "Mbanza Congo Airport , M'banza-Kongo"
        },
        {
            "value": "SSZ",
            "icao_code": "SBST",
            "label": "Santos Air Force Base , Santos"
        },
        {
            "value": "STA",
            "icao_code": "EKVJ",
            "label": "Stauning Vestjylland Airport , Skjern"
        },
        {
            "value": "STB",
            "icao_code": "SVSZ",
            "label": "Miguel Urdaneta Fern\u00e1ndez Airport , Santa B\u00e1rbara del Zulia"
        },
        {
            "value": "STC",
            "icao_code": "KSTC",
            "label": "St. Cloud Regional Airport , St. Cloud"
        },
        {
            "value": "STD",
            "icao_code": "SVSO",
            "label": "Mayor Buenaventura Vivas Airport , Santo Domingo, T\u00e1chira"
        },
        {
            "value": "STE",
            "icao_code": "KSTE",
            "label": "Stevens Point Municipal Airport , Stevens Point"
        },
        {
            "value": "STF",
            "icao_code": "YSTI",
            "label": "Stephens Island Airport , Stephens Island"
        },
        {
            "value": "STG",
            "icao_code": "PAPB",
            "label": "St. George Airport , St. George"
        },
        {
            "value": "STH",
            "icao_code": "YSMR",
            "label": "Strathmore Airport , Strathmore Station"
        },
        {
            "value": "STI",
            "icao_code": "MDST",
            "label": "Cibao International Airport , Santiago de los Caballeros"
        },
        {
            "value": "STJ",
            "icao_code": "KSTJ",
            "label": "Rosecrans Memorial Airport , St. Joseph"
        },
        {
            "value": "STK",
            "icao_code": "KSTK",
            "label": "Sterling Municipal Airport , Sterling"
        },
        {
            "value": "STL",
            "icao_code": "KSTL",
            "label": "Lambert\u2013St. Louis International Airport , St. Louis"
        },
        {
            "value": "STM",
            "icao_code": "SBSN",
            "label": "Santar\u00e9m-Maestro Wilson Fonseca Airport , Santar\u00e9m"
        },
        {
            "value": "STN",
            "icao_code": "EGSS",
            "label": "London Stansted Airport , London"
        },
        {
            "value": "STO",
            "icao_code": null,
            "label": "metropolitan area , Stockholm"
        },
        {
            "value": "STP",
            "icao_code": "KSTP",
            "label": "St. Paul Downtown Airport , St. Paul"
        },
        {
            "value": "STQ",
            "icao_code": "KOYM",
            "label": "St. Marys Municipal Airport , St. Marys"
        },
        {
            "value": "STR",
            "icao_code": "EDDS",
            "label": "Stuttgart Airport , Stuttgart"
        },
        {
            "value": "STS",
            "icao_code": "KSTS",
            "label": "Charles M. Schulz\u2013Sonoma County Airport , Santa Rosa"
        },
        {
            "value": "STT",
            "icao_code": "TIST",
            "label": "Cyril E. King Airport , St. Thomas Island"
        },
        {
            "value": "STV",
            "icao_code": "VASU",
            "label": "Surat Airport , Surat"
        },
        {
            "value": "STW",
            "icao_code": "URMT",
            "label": "Stavropol Shpakovskoye Airport , Stavropol"
        },
        {
            "value": "STX",
            "icao_code": "TISX",
            "label": "Henry E. Rohlsen Airport , Saint Croix"
        },
        {
            "value": "STY",
            "icao_code": "SUSO",
            "label": "Nueva Hesp\u00e9rides International Airport , Salto"
        },
        {
            "value": "STZ",
            "icao_code": "SWST",
            "label": "Santa Terezinha Airport , Santa Terezinha"
        },
        {
            "value": "SUA",
            "icao_code": "KSUA",
            "label": "Witham Field , Stuart"
        },
        {
            "value": "SUB",
            "icao_code": "WARR",
            "label": "Juanda International Airport , Surabaya"
        },
        {
            "value": "SUD",
            "icao_code": "KSUD",
            "label": "Stroud Municipal Airport , Stroud"
        },
        {
            "value": "SUE",
            "icao_code": "KSUE",
            "label": "Door County Cherryland Airport , Sturgeon Bay"
        },
        {
            "value": "SUF",
            "icao_code": "LICA",
            "label": "Lamezia Terme International Airport , Lamezia Terme"
        },
        {
            "value": "SUG",
            "icao_code": "RPMS",
            "label": "Surigao Airport , Surigao City"
        },
        {
            "value": "SUH",
            "icao_code": "OOSR",
            "label": "Sur Airport , Sur"
        },
        {
            "value": "SUI",
            "icao_code": "UGSS",
            "label": "Sukhumi Babushara Airport , Sukhumi"
        },
        {
            "value": "SUJ",
            "icao_code": "LRSM",
            "label": "Satu Mare International Airport , Satu Mare"
        },
        {
            "value": "SUK",
            "icao_code": "UEBS",
            "label": "Sakkyryr Airport , Batagay-Alyta"
        },
        {
            "value": "SUL",
            "icao_code": "OPSU",
            "label": "Sui Airport , Sui"
        },
        {
            "value": "SUM",
            "icao_code": "KSMS",
            "label": "Sumter Airport , Sumter"
        },
        {
            "value": "SUN",
            "icao_code": "KSUN",
            "label": "Friedman Memorial Airport , Hailey"
        },
        {
            "value": "SUO",
            "icao_code": null,
            "label": "Sunriver Airport , Sunriver"
        },
        {
            "value": "SUP",
            "icao_code": "WART",
            "label": "Trunojoyo Airport , Sumenep"
        },
        {
            "value": "SUQ",
            "icao_code": "SESC",
            "label": "Suc\u00faa Airport , Suc\u00faa"
        },
        {
            "value": "SUR",
            "icao_code": null,
            "label": "Summer Beaver Airport , Summer Beaver"
        },
        {
            "value": "SUS",
            "icao_code": "KSUS",
            "label": "Spirit of St. Louis Airport , St. Louis"
        },
        {
            "value": "SUT",
            "icao_code": "HTSU",
            "label": "Sumbawanga Airport , Sumbawanga"
        },
        {
            "value": "SUU",
            "icao_code": "KSUU",
            "label": "Travis Air Force Base , Fairfield"
        },
        {
            "value": "SUV",
            "icao_code": "NFNA",
            "label": "Nausori International Airport , Suva"
        },
        {
            "value": "SUW",
            "icao_code": "KSUW",
            "label": "Richard I. Bong Airport , Superior"
        },
        {
            "value": "SUX",
            "icao_code": "KSUX",
            "label": "Sioux Gateway Airport , Sioux City"
        },
        {
            "value": "SUY",
            "icao_code": "UENS",
            "label": "Suntar Airport , Suntar"
        },
        {
            "value": "SUZ",
            "icao_code": null,
            "label": "Suria Airport , Suria"
        },
        {
            "value": "SVA",
            "icao_code": "PASA",
            "label": "Savoonga Airport , Savoonga"
        },
        {
            "value": "SVB",
            "icao_code": "FMNS",
            "label": "Sambava Airport , Sambava"
        },
        {
            "value": "SVC",
            "icao_code": "KSVC",
            "label": "Grant County Airport , Silver City"
        },
        {
            "value": "SVD",
            "icao_code": "TVSA",
            "label": "Argyle International Airport , Saint Vincent"
        },
        {
            "value": "SVE",
            "icao_code": "KSVE",
            "label": "Susanville Municipal Airport , Susanville"
        },
        {
            "value": "SVF",
            "icao_code": "DBBS",
            "label": "Sav\u00e9 Airport , Sav\u00e9"
        },
        {
            "value": "SVG",
            "icao_code": "ENZV",
            "label": "Stavanger Airport, Sola , Stavanger"
        },
        {
            "value": "SVH",
            "icao_code": "KSVH",
            "label": "Statesville Regional Airport , Statesville"
        },
        {
            "value": "SVI",
            "icao_code": "SKSV",
            "label": "Eduardo Falla Solano Airport , San Vicente del Cagu\u00e1n"
        },
        {
            "value": "SVJ",
            "icao_code": "ENSH",
            "label": "Svolv\u00e6r Airport, Helle , Svolv\u00e6r"
        },
        {
            "value": "SVK",
            "icao_code": "MZKT",
            "label": "Silver Creek Airport , Silver Creek"
        },
        {
            "value": "SVL",
            "icao_code": "EFSA",
            "label": "Savonlinna Airport , Savonlinna"
        },
        {
            "value": "SVM",
            "icao_code": null,
            "label": "St Pauls Airport , St Pauls"
        },
        {
            "value": "SVN",
            "icao_code": "KSVN",
            "label": "Hunter Army Airfield , Savannah"
        },
        {
            "value": "SVO",
            "icao_code": "UUEE",
            "label": "Sheremetyevo International Airport , Moscow"
        },
        {
            "value": "SVP",
            "icao_code": "FNKU",
            "label": "Kuito Airport , Kuito"
        },
        {
            "value": "SVQ",
            "icao_code": "LEZL",
            "label": "Seville Airport , Seville"
        },
        {
            "value": "SVS",
            "icao_code": null,
            "label": "Stevens Village Airport , Stevens Village"
        },
        {
            "value": "SVT",
            "icao_code": "FBSV",
            "label": "Savuti Airport , Savuti"
        },
        {
            "value": "SVU",
            "icao_code": "NFNS",
            "label": "Savusavu Airport , Savusavu"
        },
        {
            "value": "SVW",
            "icao_code": "PASV",
            "label": "Sparrevohn LRRS Airport , Sparrevohn"
        },
        {
            "value": "SVX",
            "icao_code": "USSS",
            "label": "Koltsovo International Airport , Yekaterinburg"
        },
        {
            "value": "SVY",
            "icao_code": null,
            "label": "Savo Airport , Savo Island"
        },
        {
            "value": "SVZ",
            "icao_code": "SVSA",
            "label": "Juan Vicente G\u00f3mez International Airport , San Antonio del T\u00e1chira"
        },
        {
            "value": "SWA",
            "icao_code": "ZGOW",
            "label": "Jieyang Chaoshan International Airport , Shantou"
        },
        {
            "value": "SWB",
            "icao_code": null,
            "label": "Shaw River Airport , Shaw River"
        },
        {
            "value": "SWC",
            "icao_code": "YSWL",
            "label": "Stawell Airport , Stawell"
        },
        {
            "value": "SWD",
            "icao_code": "PAWD",
            "label": "Seward Airport , Seward"
        },
        {
            "value": "SWE",
            "icao_code": null,
            "label": "Siwea Airport , Siwea"
        },
        {
            "value": "SWF",
            "icao_code": "KSWF",
            "label": "Stewart International Airport , Newburgh"
        },
        {
            "value": "SWG",
            "icao_code": null,
            "label": "Satwag Airport , Satwag"
        },
        {
            "value": "SWH",
            "icao_code": "YSWH",
            "label": "Swan Hill Airport , Swan Hill"
        },
        {
            "value": "SWJ",
            "icao_code": "NVSX",
            "label": "South West Bay Airport , South West Bay"
        },
        {
            "value": "SWL",
            "icao_code": "RPSV",
            "label": "San Vicente Airport , San Vicente"
        },
        {
            "value": "SWM",
            "icao_code": null,
            "label": "Suia-Missu Airport , Alto Boa Vista"
        },
        {
            "value": "SWN",
            "icao_code": "OPSW",
            "label": "Sahiwal Airport , Sahiwal"
        },
        {
            "value": "SWO",
            "icao_code": "KSWO",
            "label": "Stillwater Regional Airport , Stillwater"
        },
        {
            "value": "SWP",
            "icao_code": "FYSM",
            "label": "Swakopmund Airport , Swakopmund"
        },
        {
            "value": "SWQ",
            "icao_code": "WADS",
            "label": "Sultan Muhammad Kaharuddin III Airport , Sumbawa Besar"
        },
        {
            "value": "SWR",
            "icao_code": null,
            "label": "Silur Airport , Silur"
        },
        {
            "value": "SWS",
            "icao_code": "EGFH",
            "label": "Swansea Airport , Swansea"
        },
        {
            "value": "SWT",
            "icao_code": "UNSS",
            "label": "Strezhevoy Airport , Strezhevoy"
        },
        {
            "value": "SWU",
            "icao_code": "RKSW",
            "label": "Suwon Air Base , Suwon"
        },
        {
            "value": "SWV",
            "icao_code": "UHMW",
            "label": "Severo-Evensk Airport , Evensk"
        },
        {
            "value": "SWW",
            "icao_code": "KSWW",
            "label": "Avenger Field , Sweetwater"
        },
        {
            "value": "SWX",
            "icao_code": "FBSW",
            "label": "Shakawe Airport , Shakawe"
        },
        {
            "value": "SWY",
            "icao_code": "WMBA",
            "label": "Sitiawan Airport , Sitiawan"
        },
        {
            "value": "SXA",
            "icao_code": null,
            "label": "Sialum Airport , Sialum"
        },
        {
            "value": "SXB",
            "icao_code": "LFST",
            "label": "Strasbourg Airport , Strasbourg"
        },
        {
            "value": "SXE",
            "icao_code": "YWSL",
            "label": "West Sale Airport , Sale"
        },
        {
            "value": "SXG",
            "icao_code": "FLSN",
            "label": "Senanga Airport , Senanga"
        },
        {
            "value": "SXH",
            "icao_code": null,
            "label": "Sehulea Airport , Sehulea"
        },
        {
            "value": "SXI",
            "icao_code": "OIBS",
            "label": "Sirri Island Airport , Sirri Island"
        },
        {
            "value": "SXJ",
            "icao_code": "ZWSS",
            "label": "Shanshan Airport , Shanshan"
        },
        {
            "value": "SXK",
            "icao_code": "WAPI",
            "label": "Saumlaki Airport , Saumlaki"
        },
        {
            "value": "SXL",
            "icao_code": "EISG",
            "label": "Sligo Airport , Sligo"
        },
        {
            "value": "SXM",
            "icao_code": "TNCM",
            "label": "Princess Juliana International Airport , Sint Maarten"
        },
        {
            "value": "SXN",
            "icao_code": "FBSN",
            "label": "Sua Pan Airport , Sua Pan"
        },
        {
            "value": "SXO",
            "icao_code": "SWFX",
            "label": "S\u00e3o F\u00e9lix do Araguaia Airport , S\u00e3o F\u00e9lix do Araguaia"
        },
        {
            "value": "SXP",
            "icao_code": null,
            "label": "Sheldon Point Airport , Nunam Iqua"
        },
        {
            "value": "SXQ",
            "icao_code": "PASX",
            "label": "Soldotna Airport , Soldotna"
        },
        {
            "value": "SXR",
            "icao_code": "VISR",
            "label": "Sheikh ul-Alam International Airport , Srinagar"
        },
        {
            "value": "SXS",
            "icao_code": "WBKH",
            "label": "Sahabat Airport , Sahabat"
        },
        {
            "value": "SXT",
            "icao_code": "WMAN",
            "label": "Sungai Tiang Airport , Taman Negara"
        },
        {
            "value": "SXU",
            "icao_code": "HASD",
            "label": "Soddu Airport , Sodo"
        },
        {
            "value": "SXV",
            "icao_code": "VOSM",
            "label": "Salem Airport , Salem"
        },
        {
            "value": "SXW",
            "icao_code": null,
            "label": "Sauren Airport , Sauren"
        },
        {
            "value": "SXX",
            "icao_code": "SNFX",
            "label": "S\u00e3o F\u00e9lix do Xingu Airport , S\u00e3o F\u00e9lix do Xingu"
        },
        {
            "value": "SXY",
            "icao_code": null,
            "label": "Sidney Municipal Airport , Sidney"
        },
        {
            "value": "SXZ",
            "icao_code": "LTCL",
            "label": "Siirt Airport , Siirt"
        },
        {
            "value": "SYA",
            "icao_code": "PASY",
            "label": "Eareckson Air Station , Shemya"
        },
        {
            "value": "SYB",
            "icao_code": null,
            "label": "Seal Bay Seaplane Base , Seal Bay"
        },
        {
            "value": "SYC",
            "icao_code": "SPSY",
            "label": "Shiringayoc Airport , Shiringayoc"
        },
        {
            "value": "SYD",
            "icao_code": "YSSY",
            "label": "Sydney Airport , Sydney"
        },
        {
            "value": "SYE",
            "icao_code": "OYSH",
            "label": "Saadah Airport , Sa'dah"
        },
        {
            "value": "SYF",
            "icao_code": null,
            "label": "Silva Bay Seaplane Base , Gabriola Island"
        },
        {
            "value": "SYI",
            "icao_code": "KSYI",
            "label": "Shelbyville Municipal Airport , Shelbyville"
        },
        {
            "value": "SYJ",
            "icao_code": "OIKY",
            "label": "Sirjan Airport , Sirjan"
        },
        {
            "value": "SYK",
            "icao_code": "BIST",
            "label": "Stykkish\u00f3lmur Airport , Stykkish\u00f3lmur"
        },
        {
            "value": "SYM",
            "icao_code": "ZPSM",
            "label": "Pu'er Simao Airport , Simao"
        },
        {
            "value": "SYN",
            "icao_code": "KSYN",
            "label": "Stanton Airfield , Stanton"
        },
        {
            "value": "SYO",
            "icao_code": "RJSY",
            "label": "Shonai Airport , Sh\u014dnai"
        },
        {
            "value": "SYP",
            "icao_code": "MPSA",
            "label": "Ruben Cantu Airport , Santiago de Veraguas"
        },
        {
            "value": "SYQ",
            "icao_code": "MRPV",
            "label": "Tob\u00edas Bola\u00f1os International Airport , San Jos\u00e9"
        },
        {
            "value": "SYR",
            "icao_code": "KSYR",
            "label": "Syracuse Hancock International Airport , Syracuse"
        },
        {
            "value": "SYS",
            "icao_code": "UERS",
            "label": "Saskylakh Airport , Saskylakh"
        },
        {
            "value": "SYT",
            "icao_code": "LFLN",
            "label": "Saint-Yan Airport , Saint-Yan"
        },
        {
            "value": "SYU",
            "icao_code": "YWBS",
            "label": "Warraber Island Airport , Sue Islet"
        },
        {
            "value": "SYV",
            "icao_code": "KSYV",
            "label": "Sylvester Airport , Sylvester"
        },
        {
            "value": "SYW",
            "icao_code": "OPSN",
            "label": "Sehwan Sharif Airport , Sehwan Sharif"
        },
        {
            "value": "SYX",
            "icao_code": "ZJSY",
            "label": "Sanya Phoenix International Airport , Sanya"
        },
        {
            "value": "SYY",
            "icao_code": "EGPO",
            "label": "Stornoway Airport , Stornoway"
        },
        {
            "value": "SYZ",
            "icao_code": "OISS",
            "label": "Shiraz International Airport , Shiraz"
        },
        {
            "value": "SZA",
            "icao_code": "FNSO",
            "label": "Soyo Airport , Soyo"
        },
        {
            "value": "SZB",
            "icao_code": "WMSA",
            "label": "Sultan Abdul Aziz Shah Airport , Kuala Lumpur"
        },
        {
            "value": "SZE",
            "icao_code": "HASM",
            "label": "Semera Airport , Semera"
        },
        {
            "value": "SZF",
            "icao_code": "LTFH",
            "label": "Samsun-\u00c7ar\u015famba Airport , Samsun"
        },
        {
            "value": "SZG",
            "icao_code": "LOWS",
            "label": "Salzburg Airport , Salzburg"
        },
        {
            "value": "SZI",
            "icao_code": null,
            "label": "Zaysan Airport , Zaysan"
        },
        {
            "value": "SZJ",
            "icao_code": "MUSN",
            "label": "Siguanea Airport , Isla de la Juventud"
        },
        {
            "value": "SZK",
            "icao_code": "FASZ",
            "label": "Skukuza Airport , Skukuza"
        },
        {
            "value": "SZL",
            "icao_code": "KSZL",
            "label": "Whiteman Air Force Base , Knob Noster"
        },
        {
            "value": "SZM",
            "icao_code": null,
            "label": "Sesriem Airport , Sesriem"
        },
        {
            "value": "SZN",
            "icao_code": "KSZN",
            "label": "Santa Cruz Island Airport , Santa Cruz Island"
        },
        {
            "value": "SZP",
            "icao_code": "KSZP",
            "label": "Santa Paula Airport , Santa Paula"
        },
        {
            "value": "SZR",
            "icao_code": "LBSZ",
            "label": "Stara Zagora Airport , Stara Zagora"
        },
        {
            "value": "SZS",
            "icao_code": "NZRC",
            "label": "Ryan's Creek Aerodrome , Stewart Island"
        },
        {
            "value": "SZU",
            "icao_code": null,
            "label": "S\u00e9gou Airport , S\u00e9gou"
        },
        {
            "value": "SZV",
            "icao_code": "ZSSZ",
            "label": "Suzhou Guangfu Airport , Suzhou"
        },
        {
            "value": "SZW",
            "icao_code": "EDOP",
            "label": "Schwerin-Parchim International Airport , Schwerin"
        },
        {
            "value": "SZX",
            "icao_code": "ZGSZ",
            "label": "Shenzhen Bao'an International Airport , Shenzhen"
        },
        {
            "value": "SZY",
            "icao_code": "EPSY",
            "label": "Olsztyn-Mazury Regional Airport , Szczytno"
        },
        {
            "value": "SZZ",
            "icao_code": "EPSC",
            "label": "\"Solidarity\" Szczecin\u2013Goleni\u00f3w Airport , Szczecin"
        },
        {
            "value": "TAA",
            "icao_code": null,
            "label": "Tarapaina Airport , Tarapaina"
        },
        {
            "value": "TAB",
            "icao_code": "TTCP",
            "label": "Arthur Napoleon Raymond Robinson International Airport , Tobago"
        },
        {
            "value": "TAC",
            "icao_code": "RPVA",
            "label": "Daniel Z. Romualdez Airport , Tacloban"
        },
        {
            "value": "TAD",
            "icao_code": "KTAD",
            "label": "Perry Stokes Airport , Trinidad"
        },
        {
            "value": "TAE",
            "icao_code": "RKTN",
            "label": "Daegu International Airport , Daegu"
        },
        {
            "value": "TAF",
            "icao_code": "DAOL",
            "label": "Oran Tafaraoui Airport , Oran"
        },
        {
            "value": "TAG",
            "icao_code": "RPSP",
            "label": "Bohol\u2013Panglao International Airport , Tagbilaran"
        },
        {
            "value": "TAH",
            "icao_code": "NVVW",
            "label": "Whitegrass Airport , Tanna Island"
        },
        {
            "value": "TAI",
            "icao_code": "OYTZ",
            "label": "Taiz International Airport , Taiz"
        },
        {
            "value": "TAJ",
            "icao_code": null,
            "label": "Tadji Airport , Aitape"
        },
        {
            "value": "TAK",
            "icao_code": "RJOT",
            "label": "Takamatsu Airport , Takamatsu"
        },
        {
            "value": "TAL",
            "icao_code": "PATA",
            "label": "Ralph M. Calhoun Memorial Airport , Tanana"
        },
        {
            "value": "TAM",
            "icao_code": "MMTM",
            "label": "General Francisco Javier Mina International Airport , Tampico"
        },
        {
            "value": "TAN",
            "icao_code": "YTGA",
            "label": "Tangalooma Airport , Tangalooma"
        },
        {
            "value": "TAO",
            "icao_code": "ZSQD",
            "label": "Qingdao Jiaodong International Airport , Qingdao"
        },
        {
            "value": "TAP",
            "icao_code": "MMTP",
            "label": "Tapachula International Airport , Tapachula"
        },
        {
            "value": "TAQ",
            "icao_code": null,
            "label": "Tarcoola Airport , Tarcoola"
        },
        {
            "value": "TAR",
            "icao_code": "LIBG",
            "label": "Taranto-Grottaglie Airport , Taranto"
        },
        {
            "value": "TAS",
            "icao_code": "UTTT",
            "label": "Tashkent International Airport , Tashkent"
        },
        {
            "value": "TAT",
            "icao_code": "LZTT",
            "label": "Poprad\u2013Tatry Airport , Poprad"
        },
        {
            "value": "TAU",
            "icao_code": null,
            "label": "Tauramena Airport , Tauramena"
        },
        {
            "value": "TAV",
            "icao_code": null,
            "label": "Tau Airport , Tau"
        },
        {
            "value": "TAW",
            "icao_code": "SUTB",
            "label": "Tacuaremb\u00f3 Airport , Tacuaremb\u00f3"
        },
        {
            "value": "TAX",
            "icao_code": "WAPT",
            "label": "Taliabu Airport , Taliabu"
        },
        {
            "value": "TAY",
            "icao_code": "EETU",
            "label": "Tartu Airport , Tartu"
        },
        {
            "value": "TAZ",
            "icao_code": "UTAT",
            "label": "Da\u015foguz Airport , Da\u015foguz"
        },
        {
            "value": "TBA",
            "icao_code": null,
            "label": "Tabibuga Airport , Tabibuga"
        },
        {
            "value": "TBB",
            "icao_code": "VVTH",
            "label": "Dong Tac Airport , Tuy H\u00f2a"
        },
        {
            "value": "TBC",
            "icao_code": null,
            "label": "Tuba City Airport , Tuba City"
        },
        {
            "value": "TBD",
            "icao_code": "SKMB",
            "label": "Timbiqu\u00ed Airport , Timbiqu\u00ed"
        },
        {
            "value": "TBE",
            "icao_code": null,
            "label": "Timbunke Airport , Timbunke"
        },
        {
            "value": "TBF",
            "icao_code": "NGTE",
            "label": "Tabiteuea North Airport , Tabiteuea North"
        },
        {
            "value": "TBG",
            "icao_code": "AYTB",
            "label": "Tabubil Airport , Tabubil"
        },
        {
            "value": "TBH",
            "icao_code": "RPVU",
            "label": "Tugdan Airport , Tablas Island"
        },
        {
            "value": "TBI",
            "icao_code": "MYCB",
            "label": "New Bight Airport , New Bight"
        },
        {
            "value": "TBJ",
            "icao_code": "DTKA",
            "label": "Tabarka\u2013A\u00efn Draham International Airport , Tabarka"
        },
        {
            "value": "TBK",
            "icao_code": "YTBR",
            "label": "Timber Creek Airport , Timber Creek"
        },
        {
            "value": "TBL",
            "icao_code": "YTAB",
            "label": "Tableland Homestead Airport , Tableland"
        },
        {
            "value": "TBM",
            "icao_code": "WAOW",
            "label": "Tumbang Samba Airport , Tumbang Samba"
        },
        {
            "value": "TBN",
            "icao_code": "KTBN",
            "label": "Waynesville-St. Robert Regional Airport , Fort Leonard Wood"
        },
        {
            "value": "TBO",
            "icao_code": "HTTB",
            "label": "Tabora Airport , Tabora"
        },
        {
            "value": "TBP",
            "icao_code": "SPME",
            "label": "Cap. FAP Pedro Canga Rodr\u00edguez Airport , Tumbes"
        },
        {
            "value": "TBQ",
            "icao_code": null,
            "label": "Tarabo Airport , Tarabo"
        },
        {
            "value": "TBR",
            "icao_code": "KTBR",
            "label": "Statesboro\u2013Bulloch County Airport , Statesboro"
        },
        {
            "value": "TBS",
            "icao_code": "UGTB",
            "label": "Tbilisi International Airport , Tbilisi"
        },
        {
            "value": "TBT",
            "icao_code": "SBTT",
            "label": "Tabatinga International Airport , Tabatinga"
        },
        {
            "value": "TBU",
            "icao_code": "NFTF",
            "label": "Fua\u02bbamotu International Airport , Nuku\u02bbalofa"
        },
        {
            "value": "TBV",
            "icao_code": null,
            "label": "Tabal Airport , Tabal Island"
        },
        {
            "value": "TBW",
            "icao_code": "UUOT",
            "label": "Tambov Donskoye Airport , Tambov"
        },
        {
            "value": "TBY",
            "icao_code": "FBTS",
            "label": "Tshabong Airport , Tsabong"
        },
        {
            "value": "TBZ",
            "icao_code": "OITT",
            "label": "Tabriz International Airport , Tabriz"
        },
        {
            "value": "TCA",
            "icao_code": "YTNK",
            "label": "Tennant Creek Airport , Tennant Creek"
        },
        {
            "value": "TCB",
            "icao_code": "MYAT",
            "label": "Treasure Cay Airport , Treasure Cay"
        },
        {
            "value": "TCC",
            "icao_code": "KTCC",
            "label": "Tucumcari Municipal Airport , Tucumcari"
        },
        {
            "value": "TCD",
            "icao_code": "SKRA",
            "label": "Tarapac\u00e1 Airport , Tarapac\u00e1"
        },
        {
            "value": "TCE",
            "icao_code": "LRTC",
            "label": "Tulcea Danube Delta Airport , Tulcea"
        },
        {
            "value": "TCF",
            "icao_code": null,
            "label": "Tocoa Airport , Tocoa"
        },
        {
            "value": "TCG",
            "icao_code": "ZWTC",
            "label": "Tacheng Airport , Tacheng"
        },
        {
            "value": "TCH",
            "icao_code": "FOOT",
            "label": "Tchibanga Airport , Tchibanga"
        },
        {
            "value": "TCI",
            "icao_code": null,
            "label": "metropolitan area , Tenerife"
        },
        {
            "value": "TCJ",
            "icao_code": null,
            "label": "Torembi Airport , Torembi"
        },
        {
            "value": "TCK",
            "icao_code": null,
            "label": "Tinboli Airport , Tinboli"
        },
        {
            "value": "TCL",
            "icao_code": "KTCL",
            "label": "Tuscaloosa Regional Airport , Tuscaloosa"
        },
        {
            "value": "TCM",
            "icao_code": "KTCM",
            "label": "McChord Field , Tacoma"
        },
        {
            "value": "TCN",
            "icao_code": "MMHC",
            "label": "Tehuac\u00e1n Airport , Tehuac\u00e1n"
        },
        {
            "value": "TCO",
            "icao_code": "SKCO",
            "label": "La Florida Airport , Tumaco"
        },
        {
            "value": "TCP",
            "icao_code": "HETB",
            "label": "Taba International Airport , Taba"
        },
        {
            "value": "TCQ",
            "icao_code": "SPTN",
            "label": "Coronel FAP Carlos Ciriani Santa Rosa International Airport , Tacna"
        },
        {
            "value": "TCR",
            "icao_code": "VOTK",
            "label": "Tuticorin Airport , Thoothukudi"
        },
        {
            "value": "TCS",
            "icao_code": "KTCS",
            "label": "Truth or Consequences Municipal Airport , Truth or Consequences"
        },
        {
            "value": "TCT",
            "icao_code": null,
            "label": "Takotna Airport , Takotna"
        },
        {
            "value": "TCU",
            "icao_code": "FATN",
            "label": "Thaba Nchu Airport , Thaba 'Nchu"
        },
        {
            "value": "TCV",
            "icao_code": "FQTE",
            "label": "Tete Airport , Tete"
        },
        {
            "value": "TCW",
            "icao_code": "YTOC",
            "label": "Tocumwal Airport , Tocumwal"
        },
        {
            "value": "TCX",
            "icao_code": "OIMT",
            "label": "Tabas Airport , Tabas"
        },
        {
            "value": "TCY",
            "icao_code": "FYTE",
            "label": "Terrace Bay Airport , Terrace Bay"
        },
        {
            "value": "TCZ",
            "icao_code": "ZUTC",
            "label": "Tengchong Tuofeng Airport , Tengchong"
        },
        {
            "value": "TDA",
            "icao_code": "SKTD",
            "label": "Trinidad Airport , Trinidad"
        },
        {
            "value": "TDB",
            "icao_code": null,
            "label": "Tetebedi Airport , Tetebedi"
        },
        {
            "value": "TDD",
            "icao_code": "SLTR",
            "label": "Teniente Jorge Henrich Arauz Airport , Trinidad"
        },
        {
            "value": "TDG",
            "icao_code": "RPMW",
            "label": "Tandag Airport , Tandag"
        },
        {
            "value": "TDJ",
            "icao_code": "HDTJ",
            "label": "Tadjoura Airport , Tadjoura"
        },
        {
            "value": "TDK",
            "icao_code": null,
            "label": "Taldykorgan Airport , Taldykorgan"
        },
        {
            "value": "TDL",
            "icao_code": "SAZT",
            "label": "Tandil Airport , Tandil"
        },
        {
            "value": "TDN",
            "icao_code": "YTHD",
            "label": "Theda Station Airport , Theda Station"
        },
        {
            "value": "TDO",
            "icao_code": "KTDO",
            "label": "South Lewis County Airport , Toledo"
        },
        {
            "value": "TDP",
            "icao_code": "SPDR",
            "label": "Trompeteros Airport , Trompeteros"
        },
        {
            "value": "TDR",
            "icao_code": "YTDR",
            "label": "Theodore Airport , Theodore"
        },
        {
            "value": "TDS",
            "icao_code": "AYSS",
            "label": "Sasereme Airport , Sasereme"
        },
        {
            "value": "TDT",
            "icao_code": null,
            "label": "Tanda Tula Airport , Timbavati"
        },
        {
            "value": "TDV",
            "icao_code": "FMSN",
            "label": "Samangoky Airport , Tanandava"
        },
        {
            "value": "TDW",
            "icao_code": "KTDW",
            "label": "Tradewind Airport , Amarillo"
        },
        {
            "value": "TDX",
            "icao_code": "VTBO",
            "label": "Trat Airport , Trat"
        },
        {
            "value": "TDZ",
            "icao_code": "KTDZ",
            "label": "Toledo Executive Airport , Toledo"
        },
        {
            "value": "TEA",
            "icao_code": "MHTE",
            "label": "Tela Airport , Tela"
        },
        {
            "value": "TEB",
            "icao_code": "KTEB",
            "label": "Teterboro Airport , Teterboro"
        },
        {
            "value": "TEC",
            "icao_code": "SBTL",
            "label": "Tel\u00eamaco Borba Airport , Tel\u00eamaco Borba"
        },
        {
            "value": "TED",
            "icao_code": "EKTS",
            "label": "Thisted Airport , Thisted"
        },
        {
            "value": "TEE",
            "icao_code": "DABS",
            "label": "Cheikh Larbi T\u00e9bessi Airport , T\u00e9bessa"
        },
        {
            "value": "TEF",
            "icao_code": "YTEF",
            "label": "Telfer Airport , Telfer"
        },
        {
            "value": "TEG",
            "icao_code": "DFET",
            "label": "Tenkodogo Airport , Tenkodogo"
        },
        {
            "value": "TEH",
            "icao_code": null,
            "label": "Tetlin Airport , Tetlin"
        },
        {
            "value": "TEI",
            "icao_code": "VETJ",
            "label": "Tezu Airport , Tezu"
        },
        {
            "value": "TEK",
            "icao_code": "PAKA",
            "label": "Tatitlek Airport , Tatitlek"
        },
        {
            "value": "TEL",
            "icao_code": "WBKE",
            "label": "Telupid Airport , Telupid"
        },
        {
            "value": "TEM",
            "icao_code": "YTEM",
            "label": "Temora Airport , Temora"
        },
        {
            "value": "TEN",
            "icao_code": "ZUTR",
            "label": "Tongren Fenghuang Airport , Tongren"
        },
        {
            "value": "TEO",
            "icao_code": null,
            "label": "Terapo Airport , Terapo"
        },
        {
            "value": "TEP",
            "icao_code": null,
            "label": "Teptep Airport , Teptep"
        },
        {
            "value": "TEQ",
            "icao_code": "LTBU",
            "label": "Tekirda\u011f \u00c7orlu Airport , Tekirda\u011f"
        },
        {
            "value": "TER",
            "icao_code": "LPLA",
            "label": "Lajes Airport , Terceira Island"
        },
        {
            "value": "TES",
            "icao_code": "HHTS",
            "label": "Teseney Airport , Teseney"
        },
        {
            "value": "TET",
            "icao_code": "FQTT",
            "label": "Chingozi Airport , Tete"
        },
        {
            "value": "TEU",
            "icao_code": "NZMO",
            "label": "Te Anau Airport , Te Anau"
        },
        {
            "value": "TEV",
            "icao_code": "LETL",
            "label": "Teruel Airport , Teruel"
        },
        {
            "value": "TEX",
            "icao_code": "KTEX",
            "label": "Telluride Regional Airport , Telluride"
        },
        {
            "value": "TEY",
            "icao_code": "BITE",
            "label": "Thingeyri Airport , Thingeyri"
        },
        {
            "value": "TEZ",
            "icao_code": "VETZ",
            "label": "Tezpur Airport , Tezpur"
        },
        {
            "value": "TFB",
            "icao_code": null,
            "label": "Tifalmin Airport , Tifalmin"
        },
        {
            "value": "TFF",
            "icao_code": "SBTF",
            "label": "Tef\u00e9 Airport , Tef\u00e9"
        },
        {
            "value": "TFI",
            "icao_code": "AYTU",
            "label": "Tufi Airport , Tufi"
        },
        {
            "value": "TFL",
            "icao_code": "SNTO",
            "label": "Te\u00f3filo Otoni Airport , Te\u00f3filo Otoni"
        },
        {
            "value": "TFM",
            "icao_code": "AYTE",
            "label": "Telefomin Airport , Telefomin"
        },
        {
            "value": "TFN",
            "icao_code": "GCXO",
            "label": "Tenerife\u2013North Airport , Tenerife"
        },
        {
            "value": "TFR",
            "icao_code": "HE13",
            "label": "Wadi al Jandali Airport , 10th of Ramadan City"
        },
        {
            "value": "TFS",
            "icao_code": "GCTS",
            "label": "Tenerife\u2013South Airport , Tenerife"
        },
        {
            "value": "TFT",
            "icao_code": null,
            "label": "Taftan Airport , Taftan"
        },
        {
            "value": "TFU",
            "icao_code": null,
            "label": "Chengdu Tianfu International Airport , Jianyang"
        },
        {
            "value": "TGA",
            "icao_code": "WSAT",
            "label": "Tengah Air Base , Singapore"
        },
        {
            "value": "TGB",
            "icao_code": null,
            "label": "Tagbita Airport , Rizal"
        },
        {
            "value": "TGC",
            "icao_code": "WBTM",
            "label": "Tanjung Manis Airport , Tanjung Manis"
        },
        {
            "value": "TGD",
            "icao_code": "LYPG",
            "label": "Podgorica Airport , Podgorica"
        },
        {
            "value": "TGE",
            "icao_code": null,
            "label": "Sharpe Field , Tuskegee"
        },
        {
            "value": "TGG",
            "icao_code": "WMKN",
            "label": "Sultan Mahmud Airport , Kuala Terengganu"
        },
        {
            "value": "TGH",
            "icao_code": "NVST",
            "label": "Tongoa Airport , Tongoa"
        },
        {
            "value": "TGI",
            "icao_code": "SPGM",
            "label": "Tingo Mar\u00eda Airport , Tingo Mar\u00eda"
        },
        {
            "value": "TGJ",
            "icao_code": "NWWA",
            "label": "Tiga Airport , Tiga Island"
        },
        {
            "value": "TGK",
            "icao_code": "URRT",
            "label": "Taganrog Airport , Taganrog"
        },
        {
            "value": "TGL",
            "icao_code": null,
            "label": "Tagula Airport , Vanatinai"
        },
        {
            "value": "TGM",
            "icao_code": "LRTM",
            "label": "T\u00e2rgu Mure\u0219 International Airport , T\u00e2rgu Mure\u0219"
        },
        {
            "value": "TGN",
            "icao_code": "YLTV",
            "label": "Latrobe Regional Airport , Traralgon"
        },
        {
            "value": "TGO",
            "icao_code": "ZBTL",
            "label": "Tongliao Airport , Tongliao"
        },
        {
            "value": "TGP",
            "icao_code": "UNIP",
            "label": "Podkamennaya Tunguska Airport , Bor"
        },
        {
            "value": "TGQ",
            "icao_code": "SWTS",
            "label": "Tangar\u00e1 da Serra Airport , Tangar\u00e1 da Serra"
        },
        {
            "value": "TGR",
            "icao_code": "DAUK",
            "label": "Sidi Mahdi Airport , Touggourt"
        },
        {
            "value": "TGS",
            "icao_code": null,
            "label": "Chokwe Airport , Chokwe"
        },
        {
            "value": "TGT",
            "icao_code": "HTTG",
            "label": "Tanga Airport , Tanga"
        },
        {
            "value": "TGU",
            "icao_code": "MHTG",
            "label": "Toncont\u00edn International Airport , Tegucigalpa"
        },
        {
            "value": "TGV",
            "icao_code": "LBTG",
            "label": "Targovishte Airport , Targovishte"
        },
        {
            "value": "TGZ",
            "icao_code": "MMTG",
            "label": "\u00c1ngel Albino Corzo International Airport , Tuxtla Guti\u00e9rrez"
        },
        {
            "value": "THA",
            "icao_code": "KTHA",
            "label": "Tullahoma Regional Airport , Tullahoma"
        },
        {
            "value": "THB",
            "icao_code": "FXTA",
            "label": "Thaba Tseka Airport , Thaba-Tseka"
        },
        {
            "value": "THC",
            "icao_code": "GLTN",
            "label": "Tchien Airport , Tchien"
        },
        {
            "value": "THD",
            "icao_code": "VVTX",
            "label": "Tho Xuan Airport , Thanh H\u00f3a"
        },
        {
            "value": "THE",
            "icao_code": "SBTE",
            "label": "Teresina\u2013Senador Petr\u00f4nio Portella Airport , Teresina"
        },
        {
            "value": "THG",
            "icao_code": "YTNG",
            "label": "Thangool Airport , Thangool"
        },
        {
            "value": "THH",
            "icao_code": null,
            "label": "Taharoa Aerodrome , Taharoa"
        },
        {
            "value": "THI",
            "icao_code": "GQNC",
            "label": "Tichitt Airport , Tichit"
        },
        {
            "value": "THK",
            "icao_code": "VLTK",
            "label": "Thakhek Airport , Thakhek"
        },
        {
            "value": "THL",
            "icao_code": "VYTL",
            "label": "Tachilek Airport , Tachileik"
        },
        {
            "value": "THM",
            "icao_code": "KTHM",
            "label": "Thompson Falls Airport , Thompson Falls"
        },
        {
            "value": "THN",
            "icao_code": "ESGT",
            "label": "Trollh\u00e4ttan\u2013V\u00e4nersborg Airport , Trollh\u00e4ttan"
        },
        {
            "value": "THO",
            "icao_code": "BITN",
            "label": "Thorshofn Airport , Thorshofn"
        },
        {
            "value": "THP",
            "icao_code": "KTHP",
            "label": "Hot Springs County\u2013Thermopolis Municipal Airport , Thermopolis"
        },
        {
            "value": "THQ",
            "icao_code": "ZLTS",
            "label": "Tianshui Maijishan Airport , Tianshui"
        },
        {
            "value": "THR",
            "icao_code": "OIII",
            "label": "Mehrabad International Airport , Tehran"
        },
        {
            "value": "THS",
            "icao_code": "VTPO",
            "label": "Sukhothai Airport , Sukhothai"
        },
        {
            "value": "THT",
            "icao_code": "GQNT",
            "label": "Tamchakett Airport , Tamchakett"
        },
        {
            "value": "THU",
            "icao_code": "BGTL",
            "label": "Pituffik Space Base , Pituffik"
        },
        {
            "value": "THV",
            "icao_code": "KTHV",
            "label": "York Airport , York"
        },
        {
            "value": "THW",
            "icao_code": null,
            "label": "Trincomalee Harbour Seaplane Base , Trincomalee"
        },
        {
            "value": "THX",
            "icao_code": "UOTT",
            "label": "Turukhansk Airport , Turukhansk"
        },
        {
            "value": "THY",
            "icao_code": "FATH",
            "label": "P.R. Mphephu Airport , Thohoyandou"
        },
        {
            "value": "THZ",
            "icao_code": "DRRT",
            "label": "Tahoua Airport , Tahoua"
        },
        {
            "value": "TIA",
            "icao_code": "LATI",
            "label": "Tirana International Airport N\u00ebn\u00eb Tereza , Tirana"
        },
        {
            "value": "TIB",
            "icao_code": "SKTB",
            "label": "Tib\u00fa Airport , Tib\u00fa"
        },
        {
            "value": "TIC",
            "icao_code": null,
            "label": "Tinak Airport , Tinak Island"
        },
        {
            "value": "TID",
            "icao_code": "DAOB",
            "label": "Abdelhafid Boussouf Bou Chekif Airport , Tiaret"
        },
        {
            "value": "TIE",
            "icao_code": "HATP",
            "label": "Tippi Airport , Tepi"
        },
        {
            "value": "TIF",
            "icao_code": "OETF",
            "label": "Ta'if Regional Airport , Ta'if"
        },
        {
            "value": "TIG",
            "icao_code": null,
            "label": "Tingwon Airport , Tingwon"
        },
        {
            "value": "TIH",
            "icao_code": "NTGC",
            "label": "Tikehau Airport , Tikehau"
        },
        {
            "value": "TII",
            "icao_code": "OATN",
            "label": "Tarinkot Airport , Tarinkot"
        },
        {
            "value": "TIJ",
            "icao_code": "MMTJ",
            "label": "Tijuana International Airport , Tijuana"
        },
        {
            "value": "TIK",
            "icao_code": "KTIK",
            "label": "Tinker Air Force Base , Oklahoma City"
        },
        {
            "value": "TIL",
            "icao_code": null,
            "label": "Cheadle Airport , Cheadle"
        },
        {
            "value": "TIM",
            "icao_code": "WABP",
            "label": "Mozes Kilangin Airport , Tembagapura"
        },
        {
            "value": "TIN",
            "icao_code": "DAOF",
            "label": "Tindouf Airport , Tindouf"
        },
        {
            "value": "TIO",
            "icao_code": "VYHN",
            "label": "Tilin Airport , Htilin"
        },
        {
            "value": "TIP",
            "icao_code": "HLLT",
            "label": "Tripoli International Airport , Tripoli"
        },
        {
            "value": "TIQ",
            "icao_code": "PGWT",
            "label": "Tinian International Airport , Tinian"
        },
        {
            "value": "TIR",
            "icao_code": "VOTP",
            "label": "Tirupati Airport , Tirupati"
        },
        {
            "value": "TIS",
            "icao_code": null,
            "label": "Thursday Island Airport , Thursday Island"
        },
        {
            "value": "TIU",
            "icao_code": "NZTU",
            "label": "Richard Pearse Airport , Timaru"
        },
        {
            "value": "TIV",
            "icao_code": "LYTV",
            "label": "Tivat Airport , Tivat"
        },
        {
            "value": "TIW",
            "icao_code": "KTIW",
            "label": "Tacoma Narrows Airport , Tacoma"
        },
        {
            "value": "TIX",
            "icao_code": "KTIX",
            "label": "Space Coast Regional Airport , Titusville"
        },
        {
            "value": "TIY",
            "icao_code": "GQND",
            "label": "Tidjikja Airport , Tidjikja"
        },
        {
            "value": "TIZ",
            "icao_code": "AYTA",
            "label": "Tari Airport , Tari"
        },
        {
            "value": "TJA",
            "icao_code": "SLTJ",
            "label": "Capit\u00e1n Oriel Lea Plaza Airport , Tarija"
        },
        {
            "value": "TJB",
            "icao_code": "WIBT",
            "label": "Sei Bati Airport , Tanjung Balai"
        },
        {
            "value": "TJC",
            "icao_code": null,
            "label": "Ticantiqu\u00ed Airport , Ticantiqu\u00ed"
        },
        {
            "value": "TJG",
            "icao_code": "WAON",
            "label": "Warukin Airport , Tanjung"
        },
        {
            "value": "TJH",
            "icao_code": "RJBT",
            "label": "Tajima Airport , Toyooka"
        },
        {
            "value": "TJI",
            "icao_code": "MHTJ",
            "label": "Trujillo Airport , Trujillo"
        },
        {
            "value": "TJK",
            "icao_code": "LTAW",
            "label": "Tokat Airport , Tokat"
        },
        {
            "value": "TJL",
            "icao_code": "SBTG",
            "label": "Pl\u00ednio Alarcom Airport , Tr\u00eas Lagoas"
        },
        {
            "value": "TJM",
            "icao_code": "USTR",
            "label": "Roshchino International Airport , Tyumen"
        },
        {
            "value": "TJN",
            "icao_code": "NTKM",
            "label": "Takume Airport , Takume"
        },
        {
            "value": "TJQ",
            "icao_code": "WIKD",
            "label": "H.A.S. Hanandjoeddin Airport , Tanjung Pandan"
        },
        {
            "value": "TJS",
            "icao_code": "WAGD",
            "label": "Tanjung Harapan Airport , Tanjung Selor"
        },
        {
            "value": "TJU",
            "icao_code": "UTDK",
            "label": "Kulob Airport , Kulob"
        },
        {
            "value": "TJV",
            "icao_code": "VOTJ",
            "label": "Thanjavur Air Force Station , Thanjavur"
        },
        {
            "value": "TKA",
            "icao_code": "PATK",
            "label": "Talkeetna Airport , Talkeetna"
        },
        {
            "value": "TKB",
            "icao_code": null,
            "label": "Tekadu Airport , Tekadu"
        },
        {
            "value": "TKC",
            "icao_code": "FKKC",
            "label": "Tiko Airport , Tiko"
        },
        {
            "value": "TKD",
            "icao_code": "DGTK",
            "label": "Takoradi Airport , Sekondi-Takoradi"
        },
        {
            "value": "TKE",
            "icao_code": null,
            "label": "Tenakee Seaplane Base , Tenakee Springs"
        },
        {
            "value": "TKF",
            "icao_code": "KTRK",
            "label": "Truckee Tahoe Airport , Truckee"
        },
        {
            "value": "TKG",
            "icao_code": "WILL",
            "label": "Radin Inten II Airport , Bandar Lampung"
        },
        {
            "value": "TKH",
            "icao_code": "VTPI",
            "label": "Takhli Royal Thai Air Force Base , Takhli"
        },
        {
            "value": "TKI",
            "icao_code": null,
            "label": "Tokeen Seaplane Base , Tokeen"
        },
        {
            "value": "TKJ",
            "icao_code": "PFTO",
            "label": "Tok Junction Airport , Tok"
        },
        {
            "value": "TKK",
            "icao_code": "PTKK",
            "label": "Chuuk International Airport , Chuuk"
        },
        {
            "value": "TKL",
            "icao_code": null,
            "label": "Taku Lodge Seaplane Base , Taku Lodge"
        },
        {
            "value": "TKN",
            "icao_code": "RJKN",
            "label": "Tokunoshima Airport , Tokunoshima"
        },
        {
            "value": "TKO",
            "icao_code": "FXTK",
            "label": "Tlokoeng Airport , Tlokoeng"
        },
        {
            "value": "TKP",
            "icao_code": "NTGT",
            "label": "Takapoto Airport , Takapoto"
        },
        {
            "value": "TKQ",
            "icao_code": "HTKA",
            "label": "Kigoma Airport , Kigoma"
        },
        {
            "value": "TKR",
            "icao_code": "VGSG",
            "label": "Thakurgaon Airport , Thakurgaon"
        },
        {
            "value": "TKS",
            "icao_code": "RJOS",
            "label": "Tokushima Airport , Tokushima"
        },
        {
            "value": "TKT",
            "icao_code": "VTPT",
            "label": "Tak Airport , Tak"
        },
        {
            "value": "TKU",
            "icao_code": "EFTU",
            "label": "Turku Airport , Turku"
        },
        {
            "value": "TKV",
            "icao_code": "NTGO",
            "label": "Tatakoto Airport , Tatakoto"
        },
        {
            "value": "TKW",
            "icao_code": "AYTN",
            "label": "Tekin Airport , Tekin"
        },
        {
            "value": "TKX",
            "icao_code": "NTKR",
            "label": "Takaroa Airport , Takaroa"
        },
        {
            "value": "TKY",
            "icao_code": "YTKY",
            "label": "Turkey Creek Airport , Warmun"
        },
        {
            "value": "TKZ",
            "icao_code": "NZTO",
            "label": "Tokoroa Aerodrome , Tokoroa"
        },
        {
            "value": "TLA",
            "icao_code": "PATE",
            "label": "Teller Airport , Teller"
        },
        {
            "value": "TLB",
            "icao_code": "OPTA",
            "label": "Tarbela Dam Airport , Tarbela Dam"
        },
        {
            "value": "TLC",
            "icao_code": "MMTO",
            "label": "Licenciado Adolfo L\u00f3pez Mateos International Airport , Toluca"
        },
        {
            "value": "TLD",
            "icao_code": "FBTL",
            "label": "Tuli Lodge Airport , Tuli Lodge"
        },
        {
            "value": "TLE",
            "icao_code": "FMST",
            "label": "Toliara Airport , Toliara"
        },
        {
            "value": "TLF",
            "icao_code": null,
            "label": "Telida Airport , Telida"
        },
        {
            "value": "TLG",
            "icao_code": "AGTI",
            "label": "Tulaghi Heliport , Tulagi"
        },
        {
            "value": "TLH",
            "icao_code": "KTLH",
            "label": "Tallahassee International Airport , Tallahassee"
        },
        {
            "value": "TLI",
            "icao_code": "WAMI",
            "label": "Sultan Bantilan Airport , Tolitoli"
        },
        {
            "value": "TLJ",
            "icao_code": "PATL",
            "label": "Tatalina LRRS Airport , Tatalina"
        },
        {
            "value": "TLK",
            "icao_code": "UECT",
            "label": "Talakan Airport , Talakan"
        },
        {
            "value": "TLL",
            "icao_code": "EETN",
            "label": "Tallinn Airport , Tallinn"
        },
        {
            "value": "TLM",
            "icao_code": "DAON",
            "label": "Zenata \u2013 Messali El Hadj Airport , Tlemcen"
        },
        {
            "value": "TLN",
            "icao_code": "LFTH",
            "label": "Toulon\u2013Hy\u00e8res Airport , Toulon"
        },
        {
            "value": "TLO",
            "icao_code": null,
            "label": "Tol Airport , Tol"
        },
        {
            "value": "TLP",
            "icao_code": null,
            "label": "Tumolbil Airport , Tumolbil"
        },
        {
            "value": "TLQ",
            "icao_code": "ZWTP",
            "label": "Turpan Jiaohe Airport , Turpan"
        },
        {
            "value": "TLR",
            "icao_code": "KTLR",
            "label": "Mefford Field Airport , Tulare"
        },
        {
            "value": "TLS",
            "icao_code": "LFBO",
            "label": "Toulouse\u2013Blagnac Airport , Toulouse"
        },
        {
            "value": "TLT",
            "icao_code": null,
            "label": "Tuluksak Airport , Tuluksak"
        },
        {
            "value": "TLU",
            "icao_code": "SKTL",
            "label": "Golfo de Morrosquillo Airport , Tol\u00fa"
        },
        {
            "value": "TLV",
            "icao_code": "LLBG",
            "label": "Ben Gurion Airport , Tel Aviv"
        },
        {
            "value": "TLW",
            "icao_code": "AYVL",
            "label": "Talasea Airport , Talasea"
        },
        {
            "value": "TLX",
            "icao_code": "SCTL",
            "label": "Panguilemo Airport , Talca"
        },
        {
            "value": "TLY",
            "icao_code": "UHWP",
            "label": "Plastun Airport , Plastun"
        },
        {
            "value": "TLZ",
            "icao_code": "SWKT",
            "label": "Catal\u00e3o Airport , Catal\u00e3o"
        },
        {
            "value": "TMA",
            "icao_code": "KTMA",
            "label": "Henry Tift Myers Airport , Tifton"
        },
        {
            "value": "TMB",
            "icao_code": "KTMB",
            "label": "Miami Executive Airport , Miami"
        },
        {
            "value": "TMC",
            "icao_code": "WADT",
            "label": "Tambolaka Airport , Tambolaka"
        },
        {
            "value": "TMD",
            "icao_code": "GQNH",
            "label": "Timbedra Airport , Timb\u00e9dra"
        },
        {
            "value": "TME",
            "icao_code": "SKTM",
            "label": "Gabriel Vargas Santos Airport , Tame"
        },
        {
            "value": "TMF",
            "icao_code": "VRNT",
            "label": "Thimarafushi Airport , Thimarafushi"
        },
        {
            "value": "TMG",
            "icao_code": "WBKM",
            "label": "Tommanggong Airport , Tommanggong"
        },
        {
            "value": "TMH",
            "icao_code": "WAKT",
            "label": "Tanah Merah Airport , Tanahmerah"
        },
        {
            "value": "TMI",
            "icao_code": "VNTR",
            "label": "Tumlingtar Airport , Tumlingtar"
        },
        {
            "value": "TMJ",
            "icao_code": "UTST",
            "label": "Termez Airport , Termez"
        },
        {
            "value": "TMK",
            "icao_code": null,
            "label": "Tam K\u1ef3 Airport , Tam K\u1ef3"
        },
        {
            "value": "TML",
            "icao_code": "DGLE",
            "label": "Tamale Airport , Tamale"
        },
        {
            "value": "TMM",
            "icao_code": "FMMT",
            "label": "Toamasina Airport , Toamasina"
        },
        {
            "value": "TMN",
            "icao_code": "NGTM",
            "label": "Tamana Airport , Tamana"
        },
        {
            "value": "TMO",
            "icao_code": "SVTM",
            "label": "Tumeremo Airport , Tumeremo"
        },
        {
            "value": "TMP",
            "icao_code": "EFTP",
            "label": "Tampere\u2013Pirkkala Airport , Tampere"
        },
        {
            "value": "TMQ",
            "icao_code": "DFEM",
            "label": "Tambao Airport , Tambao"
        },
        {
            "value": "TMR",
            "icao_code": "DAAT",
            "label": "Aguenar \u2013 Hadj Bey Akhamok Airport , Tamanrasset"
        },
        {
            "value": "TMS",
            "icao_code": "FPST",
            "label": "S\u00e3o Tom\u00e9 International Airport , S\u00e3o Tom\u00e9"
        },
        {
            "value": "TMT",
            "icao_code": "SBTB",
            "label": "Porto Trombetas Airport , Porto Trombetas"
        },
        {
            "value": "TMU",
            "icao_code": "MRTR",
            "label": "Tambor Airport , Tambor"
        },
        {
            "value": "TMW",
            "icao_code": "YSTW",
            "label": "Tamworth Airport , Tamworth"
        },
        {
            "value": "TMX",
            "icao_code": "DAUT",
            "label": "Timimoun Airport , Timimoun"
        },
        {
            "value": "TMY",
            "icao_code": null,
            "label": "Tiom Airport , Tiom"
        },
        {
            "value": "TMZ",
            "icao_code": "NZTH",
            "label": "Thames Aerodrome , Thames"
        },
        {
            "value": "TNA",
            "icao_code": "ZSJN",
            "label": "Jinan Yaoqiang International Airport , Jinan"
        },
        {
            "value": "TNB",
            "icao_code": "WRLH",
            "label": "Tanah Grogot Airport , Tanah Grogot"
        },
        {
            "value": "TNC",
            "icao_code": "PATC",
            "label": "Tin City LRRS Airport , Tin City"
        },
        {
            "value": "TND",
            "icao_code": "MUTD",
            "label": "Alberto Delgado Airport , Trinidad"
        },
        {
            "value": "TNE",
            "icao_code": "RJFG",
            "label": "New Tanegashima Airport , Tanegashima"
        },
        {
            "value": "TNF",
            "icao_code": "LFPN",
            "label": "Toussus-le-Noble Airport , Toussus-le-Noble"
        },
        {
            "value": "TNG",
            "icao_code": "GMTT",
            "label": "Tangier Ibn Battouta Airport , Tangier"
        },
        {
            "value": "TNH",
            "icao_code": "ZYTN",
            "label": "Tonghua Sanyuanpu Airport , Tonghua"
        },
        {
            "value": "TNI",
            "icao_code": "VIST",
            "label": "Satna Airport , Satna"
        },
        {
            "value": "TNJ",
            "icao_code": "WIDN",
            "label": "Raja Haji Fisabilillah Airport , Tanjung Pinang"
        },
        {
            "value": "TNK",
            "icao_code": null,
            "label": "Tununak Airport , Tununak"
        },
        {
            "value": "TNL",
            "icao_code": "UKLT",
            "label": "Ternopil International Airport , Ternopil"
        },
        {
            "value": "TNM",
            "icao_code": "SCRM",
            "label": "Teniente R. Marsh Airport , King George Island"
        },
        {
            "value": "TNN",
            "icao_code": "RCNN",
            "label": "Tainan Airport , Tainan"
        },
        {
            "value": "TNO",
            "icao_code": "MRTM",
            "label": "Tamarindo Airport , Tamarindo"
        },
        {
            "value": "TNP",
            "icao_code": "KTNP",
            "label": "Twentynine Palms Airport , Twentynine Palms"
        },
        {
            "value": "TNQ",
            "icao_code": null,
            "label": "Teraina Airport , Teraina"
        },
        {
            "value": "TNR",
            "icao_code": "FMMI",
            "label": "Ivato International Airport , Antananarivo"
        },
        {
            "value": "TNS",
            "icao_code": null,
            "label": "Tungsten (Cantung) Airport , Tungsten"
        },
        {
            "value": "TNT",
            "icao_code": "KTNT",
            "label": "Dade-Collier Training and Transition Airport , Miami"
        },
        {
            "value": "TNU",
            "icao_code": "KTNU",
            "label": "Newton Municipal Airport , Newton"
        },
        {
            "value": "TNV",
            "icao_code": null,
            "label": "Tabuaeran Island Airport , Tabuaeran"
        },
        {
            "value": "TNW",
            "icao_code": "SEJD",
            "label": "Jumandy Airport , Tena"
        },
        {
            "value": "TNX",
            "icao_code": "VDST",
            "label": "Steung Treng Airport , Stung Treng"
        },
        {
            "value": "TNZ",
            "icao_code": "ZMTL",
            "label": "Tosontsengel Airport , Tosontsengel"
        },
        {
            "value": "TOA",
            "icao_code": "KTOA",
            "label": "Zamperini Field , Torrance"
        },
        {
            "value": "TOB",
            "icao_code": "HLGN",
            "label": "Tobruk Airport , Tobruk"
        },
        {
            "value": "TOC",
            "icao_code": "KTOC",
            "label": "Toccoa Airport , Toccoa"
        },
        {
            "value": "TOD",
            "icao_code": "WMBT",
            "label": "Tioman Airport , Tioman Island"
        },
        {
            "value": "TOE",
            "icao_code": "DTTZ",
            "label": "Tozeur\u2013Nefta International Airport , Tozeur"
        },
        {
            "value": "TOF",
            "icao_code": "UNTT",
            "label": "Bogashevo Airport , Tomsk"
        },
        {
            "value": "TOG",
            "icao_code": "PATG",
            "label": "Togiak Airport , Togiak"
        },
        {
            "value": "TOH",
            "icao_code": "NVSD",
            "label": "Torres Airport , Torres Islands"
        },
        {
            "value": "TOI",
            "icao_code": "KTOI",
            "label": "Troy Municipal Airport , Troy"
        },
        {
            "value": "TOJ",
            "icao_code": "LETO",
            "label": "Madrid\u2013Torrej\u00f3n Airport , Madrid"
        },
        {
            "value": "TOK",
            "icao_code": null,
            "label": "Torokina Airport , Torokina"
        },
        {
            "value": "TOL",
            "icao_code": "KTOL",
            "label": "Toledo Express Airport , Toledo"
        },
        {
            "value": "TOM",
            "icao_code": "GATB",
            "label": "Timbuktu Airport , Timbuktu"
        },
        {
            "value": "TON",
            "icao_code": null,
            "label": "Tonu Airport , Tonu"
        },
        {
            "value": "TOO",
            "icao_code": "MRSV",
            "label": "San Vito de Java Airport , San Vito"
        },
        {
            "value": "TOP",
            "icao_code": "KTOP",
            "label": "Philip Billard Municipal Airport , Topeka"
        },
        {
            "value": "TOQ",
            "icao_code": "SCBE",
            "label": "Barriles Airport , Tocopilla"
        },
        {
            "value": "TOR",
            "icao_code": "KTOR",
            "label": "Torrington Municipal Airport , Torrington"
        },
        {
            "value": "TOS",
            "icao_code": "ENTC",
            "label": "Troms\u00f8 Airport, Langnes , Troms\u00f8"
        },
        {
            "value": "TOT",
            "icao_code": "SMCO",
            "label": "Totness Airstrip , Totness"
        },
        {
            "value": "TOU",
            "icao_code": "NWWU",
            "label": "Touho Airport , Touho"
        },
        {
            "value": "TOV",
            "icao_code": null,
            "label": "West End Seaplane Base , Tortola"
        },
        {
            "value": "TOW",
            "icao_code": "SBTD",
            "label": "Luiz dal Canalle Filho Airport , Toledo"
        },
        {
            "value": "TOX",
            "icao_code": "USTO",
            "label": "Tobolsk Airport , Tobolsk"
        },
        {
            "value": "TOY",
            "icao_code": "RJNT",
            "label": "Toyama Airport , Toyama"
        },
        {
            "value": "TOZ",
            "icao_code": "DITM",
            "label": "Mahana Airport , Touba"
        },
        {
            "value": "TPA",
            "icao_code": "KTPA",
            "label": "Tampa International Airport , Tampa"
        },
        {
            "value": "TPC",
            "icao_code": "SETR",
            "label": "Tarapoa Airport , Tarapoa"
        },
        {
            "value": "TPE",
            "icao_code": "RCTP",
            "label": "Taiwan Taoyuan International Airport , Taoyuan"
        },
        {
            "value": "TPF",
            "icao_code": "KTPF",
            "label": "Peter O. Knight Airport , Tampa"
        },
        {
            "value": "TPG",
            "icao_code": "WMBI",
            "label": "Taiping Airport , Taiping"
        },
        {
            "value": "TPH",
            "icao_code": "KTPH",
            "label": "Tonopah Airport , Tonopah"
        },
        {
            "value": "TPI",
            "icao_code": "AYTI",
            "label": "Tapini Airport , Tapini"
        },
        {
            "value": "TPJ",
            "icao_code": "VNTJ",
            "label": "Taplejung Airport , Taplejung"
        },
        {
            "value": "TPK",
            "icao_code": "WITA",
            "label": "Teuku Cut Ali Airport , Tapaktuan"
        },
        {
            "value": "TPL",
            "icao_code": "KTPL",
            "label": "Draughon\u2013Miller Central Texas Regional Airport , Temple"
        },
        {
            "value": "TPN",
            "icao_code": "SETI",
            "label": "Tiputini Airport , Tiputini"
        },
        {
            "value": "TPP",
            "icao_code": "SPST",
            "label": "Cad. FAP Guillermo del Castillo Paredes Airport , Tarapoto"
        },
        {
            "value": "TPQ",
            "icao_code": "MMEP",
            "label": "Amado Nervo International Airport , Tepic"
        },
        {
            "value": "TPR",
            "icao_code": "YTMP",
            "label": "Tom Price Airport , Tom Price"
        },
        {
            "value": "TPS",
            "icao_code": "LICT",
            "label": "Vincenzo Florio Airport Trapani\u2013Birgi , Trapani"
        },
        {
            "value": "TPT",
            "icao_code": null,
            "label": "Tapeta Airport , Tapeta"
        },
        {
            "value": "TPU",
            "icao_code": "VNTP",
            "label": "Tikapur Airport , Tikapur"
        },
        {
            "value": "TPX",
            "icao_code": "NTTU",
            "label": "Tupai Airport , T\u016bpai"
        },
        {
            "value": "TQD",
            "icao_code": "ORAT",
            "label": "Al-Taqaddum Air Base , Fallujah"
        },
        {
            "value": "TQL",
            "icao_code": "USDS",
            "label": "Tarko-Sale Airport , Tarko-Sale"
        },
        {
            "value": "TQN",
            "icao_code": "OATQ",
            "label": "Taloqan Airport , Taloqan"
        },
        {
            "value": "TQP",
            "icao_code": "YTEE",
            "label": "Trepell Airport , Trepell"
        },
        {
            "value": "TQQ",
            "icao_code": null,
            "label": "Maranggo Airport , Tomia Island"
        },
        {
            "value": "TQS",
            "icao_code": "SKTQ",
            "label": "Captain Ernesto Esguerra Cubides Air Base , Tres Esquinas"
        },
        {
            "value": "TRA",
            "icao_code": "RORT",
            "label": "Tarama Airport , Tarama"
        },
        {
            "value": "TRB",
            "icao_code": "SKTU",
            "label": "Gonzalo Mej\u00eda Airport , Turbo"
        },
        {
            "value": "TRC",
            "icao_code": "MMTC",
            "label": "Francisco Sarabia International Airport , Torre\u00f3n"
        },
        {
            "value": "TRD",
            "icao_code": "ENVA",
            "label": "Trondheim Airport, V\u00e6rnes , Trondheim"
        },
        {
            "value": "TRE",
            "icao_code": "EGPU",
            "label": "Tiree Airport , Tiree"
        },
        {
            "value": "TRF",
            "icao_code": "ENTO",
            "label": "Sandefjord Airport, Torp , Sandefjord"
        },
        {
            "value": "TRG",
            "icao_code": "NZTG",
            "label": "Tauranga Airport , Tauranga"
        },
        {
            "value": "TRH",
            "icao_code": null,
            "label": "Trona Airport , Trona"
        },
        {
            "value": "TRI",
            "icao_code": "KTRI",
            "label": "Tri-Cities Regional Airport , Bristol"
        },
        {
            "value": "TRJ",
            "icao_code": "AYTT",
            "label": "Tarakbits Airport , Tarakbits"
        },
        {
            "value": "TRK",
            "icao_code": "WALR",
            "label": "Juwata International Airport , Tarakan"
        },
        {
            "value": "TRL",
            "icao_code": "KTRL",
            "label": "Terrell Municipal Airport , Terrell"
        },
        {
            "value": "TRM",
            "icao_code": "KTRM",
            "label": "Jacqueline Cochran Regional Airport , Thermal"
        },
        {
            "value": "TRN",
            "icao_code": "LIMF",
            "label": "Turin Airport , Turin"
        },
        {
            "value": "TRO",
            "icao_code": "YTRE",
            "label": "Taree Airport , Taree"
        },
        {
            "value": "TRQ",
            "icao_code": "SBTK",
            "label": "Jos\u00e9 Galera dos Santos Airport , Tarauac\u00e1"
        },
        {
            "value": "TRR",
            "icao_code": "VCCT",
            "label": "China Bay Airport , Trincomalee"
        },
        {
            "value": "TRS",
            "icao_code": "LIPQ",
            "label": "Trieste \u2013 Friuli Venezia Giulia Airport , Trieste"
        },
        {
            "value": "TRU",
            "icao_code": "SPRU",
            "label": "FAP Captain Carlos Mart\u00ednez de Pinillos International Airport , Trujillo"
        },
        {
            "value": "TRV",
            "icao_code": "VOTV",
            "label": "Trivandrum International Airport , Thiruvananthapuram"
        },
        {
            "value": "TRW",
            "icao_code": "NGTA",
            "label": "Bonriki International Airport , Tarawa"
        },
        {
            "value": "TRX",
            "icao_code": "KTRX",
            "label": "Trenton Municipal Airport , Trenton"
        },
        {
            "value": "TRY",
            "icao_code": "HUTO",
            "label": "Tororo Airport , Tororo"
        },
        {
            "value": "TRZ",
            "icao_code": "VOTR",
            "label": "Tiruchirappalli International Airport , Tiruchirappalli"
        },
        {
            "value": "TSA",
            "icao_code": "RCSS",
            "label": "Taipei Songshan Airport , Taipei"
        },
        {
            "value": "TSB",
            "icao_code": "FYTM",
            "label": "Tsumeb Airport , Tsumeb"
        },
        {
            "value": "TSC",
            "icao_code": "SETH",
            "label": "Taisha Airport , Taisha"
        },
        {
            "value": "TSD",
            "icao_code": null,
            "label": "Tshipise Airport , Tshipise"
        },
        {
            "value": "TSE",
            "icao_code": "UACC",
            "label": "Astana International Airport , Astana"
        },
        {
            "value": "TSF",
            "icao_code": "LIPH",
            "label": "Treviso-Sant'Angelo Airport , Treviso"
        },
        {
            "value": "TSG",
            "icao_code": null,
            "label": "Tanacross Airport , Tanacross"
        },
        {
            "value": "TSH",
            "icao_code": "FZUK",
            "label": "Tshikapa Airport , Tshikapa"
        },
        {
            "value": "TSI",
            "icao_code": null,
            "label": "Tsile Tsile Airport , Tsili Tsili"
        },
        {
            "value": "TSJ",
            "icao_code": "RJDT",
            "label": "Tsushima Airport , Tsushima"
        },
        {
            "value": "TSK",
            "icao_code": null,
            "label": "Taskul Airport , Taskul"
        },
        {
            "value": "TSL",
            "icao_code": "MMTN",
            "label": "Tamu\u00edn National Airport , Tamu\u00edn"
        },
        {
            "value": "TSM",
            "icao_code": "KSKX",
            "label": "Taos Regional Airport , Taos"
        },
        {
            "value": "TSN",
            "icao_code": "ZBTJ",
            "label": "Tianjin Binhai International Airport , Tianjin"
        },
        {
            "value": "TSP",
            "icao_code": "KTSP",
            "label": "Tehachapi Municipal Airport , Tehachapi"
        },
        {
            "value": "TSQ",
            "icao_code": "SBTR",
            "label": "Torres Airport , Torres"
        },
        {
            "value": "TSR",
            "icao_code": "LRTR",
            "label": "Timi\u0219oara Traian Vuia International Airport , Timi\u0219oara"
        },
        {
            "value": "TST",
            "icao_code": "VTST",
            "label": "Trang Airport , Trang"
        },
        {
            "value": "TSU",
            "icao_code": "NGTS",
            "label": "Tabiteuea South Airport , Tabiteuea South"
        },
        {
            "value": "TSV",
            "icao_code": "YBTL",
            "label": "Townsville Airport , Townsville"
        },
        {
            "value": "TSW",
            "icao_code": null,
            "label": "Tsewi Airport , Tsewi"
        },
        {
            "value": "TSY",
            "icao_code": "WICM",
            "label": "Tasikmalaya Airport , Tasikmalaya"
        },
        {
            "value": "TSX",
            "icao_code": "WRLT",
            "label": "Tanjung Santan Airport , Tanjung Santan"
        },
        {
            "value": "TSZ",
            "icao_code": "ZMTG",
            "label": "Tsetserleg Airport , Tsetserleg"
        },
        {
            "value": "TTA",
            "icao_code": "GMAT",
            "label": "Tan Tan Airport , Tan-Tan"
        },
        {
            "value": "TTB",
            "icao_code": "LIET",
            "label": "Tortol\u00ec Airport , Tortol\u00ec"
        },
        {
            "value": "TTC",
            "icao_code": "SCTT",
            "label": "Las Breas Airport , Taltal"
        },
        {
            "value": "TTD",
            "icao_code": "KTTD",
            "label": "Portland\u2013Troutdale Airport , Portland"
        },
        {
            "value": "TTE",
            "icao_code": "WAEE",
            "label": "Sultan Babullah Airport , Ternate"
        },
        {
            "value": "TTG",
            "icao_code": "SAST",
            "label": "Tartagal \"General Enrique Mosconi\" Airport , Tartagal"
        },
        {
            "value": "TTH",
            "icao_code": "OOTH",
            "label": "RAFO Thumrait , Thumrait"
        },
        {
            "value": "TTI",
            "icao_code": "NTTE",
            "label": "Tetiaroa Airport , Tetiaroa"
        },
        {
            "value": "TTJ",
            "icao_code": "RJOR",
            "label": "Tottori Airport , Tottori"
        },
        {
            "value": "TTL",
            "icao_code": null,
            "label": "Turtle Island Seaplane Base , Nanuya Levu"
        },
        {
            "value": "TTM",
            "icao_code": null,
            "label": "Tabl\u00f3n de Tamar\u00e1 Airport , Tabl\u00f3n de Tamar\u00e1"
        },
        {
            "value": "TTN",
            "icao_code": "KTTN",
            "label": "Trenton\u2013Mercer Airport , Trenton"
        },
        {
            "value": "TTO",
            "icao_code": "KBTN",
            "label": "Britton Municipal Airport , Britton"
        },
        {
            "value": "TTQ",
            "icao_code": "MRBT",
            "label": "Tortuguero Airport , Tortuguero"
        },
        {
            "value": "TTR",
            "icao_code": "WAWT",
            "label": "Pongtiku Airport , Tana Toraja"
        },
        {
            "value": "TTS",
            "icao_code": "FMNT",
            "label": "Tsaratanana Airport , Tsaratanana"
        },
        {
            "value": "TTT",
            "icao_code": "RCFN",
            "label": "Taitung Airport , Taitung"
        },
        {
            "value": "TTU",
            "icao_code": null,
            "label": "Sania Ramel Airport , T\u00e9touan"
        },
        {
            "value": "TTW",
            "icao_code": null,
            "label": "Tissa Tank Waterdrome , Tissamaharama"
        },
        {
            "value": "TTX",
            "icao_code": "YTST",
            "label": "Truscott-Mungalalu Airport , Mungalalu"
        },
        {
            "value": "TUA",
            "icao_code": "SETU",
            "label": "Teniente Coronel Luis a Mantilla International Airport , Tulc\u00e1n"
        },
        {
            "value": "TUB",
            "icao_code": "NTAT",
            "label": "Tubuai \u2013 Mataura Airport , Tubuai"
        },
        {
            "value": "TUC",
            "icao_code": "SANT",
            "label": "Teniente General Benjam\u00edn Matienzo International Airport , San Miguel de Tucum\u00e1n"
        },
        {
            "value": "TUD",
            "icao_code": "GOTT",
            "label": "Tambacounda Airport , Tambacounda"
        },
        {
            "value": "TUF",
            "icao_code": "LFOT",
            "label": "Tours Val de Loire Airport , Tours"
        },
        {
            "value": "TUG",
            "icao_code": "RPUT",
            "label": "Tuguegarao Airport , Tuguegarao"
        },
        {
            "value": "TUI",
            "icao_code": "OETR",
            "label": "Turaif Domestic Airport , Turaif"
        },
        {
            "value": "TUJ",
            "icao_code": "HAMJ",
            "label": "Tum Airport , Tum"
        },
        {
            "value": "TUK",
            "icao_code": "OPTU",
            "label": "Turbat International Airport , Turbat"
        },
        {
            "value": "TUL",
            "icao_code": "KTUL",
            "label": "Tulsa International Airport , Tulsa"
        },
        {
            "value": "TUM",
            "icao_code": "YTMU",
            "label": "Tumut Airport , Tumut"
        },
        {
            "value": "TUN",
            "icao_code": "DTTA",
            "label": "Tunis\u2013Carthage International Airport , Tunis"
        },
        {
            "value": "TUO",
            "icao_code": "NZAP",
            "label": "Taupo Airport , Taupo"
        },
        {
            "value": "TUP",
            "icao_code": "KTUP",
            "label": "Tupelo Regional Airport , Tupelo"
        },
        {
            "value": "TUQ",
            "icao_code": "DFOT",
            "label": "Tougan Airport , Tougan"
        },
        {
            "value": "TUR",
            "icao_code": "SBTU",
            "label": "Tucuru\u00ed Airport , Tucuru\u00ed"
        },
        {
            "value": "TUS",
            "icao_code": "KTUS",
            "label": "Tucson International Airport , Tucson"
        },
        {
            "value": "TUT",
            "icao_code": null,
            "label": "Tauta Airport , Tauta"
        },
        {
            "value": "TUU",
            "icao_code": "OETB",
            "label": "Tabuk Regional Airport , Tabuk"
        },
        {
            "value": "TUV",
            "icao_code": "SVTC",
            "label": "San Rafael Airport , Tucupita"
        },
        {
            "value": "TUW",
            "icao_code": null,
            "label": "Tubala Airport , Tubala"
        },
        {
            "value": "TUX",
            "icao_code": null,
            "label": "Tumbler Ridge Airport , Tumbler Ridge"
        },
        {
            "value": "TUY",
            "icao_code": null,
            "label": "Tulum Airport , Tulum"
        },
        {
            "value": "TUZ",
            "icao_code": null,
            "label": "Tucum\u00e3 Airport , Tucum\u00e3"
        },
        {
            "value": "TVA",
            "icao_code": "FMMR",
            "label": "Morafenobe Airport , Morafenobe"
        },
        {
            "value": "TVC",
            "icao_code": "KTVC",
            "label": "Cherry Capital Airport , Traverse City"
        },
        {
            "value": "TVF",
            "icao_code": "KTVF",
            "label": "Thief River Falls Regional Airport , Thief River Falls"
        },
        {
            "value": "TVI",
            "icao_code": "KTVI",
            "label": "Thomasville Regional Airport , Thomasville"
        },
        {
            "value": "TVL",
            "icao_code": "KTVL",
            "label": "Lake Tahoe Airport , South Lake Tahoe"
        },
        {
            "value": "TVS",
            "icao_code": "ZBTS",
            "label": "Tangshan Sann\u00fche Airport , Tangshan"
        },
        {
            "value": "TVU",
            "icao_code": "NFNM",
            "label": "Matei Airport , Taveuni"
        },
        {
            "value": "TVY",
            "icao_code": "VYDW",
            "label": "Dawei Airport , Dawei"
        },
        {
            "value": "TWA",
            "icao_code": null,
            "label": "Twin Hills Airport , Twin Hills"
        },
        {
            "value": "TWB",
            "icao_code": "YTWB",
            "label": "Toowoomba City Aerodrome , Toowoomba"
        },
        {
            "value": "TWC",
            "icao_code": null,
            "label": "Tumxuk Tangwangcheng Airport , Tumxuk"
        },
        {
            "value": "TWD",
            "icao_code": null,
            "label": "Jefferson County International Airport , Port Townsend"
        },
        {
            "value": "TWE",
            "icao_code": null,
            "label": "Taylor Airport , Taylor"
        },
        {
            "value": "TWF",
            "icao_code": "KTWF",
            "label": "Magic Valley Regional Airport , Twin Falls"
        },
        {
            "value": "TWN",
            "icao_code": null,
            "label": "Tewantin Airport , Tewantin"
        },
        {
            "value": "TWP",
            "icao_code": null,
            "label": "Torwood Airport , Torwood"
        },
        {
            "value": "TWT",
            "icao_code": "RPMN",
            "label": "Sanga-Sanga Airport , Tawitawi Island"
        },
        {
            "value": "TWU",
            "icao_code": "WBKW",
            "label": "Tawau Airport , Tawau"
        },
        {
            "value": "TWY",
            "icao_code": null,
            "label": "Tawa Airport , Tawa"
        },
        {
            "value": "TWZ",
            "icao_code": "NZUK",
            "label": "Pukaki Airport , Twizel"
        },
        {
            "value": "TXE",
            "icao_code": "WITK",
            "label": "Rembele Airport , Takengon"
        },
        {
            "value": "TXF",
            "icao_code": "SNTF",
            "label": "Teixeira de Freitas Airport , Teixeira de Freitas"
        },
        {
            "value": "TXK",
            "icao_code": "KTXK",
            "label": "Texarkana Regional Airport , Texarkana"
        },
        {
            "value": "TXM",
            "icao_code": "WAST",
            "label": "Teminabuan Airport , Teminabuan"
        },
        {
            "value": "TXN",
            "icao_code": "ZSTX",
            "label": "Huangshan Tunxi International Airport , Huangshan"
        },
        {
            "value": "TXR",
            "icao_code": null,
            "label": "Tanbar Airport , Tanbar"
        },
        {
            "value": "TXU",
            "icao_code": "DITB",
            "label": "Tabou Airport , Tabou"
        },
        {
            "value": "TYA",
            "icao_code": "UUBT",
            "label": "Klokovo Airport , Tula"
        },
        {
            "value": "TYB",
            "icao_code": "YTIB",
            "label": "Tibooburra Airport , Tibooburra"
        },
        {
            "value": "TYD",
            "icao_code": "UHBW",
            "label": "Tynda Airport , Tynda"
        },
        {
            "value": "TYE",
            "icao_code": null,
            "label": "Tyonek Airport , Tyonek"
        },
        {
            "value": "TYF",
            "icao_code": "ESST",
            "label": "Torsby Airport , Torsby"
        },
        {
            "value": "TYG",
            "icao_code": null,
            "label": "Thylungra Airport , Thylungra"
        },
        {
            "value": "TYL",
            "icao_code": "SPYL",
            "label": "Capit\u00e1n FAP V\u00edctor Montes Arias International Airport , Talara"
        },
        {
            "value": "TYM",
            "icao_code": "MYES",
            "label": "Staniel Cay Airport , Staniel Cay"
        },
        {
            "value": "TYN",
            "icao_code": "ZBYN",
            "label": "Taiyuan Wusu International Airport , Taiyuan"
        },
        {
            "value": "TYO",
            "icao_code": null,
            "label": "metropolitan area , Tokyo"
        },
        {
            "value": "TYP",
            "icao_code": "YTMY",
            "label": "Tobermorey Airport , Tobermorey"
        },
        {
            "value": "TYR",
            "icao_code": "KTYR",
            "label": "Tyler Pounds Regional Airport , Tyler"
        },
        {
            "value": "TYS",
            "icao_code": "KTYS",
            "label": "McGhee Tyson Airport , Knoxville"
        },
        {
            "value": "TYT",
            "icao_code": "SUTR",
            "label": "Treinta y Tres Airport , Treinta y Tres"
        },
        {
            "value": "TYZ",
            "icao_code": "KTYL",
            "label": "Taylor Airport , Taylor"
        },
        {
            "value": "TZA",
            "icao_code": null,
            "label": "Belize City Municipal Airport , Belize City"
        },
        {
            "value": "TZC",
            "icao_code": "KCFS",
            "label": "Tuscola Area Airport , Caro"
        },
        {
            "value": "TZL",
            "icao_code": "LQTZ",
            "label": "Tuzla International Airport , Tuzla"
        },
        {
            "value": "TZM",
            "icao_code": null,
            "label": "Cupul National Airport , Tizim\u00edn"
        },
        {
            "value": "TZN",
            "icao_code": "MYAK",
            "label": "South Andros Airport , South Andros"
        },
        {
            "value": "TZO",
            "icao_code": null,
            "label": "Tsimiroro Airport , Ankisatra"
        },
        {
            "value": "TZX",
            "icao_code": "LTCG",
            "label": "Trabzon Airport , Trabzon"
        },
        {
            "value": "UAB",
            "icao_code": "LTAG",
            "label": "Incirlik Air Base , Adana"
        },
        {
            "value": "UAC",
            "icao_code": null,
            "label": "San Luis R\u00edo Colorado Airport , San Luis R\u00edo Colorado"
        },
        {
            "value": "UAE",
            "icao_code": null,
            "label": "Mount Aue Airport , Mount Aue"
        },
        {
            "value": "UAH",
            "icao_code": "NTMU",
            "label": "Ua Huka Airport , Ua Huka"
        },
        {
            "value": "UAI",
            "icao_code": "WPDB",
            "label": "Suai Airport , Suai"
        },
        {
            "value": "UAK",
            "icao_code": "BGBW",
            "label": "Narsarsuaq Airport , Narsarsuaq"
        },
        {
            "value": "UAL",
            "icao_code": "FNUA",
            "label": "Luau Airport , Luau"
        },
        {
            "value": "UAM",
            "icao_code": "PGUA",
            "label": "Andersen Air Force Base , Hag\u00e5t\u00f1a"
        },
        {
            "value": "UAP",
            "icao_code": "NTMP",
            "label": "Ua Pou Airport , Ua Pou"
        },
        {
            "value": "UAQ",
            "icao_code": "SANU",
            "label": "Domingo Faustino Sarmiento Airport , San Juan"
        },
        {
            "value": "UAR",
            "icao_code": "GMFB",
            "label": "Bouarfa Airport , Bouarfa"
        },
        {
            "value": "UAS",
            "icao_code": "HKSB",
            "label": "Samburu Airport , Samburu"
        },
        {
            "value": "UAX",
            "icao_code": null,
            "label": "Uaxactun Airport , Uaxactun"
        },
        {
            "value": "UBA",
            "icao_code": "SBUR",
            "label": "M\u00e1rio de Almeida Franco Airport , Uberaba"
        },
        {
            "value": "UBB",
            "icao_code": "YMAA",
            "label": "Mabuiag Island Airport , Mabuiag Island"
        },
        {
            "value": "UBI",
            "icao_code": null,
            "label": "Buin Airport , Buin"
        },
        {
            "value": "UBJ",
            "icao_code": "RJDC",
            "label": "Yamaguchi Ube Airport , Ube"
        },
        {
            "value": "UBN",
            "icao_code": "ZMCK",
            "label": "Chinggis Khaan International Airport , Sergelen, T\u00f6v"
        },
        {
            "value": "UBP",
            "icao_code": "VTUU",
            "label": "Ubon Ratchathani Airport , Ubon Ratchathani"
        },
        {
            "value": "UBR",
            "icao_code": "WAJU",
            "label": "Ubrub Airport , Ubrub"
        },
        {
            "value": "UBS",
            "icao_code": "KUBS",
            "label": "Columbus-Lowndes County Airport , Columbus"
        },
        {
            "value": "UBT",
            "icao_code": "SDUB",
            "label": "Ubatuba Airport , Ubatuba"
        },
        {
            "value": "UBU",
            "icao_code": "YKAL",
            "label": "Kalumburu Airport , Kalumburu"
        },
        {
            "value": "UCB",
            "icao_code": null,
            "label": "Ulanqab Airport , Ulanqab"
        },
        {
            "value": "UCC",
            "icao_code": "KUCC",
            "label": "Yucca Airstrip , Mercury"
        },
        {
            "value": "UCE",
            "icao_code": null,
            "label": "Eunice Airport , Eunice"
        },
        {
            "value": "UCK",
            "icao_code": "UKLC",
            "label": "Lutsk Airport , Lutsk"
        },
        {
            "value": "UCN",
            "icao_code": "GLBU",
            "label": "Buchanan Airport , Buchanan"
        },
        {
            "value": "UCT",
            "icao_code": "UUYH",
            "label": "Ukhta Airport , Ukhta"
        },
        {
            "value": "UCY",
            "icao_code": "KUCY",
            "label": "Everett\u2013Stewart Regional Airport , Union City"
        },
        {
            "value": "UDA",
            "icao_code": "YUDA",
            "label": "Undara Airport , Undara"
        },
        {
            "value": "UDD",
            "icao_code": "KUDD",
            "label": "Bermuda Dunes Airport , Palm Springs"
        },
        {
            "value": "UDE",
            "icao_code": "EHVK",
            "label": "Volkel Air Base , Uden"
        },
        {
            "value": "UDI",
            "icao_code": "SBUL",
            "label": "Ten. Cel. Av. C\u00e9sar Bombonato Airport , Uberl\u00e2ndia"
        },
        {
            "value": "UDJ",
            "icao_code": "UKLU",
            "label": "Uzhhorod International Airport , Uzhhorod"
        },
        {
            "value": "UDN",
            "icao_code": "LIPD",
            "label": "Campoformido Airport , Udine"
        },
        {
            "value": "UDR",
            "icao_code": "VAUD",
            "label": "Maharana Pratap Airport , Udaipur"
        },
        {
            "value": "UEE",
            "icao_code": "YQNS",
            "label": "Queenstown Airport , Queenstown"
        },
        {
            "value": "UEL",
            "icao_code": "FQQL",
            "label": "Quelimane Airport , Quelimane"
        },
        {
            "value": "UEN",
            "icao_code": "USDU",
            "label": "Urengoy Airport , Urengoy"
        },
        {
            "value": "UEO",
            "icao_code": "ROKJ",
            "label": "Kumejima Airport , Kumejima"
        },
        {
            "value": "UES",
            "icao_code": "KUES",
            "label": "Waukesha County Airport , Waukesha"
        },
        {
            "value": "UET",
            "icao_code": "OPQT",
            "label": "Quetta International Airport , Quetta"
        },
        {
            "value": "UFA",
            "icao_code": "UWUU",
            "label": "Ufa International Airport , Ufa"
        },
        {
            "value": "UGA",
            "icao_code": "ZMBN",
            "label": "Bulgan Airport , Bulgan"
        },
        {
            "value": "UGB",
            "icao_code": null,
            "label": "Ugashik Bay Airport , Pilot Point"
        },
        {
            "value": "UGC",
            "icao_code": "UTNU",
            "label": "Urgench International Airport , Urgench"
        },
        {
            "value": "UGI",
            "icao_code": null,
            "label": "San Juan (Uganik) Seaplane Base , Uganik"
        },
        {
            "value": "UGL",
            "icao_code": "SCGC",
            "label": "Union Glacier Blue-Ice Runway , Heritage Range"
        },
        {
            "value": "UGN",
            "icao_code": "KUGN",
            "label": "Waukegan National Airport , Waukegan"
        },
        {
            "value": "UGO",
            "icao_code": "FNUG",
            "label": "U\u00edge Airport , U\u00edge"
        },
        {
            "value": "UGS",
            "icao_code": null,
            "label": "Ugashik Airport , Ugashik"
        },
        {
            "value": "UGT",
            "icao_code": "ZMBR",
            "label": "Bulagtai Airport , Bulagtai"
        },
        {
            "value": "UGU",
            "icao_code": null,
            "label": "Bilogai Airport , Bilogai"
        },
        {
            "value": "UHE",
            "icao_code": "LKKU",
            "label": "Kunovice Airport , Uhersk\u00e9 Hradi\u0161t\u011b"
        },
        {
            "value": "UIB",
            "icao_code": "SKUI",
            "label": "El Cara\u00f1o Airport , Quibd\u00f3"
        },
        {
            "value": "UIH",
            "icao_code": "VVPC",
            "label": "Phu Cat Airport , Qui Nh\u01a1n"
        },
        {
            "value": "UII",
            "icao_code": "MHUT",
            "label": "\u00datila Airport , \u00datila"
        },
        {
            "value": "UIK",
            "icao_code": "UIBS",
            "label": "Ust-Ilimsk Airport , Ust-Ilimsk"
        },
        {
            "value": "UIL",
            "icao_code": "KUIL",
            "label": "Quillayute Airport , Forks"
        },
        {
            "value": "UIN",
            "icao_code": "KUIN",
            "label": "Quincy Regional Airport , Quincy"
        },
        {
            "value": "UIO",
            "icao_code": "SEQM",
            "label": "Mariscal Sucre International Airport , Quito"
        },
        {
            "value": "UIP",
            "icao_code": "LFRQ",
            "label": "Quimper\u2013Cornouaille Airport , Quimper"
        },
        {
            "value": "UIQ",
            "icao_code": "NVVQ",
            "label": "Quoin Hill Airfield , Quoin Hill"
        },
        {
            "value": "UIR",
            "icao_code": "YQDI",
            "label": "Quirindi Airport , Quirindi"
        },
        {
            "value": "UIT",
            "icao_code": null,
            "label": "Jaluit Airport , Jaluit Atoll"
        },
        {
            "value": "UJE",
            "icao_code": null,
            "label": "Ujae Airport , Ujae Atoll"
        },
        {
            "value": "UJN",
            "icao_code": "RKTL",
            "label": "Uljin Airport , Uljin"
        },
        {
            "value": "UJU",
            "icao_code": "ZKUJ",
            "label": "Uiju Airfield , Uiju"
        },
        {
            "value": "UKA",
            "icao_code": "HKUK",
            "label": "Ukunda Airport , Ukunda"
        },
        {
            "value": "UKB",
            "icao_code": "RJBE",
            "label": "Kobe Airport , Kobe"
        },
        {
            "value": "UKG",
            "icao_code": "UEBT",
            "label": "Ust-Kuyga Airport , Ust-Kuyga"
        },
        {
            "value": "UKH",
            "icao_code": "OOMK",
            "label": "Mukhaizna Airport , Mukhaizna"
        },
        {
            "value": "UKI",
            "icao_code": "KUKI",
            "label": "Ukiah Municipal Airport , Ukiah"
        },
        {
            "value": "UKK",
            "icao_code": "UASK",
            "label": "Oskemen Airport , Oskemen"
        },
        {
            "value": "UKN",
            "icao_code": null,
            "label": "Waukon Municipal Airport , Waukon"
        },
        {
            "value": "UKR",
            "icao_code": "OYMK",
            "label": "Mukeiras Airport , Mukayras"
        },
        {
            "value": "UKS",
            "icao_code": "UKFB",
            "label": "Sevastopol International Airport , Sevastopol"
        },
        {
            "value": "UKT",
            "icao_code": "KUKT",
            "label": "Quakertown Airport , Quakertown"
        },
        {
            "value": "UKU",
            "icao_code": null,
            "label": "Nuku Airport , Nuku"
        },
        {
            "value": "UKX",
            "icao_code": "UITT",
            "label": "Ust-Kut Airport , Ust-Kut"
        },
        {
            "value": "ULA",
            "icao_code": "SAWJ",
            "label": "Capit\u00e1n Jos\u00e9 Daniel Vazquez Airport , Puerto San Juli\u00e1n"
        },
        {
            "value": "ULB",
            "icao_code": "NVSU",
            "label": "Ulei Airport , Ulei"
        },
        {
            "value": "ULD",
            "icao_code": "FAUL",
            "label": "Ulundi Airport , Ulundi"
        },
        {
            "value": "ULE",
            "icao_code": null,
            "label": "Sule Airport , Sule"
        },
        {
            "value": "ULG",
            "icao_code": "ZMUL",
            "label": "\u00d6lgii Airport , \u00d6lgii"
        },
        {
            "value": "ULH",
            "icao_code": "OEAO",
            "label": "Prince Abdul Majeed bin Abdulaziz Domestic Airport , Al-'Ula"
        },
        {
            "value": "ULI",
            "icao_code": null,
            "label": "Ulithi Airport , Ulithi"
        },
        {
            "value": "ULK",
            "icao_code": "UERL",
            "label": "Lensk Airport , Lensk"
        },
        {
            "value": "ULL",
            "icao_code": null,
            "label": "Glenforsa Airfield , Mull"
        },
        {
            "value": "ULM",
            "icao_code": "KULM",
            "label": "New Ulm Municipal Airport , New Ulm"
        },
        {
            "value": "ULN",
            "icao_code": "ZMUB",
            "label": "Buyant-Ukhaa International Airport , Ulaanbaatar"
        },
        {
            "value": "ULO",
            "icao_code": "ZMUG",
            "label": "Ulaangom Airport , Ulaangom"
        },
        {
            "value": "ULP",
            "icao_code": "YQLP",
            "label": "Quilpie Airport , Quilpie"
        },
        {
            "value": "ULQ",
            "icao_code": "SKUL",
            "label": "Heriberto G\u00edl Mart\u00ednez Airport , Tulu\u00e1"
        },
        {
            "value": "ULS",
            "icao_code": null,
            "label": "Mulatos Airport , Mulatos"
        },
        {
            "value": "ULU",
            "icao_code": "HUGU",
            "label": "Gulu Airport , Gulu"
        },
        {
            "value": "ULV",
            "icao_code": "UWLL",
            "label": "Ulyanovsk Baratayevka Airport , Ulyanovsk"
        },
        {
            "value": "ULX",
            "icao_code": null,
            "label": "Ulusaba Airstrip , Ulusaba"
        },
        {
            "value": "ULY",
            "icao_code": "UWLW",
            "label": "Ulyanovsk Vostochny Airport , Ulyanovsk"
        },
        {
            "value": "ULZ",
            "icao_code": "ZMDN",
            "label": "Donoi Airport , Uliastai"
        },
        {
            "value": "UMA",
            "icao_code": null,
            "label": "Punta de Mais\u00ed Airport , Mais\u00ed"
        },
        {
            "value": "UMC",
            "icao_code": null,
            "label": "Umba Airport , Umba"
        },
        {
            "value": "UME",
            "icao_code": "ESNU",
            "label": "Ume\u00e5 Airport , Ume\u00e5"
        },
        {
            "value": "UMI",
            "icao_code": "SPIL",
            "label": "Quince Mil Airport , Quince Mil"
        },
        {
            "value": "UMM",
            "icao_code": "PAST",
            "label": "Summit Airport , Summit"
        },
        {
            "value": "UMR",
            "icao_code": "YPWR",
            "label": "RAAF Woomera Airfield , Woomera"
        },
        {
            "value": "UMS",
            "icao_code": "UEMU",
            "label": "Ust-Maya Airport , Ust-Maya"
        },
        {
            "value": "UMT",
            "icao_code": "PAUM",
            "label": "Umiat Airport , Umiat"
        },
        {
            "value": "UMU",
            "icao_code": "SSUM",
            "label": "Orlando de Carvalho Airport , Umuarama"
        },
        {
            "value": "UMY",
            "icao_code": "UKHS",
            "label": "Sumy Airport , Sumy"
        },
        {
            "value": "UMZ",
            "icao_code": "KMEZ",
            "label": "Mena Intermountain Municipal Airport , Mena, Arkansas"
        },
        {
            "value": "UNA",
            "icao_code": "SBTC",
            "label": "Una-Comandatuba Airport , Una"
        },
        {
            "value": "UNC",
            "icao_code": null,
            "label": "Ungu\u00eda Airport , Ungu\u00eda"
        },
        {
            "value": "UND",
            "icao_code": "OAUZ",
            "label": "Kunduz Airport , Kunduz"
        },
        {
            "value": "UNE",
            "icao_code": "FXQN",
            "label": "Qacha's Nek Airport , Qacha's Nek"
        },
        {
            "value": "UNG",
            "icao_code": "AYKI",
            "label": "Kiunga Airport , Kiunga"
        },
        {
            "value": "UNI",
            "icao_code": "TVSU",
            "label": "Union Island Airport , Union Island"
        },
        {
            "value": "UNK",
            "icao_code": "PAUN",
            "label": "Unalakleet Airport , Unalakleet"
        },
        {
            "value": "UNN",
            "icao_code": "VTSR",
            "label": "Ranong Airport , Ranong"
        },
        {
            "value": "UNR",
            "icao_code": "ZMUH",
            "label": "\u00d6nd\u00f6rkhaan Airport , \u00d6nd\u00f6rkhaan"
        },
        {
            "value": "UNT",
            "icao_code": null,
            "label": "Unst Airport , Unst"
        },
        {
            "value": "UNU",
            "icao_code": "KUNU",
            "label": "Dodge County Airport , Juneau"
        },
        {
            "value": "UOA",
            "icao_code": "NTTX",
            "label": "Mururoa Airport , Moruroa"
        },
        {
            "value": "UOL",
            "icao_code": "WAMY",
            "label": "Pogogul Airport , Buol"
        },
        {
            "value": "UOS",
            "icao_code": "KUOS",
            "label": "Franklin County Airport , Sewanee"
        },
        {
            "value": "UOX",
            "icao_code": "KUOX",
            "label": "University-Oxford Airport , Oxford"
        },
        {
            "value": "UPB",
            "icao_code": "MUPB",
            "label": "Playa Baracoa Airport , Havana"
        },
        {
            "value": "UPG",
            "icao_code": "WAAA",
            "label": "Sultan Hasanuddin International Airport , Makassar"
        },
        {
            "value": "UPL",
            "icao_code": "MRUP",
            "label": "Upala Airport , Upala"
        },
        {
            "value": "UPN",
            "icao_code": "MMPN",
            "label": "Ignacio L\u00f3pez Ray\u00f3n International Airport , Uruapan"
        },
        {
            "value": "UPP",
            "icao_code": "PHUP",
            "label": "Upolu Airport , Hawi"
        },
        {
            "value": "UPR",
            "icao_code": null,
            "label": "Upiara Airport , Upiara"
        },
        {
            "value": "UPV",
            "icao_code": "EGDJ",
            "label": "RAF Upavon , Upavon"
        },
        {
            "value": "URA",
            "icao_code": "UARR",
            "label": "Oral Ak Zhol Airport , Oral"
        },
        {
            "value": "URB",
            "icao_code": null,
            "label": "Castilho Airport , Castilho"
        },
        {
            "value": "URC",
            "icao_code": "ZWWW",
            "label": "\u00dcr\u00fcmqi Diwopu International Airport , \u00dcr\u00fcmqi"
        },
        {
            "value": "URD",
            "icao_code": "EDQE",
            "label": "Burg Feuerstein Airport , Burg Feuerstein"
        },
        {
            "value": "URE",
            "icao_code": "EEKE",
            "label": "Kuressaare Airport , Kuressaare"
        },
        {
            "value": "URG",
            "icao_code": "SBUG",
            "label": "Rubem Berta International Airport , Uruguaiana"
        },
        {
            "value": "URI",
            "icao_code": "SKUB",
            "label": "Uribe Airport , La Uribe"
        },
        {
            "value": "URJ",
            "icao_code": "USHU",
            "label": "Uray Airport , Uray"
        },
        {
            "value": "URM",
            "icao_code": "SVUM",
            "label": "Uriman Airport , Uriman"
        },
        {
            "value": "URN",
            "icao_code": "OAOG",
            "label": "Urgun Airport , Urgun"
        },
        {
            "value": "URO",
            "icao_code": "LFOP",
            "label": "Rouen Airport , Rouen"
        },
        {
            "value": "URR",
            "icao_code": "SKUR",
            "label": "Urrao Airport , Urrao"
        },
        {
            "value": "URS",
            "icao_code": "UUOK",
            "label": "Kursk Vostochny Airport , Kursk"
        },
        {
            "value": "URT",
            "icao_code": "VTSB",
            "label": "Surat Thani Airport , Surat Thani"
        },
        {
            "value": "URU",
            "icao_code": null,
            "label": "Uroubi Airport , Uroubi"
        },
        {
            "value": "URY",
            "icao_code": "OEGT",
            "label": "Gurayat Domestic Airport , Qurayyat"
        },
        {
            "value": "URZ",
            "icao_code": "OARG",
            "label": "Uruzgan Airport , Khas Uruzgan"
        },
        {
            "value": "USA",
            "icao_code": "KJQF",
            "label": "Concord Regional Airport , Concord"
        },
        {
            "value": "USC",
            "icao_code": null,
            "label": "Union County Airport/Troy Shelton Field , Union"
        },
        {
            "value": "USH",
            "icao_code": "SAWH",
            "label": "Ushuaia \u2013 Malvinas Argentinas International Airport , Ushuaia"
        },
        {
            "value": "USI",
            "icao_code": "SYMB",
            "label": "Mabaruma Airport , Mabaruma"
        },
        {
            "value": "USJ",
            "icao_code": "UAAL",
            "label": "Usharal Airport , Usharal"
        },
        {
            "value": "USK",
            "icao_code": "UUYS",
            "label": "Usinsk Airport , Usinsk"
        },
        {
            "value": "USL",
            "icao_code": "YUSL",
            "label": "Useless Loop Airport , Useless Loop"
        },
        {
            "value": "USM",
            "icao_code": "VTSM",
            "label": "Samui Airport , Ko Samui"
        },
        {
            "value": "USN",
            "icao_code": "RKPU",
            "label": "Ulsan Airport , Ulsan"
        },
        {
            "value": "USO",
            "icao_code": null,
            "label": "Usino Airport , Usino"
        },
        {
            "value": "USQ",
            "icao_code": "LTBO",
            "label": "U\u015fak Airport , U\u015fak"
        },
        {
            "value": "USR",
            "icao_code": "UEMT",
            "label": "Ust-Nera Airport , Ust-Nera"
        },
        {
            "value": "USS",
            "icao_code": "MUSS",
            "label": "Sancti Sp\u00edritus Airport , Sancti Sp\u00edritus"
        },
        {
            "value": "UST",
            "icao_code": "KSGJ",
            "label": "Northeast Florida Regional Airport , St. Augustine"
        },
        {
            "value": "USU",
            "icao_code": "RPVV",
            "label": "Francisco B. Reyes Airport , Busuanga"
        },
        {
            "value": "UTA",
            "icao_code": "FVMU",
            "label": "Mutare Airport , Mutare"
        },
        {
            "value": "UTB",
            "icao_code": "YMTB",
            "label": "Muttaburra Airport , Muttaburra"
        },
        {
            "value": "UTC",
            "icao_code": "EHSB",
            "label": "Soesterberg Air Base , Utrecht"
        },
        {
            "value": "UTD",
            "icao_code": null,
            "label": "Nutwood Downs Airport , Nutwood Downs"
        },
        {
            "value": "UTG",
            "icao_code": "FXQG",
            "label": "Quthing Airport , Quthing"
        },
        {
            "value": "UTH",
            "icao_code": "VTUD",
            "label": "Udon Thani International Airport , Udon Thani"
        },
        {
            "value": "UTI",
            "icao_code": "EFUT",
            "label": "Utti Airport , Utti"
        },
        {
            "value": "UTK",
            "icao_code": null,
            "label": "Utirik Airport , Utirik Atoll"
        },
        {
            "value": "UTM",
            "icao_code": "KUTA",
            "label": "Tunica Municipal Airport , Tunica"
        },
        {
            "value": "UTN",
            "icao_code": "FAUP",
            "label": "Upington Airport , Upington"
        },
        {
            "value": "UTO",
            "icao_code": "PAIM",
            "label": "Indian Mountain LRRS Airport , Utopia Creek"
        },
        {
            "value": "UTP",
            "icao_code": "VTBU",
            "label": "U-Tapao International Airport , Rayong"
        },
        {
            "value": "UTS",
            "icao_code": "UUYX",
            "label": "Ust-Tsilma Airport , Ust-Tsilma"
        },
        {
            "value": "UTT",
            "icao_code": "FAUT",
            "label": "Mthatha Airport , Mthatha"
        },
        {
            "value": "UTU",
            "icao_code": null,
            "label": "Ustupo Airport , Ustupo"
        },
        {
            "value": "UTW",
            "icao_code": "FAQT",
            "label": "Queenstown Airport , Queenstown"
        },
        {
            "value": "UUA",
            "icao_code": "UWKB",
            "label": "Bugulma Airport , Bugulma"
        },
        {
            "value": "UUD",
            "icao_code": "UIUU",
            "label": "Baikal International Airport , Ulan-Ude"
        },
        {
            "value": "UUK",
            "icao_code": "PAKU",
            "label": "Ugnu-Kuparuk Airport , Kuparuk"
        },
        {
            "value": "UUN",
            "icao_code": "ZMBU",
            "label": "Baruun-Urt Airport , Baruun-Urt"
        },
        {
            "value": "UUS",
            "icao_code": "UHSS",
            "label": "Yuzhno-Sakhalinsk Airport , Yuzhno-Sakhalinsk"
        },
        {
            "value": "UUU",
            "icao_code": null,
            "label": "Manumu Airport , Manumu"
        },
        {
            "value": "UVA",
            "icao_code": "KUVA",
            "label": "Garner Field , Uvalde"
        },
        {
            "value": "UVE",
            "icao_code": "NWWV",
            "label": "Ouv\u00e9a Airport , Ouv\u00e9a"
        },
        {
            "value": "UVF",
            "icao_code": "TLPL",
            "label": "Hewanorra International Airport , Vieux Fort"
        },
        {
            "value": "UVI",
            "icao_code": "SSUV",
            "label": "Uni\u00e3o da Vit\u00f3ria Airport , Uni\u00e3o da Vit\u00f3ria"
        },
        {
            "value": "UVL",
            "icao_code": "HEKG",
            "label": "El Kharga Airport , Kharga Oasis"
        },
        {
            "value": "UVO",
            "icao_code": null,
            "label": "Uvol Airport , Uvol"
        },
        {
            "value": "UYL",
            "icao_code": "HSNN",
            "label": "Nyala Airport , Nyala"
        },
        {
            "value": "UYN",
            "icao_code": "ZLYL",
            "label": "Yulin Yuyang Airport , Yulin"
        },
        {
            "value": "UYU",
            "icao_code": "SLUY",
            "label": "Uyuni Airport , Uyuni"
        },
        {
            "value": "UZC",
            "icao_code": "LYUZ",
            "label": "U\u017eice-Ponikve Airport , U\u017eice"
        },
        {
            "value": "UZM",
            "icao_code": null,
            "label": "Hope Bay Aerodrome , Hope Bay"
        },
        {
            "value": "UZR",
            "icao_code": null,
            "label": "Urzhar Airport , Urzhar"
        },
        {
            "value": "UZU",
            "icao_code": "SATU",
            "label": "Curuz\u00fa Cuati\u00e1 Airport , Curuz\u00fa Cuati\u00e1"
        },
        {
            "value": "VAA",
            "icao_code": "EFVA",
            "label": "Vaasa Airport , Vaasa"
        },
        {
            "value": "VAB",
            "icao_code": null,
            "label": "Yavarate Airport , Yavarate"
        },
        {
            "value": "VAC",
            "icao_code": "EDWU",
            "label": "Varrelbusch Airport , Cloppenburg"
        },
        {
            "value": "VAD",
            "icao_code": "KVAD",
            "label": "Moody Air Force Base , Valdosta"
        },
        {
            "value": "VAF",
            "icao_code": "LFLU",
            "label": "Valence-Chabeuil Airport , Valence"
        },
        {
            "value": "VAG",
            "icao_code": "SBVG",
            "label": "Major Brigadeiro Trompowsky Airport , Varginha"
        },
        {
            "value": "VAH",
            "icao_code": "SLVG",
            "label": "Cap. Av. Vidal Villagomez Toledo Airport , Vallegrande"
        },
        {
            "value": "VAI",
            "icao_code": "AYVN",
            "label": "Vanimo Airport , Vanimo"
        },
        {
            "value": "VAK",
            "icao_code": "PAVA",
            "label": "Chevak Airport , Chevak"
        },
        {
            "value": "VAL",
            "icao_code": "SNVB",
            "label": "Valen\u00e7a Airport , Valen\u00e7a"
        },
        {
            "value": "VAM",
            "icao_code": "VRMV",
            "label": "Villa International Airport Maamigili , Maamigili"
        },
        {
            "value": "VAN",
            "icao_code": "LTCI",
            "label": "Van Ferit Melen Airport , Van"
        },
        {
            "value": "VAO",
            "icao_code": "AGGV",
            "label": "Suavanao Airport , Suavanao"
        },
        {
            "value": "VAP",
            "icao_code": "SCRD",
            "label": "Rodelillo Airfield , Valpara\u00edso"
        },
        {
            "value": "VAR",
            "icao_code": "LBWN",
            "label": "Varna Airport , Varna"
        },
        {
            "value": "VAS",
            "icao_code": "LTAR",
            "label": "Sivas Airport , Sivas"
        },
        {
            "value": "VAT",
            "icao_code": "FMMY",
            "label": "Vatomandry Airport , Vatomandry"
        },
        {
            "value": "VAU",
            "icao_code": "NFNV",
            "label": "Vatukoula Airport , Vatukoula"
        },
        {
            "value": "VAV",
            "icao_code": "NFTV",
            "label": "Vava\u02bbu International Airport , Vava\u02bbu"
        },
        {
            "value": "VAW",
            "icao_code": "ENSS",
            "label": "Vard\u00f8 Airport, Svartnes , Vard\u00f8"
        },
        {
            "value": "VBA",
            "icao_code": "VYAN",
            "label": "Ann Airport , Ann"
        },
        {
            "value": "VBC",
            "icao_code": "VYCZ",
            "label": "Mandalay Chanmyathazi Airport , Mandalay"
        },
        {
            "value": "VBG",
            "icao_code": "KVBG",
            "label": "Vandenberg Air Force Base , Lompoc"
        },
        {
            "value": "VBP",
            "icao_code": "VYBP",
            "label": "Bokpyin Airport , Bokpyin"
        },
        {
            "value": "VBS",
            "icao_code": "LIPO",
            "label": "Brescia Airport , Brescia"
        },
        {
            "value": "VBV",
            "icao_code": "NFVB",
            "label": "Vanuabalavu Airport , Vanua Balavu"
        },
        {
            "value": "VBY",
            "icao_code": "ESSV",
            "label": "Visby Airport , Visby"
        },
        {
            "value": "VCA",
            "icao_code": "VVCT",
            "label": "Can Tho International Airport , Can Tho"
        },
        {
            "value": "VCD",
            "icao_code": "YVRD",
            "label": "Victoria River Downs Airport , Victoria River Downs Station"
        },
        {
            "value": "VCE",
            "icao_code": "LIPZ",
            "label": "Venice Marco Polo Airport , Venice"
        },
        {
            "value": "VCF",
            "icao_code": null,
            "label": "Valcheta Airport , Valcheta"
        },
        {
            "value": "VCH",
            "icao_code": "SUVO",
            "label": "Vichadero Airport , Vichadero"
        },
        {
            "value": "VCL",
            "icao_code": "VVCA",
            "label": "Chu Lai International Airport , Chu Lai"
        },
        {
            "value": "VCP",
            "icao_code": "SBKP",
            "label": "Viracopos/Campinas International Airport , S\u00e3o Paulo"
        },
        {
            "value": "VCR",
            "icao_code": "SVCO",
            "label": "Carora Airport , Carora"
        },
        {
            "value": "VCS",
            "icao_code": "VVCS",
            "label": "Con Dao Airport , Con Dao Island"
        },
        {
            "value": "VCT",
            "icao_code": "KVCT",
            "label": "Victoria Regional Airport , Victoria"
        },
        {
            "value": "VCV",
            "icao_code": "KVCV",
            "label": "Southern California Logistics Airport , Victorville"
        },
        {
            "value": "VDA",
            "icao_code": "LLOV",
            "label": "Ovda Airport , Ovda"
        },
        {
            "value": "VDB",
            "icao_code": "ENFG",
            "label": "Fagernes Airport, Leirin , Fagernes"
        },
        {
            "value": "VDC",
            "icao_code": "SSVC",
            "label": "Glauber Rocha Airport , Vit\u00f3ria da Conquista"
        },
        {
            "value": "VDE",
            "icao_code": "GCHI",
            "label": "El Hierro Airport , Valverde"
        },
        {
            "value": "VDH",
            "icao_code": "VVDH",
            "label": "Dong Hoi Airport , Dong Hoi"
        },
        {
            "value": "VDI",
            "icao_code": "KVDI",
            "label": "Vidalia Regional Airport , Vidalia"
        },
        {
            "value": "VDM",
            "icao_code": "SAVV",
            "label": "Gobernador Edgardo Castello Airport , Viedma"
        },
        {
            "value": "VDO",
            "icao_code": "VVVD",
            "label": "Van Don International Airport , Ha Long"
        },
        {
            "value": "VDP",
            "icao_code": "SVVP",
            "label": "Valle de la Pascua Airport , Valle de la Pascua"
        },
        {
            "value": "VDR",
            "icao_code": "SAOD",
            "label": "Villa Dolores Airport , Villa Dolores"
        },
        {
            "value": "VDS",
            "icao_code": "ENVD",
            "label": "Vads\u00f8 Airport , Vads\u00f8"
        },
        {
            "value": "VDY",
            "icao_code": "VOJV",
            "label": "Vidyanagar Airport , Toranagallu"
        },
        {
            "value": "VDZ",
            "icao_code": "PAVD",
            "label": "Valdez Airport , Valdez"
        },
        {
            "value": "VEE",
            "icao_code": "PAVE",
            "label": "Venetie Airport , Venetie"
        },
        {
            "value": "VEG",
            "icao_code": "SYMK",
            "label": "Maikwak Airport , Maikwak"
        },
        {
            "value": "VEL",
            "icao_code": "KVEL",
            "label": "Vernal Regional Airport , Vernal"
        },
        {
            "value": "VER",
            "icao_code": "MMVR",
            "label": "General Heriberto Jara International Airport , Veracruz"
        },
        {
            "value": "VEV",
            "icao_code": "AGBA",
            "label": "Barakoma Airport , Barakoma"
        },
        {
            "value": "VEX",
            "icao_code": null,
            "label": "Tioga Municipal Airport , Tioga"
        },
        {
            "value": "VEY",
            "icao_code": "BIVM",
            "label": "Vestmannaeyjar Airport , Vestmannaeyjar"
        },
        {
            "value": "VFA",
            "icao_code": "FVFA",
            "label": "Victoria Falls Airport , Victoria Falls"
        },
        {
            "value": "VGA",
            "icao_code": "VOBZ",
            "label": "NTR Amaravati International Airport , Vijayawada"
        },
        {
            "value": "VGD",
            "icao_code": "ULWW",
            "label": "Vologda Airport , Vologda"
        },
        {
            "value": "VGO",
            "icao_code": "LEVX",
            "label": "Vigo\u2013Peinador Airport , Vigo"
        },
        {
            "value": "VGS",
            "icao_code": null,
            "label": "General Villegas Airport , General Villegas"
        },
        {
            "value": "VGT",
            "icao_code": "KVGT",
            "label": "North Las Vegas Airport , Las Vegas"
        },
        {
            "value": "VGZ",
            "icao_code": "SKVG",
            "label": "Villa Garz\u00f3n Airport , Villagarz\u00f3n"
        },
        {
            "value": "VHC",
            "icao_code": "FNSA",
            "label": "Henrique de Carvalho Airport , Saurimo"
        },
        {
            "value": "VHM",
            "icao_code": "ESNV",
            "label": "Vilhelmina Airport , Vilhelmina"
        },
        {
            "value": "VHN",
            "icao_code": "KVHN",
            "label": "Culberson County Airport , Van Horn"
        },
        {
            "value": "VHV",
            "icao_code": "UENI",
            "label": "Verkhnevilyuysk Airport , Verkhnevilyuysk"
        },
        {
            "value": "VHY",
            "icao_code": "LFLV",
            "label": "Vichy \u2014 Charmeil Airport , Vichy"
        },
        {
            "value": "VHZ",
            "icao_code": "NTUV",
            "label": "Vahitahi Airport , Vahitahi"
        },
        {
            "value": "VIA",
            "icao_code": "SSVI",
            "label": "\u00c2ngelo Ponzoni Municipal Airport , Videira"
        },
        {
            "value": "VIB",
            "icao_code": null,
            "label": "Villa Constituci\u00f3n Airport , Ciudad Constituci\u00f3n"
        },
        {
            "value": "VIC",
            "icao_code": "LIPT",
            "label": "Vicenza Airport , Vicenza"
        },
        {
            "value": "VIE",
            "icao_code": "LOWW",
            "label": "Vienna International Airport , Vienna"
        },
        {
            "value": "VIG",
            "icao_code": "SVVG",
            "label": "Juan Pablo P\u00e9rez Alfonzo Airport , El Vig\u00eda"
        },
        {
            "value": "VIH",
            "icao_code": "KVIH",
            "label": "Rolla National Airport , Rolla"
        },
        {
            "value": "VII",
            "icao_code": "VVVH",
            "label": "Vinh International Airport , Vinh"
        },
        {
            "value": "VIJ",
            "icao_code": "TUPW",
            "label": "Virgin Gorda Airport , Virgin Gorda"
        },
        {
            "value": "VIL",
            "icao_code": "GMMH",
            "label": "Dakhla Airport , Dakhla"
        },
        {
            "value": "VIN",
            "icao_code": "UKWW",
            "label": "Havryshivka Vinnytsia International Airport , Vinnytsia"
        },
        {
            "value": "VIQ",
            "icao_code": "WPVQ",
            "label": "Viqueque Airport , Viqueque"
        },
        {
            "value": "VIR",
            "icao_code": "FAVG",
            "label": "Virginia Airport , Durban"
        },
        {
            "value": "VIS",
            "icao_code": "KVIS",
            "label": "Visalia Municipal Airport , Visalia"
        },
        {
            "value": "VIT",
            "icao_code": "LEVT",
            "label": "Vitoria Airport , Vitoria-Gasteiz"
        },
        {
            "value": "VIU",
            "icao_code": null,
            "label": "Viru Harbour Airport , Viru Harbour"
        },
        {
            "value": "VIV",
            "icao_code": null,
            "label": "Vivigani Airfield , Vivigani"
        },
        {
            "value": "VIX",
            "icao_code": "SBVT",
            "label": "Eurico de Aguiar Salles Airport , Vit\u00f3ria"
        },
        {
            "value": "VIY",
            "icao_code": "LFPV",
            "label": "V\u00e9lizy \u2013 Villacoublay Air Base , Paris"
        },
        {
            "value": "VJB",
            "icao_code": "FQXA",
            "label": "Xai-Xai Airport , Xai-Xai"
        },
        {
            "value": "VJI",
            "icao_code": "KVJI",
            "label": "Virginia Highlands Airport , Abingdon"
        },
        {
            "value": "VJQ",
            "icao_code": null,
            "label": "Gur\u00fa\u00e9 Airport , Gur\u00fa\u00e8"
        },
        {
            "value": "VKG",
            "icao_code": "VVRG",
            "label": "Rach Gia Airport , Rach Gia"
        },
        {
            "value": "VKO",
            "icao_code": "UUWW",
            "label": "Vnukovo International Airport , Moscow"
        },
        {
            "value": "VKS",
            "icao_code": "KVKS",
            "label": "Vicksburg Municipal Airport , Vicksburg"
        },
        {
            "value": "VKT",
            "icao_code": "UUYW",
            "label": "Vorkuta Airport , Vorkuta"
        },
        {
            "value": "VLA",
            "icao_code": "KVLA",
            "label": "Vandalia Municipal Airport , Vandalia"
        },
        {
            "value": "VLC",
            "icao_code": "LEVC",
            "label": "Valencia Airport , Valencia"
        },
        {
            "value": "VLD",
            "icao_code": "KVLD",
            "label": "Valdosta Regional Airport , Valdosta"
        },
        {
            "value": "VLE",
            "icao_code": null,
            "label": "Valle Airport , Grand Canyon"
        },
        {
            "value": "VLG",
            "icao_code": "SAZV",
            "label": "Villa Gesell Airport , Villa Gesell"
        },
        {
            "value": "VLI",
            "icao_code": "NVVV",
            "label": "Bauerfield International Airport , Port Vila"
        },
        {
            "value": "VLK",
            "icao_code": "URRY",
            "label": "Volgodonsk Airport , Volgodonsk"
        },
        {
            "value": "VLL",
            "icao_code": "LEVD",
            "label": "Valladolid Airport , Valladolid"
        },
        {
            "value": "VLM",
            "icao_code": "SLVM",
            "label": "Lieutenant Colonel Rafael Pab\u00f3n Airport , Villamontes"
        },
        {
            "value": "VLN",
            "icao_code": "SVVA",
            "label": "Arturo Michelena International Airport , Valencia"
        },
        {
            "value": "VLP",
            "icao_code": null,
            "label": "Vila Rica Municipal Airport , Vila Rica"
        },
        {
            "value": "VLR",
            "icao_code": "SCLL",
            "label": "Vallenar Airport , Vallenar"
        },
        {
            "value": "VLS",
            "icao_code": "NVSV",
            "label": "Valesdir Airport , Valesdir"
        },
        {
            "value": "VLU",
            "icao_code": "ULOL",
            "label": "Velikiye Luki Airport , Velikiye Luki"
        },
        {
            "value": "VLV",
            "icao_code": "SVVL",
            "label": "Dr. Antonio Nicol\u00e1s Brice\u00f1o Airport , Valera"
        },
        {
            "value": "VLY",
            "icao_code": "EGOV",
            "label": "Anglesey Airport , Anglesey"
        },
        {
            "value": "VME",
            "icao_code": "SAOR",
            "label": "Villa Reynolds Airport , Villa Mercedes"
        },
        {
            "value": "VMI",
            "icao_code": null,
            "label": "Dr. Juan Plate Airport , Puerto Valle-Mi"
        },
        {
            "value": "VMU",
            "icao_code": "AYBA",
            "label": "Baimuru Airport , Baimuru"
        },
        {
            "value": "VNA",
            "icao_code": "VLSV",
            "label": "Salavan Airport , Salavan"
        },
        {
            "value": "VNC",
            "icao_code": "KVNC",
            "label": "Venice Municipal Airport , Venice"
        },
        {
            "value": "VND",
            "icao_code": "FMSU",
            "label": "Vangaindrano Airport , Vangaindrano"
        },
        {
            "value": "VNE",
            "icao_code": "LFRV",
            "label": "Meucon Airport , Vannes"
        },
        {
            "value": "VNO",
            "icao_code": "EYVI",
            "label": "Vilnius Airport , Vilnius"
        },
        {
            "value": "VNR",
            "icao_code": "YVRS",
            "label": "Vanrook Airport , Vanrook Station"
        },
        {
            "value": "VNS",
            "icao_code": "VEBN",
            "label": "Lal Bahadur Shastri Airport , Varanasi"
        },
        {
            "value": "VNT",
            "icao_code": "EVVA",
            "label": "Ventspils International Airport , Ventspils"
        },
        {
            "value": "VNX",
            "icao_code": "FQVL",
            "label": "Vilankulo Airport , Vilankulo"
        },
        {
            "value": "VNY",
            "icao_code": "KVNY",
            "label": "Van Nuys Airport , Los Angeles"
        },
        {
            "value": "VOD",
            "icao_code": "LKVO",
            "label": "Vodochody Airport , Prague"
        },
        {
            "value": "VOG",
            "icao_code": "URWW",
            "label": "Volgograd International Airport , Volgograd"
        },
        {
            "value": "VOH",
            "icao_code": "FMNV",
            "label": "Vohemar Airport , Vohemar"
        },
        {
            "value": "VOI",
            "icao_code": "GLVA",
            "label": "Voinjama Airport , Voinjama"
        },
        {
            "value": "VOK",
            "icao_code": "KVOK",
            "label": "Volk Field Air National Guard Base , Camp Douglas"
        },
        {
            "value": "VOL",
            "icao_code": "LGBL",
            "label": "Nea Anchialos National Airport , Volos"
        },
        {
            "value": "VOT",
            "icao_code": "SDVG",
            "label": "Votuporanga Airport , Votuporanga"
        },
        {
            "value": "VOZ",
            "icao_code": "UUOO",
            "label": "Voronezh International Airport , Voronezh"
        },
        {
            "value": "VPE",
            "icao_code": "FNGI",
            "label": "Ondjiva Pereira Airport , Ondjiva"
        },
        {
            "value": "VPG",
            "icao_code": null,
            "label": "Vipingo Airport , Vipingo"
        },
        {
            "value": "VPN",
            "icao_code": "BIVO",
            "label": "Vopnafj\u00f6r\u00f0ur Airport , Vopnafj\u00f6r\u00f0ur"
        },
        {
            "value": "VPS",
            "icao_code": "KVPS",
            "label": "Destin\u2013Fort Walton Beach Airport , Destin"
        },
        {
            "value": "VPY",
            "icao_code": "FQCH",
            "label": "Chimoio Airport , Chimoio"
        },
        {
            "value": "VPZ",
            "icao_code": "KVPZ",
            "label": "Porter County Regional Airport , Valparaiso"
        },
        {
            "value": "VQQ",
            "icao_code": "KVQQ",
            "label": "Cecil Airport , Jacksonville"
        },
        {
            "value": "VQS",
            "icao_code": "TJVQ",
            "label": "Antonio Rivera Rodr\u00edguez Airport , Vieques"
        },
        {
            "value": "VRA",
            "icao_code": "MUVR",
            "label": "Juan Gualberto G\u00f3mez Airport , Varadero"
        },
        {
            "value": "VRB",
            "icao_code": "KVRB",
            "label": "Vero Beach Regional Airport , Vero Beach"
        },
        {
            "value": "VRC",
            "icao_code": "RPUV",
            "label": "Virac Airport , Virac"
        },
        {
            "value": "VRE",
            "icao_code": "FAVR",
            "label": "Vredendal Airport , Vredendal"
        },
        {
            "value": "VRI",
            "icao_code": "ULDW",
            "label": "Varandey Airport , Varandey"
        },
        {
            "value": "VRK",
            "icao_code": "EFVR",
            "label": "Varkaus Airport , Varkaus"
        },
        {
            "value": "VRL",
            "icao_code": "LPVR",
            "label": "Vila Real Airport , Vila Real"
        },
        {
            "value": "VRN",
            "icao_code": "LIPX",
            "label": "Verona Villafranca Airport , Verona"
        },
        {
            "value": "VRO",
            "icao_code": "MUKW",
            "label": "Kawama Airport , Varadero"
        },
        {
            "value": "VRS",
            "icao_code": null,
            "label": "Roy Otten Memorial Airfield , Versailles"
        },
        {
            "value": "VRU",
            "icao_code": "FAVB",
            "label": "Vryburg Airport , Vryburg"
        },
        {
            "value": "VSA",
            "icao_code": "MMVA",
            "label": "Carlos Rovirosa P\u00e9rez International Airport , Villahermosa"
        },
        {
            "value": "VSE",
            "icao_code": "LPVZ",
            "label": "Viseu Airport , Viseu"
        },
        {
            "value": "VSF",
            "icao_code": "KVSF",
            "label": "Hartness State Airport , Springfield"
        },
        {
            "value": "VSG",
            "icao_code": "UKCW",
            "label": "Luhansk International Airport , Luhansk"
        },
        {
            "value": "VST",
            "icao_code": "ESOW",
            "label": "Stockholm V\u00e4ster\u00e5s Airport , Stockholm"
        },
        {
            "value": "VTB",
            "icao_code": "UMII",
            "label": "Vitebsk Vostochny Airport , Vitebsk"
        },
        {
            "value": "VTE",
            "icao_code": "VLVT",
            "label": "Wattay International Airport , Vientiane"
        },
        {
            "value": "VTF",
            "icao_code": "NFVL",
            "label": "Vatulele Airport , Vatulele"
        },
        {
            "value": "VTG",
            "icao_code": "VVVT",
            "label": "Vung Tau Airport , Vung Tau"
        },
        {
            "value": "VTL",
            "icao_code": "LFSZ",
            "label": "Vittel - Champ-de-Courses Airport , Vittel"
        },
        {
            "value": "VTM",
            "icao_code": "LLNV",
            "label": "Nevatim Airbase , Nevatim"
        },
        {
            "value": "VTN",
            "icao_code": "KVTN",
            "label": "Miller Field , Valentine"
        },
        {
            "value": "VTU",
            "icao_code": "MUVT",
            "label": "Hermanos Ameijeiras Airport , Las Tunas"
        },
        {
            "value": "VTZ",
            "icao_code": "VEVZ",
            "label": "Visakhapatnam Airport , Visakhapatnam"
        },
        {
            "value": "VUP",
            "icao_code": "SKVP",
            "label": "Alfonso L\u00f3pez Pumarejo Airport , Valledupar"
        },
        {
            "value": "VUS",
            "icao_code": "ULWU",
            "label": "Veliky Ustyug Airport , Veliky Ustyug"
        },
        {
            "value": "VUU",
            "icao_code": null,
            "label": "Mvuu Camp Airport , Liwonde"
        },
        {
            "value": "VVB",
            "icao_code": "FMMH",
            "label": "Mahanoro Airport , Mahanoro"
        },
        {
            "value": "VVC",
            "icao_code": "SKVV",
            "label": "La Vanguardia Airport , Villavicencio"
        },
        {
            "value": "VVI",
            "icao_code": "SLVR",
            "label": "Viru Viru International Airport , Santa Cruz de la Sierra"
        },
        {
            "value": "VVK",
            "icao_code": "ESSW",
            "label": "V\u00e4stervik Airport , V\u00e4stervik"
        },
        {
            "value": "VVN",
            "icao_code": null,
            "label": "Las Malvinas Airport , Las Malvinas"
        },
        {
            "value": "VVO",
            "icao_code": "UHWW",
            "label": "Vladivostok International Airport , Vladivostok"
        },
        {
            "value": "VVZ",
            "icao_code": "DAAP",
            "label": "Takhamalt Airport , Illizi"
        },
        {
            "value": "VXC",
            "icao_code": "FQLC",
            "label": "Lichinga Airport , Lichinga"
        },
        {
            "value": "VXE",
            "icao_code": "GVSV",
            "label": "Ces\u00e1ria \u00c9vora Airport , S\u00e3o Vicente"
        },
        {
            "value": "VXO",
            "icao_code": "ESMX",
            "label": "V\u00e4xj\u00f6/Kronoberg Airport , V\u00e4xj\u00f6"
        },
        {
            "value": "VYD",
            "icao_code": "FAVY",
            "label": "Vryheid Airport , Vryheid"
        },
        {
            "value": "VYI",
            "icao_code": "UENW",
            "label": "Vilyuysk Airport , Vilyuysk"
        },
        {
            "value": "VYS",
            "icao_code": "KVYS",
            "label": "Illinois Valley Regional Airport , Peru"
        },
        {
            "value": "WAA",
            "icao_code": "PAIW",
            "label": "Wales Airport , Wales"
        },
        {
            "value": "WAC",
            "icao_code": "HAWC",
            "label": "Wacca Airport , Wacca"
        },
        {
            "value": "WAD",
            "icao_code": null,
            "label": "Andriamena Airport , Andriamena"
        },
        {
            "value": "WAE",
            "icao_code": "OEWD",
            "label": "Wadi al-Dawasir Domestic Airport , Wadi ad-Dawasir"
        },
        {
            "value": "WAF",
            "icao_code": "OPWN",
            "label": "Wana Airport , Wana"
        },
        {
            "value": "WAG",
            "icao_code": "NZWU",
            "label": "Whanganui Airport , Whanganui"
        },
        {
            "value": "WAH",
            "icao_code": "KBWP",
            "label": "Harry Stern Airport , Wahpeton"
        },
        {
            "value": "WAI",
            "icao_code": "FMNW",
            "label": "Ambalabe Airport , Antsohihy"
        },
        {
            "value": "WAJ",
            "icao_code": null,
            "label": "Wawoi Falls Airport , Wawoi Falls"
        },
        {
            "value": "WAK",
            "icao_code": "FMSZ",
            "label": "Ankazoabo Airport , Ankazoabo"
        },
        {
            "value": "WAL",
            "icao_code": "KWAL",
            "label": "Wallops Flight Facility , Wallops Island"
        },
        {
            "value": "WAM",
            "icao_code": "FMMZ",
            "label": "Ambatondrazaka Airport , Ambatondrazaka"
        },
        {
            "value": "WAO",
            "icao_code": "AYWB",
            "label": "Wabo Airport , Wabo"
        },
        {
            "value": "WAP",
            "icao_code": "SCAP",
            "label": "Alto Palena Airfield , Palena"
        },
        {
            "value": "WAQ",
            "icao_code": "FMMG",
            "label": "Antsalova Airport , Antsalova"
        },
        {
            "value": "WAR",
            "icao_code": "WAJR",
            "label": "Waris Airport , Waris"
        },
        {
            "value": "WAS",
            "icao_code": null,
            "label": "metropolitan area , Washington, D.C."
        },
        {
            "value": "WAT",
            "icao_code": "EIWF",
            "label": "Waterford Airport , Waterford"
        },
        {
            "value": "WAU",
            "icao_code": null,
            "label": "Wauchope Airport , Wauchope"
        },
        {
            "value": "WAV",
            "icao_code": "YWAV",
            "label": "Wave Hill Airport , Kalkarindji"
        },
        {
            "value": "WAW",
            "icao_code": "EPWA",
            "label": "Warsaw Chopin Airport , Warsaw"
        },
        {
            "value": "WAX",
            "icao_code": "HLZW",
            "label": "Zuwarah Airport , Zuwarah"
        },
        {
            "value": "WAY",
            "icao_code": "KWAY",
            "label": "Greene County Airport , Waynesburg"
        },
        {
            "value": "WAZ",
            "icao_code": "YWCK",
            "label": "Warwick Airport , Warwick"
        },
        {
            "value": "WBA",
            "icao_code": "WAPV",
            "label": "Wahai Airport , Seram Island"
        },
        {
            "value": "WBB",
            "icao_code": null,
            "label": "Stebbins Airport , Stebbins"
        },
        {
            "value": "WBC",
            "icao_code": null,
            "label": "Wapolu Airport , Wapolu"
        },
        {
            "value": "WBD",
            "icao_code": "FMNF",
            "label": "Befandriana Airport , Befandriana-Avaratra"
        },
        {
            "value": "WBE",
            "icao_code": null,
            "label": "Ankaizina Airport , Bealanana"
        },
        {
            "value": "WBG",
            "icao_code": "ETNS",
            "label": "Schleswig Air Base , Schleswig"
        },
        {
            "value": "WBK",
            "icao_code": null,
            "label": "West Branch Community Airport , West Branch"
        },
        {
            "value": "WBM",
            "icao_code": "AYWD",
            "label": "Wapenamanda Airport , Wapenamanda"
        },
        {
            "value": "WBO",
            "icao_code": "FMSB",
            "label": "Antsoa Airport , Beroroha"
        },
        {
            "value": "WBQ",
            "icao_code": "PAWB",
            "label": "Beaver Airport , Beaver"
        },
        {
            "value": "WBR",
            "icao_code": "KRQB",
            "label": "Roben\u2013Hood Airport , Big Rapids"
        },
        {
            "value": "WBU",
            "icao_code": "KBDU",
            "label": "Boulder Municipal Airport , Boulder"
        },
        {
            "value": "WBW",
            "icao_code": "KWBW",
            "label": "Wilkes-Barre Wyoming Valley Airport , Wilkes-Barre"
        },
        {
            "value": "WCA",
            "icao_code": "SCST",
            "label": "Gamboa Airport , Castro"
        },
        {
            "value": "WCH",
            "icao_code": "SCTN",
            "label": "Nuevo Chait\u00e9n Airport , Chait\u00e9n"
        },
        {
            "value": "WCR",
            "icao_code": "PALR",
            "label": "Chandalar Lake Airport , Chandalar Lake"
        },
        {
            "value": "WDA",
            "icao_code": null,
            "label": "Wadi Ain Airport , Wadi Ain"
        },
        {
            "value": "WDG",
            "icao_code": "KWDG",
            "label": "Enid Woodring Regional Airport , Enid"
        },
        {
            "value": "WDH",
            "icao_code": "FYWH",
            "label": "Hosea Kutako International Airport , Windhoek"
        },
        {
            "value": "WDI",
            "icao_code": "YWND",
            "label": "Wondai Airport , Wondai"
        },
        {
            "value": "WDN",
            "icao_code": null,
            "label": "Waldronaire Airport , Waldron Island"
        },
        {
            "value": "WDR",
            "icao_code": "KWDR",
            "label": "Barrow County Airport , Winder"
        },
        {
            "value": "WDS",
            "icao_code": "ZHSY",
            "label": "Shiyan Wudangshan Airport , Shiyan"
        },
        {
            "value": "WEA",
            "icao_code": "KWEA",
            "label": "Parker County Airport , Weatherford"
        },
        {
            "value": "WED",
            "icao_code": null,
            "label": "Wedau Airport , Wedau"
        },
        {
            "value": "WEF",
            "icao_code": "ZSWF",
            "label": "Weifang Airport , Weifang"
        },
        {
            "value": "WEH",
            "icao_code": "ZSWH",
            "label": "Weihai Dashuibo Airport , Weihai"
        },
        {
            "value": "WEI",
            "icao_code": "YBWP",
            "label": "Weipa Airport , Weipa"
        },
        {
            "value": "WEL",
            "icao_code": "FAWM",
            "label": "Welkom Airport , Welkom"
        },
        {
            "value": "WEP",
            "icao_code": null,
            "label": "Weam Airport , Weam"
        },
        {
            "value": "WET",
            "icao_code": "WABG",
            "label": "Waghete Airport , Waghete"
        },
        {
            "value": "WEW",
            "icao_code": "YWWA",
            "label": "Wee Waa Airport , Wee Waa"
        },
        {
            "value": "WFB",
            "icao_code": null,
            "label": "Ketchikan Harbor Seaplane Base , Ketchikan"
        },
        {
            "value": "WFD",
            "icao_code": "EGCD",
            "label": "Woodford Aerodrome , Woodford"
        },
        {
            "value": "WFI",
            "icao_code": "FMSF",
            "label": "Fianarantsoa Airport , Fianarantsoa"
        },
        {
            "value": "WFK",
            "icao_code": "KFVE",
            "label": "Northern Aroostook Regional Airport , Frenchville"
        },
        {
            "value": "WGA",
            "icao_code": "YSWG",
            "label": "Wagga Wagga Airport , Wagga Wagga"
        },
        {
            "value": "WGC",
            "icao_code": "VOWA",
            "label": "Warangal Airport , Warangal"
        },
        {
            "value": "WGE",
            "icao_code": "YWLG",
            "label": "Walgett Airport , Walgett"
        },
        {
            "value": "WGN",
            "icao_code": "ZGSY",
            "label": "Shaoyang Wugang Airport , Shaoyang"
        },
        {
            "value": "WGO",
            "icao_code": "KOKV",
            "label": "Winchester Regional Airport , Winchester"
        },
        {
            "value": "WGP",
            "icao_code": "WADW",
            "label": "Mau Hau Airport , Waingapu"
        },
        {
            "value": "WGT",
            "icao_code": "YWGT",
            "label": "Wangaratta Airport , Wangaratta"
        },
        {
            "value": "WGU",
            "icao_code": null,
            "label": "Wagau Airport , Wagau"
        },
        {
            "value": "WGY",
            "icao_code": null,
            "label": "Wagny Airport , Wagny"
        },
        {
            "value": "WHA",
            "icao_code": "ZSWA",
            "label": "Wuhu Xuanzhou Airport , Wuhu"
        },
        {
            "value": "WHD",
            "icao_code": null,
            "label": "Hyder Seaplane Base , Hyder"
        },
        {
            "value": "WHF",
            "icao_code": "HSSW",
            "label": "Wadi Halfa Airport , Wadi Halfa"
        },
        {
            "value": "WHK",
            "icao_code": "NZWK",
            "label": "Whakatane Airport , Whakatane"
        },
        {
            "value": "WHL",
            "icao_code": null,
            "label": "Welshpool Airport , Welshpool"
        },
        {
            "value": "WHO",
            "icao_code": null,
            "label": "Franz Josef Glacier Aerodrome , Franz Josef Glacier"
        },
        {
            "value": "WHP",
            "icao_code": "KWHP",
            "label": "Whiteman Airport , Los Angeles"
        },
        {
            "value": "WHS",
            "icao_code": "EGEH",
            "label": "Whalsay Airstrip , Whalsay"
        },
        {
            "value": "WHT",
            "icao_code": "KARM",
            "label": "Wharton Regional Airport , Wharton"
        },
        {
            "value": "WIB",
            "icao_code": null,
            "label": "Wilbarger County Airport , Vernon"
        },
        {
            "value": "WIC",
            "icao_code": "EGPC",
            "label": "Wick Airport , Wick"
        },
        {
            "value": "WIE",
            "icao_code": "ETOU",
            "label": "Wiesbaden Army Airfield , Wiesbaden"
        },
        {
            "value": "WIK",
            "icao_code": "NZKE",
            "label": "Waiheke Island Aerodrome , Waiheke Island"
        },
        {
            "value": "WIL",
            "icao_code": "HKNW",
            "label": "Wilson Airport , Nairobi"
        },
        {
            "value": "WIN",
            "icao_code": "YWTN",
            "label": "Winton Airport , Winton"
        },
        {
            "value": "WIO",
            "icao_code": "YWCA",
            "label": "Wilcannia Airport , Wilcannia"
        },
        {
            "value": "WIR",
            "icao_code": "NZWO",
            "label": "Wairoa Aerodrome , Wairoa"
        },
        {
            "value": "WIT",
            "icao_code": "YWIT",
            "label": "Wittenoom Airport , Wittenoom"
        },
        {
            "value": "WIU",
            "icao_code": null,
            "label": "Witu Airport , Witu"
        },
        {
            "value": "WJA",
            "icao_code": null,
            "label": "Woja Airport , Woja"
        },
        {
            "value": "WJF",
            "icao_code": "KWJF",
            "label": "General William J. Fox Airfield , Lancaster"
        },
        {
            "value": "WJR",
            "icao_code": "HKWJ",
            "label": "Wajir Airport , Wajir"
        },
        {
            "value": "WJU",
            "icao_code": "RKNW",
            "label": "Wonju Airport , Wonju"
        },
        {
            "value": "WKA",
            "icao_code": "NZWF",
            "label": "Wanaka Airport , Wanaka"
        },
        {
            "value": "WKB",
            "icao_code": "YWKB",
            "label": "Warracknabeal Airport , Warracknabeal"
        },
        {
            "value": "WKF",
            "icao_code": "FAWK",
            "label": "Air Force Base Waterkloof , Pretoria"
        },
        {
            "value": "WKI",
            "icao_code": "FVWT",
            "label": "Hwange Town Airport , Hwange"
        },
        {
            "value": "WKJ",
            "icao_code": "RJCW",
            "label": "Wakkanai Airport , Wakkanai"
        },
        {
            "value": "WKK",
            "icao_code": null,
            "label": "Aleknagik Airport , Aleknagik"
        },
        {
            "value": "WKN",
            "icao_code": null,
            "label": "Wakunai Airport , Wakunai"
        },
        {
            "value": "WKR",
            "icao_code": "MYAW",
            "label": "Walker's Cay Airport , Walker's Cay"
        },
        {
            "value": "WLA",
            "icao_code": "YWAL",
            "label": "Wallal Airport , Wallal"
        },
        {
            "value": "WLC",
            "icao_code": "YWCH",
            "label": "Walcha Airport , Walcha"
        },
        {
            "value": "WLD",
            "icao_code": "KWLD",
            "label": "Strother Field , Winfield"
        },
        {
            "value": "WLE",
            "icao_code": "YMLS",
            "label": "Miles Airport , Miles"
        },
        {
            "value": "WLG",
            "icao_code": "NZWN",
            "label": "Wellington International Airport , Wellington"
        },
        {
            "value": "WLH",
            "icao_code": "NVSW",
            "label": "Walaha Airport , Walaha"
        },
        {
            "value": "WLK",
            "icao_code": "PASK",
            "label": "Selawik Airport , Selawik"
        },
        {
            "value": "WLL",
            "icao_code": "YWOR",
            "label": "Wollogorang Airport , Wollogorang Station"
        },
        {
            "value": "WLO",
            "icao_code": "YWTL",
            "label": "Waterloo Airport , Waterloo"
        },
        {
            "value": "WLP",
            "icao_code": "YANG",
            "label": "West Angelas Airport , West Angelas"
        },
        {
            "value": "WLR",
            "icao_code": null,
            "label": "Loring Seaplane Base , Loring"
        },
        {
            "value": "WLS",
            "icao_code": "NLWW",
            "label": "Hihifo Airport , Wallis Island"
        },
        {
            "value": "WLW",
            "icao_code": "KWLW",
            "label": "Willows-Glenn County Airport , Willows"
        },
        {
            "value": "WMA",
            "icao_code": "FMNX",
            "label": "Mandritsara Airport , Mandritsara"
        },
        {
            "value": "WMB",
            "icao_code": "YWBL",
            "label": "Warrnambool Airport , Warrnambool"
        },
        {
            "value": "WMC",
            "icao_code": "KWMC",
            "label": "Winnemucca Municipal Airport , Winnemucca"
        },
        {
            "value": "WMD",
            "icao_code": "FMSC",
            "label": "Mandabe Airport , Mandabe"
        },
        {
            "value": "WME",
            "icao_code": "YMNE",
            "label": "Mount Keith Airport , Mount Keith"
        },
        {
            "value": "WMH",
            "icao_code": "KBPK",
            "label": "Ozark Regional Airport , Mountain Home"
        },
        {
            "value": "WMI",
            "icao_code": "EPMO",
            "label": "Warsaw Modlin Airport , Warsaw"
        },
        {
            "value": "WMK",
            "icao_code": null,
            "label": "Meyers Chuck Seaplane Base , Meyers Chuck"
        },
        {
            "value": "WML",
            "icao_code": "FMMC",
            "label": "Malaimbandy Airport , Malaimbandy"
        },
        {
            "value": "WMN",
            "icao_code": "FMNR",
            "label": "Maroantsetra Airport , Maroantsetra"
        },
        {
            "value": "WMO",
            "icao_code": "PAWM",
            "label": "White Mountain Airport , White Mountain"
        },
        {
            "value": "WMP",
            "icao_code": "FMNP",
            "label": "Mampikony Airport , Mampikony"
        },
        {
            "value": "WMR",
            "icao_code": "FMNC",
            "label": "Mananara Nord Airport , Mananara Nord"
        },
        {
            "value": "WMT",
            "icao_code": "ZUMT",
            "label": "Zunyi Maotai Airport , Renhuai"
        },
        {
            "value": "WMV",
            "icao_code": null,
            "label": "Madirovalo Airport , Madirovalo"
        },
        {
            "value": "WMX",
            "icao_code": "WAVV",
            "label": "Wamena Airport , Wamena"
        },
        {
            "value": "WNA",
            "icao_code": "PANA",
            "label": "Napakiak Airport , Napakiak"
        },
        {
            "value": "WND",
            "icao_code": null,
            "label": "Windarra Airport , Windarra"
        },
        {
            "value": "WNE",
            "icao_code": null,
            "label": "Wora na Yeno Airport , Wora na Yeno"
        },
        {
            "value": "WNH",
            "icao_code": "ZPWS",
            "label": "Wenshan Puzhehei Airport , Wenshan"
        },
        {
            "value": "WNI",
            "icao_code": "WAWD",
            "label": "Matahora Airport , Wangi-wangi Island"
        },
        {
            "value": "WNN",
            "icao_code": null,
            "label": "Wunnummin Lake Airport , Wunnumin Lake"
        },
        {
            "value": "WNP",
            "icao_code": "RPUN",
            "label": "Naga Airport , Naga"
        },
        {
            "value": "WNR",
            "icao_code": "YWDH",
            "label": "Windorah Airport , Windorah"
        },
        {
            "value": "WNS",
            "icao_code": "OPNH",
            "label": "Nawabshah Airport , Nawabshah"
        },
        {
            "value": "WNU",
            "icao_code": null,
            "label": "Wanuma Airport , Wanuma"
        },
        {
            "value": "WNZ",
            "icao_code": "ZSWZ",
            "label": "Wenzhou Longwan International Airport , Wenzhou"
        },
        {
            "value": "WOA",
            "icao_code": null,
            "label": "Wonenara Airport , Wonenara"
        },
        {
            "value": "WOE",
            "icao_code": "EHWO",
            "label": "Woensdrecht Air Base , Bergen op Zoom"
        },
        {
            "value": "WOK",
            "icao_code": "SVUQ",
            "label": "Uonqu\u00e9n Airport , Uonqu\u00e9n"
        },
        {
            "value": "WOL",
            "icao_code": "YWOL",
            "label": "Shellharbour Airport , Shellharbour"
        },
        {
            "value": "WON",
            "icao_code": "YWDL",
            "label": "Wondoola Airport , Wondoola"
        },
        {
            "value": "WOR",
            "icao_code": null,
            "label": "Moramba Airport , Ankorefo"
        },
        {
            "value": "WOS",
            "icao_code": "ZKWS",
            "label": "Wonsan Kalma International Airport , Wonsan"
        },
        {
            "value": "WOT",
            "icao_code": "RCWA",
            "label": "Wang-an Airport , Wang'an"
        },
        {
            "value": "WOW",
            "icao_code": "PAUO",
            "label": "Willow Airport , Willow"
        },
        {
            "value": "WPA",
            "icao_code": "SCAS",
            "label": "Cabo Juan Rom\u00e1n Airfield , Puerto Ais\u00e9n"
        },
        {
            "value": "WPB",
            "icao_code": "FMNG",
            "label": "Port Berg\u00e9 Airport , Boriziny"
        },
        {
            "value": "WPC",
            "icao_code": "CZPC",
            "label": "Pincher Creek Airport , Pincher Creek"
        },
        {
            "value": "WPK",
            "icao_code": "YWMP",
            "label": "Wrotham Park Airport , Wrotham Park"
        },
        {
            "value": "WPL",
            "icao_code": null,
            "label": "Powell Lake Water Aerodrome , Powell River"
        },
        {
            "value": "WPM",
            "icao_code": "AYXP",
            "label": "Wipim Airport , Wipim"
        },
        {
            "value": "WPO",
            "icao_code": null,
            "label": "North Fork Valley Airport , Paonia"
        },
        {
            "value": "WPR",
            "icao_code": "SCFM",
            "label": "Capit\u00e1n Fuentes Mart\u00ednez Airport , Porvenir"
        },
        {
            "value": "WPU",
            "icao_code": "SCGZ",
            "label": "Guardiamarina Za\u00f1artu Airport , Puerto Williams"
        },
        {
            "value": "WRA",
            "icao_code": "HAWR",
            "label": "Warder Airport , Werder"
        },
        {
            "value": "WRB",
            "icao_code": "KWRB",
            "label": "Robins Air Force Base , Warner Robins"
        },
        {
            "value": "WRE",
            "icao_code": "NZWR",
            "label": "Whangarei Airport , Whangarei"
        },
        {
            "value": "WRG",
            "icao_code": "PAWG",
            "label": "Wrangell Airport , Wrangell"
        },
        {
            "value": "WRI",
            "icao_code": "KWRI",
            "label": "McGuire Air Force Base , Wrightstown"
        },
        {
            "value": "WRL",
            "icao_code": "KWRL",
            "label": "Worland Municipal Airport , Worland"
        },
        {
            "value": "WRN",
            "icao_code": "YWDG",
            "label": "Windarling Airport , Windarling"
        },
        {
            "value": "WRO",
            "icao_code": "EPWR",
            "label": "Copernicus Airport Wroc\u0142aw , Wroc\u0142aw"
        },
        {
            "value": "WRT",
            "icao_code": "EGNO",
            "label": "Warton Aerodrome , Warton"
        },
        {
            "value": "WRW",
            "icao_code": "YWWG",
            "label": "Warrawagine Airport , Warrawagine Station"
        },
        {
            "value": "WRY",
            "icao_code": "EGEW",
            "label": "Westray Airport , Westray"
        },
        {
            "value": "WRZ",
            "icao_code": "VCCW",
            "label": "Weerawila Airport , Weerawila"
        },
        {
            "value": "WSA",
            "icao_code": null,
            "label": "Wasua Airport , Wasua"
        },
        {
            "value": "WSB",
            "icao_code": null,
            "label": "Steamboat Bay Seaplane Base , Steamboat Bay"
        },
        {
            "value": "WSD",
            "icao_code": "KWSD",
            "label": "Condron Army Airfield , White Sands"
        },
        {
            "value": "WSF",
            "icao_code": "PACS",
            "label": "Cape Sarichef Airport , Cape Sarichef"
        },
        {
            "value": "WSG",
            "icao_code": "KAFJ",
            "label": "Washington County Airport , Washington"
        },
        {
            "value": "WSH",
            "icao_code": "KHWV",
            "label": "Brookhaven Airport , Shirley"
        },
        {
            "value": "WSI",
            "icao_code": null,
            "label": "Western Sydney Airport , Sydney"
        },
        {
            "value": "WSK",
            "icao_code": "ZUWS",
            "label": "Chongqing Wushan Airport , Wushan"
        },
        {
            "value": "WSM",
            "icao_code": null,
            "label": "Wiseman Airport , Wiseman"
        },
        {
            "value": "WSN",
            "icao_code": "PFWS",
            "label": "South Naknek Airport , South Naknek"
        },
        {
            "value": "WSO",
            "icao_code": "SMWS",
            "label": "Washabo Airport , Washabo"
        },
        {
            "value": "WSP",
            "icao_code": "MNWP",
            "label": "Waspam Airport , Waspam"
        },
        {
            "value": "WSR",
            "icao_code": "WASW",
            "label": "Wasior Airport , Wasior"
        },
        {
            "value": "WST",
            "icao_code": "KWST",
            "label": "Westerly State Airport , Westerly"
        },
        {
            "value": "WSU",
            "icao_code": null,
            "label": "Wasu Airport , Wasu"
        },
        {
            "value": "WSX",
            "icao_code": null,
            "label": "Westsound Seaplane Base , West Sound"
        },
        {
            "value": "WSY",
            "icao_code": "YSHR",
            "label": "Whitsunday Airport , Airlie Beach"
        },
        {
            "value": "WSZ",
            "icao_code": "NZWS",
            "label": "Westport Airport , Westport"
        },
        {
            "value": "WTA",
            "icao_code": "FMMU",
            "label": "Tambohorano Airport , Tambohorano"
        },
        {
            "value": "WTB",
            "icao_code": "YBWW",
            "label": "Toowoomba Wellcamp Airport , Toowoomba"
        },
        {
            "value": "WTD",
            "icao_code": "MYGW",
            "label": "West End Airport , West End"
        },
        {
            "value": "WTE",
            "icao_code": null,
            "label": "Wotje Airport , Wotje Atoll"
        },
        {
            "value": "WTK",
            "icao_code": "PAWN",
            "label": "Noatak Airport , Noatak"
        },
        {
            "value": "WTL",
            "icao_code": null,
            "label": "Tuntutuliak Airport , Tuntutuliak"
        },
        {
            "value": "WTN",
            "icao_code": "EGXW",
            "label": "RAF Waddington , Waddington"
        },
        {
            "value": "WTO",
            "icao_code": null,
            "label": "Wotho Airport , Wotho Atoll"
        },
        {
            "value": "WTP",
            "icao_code": "AYWT",
            "label": "Woitape Airport , Woitape"
        },
        {
            "value": "WTR",
            "icao_code": null,
            "label": "Whiteriver Airport , Whiteriver"
        },
        {
            "value": "WTS",
            "icao_code": "FMMX",
            "label": "Tsiroanomandidy Airport , Tsiroanomandidy"
        },
        {
            "value": "WTT",
            "icao_code": null,
            "label": "Wantoat Airport , Wantoat"
        },
        {
            "value": "WTZ",
            "icao_code": "NZWT",
            "label": "Whitianga Aerodrome , Whitianga"
        },
        {
            "value": "WUA",
            "icao_code": "ZBUH",
            "label": "Wuhai Airport , Wuhai"
        },
        {
            "value": "WUD",
            "icao_code": "YWUD",
            "label": "Wudinna Airport , Wudinna"
        },
        {
            "value": "WUG",
            "icao_code": "AYWU",
            "label": "Wau Airport , Wau"
        },
        {
            "value": "WUH",
            "icao_code": "ZHHH",
            "label": "Wuhan Tianhe International Airport , Wuhan"
        },
        {
            "value": "WUI",
            "icao_code": "YMMI",
            "label": "Murrin Murrin Airport , Murrin Murrin"
        },
        {
            "value": "WUM",
            "icao_code": null,
            "label": "Wasum Airport , Wasum"
        },
        {
            "value": "WUN",
            "icao_code": "YWLU",
            "label": "Wiluna Airport , Wiluna"
        },
        {
            "value": "WUS",
            "icao_code": "ZSWY",
            "label": "Wuyishan Airport , Wuyishan"
        },
        {
            "value": "WUT",
            "icao_code": "ZBXZ",
            "label": "Xinzhou Wutaishan Airport , Xinzhou"
        },
        {
            "value": "WUU",
            "icao_code": "HSWW",
            "label": "Wau Airport , Wau"
        },
        {
            "value": "WUV",
            "icao_code": null,
            "label": "Wuvulu Island Airport , Wuvulu Island"
        },
        {
            "value": "WUX",
            "icao_code": "ZSWX",
            "label": "Sunan Shuofang International Airport , Wuxi"
        },
        {
            "value": "WUZ",
            "icao_code": null,
            "label": "Wuzhou Xijiang Airport , Wuzhou"
        },
        {
            "value": "WVB",
            "icao_code": "FYWB",
            "label": "Walvis Bay Airport , Walvis Bay"
        },
        {
            "value": "WVI",
            "icao_code": "KWVI",
            "label": "Watsonville Municipal Airport , Watsonville"
        },
        {
            "value": "WVK",
            "icao_code": "FMSK",
            "label": "Manakara Airport , Manakara"
        },
        {
            "value": "WVL",
            "icao_code": "KWVL",
            "label": "Waterville Robert LaFleur Airport , Waterville"
        },
        {
            "value": "WVN",
            "icao_code": "EDWI",
            "label": "JadeWeserAirport , Wilhelmshaven"
        },
        {
            "value": "WWA",
            "icao_code": "PAWS",
            "label": "Wasilla Airport , Wasilla"
        },
        {
            "value": "WWD",
            "icao_code": "KWWD",
            "label": "Cape May Airport , Wildwood"
        },
        {
            "value": "WWI",
            "icao_code": "YWWI",
            "label": "Woodie Woodie Airport , Woodie Woodie"
        },
        {
            "value": "WWK",
            "icao_code": "AYWK",
            "label": "Wewak Airport , Wewak"
        },
        {
            "value": "WWP",
            "icao_code": null,
            "label": "North Whale Seaplane Base , Whale Pass"
        },
        {
            "value": "WWR",
            "icao_code": "KWWR",
            "label": "West Woodward Airport , Woodward"
        },
        {
            "value": "WWT",
            "icao_code": "PAEW",
            "label": "Newtok Airport , Newtok"
        },
        {
            "value": "WWY",
            "icao_code": "YWWL",
            "label": "West Wyalong Airport , West Wyalong"
        },
        {
            "value": "WXN",
            "icao_code": "ZUWX",
            "label": "Wanzhou Wuqiao Airport , Wanzhou"
        },
        {
            "value": "WYA",
            "icao_code": "YWHA",
            "label": "Whyalla Airport , Whyalla"
        },
        {
            "value": "WYB",
            "icao_code": null,
            "label": "Yes Bay Lodge Seaplane Base , Yes Bay"
        },
        {
            "value": "WYE",
            "icao_code": "GFYE",
            "label": "Yengema Airport , Yengema"
        },
        {
            "value": "WYN",
            "icao_code": "YWYM",
            "label": "Wyndham Airport , Wyndham"
        },
        {
            "value": "WYS",
            "icao_code": "KWYS",
            "label": "Yellowstone Airport , West Yellowstone"
        },
        {
            "value": "WZA",
            "icao_code": "DGLW",
            "label": "Wa Airport , Wa"
        },
        {
            "value": "WZQ",
            "icao_code": null,
            "label": "Urad Middle Banner Airport , Urad Middle Banner"
        },
        {
            "value": "XAI",
            "icao_code": "ZHXY",
            "label": "Xinyang Minggang Airport , Xinyang"
        },
        {
            "value": "XAL",
            "icao_code": null,
            "label": "\u00c1lamos Airport , \u00c1lamos"
        },
        {
            "value": "XAP",
            "icao_code": "SBCH",
            "label": "Serafin Enoss Bertaso Airport , Chapec\u00f3"
        },
        {
            "value": "XAR",
            "icao_code": "DFOY",
            "label": "Aribinda Airport , Aribinda"
        },
        {
            "value": "XAU",
            "icao_code": "SOOS",
            "label": "Sa\u00fcl Airport , Sa\u00fcl"
        },
        {
            "value": "XBB",
            "icao_code": null,
            "label": "Blubber Bay Seaplane Base , Blubber Bay"
        },
        {
            "value": "XBE",
            "icao_code": null,
            "label": "Bearskin Lake Airport , Bearskin Lake"
        },
        {
            "value": "XBG",
            "icao_code": "DFEB",
            "label": "Bogande Airport , Bogand\u00e9"
        },
        {
            "value": "XBJ",
            "icao_code": "OIMB",
            "label": "Birjand International Airport , Birjand"
        },
        {
            "value": "XBL",
            "icao_code": "HABB",
            "label": "Bedele Airport , Bedele"
        },
        {
            "value": "XBN",
            "icao_code": null,
            "label": "Biniguni Airport , Biniguni"
        },
        {
            "value": "XBO",
            "icao_code": "DFEA",
            "label": "Boulsa Airport , Boulsa"
        },
        {
            "value": "XBR",
            "icao_code": null,
            "label": "Brockville Regional Tackaberry Airport , Brockville"
        },
        {
            "value": "XCH",
            "icao_code": "YPXM",
            "label": "Christmas Island Airport , Christmas Island"
        },
        {
            "value": "XCL",
            "icao_code": null,
            "label": "Cluff Lake Airport , Cluff Lake"
        },
        {
            "value": "XCM",
            "icao_code": "CYCK",
            "label": "Chatham-Kent Airport , Chatham-Kent"
        },
        {
            "value": "XCN",
            "icao_code": null,
            "label": "Coron Airport , Coron"
        },
        {
            "value": "XCO",
            "icao_code": "YOLA",
            "label": "Colac Airport , Colac"
        },
        {
            "value": "XCR",
            "icao_code": "LFOK",
            "label": "Ch\u00e2lons Vatry Airport , Ch\u00e2lons-en-Champagne"
        },
        {
            "value": "XDE",
            "icao_code": "DFOU",
            "label": "Di\u00e9bougou Airport , Di\u00e9bougou"
        },
        {
            "value": "XDJ",
            "icao_code": "DFCJ",
            "label": "Djibo Airport , Djibo"
        },
        {
            "value": "XEN",
            "icao_code": "ZYXC",
            "label": "Xingcheng Airport , Xingcheng"
        },
        {
            "value": "XES",
            "icao_code": null,
            "label": "Grand Geneva Resort Airport , Lake Geneva"
        },
        {
            "value": "XFN",
            "icao_code": "ZHXF",
            "label": "Xiangyang Liuji Airport , Xiangyang"
        },
        {
            "value": "XFW",
            "icao_code": "EDHI",
            "label": "Hamburg Finkenwerder Airport , Hamburg"
        },
        {
            "value": "XGA",
            "icao_code": "DFOG",
            "label": "Gaoua Airport , Gaoua"
        },
        {
            "value": "XGG",
            "icao_code": "DFEG",
            "label": "Gorom Gorom Airport , Gorom-Gorom"
        },
        {
            "value": "XGN",
            "icao_code": "FNXA",
            "label": "Xangongo Airport , Xangongo"
        },
        {
            "value": "XGR",
            "icao_code": "CYLU",
            "label": "Kangiqsualujjuaq (Georges River) Airport , Kangiqsualujjuaq"
        },
        {
            "value": "XIC",
            "icao_code": "ZUXC",
            "label": "Xichang Qingshan Airport , Xichang"
        },
        {
            "value": "XIE",
            "icao_code": null,
            "label": "Xienglom Airport , Xienglom"
        },
        {
            "value": "XIG",
            "icao_code": null,
            "label": "Xinguara Municipal Airport , Xinguara"
        },
        {
            "value": "XIJ",
            "icao_code": "OKAJ",
            "label": "Ahmad al-Jaber Air Base , Al Ahmadi"
        },
        {
            "value": "XIL",
            "icao_code": "ZBXH",
            "label": "Xilinhot Airport , Xilinhot"
        },
        {
            "value": "XIN",
            "icao_code": "ZGXN",
            "label": "Xingning Air Base , Xingning"
        },
        {
            "value": "XIY",
            "icao_code": "ZLXY",
            "label": "Xi'an Xianyang International Airport , Xi'an"
        },
        {
            "value": "XJD",
            "icao_code": "OTBH",
            "label": "Al Udeid Air Base , Al Rayyan"
        },
        {
            "value": "XJM",
            "icao_code": "OPMA",
            "label": "Mangla Airport , Mangla"
        },
        {
            "value": "XKA",
            "icao_code": "DFEL",
            "label": "Kantchari Airport , Kantchari"
        },
        {
            "value": "XKH",
            "icao_code": "VLXK",
            "label": "Xieng Khouang Airport , Phonsavan"
        },
        {
            "value": "XKS",
            "icao_code": "CYAQ",
            "label": "Kasabonika Airport , Kasabonika"
        },
        {
            "value": "XKY",
            "icao_code": "DFCA",
            "label": "Kaya Airport , Kaya"
        },
        {
            "value": "XLB",
            "icao_code": "CZWH",
            "label": "Lac Brochet Airport , Lac Brochet"
        },
        {
            "value": "XLO",
            "icao_code": null,
            "label": "Long Xuyen Airport , Long Xuy\u00ean"
        },
        {
            "value": "XLS",
            "icao_code": "GOSS",
            "label": "Saint-Louis Airport , Saint-Louis"
        },
        {
            "value": "XLU",
            "icao_code": "DFCL",
            "label": "Leo Airport , L\u00e9o"
        },
        {
            "value": "XLW",
            "icao_code": "EDWD",
            "label": "Lemwerder Airport , Lemwerder"
        },
        {
            "value": "XMA",
            "icao_code": null,
            "label": "Maramag Airport , Maramag"
        },
        {
            "value": "XMC",
            "icao_code": "YMCO",
            "label": "Mallacoota Airport , Mallacoota"
        },
        {
            "value": "XMD",
            "icao_code": "KMDS",
            "label": "Madison Municipal Airport , Madison"
        },
        {
            "value": "XMG",
            "icao_code": "VNMN",
            "label": "Mahendranagar Airport , Bhimdatta"
        },
        {
            "value": "XMH",
            "icao_code": "NTGI",
            "label": "Manihi Airport , Manihi"
        },
        {
            "value": "XMI",
            "icao_code": "HTMI",
            "label": "Masasi Airport , Masasi"
        },
        {
            "value": "XML",
            "icao_code": "YMIN",
            "label": "Minlaton Airport , Minlaton"
        },
        {
            "value": "XMN",
            "icao_code": "ZSAM",
            "label": "Xiamen Gaoqi International Airport , Xiamen"
        },
        {
            "value": "XMP",
            "icao_code": null,
            "label": "Macmillan Pass Airport , Macmillan Pass"
        },
        {
            "value": "XMS",
            "icao_code": "SEMC",
            "label": "Edmundo Carvajal Airport , Macas"
        },
        {
            "value": "XMY",
            "icao_code": "YYMI",
            "label": "Yam Island Airport , Yam Island"
        },
        {
            "value": "XNA",
            "icao_code": "KXNA",
            "label": "Northwest Arkansas Regional Airport , Fayetteville"
        },
        {
            "value": "XNG",
            "icao_code": null,
            "label": "Quang Ngai Airport , Quang Ngai"
        },
        {
            "value": "XNH",
            "icao_code": "ORTL",
            "label": "Ali Air Base , Nasiriyah"
        },
        {
            "value": "XNN",
            "icao_code": "ZLXN",
            "label": "Xining Caojiabao Airport , Xining"
        },
        {
            "value": "XNT",
            "icao_code": "ZBXT",
            "label": "Xingtai Dalian Airport , Xingtai"
        },
        {
            "value": "XNU",
            "icao_code": "DFON",
            "label": "Nouna Airport , Nouna"
        },
        {
            "value": "XPA",
            "icao_code": "DFEP",
            "label": "Pama Airport , Pama"
        },
        {
            "value": "XPK",
            "icao_code": "CZFG",
            "label": "Pukatawagan Airport , Pukatawagan"
        },
        {
            "value": "XPL",
            "icao_code": "MHSC",
            "label": "Soto Cano Air Base , Comayagua"
        },
        {
            "value": "XPP",
            "icao_code": "CZNG",
            "label": "Poplar River Airport , Poplar River"
        },
        {
            "value": "XPR",
            "icao_code": "KIEN",
            "label": "Pine Ridge Airport , Pine Ridge"
        },
        {
            "value": "XQP",
            "icao_code": "MRQP",
            "label": "Quepos La Managua Airport , Quepos"
        },
        {
            "value": "XQU",
            "icao_code": null,
            "label": "Qualicum Beach Airport , Qualicum Beach"
        },
        {
            "value": "XRH",
            "icao_code": "YSRI",
            "label": "RAAF Base Richmond , Richmond"
        },
        {
            "value": "XRQ",
            "icao_code": null,
            "label": "Xinbarag Youqi Baogede Airport , Xinbarag Youqi"
        },
        {
            "value": "XRR",
            "icao_code": "CYDM",
            "label": "Ross River Airport , Ross River"
        },
        {
            "value": "XRY",
            "icao_code": "LEJR",
            "label": "Jerez Airport , Jerez de la Frontera"
        },
        {
            "value": "XSB",
            "icao_code": "OMBY",
            "label": "Sir Bani Yas Airport , Sir Bani Yas"
        },
        {
            "value": "XSC",
            "icao_code": "MBSC",
            "label": "South Caicos Airport , South Caicos"
        },
        {
            "value": "XSD",
            "icao_code": "KTNX",
            "label": "Tonopah Test Range Airport , Tonopah"
        },
        {
            "value": "XSE",
            "icao_code": "DFES",
            "label": "Sebba Airport , Sebba"
        },
        {
            "value": "XSI",
            "icao_code": "CZSN",
            "label": "South Indian Lake Airport , South Indian Lake"
        },
        {
            "value": "XSO",
            "icao_code": "RPNO",
            "label": "Siocon Airport , Siocon"
        },
        {
            "value": "XSP",
            "icao_code": "WSSL",
            "label": "Seletar Airport , Singapore"
        },
        {
            "value": "XTA",
            "icao_code": "KXTA",
            "label": "Homey Airport , Groom Lake"
        },
        {
            "value": "XTG",
            "icao_code": "YTGM",
            "label": "Thargomindah Airport , Thargomindah"
        },
        {
            "value": "XTL",
            "icao_code": "CYBQ",
            "label": "Tadoule Lake Airport , Tadoule Lake"
        },
        {
            "value": "XTO",
            "icao_code": "YTAM",
            "label": "Taroom Airport , Taroom"
        },
        {
            "value": "XTR",
            "icao_code": "YTAA",
            "label": "Tara Airport , Tara"
        },
        {
            "value": "XUZ",
            "icao_code": "ZSXZ",
            "label": "Xuzhou Guanyin Airport , Xuzhou"
        },
        {
            "value": "XVL",
            "icao_code": null,
            "label": "Vinh Long Airport , Vinh Long"
        },
        {
            "value": "XWA",
            "icao_code": "KXWA",
            "label": "Williston Basin International Airport , Williston"
        },
        {
            "value": "XYA",
            "icao_code": "AGGY",
            "label": "Yandina Airport , Yandina"
        },
        {
            "value": "XYE",
            "icao_code": "VYYE",
            "label": "Ye Airport , Ye"
        },
        {
            "value": "XYR",
            "icao_code": "AYED",
            "label": "Edwaki Airport , Yellow River"
        },
        {
            "value": "XZA",
            "icao_code": "DFEZ",
            "label": "Zabr\u00e9 Airport , Zabr\u00e9"
        },
        {
            "value": "YAA",
            "icao_code": null,
            "label": "Anahim Lake Airport , Anahim Lake"
        },
        {
            "value": "YAB",
            "icao_code": "CYAB",
            "label": "Arctic Bay Airport , Arctic Bay"
        },
        {
            "value": "YAC",
            "icao_code": "CYAC",
            "label": "Cat Lake Airport , Cat Lake"
        },
        {
            "value": "YAD",
            "icao_code": null,
            "label": "Moose Lake Airport , Moose Lake"
        },
        {
            "value": "YAG",
            "icao_code": "CYAG",
            "label": "Fort Frances Municipal Airport , Fort Frances"
        },
        {
            "value": "YAH",
            "icao_code": "CYAH",
            "label": "La Grande-4 Airport , La Grande-4"
        },
        {
            "value": "YAI",
            "icao_code": "SCCH",
            "label": "General Bernardo O'Higgins Airport , Chill\u00e1n"
        },
        {
            "value": "YAJ",
            "icao_code": null,
            "label": "Lyall Harbour Seaplane Base , Saturna Island"
        },
        {
            "value": "YAK",
            "icao_code": "PAYA",
            "label": "Yakutat Airport , Yakutat"
        },
        {
            "value": "YAL",
            "icao_code": "CYAL",
            "label": "Alert Bay Airport , Alert Bay"
        },
        {
            "value": "YAM",
            "icao_code": "CYAM",
            "label": "Sault Ste. Marie Airport , Sault Ste. Marie"
        },
        {
            "value": "YAN",
            "icao_code": "FZIR",
            "label": "Yangambi Airport , Yangambi"
        },
        {
            "value": "YAO",
            "icao_code": "FKKY",
            "label": "Yaound\u00e9 Airport , Yaound\u00e9"
        },
        {
            "value": "YAP",
            "icao_code": "PTYA",
            "label": "Yap International Airport , Yap"
        },
        {
            "value": "YAQ",
            "icao_code": null,
            "label": "Maple Bay Seaplane Base , Maple Bay"
        },
        {
            "value": "YAR",
            "icao_code": "CYAD",
            "label": "La Grande-3 Airport , La Grande-3"
        },
        {
            "value": "YAS",
            "icao_code": "NFSW",
            "label": "Yasawa Island Airport , Yasawa"
        },
        {
            "value": "YAT",
            "icao_code": "CYAT",
            "label": "Attawapiskat Airport , Attawapiskat"
        },
        {
            "value": "YAU",
            "icao_code": null,
            "label": "Kattiniq/Donaldson Airport , Raglan Mine"
        },
        {
            "value": "YAV",
            "icao_code": null,
            "label": "Mayne Island Water Aerodrome , Mayne Island"
        },
        {
            "value": "YAX",
            "icao_code": null,
            "label": "Angling Lake/Wapekeka Airport , Wapekeka"
        },
        {
            "value": "YAY",
            "icao_code": "CYAY",
            "label": "St. Anthony Airport , St. Anthony"
        },
        {
            "value": "YAZ",
            "icao_code": "CYAZ",
            "label": "Tofino/Long Beach Airport , Tofino"
        },
        {
            "value": "YBA",
            "icao_code": "CYBA",
            "label": "Banff Airport , Banff"
        },
        {
            "value": "YBB",
            "icao_code": "CYBB",
            "label": "Kugaaruk Airport , Kugaaruk"
        },
        {
            "value": "YBC",
            "icao_code": "CYBC",
            "label": "Baie-Comeau Airport , Baie-Comeau"
        },
        {
            "value": "YBE",
            "icao_code": "CYBE",
            "label": "Uranium City Airport , Uranium City"
        },
        {
            "value": "YBF",
            "icao_code": null,
            "label": "Bamfield Water Aerodrome , Bamfield"
        },
        {
            "value": "YBG",
            "icao_code": "CYBG",
            "label": "Canadian Forces Base Bagotville , La Baie"
        },
        {
            "value": "YBH",
            "icao_code": "CYBH",
            "label": "Bull Harbour Waterdrome , Bull Harbour"
        },
        {
            "value": "YBI",
            "icao_code": null,
            "label": "Black Tickle Airport , Black Tickle"
        },
        {
            "value": "YBJ",
            "icao_code": null,
            "label": "Baie-Johan-Beetz Seaplane Base , Baie-Johan-Beetz"
        },
        {
            "value": "YBK",
            "icao_code": "CYBK",
            "label": "Baker Lake Airport , Baker Lake"
        },
        {
            "value": "YBL",
            "icao_code": "CYBL",
            "label": "Campbell River Airport , Campbell River"
        },
        {
            "value": "YBO",
            "icao_code": null,
            "label": "Bob Quinn Lake Airport , Bob Quinn Lake"
        },
        {
            "value": "YBP",
            "icao_code": "ZUYB",
            "label": "Yibin Wuliangye Airport , Yibin"
        },
        {
            "value": "YBQ",
            "icao_code": null,
            "label": "Telegraph Harbour Seaplane Base , Thetis Island"
        },
        {
            "value": "YBR",
            "icao_code": "CYBR",
            "label": "Brandon Municipal Airport , Brandon"
        },
        {
            "value": "YBS",
            "icao_code": null,
            "label": "Opapimiskan Lake Airport , Opapimiskan Lake"
        },
        {
            "value": "YBT",
            "icao_code": "CYBT",
            "label": "Brochet Airport , Brochet"
        },
        {
            "value": "YBV",
            "icao_code": "CYBV",
            "label": "Berens River Airport , Berens River"
        },
        {
            "value": "YBW",
            "icao_code": null,
            "label": "Bedwell Harbour Water Aerodrome , Bedwell Harbour"
        },
        {
            "value": "YBX",
            "icao_code": "CYBX",
            "label": "Lourdes-de-Blanc-Sablon Airport , Blanc-Sablon"
        },
        {
            "value": "YBY",
            "icao_code": "CYBF",
            "label": "Bonnyville Airport , Bonnyville"
        },
        {
            "value": "YCA",
            "icao_code": null,
            "label": "Courtenay Airpark , Courtenay"
        },
        {
            "value": "YCB",
            "icao_code": "CYCB",
            "label": "Cambridge Bay Airport , Cambridge Bay"
        },
        {
            "value": "YCC",
            "icao_code": "CYCC",
            "label": "Cornwall Regional Airport , Cornwall"
        },
        {
            "value": "YCD",
            "icao_code": "CYCD",
            "label": "Nanaimo Airport , Nanaimo"
        },
        {
            "value": "YCE",
            "icao_code": "CYCE",
            "label": "Centralia/James T. Field Memorial Aerodrome , Centralia"
        },
        {
            "value": "YCF",
            "icao_code": null,
            "label": "Cortes Island Aerodrome , Cortes Island"
        },
        {
            "value": "YCG",
            "icao_code": "CYCG",
            "label": "West Kootenay Regional Airport , Castlegar"
        },
        {
            "value": "YCH",
            "icao_code": "CYCH",
            "label": "Miramichi Airport , Miramichi"
        },
        {
            "value": "YCK",
            "icao_code": "CYVL",
            "label": "Colville Lake/Tommy Kochon Aerodrome , Colville Lake"
        },
        {
            "value": "YCL",
            "icao_code": "CYCL",
            "label": "Charlo Airport , Charlo"
        },
        {
            "value": "YCM",
            "icao_code": "CYSN",
            "label": "St. Catharines/Niagara District Airport , St. Catharines"
        },
        {
            "value": "YCN",
            "icao_code": "CYCN",
            "label": "Cochrane Aerodrome , Cochrane"
        },
        {
            "value": "YCO",
            "icao_code": "CYCO",
            "label": "Kugluktuk Airport , Kugluktuk"
        },
        {
            "value": "YCQ",
            "icao_code": "CYCQ",
            "label": "Chetwynd Airport , Chetwynd"
        },
        {
            "value": "YCR",
            "icao_code": "CYCR",
            "label": "Cross Lake (Charlie Sinclair Memorial) Airport , Cross Lake"
        },
        {
            "value": "YCS",
            "icao_code": "CYCS",
            "label": "Chesterfield Inlet Airport , Chesterfield Inlet"
        },
        {
            "value": "YCT",
            "icao_code": "CYCT",
            "label": "Coronation Airport , Coronation"
        },
        {
            "value": "YCU",
            "icao_code": "ZBYC",
            "label": "Yuncheng Guangong Airport , Yuncheng"
        },
        {
            "value": "YCW",
            "icao_code": "CYCW",
            "label": "Chilliwack Airport , Chilliwack"
        },
        {
            "value": "YCY",
            "icao_code": "CYCY",
            "label": "Clyde River Airport , Clyde River"
        },
        {
            "value": "YCZ",
            "icao_code": "CYCZ",
            "label": "Fairmont Hot Springs Airport , Fairmont Hot Springs"
        },
        {
            "value": "YDA",
            "icao_code": "CYDA",
            "label": "Dawson City Airport , Dawson City"
        },
        {
            "value": "YDB",
            "icao_code": "CYDB",
            "label": "Burwash Airport , Burwash Landing"
        },
        {
            "value": "YDC",
            "icao_code": null,
            "label": "Drayton Valley Industrial Airport , Drayton Valley"
        },
        {
            "value": "YDE",
            "icao_code": null,
            "label": "Paradise River Airport , Paradise River"
        },
        {
            "value": "YDF",
            "icao_code": "CYDF",
            "label": "Deer Lake Regional Airport , Deer Lake"
        },
        {
            "value": "YDG",
            "icao_code": "CYID",
            "label": "Digby/Annapolis Regional Airport , Digby"
        },
        {
            "value": "YDJ",
            "icao_code": null,
            "label": "Hatchet Lake Airport , Hatchet Lake"
        },
        {
            "value": "YDL",
            "icao_code": "CYDL",
            "label": "Dease Lake Airport , Dease Lake"
        },
        {
            "value": "YDN",
            "icao_code": "CYDN",
            "label": "Lt. Col W.G. (Billy) Barker VC Airport , Dauphin"
        },
        {
            "value": "YDO",
            "icao_code": "CYDO",
            "label": "Dolbeau-Saint-F\u00e9licien Airport , Dolbeau-Mistassini"
        },
        {
            "value": "YDP",
            "icao_code": "CYDP",
            "label": "Nain Airport , Nain"
        },
        {
            "value": "YDQ",
            "icao_code": "CYDQ",
            "label": "Dawson Creek Airport , Dawson Creek"
        },
        {
            "value": "YDT",
            "icao_code": "CZBB",
            "label": "Boundary Bay Airport , Vancouver"
        },
        {
            "value": "YDU",
            "icao_code": null,
            "label": "Kasba Lake Airport , Kasba Lake"
        },
        {
            "value": "YDV",
            "icao_code": "CZTA",
            "label": "Bloodvein River Airport , Bloodvein"
        },
        {
            "value": "YDW",
            "icao_code": null,
            "label": "Obre Lake/North of Sixty Airport , Obre Lake"
        },
        {
            "value": "YEA",
            "icao_code": null,
            "label": "metropolitan area , Edmonton"
        },
        {
            "value": "YEB",
            "icao_code": null,
            "label": "Bar River Airport , Bar River"
        },
        {
            "value": "YEC",
            "icao_code": "RKTY",
            "label": "Yecheon Air Base , Yecheon"
        },
        {
            "value": "YEG",
            "icao_code": "CYEG",
            "label": "Edmonton International Airport , Edmonton"
        },
        {
            "value": "YEI",
            "icao_code": "LTBR",
            "label": "Yeni\u015fehir Airport , Bursa"
        },
        {
            "value": "YEK",
            "icao_code": "CYEK",
            "label": "Arviat Airport , Arviat"
        },
        {
            "value": "YEL",
            "icao_code": "CYEL",
            "label": "Elliot Lake Municipal Airport , Elliot Lake"
        },
        {
            "value": "YEM",
            "icao_code": "CYEM",
            "label": "Manitowaning/Manitoulin East Municipal Airport , Manitowaning"
        },
        {
            "value": "YEN",
            "icao_code": "CYEN",
            "label": "Estevan Regional Aerodrome , Estevan"
        },
        {
            "value": "YEO",
            "icao_code": "EGDY",
            "label": "Royal Naval Air Station Yeovilton , Yeovilton"
        },
        {
            "value": "YEQ",
            "icao_code": null,
            "label": "Yankisa Airport , Yankisa"
        },
        {
            "value": "YER",
            "icao_code": "CYER",
            "label": "Fort Severn Airport , Fort Severn"
        },
        {
            "value": "YES",
            "icao_code": "OISY",
            "label": "Yasuj Airport , Yasuj"
        },
        {
            "value": "YET",
            "icao_code": "CYET",
            "label": "Edson Airport , Edson"
        },
        {
            "value": "YEU",
            "icao_code": "CYEU",
            "label": "Eureka Aerodrome , Eureka"
        },
        {
            "value": "YEV",
            "icao_code": "CYEV",
            "label": "Inuvik (Mike Zubko) Airport , Inuvik"
        },
        {
            "value": "YEY",
            "icao_code": "CYEY",
            "label": "Amos/Magny Airport , Amos"
        },
        {
            "value": "YFA",
            "icao_code": "CYFA",
            "label": "Fort Albany Airport , Fort Albany"
        },
        {
            "value": "YFB",
            "icao_code": "CYFB",
            "label": "Iqaluit Airport , Iqaluit"
        },
        {
            "value": "YFC",
            "icao_code": "CYFC",
            "label": "Fredericton International Airport , Fredericton"
        },
        {
            "value": "YFE",
            "icao_code": "CYFE",
            "label": "Forestville Airport , Forestville"
        },
        {
            "value": "YFG",
            "icao_code": null,
            "label": "Fontanges Airport , Fontanges"
        },
        {
            "value": "YFH",
            "icao_code": "CYFH",
            "label": "Fort Hope Airport , Eabametoong"
        },
        {
            "value": "YFI",
            "icao_code": null,
            "label": "Fort MacKay/Firebag Aerodrome , Fort McKay"
        },
        {
            "value": "YFJ",
            "icao_code": "CYWE",
            "label": "Wekwe\u00e8t\u00ec Airport , Wekweeti"
        },
        {
            "value": "YFL",
            "icao_code": null,
            "label": "Fort Reliance Water Aerodrome , Fort Reliance"
        },
        {
            "value": "YFO",
            "icao_code": "CYFO",
            "label": "Flin Flon Airport , Flin Flon"
        },
        {
            "value": "YFR",
            "icao_code": "CYFR",
            "label": "Fort Resolution Airport , Fort Resolution"
        },
        {
            "value": "YFS",
            "icao_code": "CYFS",
            "label": "Fort Simpson Airport , Fort Simpson"
        },
        {
            "value": "YFT",
            "icao_code": null,
            "label": "Taipa Ferry Terminal , Taipa"
        },
        {
            "value": "YFX",
            "icao_code": null,
            "label": "St. Lewis (Fox Harbour) Airport , St. Lewis"
        },
        {
            "value": "YGB",
            "icao_code": "CYGB",
            "label": "Texada/Gillies Bay Airport , Gillies Bay"
        },
        {
            "value": "YGC",
            "icao_code": null,
            "label": "Grande Cache Airport , Grande Cache"
        },
        {
            "value": "YGE",
            "icao_code": null,
            "label": "Gorge Harbour Water Aerodrome , Gorge Harbour"
        },
        {
            "value": "YGG",
            "icao_code": null,
            "label": "Ganges Water Aerodrome , Ganges"
        },
        {
            "value": "YGH",
            "icao_code": "CYGH",
            "label": "Fort Good Hope Airport , Fort Good Hope"
        },
        {
            "value": "YGJ",
            "icao_code": "RJOH",
            "label": "Miho\u2013Yonago Airport , Yonago"
        },
        {
            "value": "YGK",
            "icao_code": "CYGK",
            "label": "Kingston/Norman Rogers Airport , Kingston"
        },
        {
            "value": "YGL",
            "icao_code": "CYGL",
            "label": "La Grande Rivi\u00e8re Airport , Radisson"
        },
        {
            "value": "YGM",
            "icao_code": "CYGM",
            "label": "Gimli Industrial Park Airport , Gimli"
        },
        {
            "value": "YGN",
            "icao_code": null,
            "label": "Greenway Sound Water Aerodrome , Greenway Sound"
        },
        {
            "value": "YGO",
            "icao_code": "CYGO",
            "label": "Gods Lake Narrows Airport , Gods Lake Narrows"
        },
        {
            "value": "YGP",
            "icao_code": "CYGP",
            "label": "Michel-Pouliot Gasp\u00e9 Airport , Gasp\u00e9"
        },
        {
            "value": "YGQ",
            "icao_code": "CYGQ",
            "label": "Geraldton (Greenstone Regional) Airport , Greenstone"
        },
        {
            "value": "YGR",
            "icao_code": "CYGR",
            "label": "\u00celes-de-la-Madeleine Airport , Les \u00celes-de-la-Madeleine"
        },
        {
            "value": "YGT",
            "icao_code": "CYGT",
            "label": "Igloolik Airport , Igloolik"
        },
        {
            "value": "YGV",
            "icao_code": "CYGV",
            "label": "Havre Saint-Pierre Airport , Havre-Saint-Pierre"
        },
        {
            "value": "YGW",
            "icao_code": "CYGW",
            "label": "Kuujjuarapik Airport , Kuujjuarapik"
        },
        {
            "value": "YGX",
            "icao_code": "CYGX",
            "label": "Gillam Airport , Gillam"
        },
        {
            "value": "YGZ",
            "icao_code": "CYGZ",
            "label": "Grise Fiord Airport , Grise Fiord"
        },
        {
            "value": "YHA",
            "icao_code": null,
            "label": "Port Hope Simpson Airport , Port Hope Simpson"
        },
        {
            "value": "YHB",
            "icao_code": "CYHB",
            "label": "Hudson Bay Airport , Hudson Bay"
        },
        {
            "value": "YHC",
            "icao_code": null,
            "label": "Hakai Passage Water Aerodrome , Hakai Passage"
        },
        {
            "value": "YHD",
            "icao_code": "CYHD",
            "label": "Dryden Regional Airport , Dryden"
        },
        {
            "value": "YHE",
            "icao_code": "CYHE",
            "label": "Hope Aerodrome , Hope"
        },
        {
            "value": "YHF",
            "icao_code": "CYHF",
            "label": "Hearst (Ren\u00e9 Fontaine) Municipal Airport , Hearst"
        },
        {
            "value": "YHG",
            "icao_code": null,
            "label": "Charlottetown Airport , Charlottetown"
        },
        {
            "value": "YHH",
            "icao_code": null,
            "label": "Campbell River Water Aerodrome , Campbell River"
        },
        {
            "value": "YHI",
            "icao_code": "CYHI",
            "label": "Ulukhaktok/Holman Airport , Ulukhaktok"
        },
        {
            "value": "YHK",
            "icao_code": "CYHK",
            "label": "Gjoa Haven Airport , Gjoa Haven"
        },
        {
            "value": "YHM",
            "icao_code": "CYHM",
            "label": "John C. Munro Hamilton International Airport , Hamilton"
        },
        {
            "value": "YHN",
            "icao_code": "CYHN",
            "label": "Hornepayne Municipal Airport , Hornepayne"
        },
        {
            "value": "YHO",
            "icao_code": "CYHO",
            "label": "Hopedale Airport , Hopedale"
        },
        {
            "value": "YHP",
            "icao_code": "CYHP",
            "label": "Poplar Hill Airport , Poplar Hill"
        },
        {
            "value": "YHR",
            "icao_code": "CYHR",
            "label": "Chevery Airport , Chevery"
        },
        {
            "value": "YHS",
            "icao_code": null,
            "label": "Sechelt Aerodrome , Sechelt"
        },
        {
            "value": "YHT",
            "icao_code": "CYHT",
            "label": "Haines Junction Airport , Haines Junction"
        },
        {
            "value": "YHU",
            "icao_code": "CYHU",
            "label": "Montr\u00e9al/Saint-Hubert Airport , Montreal"
        },
        {
            "value": "YHY",
            "icao_code": "CYHY",
            "label": "Hay River/Merlyn Carter Airport , Hay River"
        },
        {
            "value": "YHZ",
            "icao_code": "CYHZ",
            "label": "Halifax Stanfield International Airport , Halifax"
        },
        {
            "value": "YIA",
            "icao_code": "WAHI",
            "label": "Yogyakarta International Airport , Kulon Progo Regency"
        },
        {
            "value": "YIB",
            "icao_code": "CYIB",
            "label": "Atikokan Municipal Airport , Atikokan"
        },
        {
            "value": "YIC",
            "icao_code": "ZSYC",
            "label": "Yichun Mingyueshan Airport , Yichun"
        },
        {
            "value": "YIE",
            "icao_code": "ZBES",
            "label": "Arxan Yi'ershi Airport , Arxan"
        },
        {
            "value": "YIF",
            "icao_code": "CYIF",
            "label": "Saint-Augustin Airport , Saint-Augustin"
        },
        {
            "value": "YIG",
            "icao_code": null,
            "label": "Big Bay Water Aerodrome , Stuart Island"
        },
        {
            "value": "YIH",
            "icao_code": "ZHYC",
            "label": "Yichang Sanxia Airport , Yichang"
        },
        {
            "value": "YIK",
            "icao_code": "CYIK",
            "label": "Ivujivik Airport , Ivujivik"
        },
        {
            "value": "YIN",
            "icao_code": "ZWYN",
            "label": "Yining Airport , Yining"
        },
        {
            "value": "YIO",
            "icao_code": "CYIO",
            "label": "Pond Inlet Airport , Pond Inlet"
        },
        {
            "value": "YIP",
            "icao_code": "KYIP",
            "label": "Willow Run Airport , Detroit"
        },
        {
            "value": "YIV",
            "icao_code": "CYIV",
            "label": "Island Lake Airport , Island Lake"
        },
        {
            "value": "YIW",
            "icao_code": "ZSYW",
            "label": "Yiwu Airport , Yiwu"
        },
        {
            "value": "YJA",
            "icao_code": "CYJA",
            "label": "Jasper Airport , Jasper"
        },
        {
            "value": "YJF",
            "icao_code": "CYJF",
            "label": "Fort Liard Airport , Fort Liard"
        },
        {
            "value": "YJN",
            "icao_code": "CYJN",
            "label": "Saint-Jean Airport , Saint-Jean-sur-Richelieu"
        },
        {
            "value": "YJP",
            "icao_code": null,
            "label": "Hinton/Jasper-Hinton Airport , Hinton"
        },
        {
            "value": "YJS",
            "icao_code": "ZKSE",
            "label": "Samjiyon Airport , Samjiyon"
        },
        {
            "value": "YJT",
            "icao_code": "CYJT",
            "label": "Stephenville International Airport , Stephenville"
        },
        {
            "value": "YKA",
            "icao_code": "CYKA",
            "label": "Kamloops Airport , Kamloops"
        },
        {
            "value": "YKC",
            "icao_code": "CYKC",
            "label": "Collins Bay Airport , Collins Bay"
        },
        {
            "value": "YKD",
            "icao_code": "CYKM",
            "label": "Kincardine Municipal Airport , Kincardine"
        },
        {
            "value": "YKE",
            "icao_code": null,
            "label": "Knee Lake Airport , Knee Lake"
        },
        {
            "value": "YKF",
            "icao_code": "CYKF",
            "label": "Region of Waterloo International Airport , Kitchener"
        },
        {
            "value": "YKG",
            "icao_code": "CYAS",
            "label": "Kangirsuk Airport , Kangirsuk"
        },
        {
            "value": "YKH",
            "icao_code": "ZYYK",
            "label": "Yingkou Lanqi Airport , Yingkou"
        },
        {
            "value": "YKJ",
            "icao_code": "CYKJ",
            "label": "Key Lake Airport , Key Lake"
        },
        {
            "value": "YKK",
            "icao_code": null,
            "label": "Kitkatla Water Aerodrome , Kitkatla"
        },
        {
            "value": "YKL",
            "icao_code": "CYKL",
            "label": "Schefferville Airport , Schefferville"
        },
        {
            "value": "YKM",
            "icao_code": "KYKM",
            "label": "Yakima Air Terminal , Yakima"
        },
        {
            "value": "YKN",
            "icao_code": "KYKN",
            "label": "Chan Gurney Municipal Airport , Yankton"
        },
        {
            "value": "YKO",
            "icao_code": "LTCW",
            "label": "Hakkari Y\u00fcksekova Airport , Hakk\u00e2ri"
        },
        {
            "value": "YKQ",
            "icao_code": "CYKQ",
            "label": "Waskaganish Airport , Waskaganish"
        },
        {
            "value": "YKS",
            "icao_code": "UEEE",
            "label": "Yakutsk Airport , Yakutsk"
        },
        {
            "value": "YKT",
            "icao_code": null,
            "label": "Klemtu Water Aerodrome , Klemtu"
        },
        {
            "value": "YKU",
            "icao_code": null,
            "label": "Chisasibi Airport , Chisasibi"
        },
        {
            "value": "YKX",
            "icao_code": "CYKX",
            "label": "Kirkland Lake Airport , Kirkland Lake"
        },
        {
            "value": "YKY",
            "icao_code": "CYKY",
            "label": "Kindersley Regional Airport , Kindersley"
        },
        {
            "value": "YKZ",
            "icao_code": "CYKZ",
            "label": "Buttonville Municipal Airport , Toronto"
        },
        {
            "value": "YLB",
            "icao_code": "CYLB",
            "label": "Lac La Biche Airport , Lac La Biche"
        },
        {
            "value": "YLC",
            "icao_code": "CYLC",
            "label": "Kimmirut Airport , Kimmirut"
        },
        {
            "value": "YLD",
            "icao_code": "CYLD",
            "label": "Chapleau Airport , Chapleau"
        },
        {
            "value": "YLE",
            "icao_code": null,
            "label": "What\u00ec Airport , What\u00ec"
        },
        {
            "value": "YLG",
            "icao_code": "YYAL",
            "label": "Yalgoo Airport , Yalgoo"
        },
        {
            "value": "YLH",
            "icao_code": "CYLH",
            "label": "Lansdowne House Airport , Neskantaga"
        },
        {
            "value": "YLI",
            "icao_code": "EFYL",
            "label": "Ylivieska Airfield , Ylivieska"
        },
        {
            "value": "YLJ",
            "icao_code": "CYLJ",
            "label": "Meadow Lake Airport , Meadow Lake"
        },
        {
            "value": "YLK",
            "icao_code": "CYLS",
            "label": "Lake Simcoe Regional Airport , Barrie"
        },
        {
            "value": "YLL",
            "icao_code": "CYLL",
            "label": "Lloydminster Airport , Lloydminster"
        },
        {
            "value": "YLM",
            "icao_code": null,
            "label": "Clinton Creek Airport , Clinton Creek"
        },
        {
            "value": "YLN",
            "icao_code": "ZYYL",
            "label": "Yilan Airport , Yilan"
        },
        {
            "value": "YLP",
            "icao_code": "CYLP",
            "label": "Mingan Airport , Mingan"
        },
        {
            "value": "YLQ",
            "icao_code": "CYLQ",
            "label": "La Tuque Airport , La Tuque"
        },
        {
            "value": "YLR",
            "icao_code": "CYLR",
            "label": "Leaf Rapids Airport , Leaf Rapids"
        },
        {
            "value": "YLS",
            "icao_code": null,
            "label": "Lebel-sur-Qu\u00e9villon Airport , Lebel-sur-Qu\u00e9villon"
        },
        {
            "value": "YLT",
            "icao_code": "CYLT",
            "label": "Alert Airport , Alert"
        },
        {
            "value": "YLV",
            "icao_code": "UBEE",
            "label": "Yevlakh Airport , Yevlakh"
        },
        {
            "value": "YLW",
            "icao_code": "CYLW",
            "label": "Kelowna International Airport , Kelowna"
        },
        {
            "value": "YLX",
            "icao_code": null,
            "label": "Yulin Fumian Airport , Yulin, Guangxi"
        },
        {
            "value": "YLY",
            "icao_code": "CYNJ",
            "label": "Langley Regional Airport , Langley"
        },
        {
            "value": "YMA",
            "icao_code": "CYMA",
            "label": "Mayo Airport , Mayo"
        },
        {
            "value": "YMB",
            "icao_code": null,
            "label": "Merritt Airport , Merritt"
        },
        {
            "value": "YMD",
            "icao_code": "CYMD",
            "label": "Mould Bay Airport , Prince Patrick Island"
        },
        {
            "value": "YME",
            "icao_code": "CYME",
            "label": "Matane Airport , Matane"
        },
        {
            "value": "YMF",
            "icao_code": null,
            "label": "Montague Harbour Water Aerodrome , Galiano Island"
        },
        {
            "value": "YMG",
            "icao_code": "CYMG",
            "label": "Manitouwadge Airport , Manitouwadge"
        },
        {
            "value": "YMH",
            "icao_code": "CYMH",
            "label": "Mary's Harbour Airport , Mary's Harbour"
        },
        {
            "value": "YMJ",
            "icao_code": "CYMJ",
            "label": "CFB Moose Jaw , Moose Jaw"
        },
        {
            "value": "YMK",
            "icao_code": "USDK",
            "label": "Mys-Kamenny Airport , Mys-Kamenny"
        },
        {
            "value": "YML",
            "icao_code": "CYML",
            "label": "Charlevoix Airport , La Malbaie"
        },
        {
            "value": "YMM",
            "icao_code": "CYMM",
            "label": "Fort McMurray International Airport , Fort McMurray"
        },
        {
            "value": "YMN",
            "icao_code": "CYFT",
            "label": "Makkovik Airport , Makkovik"
        },
        {
            "value": "YMO",
            "icao_code": "CYMO",
            "label": "Moosonee Airport , Moosonee"
        },
        {
            "value": "YMP",
            "icao_code": null,
            "label": "Port McNeill Airport , Port McNeill"
        },
        {
            "value": "YMQ",
            "icao_code": null,
            "label": "metropolitan area , Montreal"
        },
        {
            "value": "YMS",
            "icao_code": "SPMS",
            "label": "Mois\u00e9s Benzaqu\u00e9n Rengifo Airport , Yurimaguas"
        },
        {
            "value": "YMT",
            "icao_code": "CYMT",
            "label": "Chibougamau/Chapais Airport , Chibougamau"
        },
        {
            "value": "YMU",
            "icao_code": null,
            "label": "Mansons Landing Water Aerodrome , Mansons Landing"
        },
        {
            "value": "YMV",
            "icao_code": null,
            "label": "Mary River Aerodrome , Mary River"
        },
        {
            "value": "YMW",
            "icao_code": "CYMW",
            "label": "Maniwaki Airport , Maniwaki"
        },
        {
            "value": "YMX",
            "icao_code": "CYMX",
            "label": "Montr\u00e9al\u2013Mirabel International Airport , Montreal"
        },
        {
            "value": "YNA",
            "icao_code": "CYNA",
            "label": "Natashquan Airport , Natashquan"
        },
        {
            "value": "YNB",
            "icao_code": "OEYN",
            "label": "Yanbu Airport , Yanbu al Bahr"
        },
        {
            "value": "YNC",
            "icao_code": "CYNC",
            "label": "Wemindji Airport , Wemindji"
        },
        {
            "value": "YND",
            "icao_code": "CYND",
            "label": "Gatineau-Ottawa Executive Airport , Gatineau"
        },
        {
            "value": "YNE",
            "icao_code": "CYNE",
            "label": "Norway House Airport , Norway House"
        },
        {
            "value": "YNG",
            "icao_code": "KYNG",
            "label": "Youngstown\u2013Warren Regional Airport , Youngstown"
        },
        {
            "value": "YNH",
            "icao_code": "CYNH",
            "label": "Hudson's Hope Airport , Hudson's Hope"
        },
        {
            "value": "YNJ",
            "icao_code": "ZYYJ",
            "label": "Yanji Chaoyangchuan Airport , Yanji"
        },
        {
            "value": "YNL",
            "icao_code": "CYNL",
            "label": "Points North Landing Airport , Points North Landing"
        },
        {
            "value": "YNM",
            "icao_code": "CYNM",
            "label": "Matagami Airport , Matagami"
        },
        {
            "value": "YNN",
            "icao_code": null,
            "label": "Yandicoogina Airport , Yandicoogina"
        },
        {
            "value": "YNO",
            "icao_code": null,
            "label": "North Spirit Lake Airport , North Spirit Lake"
        },
        {
            "value": "YNP",
            "icao_code": null,
            "label": "Natuashish Airport , Natuashish"
        },
        {
            "value": "YNS",
            "icao_code": "CYHH",
            "label": "Nemiscau Airport , Nemiscau"
        },
        {
            "value": "YNT",
            "icao_code": "ZSYT",
            "label": "Yantai Penglai International Airport , Yantai"
        },
        {
            "value": "YNX",
            "icao_code": null,
            "label": "Snap Lake Airport , Snap Lake"
        },
        {
            "value": "YNY",
            "icao_code": "RKNY",
            "label": "Yangyang International Airport , Yangyang"
        },
        {
            "value": "YNZ",
            "icao_code": "ZSYN",
            "label": "Yancheng Nanyang International Airport , Yancheng"
        },
        {
            "value": "YOA",
            "icao_code": "CYOA",
            "label": "Ekati Airport , Ekati"
        },
        {
            "value": "YOC",
            "icao_code": "CYOC",
            "label": "Old Crow Airport , Old Crow"
        },
        {
            "value": "YOD",
            "icao_code": "CYOD",
            "label": "CFB Cold Lake , Cold Lake"
        },
        {
            "value": "YOE",
            "icao_code": null,
            "label": "Donnelly Airport , Donnelly"
        },
        {
            "value": "YOG",
            "icao_code": "CYKP",
            "label": "Ogoki Post Airport , Marten Falls"
        },
        {
            "value": "YOH",
            "icao_code": "CYOH",
            "label": "Oxford House Airport , Oxford House"
        },
        {
            "value": "YOJ",
            "icao_code": "CYOJ",
            "label": "High Level Airport , High Level"
        },
        {
            "value": "YOL",
            "icao_code": "DNYO",
            "label": "Yola Airport , Yola"
        },
        {
            "value": "YON",
            "icao_code": "VQTY",
            "label": "Yongphulla Airport , Trashigang"
        },
        {
            "value": "YOO",
            "icao_code": "CYOO",
            "label": "Oshawa Airport , Oshawa"
        },
        {
            "value": "YOP",
            "icao_code": "CYOP",
            "label": "Rainbow Lake Airport , Rainbow Lake"
        },
        {
            "value": "YOS",
            "icao_code": "CYOS",
            "label": "Billy Bishop Regional Airport , Owen Sound"
        },
        {
            "value": "YOT",
            "icao_code": "LLYT",
            "label": "Yotvata Airfield , Yotvata"
        },
        {
            "value": "YOW",
            "icao_code": "CYOW",
            "label": "Ottawa Macdonald\u2013Cartier International Airport , Ottawa"
        },
        {
            "value": "YPA",
            "icao_code": "CYPA",
            "label": "Prince Albert (Glass Field) Airport , Prince Albert"
        },
        {
            "value": "YPB",
            "icao_code": null,
            "label": "Alberni Valley Regional Airport , Port Alberni"
        },
        {
            "value": "YPC",
            "icao_code": "CYPC",
            "label": "Nora Aliqatchialuk Ruben Airport , Paulatuk"
        },
        {
            "value": "YPD",
            "icao_code": null,
            "label": "Parry Sound Area Municipal Airport , Parry Sound"
        },
        {
            "value": "YPE",
            "icao_code": "CYPE",
            "label": "Peace River Airport , Peace River"
        },
        {
            "value": "YPG",
            "icao_code": "CYPG",
            "label": "Portage la Prairie/Southport Airport , Portage la Prairie"
        },
        {
            "value": "YPH",
            "icao_code": "CYPH",
            "label": "Inukjuak Airport , Inukjuak"
        },
        {
            "value": "YPI",
            "icao_code": null,
            "label": "Port Simpson Water Aerodrome , Lax Kw'alaams"
        },
        {
            "value": "YPJ",
            "icao_code": "CYLA",
            "label": "Aupaluk Airport , Aupaluk"
        },
        {
            "value": "YPL",
            "icao_code": "CYPL",
            "label": "Pickle Lake Airport , Pickle Lake"
        },
        {
            "value": "YPM",
            "icao_code": "CYPM",
            "label": "Pikangikum Airport , Pikangikum"
        },
        {
            "value": "YPN",
            "icao_code": "CYPN",
            "label": "Port-Menier Airport , Port-Menier"
        },
        {
            "value": "YPO",
            "icao_code": "CYPO",
            "label": "Peawanuck Airport , Peawanuck"
        },
        {
            "value": "YPQ",
            "icao_code": "CYPQ",
            "label": "Peterborough Airport , Peterborough"
        },
        {
            "value": "YPR",
            "icao_code": "CYPR",
            "label": "Prince Rupert Airport , Prince Rupert"
        },
        {
            "value": "YPS",
            "icao_code": "CYPD",
            "label": "Port Hawkesbury Airport , Port Hawkesbury"
        },
        {
            "value": "YPT",
            "icao_code": null,
            "label": "Pender Harbour Water Aerodrome , Pender Harbour"
        },
        {
            "value": "YPW",
            "icao_code": "CYPW",
            "label": "Powell River Airport , Powell River"
        },
        {
            "value": "YPX",
            "icao_code": "CYPX",
            "label": "Puvirnituq Airport , Puvirnituq"
        },
        {
            "value": "YPY",
            "icao_code": "CYPY",
            "label": "Fort Chipewyan Airport , Fort Chipewyan"
        },
        {
            "value": "YPZ",
            "icao_code": "CYPZ",
            "label": "Burns Lake Airport , Burns Lake"
        },
        {
            "value": "YQA",
            "icao_code": "CYQA",
            "label": "Muskoka Airport , Muskoka"
        },
        {
            "value": "YQB",
            "icao_code": "CYQB",
            "label": "Qu\u00e9bec City Jean Lesage International Airport , Quebec City"
        },
        {
            "value": "YQC",
            "icao_code": "CYHA",
            "label": "Quaqtaq Airport , Quaqtaq"
        },
        {
            "value": "YQD",
            "icao_code": "CYQD",
            "label": "The Pas Airport , The Pas"
        },
        {
            "value": "YQF",
            "icao_code": "CYQF",
            "label": "Red Deer Regional Airport , Red Deer"
        },
        {
            "value": "YQG",
            "icao_code": "CYQG",
            "label": "Windsor International Airport , Windsor"
        },
        {
            "value": "YQH",
            "icao_code": "CYQH",
            "label": "Watson Lake Airport , Watson Lake"
        },
        {
            "value": "YQI",
            "icao_code": "CYQI",
            "label": "Yarmouth Airport , Yarmouth"
        },
        {
            "value": "YQJ",
            "icao_code": null,
            "label": "April Point Water Aerodrome , Quadra Island"
        },
        {
            "value": "YQK",
            "icao_code": "CYQK",
            "label": "Kenora Airport , Kenora"
        },
        {
            "value": "YQL",
            "icao_code": "CYQL",
            "label": "Lethbridge Airport , Lethbridge"
        },
        {
            "value": "YQM",
            "icao_code": "CYQM",
            "label": "Greater Moncton International Airport , Moncton"
        },
        {
            "value": "YQN",
            "icao_code": "CYQN",
            "label": "Nakina Airport , Greenstone"
        },
        {
            "value": "YQQ",
            "icao_code": "CYQQ",
            "label": "CFB Comox , Comox"
        },
        {
            "value": "YQR",
            "icao_code": "CYQR",
            "label": "Regina International Airport , Regina"
        },
        {
            "value": "YQS",
            "icao_code": "CYQS",
            "label": "St. Thomas Municipal Airport , St. Thomas"
        },
        {
            "value": "YQT",
            "icao_code": "CYQT",
            "label": "Thunder Bay International Airport , Thunder Bay"
        },
        {
            "value": "YQU",
            "icao_code": "CYQU",
            "label": "Grande Prairie Airport , Grande Prairie"
        },
        {
            "value": "YQV",
            "icao_code": "CYQV",
            "label": "Yorkton Municipal Airport , Yorkton"
        },
        {
            "value": "YQW",
            "icao_code": "CYQW",
            "label": "North Battleford Airport , North Battleford"
        },
        {
            "value": "YQX",
            "icao_code": "CYQX",
            "label": "Gander International Airport , Gander"
        },
        {
            "value": "YQY",
            "icao_code": "CYQY",
            "label": "Sydney/J.A. Douglas McCurdy Airport , Sydney"
        },
        {
            "value": "YQZ",
            "icao_code": "CYQZ",
            "label": "Quesnel Airport , Quesnel"
        },
        {
            "value": "YRA",
            "icao_code": "CYRA",
            "label": "Gam\u00e8t\u00ec/Rae Lakes Airport , Gam\u00e8ti"
        },
        {
            "value": "YRB",
            "icao_code": "CYRB",
            "label": "Resolute Bay Airport , Resolute"
        },
        {
            "value": "YRC",
            "icao_code": null,
            "label": "Refuge Cove Water Aerodrome , Refuge Cove"
        },
        {
            "value": "YRD",
            "icao_code": null,
            "label": "Dean River Airport , Dean River"
        },
        {
            "value": "YRF",
            "icao_code": "CYCA",
            "label": "Cartwright Airport , Cartwright"
        },
        {
            "value": "YRG",
            "icao_code": null,
            "label": "Rigolet Airport , Rigolet"
        },
        {
            "value": "YRI",
            "icao_code": "CYRI",
            "label": "Rivi\u00e8re-du-Loup Airport , Rivi\u00e8re-du-Loup"
        },
        {
            "value": "YRJ",
            "icao_code": "CYRJ",
            "label": "Roberval Airport , Roberval"
        },
        {
            "value": "YRL",
            "icao_code": "CYRL",
            "label": "Red Lake Airport , Red Lake"
        },
        {
            "value": "YRM",
            "icao_code": "CYRM",
            "label": "Rocky Mountain House Airport , Rocky Mountain House"
        },
        {
            "value": "YRN",
            "icao_code": null,
            "label": "Rivers Inlet Water Aerodrome , Rivers Inlet"
        },
        {
            "value": "YRO",
            "icao_code": "CYRO",
            "label": "Ottawa/Rockcliffe Airport , Ottawa"
        },
        {
            "value": "YRQ",
            "icao_code": "CYRQ",
            "label": "Trois-Rivi\u00e8res Airport , Trois-Rivi\u00e8res"
        },
        {
            "value": "YRR",
            "icao_code": null,
            "label": "Stuart Island Airport , Stuart Island"
        },
        {
            "value": "YRS",
            "icao_code": "CYRS",
            "label": "Red Sucker Lake Airport , Red Sucker Lake"
        },
        {
            "value": "YRT",
            "icao_code": "CYRT",
            "label": "Rankin Inlet Airport , Rankin Inlet"
        },
        {
            "value": "YRV",
            "icao_code": "CYRV",
            "label": "Revelstoke Airport , Revelstoke"
        },
        {
            "value": "YSA",
            "icao_code": null,
            "label": "Sable Island Aerodrome , Sable Island"
        },
        {
            "value": "YSB",
            "icao_code": "CYSB",
            "label": "Sudbury Airport , Greater Sudbury"
        },
        {
            "value": "YSC",
            "icao_code": "CYSC",
            "label": "Sherbrooke Airport , Sherbrooke"
        },
        {
            "value": "YSE",
            "icao_code": "CYSE",
            "label": "Squamish Airport , Squamish"
        },
        {
            "value": "YSF",
            "icao_code": "CYSF",
            "label": "Stony Rapids Airport , Stony Rapids"
        },
        {
            "value": "YSG",
            "icao_code": "CYLK",
            "label": "Lutselk'e Airport , Lutselk'e"
        },
        {
            "value": "YSH",
            "icao_code": "CYSH",
            "label": "Smiths Falls-Montague Airport , Smiths Falls"
        },
        {
            "value": "YSI",
            "icao_code": null,
            "label": "Parry Sound/Frying Pan Island-Sans Souci Water Aerodrome , Fryingpan Island"
        },
        {
            "value": "YSJ",
            "icao_code": "CYSJ",
            "label": "Saint John Airport , Saint John"
        },
        {
            "value": "YSK",
            "icao_code": "CYSK",
            "label": "Sanikiluaq Airport , Sanikiluaq"
        },
        {
            "value": "YSL",
            "icao_code": "CYSL",
            "label": "Saint-L\u00e9onard Aerodrome , Saint-L\u00e9onard"
        },
        {
            "value": "YSM",
            "icao_code": "CYSM",
            "label": "Fort Smith Airport , Fort Smith"
        },
        {
            "value": "YSN",
            "icao_code": "CZAM",
            "label": "Salmon Arm Airport , Salmon Arm"
        },
        {
            "value": "YSO",
            "icao_code": null,
            "label": "Postville Airport , Postville"
        },
        {
            "value": "YSP",
            "icao_code": "CYSP",
            "label": "Marathon Aerodrome , Marathon"
        },
        {
            "value": "YSQ",
            "icao_code": "ZYSQ",
            "label": "Songyuan Chaganhu Airport , Songyuan"
        },
        {
            "value": "YST",
            "icao_code": "CYST",
            "label": "St. Theresa Point Airport , St. Theresa Point"
        },
        {
            "value": "YSU",
            "icao_code": "CYSU",
            "label": "Summerside Airport , Summerside"
        },
        {
            "value": "YSX",
            "icao_code": null,
            "label": "Bella Bella/Shearwater Water Aerodrome , Bella Bella"
        },
        {
            "value": "YSY",
            "icao_code": "CYSY",
            "label": "Sachs Harbour (David Nasogaluak Jr. Saaryuaq) Airport , Sachs Harbour"
        },
        {
            "value": "YTA",
            "icao_code": "CYTA",
            "label": "Pembroke Airport , Pembroke"
        },
        {
            "value": "YTB",
            "icao_code": null,
            "label": "Hartley Bay Water Aerodrome , Hartley Bay"
        },
        {
            "value": "YTD",
            "icao_code": "CZLQ",
            "label": "Thicket Portage Airport , Thicket Portage"
        },
        {
            "value": "YTE",
            "icao_code": "CYTE",
            "label": "Cape Dorset Airport , Cape Dorset"
        },
        {
            "value": "YTF",
            "icao_code": "CYTF",
            "label": "Alma Airport , Alma"
        },
        {
            "value": "YTG",
            "icao_code": null,
            "label": "Sullivan Bay Water Aerodrome , Sullivan Bay"
        },
        {
            "value": "YTH",
            "icao_code": "CYTH",
            "label": "Thompson Airport , Thompson"
        },
        {
            "value": "YTL",
            "icao_code": "CYTL",
            "label": "Big Trout Lake Airport , Kitchenuhmaykoosib Inninuwug"
        },
        {
            "value": "YTM",
            "icao_code": "CYFJ",
            "label": "Mont Tremblant International Airport , Mont-Tremblant"
        },
        {
            "value": "YTO",
            "icao_code": null,
            "label": "metropolitan area , Toronto"
        },
        {
            "value": "YTP",
            "icao_code": null,
            "label": "Tofino Harbour Water Aerodrome , Tofino"
        },
        {
            "value": "YTQ",
            "icao_code": "CYTQ",
            "label": "Tasiujaq Airport , Tasiujaq"
        },
        {
            "value": "YTR",
            "icao_code": "CYTR",
            "label": "CFB Trenton , Trenton"
        },
        {
            "value": "YTS",
            "icao_code": "CYTS",
            "label": "Timmins/Victor M. Power Airport , Timmins"
        },
        {
            "value": "YTT",
            "icao_code": null,
            "label": "Tisdale Airport , Tisdale"
        },
        {
            "value": "YTU",
            "icao_code": null,
            "label": "Tasu Water Aerodrome , Tasu"
        },
        {
            "value": "YTW",
            "icao_code": "ZWYT",
            "label": "Yutian Wanfang Airport , Yutian"
        },
        {
            "value": "YTX",
            "icao_code": null,
            "label": "Telegraph Creek Airport , Telegraph Creek"
        },
        {
            "value": "YTY",
            "icao_code": "ZSYA",
            "label": "Yangzhou Taizhou Airport , Yangzhou"
        },
        {
            "value": "YTZ",
            "icao_code": "CYTZ",
            "label": "Billy Bishop Toronto City Airport , Toronto"
        },
        {
            "value": "YUA",
            "icao_code": "ZPYM",
            "label": "Yuanmou Air Base , Yuanmou"
        },
        {
            "value": "YUB",
            "icao_code": "CYUB",
            "label": "Tuktoyaktuk/James Gruben Airport , Tuktoyaktuk"
        },
        {
            "value": "YUD",
            "icao_code": "CYMU",
            "label": "Umiujaq Airport , Umiujaq"
        },
        {
            "value": "YUE",
            "icao_code": "YYND",
            "label": "Yuendumu Airport , Yuendumu"
        },
        {
            "value": "YUL",
            "icao_code": "CYUL",
            "label": "Montr\u00e9al\u2013Trudeau International Airport , Montreal"
        },
        {
            "value": "YUM",
            "icao_code": "KNYL",
            "label": "Yuma International Airport , Yuma"
        },
        {
            "value": "YUS",
            "icao_code": "ZLYS",
            "label": "Yushu Batang Airport , Yushu City"
        },
        {
            "value": "YUT",
            "icao_code": "CYUT",
            "label": "Repulse Bay Airport , Naujaat"
        },
        {
            "value": "YUX",
            "icao_code": "CYUX",
            "label": "Hall Beach Airport , Hall Beach"
        },
        {
            "value": "YUY",
            "icao_code": "CYUY",
            "label": "Rouyn-Noranda Airport , Rouyn-Noranda"
        },
        {
            "value": "YVA",
            "icao_code": "FMCN",
            "label": "Iconi Airport , Moroni"
        },
        {
            "value": "YVB",
            "icao_code": "CYVB",
            "label": "Bonaventure Airport , Bonaventure"
        },
        {
            "value": "YVC",
            "icao_code": "CYVC",
            "label": "La Ronge (Barber Field) Airport , La Ronge"
        },
        {
            "value": "YVD",
            "icao_code": null,
            "label": "Yeva Airport , Yeva"
        },
        {
            "value": "YVE",
            "icao_code": "CYVK",
            "label": "Vernon Regional Airport , Vernon"
        },
        {
            "value": "YVG",
            "icao_code": "CYVG",
            "label": "Vermilion Airport , Vermilion"
        },
        {
            "value": "YVM",
            "icao_code": "CYVM",
            "label": "Qikiqtarjuaq Airport , Qikiqtarjuaq"
        },
        {
            "value": "YVN",
            "icao_code": "CYVN",
            "label": "Cape Dyer Airport , Cape Dyer"
        },
        {
            "value": "YVO",
            "icao_code": "CYVO",
            "label": "Val-d'Or Airport , Val-d'Or"
        },
        {
            "value": "YVP",
            "icao_code": "CYVP",
            "label": "Kuujjuaq Airport , Kuujjuaq"
        },
        {
            "value": "YVQ",
            "icao_code": "CYVQ",
            "label": "Norman Wells Airport , Norman Wells"
        },
        {
            "value": "YVR",
            "icao_code": "CYVR",
            "label": "Vancouver International Airport , Vancouver"
        },
        {
            "value": "YVT",
            "icao_code": "CYVT",
            "label": "Buffalo Narrows Airport , Buffalo Narrows"
        },
        {
            "value": "YVV",
            "icao_code": "CYVV",
            "label": "Wiarton Airport , Wiarton"
        },
        {
            "value": "YVZ",
            "icao_code": "CYVZ",
            "label": "Deer Lake Airport , Deer Lake"
        },
        {
            "value": "YWA",
            "icao_code": "CYWA",
            "label": "Petawawa Airport , Petawawa"
        },
        {
            "value": "YWB",
            "icao_code": "CYKG",
            "label": "Kangiqsujuaq (Wakeham Bay) Airport , Kangiqsujuaq"
        },
        {
            "value": "YWG",
            "icao_code": "CYWG",
            "label": "Winnipeg James Armstrong Richardson International Airport , Winnipeg"
        },
        {
            "value": "YWH",
            "icao_code": "CYWH",
            "label": "Victoria Harbour Water Airport , Victoria"
        },
        {
            "value": "YWJ",
            "icao_code": "CYWJ",
            "label": "D\u00e9line Airport , Deline"
        },
        {
            "value": "YWK",
            "icao_code": "CYWK",
            "label": "Wabush Airport , Wabush"
        },
        {
            "value": "YWL",
            "icao_code": "CYWL",
            "label": "Williams Lake Airport , Williams Lake"
        },
        {
            "value": "YWM",
            "icao_code": null,
            "label": "Williams Harbour Airport , William's Harbour"
        },
        {
            "value": "YWP",
            "icao_code": "CYWP",
            "label": "Webequie Airport , Webequie"
        },
        {
            "value": "YWQ",
            "icao_code": null,
            "label": "Chutes-des-Passes/Lac Margane Water Aerodrome , Passes-Dangereuses"
        },
        {
            "value": "YWR",
            "icao_code": null,
            "label": "White River Water Aerodrome , White River"
        },
        {
            "value": "YWS",
            "icao_code": null,
            "label": "Whistler/Green Lake Water Aerodrome , Whistler"
        },
        {
            "value": "YWY",
            "icao_code": "CYWY",
            "label": "Wrigley Airport , Wrigley"
        },
        {
            "value": "YXC",
            "icao_code": "CYXC",
            "label": "Cranbrook/Canadian Rockies International Airport , Cranbrook"
        },
        {
            "value": "YXD",
            "icao_code": "CYXD",
            "label": "Edmonton City Centre (Blatchford Field) Airport , Edmonton"
        },
        {
            "value": "YXE",
            "icao_code": "CYXE",
            "label": "Saskatoon John G. Diefenbaker International Airport , Saskatoon"
        },
        {
            "value": "YXH",
            "icao_code": "CYXH",
            "label": "Medicine Hat Airport , Medicine Hat"
        },
        {
            "value": "YXJ",
            "icao_code": "CYXJ",
            "label": "Fort St. John Airport , Fort St. John"
        },
        {
            "value": "YXK",
            "icao_code": "CYXK",
            "label": "Rimouski Airport , Rimouski"
        },
        {
            "value": "YXL",
            "icao_code": "CYXL",
            "label": "Sioux Lookout Airport , Sioux Lookout"
        },
        {
            "value": "YXN",
            "icao_code": "CYXN",
            "label": "Whale Cove Airport , Whale Cove"
        },
        {
            "value": "YXP",
            "icao_code": "CYXP",
            "label": "Pangnirtung Airport , Pangnirtung"
        },
        {
            "value": "YXQ",
            "icao_code": "CYXQ",
            "label": "Beaver Creek Airport , Beaver Creek"
        },
        {
            "value": "YXR",
            "icao_code": "CYXR",
            "label": "Earlton (Timiskaming Regional) Airport , Armstrong"
        },
        {
            "value": "YXS",
            "icao_code": "CYXS",
            "label": "Prince George Airport , Prince George"
        },
        {
            "value": "YXT",
            "icao_code": "CYXT",
            "label": "Northwest Regional Airport , Terrace"
        },
        {
            "value": "YXU",
            "icao_code": "CYXU",
            "label": "London International Airport , London"
        },
        {
            "value": "YXX",
            "icao_code": "CYXX",
            "label": "Abbotsford International Airport , Abbotsford"
        },
        {
            "value": "YXY",
            "icao_code": "CYXY",
            "label": "Erik Nielsen Whitehorse International Airport , Whitehorse"
        },
        {
            "value": "YXZ",
            "icao_code": "CYXZ",
            "label": "Wawa Airport , Wawa"
        },
        {
            "value": "YYA",
            "icao_code": "ZGYY",
            "label": "Yueyang Sanhe Airport , Yueyang"
        },
        {
            "value": "YYB",
            "icao_code": "CYYB",
            "label": "North Bay/Jack Garland Airport , North Bay"
        },
        {
            "value": "YYC",
            "icao_code": "CYYC",
            "label": "Calgary International Airport , Calgary"
        },
        {
            "value": "YYD",
            "icao_code": "CYYD",
            "label": "Smithers Airport , Smithers"
        },
        {
            "value": "YYE",
            "icao_code": "CYYE",
            "label": "Northern Rockies Regional Airport , Fort Nelson"
        },
        {
            "value": "YYF",
            "icao_code": "CYYF",
            "label": "Penticton Regional Airport , Penticton"
        },
        {
            "value": "YYG",
            "icao_code": "CYYG",
            "label": "Charlottetown Airport , Charlottetown"
        },
        {
            "value": "YYH",
            "icao_code": "CYYH",
            "label": "Taloyoak Airport , Taloyoak"
        },
        {
            "value": "YYI",
            "icao_code": "CYYI",
            "label": "Rivers Airport , Rivers"
        },
        {
            "value": "YYJ",
            "icao_code": "CYYJ",
            "label": "Victoria International Airport , Victoria"
        },
        {
            "value": "YYL",
            "icao_code": "CYYL",
            "label": "Lynn Lake Airport , Lynn Lake"
        },
        {
            "value": "YYM",
            "icao_code": "CYYM",
            "label": "Cowley Airport , Cowley"
        },
        {
            "value": "YYN",
            "icao_code": "CYYN",
            "label": "Swift Current Airport , Swift Current"
        },
        {
            "value": "YYQ",
            "icao_code": "CYYQ",
            "label": "Churchill Airport , Churchill"
        },
        {
            "value": "YYR",
            "icao_code": "CYYR",
            "label": "CFB Goose Bay , Happy Valley-Goose Bay"
        },
        {
            "value": "YYT",
            "icao_code": "CYYT",
            "label": "St. John's International Airport , St. John's"
        },
        {
            "value": "YYU",
            "icao_code": "CYYU",
            "label": "Kapuskasing Airport , Kapuskasing"
        },
        {
            "value": "YYW",
            "icao_code": "CYYW",
            "label": "Armstrong Airport , Armstrong"
        },
        {
            "value": "YYY",
            "icao_code": "CYYY",
            "label": "Mont-Joli Airport , Mont-Joli"
        },
        {
            "value": "YYZ",
            "icao_code": "CYYZ",
            "label": "Toronto Pearson International Airport , Toronto"
        },
        {
            "value": "YZA",
            "icao_code": "CYZA",
            "label": "Cache Creek Airport , Cache Creek"
        },
        {
            "value": "YZE",
            "icao_code": "CYZE",
            "label": "Gore Bay-Manitoulin Airport , Gore Bay"
        },
        {
            "value": "YZF",
            "icao_code": "CYZF",
            "label": "Yellowknife Airport , Yellowknife"
        },
        {
            "value": "YZG",
            "icao_code": "CYZG",
            "label": "Salluit Airport , Salluit"
        },
        {
            "value": "YZH",
            "icao_code": "CYZH",
            "label": "Slave Lake Airport , Slave Lake"
        },
        {
            "value": "YZP",
            "icao_code": "CYZP",
            "label": "Sandspit Airport , Sandspit"
        },
        {
            "value": "YZR",
            "icao_code": "CYZR",
            "label": "Sarnia Chris Hadfield Airport , Sarnia"
        },
        {
            "value": "YZS",
            "icao_code": "CYZS",
            "label": "Coral Harbour Airport , Coral Harbour"
        },
        {
            "value": "YZT",
            "icao_code": "CYZT",
            "label": "Port Hardy Airport , Port Hardy"
        },
        {
            "value": "YZU",
            "icao_code": "CYZU",
            "label": "Whitecourt Airport , Whitecourt"
        },
        {
            "value": "YZV",
            "icao_code": "CYZV",
            "label": "Sept-\u00celes Airport , Sept-\u00celes"
        },
        {
            "value": "YZW",
            "icao_code": "CYZW",
            "label": "Teslin Airport , Teslin"
        },
        {
            "value": "YZX",
            "icao_code": "CYZX",
            "label": "CFB Greenwood , Greenwood"
        },
        {
            "value": "YZY",
            "icao_code": "ZLZY",
            "label": "Zhangye Ganzhou Airport , Zhangye"
        },
        {
            "value": "YZZ",
            "icao_code": null,
            "label": "Trail Airport , Trail"
        },
        {
            "value": "ZAA",
            "icao_code": null,
            "label": "Alice Arm/Silver City Water Aerodrome , Alice Arm"
        },
        {
            "value": "ZAC",
            "icao_code": "CZAC",
            "label": "York Landing Airport , York Landing"
        },
        {
            "value": "ZAD",
            "icao_code": "LDZD",
            "label": "Zadar Airport , Zadar"
        },
        {
            "value": "ZAG",
            "icao_code": "LDZA",
            "label": "Franjo Tu\u0111man Airport , Zagreb"
        },
        {
            "value": "ZAH",
            "icao_code": "OIZH",
            "label": "Zahedan Airport , Zahedan"
        },
        {
            "value": "ZAJ",
            "icao_code": "OAZJ",
            "label": "Zaranj Airport , Zaranj"
        },
        {
            "value": "ZAL",
            "icao_code": "SCVD",
            "label": "Pichoy Airport , Valdivia"
        },
        {
            "value": "ZAM",
            "icao_code": "RPMZ",
            "label": "Zamboanga International Airport , Zamboanga City"
        },
        {
            "value": "ZAO",
            "icao_code": "LFCC",
            "label": "Cahors - Lalbenque Airport , Cahors"
        },
        {
            "value": "ZAR",
            "icao_code": "DNZA",
            "label": "Zaria Airport , Zaria"
        },
        {
            "value": "ZAT",
            "icao_code": "ZPZT",
            "label": "Zhaotong Airport , Zhaotong"
        },
        {
            "value": "ZAZ",
            "icao_code": "LEZG",
            "label": "Zaragoza Airport , Zaragoza"
        },
        {
            "value": "ZBE",
            "icao_code": "LKZA",
            "label": "Z\u00e1b\u0159eh Airport , Doln\u00ed Bene\u0161ov"
        },
        {
            "value": "ZBF",
            "icao_code": "CZBF",
            "label": "Bathurst Airport , Bathurst"
        },
        {
            "value": "ZBK",
            "icao_code": null,
            "label": "\u017dabljak Airport , \u017dabljak"
        },
        {
            "value": "ZBL",
            "icao_code": null,
            "label": "Biloela Airport , Biloela"
        },
        {
            "value": "ZBM",
            "icao_code": "CZBM",
            "label": "Roland-D\u00e9sourdy Airport , Bromont"
        },
        {
            "value": "ZBO",
            "icao_code": "YBWN",
            "label": "Bowen Airport , Bowen"
        },
        {
            "value": "ZBR",
            "icao_code": "OIZC",
            "label": "Konarak Airport , Chabahar"
        },
        {
            "value": "ZBY",
            "icao_code": "VLSB",
            "label": "Sayaboury Airport , Sainyabuli"
        },
        {
            "value": "ZCL",
            "icao_code": "MMZC",
            "label": "General Leobardo C. Ruiz International Airport , Zacatecas City"
        },
        {
            "value": "ZCO",
            "icao_code": "SCQP",
            "label": "La Araucan\u00eda Airport , Temuco"
        },
        {
            "value": "ZDJ",
            "icao_code": null,
            "label": "Bern Railway Station , Bern, Switzerland"
        },
        {
            "value": "ZDY",
            "icao_code": "OMDL",
            "label": "Dalma Airport , Dalma Island"
        },
        {
            "value": "ZEC",
            "icao_code": "FASC",
            "label": "Secunda Airport , Secunda"
        },
        {
            "value": "ZEG",
            "icao_code": null,
            "label": "Senggo Airport , Senggo"
        },
        {
            "value": "ZEL",
            "icao_code": "CBBC",
            "label": "Bella Bella (Campbell Island) Airport , Bella Bella"
        },
        {
            "value": "ZEM",
            "icao_code": "CZEM",
            "label": "Eastmain River Airport , Eastmain"
        },
        {
            "value": "ZEN",
            "icao_code": null,
            "label": "Zenag Airport , Zenag"
        },
        {
            "value": "ZER",
            "icao_code": "VEZO",
            "label": "Zero Airport , Ziro"
        },
        {
            "value": "ZFA",
            "icao_code": "CZFA",
            "label": "Faro Airport , Faro"
        },
        {
            "value": "ZFD",
            "icao_code": "CZFD",
            "label": "Fond-du-Lac Airport , Fond-du-Lac"
        },
        {
            "value": "ZFM",
            "icao_code": "CZFM",
            "label": "Fort McPherson Airport , Fort McPherson"
        },
        {
            "value": "ZFN",
            "icao_code": "CZFN",
            "label": "Tulita Airport , Tulita"
        },
        {
            "value": "ZFW",
            "icao_code": null,
            "label": "Fairview Airport , Fairview"
        },
        {
            "value": "ZGF",
            "icao_code": "CZGF",
            "label": "Grand Forks Airport , Grand Forks"
        },
        {
            "value": "ZGI",
            "icao_code": "CZGI",
            "label": "Gods River Airport , Gods River"
        },
        {
            "value": "ZGL",
            "icao_code": "YSGW",
            "label": "South Galway Airport , South Galway Station"
        },
        {
            "value": "ZGM",
            "icao_code": "FLNA",
            "label": "Ngoma Airport , Ngoma"
        },
        {
            "value": "ZGR",
            "icao_code": "CZGR",
            "label": "Little Grand Rapids Airport , Little Grand Rapids"
        },
        {
            "value": "ZGS",
            "icao_code": null,
            "label": "La Romaine Airport , La Romaine"
        },
        {
            "value": "ZGU",
            "icao_code": "NVSQ",
            "label": "Gaua Airport , Gaua"
        },
        {
            "value": "ZHA",
            "icao_code": "ZGZJ",
            "label": "Zhanjiang Wuchuan Airport , Zhanjiang"
        },
        {
            "value": "ZHM",
            "icao_code": "VGSH",
            "label": "Shamshernagar Airport , Shamshernagar"
        },
        {
            "value": "ZHP",
            "icao_code": "CZHP",
            "label": "High Prairie Airport , High Prairie"
        },
        {
            "value": "ZHT",
            "icao_code": null,
            "label": "Geneva Railway Station , Geneva, Switzerland"
        },
        {
            "value": "ZHY",
            "icao_code": "ZLZW",
            "label": "Zhongwei Shapotou Airport , Zhongwei"
        },
        {
            "value": "ZIA",
            "icao_code": "UUBW",
            "label": "Ramenskoye Airport , Zhukovsky"
        },
        {
            "value": "ZIC",
            "icao_code": "SCTO",
            "label": "Victoria Airport , Victoria"
        },
        {
            "value": "ZIG",
            "icao_code": "GOGG",
            "label": "Ziguinchor Airport , Ziguinchor"
        },
        {
            "value": "ZIH",
            "icao_code": "MMZH",
            "label": "Ixtapa-Zihuatanejo International Airport , Ixtapa"
        },
        {
            "value": "ZIS",
            "icao_code": "HLZN",
            "label": "Alzintan Airport , Zintan"
        },
        {
            "value": "ZIX",
            "icao_code": "UEVV",
            "label": "Zhigansk Airport , Zhigansk"
        },
        {
            "value": "ZIZ",
            "icao_code": null,
            "label": "Zamzama Airport , Zamzama"
        },
        {
            "value": "ZJG",
            "icao_code": "CZJG",
            "label": "Jenpeg Airport , Jenpeg"
        },
        {
            "value": "ZJN",
            "icao_code": "CZJN",
            "label": "Swan River Airport , Swan River"
        },
        {
            "value": "ZJT",
            "icao_code": null,
            "label": "Tanjung Pelepas Port Airport , Tanjung Pelepas"
        },
        {
            "value": "ZKB",
            "icao_code": "FLKY",
            "label": "Kasaba Bay Airport , Kasaba Bay"
        },
        {
            "value": "ZKE",
            "icao_code": "CZKE",
            "label": "Kashechewan Airport , Kashechewan"
        },
        {
            "value": "ZKG",
            "icao_code": null,
            "label": "Kegaska Airport , Kegaska"
        },
        {
            "value": "ZKP",
            "icao_code": "UESU",
            "label": "Zyryanka Airport , Zyryanka"
        },
        {
            "value": "ZLO",
            "icao_code": "MMZO",
            "label": "Playa de Oro International Airport , Manzanillo"
        },
        {
            "value": "ZLT",
            "icao_code": null,
            "label": "La Tabati\u00e8re Airport , Gros-M\u00e9catina"
        },
        {
            "value": "ZLW",
            "icao_code": null,
            "label": "Pasir Gudang Port Airport , Pasir Gudang"
        },
        {
            "value": "ZLX",
            "icao_code": "HSZA",
            "label": "Zalingei Airport , Zalingei"
        },
        {
            "value": "ZMD",
            "icao_code": "SWSN",
            "label": "Sena Madureira Airport , Sena Madureira"
        },
        {
            "value": "ZMH",
            "icao_code": "CZML",
            "label": "South Cariboo Regional Airport , 108 Mile Ranch"
        },
        {
            "value": "ZMM",
            "icao_code": "MMZM",
            "label": "Zamora National Airport , Zamora"
        },
        {
            "value": "ZMT",
            "icao_code": "CZMT",
            "label": "Masset Airport , Masset"
        },
        {
            "value": "ZNA",
            "icao_code": null,
            "label": "Nanaimo Harbour Water Airport , Nanaimo"
        },
        {
            "value": "ZNC",
            "icao_code": null,
            "label": "Nyac Airport , Nyac"
        },
        {
            "value": "ZND",
            "icao_code": "DRZR",
            "label": "Zinder Airport , Zinder"
        },
        {
            "value": "ZNE",
            "icao_code": "YNWN",
            "label": "Newman Airport , Newman"
        },
        {
            "value": "ZNU",
            "icao_code": null,
            "label": "Namu Water Aerodrome , Namu"
        },
        {
            "value": "ZNZ",
            "icao_code": "HTZA",
            "label": "Abeid Amani Karume International Airport , Zanzibar"
        },
        {
            "value": "ZOF",
            "icao_code": null,
            "label": "Ocean Falls Water Aerodrome , Ocean Falls"
        },
        {
            "value": "ZOS",
            "icao_code": "SCJO",
            "label": "Ca\u00f1al Bajo Carlos Hott Siebert Airport , Osorno"
        },
        {
            "value": "ZPB",
            "icao_code": "CZPB",
            "label": "Sachigo Lake Airport , Sachigo Lake"
        },
        {
            "value": "ZPC",
            "icao_code": "SCPC",
            "label": "Puc\u00f3n Airport , Puc\u00f3n"
        },
        {
            "value": "ZPH",
            "icao_code": "KZPH",
            "label": "Zephyrhills Municipal Airport , Zephyrhills"
        },
        {
            "value": "ZPO",
            "icao_code": "CZPO",
            "label": "Pinehouse Lake Airport , Pinehouse"
        },
        {
            "value": "ZQN",
            "icao_code": "NZQN",
            "label": "Queenstown Airport , Queenstown"
        },
        {
            "value": "ZQS",
            "icao_code": null,
            "label": "Queen Charlotte City Water Aerodrome , Queen Charlotte"
        },
        {
            "value": "ZQW",
            "icao_code": "EDRZ",
            "label": "Zweibr\u00fccken Airport , Zweibr\u00fccken"
        },
        {
            "value": "ZQZ",
            "icao_code": "ZBZJ",
            "label": "Zhangjiakou Ningyuan Airport , Zhangjiakou"
        },
        {
            "value": "ZRH",
            "icao_code": "LSZH",
            "label": "Zurich Airport , Z\u00fcrich"
        },
        {
            "value": "ZRI",
            "icao_code": "WABO",
            "label": "Serui Airport , Serui"
        },
        {
            "value": "ZRJ",
            "icao_code": "CZRJ",
            "label": "Round Lake (Weagamow Lake) Airport , North Caribou Lake"
        },
        {
            "value": "ZRM",
            "icao_code": "WAJI",
            "label": "Sarmi Orai Airport , Sarmi"
        },
        {
            "value": "ZSA",
            "icao_code": "MYSM",
            "label": "San Salvador Airport , San Salvador Island"
        },
        {
            "value": "ZSE",
            "icao_code": "FMEP",
            "label": "Pierrefonds Airport , Saint-Pierre"
        },
        {
            "value": "ZSJ",
            "icao_code": "CZSJ",
            "label": "Sandy Lake Airport , Sandy Lake"
        },
        {
            "value": "ZSS",
            "icao_code": "DISS",
            "label": "Sassandra Airport , Sassandra"
        },
        {
            "value": "ZST",
            "icao_code": "CZST",
            "label": "Stewart Aerodrome , Stewart"
        },
        {
            "value": "ZSW",
            "icao_code": "CZSW",
            "label": "Prince Rupert/Seal Cove Water Airport , Prince Rupert"
        },
        {
            "value": "ZTA",
            "icao_code": "NTGY",
            "label": "Tureia Airport , Tureia"
        },
        {
            "value": "ZTB",
            "icao_code": null,
            "label": "T\u00eate-\u00e0-la-Baleine Airport , T\u00eate-\u00e0-la-Baleine"
        },
        {
            "value": "ZTH",
            "icao_code": "LGZA",
            "label": "Zakynthos International Airport , Zakynthos"
        },
        {
            "value": "ZTM",
            "icao_code": "CZTM",
            "label": "Shamattawa Airport , Shamattawa"
        },
        {
            "value": "ZTR",
            "icao_code": "UKKV",
            "label": "Zhytomyr Airport , Zhytomyr"
        },
        {
            "value": "ZTS",
            "icao_code": null,
            "label": "Tahsis Water Aerodrome , Tahsis"
        },
        {
            "value": "ZTU",
            "icao_code": "UBBY",
            "label": "Zaqatala International Airport , Zaqatala"
        },
        {
            "value": "ZUC",
            "icao_code": "CZUC",
            "label": "Ignace Municipal Airport , Ignace"
        },
        {
            "value": "ZUD",
            "icao_code": "SCAC",
            "label": "Pupelde Airfield , Ancud"
        },
        {
            "value": "ZUH",
            "icao_code": "ZGSD",
            "label": "Zhuhai Jinwan Airport , Zhuhai"
        },
        {
            "value": "ZUL",
            "icao_code": "OEZL",
            "label": "Zilfi Airport , Al Zulfi"
        },
        {
            "value": "ZUM",
            "icao_code": "CZUM",
            "label": "Churchill Falls Airport , Churchill Falls"
        },
        {
            "value": "ZVA",
            "icao_code": "FMMN",
            "label": "Miandrivazo Airport , Miandrivazo"
        },
        {
            "value": "ZVG",
            "icao_code": null,
            "label": "Springvale Airport , Springvale"
        },
        {
            "value": "ZVK",
            "icao_code": "VLSK",
            "label": "Savannakhet Airport , Savannakhet"
        },
        {
            "value": "ZWA",
            "icao_code": "FMND",
            "label": "Andapa Airport , Andapa"
        },
        {
            "value": "ZWL",
            "icao_code": "CZWL",
            "label": "Wollaston Lake Airport , Wollaston Lake"
        },
        {
            "value": "ZXT",
            "icao_code": "UBTT",
            "label": "Zabrat Airport , Baku"
        },
        {
            "value": "ZYI",
            "icao_code": "ZUZY",
            "label": "Zunyi Xinzhou Airport , Zunyi"
        },
        {
            "value": "ZYL",
            "icao_code": "VGSY",
            "label": "Osmani International Airport , Sylhet"
        },
        {
            "value": "ZZE",
            "icao_code": "UBBZ",
            "label": "Zangilan International Airport , Zangilan"
        },
        {
            "value": "ZZO",
            "icao_code": "UHSO",
            "label": "Zonalnoye Airport , Tymovskoye"
        },
        {
            "value": "ZZU",
            "icao_code": "FWUU",
            "label": "Mzuzu Airport , Mzuzu"
        },
        {
            "value": "ZZV",
            "icao_code": "KZZV",
            "label": "Zanesville Municipal Airport , Zanesville"
        }
    ];

    const [selectedOrigin, setSelectedOrigin] = useState(null);
    const handleChangeSelectOrigin = (value) => {
        console.log(value);
        setSelectedOrigin(value);
    }
    
    const [selectedDestination, setSelectedDestination] = useState(null);
    const handleChangeSelectDestination= (value) => {
        console.log(value);
        setSelectedDestination(value);
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={close}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto relative w-96">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-500"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                                            <button
                                                type="button"
                                                className=""
                                                onClick={close}
                                            >
                                                <span className="sr-only">Close panel</span>
                                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="h-full overflow-y-auto bg-white p-8">
                                        <h1 className="font-bold text-violet-500">Edit Flight Details</h1>
                                        <div className="mt-2">
                                            <form id='editForm' onSubmit={postRecord}>
                                                <h3 className="font-medium text-indigo-900">Select Passengers</h3>
                                                <Select
                                                    id="passengers"
                                                    name="passengers"
                                                    value={selectedPassenger}
                                                    options={passengerList}
                                                    isSearchable={true}
                                                    isMultiple={true}
                                                    isClearable={true}
                                                    primaryColor='black'
                                                    searchInputPlaceholder='Search'
                                                    onChange={handleChangeSelectPassenger}
                                                    classNames={{
                                                        searchIcon: `absolute w-5 h-5 mt-2.5 pb-0.5 ml-12 text-gray-500`,
                                                        tagItemIconContainer : `flex items-center px-2  cursor-pointer rounded-r-sm hover:bg-red-200 hover:text-red-600`,
                                                        tagItemIcon : `w-3 h-3 bg-white-500 `

                                                    }}
                                                />
                                                <h3 className="font-medium text-indigo-900">Airline</h3>
                                                <input
                                                    id="airline"
                                                    name="airline"
                                                    type="text"
                                                    required
                                                    value={record.airline}
                                                    onChange={handleFormChange}
                                                    placeholder="Airline *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">PNR (If Confirmed)</h3>
                                                <input
                                                    id="pnr"
                                                    name="pnr"
                                                    type="text"
                                                    value={record.pnr}
                                                    onChange={handleFormChange}
                                                    placeholder="PNR"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Origin Airport</h3>
                                                <Select
                                                    id="origin_airport"
                                                    name="origin_airport"
                                                    value={selectedOrigin}
                                                    options={airports}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    primaryColor='black'
                                                    searchInputPlaceholder='Search'
                                                    onChange={handleChangeSelectOrigin}
                                                    classNames={{
                                                        searchIcon: `absolute w-5 h-5 mt-2.5 pb-0.5 ml-12 text-gray-500`,
                                                    }}
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Departure Date/Time</h3>
                                                <input
                                                    id="departure_datetime"
                                                    name="departure_datetime"
                                                    type="datetime-local"
                                                    required
                                                    value={record.departure_datetime}
                                                    onChange={handleFormChange}
                                                    placeholder="Departure Date/Time *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Destination Airport</h3>
                                                <Select
                                                    id="destination_airport"
                                                    name="odestination_airport"
                                                    value={selectedDestination}
                                                    options={airports}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    primaryColor='black'
                                                    searchInputPlaceholder='Search'
                                                    onChange={handleChangeSelectDestination}
                                                    classNames={{
                                                        searchIcon: `absolute w-5 h-5 mt-2.5 pb-0.5 ml-12 text-gray-500`,
                                                    }}
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Arrival Date/Time</h3>
                                                <input
                                                    id="arrival_datetime"
                                                    name="arrival_datetime"
                                                    type="datetime-local"
                                                    required
                                                    value={record.arrival_datetime}
                                                    onChange={handleFormChange}
                                                    placeholder="Arrival Date/Time *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Status</h3>
                                                <select
                                                    id="status"
                                                    name="status"
                                                    className="block w-full mt-2 py-2 pl-3 pr-10 mt-2 text-gray-900 border-0 rounded-md ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                    value={record.status}
                                                    onChange={handleFormChange}
                                                >
                                                    <option>Not Booked</option>
                                                    <option>Booked, To be Confirmed</option>
                                                    <option>Confirmed</option>
                                                </select>
                                                <h3 className="font-medium mt-2 text-indigo-900">Quantity</h3>
                                                <input
                                                    id="quantity"
                                                    name="quantity"
                                                    type="number"
                                                    required
                                                    value={record.quantity}
                                                    onChange={handleFormChange}
                                                    placeholder="Quantity *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Buy Price (Paid By You)</h3>
                                                <input
                                                    id="buy_price"
                                                    name="buy_price"
                                                    type="number"
                                                    required
                                                    value={record.buy_price}
                                                    onChange={handleFormChange}
                                                    placeholder="Buy Price *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Quote Price (Paid By Customer To You)</h3>
                                                <input
                                                    id="quote_price"
                                                    name="quote_price"
                                                    type="number"
                                                    required
                                                    value={record.quote_price}
                                                    onChange={handleFormChange}
                                                    placeholder="Quote Price *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <div className="mt-2 flex">
                                                    <button
                                                        type="submit"
                                                        className="flex-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default EditFlightForm;