import React, { Fragment } from 'react'
import { Image, Text, View, Page, Document, StyleSheet } from '@react-pdf/renderer';
import { ToWords } from 'to-words';

const Quotation = ({quote_data}) => {
    if(quote_data == null)
        return (<Document><Page></Page></Document>);
    const toWords = new ToWords({
        converterOptions: {
            currency: false,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false
        }
    });

    const styles = StyleSheet.create({
        page: { fontSize: 11, paddingTop: 20, paddingLeft: 30, paddingRight: 30, paddingBottom: 50,lineHeight: 1.5, flexDirection: 'column' },

        spaceBetween: { flex: 2, flexDirection: 'row', justifyContent: 'space-between', color: "#3E3E3E" },
        
        logo: { width: 90, height: 45},

        titleContainer: { flexDirection: 'row', marginTop: 10 },

        reportTitle: { fontSize: 12, textAlign: 'center' },

        addressTitle: { fontSize: 10 },

        heading: { fontWeight: 'bold', fontSize: 42 },

        Misc: { fontSize: 14, fontWeight: 'bold' },

        address: { fontWeight: 400, fontSize: 10 },

        footer: { position:'absolute', width:'200%', bottom:10, fontSize: 8, fontStyle: 'bold', paddingTop: 4, paddingLeft: 7, backgroundColor: quote_data.footer_tab_color, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1},

        quoteDetail: { fontSize: 11, fontStyle: 'bold', paddingTop: 4, paddingLeft: 7, flex: 1, height: 25, backgroundColor: quote_data.detail_tab_color, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },

        theader: { fontSize: 11, fontStyle: 'bold', paddingTop: 4, paddingLeft: 7, flex: 0.5, height: 25, backgroundColor: quote_data.table_tab_color, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },

        theader2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },

        tbody: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 0.5, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1, borderLeftWidth:1 },

        total: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 3, borderColor: 'whitesmoke', borderBottomWidth: 1, borderLeftWidth:1 },

        tbody2: { flex: 2, borderRightWidth: 1, },

        tbodyIE: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 1},

    });

    const QuoteTop = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    <Image style={styles.logo} src={quote_data.logo} />
                </View>
                <View>
                    <Text style={styles.heading}>Tour Quote</Text>
                </View>
            </View>
        </View>
    );
    
    const QuoteDetail = () => (
        <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
            <View style={styles.quoteDetail}>
                <Text >Valid Till:{quote_data.valid_till_date}</Text>
            </View>
            <View style={styles.quoteDetail}>
                <Text>Quotation Number:{quote_data.id}</Text>
            </View>
            <View style={styles.quoteDetail}>
                <Text>Currency:{quote_data.currency}</Text>
            </View>
        </View>
    );

    const QuoteTo = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View style={{ maxWidth: 200 }}>
                    <Text style={styles.Misc}>To:</Text>
                    <Text style={styles.address}>
                        {quote_data.customer_name}
                    </Text>
                    <Text style={styles.address}>
                        {quote_data.customer_phone} , {quote_data.customer_email}
                    </Text>
                </View>
            </View>
        </View>
    );

    const QuoteTitle = () => (
        <View style={styles.titleContainer}>
            <View>
                <Text style={styles.Misc}>{quote_data.title}</Text>
            </View>
        </View>
    );

    const DescriptionTableHead = () => (
        <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
            <View style={[styles.theader, styles.theader2]}>
                <Text >Description</Text>
            </View>
            <View style={styles.theader}>
                <Text>Qty</Text>
            </View>
            <View style={styles.theader}>
                <Text>Amount</Text>
            </View>
        </View>
    );


    const DescriptionTableBody = () => (
        quote_data.items.map((receipt) => (
            <Fragment key={receipt.id}>
                <View style={{ width: '100%', flexDirection: 'row' }}>
                    <View style={[styles.tbody, styles.tbody2]}>
                        <Text >{receipt.description}</Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{receipt.quantity}</Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{receipt.amount}</Text>
                    </View>
                </View>
            </Fragment>
        ))
        
    );

    const DescriptionTableTotal = () => (
        <View style={{ width: '100%', flexDirection: 'row' }}>
            <View style={styles.tbody}>
                <Text>Total</Text>
            </View>
            <View style={styles.total}>
                <Text>{toWords.convert(quote_data.items.reduce((sum, item) => sum + (parseInt(item.amount) * parseInt(item.quantity)), 0))}</Text>
            </View>
            <View style={styles.tbody}>
                <Text >
                    {quote_data.items.reduce((sum, item) => sum + (parseInt(item.amount) * parseInt(item.quantity)), 0)}
                </Text>
            </View>
        </View>
    );
    
    const InclusionTableHead = () => (
        <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
            <View style={[styles.theader, styles.theader2]}>
                <Text >Inclusions : </Text>
            </View>
        </View>
    );


    const InclusionTableBody = () => (
            <Fragment key={1}>
                <View style={{ width: '100%', flexDirection: 'row' }}>
                    <View style={styles.tbodyIE}>
                        <Text >{quote_data.inclusions}</Text>
                    </View>
                </View>
            </Fragment>
    );

    const ExclusionTableHead = () => (
        <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
            <View style={[styles.theader, styles.theader2]}>
                <Text >Exclusions : </Text>
            </View>
        </View>
    );


    const ExclusionTableBody = () => (
            <Fragment key={1}>
                <View style={{ width: '100%', flexDirection: 'row' }}>
                    <View style={styles.tbodyIE}>
                        <Text >{quote_data.exclusions}</Text>
                    </View>
                </View>
            </Fragment>
    );
    
    const NotesTableHead = () => (
        <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
            <View style={[styles.theader, styles.theader2]}>
                <Text >Notes : </Text>
            </View>
        </View>
    );


    const NotesTableBody = () => (
            <Fragment key={1}>
                <View style={{ width: '100%', flexDirection: 'row' }}>
                    <View style={styles.tbodyIE}>
                        <Text >{quote_data.notes}</Text>
                    </View>
                </View>
            </Fragment>
    );

    const QuoteFooter = () => (
            <View style={styles.footer} fixed>
                <Text >Contact Us:{quote_data.contact_footer_1}</Text>
                <Text >{quote_data.contact_footer_2}</Text>
            </View>
    );

    return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                <QuoteTop />
                <QuoteDetail />
                <QuoteTo />
                <QuoteTitle />
                <DescriptionTableHead />
                <DescriptionTableBody wrap/>
                <DescriptionTableTotal wrap/>
                <InclusionTableHead wrap/>
                <InclusionTableBody wrap/>
                <ExclusionTableHead wrap/>
                <ExclusionTableBody wrap/>
                <NotesTableHead wrap/>
                <NotesTableBody wrap/>
                <QuoteFooter fixed/>
            </Page>
        </Document>

    )
}

export default Quotation