import { Fragment, useState, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { HeartIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { PaperClipIcon, PlusIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import Select from "react-tailwindcss-select";

const AddPassengerForm = ({ open, close, tour_id }) => {

  //To Store Uploaded Files
  const [passportFile, SetPassportFile] = useState("");
  const [nationalIDFile, SetNationalIDFile] = useState("");

  const SetPassportFileHelper = (e) => {
    e.preventDefault();
    SetPassportFile(e.target.files[0]);
  }

  const SetNationalIDFileHelper = (e) => {
    e.preventDefault();
    SetNationalIDFile(e.target.files[0]);
  }

  //To store name of server files
  const [passportFileServer, SetPassportFileServer] = useState("default");
  const [nationalIDFileServer, SetNationalIDFileServer] = useState("default");
  //To Add the Passenger Record (API Call)
  const postRecord = async (event) => {
    event.preventDefault();
    const formdata = new FormData(document.getElementById('addForm'));

    if(formdata.get("last_name").length == 0){
      alert("Please Enter Last Name.")
    }
    if(formdata.get("dob").length == 0){
      alert("Please Enter DOB.")
    }
    if(selectedNationality == null){
      alert("Please Select Nationality.")
    }
    const update_data = {
      first_name: formdata.get("first_name"),
      middle_name: formdata.get("middle_name"),
      last_name: formdata.get("last_name"),
      dob: formdata.get("dob"),
      gender: formdata.get("gender"),
      nationality: selectedNationality.value,
      passport: formdata.get("passport"),
      national_id: formdata.get("national_id"),
      passport_file: passportFileServer,
      national_id_file: nationalIDFileServer
    };
    const data = { ...update_data, 'action': 'add_passenger', 'username': localStorage.getItem('user_id'),'tours_id': tour_id };
    const token = localStorage.getItem('token');
    const config = { Authorization: `Bearer ${token}` };
    const response = await axios.post(`http://test-ilios-master.linkpc.net/api/addDocuments.php`, data, {
      headers: config
    });
    if (response.data.message == "Success") {
      document.location.reload();
    } else {
      alert(response.data.message);
    }
  }

  //Use to set the button for upload of files or display status as uploaded.
  const [passportUploaded, setPassportUploaded] = useState(false);
  const [nationalIDUploaded, setNationalIDUploaded] = useState(false);

  //File Input using custom button style
  const hiddenFileInputPassport = useRef(null);
  const handleClickPassport = event => {
    hiddenFileInputPassport.current.click();
    setPassportUploaded(false);
  };
  const hiddenFileInputNationalID = useRef(null);
  const handleClickNationalID = event => {
    hiddenFileInputNationalID.current.click();
    setNationalIDUploaded(false);
  };

  //Handle Upload Call
  const uploadPassport = async() => {
    var fileSize = passportFile.size / 1024;
    console.log(passportFile);
    if (fileSize > 1024) 
    {
      alert('File Size Exceeds 1MB Limit.');
    }
    else 
    {
        const formData = new FormData();
        formData.append('pdf', passportFile);
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data', 'unique_id':tour_id};
        const response = await axios.post('http://test-ilios-master.linkpc.net/api/files/uploadPassport.php', formData, {
          headers: config
        });
        if(response.data.message == "Success")
        {
          setPassportUploaded(true);
          SetPassportFileServer(response.data.name);
        }
        else
        {
          alert('Error uploading file'); 
        }
    }
  }
  const uploadNationalID = async() => {
    var fileSize = nationalIDFile.size / 1024;
    console.log(nationalIDFile);
    if (fileSize > 1024) {
      alert('File Size Exceeds 1MB Limit.');
    }
    else {
        const formData = new FormData();
        formData.append('pdf', nationalIDFile);
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data', 'unique_id':tour_id}
        const response = await axios.post('http://test-ilios-master.linkpc.net/api/files/uploadNationalID.php', formData, {
          headers: config
        });
        if(response.data.message == "Success")
        {
          setNationalIDUploaded(true);
          SetNationalIDFileServer(response.data.name);
        }
        else
        {
          alert('Error uploading file'); 
        }
      
    }
  }
  const countriesWithFlags = [
    { "value": "Afghanistan", "label": "Afghanistan", "emojicode": "AF" },
    { "value": "Albania", "label": "Albania", "emojicode": "AL" },
    { "value": "Algeria", "label": "Algeria", "emojicode": "DZ" },
    { "value": "Andorra", "label": "Andorra", "emojicode": "AD" },
    { "value": "Angola", "label": "Angola", "emojicode": "AO" },
    { "value": "Antigua and Barbuda", "label": "Antigua and Barbuda", "emojicode": "AG" },
    { "value": "Argentina", "label": "Argentina", "emojicode": "AR" },
    { "value": "Armenia", "label": "Armenia", "emojicode": "AM" },
    { "value": "Australia", "label": "Australia", "emojicode": "AU" },
    { "value": "Austria", "label": "Austria", "emojicode": "AT" },
    { "value": "Azerbaijan", "label": "Azerbaijan", "emojicode": "AZ" },
    { "value": "Bahamas", "label": "Bahamas", "emojicode": "BS" },
    { "value": "Bahrain", "label": "Bahrain", "emojicode": "BH" },
    { "value": "Bangladesh", "label": "Bangladesh", "emojicode": "BD" },
    { "value": "Barbados", "label": "Barbados", "emojicode": "BB" },
    { "value": "Belarus", "label": "Belarus", "emojicode": "BY" },
    { "value": "Belgium", "label": "Belgium", "emojicode": "BE" },
    { "value": "Belize", "label": "Belize", "emojicode": "BZ" },
    { "value": "Benin", "label": "Benin", "emojicode": "BJ" },
    { "value": "Bhutan", "label": "Bhutan", "emojicode": "BT" },
    { "value": "Bolivia", "label": "Bolivia", "emojicode": "BO" },
    { "value": "Bosnia and Herzegovina", "label": "Bosnia and Herzegovina", "emojicode": "BA" },
    { "value": "Botswana", "label": "Botswana", "emojicode": "BW" },
    { "value": "Brazil", "label": "Brazil", "emojicode": "BR" },
    { "value": "Brunei", "label": "Brunei", "emojicode": "BN" },
    { "value": "Bulgaria", "label": "Bulgaria", "emojicode": "BG" },
    { "value": "Burkina Faso", "label": "Burkina Faso", "emojicode": "BF" },
    { "value": "Burundi", "label": "Burundi", "emojicode": "BI" },
    { "value": "Cabo Verde", "label": "Cabo Verde", "emojicode": "CV" },
    { "value": "Cambodia", "label": "Cambodia", "emojicode": "KH" },
    { "value": "Cameroon", "label": "Cameroon", "emojicode": "CM" },
    { "value": "Canada", "label": "Canada", "emojicode": "CA" },
    { "value": "Central African Republic", "label": "Central African Republic", "emojicode": "CF" },
    { "value": "Chad", "label": "Chad", "emojicode": "TD" },
    { "value": "Chile", "label": "Chile", "emojicode": "CL" },
    { "value": "China", "label": "China", "emojicode": "CN" },
    { "value": "Colombia", "label": "Colombia", "emojicode": "CO" },
    { "value": "Comoros", "label": "Comoros", "emojicode": "KM" },
    { "value": "Congo, Democratic Republic of the", "label": "Congo, Democratic Republic of the", "emojicode": "CD" },
    { "value": "Congo, Republic of the", "label": "Congo, Republic of the", "emojicode": "CG" },
    { "value": "Costa Rica", "label": "Costa Rica", "emojicode": "CR" },
    { "value": "Croatia", "label": "Croatia", "emojicode": "HR" },
    { "value": "Cuba", "label": "Cuba", "emojicode": "CU" },
    { "value": "Cyprus", "label": "Cyprus", "emojicode": "CY" },
    { "value": "Czech Republic", "label": "Czech Republic", "emojicode": "CZ" },
    { "value": "Denmark", "label": "Denmark", "emojicode": "DK" },
    { "value": "Djibouti", "label": "Djibouti", "emojicode": "DJ" },
    { "value": "Dominica", "label": "Dominica", "emojicode": "DM" },
    { "value": "Dominican Republic", "label": "Dominican Republic", "emojicode": "DO" },
    { "value": "Ecuador", "label": "Ecuador", "emojicode": "EC" },
    { "value": "Egypt", "label": "Egypt", "emojicode": "EG" },
    { "value": "El Salvador", "label": "El Salvador", "emojicode": "SV" },
    { "value": "Equatorial Guinea", "label": "Equatorial Guinea", "emojicode": "GQ" },
    { "value": "Eritrea", "label": "Eritrea", "emojicode": "ER" },
    { "value": "Estonia", "label": "Estonia", "emojicode": "EE" },
    { "value": "Eswatini", "label": "Eswatini", "emojicode": "SZ" },
    { "value": "Ethiopia", "label": "Ethiopia", "emojicode": "ET" },
    { "value": "Fiji", "label": "Fiji", "emojicode": "FJ" },
    { "value": "Finland", "label": "Finland", "emojicode": "FI" },
    { "value": "France", "label": "France", "emojicode": "FR" },
    { "value": "Gabon", "label": "Gabon", "emojicode": "GA" },
    { "value": "Gambia", "label": "Gambia", "emojicode": "GM" },
    { "value": "Georgia", "label": "Georgia", "emojicode": "GE" },
    { "value": "Germany", "label": "Germany", "emojicode": "DE" },
    { "value": "Ghana", "label": "Ghana", "emojicode": "GH" },
    { "value": "Greece", "label": "Greece", "emojicode": "GR" },
    { "value": "Grenada", "label": "Grenada", "emojicode": "GD" },
    { "value": "Guatemala", "label": "Guatemala", "emojicode": "GT" },
    { "value": "Guinea", "label": "Guinea", "emojicode": "GN" },
    { "value": "Guinea-Bissau", "label": "Guinea-Bissau", "emojicode": "GW" },
    { "value": "Guyana", "label": "Guyana", "emojicode": "GY" },
    { "value": "Haiti", "label": "Haiti", "emojicode": "HT" },
    { "value": "Honduras", "label": "Honduras", "emojicode": "HN" },
    { "value": "Hungary", "label": "Hungary", "emojicode": "HU" },
    { "value": "Iceland", "label": "Iceland", "emojicode": "IS" },
    { "value": "India", "label": "India", "emojicode": "IN" },
    { "value": "Indonesia", "label": "Indonesia", "emojicode": "ID" },
    { "value": "Iran", "label": "Iran", "emojicode": "IR" },
    { "value": "Iraq", "label": "Iraq", "emojicode": "IQ" },
    { "value": "Ireland", "label": "Ireland", "emojicode": "IE" },
    { "value": "Israel", "label": "Israel", "emojicode": "IL" },
    { "value": "Italy", "label": "Italy", "emojicode": "IT" },
    { "value": "Jamaica", "label": "Jamaica", "emojicode": "JM" },
    { "value": "Japan", "label": "Japan", "emojicode": "JP" },
    { "value": "Jordan", "label": "Jordan", "emojicode": "JO" },
    { "value": "Kazakhstan", "label": "Kazakhstan", "emojicode": "KZ" },
    { "value": "Kenya", "label": "Kenya", "emojicode": "KE" },
    { "value": "Kiribati", "label": "Kiribati", "emojicode": "KI" },
    { "value": "Korea, North", "label": "Korea, North", "emojicode": "KP" },
    { "value": "Korea, South", "label": "Korea, South", "emojicode": "KR" },
    { "value": "Kosovo", "label": "Kosovo", "emojicode": "XK" },
    { "value": "Kuwait", "label": "Kuwait", "emojicode": "KW" },
    { "value": "Kyrgyzstan", "label": "Kyrgyzstan", "emojicode": "KG" },
    { "value": "Laos", "label": "Laos", "emojicode": "LA" },
    { "value": "Latvia", "label": "Latvia", "emojicode": "LV" },
    { "value": "Lebanon", "label": "Lebanon", "emojicode": "LB" },
    { "value": "Lesotho", "label": "Lesotho", "emojicode": "LS" },
    { "value": "Liberia", "label": "Liberia", "emojicode": "LR" },
    { "value": "Libya", "label": "Libya", "emojicode": "LY" },
    { "value": "Liechtenstein", "label": "Liechtenstein", "emojicode": "LI" },
    { "value": "Lithuania", "label": "Lithuania", "emojicode": "LT" },
    { "value": "Luxembourg", "label": "Luxembourg", "emojicode": "LU" },
    { "value": "Madagascar", "label": "Madagascar", "emojicode": "MG" },
    { "value": "Malawi", "label": "Malawi", "emojicode": "MW" },
    { "value": "Malaysia", "label": "Malaysia", "emojicode": "MY" },
    { "value": "Maldives", "label": "Maldives", "emojicode": "MV" },
    { "value": "Mali", "label": "Mali", "emojicode": "ML" },
    { "value": "Malta", "label": "Malta", "emojicode": "MT" },
    { "value": "Marshall Islands", "label": "Marshall Islands", "emojicode": "MH" },
    { "value": "Mauritania", "label": "Mauritania", "emojicode": "MR" },
    { "value": "Mauritius", "label": "Mauritius", "emojicode": "MU" },
    { "value": "Mexico", "label": "Mexico", "emojicode": "MX" },
    { "value": "Micronesia", "label": "Micronesia", "emojicode": "FM" },
    { "value": "Moldova", "label": "Moldova", "emojicode": "MD" },
    { "value": "Monaco", "label": "Monaco", "emojicode": "MC" },
    { "value": "Mongolia", "label": "Mongolia", "emojicode": "MN" },
    { "value": "Montenegro", "label": "Montenegro", "emojicode": "ME" },
    { "value": "Morocco", "label": "Morocco", "emojicode": "MA" },
    { "value": "Mozambique", "label": "Mozambique", "emojicode": "MZ" },
    { "value": "Myanmar", "label": "Myanmar", "emojicode": "MM" },
    { "value": "Namibia", "label": "Namibia", "emojicode": "NA" },
    { "value": "Nauru", "label": "Nauru", "emojicode": "NR" },
    { "value": "Nepal", "label": "Nepal", "emojicode": "NP" },
    { "value": "Netherlands", "label": "Netherlands", "emojicode": "NL" },
    { "value": "New Zealand", "label": "New Zealand", "emojicode": "NZ" },
    { "value": "Nicaragua", "label": "Nicaragua", "emojicode": "NI" },
    { "value": "Niger", "label": "Niger", "emojicode": "NE" },
    { "value": "Nigeria", "label": "Nigeria", "emojicode": "NG" },
    { "value": "North Macedonia", "label": "North Macedonia", "emojicode": "MK" },
    { "value": "Norway", "label": "Norway", "emojicode": "NO" },
    { "value": "Oman", "label": "Oman", "emojicode": "OM" },
    { "value": "Pakistan", "label": "Pakistan", "emojicode": "PK" },
    { "value": "Palau", "label": "Palau", "emojicode": "PW" },
    { "value": "Palestine", "label": "Palestine", "emojicode": "PS" },
    { "value": "Panama", "label": "Panama", "emojicode": "PA" },
    { "value": "Papua New Guinea", "label": "Papua New Guinea", "emojicode": "PG" },
    { "value": "Paraguay", "label": "Paraguay", "emojicode": "PY" },
    { "value": "Peru", "label": "Peru", "emojicode": "PE" },
    { "value": "Philippines", "label": "Philippines", "emojicode": "PH" },
    { "value": "Poland", "label": "Poland", "emojicode": "PL" },
    { "value": "Portugal", "label": "Portugal", "emojicode": "PT" },
    { "value": "Qatar", "label": "Qatar", "emojicode": "QA" },
    { "value": "Romania", "label": "Romania", "emojicode": "RO" },
    { "value": "Russia", "label": "Russia", "emojicode": "RU" },
    { "value": "Rwanda", "label": "Rwanda", "emojicode": "RW" },
    { "value": "Saint Kitts and Nevis", "label": "Saint Kitts and Nevis", "emojicode": "KN" },
    { "value": "Saint Lucia", "label": "Saint Lucia", "emojicode": "LC" },
    { "value": "Saint Vincent and the Grenadines", "label": "Saint Vincent and the Grenadines", "emojicode": "VC" },
    { "value": "Samoa", "label": "Samoa", "emojicode": "WS" },
    { "value": "San Marino", "label": "San Marino", "emojicode": "SM" },
    { "value": "Sao Tome and Principe", "label": "Sao Tome and Principe", "emojicode": "ST" },
    { "value": "Saudi Arabia", "label": "Saudi Arabia", "emojicode": "SA" },
    { "value": "Senegal", "label": "Senegal", "emojicode": "SN" },
    { "value": "Serbia", "label": "Serbia", "emojicode": "RS" },
    { "value": "Seychelles", "label": "Seychelles", "emojicode": "SC" },
    { "value": "Sierra Leone", "label": "Sierra Leone", "emojicode": "SL" },
    { "value": "Singapore", "label": "Singapore", "emojicode": "SG" },
    { "value": "Slovakia", "label": "Slovakia", "emojicode": "SK" },
    { "value": "Slovenia", "label": "Slovenia", "emojicode": "SI" },
    { "value": "Solomon Islands", "label": "Solomon Islands", "emojicode": "SB" },
    { "value": "Somalia", "label": "Somalia", "emojicode": "SO" },
    { "value": "South Africa", "label": "South Africa", "emojicode": "ZA" },
    { "value": "South Sudan", "label": "South Sudan", "emojicode": "SS" },
    { "value": "Spain", "label": "Spain", "emojicode": "ES" },
    { "value": "Sri Lanka", "label": "Sri Lanka", "emojicode": "LK" },
    { "value": "Sudan", "label": "Sudan", "emojicode": "SD" },
    { "value": "Suriname", "label": "Suriname", "emojicode": "SR" },
    { "value": "Sweden", "label": "Sweden", "emojicode": "SE" },
    { "value": "Switzerland", "label": "Switzerland", "emojicode": "CH" },
    { "value": "Syria", "label": "Syria", "emojicode": "SY" },
    { "value": "Taiwan", "label": "Taiwan", "emojicode": "TW" },
    { "value": "Tajikistan", "label": "Tajikistan", "emojicode": "TJ" },
    { "value": "Tanzania", "label": "Tanzania", "emojicode": "TZ" },
    { "value": "Thailand", "label": "Thailand", "emojicode": "TH" },
    { "value": "Timor-Leste", "label": "Timor-Leste", "emojicode": "TL" },
    { "value": "Togo", "label": "Togo", "emojicode": "TG" },
    { "value": "Tonga", "label": "Tonga", "emojicode": "TO" },
    { "value": "Trinidad and Tobago", "label": "Trinidad and Tobago", "emojicode": "TT" },
    { "value": "Tunisia", "label": "Tunisia", "emojicode": "TN" },
    { "value": "Turkey", "label": "Turkey", "emojicode": "TR" },
    { "value": "Turkmenistan", "label": "Turkmenistan", "emojicode": "TM" },
    { "value": "Tuvalu", "label": "Tuvalu", "emojicode": "TV" },
    { "value": "Uganda", "label": "Uganda", "emojicode": "UG" },
    { "value": "Ukraine", "label": "Ukraine", "emojicode": "UA" },
    { "value": "United Arab Emirates", "label": "United Arab Emirates", "emojicode": "AE" },
    { "value": "United Kingdom", "label": "United Kingdom", "emojicode": "GB" },
    { "value": "United States", "label": "United States", "emojicode": "US" },
    { "value": "Uruguay", "label": "Uruguay", "emojicode": "UY" },
    { "value": "Uzbekistan", "label": "Uzbekistan", "emojicode": "UZ" },
    { "value": "Vanuatu", "label": "Vanuatu", "emojicode": "VU" },
    { "value": "Vatican City", "label": "Vatican City", "emojicode": "VA" },
    { "value": "Venezuela", "label": "Venezuela", "emojicode": "VE" },
    { "value": "Vietnam", "label": "Vietnam", "emojicode": "VN" },
    { "value": "Yemen", "label": "Yemen", "emojicode": "YE" },
    { "value": "Zambia", "label": "Zambia", "emojicode": "ZM" },
    { "value": "Zimbabwe", "label": "Zimbabwe", "emojicode": "ZW" }
  ];
  //to store nationality
  const [selectedNationality, setSelectedNationality] = useState(null);

  const handleChangeSelectNationality = value => {
    setSelectedNationality(value);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-96">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className=""
                        onClick={close}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full overflow-y-auto bg-white p-8">
                    <h1 className="font-bold text-violet-500">Enter New Passenger Details</h1>
                    <div className="mt-2">
                      <form id='addForm' onsubmit="return false;">
                        <h3 className="font-medium text-indigo-900">First Name</h3>
                        <input
                          id="first_name"
                          name="first_name"
                          type="text"
                          placeholder="First Name"
                          className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Middle Name</h3>
                        <input
                          id="middle_name"
                          name="middle_name"
                          type="text"
                          placeholder="Middle Name"
                          className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Last Name</h3>
                        <input
                          id="last_name"
                          name="last_name"
                          type="text"
                          required
                          placeholder="Last Name *"
                          className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Gender</h3>
                        <select
                          id="gender"
                          name="gender"
                          className="block w-full mt-2 py-2 pl-3 pr-10 mt-2 text-gray-900 border-0 rounded-md ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-50 sm:text-sm sm:leading-6"
                          defaultValue="Male"
                        >
                          <option>Male</option>
                          <option>Female</option>
                          <option>Other</option>
                        </select>
                        <h3 className="font-medium mt-2 text-indigo-900">Nationality</h3>
                        <Select
                          id="nationality"
                          name="nationality"
                          value={selectedNationality}
                          options={countriesWithFlags}
                          isSearchable={true}
                          primaryColor='black'
                          searchInputPlaceholder='Search'
                          onChange={handleChangeSelectNationality}
                          formatOptionLabel={data => (
                            <li
                              className={`block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${!data.isSelected
                                  ? `text-black`
                                  : `bg-blue-100 text-black`
                                }`}
                            >
                              <img src={`https://test-ilios-master.linkpc.net/assets/images/${data.emojicode.toLowerCase()}.png`} alt='flag' />
                              {data.label}
                            </li>
                          )}
                          classNames={{
                            searchIcon: `absolute w-5 h-5 mt-2.5 pb-0.5 ml-12 text-gray-500`,

                          }}
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Date of Birth</h3>
                        <input
                          id="dob"
                          name="dob"
                          type="date"
                          required
                          className="mt-2 w-full"
                        >
                        </input>
                        <h3 className="font-medium mt-2 text-indigo-900">Passport Number</h3>
                        <input
                          id="passport"
                          name="passport"
                          type="text"
                          placeholder="Passport *"
                          className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">National ID</h3>
                        <input
                          id="national_id"
                          name="national_id"
                          type="text"
                          placeholder="National ID *"
                          className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                        />
                      </form>
                        <h3 className="font-medium mt-2 text-indigo-900">Upload ID's</h3>
                        <ul role="list" className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                          <li className="flex items-center justify-between py-3">
                            <div className="flex items-center">
                              <img
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbQiOeebAFaQFZv5UxhNfSzMsQiTaPShoWdPdHEsxTbulhu8CPTfzxHp0AQ6JvjHj_Iwg&usqp=CAU"
                                alt=""
                                className="h-8 w-8 rounded-full"
                              />
                              <p className="ml-4 text-sm font-medium text-gray-900">Passport</p>
                              <button className="text-xs" onClick={handleClickPassport}>
                                <div className='flex'><PaperClipIcon className="h-5 w-5 pl-2 flex-shrink-0 text-gray-400" aria-hidden="true" /> Attach</div>
                              </button>
                              <text className='p-1 text-xs text-indigo-500' style={{ 'max-width': '10ch', 'text-overflow': 'ellipsis', 'overflow': 'hidden', 'white-space': 'nowrap' }}>{passportFile.name}</text>
                              <input type='file' accept='pdf/*' id='passport_file' name='passport_file' onChange={(e) => { SetPassportFileHelper(e) }} ref={hiddenFileInputPassport} style={{ display: 'none' }}>
                              </input>
                            </div>
                            {passportUploaded == false ?
                              <button
                                type="button"
                                className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={uploadPassport}
                              >
                                Upload
                              </button>
                              :
                              <text className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                Uploaded
                              </text>}
                          </li>
                          <li className="flex items-center justify-between py-3">
                            <div className="flex items-center">
                              <img
                                src="https://thumbs.dreamstime.com/b/id-card-license-identity-driver-document-identification-national-name-age-state-passport-chip-barcode-electronic-232461536.jpg"
                                alt=""
                                className="h-8 w-8 rounded-full"
                              />
                              <p className="ml-4 text-sm font-medium text-gray-900">National ID</p>
                              <button className="text-xs" onClick={handleClickNationalID}>
                                <div className='flex'><PaperClipIcon className="h-5 w-5 pl-2 flex-shrink-0 text-gray-400" aria-hidden="true" /> Attach</div>
                              </button>
                              <text className='p-1 text-xs text-indigo-500' style={{ 'max-width': '10ch', 'text-overflow': 'ellipsis', 'overflow': 'hidden', 'white-space': 'nowrap' }}>{nationalIDFile.name}</text>
                              <input type='file' accept='pdf/*' id='national_id_file' name='national_id_file' onChange={(e) => { SetNationalIDFileHelper(e) }} ref={hiddenFileInputNationalID} style={{ display: 'none' }}>
                              </input>
                            </div>
                            {nationalIDUploaded == false ?
                              <button
                                type="button"
                                className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={uploadNationalID}
                              >
                                Upload
                              </button>
                              :
                              <text className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                Uploaded
                              </text>}
                          </li>
                        </ul>
                      <div className="mt-2 flex">
                        <button
                          onClick={postRecord}
                          className="flex-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default AddPassengerForm;