import { Fragment, useEffect, useState } from "react";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import axios from "axios";

const ModalTourShareWithCustomer = ({ open, close, tour_id }) => {
  
  const [custEmail, setCustEmail] = useState(null);

  const handleCustEmailChange = (event) => {
    setCustEmail(event.target.value);
  }

  const postTourShareWithCustomer = async (event) => {
    event.preventDefault();
    const data = { 'action': 'tour_share_with_customer', 'username': localStorage.getItem('user_id'), 
                   'email': custEmail, 'tour_id':tour_id};
    const token = localStorage.getItem('token');
    const config = { Authorization: `Bearer ${token}` }
    const response = await axios.post(`http://test-ilios-master.linkpc.net/api/updateDocuments.php`, data, {
        headers: config
    });
    if (response.data.message == "Success") {
        document.location.reload();
    } else {
        alert(response.data.message);
    }
}
  
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={close}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="font-bold text-violet-500"
                      >
                        Enter the Email of Customer to access this Quote...
                      </Dialog.Title>
                    </div>
                  </div>
                </div>
                {/*Modal Form*/}
                    <div className="mt-2 mr-5 ml-5">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Enter Customer Email ..."
                            value={custEmail}
                            onChange={handleCustEmailChange}
                            required
                            className="block w-full py-3 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                        />
                    </div>
                <div className="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-blue-600 rounded-md shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                    onClick={postTourShareWithCustomer}
                  >
                    Share
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalTourShareWithCustomer;