import {
  HiOutlineUser,
  HiOutlineHome,
  HiOutlineMap,
  HiOutlineWallet,
  HiMiniWrenchScrewdriver,
  HiDocument
} from "react-icons/hi2";

export const NAV_SIDEBAR_LINKS = [
  {
    name: "dashboard",
    label: "Dashboard",
    path: "/",
    icon: HiOutlineHome,
  },
  {
    name: "tours",
    label: "Tours",
    path: "/tours",
    icon: HiOutlineMap,
  },
  {
    name: "customers",
    label: "Customers",
    path: "/customers",
    icon: HiOutlineUser,
  },
  {
    name: "quotes",
    label: "Quotes",
    path: "/quotes",
    icon: HiDocument,
  },
  {
    name: "wallet",
    label: "Wallet",
    path: "/wallet",
    icon: HiOutlineWallet,
  },
  {
    name: "PDF Converter Tool",
    label: "PDF Converter Tool",
    path: "pdfConverter",
    icon: HiMiniWrenchScrewdriver, 
  }
];


