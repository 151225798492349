import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HeartIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Select from "react-tailwindcss-select";
import axios from 'axios';

const AddQuoteForm = ({ open, close }) => {
  //To store the customers list
  const [customersList, setCustomersList] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleChangeSelectCustomer = value => {
    setSelectedCustomer(value);
  };

  useEffect(() => {
    fetchCustomerList();
  }, []);

  const fetchCustomerList = async () => {
    const data = { 'action': 'fetch_customers_label', 'username': localStorage.getItem('user_id') };
    const token = localStorage.getItem('token');
    const config = { Authorization: `Bearer ${token}` }
    const response = await axios.post(`http://test-ilios-master.linkpc.net/api/getDocuments.php`, data, {
      headers: config
    });
    setCustomersList(response.data);
  }
  //To Add the Quote Record (API Call)
  const postRecord = async (event) => {

    event.preventDefault();
    const formdata = new FormData(document.getElementById('addForm'));
    const update_data = {
      customer: selectedCustomer.value.toString(),
      title: formdata.get("title"),
      adults_count: formdata.get("adults_count"),
      childs_count: formdata.get("childs_count"),
      toddlers_count: formdata.get("toddlers_count"),
      travel_start_date: formdata.get("travel_start_date"),
      valid_till_date: formdata.get("valid_till_date"),
      currency: selectedCurrency.value
    };
    const data = { ...update_data, 'action': 'add_quote', 'username': localStorage.getItem('user_id') };
    const token = localStorage.getItem('token');
    const config = { Authorization: `Bearer ${token}` }
    const response = await axios.post(`http://test-ilios-master.linkpc.net/api/addDocuments.php`, data, {
      headers: config
    });
    if (response.data.message == "Success") {
      document.location.reload();
    } else {
      alert(response.data.message);
    }
  }

  const currencies = [
    {"value":"INR","label":"Indian Rupee"},
    {"value":"USD","label":"US Dollar"},
    {"value":"AED","label":"Arab Emirates Dhiram"}
  ]; 
  //to store currency
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const handleChangeSelectCurrency = value => {
    setSelectedCurrency(value);
  };
  
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-96">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className=""
                        onClick={close}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full overflow-y-auto bg-white p-8">
                    <h1 className="font-bold text-violet-500">Enter New Quote Details</h1>
                    <div className="mt-2">
                      <form id='addForm' onSubmit={postRecord}>
                        <h3 className="font-medium text-indigo-900">Customer</h3>
                        <Select
                          id="customer"
                          name="customer"
                          value={selectedCustomer}
                          options={customersList}
                          isSearchable={true}
                          primaryColor='black'
                          searchInputPlaceholder='Search'
                          onChange={handleChangeSelectCustomer}
                          formatOptionLabel={data => (
                            <li
                              className={`block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${!data.isSelected
                                  ? `text-black`
                                  : `bg-blue-100 text-black`
                                }`}
                            >
                              {data.label}
                            </li>
                          )}
                          classNames={{
                            searchIcon: `absolute w-5 h-5 mt-2.5 pb-0.5 ml-12 text-gray-500`,

                          }}
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Title (Short Description Max:50 Characters)</h3>
                        <input
                          id="title"
                          name="title"
                          type="text"
                          maxLength={50}
                          required
                          placeholder="Title*"
                          className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Adults Travelling</h3>
                        <input
                          id="adults_count"
                          name="adults_count"
                          type="number"
                          required
                          placeholder="Adults Count"
                          className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Children Travelling (4+ yrs old)</h3>
                        <input
                          id="childs_count"
                          name="childs_count"
                          type="number"
                          required
                          placeholder="Childs Count"
                          className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Children Travelling (0-3 yrs old)</h3>
                        <input
                          id="toddlers_count"
                          name="toddlers_count"
                          type="number"
                          required
                          placeholder="Toddlers Count"
                          className="block w-full mt-2 py-2 pl-3 pr-10 mt-2 text-gray-900 border-0 rounded-md ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-50 sm:text-sm sm:leading-6"
                          defaultValue="Male"
                        />
                        <h3 className="font-medium mt-2 text-indigo-900">Date of Travel(Start)</h3>
                        <input
                          id="travel_start_date"
                          name="travel_start_date"
                          type="date"
                          required
                          className="mt-2 w-full"
                        >
                        </input>
                        <h3 className="font-medium mt-2 text-indigo-900">Quote Valid Till Date</h3>
                        <input
                          id="valid_till_date"
                          name="valid_till_date"
                          type="date"
                          required
                          className="mt-2 w-full"
                        >
                        </input>
                        <h3 className="font-medium text-indigo-900">Currency</h3>
                        <Select
                          id="currency"
                          name="currency"
                          value={selectedCurrency}
                          options={currencies}
                          isSearchable={true}
                          primaryColor='black'
                          searchInputPlaceholder='Search'
                          onChange={handleChangeSelectCurrency}
                          formatOptionLabel={data => (
                            <li
                              className={`block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${!data.isSelected
                                  ? `text-black`
                                  : `bg-blue-100 text-black`
                                }`}
                            >
                              {data.label}
                            </li>
                          )}
                          classNames={{
                            searchIcon: `absolute w-5 h-5 mt-2.5 pb-0.5 ml-12 text-gray-500`,

                          }}
                        />
                        <div className="mt-2 flex">
                          <button
                            type="submit"
                            className="flex-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default AddQuoteForm;