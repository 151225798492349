import { Fragment, useState, useEffect,useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HeartIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { PaperClipIcon, PlusIcon } from '@heroicons/react/20/solid'
import axios from 'axios';
import Select from "react-tailwindcss-select";

const AddSightseeingVoucherForm = ({ open, close, inputs, tour_id }) => {

    const [record, setRecord] = useState({});

    //To Update the Values of the form with inputs received from parent
    useEffect(() => {
        setRecord(inputs);
        setPassengerList(inputs.passengers);
        SetTicketFile(Array(inputs.quantity).fill(null));
        SetTicketFileServer(Array(inputs.quantity).fill("default"));
        SetTickets(Array(inputs.quantity).fill(""));
    }, [inputs])

    //Handle when Values are edited
    const handleFormChange = (event) => {
        setRecord({
            ...record,
            [event.target.name]: event.target.value,
        });
    };

    //To Add the Sightseeing Record (API Call)
    const postRecord = async (event) => {
        event.preventDefault();
        const formdata = new FormData(document.getElementById('addForm'));
        if (selectedPassenger == null) {
            alert("Please Select Passengers.")
        }
        else if (formdata.get("activity_datetime").length == 0) {
            alert("Please Enter Activity Date.")
        }
        else if (formdata.get("quantity") < 1) {
            alert("Please Enter At least 1 Quantity.")
        }
        else {
            const update_data = {
                sightseeing_details_id:record.id,
                lead_passenger: selectedPassenger == null ? record.passengers:selectedPassenger,
                passenger_contact: formdata.get("passenger_contact"),
                activity: formdata.get("activity"),
                activity_datetime: formdata.get("activity_datetime"),
                booking_number: formdata.get("booking_number"),
                ticket_numbers:tickets,
                qr_codes:ticketFileServer,
                terms: formdata.get("terms"),
                quantity: formdata.get("quantity")
            };
            const data = { ...update_data, 'action': 'add_sightseeing_voucher', 'username': localStorage.getItem('user_id'), 'tours_id': tour_id };
            const token = localStorage.getItem('token');
            const config = { Authorization: `Bearer ${token}` };
            const response = await axios.post(`http://test-ilios-master.linkpc.net/api/updateDocuments.php`, data, {
                headers: config
            });
            if (response.data.message == "Success") {
                document.location.reload();
            } else {
                alert(response.data.message);
            }
        }
    }
    //To store the passenger list options and selected passengers
    const [passengerList, setPassengerList] = useState(null);
    const [selectedPassenger, setSelectedPassenger] = useState(null);

    const handleChangeSelectPassenger = (value) => {
        console.log(value);
        setSelectedPassenger(value);
    }
    //To store ticket numbers
  const [tickets, SetTickets] = useState([]);
    //To store name of server files
  const [ticketFileServer, SetTicketFileServer] = useState([]);

    //To Store Uploaded Files
  const [ticketFile, SetTicketFile] = useState([]);

  const SetTicketFileHelper = (e,index) => {
    e.preventDefault();
    SetTicketFile(a => [
        ...a.slice(0, index),
        e.target.files[0],
        ...a.slice(index + 1, a.length),
    ]);
  }
    //Use to set the button for upload of files or display status as uploaded.
  const [ticketUploaded, setTicketUploaded] = useState([]);

  //File Input using custom button style
  const hiddenFileInputTicket = useRef([]);
  const handleClickTicket = (event,index) => {
    hiddenFileInputTicket.current[index].click();
    setTicketUploaded(a => [
        ...a.slice(0, index),
        false,
        ...a.slice(index + 1, a.length),
    ]);
    };

   //Handle Upload Call
  const uploadTicket = async(index) => {
    var fileSize = ticketFile[index].size / 1024;
    console.log(ticketFile[index]);
    if (fileSize > 100) 
    {
      alert('File Size Exceeds 100kB Limit.');
    }
    else 
    {
        const formData = new FormData();
        formData.append('image', ticketFile[index]);
        const token = localStorage.getItem('token');
        const config = { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data', 'unique_id':tour_id};
        const response = await axios.post('http://test-ilios-master.linkpc.net/api/files/uploadQRCode.php', formData, {
          headers: config
        });
        if(response.data.message == "Success")
        {
            setTicketUploaded(a => [
                ...a.slice(0, index),
                true,
                ...a.slice(index + 1, a.length),
            ]);
          SetTicketFileServer(a => [
            ...a.slice(0, index),
            response.data.name,
            ...a.slice(index + 1, a.length),
        ]);
        }
        else
        {
          alert('Error uploading file'); 
        }
    }
  }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={close}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto relative w-96">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-500"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                                            <button
                                                type="button"
                                                className=""
                                                onClick={close}
                                            >
                                                <span className="sr-only">Close panel</span>
                                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="h-full overflow-y-auto bg-white p-8">
                                        <h1 className="font-bold text-violet-500">Enter New Sightseeing Voucher Details</h1>
                                        <div className="mt-2">
                                            <form id='addForm'>
                                                <h3 className="font-medium text-indigo-900">Select Lead Passenger</h3>
                                                <Select
                                                    id="passengers"
                                                    name="passengers"
                                                    value={selectedPassenger}
                                                    options={passengerList}
                                                    isSearchable={true}
                                                    isMultiple={true}
                                                    isClearable={true}
                                                    primaryColor='black'
                                                    searchInputPlaceholder='Search'
                                                    onChange={handleChangeSelectPassenger}
                                                    classNames={{
                                                        searchIcon: `absolute w-5 h-5 mt-2.5 pb-0.5 ml-12 text-gray-500`,
                                                        tagItemIconContainer: `flex items-center px-2  cursor-pointer rounded-r-sm hover:bg-red-200 hover:text-red-600`,
                                                        tagItemIcon: `w-3 h-3 bg-white-500 `

                                                    }}
                                                />
                                                <h3 className="font-medium text-indigo-900">Passenger Contact Detail(Include Country Code without +)</h3>
                                                <input
                                                    id="passenger_contact"
                                                    name="passenger_contact"
                                                    type="number"
                                                    required
                                                    value={record.passenger_contact}
                                                    onChange={handleFormChange}
                                                    placeholder="Passenger Contact"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium text-indigo-900">Activity</h3>
                                                <textarea
                                                    id="activity"
                                                    name="activity"
                                                    rows={5}
                                                    required
                                                    onChange={handleFormChange}
                                                    placeholder="Activity Title & Description *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                >
                                                    {record.activity}
                                                </textarea>
                                                <h3 className="font-medium mt-2 text-indigo-900">Date/Time</h3>
                                                <input
                                                    id="activity_datetime"
                                                    name="activity_datetime"
                                                    type="datetime-local"
                                                    value={record.activity_datetime}
                                                    onChange={handleFormChange}
                                                    required
                                                    placeholder="Activity Date/Time *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Booking Number (If Confirmed)</h3>
                                                <input
                                                    id="booking_number"
                                                    name="booking_number"
                                                    type="text"
                                                    value={record.booking_number}
                                                    onChange={handleFormChange}
                                                    placeholder="Booking Number"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Quantity</h3>
                                                <input
                                                    id="quantity"
                                                    name="quantity"
                                                    type="number"
                                                    value={record.quantity}
                                                    required
                                                    placeholder="Quantity *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                />
                                                <h3 className="font-medium mt-2 text-indigo-900">Terms & Conditions</h3>
                                                <textarea
                                                    id="terms"
                                                    name="terms"
                                                    rows={5}
                                                    required
                                                    onChange={handleFormChange}
                                                    placeholder="Terms & Condition *"
                                                    className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                >
                                                    {record.terms}
                                                </textarea>
                                            </form>
                                                <ul role="list" className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                                                    {tickets.map((t,index) => (
                                                        <li>
                                                        <h3 className="font-medium mt-2 text-indigo-900">Ticket Number {index +1}</h3>
                                                        <input
                                                            id="ticket_number"
                                                            name="ticket_number"
                                                            type="text"
                                                            value={t}
                                                            onChange={event => SetTickets(a=>[
                                                                ...a.slice(0, index),
                                                                event.target.value,
                                                                ...a.slice(index + 1, a.length),
                                                            ])}
                                                            required
                                                            placeholder="Ticket Number *"
                                                            className="block w-full mt-2 px-2 py-2 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:text-sm sm:leading-6"
                                                        />
                                                        <div className="flex items-center justify-between py-3">
                                                            <div className="flex items-center">
                                                                <img
                                                                    src="https://cdn-icons-png.flaticon.com/512/7143/7143978.png"
                                                                    alt=""
                                                                    className="h-8 w-8 rounded-full"
                                                                />
                                                                <p className="ml-4 text-sm font-medium text-gray-900">QR/Barcode</p>
                                                                <button className="text-xs" onClick={event => handleClickTicket(event, index)}>
                                                                    <div className='flex'><PaperClipIcon className="h-5 w-5 pl-2 flex-shrink-0 text-gray-400" aria-hidden="true" /> Attach</div>
                                                                </button>
                                                                <text className='p-1 text-xs text-indigo-500' style={{ 'max-width': '10ch', 'text-overflow': 'ellipsis', 'overflow': 'hidden', 'white-space': 'nowrap' }}>{ticketFile !=null && ticketFile[index] != null?ticketFile[index].name:''}</text>
                                                                <input type='file' accept='.png, .jpg, .jpeg' id='qr_file' name='qr_file' onChange={(e) => { SetTicketFileHelper(e,index) }} ref={el => hiddenFileInputTicket.current[index] = el} style={{ display: 'none' }}>
                                                                </input>
                                                            </div>
                                                            {ticketUploaded[index] == false ?
                                                                <button
                                                                    type="button"
                                                                    className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                    onClick={() => uploadTicket(index)}
                                                                >
                                                                    Upload
                                                                </button>
                                                                :
                                                                <text className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                                    Uploaded
                                                                </text>}
                                                        </div>
                                                    </li>
                                                    ))}

                                                </ul>
                                                <div className="mt-2 flex">
                                                    <button
                                                        onClick={(event) => postRecord(event)}
                                                        className="flex-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default AddSightseeingVoucherForm;