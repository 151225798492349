import React, { Fragment } from 'react'
import { Image, Text, View, Page, Document, StyleSheet, Link } from '@react-pdf/renderer';
import { ToWords } from 'to-words';

const Invoice = ({invoice_data, logoBlob}) => {

    const toWords = new ToWords({
        converterOptions: {
            currency: false,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false
        }
    });

    const styles = StyleSheet.create({
        page: { fontSize: 11, paddingTop: 20, paddingLeft: 30, paddingRight: 30, paddingBottom: 50,lineHeight: 1.5, flexDirection: 'column' },

        spaceBetween: { flex: 2, flexDirection: 'row', justifyContent: 'space-between', color: "#3E3E3E" },
        
        logo: { width: 90, height: 45},

        titleContainer: { flexDirection: 'row', marginTop: 10 },

        reportTitle: { fontSize: 12, textAlign: 'center' },

        addressTitle: { fontSize: 10 },

        invoice: { fontWeight: 'bold', fontSize: 42 },

        invoiceMisc: { fontSize: 14, fontWeight: 'bold' },

        address: { fontWeight: 400, fontSize: 10 },

        footer: { position:'absolute', width:'200%', bottom:10, fontSize: 8, fontStyle: 'bold', paddingTop: 4, paddingLeft: 7, backgroundColor: invoice_data.footer_tab_color, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1},

        invoiceDetail: { fontSize: 11, fontStyle: 'bold', paddingTop: 4, paddingLeft: 7, flex: 1, height: 25, backgroundColor: invoice_data.detail_tab_color, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },

        theader: { fontSize: 11, fontStyle: 'bold', paddingTop: 4, paddingLeft: 7, flex: 0.5, height: 25, backgroundColor: invoice_data.table_tab_color, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },

        theader2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },

        tbody: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 0.5, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1, borderLeftWidth:1 },

        total: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 3, borderColor: 'whitesmoke', borderBottomWidth: 1, borderLeftWidth:1 },

        tbody2: { flex: 2, borderRightWidth: 1, },
        
        tbodybold: { fontSize: 9, fontWeight: 'bold', paddingTop: 4, paddingLeft: 7, flex: 0.5, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1, borderLeftWidth:1 }

    });

    const InvoiceTitle = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    <Image style={styles.logo} src={logoBlob} />
                </View>
                <View>
                    <Text style={styles.invoice}>INVOICE</Text>
                </View>
            </View>
        </View>
    );
    
    const InvoiceDetail = () => (
        <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
            <View style={styles.invoiceDetail}>
                <Text >Invoice Date:{invoice_data.invoice_date}</Text>
            </View>
            <View style={styles.invoiceDetail}>
                <Text>Invoice Number:{invoice_data.invoice_number}</Text>
            </View>
            <View style={styles.invoiceDetail}>
                <Text>Currency:{invoice_data.currency}</Text>
            </View>
        </View>
    );

    const InvoiceToFrom = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View style={{ maxWidth: 200 }}>
                    <Text style={styles.invoiceMisc}>Bill To:</Text>
                    <Text style={styles.address}>
                        {invoice_data.customer_name}
                    </Text>
                    <Text style={styles.address}>
                        {invoice_data.customer_email} , {invoice_data.customer_phone}
                    </Text>
                    {invoice_data.customer_taxid != ""?<Text style={styles.address}>
                        {invoice_data.customer_tax_id}
                    </Text>:null}
                </View>
                <View style={{ maxWidth: 200 }}>
                    <Text style={styles.invoiceMisc} >Bill From:</Text>
                    <Text style={styles.reportTitle}>{invoice_data.company_name}</Text>
                    <Text style={styles.addressTitle}>{invoice_data.address_line_1}</Text>
                    <Text style={styles.addressTitle}>{invoice_data.address_line_2}</Text>
                    <Text style={styles.addressTitle}>{invoice_data.company_tax_id}</Text>
                </View>
            </View>
        </View>
    );

    const TableHead = () => (
        <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
            <View style={[styles.theader, styles.theader2]}>
                <Text >Description</Text>
            </View>
            <View style={styles.theader}>
                <Text>Price</Text>
            </View>
            <View style={styles.theader}>
                <Text>Quantity</Text>
            </View>
            <View style={styles.theader}>
                <Text>Amount</Text>
            </View>
        </View>
    );

    const TableBody1 = () => (
            <Fragment key={1}>
                <View style={{ width: '100%', flexDirection: 'row' }}>
                    <View style={[styles.tbody, styles.tbody2]}>
                        <Text >{invoice_data.invoice_description}</Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{invoice_data.invoice_type == "Simple" ?invoice_data.invoice_total:null} </Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{invoice_data.invoice_type == "Simple" ?1:null}</Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{invoice_data.invoice_type == "Simple" ?invoice_data.invoice_total:null}</Text>
                    </View>
                </View>
            </Fragment>
    );

    const TableBody2 = () => (
        invoice_data.invoice_type == "Detailed" ? invoice_data.items.map((receipt) => (
            <Fragment key={receipt.id}>
                <View style={{ width: '100%', flexDirection: 'row' }}>
                    <View style={[styles.tbody, styles.tbody2]}>
                        <Text >{receipt.description}</Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{receipt.price} </Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{receipt.quantity}</Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{(receipt.price * receipt.quantity).toFixed(3)}</Text>
                    </View>
                </View>
            </Fragment>
        )):null
    );

    const TableBodyServiceCharge = () => (
        invoice_data.is_service_charge == "Yes" ? (
            <Fragment key={1}>
                <View style={{ width: '100%', flexDirection: 'row' }}>
                    <View style={[styles.tbody, styles.tbody2]}>
                        <Text >Service Charge</Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text></Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text></Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{invoice_data.service_charge}</Text>
                    </View>
                </View>
            </Fragment>
        ):null
    );
    
    const TableGross = () => (
        <View style={{ width: '100%', flexDirection: 'row' }}>
            <View style={[styles.tbody, styles.tbody2]}>
                <Text></Text>
            </View>
            <View style={styles.tbody}>
                <Text></Text>
            </View>
            <View style={styles.tbody}>
                <Text>Gross Amount</Text>
            </View>
            <View style={styles.tbody}>
                <Text >
                    {invoice_data.invoice_gross}
                </Text>
            </View>
        </View>
    );

    const TableBodyTax = () => (
        invoice_data.is_tax == "Yes" ? (
            <Fragment key={1}>
                <View style={{ width: '100%', flexDirection: 'row' }}>
                    <View style={[styles.tbody, styles.tbody2]}>
                        <Text >{invoice_data.tax_description}</Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text></Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{invoice_data.tax_percentage}%</Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{invoice_data.tax_type == "Gross Amount"?(invoice_data.invoice_gross * invoice_data.tax_percentage * 0.01).toFixed(3):(invoice_data.service_charge * invoice_data.tax_percentage * 0.01).toFixed(3)}</Text>
                    </View>
                </View>
            </Fragment>
        ):null
    );

    const TableTotal = () => (
        <View style={{ width: '100%', flexDirection: 'row' }}>
            <View style={styles.tbody}>
                <Text>Total</Text>
            </View>
            <View style={styles.tbodybold}>
                <Text>{toWords.convert(invoice_data.invoice_total)}</Text>
            </View>
            <View style={styles.tbody}>
                <Text >
                    {invoice_data.invoice_total}
                </Text>
            </View>
        </View>
    );

    const InvoiceBelow = () => (
        <View style={[styles.titleContainer,{marginTop:30}]}>
            <View style={styles.spaceBetween}>
                <View style={{ maxWidth: 200 }}>
                    <Text style={styles.invoiceMisc}>Payment To:</Text>
                    <Text style={styles.address}>
                        Bank:{invoice_data.default_bank_name}
                    </Text>
                    <Text style={styles.address}>
                        Account No:{invoice_data.default_account_number}
                    </Text>
                    <Text style={styles.address}>
                        {invoice_data.default_bank_code}
                    </Text>
                    <Text style={styles.address}>
                        <Link src={invoice_data.payment_link}>Click To Pay Online Now...</Link>
                    </Text>
                </View>
                <View style={{ maxWidth: 200 }}>
                    <Text style={styles.invoiceMisc} >Authorised By:</Text>
                    <Text style={styles.invoiceMisc}>{invoice_data.authorised_by}</Text>
                </View>
            </View>
        </View>
    );

    const InvoiceNote = () => (
        <View style={styles.titleContainer}>
                <View>
                <Text style={styles.reportTitle}>{invoice_data.comment}</Text>
                    <Text style={styles.invoiceMisc}>Note:This is a computer generated Invoice and does not require signature.</Text>
                    
                </View>
        </View>
    );

    const InvoiceFooter = () => (
            <View style={styles.footer} fixed>
                <Text >Contact Us:{invoice_data.default_contact_footer_1}</Text>
                <Text >{invoice_data.default_contact_footer_2}</Text>
            </View>
    );

    return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                <InvoiceTitle />
                <InvoiceDetail />
                <InvoiceToFrom />
                <TableHead />
                <TableBody1 wrap/>
                <TableBody2 wrap/>
                <TableBodyServiceCharge wrap/>
                <TableGross wrap/>
                <TableBodyTax wrap/>
                <TableTotal wrap/>
                <InvoiceBelow wrap/>
                <InvoiceNote wrap/>
                <InvoiceFooter fixed/>
            </Page>
        </Document>

    )
}

export default Invoice